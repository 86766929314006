import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NgChartsModule} from 'ng2-charts';
import { AddDataMatDialogComponent } from './add-data-mat-dialog/add-data-mat-dialog.component';
import {MatDialogModule} from "@angular/material/dialog";
import {MatButtonModule} from "@angular/material/button";
import {EndpointModule} from "../endpoint/endpoint.module";
import {MatIconModule} from "@angular/material/icon";

/*
 Do not specify app-wide singleton providers in a shared module.
 A lazy loaded module that imports that shared module will make its own copy of the service.
 */

@NgModule({
  declarations: [
    AddDataMatDialogComponent
  ],
  imports: [CommonModule, NgChartsModule, MatDialogModule, MatButtonModule, EndpointModule, MatIconModule],
  exports: [NgChartsModule],
})
export class SharedModule {
}
