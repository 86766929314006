import {Component, Input, OnInit} from '@angular/core';
import {FormControl} from '@angular/forms';
import {NavItem} from "../navigation/models/nav-item";
import {menu} from "../navigation/models/menu";
import {NavigationService} from "../navigation/navigation.service";

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
})
export class HeaderComponent implements OnInit {
  selectedProduct: string;

  myControl = new FormControl();
  @Input()
  isHeaderVisible: boolean;

  menu: NavItem[] = menu;
  url: string;

  constructor(private navService: NavigationService) {
  }

  ngOnInit(): void {
    this.navService.currentUrl.subscribe(url => {
      this.url = url;
    });
  }

  getQueryParamFromSession(key: string) {
    return sessionStorage.getItem(key);
  }

  handleItemExpansion(item: NavItem) {
    this.menu.forEach(navItem => {
      if (navItem.displayName != item.displayName) {
        navItem.expanded = false;
      }
    })
  }


}
