<div class="dialog-container">
<h2 class="title" mat-dialog-title>Select Set</h2>
<div mat-dialog-content>
  <p>Select set of Active Ingredients</p>
  <ng-select [items]="userSets"
             appearance="fill"
             bindLabel="setName"
             appendTo="body"
             [(ngModel)]="selectedSet"
             [closeOnSelect]="true">
  </ng-select>

</div>
  <div mat-dialog-actions class="dialog-buttons">
    <button (click)="cancel()" class="cancel-button" mat-button>Cancel</button>
    <button [mat-dialog-close]="selectedSet" class="confirm-button" mat-button>Ok</button>
    <div class="delete-button" [hidden]="selectedSet.setName.length < 1">
      <button (click)="delete()" class="confirm-button" mat-button>Delete</button>
    </div>
  </div>
</div>
