import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {SpiderPageComponent} from './spider-page/spider-page.component';

const routes: Routes = [
  {
    path: ':id/:sessionId',
    component: SpiderPageComponent,
    children: [],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class SpiderRoutingModule {
}
