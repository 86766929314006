import {EventEmitter, Injectable} from "@angular/core";

@Injectable({
  providedIn: 'root',
})

export class addNewValuesToPicklists {

  arrayDataEmitter = new EventEmitter<any>();

  receiveArrayName(arrayNameAndData: string[]): any {
    this.arrayDataEmitter.emit(arrayNameAndData);
  }

}
