<h2 class="title" mat-dialog-title>Updating Active Ingredient Set</h2>
<div mat-dialog-content>
  <mat-form-field appearance="fill" class="full-width">
    <mat-label>Set Name</mat-label>
    <input matInput required [(ngModel)]="data.setName">
  </mat-form-field>
  <div [hidden]="data.setName.length > 0">
    Name is required
  </div>
</div>
<div class="dialog-buttons" mat-dialog-actions>
  <button (click)="cancel()" class="cancel-button" mat-button>Cancel</button>
  <button [disabled]="data.setName.length < 1" [mat-dialog-close]="data" class="confirm-button" mat-button>Ok</button>
</div>
