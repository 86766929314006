<div>
  <app-existing-ecotox-endpoints *ngIf="loadingDataHidden"
                                  [allTestGuidelinePicklists]="testGuidelinePicklists"
                                 [allTestGuidelines]="allTestGuidelines"
                                 [documents]="documents"
                                 [receivedAdditionalFieldValues]="additionalFieldValues"
                                 [receivedAllAdditionalFields]="additionalFields"
                                 [receivedAllEndpointUnits]="allEndpointUnits"
                                 [receivedAllEndpoints]="allEndpoints"
                                 [receivedAllOperators]="operators"
                                 [receivedAllStudyTypes]="allStudyTypes"
                                 [receivedAllTestSpecies]="allTestSpecies"
                                 [receivedAllTestSystemTypes]="allTestSystemTypes"
                                 [receivedCategories]="categories"
                                 [receivedCategoryPicklists]="ecotoxCategoryPicklists"
                                 [relatedSubstances]="relatedSubstances"
                                 [substanceTypes]="substanceTypes"
                                 [substance]="substance"
                                 [workAreaId]="workAreaId"
                                 [workAreaName]="workAreaLabel"

  ></app-existing-ecotox-endpoints>

  <div id="categoryHolder">
    <app-ecotox-category #ecoToxCategory *ngFor="let ecoCategory of categories"
                         [allTestGuidelinePicklists]="testGuidelinePicklists"
                         [allTestGuidelines]="allTestGuidelines"
                         [category]="ecoCategory"
                         [dataPicklists]="ecotoxCategoryPicklists"
                         [documents]="documents"
                         [endpointsArrayList]="endpointsArrayList"
                         [operators]="operators"
                         [receivedAdditionalFieldValues]="additionalFieldValues"
                         [receivedAllAdditionalFields]="additionalFields"
                         [receivedAllEndpointUnits]="allEndpointUnits"
                         [receivedAllEndpoints]="allEndpoints"
                         [receivedAllStudyTypes]="allStudyTypes"
                         [receivedAllTestSpecies]="allTestSpecies"
                         [receivedAllTestSystemTypes]="allTestSystemTypes"
                         [relatedSubstances]="relatedSubstances"
                         [substanceTypes]="substanceTypes"
                         [substance]="substance"
                         [workAreaId]="workAreaId"
                         [workAreaLabel]="workAreaLabel"
    ></app-ecotox-category>
  </div>

  <div *ngIf="!loadingDataHidden" class="loadingDataMessage">Loading data...</div>
  <div [hidden]="errorMessage" class="errorMessageHolder" id="errorMessageHolder"></div>
  <div class="controls" id="saveControls">
    <button (click)="save()" [class.spinner]="loading" [disabled]="disabled" class="buttons saveButton" id="saveButton"
            mat-raised-button type="button"><strong>&#10003; </strong>Save Endpoints
    </button>

  </div>

  <div class="spacer"></div>
</div>

