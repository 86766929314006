import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {Row} from "../../models/row";
import {ChartDataset, ChartOptions, ChartType, LegendItem} from "chart.js";
import {VaultService} from "../../service/vault.service";
import {BaseChartDirective} from "ng2-charts";
import {DatasetIndexes} from "../legend-item/legend-item.component";
import {Subscription} from "rxjs";
import domtoimage from 'dom-to-image';
import {ToastrService} from "ngx-toastr";
import * as FileSaver from "file-saver";


@Component({
  selector: 'app-charts',
  templateUrl: './charts.component.html',
  styleUrls: ['./charts.component.css']
})
export class ChartsComponent implements OnInit, OnChanges, OnDestroy {

  @Input() rows: Row[];

  exportClickEventSubscription: Subscription;
  saveChartClickEventSubscription: Subscription;

  greyColor = Array(2).fill('#808080');
  greenColor = Array(9).fill('#9CCF00');
  yellowColor = Array(9).fill('#FFFF00');
  orangeColor = Array(9).fill('#FF9A00');
  redColor = Array(10).fill('#FF0000');

  isLoading: boolean;

  public radarChartOptions: ChartOptions = {
    responsive: true,
    aspectRatio: 1.78,
    devicePixelRatio: 2,
    layout: {
      padding: 20
    },
    plugins: {

      legend: {
        labels: {

          font: {
            size: 14,
          },
          color: 'grey',
          padding: 20,
          usePointStyle: true,
          boxWidth: 12,

        },
        position: 'right',
        align: 'start',
        fullSize: false,
      },
    },
    elements: {
      point: {
        radius: 6,
        hoverRadius: 6,

      },
      line: {
        borderWidth: 3,

      }
    },
    scales: {
      r: {
        beginAtZero: true,
        max: 4,
        min: 0,
        ticks: {
          display: false,
          stepSize: 0.1,
          padding: 10
        },
        pointLabels: {

          font: {
            size: 14,
            lineHeight: 1.5,
            weight: 'bold'
          },

        },
        grid: {
          circular: true,
          drawBorder: true,
          drawTicks: true,
          borderColor: 'black',
          offset: true,
          color: [
            ...this.greyColor,
            ...this.greenColor,
            ...this.yellowColor,
            ...this.orangeColor,
            ...this.redColor,
            'rgba(0,0,0,0)'
          ],
          lineWidth: 10
        },
        angleLines: {
          color: 'rgba(255, 255, 255, 0.5)'
        }
      }
    }
  };

  public barChartOptions: ChartOptions = {
    responsive: true,
    aspectRatio: 3,

    // maintainAspectRatio: false,
    devicePixelRatio: 2,

    plugins: {
      legend: {
        display: false,
        labels: {
          color: 'black',
          font: {
            size: 14,

          },
          padding: 20,
          usePointStyle: false,
          boxWidth: 12,
        },
        position: 'top',
        align: 'start',
        fullSize: false,
      }
    },
    scales: {
      yAxis: {
        grid: {
          color: 'black',
          borderColor: 'black',

        },
        beginAtZero: true,
        min: 0,
        max: 4,
        ticks: {
          maxTicksLimit: 5,
          stepSize: 1,
          font: {
            size: 14,
          },
          callback(value: number): string {
            switch (value) {
              case 0:
                return 'No Data';
              case 1:
                return 'No risk';
              case 2:
                return 'Minor risk';
              case 3:
                return 'Moderate risk';
              case 4:
                return 'High Risk';
              default:
                return 'No Data';
            }
          },
          color: 'black',
        }
      },
      xAxis: {
        grid: {
          // color: 'black',
          // borderColor: 'black',
          display: false,

        },
        pointLabels: {
          color: 'black',

        },

        ticks: {

          color: 'black',
          maxRotation: 60,
          minRotation: 60,
          font: {
            size: 12,
          }
        }
      }
    }
  };

  @ViewChild(BaseChartDirective) private radarChart: BaseChartDirective;

  public chartLabels: string[] = [];

  public chartData: ChartDataset[] = [];

  public chartType: ChartType;

  public plugins: any;

  public colorMap: any;

  public radarLegendItems: LegendItem[];
  public barLegendItems: LegendItem[];


  constructor(
    private service: VaultService,
    private toastrService: ToastrService,
  ) {
    this.exportClickEventSubscription = this.service.getExportClickEvent().subscribe(() => {
      this.exportToExcel();
    })
    this.saveChartClickEventSubscription = this.service.getSaveChartClickEvent().subscribe(() => {
      this.saveChartToLocalStorage();
    })


  }

  ngOnInit(): void {
    this.chartType = 'radar';
    this.isLoading = true;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!changes.rows.firstChange) {
      this.chartLabels = this.service.getChartLabels(this.rows);
      this.service.getChartData(this.rows).subscribe(result => {
        this.chartData = result;
      }, error => {
        console.log('error receiving chart data ', error);
      }, () => {
        this.barLegendItems = this.getLegendItems(this.chartData, 'bar');
        this.radarLegendItems = this.getLegendItems(this.chartData, 'radar');
      });
      this.updateScales();
      this.isLoading = false;
    }
  }

  ngOnDestroy() {
    this.exportClickEventSubscription.unsubscribe();
    this.saveChartClickEventSubscription.unsubscribe();
  }


  async saveChartToLocalStorage(): Promise<any> {
    this.saveSelectedLegendItems();
    let chartContainer = document.getElementById(this.chartType + '-chart');
    console.log('chart type: ', this.chartType);
    try {
      const dataUrl = await domtoimage.toPng(chartContainer, {quality: 1.0, bgcolor: '#ffffff'})
      sessionStorage.setItem(this.chartType + '-chart.png', this.chartType + dataUrl);
    } catch (error) {
      if (error) {
        this.toastrService.error(error.message, 'Error saving chart')
      }
    }
    let message = this.chartType + ' chart successfully saved.';
    const title = 'Success';
    const toastId = this.showSuccessMessage(message, title);
    setTimeout(() => {
      this.toastrService.clear(toastId);
    }, 2000);

  }

  showSuccessMessage(message: string, title: string): number {
    return this.toastrService.success(message, title, {
      timeOut: 1500,
      extendedTimeOut: 500,
      tapToDismiss: true,
    }).toastId;
  }


  async exportToExcel() {
    const today = new Date();
    const fileName = 'Comparing ' + this.service.evaluations[0].name__v + '-' + today.getFullYear() + '-' +
      (today.getMonth() + 1) + '-' + today.getDate();
    let radarBase = sessionStorage.getItem('radar-chart.png');
    let barBase = sessionStorage.getItem('bar-chart.png');
    if (radarBase === null && this.chartType === 'radar') {
      console.log('awaiting save chart');
      await this.saveChartToLocalStorage()
      radarBase = sessionStorage.getItem('radar-chart.png');
    } else if (barBase === null && this.chartType === 'bar') {
      await this.saveChartToLocalStorage()
      barBase = sessionStorage.getItem('bar-chart.png')
    }
    this.service.exportToExcel(radarBase, barBase).subscribe(response => {
      let blob = new Blob([response], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8"'});
      FileSaver.saveAs(blob, fileName + '.xlsx');
    })
    sessionStorage.clear();
  }


  getSelectedLegendItems(legendItems: LegendItem[]): LegendItem[] {
    return legendItems.filter(item => {
      if (item.hidden == false) {
        return item;
      }
    });
  }

  updateSelectedData(event: DatasetIndexes, index: number) {
    let previousIndex = event.previousDataIndex;
    let currentIndex = event.currentDataIndex;
    if (previousIndex != undefined) {
      this.chartData[previousIndex].hidden = true;
      if (this.chartType === 'bar') {
        this.barLegendItems[previousIndex].hidden = true;
      } else {
        this.radarLegendItems[previousIndex].hidden = true;
      }
    }
    if (previousIndex != undefined && currentIndex === undefined) {
      this.chartData[previousIndex].hidden = true;
    }
    if (currentIndex != undefined) {
      this.chartData[currentIndex].hidden = false;


      if (this.chartType === 'bar') {
        this.chartData[currentIndex].backgroundColor = this.service.barChartColors[index];
        this.chartData[currentIndex].borderColor = this.service.barChartColors[index];
        this.chartData[currentIndex]['pointBackgroundColor'] = this.service.barChartColors[index];
        this.barLegendItems[currentIndex].fillStyle = this.service.barChartColors[index];
      } else {
        this.chartData[currentIndex].backgroundColor = 'rgba(0,0,0,0)';
        this.chartData[currentIndex].borderColor = this.service.radarChartColors[index];
        this.chartData[currentIndex]['pointBackgroundColor'] = this.service.radarChartColors[index];
        this.chartData[currentIndex]['pointBorderColor'] = 'rgba(255,255,255,1)';
        this.radarLegendItems[currentIndex].fillStyle = this.service.radarChartColors[index];
      }
    }
    this.saveSelectedLegendItems();
    this.radarChart.update();

  }

  saveSelectedLegendItems() {
    if (this.chartType === 'bar' && this.barLegendItems != undefined) {
      this.service.barLegendItems = this.getSelectedLegendItems(this.barLegendItems);
    } else if (this.chartType === 'radar' && this.radarLegendItems != undefined) {
      this.service.radarLegendItems = this.getSelectedLegendItems(this.radarLegendItems);
    }
  }

  getLegendItems(data: ChartDataset[], chartType: string): LegendItem[] {
    let legendItems: LegendItem[] = [];
    data.forEach((dataset, index) => {
      let legendItem = {} as LegendItem;
      // legendItem.hidden = dataset.hidden;
      legendItem.text = dataset.label;
      legendItem.datasetIndex = index;
      legendItem.fontColor = 'black';
      if (chartType === 'radar') {
        legendItem.fillStyle = this.service.radarChartColors[index];
        legendItem.hidden = index >= 2;
      } else {
        legendItem.fillStyle = this.service.barChartColors[index];
        legendItem.hidden = index >= 4;
      }

      legendItems.push(legendItem);
    })
    this.service.selectedLegendItems.push(legendItems[0]);
    this.service.selectedLegendItems.push(legendItems[1]);
    return legendItems;
  }


  updateScales() {
    this.colorMap = this.getColorMap();
    let colorMap = this.colorMap;
    let categoriesNum = this.chartLabels.length;
    this.radarChartOptions.scales = {
      r: {
        beginAtZero: true,
        max: 4,
        min: 0,
        ticks: {
          display: false,
          stepSize: 0.1,
        },
        pointLabels: {
          callback: function (label, index) {
            let minThreshold = 0;
            let half;
            if (categoriesNum % 2 === 0) {
              half = Math.floor(categoriesNum / 2);
            } else {
              half = Math.floor(categoriesNum / 2) + 1;
            }
            if (index === minThreshold) {
              return [label, '', ''];
            } else if (index === (minThreshold + 1)) {
              return [label, ''];
            } else if (index > 1 && index < (half - 1)) {
              return label;
            } else if (index === (half - 1)) {
              return ['', label];
            } else if (index === half) {
              return ['', '', label];
            } else if (index === (half + 1)) {
              return ['', label];
            } else if (index > (half + 1) && index < (categoriesNum - 1)) {
              return label;
            } else {
              return [label, '']
            }

          },
          font: {
            size: 16,
            lineHeight: 1.5,
            weight: 'bold',

          },
          color: function (context) {
            if (Array.isArray(context.label)) {
              for (let label of context.label) {
                if (label != '') {
                  return colorMap[label];
                }
              }
            } else {
              return colorMap[context.label];
            }

          },

        },
        grid: {
          circular: true,
          drawBorder: true,
          drawTicks: true,
          borderColor: 'black',
          offset: true,
          color: [
            ...this.greyColor,
            ...this.greenColor,
            ...this.yellowColor,
            ...this.orangeColor,
            ...this.redColor,
            'rgba(0,0,0,0)'
          ],
          lineWidth: 10
        },
        angleLines: {
          color: 'rgba(255, 255, 255, 0.5)'
        }
      }
    }

  }


  getColorMap(): Object {
    let color = {};
    this.rows.forEach(row => {
      if (row.workArea === 'Environmental Fate') {
        color[row.category] = 'black';
      } else if (row.workArea === 'Ecotoxicology') {
        color[row.category] = '#555555';
      } else if (row.workArea === 'Consumer Safety') {
        color[row.category] = 'black';
      } else if (row.workArea === 'Toxicology' && row.category != 'PBT Assessment') {
        color[row.category] = '#555555';
      } else {
        color[row.category] = 'grey';
      }
    });
    return color;
  }

  updateChartData(type: ChartType) {
    if (type === 'bar') {
      this.updateChartColors('bar', this.chartData);
      this.chartData.forEach((dataSet, index) => {
        // this.legendItems[index].fillStyle = this.service.barChartColors[index];
        dataSet.hidden = index >= 4;
        dataSet.data = dataSet.data.map(dataPoint => {
          if (dataPoint === 0) {
            return dataPoint;
          } else {
            return Number(dataPoint) + 0.5;
          }
        })
        dataSet.hidden = index > 3;
      });
    } else {
      this.updateChartColors('radar', this.chartData);
      this.chartData.forEach((dataSet, index) => {
        // this.legendItems[index].fillStyle = this.service.radarChartColors[index];
        dataSet.hidden = index >= 2;
        dataSet.data = dataSet.data.map(dataPoint => {
          if (dataPoint === 0) {
            return dataPoint;
          } else {
            return Number(dataPoint) - 0.5;
          }
        })
        dataSet.hidden = index > 1;
      })
    }
    this.updateLegendItems(type);
  }

  updateLegendItems(type: ChartType) {
    if (type === 'bar') {
      this.barLegendItems.forEach((item, index) => {
        item.hidden = index >= 4;
      });
    } else {
      this.radarLegendItems.forEach((item, index) => {
        item.hidden = index >= 2;
      })
    }
  }

  updateChartColors(type: ChartType, data: ChartDataset[]) {
    if (type === 'bar') {
      for (let i = 0; i < data.length; i++) {
        data[i].backgroundColor = this.service.barChartColors[i];
      }
    } else {
      data.forEach(dataSet => {
        dataSet.backgroundColor = 'rgb(0,0,0,0)';
      })
    }
  }


}
