<div>

  <div *ngIf="showExistingRecord" class="fieldsHolder" id="viewRecord">

    <div class="copyHolder">
      <button (click)="copyExistingEndpoint(this.existingEndpointRecord)" class="copy" data-cy="CopyEndpoint"
              title="Copy Endpoint"><span class="material-icons">content_copy</span></button>
    </div>

    <form [formGroup]="selectFormGroup">

      <div (click)="enableEditing()" class="singleFieldContainer">
        <div class="formItemTitle">Category:</div>
        <div (click)="enableEditing()">
          <select class="formSelector" name="category" formControlName="category__c">
            <option value="{{category.id}}" selected>{{category.name__v}}</option>
          </select>
        </div>
      </div>

      <div class="singleFieldContainer">
        <div class="formItemTitle">Source:</div>
        <div (click)="enableEditing()">
          <select class="disabledDocumentFormSelector" formControlName="source" name="source"
                  title="document_unbound__c">
            <option *ngFor="let document of documents" value="{{ document.id }}">{{ document.name }}</option>
          </select>
        </div>
      </div>

      <div class="singleFieldContainer">
        <div class="formItemTitle">Substance Type:</div>
        <div (click)="enableEditing()">
          <select class="formSelector" formControlName="substanceType" name="substanceType">
            <option value=""></option>
            <option *ngFor="let substaneType of substanceTypes"
                    [value]="substaneType.name">{{ substaneType.label }}</option>
          </select>
        </div>
      </div>

      <div class="singleFieldContainer">
        <div class="formItemTitle">Related Substance:</div>
        <div (click)="enableEditing()">
          <select class="formSelector" formControlName="relatedSubstance" name="relatedSubstance"
                  title="related_substance__c">
            <option selected value=""></option>
            <option *ngFor="let relSubstance of relatedSubstances"
                    value="{{relSubstance.id}}">{{relSubstance.name}}</option>
          </select>
        </div>
      </div>

      <div class="singleFieldContainer">
        <div class="formItemTitle">Study Type:</div>
        <div (click)="enableEditing()">
          <select class="formSelector" formControlName="studyType" name="study-type">
            <option value=""></option>
            <option *ngFor="let listedStudyType of allStudyTypes" [title]="listedStudyType.picklist"
                    [value]="listedStudyType.name">{{ listedStudyType.label }}</option>
          </select>
        </div>
      </div>

      <div class="singleFieldContainer">
        <div class="formItemTitle">Test System Type:</div>
        <div (click)="enableEditing()">
          <select class="formSelector"
                  formControlName="test_system_type__c" title="test_system_type__c">
            <option value=""></option>
            <option *ngFor="let testSystemType of allTestSystemTypes" [title]="testSystemType.picklist"
                    [value]="testSystemType.name">{{ testSystemType.label }}</option>
          </select>
        </div>
      </div>

      <div class="singleFieldContainer">
        <div class="formItemTitle">Route of Administration:</div>
        <div (click)="enableEditing()">
          <select class="formSelector"
                  formControlName="route_of_administration__c" title="route_of_administration__c">
            <option value=""></option>
            <option *ngFor="let routeOfAdmin of allRouteOfAdministration" [title]="routeOfAdmin.picklist"
                    [value]="routeOfAdmin.name">{{ routeOfAdmin.label }}</option>
          </select>
        </div>
      </div>

      <div class="singleFieldContainer">
        <div class="formItemTitle">Test Species:</div>
        <div (click)="enableEditing()">
          <select class="formSelector"
                  formControlName="test_system_name__c" title="test_system_name__c">
            <option value=""></option>
            <option *ngFor="let testSystemName of allTestSystemNames" [title]="testSystemName.picklist"
                    [value]="testSystemName.name">{{ testSystemName.label }}</option>
          </select>
        </div>
      </div>

      <div class="singleFieldContainer">
        <div class="formItemTitle">Endpoint:</div>
        <div (click)="enableEditing()">
          <select class="formSelector" formControlName="endpoint" name="endpoint">
            <option value=""></option>
            <option *ngFor="let endpoint of allEndpoints" [title]="endpoint.picklist"
                    value="{{ endpoint.name }}">{{ endpoint.label }}</option>
          </select>
        </div>
      </div>

      <div class="singleFieldContainer">
        <div class="formItemTitle">Endpoint Unit:</div>
        <div (click)="enableEditing()">
          <select class="formSelector" formControlName="endpointUnit" name="endpointUnit">
            <option value=""></option>
            <option *ngFor="let endpointUnit of allEndpointUnits" [title]="endpointUnit.picklist"
                    value="{{ endpointUnit.name }}">{{ endpointUnit.label }}</option>
          </select>
        </div>
      </div>

      <div class="singleFieldContainer">
        <div class="formItemTitle">Operator:</div>
        <div (click)="enableEditing()">
          <select class="formSelector" formControlName="operator" name="operator">
            <option value=""></option>
            <option *ngFor="let operator of operators" value="{{ operator.name }}">{{ operator.label }}</option>
          </select>
        </div>
      </div>

      <div class="singleFieldContainer" [hidden]="isValue1Disabled">
        <div class="formItemTitle">Value 1:</div>
        <input class="disabledNumberSelector" formControlName="value_1__c"
               name="value_1__c" placeholder="Number" type="number"/>
      </div>

      <div class="singleFieldContainer" [hidden]="isValue2Disabled">
        <div class="formItemTitle">Value 2:</div>
        <input class="disabledNumberSelector" formControlName="value_2__c"
               name="value_2__c" placeholder="Number 2" type="number"/>
      </div>

      <div *ngFor="let field of additionalFields" [formGroup]="selectFormGroup" class="singleFieldContainer">
        <div class="formItemTitle">{{field.label}}:</div>

        <div (click)="enableEditing()" [ngSwitch]="field.type">
          <textarea *ngSwitchCase="'text'" [formControlName]="field.name" [title]="field.name"
                    class="disabledFormFreeText" rows="1"></textarea>
          <input *ngSwitchCase="'number'" [formControlName]="field.name" [title]="field.name"
                 class="disabledNumberSelector" type="number">
          <select *ngSwitchCase="'picklist'" [formControlName]="field.name" [title]="field.name" class="formSelector">
            <option value=""></option>
            <option *ngFor="let opt of filterAdditionalFieldValues(allAdditionalFieldValues, field.picklist)"
                    [value]="opt.name">{{opt.label}}</option>
          </select>

          <mat-form-field *ngSwitchCase="'multi-picklist'" class="multiPicklistFormSelector">
            <mat-select
              [formControlName]="field.name"
              [title]="field.name"
              class="formSelector"
              multiple ngDefaultControl>
              <mat-option *ngFor="let topping of filterAdditionalFieldValues(allAdditionalFieldValues, field.picklist)"
                          [value]="topping.name">{{topping.label}}
              </mat-option>
            </mat-select>
          </mat-form-field>

        </div>
      </div>

      <div class="rightConnerView">
        <div class="singleFieldContainer">
          <div class="authorInfo">Created by {{createdBy[0].name}}
            <br>at {{formatCreatedDate}}
          </div>
        </div>

        <div class="singleFieldContainer">
          <div class="authorInfo">Last Modified by {{modifiedBy[0].name}}
            <br>at {{formatModifiedDate}}
          </div>
        </div>
      </div>

    </form>

  </div>

  <div *ngIf="showEditRecord" class="editableFieldsHolder" id="editable">
    <app-stot
      (cancelEdit)="cancelEditing()"
      (saveChanges)="saveChanges($event)"
      [singleEndpointRecord]="existingEndpointRecord"
      [purpose]="purposeMessage"
      [category]="category"
      [substanceTypes]="substanceTypes"
      [relatedSubstances]="relatedSubstances"
      [allStudyTypes]="allStudyTypes"
      [allTestSystemTypes]="allTestSystemTypes"
      [allRouteOfAdministration]="allRouteOfAdministration"
      [allTestSystemNames]="allTestSystemNames"
      [allEndpoints]="allEndpoints"
      [allEndpointUnits]="allEndpointUnits"
      [allOperators]="allOperators"
      [allAdditionalFields]="allAdditionalFields"
      [allAdditionalFieldValues]="allAdditionalFieldValues"
      [documents]="documents"
    ></app-stot>
  </div>

</div>
