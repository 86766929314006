import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class EndpointServicesService {
  private documentSource = new BehaviorSubject<any>('none');
  currentDocument = this.documentSource.asObservable();

  private userProfileSource = new BehaviorSubject<any>('none');
  userProfile = this.userProfileSource.asObservable();

  constructor() {
  }

  changeDocument(document): any {
    this.documentSource.next(document);
  }

  setUserprofile(userProfile): any {
    this.userProfileSource.next(userProfile);
  }
}
