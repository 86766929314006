<div class="container">
  <h2 class="title" mat-dialog-title>Saving Active Ingredient Set</h2>
  <div mat-dialog-content>
    <mat-form-field appearance="fill" class="full-width">
      <mat-label class="full-width">Set Name</mat-label>
      <input matInput required [(ngModel)]="data.setName">
    </mat-form-field>

    <div class="checkbox-container">
      <mat-checkbox (change)="isShared = !isShared"
                    (keydown.enter)="isShared = !isShared"
                    [checked]="isShared"
      >Share Set
      </mat-checkbox>
    </div>
    <div class="share-set-input" *ngIf="isShared">
      <ng-select class="substance-search"
                 appearance="fill"
                 [items]="users"
                 bindLabel="user_name__v"
                 bindValue="id"
                 [multiple]="true"
                 appendTo="body"
                 [closeOnSelect]="false"
                 [(ngModel)]="selectedIds"
                 (ngModelChange)="updateSets()">
      </ng-select>
    </div>

  </div>
  <div mat-dialog-actions class="dialog-buttons">
    <button (click)="cancel()" class="cancel-button" mat-button>Cancel</button>
    <button [disabled]="data.setName.length < 1" [mat-dialog-close]="setsToSave" class="save-button" mat-button>Ok
    </button>
  </div>
</div>
