import {Component, Input, OnInit, QueryList, ViewChildren} from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';
import {ActivatedRoute} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {ExceptionService} from 'src/app/core/exception.service';
import {ServerService} from 'src/app/core/server.service';
import {PicklistValue} from '../../models/picklist-value';
import {EcotoxCategoryPicklists} from '../models/ecotox-category-picklists';
import {CreateObjectResponse, CreateObjectsResponse} from '../../models/create-objects-response';
import {VaultError} from '../../../core/models/vault-error';
import {SubstanceItem} from '../../models/substance-item';
import {EcotoxAdditionalField} from '../models/ecotox-additional-field';
import {RelatedSubstances} from '../../models/related-substances';
import {DocumentMenuItem} from '../../models/document-menu-item';
import {EcotoxCategoryComponent} from '../ecotox-category/ecotox-category.component';
import {EcotoxEndpointTableItem} from '../models/ecotox-endpoint-table-item';
import {TestGuidelines} from '../../models/test-guidelines';

let me: EcotoxComponent;

@Component({
  selector: 'app-ecotox',
  templateUrl: './ecotox.component.html',
  styleUrls: ['./ecotox.component.css'],
})
export class EcotoxComponent implements OnInit {
  @Input() substance: SubstanceItem;
  @Input() workAreas: Array<PicklistValue>;
  @Input() workAreaLabel: string;
  @Input() relatedSubstances: Array<RelatedSubstances>;
  @Input() documents: Array<DocumentMenuItem>;
  @Input() substanceTypes: Array<PicklistValue>;
  @ViewChildren('ecoToxCategory') ecoToxCategory: QueryList<EcotoxCategoryComponent>;
  loading = false;
  disabled = true;
  errorMessage = true;
  public loadingDataHidden: boolean;
  subscriptions: any;
  public currentRequestsNo: number;
  public totalNumberOfRequests: number;
  public workAreaId: string;
  public endpointsArrayList: Array<EcotoxEndpointTableItem>;
  public categories: Array<PicklistValue>;
  public ecotoxCategoryPicklists: Array<EcotoxCategoryPicklists>;
  public operators: Array<PicklistValue>;
  public allEndpoints: Array<PicklistValue>;
  public allStudyTypes: Array<PicklistValue>;
  public allEndpointUnits: Array<PicklistValue>;
  public allTestSystemTypes: Array<PicklistValue>;
  public allTestSpecies: Array<PicklistValue>;
  public testGuidelinePicklists: Array<TestGuidelines>;
  public uniqueTestGuidelinePicklist: Array<TestGuidelines>;
  public allTestGuidelines: Array<PicklistValue>;
  public additionalFields: Array<EcotoxAdditionalField>;
  public additionalPicklistFields: Array<EcotoxAdditionalField>;
  public uniqueAdditionalPicklist: Array<EcotoxAdditionalField>;
  public additionalFieldValues: Array<EcotoxAdditionalField>;
  public successRecords: any[];
  public attemptNumber: number;
  public testGuidelineGroupAttemptNo: number;
  private sessionId: string;
  private haveErrors: Boolean;

  constructor(
    private route: ActivatedRoute,
    private serverService: ServerService,
    private exceptionService: ExceptionService,
    private toastrService: ToastrService
  ) {
    me = this;
    this.workAreaId = '';
    this.subscriptions = {};
    this.endpointsArrayList = [];
    this.categories = [];
    this.ecotoxCategoryPicklists = [];
    this.route.paramMap.subscribe((params) => {
      this.sessionId = params.get('sessionId');
    });
    this.subscriptions = {};
    this.operators = [];
    this.allEndpoints = [];
    this.allStudyTypes = [];
    this.allEndpointUnits = [];
    this.allTestSystemTypes = [];
    this.allTestSpecies = [];
    this.testGuidelinePicklists = [];
    this.uniqueTestGuidelinePicklist = [];
    this.allTestGuidelines = [];
    this.additionalFields = [];
    this.additionalPicklistFields = [];
    this.uniqueAdditionalPicklist = [];
    this.additionalFieldValues = [];
    this.loadingDataHidden = false;
    this.currentRequestsNo = 0;
    this.totalNumberOfRequests = 0;
    this.successRecords = [];
    this.attemptNumber = 0;
    this.testGuidelineGroupAttemptNo = 0;
  }

  ngOnInit(): void {
    const workAreaObject = this.workAreas.filter(obj => {
      return obj.name__v === this.workAreaLabel;
    });
    this.workAreaId = workAreaObject[0].id;
    this.getAllGroupPicklistNames();
    this.getOperators();
    this.retrieveAdditionalFields();
    this.fetchTestGuidelines();
  }

  fetchTestGuidelines(): void {
    this.subscriptions.test = this.serverService
      .getTestGuidelines(this.sessionId)
      .subscribe(
        (response: any) => {
          if (response.data && response.data.length > 0) {
            response.data.forEach((item: any) => {
              const TestGuideline = {
                categoryName: item?.category__c,
                studyType: item?.study_type_record__c[0],
                testGuidelineGroup: item?.test_guideline_group_picklist__c,
              };
              this.testGuidelinePicklists.push(TestGuideline);
            });
          }
        },
        (error: HttpErrorResponse) => {
          if (this.testGuidelineGroupAttemptNo === 3) {
            this.exceptionService.handleError(error);
            this.toastrService.error(
              `Unable to get Test Guideline group picklist for Study Type record.`,
              'Error'
            );
            alert('Cannot fetch Test Guideline groups! Please reload the page!');
          } else {
            this.testGuidelineGroupAttemptNo = this.testGuidelineGroupAttemptNo + 1;
            this.fetchTestGuidelines();
          }
        },
        () => {
          if (this.testGuidelineGroupAttemptNo === 0) {
            this.unifyTestGuidelines();
          }
        }
      );
  }

  unifyTestGuidelines(): void {
    // remove duplicates
    const array = this.testGuidelinePicklists;

    const map = new Map();
    for (const item of array) {
      if (!map.has(item.testGuidelineGroup)) {
        map.set(item.testGuidelineGroup, true); // set any value to Map
        this.uniqueTestGuidelinePicklist.push({
          categoryName: item?.categoryName,
          studyType: '',
          testGuidelineGroup: item?.testGuidelineGroup,
        });
      }
    }
    this.callTestGuidelines();
  }

  callTestGuidelines(): void {
    this.uniqueTestGuidelinePicklist.forEach((testGuidelinesPicklistsT: TestGuidelines) => {
      this.getTestGuidelineRecords(testGuidelinesPicklistsT);
    });
  }


  getTestGuidelineRecords(testGuidelinesPicklists: TestGuidelines): void {
    this.subscriptions[
      testGuidelinesPicklists.testGuidelineGroup
      ] = this.serverService
      .getPicklist(this.sessionId, testGuidelinesPicklists.testGuidelineGroup)
      .subscribe(
        (response) => {
          if (response.picklistValues && response.picklistValues.length > 0) {
            response.picklistValues.forEach((element: any) => {
              const entry = {
                name__v: '',
                id: '',
                name: element.name,
                label: element.label,
                picklist: testGuidelinesPicklists.testGuidelineGroup,
              };
              this.allTestGuidelines.push(entry);
            });
          }
        },
        (error: HttpErrorResponse) => {
          this.exceptionService.handleError(error);
          this.toastrService.error(
            `Unable to get Test Guidelines for ${testGuidelinesPicklists.testGuidelineGroup}.`,
            'Error'
          );
        },
      );
  }

  getAllGroupPicklistNames(): void {
    this.serverService.isLoading();
    this.subscriptions.getEcotoxicologyCategoryPicklists = this.serverService
      .getCategoryPicklists(this.sessionId, this.workAreaId)
      .subscribe(
        (response: any) => {
          if (response.data && response.data.length > 0) {
            response.data.forEach((element) => {
              this.ecotoxCategoryPicklists.push({
                categoryId: element.category__c || null,
                studyTypePicklist: element.study_type__c || null,
                endpointPicklist: element.endpoint__c || null,
                endpointUnitPicklist: element.endpoint_unit__c || null,
                testSystemTypesPicklist: element.test_system_type__c || null,
                testSpeciesPicklist: element.test_species__c || null,
              });
            });
          }
        },
        (error: HttpErrorResponse) => {
          this.exceptionService.handleError(error);
          this.toastrService.error(
            'Unable to get category picklists.',
            'Error'
          );
        },
        () => {
          const numberOfPicklistRows = this.ecotoxCategoryPicklists.length;
          // for each group of data (row or record * field) * 5:
          // 1 = Study Type, 2 = Endpoint, 3 = Endpoint Unit, 4 = Test System Type, 5 = Test Species
          this.totalNumberOfRequests = numberOfPicklistRows * 5;
          this.getAllPicklistValues();
        }
      );
  }

  getAllPicklistValues(): void {
    this.ecotoxCategoryPicklists.forEach((groupPicklistsT: EcotoxCategoryPicklists) => {
      this.getStudyTypesForGroup(groupPicklistsT);
      this.getEndpointsForGroup(groupPicklistsT);
      this.getEndpointUnitsForGroup(groupPicklistsT);
      this.getTestSystemTypesForGroup(groupPicklistsT);
      this.getTestSpeciesForGroup(groupPicklistsT);
    });
  }

  getStudyTypesForGroup(groupPicklists: EcotoxCategoryPicklists): void {
    this.subscriptions[
      groupPicklists.studyTypePicklist
      ] = this.serverService
      .getPicklist(this.sessionId, groupPicklists.studyTypePicklist)
      .subscribe(
        (response) => {
          if (response.picklistValues && response.picklistValues.length > 0) {
            response.picklistValues.forEach((element: any) => {
              const entry = {
                name__v: '',
                id: groupPicklists.categoryId,
                name: element.name,
                label: element.label,
                picklist: groupPicklists.studyTypePicklist,
              };
              this.allStudyTypes.push(entry);
            });
          }
        },
        (error: HttpErrorResponse) => {
          this.exceptionService.handleError(error);
          this.toastrService.error(
            `Unable to get study types for ${groupPicklists.categoryId}.`,
            'Error'
          );
        },
        () => {
          this.currentRequestsNo = this.currentRequestsNo + 1;

          if (this.currentRequestsNo === this.totalNumberOfRequests) {
            this.getCategoriesForWorkArea();
          }
        }
      );
  }

  getEndpointsForGroup(groupPicklists: EcotoxCategoryPicklists): void {
    this.subscriptions[
      groupPicklists.endpointPicklist
      ] = this.serverService
      .getPicklist(this.sessionId, groupPicklists.endpointPicklist)
      .subscribe(
        (response) => {
          if (response.picklistValues && response.picklistValues.length > 0) {
            response.picklistValues.forEach((element: any) => {
              const entry = {
                name__v: '',
                id: groupPicklists.categoryId,
                name: element.name,
                label: element.label,
                picklist: groupPicklists.endpointPicklist,
              };
              this.allEndpoints.push(entry);
            });
          }
        },
        (error: HttpErrorResponse) => {
          this.exceptionService.handleError(error);
          this.toastrService.error(
            `Unable to get endpoints for ${groupPicklists.categoryId}.`,
            'Error'
          );
        },
        () => {
          this.currentRequestsNo = this.currentRequestsNo + 1;

          if (this.currentRequestsNo === this.totalNumberOfRequests) {
            this.getCategoriesForWorkArea();
          }
        }
      );
  }

  getEndpointUnitsForGroup(groupPicklists: EcotoxCategoryPicklists): void {
    this.subscriptions[
      groupPicklists.endpointUnitPicklist
      ] = this.serverService
      .getPicklist(this.sessionId, groupPicklists.endpointUnitPicklist)
      .subscribe(
        (response) => {
          if (response.picklistValues && response.picklistValues.length > 0) {
            response.picklistValues.forEach((element: any) => {
              const entry = {
                name__v: '',
                id: groupPicklists.categoryId,
                name: element.name,
                label: element.label,
                picklist: groupPicklists.endpointUnitPicklist,
              };
              this.allEndpointUnits.push(entry);
            });
          }
        },
        (error: HttpErrorResponse) => {
          this.exceptionService.handleError(error);
          this.toastrService.error(
            `Unable to get endpoint units for ${groupPicklists.categoryId}.`,
            'Error'
          );
        },
        () => {

          this.currentRequestsNo = this.currentRequestsNo + 1;

          if (this.currentRequestsNo === this.totalNumberOfRequests) {
            this.getCategoriesForWorkArea();
          }
        }
      );
  }

  getTestSystemTypesForGroup(groupPicklists: EcotoxCategoryPicklists): void {
    this.subscriptions[
      groupPicklists.testSystemTypesPicklist
      ] = this.serverService
      .getPicklist(this.sessionId, groupPicklists.testSystemTypesPicklist)
      .subscribe(
        (response) => {
          if (response.picklistValues && response.picklistValues.length > 0) {
            response.picklistValues.forEach((element: any) => {
              const entry = {
                name__v: '',
                id: groupPicklists.categoryId,
                name: element.name,
                label: element.label,
                picklist: groupPicklists.testSystemTypesPicklist,
              };
              this.allTestSystemTypes.push(entry);
            });
          }
        },
        (error: HttpErrorResponse) => {
          // re-try
          if (this.attemptNumber === 2) {
            this.exceptionService.handleError(error);
            this.toastrService.error(
              `Unable to get Test System Types for ${groupPicklists.categoryId}.`,
              'Error'
            );
          } else {
            this.attemptNumber = this.attemptNumber + 1;
            this.additionalFieldValues = []; // clear list
            this.getTestSystemTypesForGroup(groupPicklists);
          }
        },

        () => {
          this.currentRequestsNo = this.currentRequestsNo + 1;

          if (this.currentRequestsNo === this.totalNumberOfRequests) {
            this.getCategoriesForWorkArea();
          }
        }
      );
  }

  getTestSpeciesForGroup(groupPicklists: EcotoxCategoryPicklists): void {
    this.subscriptions[
      groupPicklists.testSpeciesPicklist
      ] = this.serverService
      .getPicklist(this.sessionId, groupPicklists.testSpeciesPicklist)
      .subscribe(
        (response) => {
          if (response.picklistValues && response.picklistValues.length > 0) {
            response.picklistValues.forEach((element: any) => {
              const entry = {
                name__v: '',
                id: groupPicklists.categoryId,
                name: element.name,
                label: element.label,
                picklist: groupPicklists.testSpeciesPicklist,
              };
              this.allTestSpecies.push(entry);
            });
          }
        },
        (error: HttpErrorResponse) => {
          this.exceptionService.handleError(error);
          this.toastrService.error(
            `Unable to get Test Species for ${groupPicklists.categoryId}.`,
            'Error'
          );
        },
        () => {
          this.currentRequestsNo = this.currentRequestsNo + 1;

          if (this.currentRequestsNo === this.totalNumberOfRequests) {
            this.getCategoriesForWorkArea();
          }
        }
      );
  }

  getOperators(): void {
    this.subscriptions.getOperators = this.serverService
      .getOperators(this.sessionId)
      .subscribe(
        (response) => {
          if (response.picklistValues && response.picklistValues.length > 0) {
            response.picklistValues.forEach((singleOperator) => {
              this.operators.push({
                name__v: '',
                id: '',
                name: singleOperator.name,
                label: singleOperator.label,
                picklist: '',
              });
            });
          }
        },
        (error: HttpErrorResponse) => {
          this.exceptionService.handleError(error);
          this.toastrService.error('Unable to get operators.', 'Error');
        },
      );
  }

  retrieveAdditionalFields(): void {
    this.subscriptions.test = this.serverService
      .getAllAdditionalFields(this.sessionId, this.workAreaId)
      .subscribe(
        (response: any) => {
          if (response.data && response.data.length > 0) {
            response.data.forEach((item: any) => {
              const additionalField = {
                categoryId: item?.category__c,
                name: item?.save_to_field__c ?? 'unknown_field',
                label: item?.field_label__c ?? 'Unknown field',
                type: item.field_type__c ?? 'text',
                picklist: item?.source__c,
                saveTo: item?.save_to_field__c,
                group: item?.study_group__c,
                hidden: item?.initially_hidden__c[0],
                sortNumber: item?.sort_number__c,
              };
              this.additionalFields.push(additionalField);
            });
          }
        },
        (error: HttpErrorResponse) => {
          this.exceptionService.handleError(error);
          this.toastrService.error(
            `Unable to get additional fields for an endpoint.`,
            'Error'
          );
        },
        () => {
          this.uniqueAdditionalSource();
        }
      );
  }

  uniqueAdditionalSource(): void {
    // filter only picklist fields
    this.additionalPicklistFields = this.additionalFields.filter(
      (a) => a.type === 'picklist'
    );

    // remove duplicates
    const array = this.additionalPicklistFields;
    const map = new Map();
    for (const item of array) {
      if (!map.has(item.picklist)) {
        map.set(item.picklist, true); // set any value to Map
        this.uniqueAdditionalPicklist.push({
          categoryId: item.categoryId,
          label: item.label,
          name: item.name,
          type: item.type,
          picklist: item.picklist,
          saveTo: item.saveTo,
          group: item.group,
          hidden: item.hidden,
          sortNumber: item.sortNumber,
        });
      }
    }
    this.uniqueAdditionalMultiSelectSource();
  }

  uniqueAdditionalMultiSelectSource(): void {
    // filter only multi-picklist fields
    this.additionalPicklistFields = this.additionalFields.filter(
      (b) => b.type === 'multi-picklist'
    );

    // remove duplicates
    const array = this.additionalPicklistFields;
    const map = new Map();
    for (const item of array) {
      if (!map.has(item.picklist)) {
        map.set(item.picklist, true); // set any value to Map
        this.uniqueAdditionalPicklist.push({
          categoryId: item.categoryId,
          label: item.label,
          name: item.name,
          type: item.type,
          picklist: item.picklist,
          saveTo: item.saveTo,
          group: item.group,
          hidden: item.hidden,
          sortNumber: item.sortNumber,
        });
      }
    }
    this.getValuesForAdditionalFieldPicklists();
  }

  getValuesForAdditionalFieldPicklists(): void {
    this.uniqueAdditionalPicklist.forEach((uniqueItem) => {
      this.subscriptions[uniqueItem.picklist] = this.serverService
        .getPicklist(this.sessionId, uniqueItem.picklist)
        .subscribe(
          (response) => {
            if (response.picklistValues && response.picklistValues.length > 0) {
              response.picklistValues.forEach((element: any) => {
                const entry = {
                  categoryId: element.categoryId,
                  label: element.label,
                  name: element.name,
                  type: uniqueItem.type,
                  picklist: uniqueItem.picklist,
                  group: uniqueItem.group,
                  saveTo: uniqueItem.saveTo,
                  hidden: uniqueItem.hidden,
                  sortNumber: uniqueItem.sortNumber,
                };
                this.additionalFieldValues.push(entry);
              });
            }
          },
          (error: HttpErrorResponse) => {
            // re-try
            if (this.attemptNumber === 2) {
              this.exceptionService.handleError(error);
              this.toastrService.error(
                `Unable to get picklist values for ${uniqueItem.picklist}.`,
                'Error'
              );
            } else {
              this.attemptNumber = this.attemptNumber + 1;
              this.additionalFieldValues = []; // clear list
              this.getValuesForAdditionalFieldPicklists();
            }
          },
        );
    });
  }

  getCategoriesForWorkArea(): void {
    this.subscriptions.getWorkAreaCategories = this.serverService
      .getEndpointCategories(this.sessionId, this.workAreaId)
      .subscribe(
        (response) => {
          this.categories = response.data.sort((a, b) => a.order_by__c - b.order_by__c) ?? [];
        },
        (error: HttpErrorResponse) => {
          this.exceptionService.handleError(error);
          this.toastrService.error(
            'Unable to get categories for a work area.',
            'Error'
          );
        },
        () => {
          this.serverService.isNotLoading();
          this.disabled = false;
          this.loadingDataHidden = true;
        }
      );
  }


  save(): void {
    this.haveErrors = false; // reset if corrected by the user

    this.loading = true;
    this.disabled = true;
    this.serverService.isLoading();

    document.getElementById('errorMessageHolder').innerHTML =
      'Displayed Endpoints are not saved due to the following errors:' + '<br>';
    this.errorMessage = true;
    this.successRecords = [];


    this.endpointsArrayList.forEach((record) => {
      const value1 = parseInt(String(record.value_1__c));
      const value2 = parseInt(String(record.value_2__c));

        console.log('record to save ' + JSON.stringify(record));

        if (record.operator__c === 'between__c') {
          if (value2 <= value1 || isNaN(value1) || isNaN(value2)) {
            this.haveErrors = true;
            console.log(value1 + ', ' + value2);
          }
        }
      }
    );

    if (this.haveErrors === true) {
      console.log('saving with errors!');
      this.loading = false;
      this.disabled = false;
      this.serverService.isNotLoading();
      this.toastrService.error('Value 2 must be greater than Value 1', 'Values Error');
    }
    else {
      const body = this.endpointsArrayList;
      this.serverService.postEndpoint(this.sessionId, body).subscribe(
        (success: CreateObjectsResponse) => {
          let created = 0;
          success.data?.forEach((record: CreateObjectResponse) => {
            if (record?.responseStatus === 'SUCCESS') {
              created++;
            }
            if (record?.responseStatus === 'FAILURE') {
              this.errorMessage = false;
              record?.errors?.forEach((error: VaultError) => {
                this.toastrService.error(error.message, error.type);
                document.getElementById('errorMessageHolder').innerHTML =
                  document.getElementById('errorMessageHolder').innerHTML +
                  '<br>' +
                  error.message;
              });
            }
          });
          this.toastrService.info(
            `${created} Endpoint(s) created successfully.`,
            'Info'
          );

          this.loading = false;
          this.disabled = false;

          const response = success.data;

          for (let i = 0; i < this.endpointsArrayList.length; i++) {
            for (let j = 0; j < response.length; j++) {
              if (i === j) {
                if (response[j].responseStatus === 'SUCCESS') {
                  this.successRecords.push(this.endpointsArrayList[i].id__c);
                }
              }
            }
          }
          this.clearSuccessRecords();
        },
        (error: HttpErrorResponse) => {
          this.exceptionService.handleError(error);
          this.toastrService.error(
            `An error occurred while saving endpoints.`,
            'Error'
          );
        },
      );
      this.serverService.isNotLoading();
    }
  }

  clearSuccessRecords(): any {
    for (const recordId of this.successRecords) {
      this.endpointsArrayList = this.endpointsArrayList.filter(
        obj => obj.id__c !== recordId
      );

      this.ecoToxCategory.forEach((EcotoxCategoryComponent) => {
        EcotoxCategoryComponent.removeSavedEndpoint(recordId);
      });
    }
  }
}

