<mat-card class="dashboard-card">
  <mat-card-header>
    <mat-card-title>
      {{title}}
      <button mat-icon-button class="more-button" [matMenuTriggerFor]="menu" aria-label="Toggle menu">
        <mat-icon>more_horizontal</mat-icon>
      </button>
      <mat-menu #menu="matMenu" xPosition="before">
        <button *ngIf="title==='Regulatory Risk Scores'" mat-menu-item (click)="onChartSave()">Save Chart for Export
        </button>
        <button mat-menu-item (click)="onExport()">Export To Excel</button>
      </mat-menu>
    </mat-card-title>
  </mat-card-header>
  <mat-card-content class="dashboard-card-content">
    <ng-content></ng-content>
  </mat-card-content>
</mat-card>
