import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ConsumerSafetyEndpointTableItem} from '../../models/consumer-safety-endpoint-table-item';
import {PicklistValue} from '../../../models/picklist-value';
import {SubstanceItem} from '../../../models/substance-item';
import {RelatedSubstances} from '../../../models/related-substances';
import {DocumentMenuItem} from '../../../models/document-menu-item';
import {ConsumerSafetyAdditionalFields} from '../../models/consumer-safety-additional-fields';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {ExceptionService} from '../../../../core/exception.service';
import {ServerService} from '../../../../core/server.service';
import {MatDialog} from '@angular/material/dialog';

@Component({
  selector: 'app-residue-definition',
  templateUrl: './residue-definition.component.html',
  styleUrls: ['../../../record_style.css']
})
export class ResidueDefinitionComponent implements OnInit {
  @Input() singleEndpointRecord: ConsumerSafetyEndpointTableItem;
  @Input() category: PicklistValue;
  @Input() allStudyTypes: Array<PicklistValue>;
  @Input() allTestSystemTypes: Array<PicklistValue>;
  @Input() allTestSystemNames: Array<PicklistValue>;
  @Input() substance: SubstanceItem;
  @Input() substanceTypes: Array<PicklistValue>;
  @Input() relatedSubstances: Array<RelatedSubstances>;
  @Input() documents: Array<DocumentMenuItem>;
  @Input() allAdditionalFields: Array<ConsumerSafetyAdditionalFields>;
  @Input() allAdditionalFieldValues: Array<ConsumerSafetyAdditionalFields>;
  @Input() purpose: string;

  @Output() removeInput: EventEmitter<ConsumerSafetyEndpointTableItem> = new EventEmitter();
  @Output() copyInput: EventEmitter<ConsumerSafetyEndpointTableItem> = new EventEmitter();
  @Output() cancelEdit: EventEmitter<ConsumerSafetyEndpointTableItem> = new EventEmitter();
  @Output() saveChanges: EventEmitter<ConsumerSafetyEndpointTableItem> = new EventEmitter();

  subscriptions: any;
  selectFormGroup: FormGroup;
  configureFormGroup: FormGroup;
  public studyTypes: Array<PicklistValue>;
  public testSystemTypes: Array<PicklistValue>;
  public testSystemNames: Array<PicklistValue>;
  public rdComponents:Array<RelatedSubstances>;
  public filteredRelatedSubstances: Array<RelatedSubstances>;
  public additionalFields: Array<ConsumerSafetyAdditionalFields>;
  private sessionId: string;
  public hiddenRDComponentsSingleSelect: boolean;
  public hiddenRDComponentsMultiSelect: boolean;
  public showCopyOptions: boolean;
  public showEditOptions: boolean;
  public dialogTitle: string;
  public dialogRow1: string;
  public dialogRow2: string;

  constructor(private route: ActivatedRoute,
              private exceptionService: ExceptionService,
              private serverService: ServerService,
              private toastrService: ToastrService,
              private formBuilder: FormBuilder,
              public dialog: MatDialog
  ) {
    this.subscriptions = {};
    this.route.paramMap.subscribe((params) => {
      this.sessionId = params.get('sessionId');
    });
    this.dialogTitle = 'Add New Test System Type';
    this.dialogRow1 = "Test template for adding new data entries to Endpoints.";
    this.dialogRow2 = "";

    this.selectFormGroup = this.formBuilder.group({
      substanceType: [],
      relatedSubstance: [{value: '', disabled: true}],
      studyType: [{value: '', disabled: false}, Validators.required],
      testSystemTypeMultiSelect: [{value: [], disabled: false}],
      testSystemNameSingleSelect: [{value: '', disabled: false}],
      rdComponentsSingleSelect: [{value: [], disabled: true}],
      rdComponentsMultiSelect: [{value: [], disabled: true}],
      // additional (depended) fields
      rd_components__c: [{value: '', disabled: false}],
      rd_wording__c: [{value: '', disabled: false}],
      rd_status__c: [{value: '', disabled: false}],
      comment__c: [{value: '', disabled: false}],
      // default
      selectedDocument: [{value: '', disabled: false}],
    });

    this.testSystemTypes = [];
    this.testSystemNames = [];
    this.filteredRelatedSubstances = [];
    this.rdComponents = this.relatedSubstances;
    this.additionalFields = [];
    this.hiddenRDComponentsSingleSelect = false;
    this.hiddenRDComponentsMultiSelect = true;
  }

  ngOnInit(): void {
    this.showGroupAdditionalFields();
    if (this.purpose === 'editOnExisting') {
      this.showCopyOptions = false;
      this.showEditOptions = true;
    }
    else {
      this.showCopyOptions = true;
      this.showEditOptions = false;
    }

    this.selectFormGroup.get('selectedDocument').setValue(this.singleEndpointRecord.document_unbound__c);
    this.studyTypes = this.allStudyTypes;
    this.studyTypes = this.allStudyTypes.sort((a, b) => a.name > b.name ? 1 : -1);

    this.testSystemTypes = this.allTestSystemTypes.filter(
      (entry) => entry.id === this.category.id);

    if (this.singleEndpointRecord.study_type_picklist_name__c !== '') {
    // COPY or EDIT record
      if (this.singleEndpointRecord.substance_type__c === 'parent__c'
        || this.singleEndpointRecord.substance_type__c === '') {
        this.selectFormGroup.get('relatedSubstance').disable();
      }
      else {
        this.selectFormGroup.get('relatedSubstance').enable();
        this.filteredRelatedSubstances = this.relatedSubstances.filter(
          (f) => f.substance_type__c[0] === this.singleEndpointRecord.substance_type__c
        );
      }
      this.setRDComponents();
      this.setFormFields();
    }
    else {
    // NEW record
      // set default value
      this.selectFormGroup.get('substanceType').setValue('parent__c');
      this.singleEndpointRecord.substance_type__c = 'parent__c';
    }
  }

  getSelectedOptionPicklist(event: any): string {
    return event.target.options[event.target.options.selectedIndex].title;
  }

  onSelectSubstanceType(event: any): any {
    // update object
    this.singleEndpointRecord.substance_type__c = event.target.value;

    // this function is triggered only if value is changed, meaning the group is changed
    this.singleEndpointRecord.related_substance__c = '';
    this.selectFormGroup.get('relatedSubstance').setValue('');

    // parent cannot have Related Substance
    if (event.target.value === 'parent__c') {
      this.selectFormGroup.get('relatedSubstance').disable();
    } else {
      // filter Related Substances by the selected Substance Type
      this.filteredRelatedSubstances = this.relatedSubstances.filter(
        (f) => f.substance_type__c[0] === event.target.value
      );
      this.selectFormGroup.get('relatedSubstance').enable();
    }
  }

  onSelectRelatedSubstance(event: any): any {
    this.singleEndpointRecord.related_substance__c = event.target.value;
  }

  onSelectStudyType(event: any): any {
    this.singleEndpointRecord.study_type__c = event.target.value;

    if (event.target.value !== '') {
      this.singleEndpointRecord.study_type_picklist_name__c = this.getSelectedOptionPicklist(event);
    }
    else {
      // Study Type = ''
      this.singleEndpointRecord.study_type_picklist_name__c = '';
    }
  }

  onSelectTestSystemType(event: any): void {
    this.singleEndpointRecord.test_system_type__c = event.value;
  }

  onSelectTestSystemName(event: any): void {
    // single select record
    this.singleEndpointRecord.test_system_name__c = [event.value];

    this.singleEndpointRecord.rd_components__c = ''; // reset because filter is changing
    this.selectFormGroup.get('rdComponentsSingleSelect').setValue('');
    this.selectFormGroup.get('rdComponentsMultiSelect').setValue('');

    this.setRDComponents();
  }

  setRDComponents(): void {
    if (this.singleEndpointRecord.test_system_name__c[0] === 'parent_only__c') {
      // dropdown from parent entries linked to parent substance
      this.rdComponents = this.relatedSubstances.filter(
        (f) => f.substance_type__c[0] === 'parent__c'
      );

      // set single select
      this.hiddenRDComponentsSingleSelect = false;
      this.selectFormGroup.get('rdComponentsSingleSelect').enable();

      this.hiddenRDComponentsMultiSelect = true; // hidden
    }
    else if (this.singleEndpointRecord.test_system_name__c[0] === 'metabolites_only__c') {
      // multiselect from all metabolite entries linked to parent substance
      this.rdComponents = this.relatedSubstances.filter(
        (f) => f.substance_type__c[0] === 'metabolite__c'
      );

      // set multi-select
      this.hiddenRDComponentsMultiSelect = false;
      this.selectFormGroup.get('rdComponentsMultiSelect').enable();

      this.hiddenRDComponentsSingleSelect = true; // hidden
    }
    else if (this.singleEndpointRecord.test_system_name__c[0] === 'parent_metabolites__c' || this.singleEndpointRecord.test_system_name__c[0] === 'common_moiety__c') {
      // multiselect from all parent and metabolite entries linked to parent substance
       this.rdComponents = this.relatedSubstances.filter(
        (f) => f.substance_type__c[0] === 'parent__c' || f.substance_type__c[0] === 'metabolite__c'
      );

      // set multi-select
      this.hiddenRDComponentsMultiSelect = false;
      this.selectFormGroup.get('rdComponentsMultiSelect').enable();

      this.hiddenRDComponentsSingleSelect = true; // hidden
    }
    else {
      this.selectFormGroup.get('rdComponentsSingleSelect').disable();
      this.selectFormGroup.get('rdComponentsMultiSelect').disable();
    }
  }

  onSelectRDComponent(event: any, fieldType): void {
    if (fieldType === 'singleSelect') {
        this.singleEndpointRecord.rd_components__c = [event.value];
    }
    else if (fieldType === 'multiSelect') {
      this.singleEndpointRecord.rd_components__c = event.value;
    }
  }

  updateFieldValue(event: any): any {
    const fieldName = event.title;
    this.singleEndpointRecord[fieldName] = event.value;
  }

  filterAdditionalFieldValues(
    allAdditionalFieldValues: Array<ConsumerSafetyAdditionalFields>,
    filterWord: string,
  ): Array<ConsumerSafetyAdditionalFields> {
      return allAdditionalFieldValues.filter(
        (item) => item.picklist === filterWord
      );
  }

  showGroupAdditionalFields(): void {
    this.additionalFields = this.allAdditionalFields.filter(
      (entry) =>
        entry.group === 'study_type_residue_definition__c'
    ).sort((a, b) => a.sortNumber - b.sortNumber);
  }

  setFormFields(): void {
    this.selectFormGroup.get('substanceType').setValue(this.singleEndpointRecord.substance_type__c);
    this.selectFormGroup.get('relatedSubstance').setValue(this.singleEndpointRecord.related_substance__c);
    this.selectFormGroup.get('studyType').setValue(this.singleEndpointRecord.study_type__c);
    if (this.singleEndpointRecord.test_system_type__c) {
      this.selectFormGroup.get('testSystemTypeMultiSelect').setValue(this.singleEndpointRecord.test_system_type__c);
    }
    this.selectFormGroup.get('testSystemNameSingleSelect').setValue(this.singleEndpointRecord.test_system_name__c);
    if (this.singleEndpointRecord.rd_components__c)
    {
      this.selectFormGroup.get('rdComponentsSingleSelect').setValue(this.singleEndpointRecord.rd_components__c.toString());
      this.selectFormGroup.get('rdComponentsMultiSelect').setValue(this.singleEndpointRecord.rd_components__c);
    }
    // additional fields
    this.selectFormGroup.get('rd_wording__c').setValue(this.singleEndpointRecord.rd_wording__c);
    this.selectFormGroup.get('rd_status__c').setValue(this.singleEndpointRecord.rd_status__c);
    this.selectFormGroup.get('comment__c').setValue(this.singleEndpointRecord.comment__c);
    // default
    this.selectFormGroup.get('selectedDocument')
      .setValue(this.singleEndpointRecord.document_unbound__c);
  }

  removeEndpoint(singleEndpointRecord: ConsumerSafetyEndpointTableItem): any {
    this.removeInput.emit(singleEndpointRecord);
  }

  copyEndpoint(singleEndpointRecord: ConsumerSafetyEndpointTableItem): void {

    this.copyInput.emit(singleEndpointRecord);
  }

  cancelEditing(endpointRecord): void {
    this.cancelEdit.emit(endpointRecord);
  }

  saveEndpointChanges(endpointRecord): void {
    this.saveChanges.emit(endpointRecord);
  }
}
