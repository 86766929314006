<div [ngStyle]="{'z-index':singleEndpointRecord.id__c}" class="fieldsHolder" id="{{singleEndpointRecord.id__c}}">
  <form [formGroup]="selectFormGroup">
    <div class="rightConner">
      <button *ngIf="showCopyOptions" (click)="removeEndpoint(singleEndpointRecord)" class="delete" data-cy="RemoveEndpoint"
              title="Remove Endpoint"><span class="material-icons">remove_circle_outline</span></button>
      <button *ngIf="showCopyOptions" (click)="copyEndpoint(singleEndpointRecord)" class="copy" data-cy="CopyEndpoint"
              title="Copy Endpoint"><span class="material-icons">content_copy</span></button>
      <button *ngIf="showEditOptions" (click)="cancelEditing(singleEndpointRecord)" class="delete" data-cy="RemoveEndpoint"
              title="Remove Endpoint"><span class="material-icons">cancel</span></button>
      <button *ngIf="showEditOptions" (click)="saveEndpointChanges(singleEndpointRecord)" class="copy" data-cy="SaveEndpoint"
              title="Save Changes"><span class="material-icons">check_circle</span></button>
    </div>

    <div class="singleFieldContainer">
      <div class="formItemTitle">Category:</div>
      <div>
        <select class="formSelector" name="category">
          <option value="{{category.id}}">{{category.name__v}}</option>
        </select>
      </div>
    </div>

    <div class="singleFieldContainer">
      <div class="formItemTitle">Source:</div>
      <select (change)="updateFieldValue($event.target.valueOf())" class="formSelectorForDocument"
              formControlName="selectedDocument" name="source" title="document_unbound__c">
        <option *ngFor="let document of documents" value="{{ document.id }}">{{ document.name }}</option>
      </select>
    </div>

    <div class="singleFieldContainer">
      <div class="formItemTitle">Substance Type:</div>
      <div>
        <select (change)="onSelectSubstanceType($event)" class="formSelector" formControlName="substanceType"
                name="substanceType">
          <option value=""></option>
          <option *ngFor="let substaneType of substanceTypes"
                  [value]="substaneType.name">{{ substaneType.label }}</option>
        </select>
      </div>
    </div>

    <div class="singleFieldContainer">
      <div class="formItemTitle">Related Substance:</div>
      <div>
        <select (change)="updateFieldValue($event.target.valueOf())" class="formSelector"
                formControlName="relatedSubstance" name="relatedSubstance" title="related_substance__c">
          <option selected value=""></option>
          <option *ngFor="let relSubstance of filteredRelatedSubstances"
                  value="{{relSubstance.id}}">{{relSubstance.name}}</option>
        </select>
      </div>
    </div>

    <div *ngFor="let field of additionalFields" class="singleFieldContainer" id="{{field.name}}">
      <div class="formItemTitle">{{field.label}}:</div>

      <div [ngSwitch]="field.type">
        <textarea (change)="updateFieldValue($event.target.valueOf())"
                  (keyup)="updateFieldValue($event.target.valueOf())" *ngSwitchCase="'text'"
                  [formControlName]="field.name" [title]="field.name" class="formFreeText" rows="1"></textarea>
        <input (change)="updateNumberFieldValue($event.target.valueOf())"
               (keyup)="updateNumberFieldValue($event.target.valueOf())"
               *ngSwitchCase="'number'" [formControlName]="field.name" [title]="field.name" class="numberSelector"
               min=0 type="number">

        <select (change)="updateFieldValue($event.target.valueOf())" *ngSwitchCase="'picklist'"
                [formControlName]="field.name" [title]="field.name" class="formSelector">
          <option value=""></option>
          <option *ngFor="let opt of filterAdditionalFieldValues(allAdditionalFieldValues, field.picklist)"
                  [value]="opt.name">{{opt.label}}</option>
        </select>

        <mat-form-field *ngSwitchCase="'multi-picklist'" class="multiPicklistFormSelector">
          <mat-select
            (selectionChange)="updateMultiPicklistFieldValue($event, field.name)"
            [formControlName]="field.name"
            [title]="field.name"
            class="formSelector"
            multiple ngDefaultControl>
            <mat-option *ngFor="let topping of filterAdditionalFieldValues(allAdditionalFieldValues, field.picklist)"
                        [value]="topping.name">{{topping.label}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>

  </form>

</div>
