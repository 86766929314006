import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {VaultService} from '../../service/vault.service';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],

})
export class HomeComponent implements OnInit {

  userId: string;
  mail: string;
  userName: string;
  sessionId: string;
  queryParams: any;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private vaultService: VaultService
  ) {

  }

  ngOnInit(): void {
    this.queryParams = {
      userName: sessionStorage.getItem('userName'),
      mail: sessionStorage.getItem('mail'),
      userId: sessionStorage.getItem('userId'),
      sessionId: sessionStorage.getItem('sessionId')
    }
    this.vaultService.queryParams = this.queryParams;

    this.vaultService.addUserToDatabase().subscribe(response => {
      if (response) {
        console.log('user added to database');
      } else {
        console.log('user already added to database');
      }
    });
    this.router.navigate(['/aicompare/select'], {
      queryParams: this.queryParams
    });
  }

}



