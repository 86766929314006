import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable, of, Subject} from "rxjs";
import {ActiveIngredient} from "../models/active-ingredient";
import {Evaluation} from "../models/evaluation";
import {map, tap} from 'rxjs/operators';
import {SubstanceSet} from "../models/substance-set";
import {environment} from '../../../environments/environment';
import {Row} from '../models/row';
import {TableRow} from '../models/table-row';
import {ChartData} from "../models/chart-data";
import {Cell} from "../models/cell";
import {LegendItem} from "chart.js";
import {User} from "../models/user";

@Injectable({
  providedIn: 'root'
})
export class VaultService {

  substances: ActiveIngredient[] = [];

  apiUrl: string = environment.apiUrl;

  queryParams: any;

  substanceSet: SubstanceSet = new SubstanceSet();

  evaluations: Evaluation[];

  rows: Row[] = []

  overallFutures: Row[] = [];

  singleRiskScoresData: TableRow[] = [];

  tableCols: string[] = [];

  barLegendItems: LegendItem[] = [];

  radarLegendItems: LegendItem[] = [];

  private saveChartSubject = new Subject<any>();

  private exportSubject = new Subject<any>();


  selectedLegendItems: LegendItem[] = new Array(6);

  barChartColors = ['rgba(0,40,117, 1.0)', 'rgba(0,74,150, 1.0)', 'rgba(68, 114, 170, 1.0)', 'rgba(124,160,198, 1.0)',
    'rgba(166, 192, 218,1.0)', 'rgba(224, 233, 242, 1.0)'];

  radarChartColors = ['rgba(0,74,150, 1.0)', 'rgba(124,160,198, 1.0)', 'rgba(224, 233, 242, 1.0)', 'rgba(0,0,0,1)'];

  constructor(private http: HttpClient) {
  }


  sendSaveChartClickEvent() {
    this.saveChartSubject.next(1);
  }

  getSaveChartClickEvent() {
    return this.saveChartSubject.asObservable();
  }


  sendExportClickEvent() {
    this.exportSubject.next(1);
  }


  getExportClickEvent() {
    return this.exportSubject.asObservable();
  }

  exportToExcel(radarBase: string, barBase: string): Observable<Blob> {
    let data: Object;
    data = {
      'radarBase': radarBase,
      'barBase': barBase,
      'commentsData': this.rows,
      'overallFuturesData': this.overallFutures,
      'singleRiskScoresData': this.singleRiskScoresData,
      'radarLegendItems': this.radarLegendItems,
      'barLegendItems': this.barLegendItems
    }

    let headers = new HttpHeaders();
    headers.set('Content-Type', 'application/json')
      .set('Accept', 'application/json');

    return this.http.post(this.apiUrl + '/report/download', data, {
      responseType: 'blob',
      headers: headers
    })
  }

  exportEvaluationToExcel(radarBase: string, evaluationId: string): Observable<Blob> {
    let headers = new HttpHeaders();
    headers.set('Content-Type', 'application/json')
      .set('Accept', 'application/json');
    return this.http.post(this.apiUrl + '/export/' + evaluationId, radarBase, {
      responseType: 'blob',
      headers: headers
    })
  }

  addUserToDatabase(): Observable<boolean> {
    let user = {} as User;
    user.id = this.queryParams.userId;
    user.user_email__v = this.queryParams.mail;
    user.user_name__v = this.queryParams.userName;
    return this.http.post<boolean>(this.apiUrl + '/user', user);
  }

  getAllUsers(): Observable<User[]> {
    return this.http.get<User[]>(this.apiUrl + '/users');
  }


  getSubstances(): Observable<ActiveIngredient[]> {
    if (this.substances.length === 0) {
      return this.http.get<ActiveIngredient[]>(this.apiUrl + `/substances`)
        .pipe(
          tap(response => {
            this.substances = response;

          })
        );
    } else {
      return of(this.substances);
    }

  }

  getSingleRiskScoresTableData(): Observable<TableRow[]> {
    let rows: TableRow[] = []
    this.evaluations.forEach(evaluation => {
      let row = new TableRow();
      row['Evaluation'] = evaluation.name__v;
      if (evaluation.current_risk_comment__c != undefined) {
        row['Single Risk Score Current'] = evaluation.current_risk_comment__c;
      }
      if (evaluation.future_risk_comment__c) {
        row['Single Risk Score Future'] = evaluation.future_risk_comment__c;
      }
      row['Region'] = evaluation['region__cr.name__v'];
      row['Active Ingredient'] = evaluation['poc_active_ingredient__cr.name__v'];
      row['Single Risk Score Future-color'] = this.getColorFromString(evaluation.future_risk_color__c);
      row['Single Risk Score Current-color'] = this.getColorFromString(evaluation.current_risk_color__c);
      if (evaluation.current_risk_score__c != undefined) {
        if (this.getRiskScoreInt(evaluation.current_risk_score__c[0]) === 5) {
          row['Single Risk Score Current-text-color'] = 'rgb(255,255,255)';
        } else if (this.getRiskScoreInt(evaluation.current_risk_score__c[0]) != 5) {
          row['Single Risk Score Current-text-color'] = 'rgb(0,0,0)'
        }
        row['Current-score'] = this.getRiskScoreInt(evaluation.current_risk_score__c[0]);
      }
      if (evaluation.future_risk_score__c != undefined) {
        if (this.getRiskScoreInt(evaluation.future_risk_score__c[0]) === 5) {
          row['Single Risk Score Future-text-color'] = 'rgb(255,255,255)';
        } else if (this.getRiskScoreInt(evaluation.future_risk_score__c[0]) != 5) {
          row['Single Risk Score Future-text-color'] = 'rgb(0,0,0)';
        }

        row['Future-score'] = this.getRiskScoreInt(evaluation.future_risk_score__c[0]);
      }
      rows.push(row)
    })
    this.singleRiskScoresData = rows;
    return of(rows);
  }

  getRiskScoreInt(score: string): number {
    switch (score) {
      case "no_risk__c":
        return 1;
      case "minor_risk__c":
        return 2;
      case "moderate_risk__c":
        return 3;
      case "high_risk__c":
        return 4;
      case "registration_not_possible_or_revoked__c":
        return 5;
      default:
        return 0;
    }
  }

  getColorFromString(colorString: string): string {
    let string1 = colorString.split('icon_color=');
    let string2 = string1[1].split(';;;')[0];
    let string3 = string2.replace(/['"]+/g, '');
    return string3;
  }



  getUserSets(userId: string):Observable<SubstanceSet[]> {
    return this.http.get<SubstanceSet[]>(this.apiUrl + `/substances/${userId}`);
  }

  saveSets(sets: SubstanceSet[]): Observable<any> {
    return this.http.post(this.apiUrl + `/substances/save`, sets);
  }

  deleteSet(id: string): Observable<boolean>{
    return this.http.delete<boolean>(this.apiUrl + `/substances/delete/${id}`);
  }

  updateSet(set: SubstanceSet): Observable<any>{
    return this.http.put(this.apiUrl + `/substances/update/${set.id}`, set);
  }

  getUserId(): string {
    return this.queryParams.userId;
  }


  getRows():Observable<Row[]>{
    if (this.evaluations != undefined) {
      return this.http.post<Row[]>(this.apiUrl + '/rows', this.evaluations).pipe(
        map((response: Row[]) => {
          this.rows = this.sortRows(response);
          return this.sortRows(response);
        })
      );
    }
  }

  getOverallFuturesRows(): Observable<TableRow[]> {
    if (this.evaluations != undefined){
      return this.http.post<Row[]>(this.apiUrl + '/overallFutures', this.evaluations).pipe(
        map((response) => {
          this.overallFutures = response;
          return this.getOverallFuturesTableData(response);
        })
      );
    }
  }

  getOverallFuturesTableData(rows: Row[]): TableRow[]{
    if (rows != undefined){
      let tableData: TableRow[] = [];
      rows.forEach(r => {
        let tableRow = new TableRow();
        tableRow["Work Area"] = r.category;
        r.cells.forEach(cell => {
          tableRow[cell.name] = cell.comment;
          tableRow[cell.name + '-color'] = this.getCellColor(cell);
          if (cell.score === 5) {
            tableRow[cell.name + '-text-color'] = 'rgb(255,255,255)'
          } else {
            tableRow[cell.name + '-text-color'] = 'rgb(0,0,0)';
          }
        })
        tableData.push(tableRow);
      })
      return tableData;
    }
  }


  getTableData(rows: Row[], ): TableRow[] {
    if (rows != undefined) {
      let tableData: TableRow[] = [];
      rows.forEach(r => {
        let tableRow = new TableRow();
        tableRow["Category"] = r.category;
        if (r.workArea === 'PBT Assessment') {
          tableRow['Work Area'] = ""
        } else {
          tableRow["Work Area"] = r.workArea;
        }
        r.cells.forEach(cell => {
          tableRow[cell.name] = cell.comment;
          tableRow[cell.name + '-color'] = this.getCellColor(cell);
          tableRow["Work Area-color"] = this.getWorkAreaColor(r.workArea);
        })
        tableData.push(tableRow);
      })
      return tableData;
    }
  }

  getChartData(rows: Row[]): Observable<ChartData[]> {

    let data: ChartData[] = [];
    let cells = rows[0].cells;
    for (let i = 0; i < cells.length; i++) {
      let chartData = new ChartData();
      chartData.label = cells[i].name;
      chartData.backgroundColor = 'rgb(0,0,0,0)';
      chartData.hoverBackgroundColor = 'rgb(0, 12, 36)';
      if (i < 2) {
        chartData.borderColor = this.radarChartColors[i];
        chartData.pointBackgroundColor = this.radarChartColors[i];
        chartData.pointBorderColor = 'rgba(255, 255, 255, 1.0)'
        chartData.hidden = false;

      } else {
        chartData.pointBorderColor = 'rgba(255, 255, 255, 1.0)'

        chartData.hidden = true;
      }

      rows.forEach(row => {
        let cell = row.cells.filter(cell => {
          return cell.name === chartData.label;
        })[0];
        if (cell.score === 0) {
          chartData.addData(cell.score);
        } else {
          chartData.addData(cell.score - 0.5);
        }
      })
      data.push(chartData);
    }
    return of(data);
  }

  getChartLabels(rows: Row[]): string[] {
    return rows.map(row => {
      return row.category
    });
  }

  getWorkAreaColor(workArea: string): string {
    switch (workArea) {
      case 'Consumer Safety':
        return '#CCFFFF';
      case 'Environmental Fate':
        return '#FFFF99';
      case 'Toxicology':
        return '#CCCCFF';
      case 'Ecotoxicology':
        return '#CCFFCC';
      case 'PBT Assessment':
        return 'rgb(231,230,230)';
      default:
        return 'white';

    }
  }


  getCellColor(cell: Cell): string {
    switch (cell.score) {
      case 0:
        return 'rgb(231,230,230)';
      case 1:
        return '#9CCF00';
      case 2:
        return '#FFFF00';
      case 3:
        return '#FF9A00';
      case 4:
        return '#FF0000';
      default:
        return 'rgb(231,230,230)';
    }
  }

  sortRows(rows: Row[]): Row[] {
    let workAreas = ['PBT Assessment', 'Environmental Fate', 'Ecotoxicology', 'Consumer Safety', 'Toxicology'];
    let sortedRows: Row[] = [];
    for (let workArea of workAreas) {
      let subgroup = this.getRowSubgroup(workArea, rows);
      subgroup.sort((a, b) => (a.orderBy > b.orderBy) ? 1 : -1);
      sortedRows.push(...subgroup);
    }
    return sortedRows;
  }

  getRowSubgroup(workArea: string, rows: Row[]): Row[] {
    return rows.filter(row => {
      return row.workArea === workArea;
    })
  }

}
