<div class="existingEndpointsComponent">

  <mat-accordion>
    <mat-expansion-panel (closed)="destroyEndpoints(); panelOpenState = false"
                         (opened)="getEndpointsWithFilters(this.pageSize, 0); panelOpenState = true"
                         [expanded]="expandPanel"
                         style="border:none; box-shadow: none;">
      <mat-expansion-panel-header class="expansionHeader">
        <mat-panel-title class="expansionTitle">
          Existing "{{workAreaName}}" Endpoints With "{{substance.name}}" Substance.
        </mat-panel-title>
      </mat-expansion-panel-header>

      <ng-container>

        <div class="filterChipsRow">

          <mat-form-field class="example-chip-list">
            <mat-label>Filters</mat-label>
            <mat-chip-list #chipList aria-label="Fields selection">
              <mat-chip (removed)="remove(field)"
                        *ngFor="let field of fields"
                        [removable]="removable"
                        [selectable]="selectable"
                        class="angularFormChipValue">
                {{field}}
                <mat-icon *ngIf="removable" matChipRemove>cancel</mat-icon>
              </mat-chip>
              <input
                #fieldInput
                (matChipInputTokenEnd)="add($event)"
                [formControl]="fieldCtrl"
                [matAutocomplete]="auto"
                [matChipInputFor]="chipList"
                [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                class="angularFormChipValue" placeholder="New filter...">
            </mat-chip-list>

            <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">

              <ng-container *ngFor="let field of filteredFields | async">
                <mat-option *ngIf="!fields.includes(field)" [value]="field" class="angularFormSelector">
                  {{field}}
                </mat-option>
              </ng-container>
            </mat-autocomplete>
          </mat-form-field>
        </div>

        <div class="filterFieldsRowSELECTED" id="filterFields">

          <div class="singleFieldContainer" id='Category'>
            <mat-form-field class="angularSearchWrapper">
              <mat-label>Category</mat-label>
              <mat-select (selectionChange)="picklistValueToArray('category__c', 'Category', category__c)"
                          [(ngModel)]="category__c">
                <mat-option value="">All</mat-option>
                <mat-option *ngFor="let category of receivedCategories" [value]=" category.id">
                  {{ category.name__v}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="singleFieldContainer" id='Related Substance'>
            <mat-form-field class="angularSearchWrapper">
              <mat-label>Related Substance</mat-label>
              <mat-select
                (selectionChange)="picklistValueToArray('related_substance__c', 'Related Substance', related_substance__c)"
                [(ngModel)]="related_substance__c">
                <mat-option>All</mat-option>
                <mat-option *ngFor="let relatedSubstance of relatedSubstances" [value]=" relatedSubstance.id">
                  {{ relatedSubstance.name}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

        </div>

        <div class="filterFieldsRow" id="preparedFilterFields">

          <div class="singleFieldContainer" id='Substance Type'>
            <mat-form-field class="angularSearchWrapper">
              <mat-label>Substance Type</mat-label>
              <mat-select
                (selectionChange)="picklistValueToArray('substance_type__c', 'Substance Type', substance_type__c)"
                [(ngModel)]="substance_type__c">
                <mat-option value="">All</mat-option>
                <mat-option *ngFor="let substanceType of substanceTypes" [value]=" substanceType.name">
                  {{ substanceType.label}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="singleFieldContainer" id='Study Type'>
            <mat-form-field class="angularSearchWrapper">
              <mat-label>Study Type</mat-label>
              <mat-select (selectionChange)="picklistValueToArray('study_type__c', 'Study Type', study_type__c)"
                          [(ngModel)]="study_type__c">
                <mat-option value="">All</mat-option>
                <mat-option *ngFor="let filterStudyType of uniqueStudyTypes" [value]=" filterStudyType.name">
                  {{ filterStudyType.label}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="singleFieldContainer" id='Endpoint'>
            <mat-form-field class="angularSearchWrapper">
              <mat-label>Endpoint</mat-label>
              <mat-select (selectionChange)="picklistValueToArray('endpoint__c', 'Endpoint', endpoint__c)"
                          [(ngModel)]="endpoint__c">
                <mat-option value="">All</mat-option>
                <mat-option *ngFor="let filterEndpoint of uniqueEndpoints" [value]=" filterEndpoint.name">
                  {{ filterEndpoint.label}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="singleFieldContainer" id='Endpoint Unit'>
            <mat-form-field class="angularSearchWrapper">
              <mat-label>Endpoint Unit</mat-label>
              <mat-select
                (selectionChange)="picklistValueToArray('endpoint_unit__c', 'Endpoint Unit', endpoint_unit__c)"
                [(ngModel)]="endpoint_unit__c">
                <mat-option value="">All</mat-option>
                <mat-option *ngFor="let filterEndpointUnit of uniqueEndpointUnits" [value]=" filterEndpointUnit.name">
                  {{ filterEndpointUnit.label}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="singleFieldContainer" id='Operator'>
            <mat-form-field class="angularSearchWrapper">
              <mat-label>Operator</mat-label>
              <mat-select (selectionChange)="picklistValueToArray('operator__c', 'Operator', operator__c)"
                          [(ngModel)]="operator__c">
                <mat-option value="">All</mat-option>
                <mat-option *ngFor="let filterOperator of receivedAllOperators" [value]=" filterOperator.name">
                  {{ filterOperator.label}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="singleFieldContainer" id='Value 1'>
            <mat-form-field class="angularSearchWrapper">
              <mat-label>Value 1</mat-label>
              <input (change)="textFieldValueToArray('value_1__c', 'Value 1')"
                     (keyup)="textFieldValueToArray('value_1__c', 'Value 1')" [(ngModel)]="value_1__c"
                     class="orangeTextFieldInput" id="value_1__c" matInput type="number">
            </mat-form-field>
          </div>

          <div class="singleFieldContainer" id='Value 2'>
            <mat-form-field class="angularSearchWrapper">
              <mat-label>Value 2</mat-label>
              <input (change)="textFieldValueToArray('value_2__c', 'Value 2')"
                     (keyup)="textFieldValueToArray('value_2__c', 'Value 2')" [(ngModel)]="value_2__c"
                     class="orangeTextFieldInput" id="value_2__c" matInput type="number">
            </mat-form-field>
          </div>


          <div class="singleFieldContainer" id="Created Date Range">
            <mat-form-field class="angularSearchWrapper">
              <mat-label>Created Date Range</mat-label>
              <mat-date-range-input [formGroup]="createdRange" [rangePicker]="picker">
                <input [(ngModel)]="created_date_start" formControlName="createdDateStart" matStartDate
                       placeholder="Start date">
                <input [(ngModel)]="created_date_end" formControlName="createdDateEnd" matEndDate
                       placeholder="End date">
              </mat-date-range-input>
              <mat-datepicker-toggle [for]="picker" matSuffix></mat-datepicker-toggle>
              <mat-date-range-picker #picker color="accent"></mat-date-range-picker>

              <mat-error *ngIf="createdRange.controls.createdDateStart.hasError('matStartDateInvalid')">Invalid start
                date
              </mat-error>
              <mat-error *ngIf="createdRange.controls.createdDateEnd.hasError('matEndDateInvalid')">Invalid end date
              </mat-error>
            </mat-form-field>
          </div>

          <div class="singleFieldContainer" id="Modified Date Range">
            <mat-form-field class="angularSearchWrapper">
              <mat-label>Modified Date Range</mat-label>
              <mat-date-range-input [formGroup]="modifiedRange" [rangePicker]="modifiedPicker">
                <input [(ngModel)]="modified_date_start" formControlName="modifiedDateStart" matStartDate
                       placeholder="Start date">
                <input [(ngModel)]="modified_date_end" formControlName="modifiedDateEnd" matEndDate
                       placeholder="End date">
              </mat-date-range-input>
              <mat-datepicker-toggle [for]="modifiedPicker" matSuffix></mat-datepicker-toggle>
              <mat-date-range-picker #modifiedPicker color="accent"></mat-date-range-picker>

              <mat-error *ngIf="modifiedRange.controls.modifiedDateStart.hasError('matStartDateInvalid')">Invalid start
                date
              </mat-error>
              <mat-error *ngIf="modifiedRange.controls.modifiedDateEnd.hasError('matEndDateInvalid')">Invalid end date
              </mat-error>
            </mat-form-field>
          </div>


          <div class="singleFieldContainer" id="Created By">
            <div class="form-row">
              <mat-form-field class="angularSearchWrapper">
                <input [(ngModel)]="created_by__v"
                       [formControl]="searchUserCreateControl"
                       [matAutocomplete]="autoCreatedUser"
                       aria-label="User"
                       matInput
                       placeholder="Created By" type="text"
                >
                <mat-autocomplete #autoCreatedUser="matAutocomplete">
                  <mat-option (onSelectionChange)="picklistValueToArray('created_by__v', 'Created By', '')"
                              [value]="All"
                              value="">All
                  </mat-option>
                  <mat-option (onSelectionChange)="picklistValueToArray('created_by__v', 'Created By', user.id)"
                              *ngFor="let user of filteredCreateUsers | async" [value]="user.name"
                              class="angularFormSelector">
                    {{user.name}}
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field>
            </div>
          </div>

          <div class="singleFieldContainer" id="Last Modified By">
            <div class="form-row">
              <mat-form-field class="angularSearchWrapper">
                <input [(ngModel)]="modified_by__v"
                       [formControl]="searchUserModifyControl"
                       [matAutocomplete]="autoModifiedUser"
                       aria-label="User"
                       matInput
                       placeholder="Last Modified By" type="text"
                >
                <mat-autocomplete #autoModifiedUser="matAutocomplete">
                  <mat-option (onSelectionChange)="picklistValueToArray('modified_by__v', 'Last Modified By', '')"
                              [value]="All"
                              value="">All
                  </mat-option>
                  <mat-option (onSelectionChange)="picklistValueToArray('modified_by__v', 'Last Modified By', user.id)"
                              *ngFor="let user of filteredModifiedUsers | async" [value]="user.name"
                              class="angularFormSelector">
                    {{user.name}}
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field>
            </div>
          </div>

          <div class="singleFieldContainer" id="Source">
            <mat-form-field class="angularSearchWrapper">
              <mat-label>Source</mat-label>
              <mat-select (selectionChange)="picklistValueToArray('document_unbound__c', 'Source', selectedDocumentId)"
                          [(ngModel)]="selectedDocumentId">
                <mat-option value="">All</mat-option>
                <mat-option *ngFor="let document of documents" [value]=" document.id">
                  {{ document.name}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>


          <div class="singleFieldContainer" id='Test System Type'>
            <mat-form-field class="angularSearchWrapper">
              <mat-label>Test System Type</mat-label>
              <mat-select
                (selectionChange)="picklistValueToArray('test_system_type__c', 'Test System Type', test_system_type__c)"
                [(ngModel)]="test_system_type__c">
                <mat-option value="">All</mat-option>
                <mat-option *ngFor="let testSystemType of uniqueTestSystemTypes" [value]=" testSystemType.name">
                  {{ testSystemType.label}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="singleFieldContainer" id='Test Species'>
            <mat-form-field class="angularSearchWrapper">
              <mat-label>Test Species</mat-label>
              <mat-select
                (selectionChange)="picklistValueToArray('test_system_name__c', 'Test Species', test_system_name__c)"
                [(ngModel)]="test_system_name__c">
                <mat-option value="">All</mat-option>
                <mat-option *ngFor="let testSystemName of uniqueTestSystemNames" [value]=" testSystemName.name">
                  {{ testSystemName.label}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="singleFieldContainer" id='T Trigger'>
            <mat-form-field class="angularSearchWrapper">
              <mat-label>T Trigger</mat-label>
              <mat-select
                (selectionChange)="picklistValueToArray('t_trigger__c', 'T Trigger', t_trigger__c)"
                [(ngModel)]="t_trigger__c">
                <mat-option value="">All</mat-option>
                <mat-option
                  *ngFor="let tTriggerValue of filterAdditionalFieldValues(receivedAdditionalFieldValues, 't_trigger__c')"
                  [value]="tTriggerValue.name">
                  {{tTriggerValue.label}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="singleFieldContainer" id='Study Endpoint'>
            <mat-form-field class="angularSearchWrapper">
              <mat-label>Study Endpoint</mat-label>
              <mat-select
                (selectionChange)="picklistValueToArray('study_endpoint__c', 'Study Endpoint', study_endpoint__c)"
                [(ngModel)]="study_endpoint__c">
                <mat-option value="">All</mat-option>
                <mat-option
                  *ngFor="let studyEndpointValue of filterAdditionalFieldValues(receivedAdditionalFieldValues, 'study_endpoint__c')"
                  [value]="studyEndpointValue.name">
                  {{studyEndpointValue.label}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="singleFieldContainer" id='Study'>
            <mat-form-field class="angularSearchWrapper">
              <mat-label>Study</mat-label>
              <mat-select
                (selectionChange)="picklistValueToArray('study__c', 'Study', study__c)"
                [(ngModel)]="study__c">
                <mat-option value="">All</mat-option>
                <mat-option
                  *ngFor="let undefinedValue of filterAdditionalFieldValues(receivedAdditionalFieldValues, 'study__c')"
                  [value]="undefinedValue.name">
                  {{undefinedValue.label}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="singleFieldContainer" id='Crops'>
            <mat-form-field class="angularSearchWrapper">
              <mat-label>Crops</mat-label>
              <mat-select
                (selectionChange)="picklistValueToArray('crops__c', 'Crops', crops__c)"
                [(ngModel)]="crops__c">
                <mat-option value="">All</mat-option>
                <mat-option
                  *ngFor="let undefinedValue of filterAdditionalFieldValues(receivedAdditionalFieldValues, 'crops__c')"
                  [value]="undefinedValue.name">
                  {{undefinedValue.label}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="singleFieldContainer" id='Application Method'>
            <mat-form-field class="angularSearchWrapper">
              <mat-label>Application Method</mat-label>
              <mat-select
                (selectionChange)="picklistValueToArray('application_method__c', 'Application Method', application_method__c)"
                [(ngModel)]="application_method__c">
                <mat-option value="">All</mat-option>
                <mat-option
                  *ngFor="let undefinedValue of filterAdditionalFieldValues(receivedAdditionalFieldValues, 'application_method__c')"
                  [value]="undefinedValue.name">
                  {{undefinedValue.label}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="singleFieldContainer" id='PPE required ?'>
            <mat-form-field class="angularSearchWrapper">
              <mat-label>PPE required ?</mat-label>
              <mat-select
                (selectionChange)="picklistValueToArray('ppe_required__c', 'PPE required ?', ppe_required__c)"
                [(ngModel)]="ppe_required__c">
                <mat-option value="">All</mat-option>
                <mat-option
                  *ngFor="let undefinedValue of filterAdditionalFieldValues(receivedAdditionalFieldValues, 'ppe_required__c')"
                  [value]="undefinedValue.name">
                  {{undefinedValue.label}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="singleFieldContainer" id='Additional FQPA (US EPA)'>
            <mat-form-field class="angularSearchWrapper">
              <mat-label>Additional FQPA (US EPA)</mat-label>
              <mat-select
                (selectionChange)="picklistValueToArray('additional_fqpa_us_epa__c', 'Additional FQPA (US EPA)', additional_fqpa_us_epa__c)"
                [(ngModel)]="additional_fqpa_us_epa__c">
                <mat-option value="">All</mat-option>
                <mat-option
                  *ngFor="let undefinedValue of filterAdditionalFieldValues(receivedAdditionalFieldValues, 'additional_fqpa_us_epa__c')"
                  [value]="undefinedValue.name">
                  {{undefinedValue.label}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="singleFieldContainer" id='Species'>
            <mat-form-field class="angularSearchWrapper">
              <mat-label>Species</mat-label>
              <mat-select
                (selectionChange)="picklistValueToArray('species__c', 'Species', species__c)"
                [(ngModel)]="species__c">
                <mat-option value="">All</mat-option>
                <mat-option
                  *ngFor="let undefinedValue of filterAdditionalFieldValues(receivedAdditionalFieldValues, 'species__c')"
                  [value]="undefinedValue.name">
                  {{undefinedValue.label}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="singleFieldContainer" id='Tumor Site (female)'>
            <mat-form-field class="angularSearchWrapper">
              <mat-label>Tumor Site (female)</mat-label>
              <mat-select
                (selectionChange)="picklistValueToArray('tumor_site_female__c', 'Tumor Site (female)', tumor_site_female__c)"
                [(ngModel)]="tumor_site_female__c">
                <mat-option value="">All</mat-option>
                <mat-option
                  *ngFor="let undefinedValue of filterAdditionalFieldValues(receivedAdditionalFieldValues, 'tumor_site_female__c')"
                  [value]="undefinedValue.name">
                  {{undefinedValue.label}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="singleFieldContainer" id='Tumor Site (male)'>
            <mat-form-field class="angularSearchWrapper">
              <mat-label>Tumor Site (male)</mat-label>
              <mat-select
                (selectionChange)="picklistValueToArray('tumor_site_male__c', 'Tumor Site (male)', tumor_site_male__c)"
                [(ngModel)]="tumor_site_male__c">
                <mat-option value="">All</mat-option>
                <mat-option
                  *ngFor="let undefinedValue of filterAdditionalFieldValues(receivedAdditionalFieldValues, 'tumor_site_male__c')"
                  [value]="undefinedValue.name">
                  {{undefinedValue.label}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="singleFieldContainer" id='Chromosome Aberration, Hamster'>
            <mat-form-field class="angularSearchWrapper">
              <mat-label>Chromosome Aberration, Hamster</mat-label>
              <mat-select
                (selectionChange)="picklistValueToArray('chromosome_aberration_hamster__c', 'Chromosome Aberration, Hamster', chromosome_aberration_hamster__c)"
                [(ngModel)]="chromosome_aberration_hamster__c">
                <mat-option value="">All</mat-option>
                <mat-option
                  *ngFor="let undefinedValue of filterAdditionalFieldValues(receivedAdditionalFieldValues, 'chromosome_aberration_hamster__c')"
                  [value]="undefinedValue.name">
                  {{undefinedValue.label}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="singleFieldContainer" id='Chromosome Aberration, Mouse'>
            <mat-form-field class="angularSearchWrapper">
              <mat-label>Chromosome Aberration, Mouse</mat-label>
              <mat-select
                (selectionChange)="picklistValueToArray('chromosome_aberration_mouse__c', 'Chromosome Aberration, Mouse', chromosome_aberration_mouse__c)"
                [(ngModel)]="chromosome_aberration_mouse__c">
                <mat-option value="">All</mat-option>
                <mat-option
                  *ngFor="let undefinedValue of filterAdditionalFieldValues(receivedAdditionalFieldValues, 'chromosome_aberration_mouse__c')"
                  [value]="undefinedValue.name">
                  {{undefinedValue.label}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="singleFieldContainer" id='Chromosome Aberration, Rat'>
            <mat-form-field class="angularSearchWrapper">
              <mat-label>Chromosome Aberration, Rat</mat-label>
              <mat-select
                (selectionChange)="picklistValueToArray('chromosome_aberration_rat__c', 'Chromosome Aberration, Rat', chromosome_aberration_rat__c)"
                [(ngModel)]="chromosome_aberration_rat__c">
                <mat-option value="">All</mat-option>
                <mat-option
                  *ngFor="let undefinedValue of filterAdditionalFieldValues(receivedAdditionalFieldValues, 'chromosome_aberration_rat__c')"
                  [value]="undefinedValue.name">
                  {{undefinedValue.label}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="singleFieldContainer" id='UDS, in vivo'>
            <mat-form-field class="angularSearchWrapper">
              <mat-label>UDS, in vivo</mat-label>
              <mat-select
                (selectionChange)="picklistValueToArray('uds_in_vivo__c', 'UDS, in vivo', uds_in_vivo__c)"
                [(ngModel)]="uds_in_vivo__c">
                <mat-option value="">All</mat-option>
                <mat-option
                  *ngFor="let undefinedValue of filterAdditionalFieldValues(receivedAdditionalFieldValues, 'uds_in_vivo__c')"
                  [value]="undefinedValue.name">
                  {{undefinedValue.label}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="singleFieldContainer" id='Micronucleus Test, Mouse'>
            <mat-form-field class="angularSearchWrapper">
              <mat-label>Micronucleus Test, Mouse</mat-label>
              <mat-select
                (selectionChange)="picklistValueToArray('micronucleus_test_mouse__c', 'Micronucleus Test, Mouse', micronucleus_test_mouse__c)"
                [(ngModel)]="micronucleus_test_mouse__c">
                <mat-option value="">All</mat-option>
                <mat-option
                  *ngFor="let undefinedValue of filterAdditionalFieldValues(receivedAdditionalFieldValues, 'micronucleus_test_mouse__c')"
                  [value]="undefinedValue.name">
                  {{undefinedValue.label}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="singleFieldContainer" id='Micronucleus Test, Rat'>
            <mat-form-field class="angularSearchWrapper">
              <mat-label>Micronucleus Test, Rat</mat-label>
              <mat-select
                (selectionChange)="picklistValueToArray('micronucleus_test_rat__c', 'Micronucleus Test, Rat', micronucleus_test_rat__c)"
                [(ngModel)]="micronucleus_test_rat__c">
                <mat-option value="">All</mat-option>
                <mat-option
                  *ngFor="let undefinedValue of filterAdditionalFieldValues(receivedAdditionalFieldValues, 'micronucleus_test_rat__c')"
                  [value]="undefinedValue.name">
                  {{undefinedValue.label}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="singleFieldContainer" id='Micronucleus Test, in vitro'>
            <mat-form-field class="angularSearchWrapper">
              <mat-label>Micronucleus Test, in vitro</mat-label>
              <mat-select
                (selectionChange)="picklistValueToArray('micronucleus_test_in_vitro__c', 'Micronucleus Test, in vitro', micronucleus_test_in_vitro__c)"
                [(ngModel)]="micronucleus_test_in_vitro__c">
                <mat-option value="">All</mat-option>
                <mat-option
                  *ngFor="let undefinedValue of filterAdditionalFieldValues(receivedAdditionalFieldValues, 'micronucleus_test_in_vitro__c')"
                  [value]="undefinedValue.name">
                  {{undefinedValue.label}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="singleFieldContainer" id='Comet Assay, in vivo'>
            <mat-form-field class="angularSearchWrapper">
              <mat-label>Comet Assay, in vivo</mat-label>
              <mat-select
                (selectionChange)="picklistValueToArray('comet_assay_in_vivo__c', 'Comet Assay, in vivo', comet_assay_in_vivo__c)"
                [(ngModel)]="comet_assay_in_vivo__c">
                <mat-option value="">All</mat-option>
                <mat-option
                  *ngFor="let undefinedValue of filterAdditionalFieldValues(receivedAdditionalFieldValues, 'comet_assay_in_vivo__c')"
                  [value]="undefinedValue.name">
                  {{undefinedValue.label}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="singleFieldContainer" id='UDS, in vitro'>
            <mat-form-field class="angularSearchWrapper">
              <mat-label>UDS, in vitro</mat-label>
              <mat-select
                (selectionChange)="picklistValueToArray('uds_in_vitro__c', 'UDS, in vitro', uds_in_vitro__c)"
                [(ngModel)]="uds_in_vitro__c">
                <mat-option value="">All</mat-option>
                <mat-option
                  *ngFor="let undefinedValue of filterAdditionalFieldValues(receivedAdditionalFieldValues, 'uds_in_vitro__c')"
                  [value]="undefinedValue.name">
                  {{undefinedValue.label}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="singleFieldContainer" id='Chromosome Aberration, Non-Human Cells'>
            <mat-form-field class="angularSearchWrapper">
              <mat-label>Chromosome Aberration, Non-Human Cells</mat-label>
              <mat-select
                (selectionChange)="picklistValueToArray('chromosome_aberration_nonhuman_cells__c', 'Chromosome Aberration, Non-Human Cells', chromosome_aberration_nonhuman_cells__c)"
                [(ngModel)]="chromosome_aberration_nonhuman_cells__c">
                <mat-option value="">All</mat-option>
                <mat-option
                  *ngFor="let undefinedValue of filterAdditionalFieldValues(receivedAdditionalFieldValues, 'chromosome_aberration_nonhuman_cells__c')"
                  [value]="undefinedValue.name">
                  {{undefinedValue.label}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="singleFieldContainer" id='Chromosome Aberration, Human Lymphocytes'>
            <mat-form-field class="angularSearchWrapper">
              <mat-label>Chromosome Aberration, Human Lymphocytes</mat-label>
              <mat-select
                (selectionChange)="picklistValueToArray('chromosome_aberration_human_lymphocytes__c', 'Chromosome Aberration, Human Lymphocytes', chromosome_aberration_human_lymphocytes__c)"
                [(ngModel)]="chromosome_aberration_human_lymphocytes__c">
                <mat-option value="">All</mat-option>
                <mat-option
                  *ngFor="let undefinedValue of filterAdditionalFieldValues(receivedAdditionalFieldValues, 'chromosome_aberration_human_lymphocytes__c')"
                  [value]="undefinedValue.name">
                  {{undefinedValue.label}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="singleFieldContainer" id='Mouse Lymphoma Assay'>
            <mat-form-field class="angularSearchWrapper">
              <mat-label>Mouse Lymphoma Assay</mat-label>
              <mat-select
                (selectionChange)="picklistValueToArray('mouse_lymphoma_assay__c', 'Mouse Lymphoma Assay', mouse_lymphoma_assay__c)"
                [(ngModel)]="mouse_lymphoma_assay__c">
                <mat-option value="">All</mat-option>
                <mat-option
                  *ngFor="let undefinedValue of filterAdditionalFieldValues(receivedAdditionalFieldValues, 'mouse_lymphoma_assay__c')"
                  [value]="undefinedValue.name">
                  {{undefinedValue.label}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="singleFieldContainer" id='HPRT Test'>
            <mat-form-field class="angularSearchWrapper">
              <mat-label>HPRT Test</mat-label>
              <mat-select
                (selectionChange)="picklistValueToArray('hprt_test__c', 'HPRT Test', hprt_test__c)"
                [(ngModel)]="hprt_test__c">
                <mat-option value="">All</mat-option>
                <mat-option
                  *ngFor="let undefinedValue of filterAdditionalFieldValues(receivedAdditionalFieldValues, 'hprt_test__c')"
                  [value]="undefinedValue.name">
                  {{undefinedValue.label}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="singleFieldContainer" id='Ames Test'>
            <mat-form-field class="angularSearchWrapper">
              <mat-label>Ames Test</mat-label>
              <mat-select
                (selectionChange)="picklistValueToArray('ames_test__c', 'Ames Test', ames_test__c)"
                [(ngModel)]="ames_test__c">
                <mat-option value="">All</mat-option>
                <mat-option
                  *ngFor="let amesTestValue of filterAdditionalFieldValues(receivedAdditionalFieldValues, 'ames_test__c')"
                  [value]="amesTestValue.name">
                  {{amesTestValue.label}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="singleFieldContainer" id='Target Organ'>
            <mat-form-field class="angularSearchWrapper">
              <mat-label>Target Organ</mat-label>
              <mat-select
                (selectionChange)="picklistValueToArray('target_organ__c', 'Target Organ', target_organ__c)"
                [(ngModel)]="target_organ__c">
                <mat-option value="">All</mat-option>
                <mat-option
                  *ngFor="let undefinedValue of filterAdditionalFieldValues(receivedAdditionalFieldValues, 'target_organ__c')"
                  [value]="undefinedValue.name">
                  {{undefinedValue.label}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="singleFieldContainer" id='Test Method Skin Sensitization'>
            <mat-form-field class="angularSearchWrapper">
              <mat-label>Test Method Skin Sensitization</mat-label>
              <mat-select
                (selectionChange)="picklistValueToArray('test_method_skin_sensitization__c', 'Test Method Skin Sensitization', test_method_skin_sensitization__c)"
                [(ngModel)]="test_method_skin_sensitization__c">
                <mat-option value="">All</mat-option>
                <mat-option
                  *ngFor="let undefinedValue of filterAdditionalFieldValues(receivedAdditionalFieldValues, 'test_method_skin_sensitization__c')"
                  [value]="undefinedValue.name">
                  {{undefinedValue.label}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="singleFieldContainer" id='Route of Administration'>
            <mat-form-field class="angularSearchWrapper">
              <mat-label>Route of Administration</mat-label>
              <mat-select
                (selectionChange)="picklistValueToArray('route_of_administration__c', 'Route of Administration', route_of_administration__c)"
                [(ngModel)]="route_of_administration__c">
                <mat-option value="">All</mat-option>
                <mat-option
                  *ngFor="let undefinedValue of filterAdditionalFieldValues(uniqueAdditionalFieldValues, 'route_of_administration__c')"
                  [value]="undefinedValue.name">
                  {{undefinedValue.label}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="singleFieldContainer" id='Transgenic Rodent Assay, in vitro'>
            <mat-form-field class="angularSearchWrapper">
              <mat-label>Transgenic Rodent Assay, in vitro</mat-label>
              <mat-select
                (selectionChange)="picklistValueToArray('transgenic_rodent_assay_in_vitro__c', 'Transgenic Rodent Assay, in vitro', transgenic_rodent_assay_in_vitro__c)"
                [(ngModel)]="transgenic_rodent_assay_in_vitro__c">
                <mat-option value="">All</mat-option>
                <mat-option
                  *ngFor="let undefinedValue of filterAdditionalFieldValues(uniqueAdditionalFieldValues, 'transgenic_rodent_assay_in_vitro__c')"
                  [value]="undefinedValue.name">
                  {{undefinedValue.label}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="singleFieldContainer" id='Comet Assay, in vitro'>
            <mat-form-field class="angularSearchWrapper">
              <mat-label>Comet Assay, in vitro</mat-label>
              <mat-select
                (selectionChange)="picklistValueToArray('comet_assay_in_vitro__c', 'Comet Assay, in vitro', comet_assay_in_vitro__c)"
                [(ngModel)]="comet_assay_in_vitro__c">
                <mat-option value="">All</mat-option>
                <mat-option
                  *ngFor="let undefinedValue of filterAdditionalFieldValues(uniqueAdditionalFieldValues, 'comet_assay_in_vitro__c')"
                  [value]="undefinedValue.name">
                  {{undefinedValue.label}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="singleFieldContainer" id='Transgenic Rodent Assay, in vivo'>
            <mat-form-field class="angularSearchWrapper">
              <mat-label>Transgenic Rodent Assay, in vivo</mat-label>
              <mat-select
                (selectionChange)="picklistValueToArray('transgenic_rodent_assay_in_vivo__c', 'Transgenic Rodent Assay, in vivo', transgenic_rodent_assay_in_vivo__c)"
                [(ngModel)]="transgenic_rodent_assay_in_vivo__c">
                <mat-option value="">All</mat-option>
                <mat-option
                  *ngFor="let undefinedValue of filterAdditionalFieldValues(uniqueAdditionalFieldValues, 'transgenic_rodent_assay_in_vivo__c')"
                  [value]="undefinedValue.name">
                  {{undefinedValue.label}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="singleFieldContainer" id='ED Mechanistic Study'>
            <mat-form-field class="angularSearchWrapper">
              <mat-label>ED Mechanistic Study</mat-label>
              <mat-select
                (selectionChange)="picklistValueToArray('ed_mechanistic_study__c', 'ED Mechanistic Study', ed_mechanistic_study__c)"
                [(ngModel)]="ed_mechanistic_study__c">
                <mat-option value="">All</mat-option>
                <mat-option
                  *ngFor="let undefinedValue of filterAdditionalFieldValues(uniqueAdditionalFieldValues, 'ed_mechanistic_study__c')"
                  [value]="undefinedValue.name">
                  {{undefinedValue.label}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="singleFieldContainer" id='EATS and Developmental Parameters Affected'>
            <mat-form-field class="angularSearchWrapper">
              <mat-label>EATS and Developmental Parameters Affected</mat-label>
              <mat-select
                (selectionChange)="picklistValueToArray('eats_and_developmental_parameters_affect__c', 'EATS and Developmental Parameters Affected', eats_and_developmental_parameters_affect__c)"
                [(ngModel)]="eats_and_developmental_parameters_affect__c">
                <mat-option value="">All</mat-option>
                <mat-option
                  *ngFor="let undefinedValue of filterAdditionalFieldValues(receivedAdditionalFieldValues, 'eats_and_developmental_parameters_affect__c')"
                  [value]="undefinedValue.name">
                  {{undefinedValue.label}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="singleFieldContainer" id='Mechanistic Studies'>
            <mat-form-field class="angularSearchWrapper">
              <mat-label>Mechanistic Studies</mat-label>
              <mat-select
                (selectionChange)="picklistValueToArray('mechanistic_studies__c', 'Mechanistic Studies', mechanistic_studies__c)"
                [(ngModel)]="mechanistic_studies__c">
                <mat-option value="">All</mat-option>
                <mat-option
                  *ngFor="let undefinedValue of filterAdditionalFieldValues(receivedAdditionalFieldValues, 'mechanistic_studies__c')"
                  [value]="undefinedValue.name">
                  {{undefinedValue.label}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="singleFieldContainer" id='Mechanism T-Modality'>
            <mat-form-field class="angularSearchWrapper">
              <mat-label>Mechanism T-Modality</mat-label>
              <mat-select
                (selectionChange)="picklistValueToArray('mechanism_tmodality__c', 'Mechanism T-Modality', mechanism_tmodality__c)"
                [(ngModel)]="mechanism_tmodality__c">
                <mat-option value="">All</mat-option>
                <mat-option
                  *ngFor="let undefinedValue of filterAdditionalFieldValues(receivedAdditionalFieldValues, 'mechanism_tmodality__c')"
                  [value]="undefinedValue.name">
                  {{undefinedValue.label}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="singleFieldContainer" id='ED Modality'>
            <mat-form-field class="angularSearchWrapper">
              <mat-label>ED Modality</mat-label>
              <mat-select
                (selectionChange)="picklistValueToArray('ed_modality__c', 'ED Modality', ed_modality__c)"
                [(ngModel)]="ed_modality__c">
                <mat-option value="">All</mat-option>
                <mat-option
                  *ngFor="let undefinedValue of filterAdditionalFieldValues(receivedAdditionalFieldValues, 'ed_modality__c')"
                  [value]="undefinedValue.name">
                  {{undefinedValue.label}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="singleFieldContainer" id='LOAEL Immunotoxicity (mg/kg bw/d)'>
            <mat-form-field class="angularSearchWrapper">
              <mat-label>LOAEL Immunotoxicity (mg/kg bw/d)</mat-label>
              <mat-select
                (selectionChange)="picklistValueToArray('loael_immunotoxicity_mgkg_bwd__c', 'LOAEL Immunotoxicity (mg/kg bw/d)', loael_immunotoxicity_mgkg_bwd__c)"
                [(ngModel)]="loael_immunotoxicity_mgkg_bwd__c">
                <mat-option value="">All</mat-option>
                <mat-option
                  *ngFor="let undefinedValue of filterAdditionalFieldValues(receivedAdditionalFieldValues, 'loael_immunotoxicity_mgkg_bwd__c')"
                  [value]="undefinedValue.name">
                  {{undefinedValue.label}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="singleFieldContainer" id='NOAEL Immunotoxicity (mg/kg bw/d)'>
            <mat-form-field class="angularSearchWrapper">
              <mat-label>NOAEL Immunotoxicity (mg/kg bw/d)</mat-label>
              <mat-select
                (selectionChange)="picklistValueToArray('noael_immunotoxicity_mgkg_bwd__c', 'NOAEL Immunotoxicity (mg/kg bw/d)', noael_immunotoxicity_mgkg_bwd__c)"
                [(ngModel)]="noael_immunotoxicity_mgkg_bwd__c">
                <mat-option value="">All</mat-option>
                <mat-option
                  *ngFor="let undefinedValue of filterAdditionalFieldValues(receivedAdditionalFieldValues, 'noael_immunotoxicity_mgkg_bwd__c')"
                  [value]="undefinedValue.name">
                  {{undefinedValue.label}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="singleFieldContainer" id='NK Cell Activity Test'>
            <mat-form-field class="angularSearchWrapper">
              <mat-label>NK Cell Activity Test</mat-label>
              <mat-select
                (selectionChange)="picklistValueToArray('nk_cell_activity_test__c', 'NK Cell Activity Test', nk_cell_activity_test__c)"
                [(ngModel)]="nk_cell_activity_test__c">
                <mat-option value="">All</mat-option>
                <mat-option
                  *ngFor="let undefinedValue of filterAdditionalFieldValues(receivedAdditionalFieldValues, 'nk_cell_activity_test__c')"
                  [value]="undefinedValue.name">
                  {{undefinedValue.label}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="singleFieldContainer" id='Lymphocyte Subpopulation'>
            <mat-form-field class="angularSearchWrapper">
              <mat-label>Lymphocyte Subpopulation</mat-label>
              <mat-select
                (selectionChange)="picklistValueToArray('lymphocyte_subpopulation__c', 'Lymphocyte Subpopulation', lymphocyte_subpopulation__c)"
                [(ngModel)]="lymphocyte_subpopulation__c">
                <mat-option value="">All</mat-option>
                <mat-option
                  *ngFor="let undefinedValue of filterAdditionalFieldValues(receivedAdditionalFieldValues, 'lymphocyte_subpopulation__c')"
                  [value]="undefinedValue.name">
                  {{undefinedValue.label}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="singleFieldContainer" id='SRBC Assay'>
            <mat-form-field class="angularSearchWrapper">
              <mat-label>SRBC Assay</mat-label>
              <mat-select
                (selectionChange)="picklistValueToArray('srbc_assay__c', 'SRBC Assay', srbc_assay__c)"
                [(ngModel)]="srbc_assay__c">
                <mat-option value="">All</mat-option>
                <mat-option
                  *ngFor="let undefinedValue of filterAdditionalFieldValues(receivedAdditionalFieldValues, 'srbc_assay__c')"
                  [value]="undefinedValue.name">
                  {{undefinedValue.label}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="singleFieldContainer" id='Qstar Tumor Site'>
            <mat-form-field class="angularSearchWrapper">
              <mat-label>Qstar Tumor Site</mat-label>
              <mat-select
                (selectionChange)="picklistValueToArray('qstar_tumor_site__c', 'Qstar Tumor Site', qstar_tumor_site__c)"
                [(ngModel)]="qstar_tumor_site__c">
                <mat-option value="">All</mat-option>
                <mat-option
                  *ngFor="let undefinedValue of filterAdditionalFieldValues(receivedAdditionalFieldValues, 'qstar_tumor_site__c')"
                  [value]="undefinedValue.name">
                  {{undefinedValue.label}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="singleFieldContainer" id='US Cancer RA'>
            <mat-form-field class="angularSearchWrapper">
              <mat-label>US Cancer RA</mat-label>
              <mat-select
                (selectionChange)="picklistValueToArray('us_cancer_ra__c', 'US Cancer RA', us_cancer_ra__c)"
                [(ngModel)]="us_cancer_ra__c">
                <mat-option value="">All</mat-option>
                <mat-option
                  *ngFor="let undefinedValue of filterAdditionalFieldValues(receivedAdditionalFieldValues, 'us_cancer_ra__c')"
                  [value]="undefinedValue.name">
                  {{undefinedValue.label}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <!-- NUMBER FIELDS -->

          <!-- -->

        </div>

        <div class="searchRow">
          <div class="singleFieldContainer">
            <mat-form-field class="angularSearchWrapper">
              <mat-label>Order By</mat-label>
              <mat-select (selectionChange)="defineSortOrder()" [(value)]="sortByFieldName">
                <mat-option value="id">Latest</mat-option>
                <mat-option value="category_name__c">Category</mat-option>
                <mat-option value="substance_type__c">Substance Type</mat-option>
                <mat-option value="related_substance__c">Related Substance</mat-option>
                <mat-option value="study_type__c">Study Type</mat-option>
                <mat-option value="endpoint__c">Endpoint</mat-option>
                <mat-option value="document__c">Source</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="singleFieldContainer">
            <button (click)="getEndpointsWithFilters(this.pageSize, 0)" [hidden]="searchButtonHidden"
                    class="searchButton" data-cy="ResetFilters" title="Apply Filters"><span class="material-icons">search</span>
            </button>
            <div [hidden]="loaderHidden" class="loaderHolder">
              <div class="loader"></div>
            </div>
          </div>
        </div>

        <!-- the list of the records in Vault -->
        <app-existing-tox-endpoint-record
          (reduceRecordCount)="reduceCount()"
          (refreshEndpoints)="getEndpointsWithFilters(this.pageSize, 0);"
          *ngFor="let singleRecord of existingEndpoints; index as i;"
          [allAdditionalFieldValues]="receivedAdditionalFieldValues"
          [allAdditionalFields]="receivedAllAdditionalFields"
          [allEndpointUnits]="receivedAllEndpointUnits"
          [allEndpoints]="receivedAllEndpoints"
          [allStudyTypes]="receivedAllStudyTypes"
          [allTestSystemNames]="receivedAllTestSystemNames"
          [allRouteOfAdministrations]="receivedAllRouteOfAdministration"
          [allTestSystemTypes]="receivedAllTestSystemTypes"
          [allUsers]="allUsers"
          [endpointRecord]="singleRecord"
          [categories]="receivedCategories"
          [categoryPicklists]="receivedCategoryPicklists"
          [dataSourceLength]="dataSourceLength"
          [documents]="documents"
          [operators]="receivedAllOperators"
          [relatedSubstances]="relatedSubstances"
          [substanceTypes]="substanceTypes"
          [subtractNumber]="i"
          class="recordsContainer"
        >
        </app-existing-tox-endpoint-record>

        <mat-paginator (page)="pageEvent = $event; onPaginateChange($event)"
                       [length]="dataSourceLength"
                       [pageSizeOptions]="[5, 10, 25, 100]"
                       [pageSize]="pageSize"
                       class="paginator"
                       style="float: left;">
        </mat-paginator>

      </ng-container>

    </mat-expansion-panel>
  </mat-accordion>

</div>
