<div [ngStyle]="{'z-index':singleEndpointRecord.id__c}" class="fieldsHolder" id="{{singleEndpointRecord.id__c}}">

  <form [formGroup]="selectFormGroup">
    <div class="rightConner">
      <button *ngIf="showCopyOptions" (click)="removeEndpoint(singleEndpointRecord)" class="delete" data-cy="RemoveEndpoint"
              title="Remove Endpoint"><span class="material-icons">remove_circle_outline</span></button>
      <button *ngIf="showCopyOptions" (click)="copyEndpoint(singleEndpointRecord)" class="copy" data-cy="CopyEndpoint"
              title="Copy Endpoint"><span class="material-icons">content_copy</span></button>
      <button *ngIf="showEditOptions" (click)="cancelEditing(singleEndpointRecord)" class="delete" data-cy="RemoveEndpoint"
              title="Remove Endpoint"><span class="material-icons">cancel</span></button>
      <button *ngIf="showEditOptions" (click)="saveEndpointChanges(this.singleEndpointRecord)" class="copy" data-cy="SaveEndpoint"
              title="Save Changes"><span class="material-icons">check_circle</span></button>
    </div>

    <div class="singleFieldContainer">
      <div class="formItemTitle">Category:</div>
      <div>
        <select class="formSelector" name="category">
          <option value="{{category.id}}">{{category.name__v}}</option>
        </select>
      </div>
    </div>

    <div class="singleFieldContainer">
      <div class="formItemTitle">Source:</div>
      <select (change)="updateFieldValue($event.target.valueOf())" class="formSelectorForDocument"
              formControlName="selectedDocument" name="source" title="document_unbound__c">
        <option *ngFor="let document of documents" value="{{ document.id }}">{{ document.name }}</option>
      </select>
    </div>

    <div class="singleFieldContainer">
      <div class="formItemTitle">Waiver/Data Gap:</div>
      <div>
        <select (change)="updateFieldValue($event.target.valueOf())"
                class="formSelector"
                formControlName="waiverdata_gap__c"
                title="waiverdata_gap__c"
        >
          <option value=""></option>
          <option *ngFor="let waiverDataGap of waiverDataGaps"
                  [value]="waiverDataGap.name">{{ waiverDataGap.label }}</option>
        </select>
      </div>
    </div>

    <div class="singleFieldContainer">
      <div class="formItemTitle">Study Type:</div>
      <div>
        <mat-form-field class="multiPicklistFormSelector">
          <mat-select
            (selectionChange)="onSelectStudyType($event)"
            class="formSelector"
            formControlName="studyType"
            multiple
            ngDefaultControl title="study_type__c">
            <mat-option *ngFor="let studyType of studyTypes"
                        [title]="studyType.picklist"
                        [value]="studyType.name">{{ studyType.label }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>

    <div class="singleFieldContainer">
      <div class="formItemTitle">Comment:</div>
      <div>
        <textarea (change)="updateFieldValue($event.target.valueOf())"
                  (keyup)="updateFieldValue($event.target.valueOf())"
                  formControlName="comment__c" title="comment__c" class="formFreeText" rows="1">
        </textarea>
      </div>
    </div>

  </form>

</div>

