import {Component, Input, OnInit} from '@angular/core';
import {VaultService} from "../../service/vault.service";

@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.css']
})
export class CardComponent implements OnInit {

  constructor(private service: VaultService) {
  }

  ngOnInit(): void {
  }

  @Input() title: string;


  onExport() {
    this.service.sendExportClickEvent();
  }

  onChartSave() {
    this.service.sendSaveChartClickEvent();
  }

}
