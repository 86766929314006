import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {PicklistValue} from '../../models/picklist-value';
import {ConsumerSafetyCategoryPicklists} from '../models/consumer-safety-category-picklists';
import {ConsumerSafetyAdditionalFields} from '../models/consumer-safety-additional-fields';
import {ConsumerSafetyExisting} from '../models/consumer-safety-existing';
import {DocumentMenuItem} from '../../models/document-menu-item';
import {ConsumerSafetyEndpointTableItem} from '../models/consumer-safety-endpoint-table-item';
import {convertArrayToString} from '../../../shared/convert-array-to-string';
import {CreateObjectResponse, CreateObjectsResponse} from '../../models/create-objects-response';
import {VaultError} from '../../../core/models/vault-error';
import {HttpErrorResponse} from '@angular/common/http';
import {ServerService} from '../../../core/server.service';
import {ExceptionService} from '../../../core/exception.service';
import {ToastrService} from 'ngx-toastr';
import {MatDialog} from '@angular/material/dialog';
import {MatDialogComponent} from '../../mat-dialog/mat-dialog.component';
import {SingleRecordDependencies} from "../models/single-record-dependencies";
import {convertSpecialFieldsToArray} from "../../../shared/convert-special-fields";
import {convertSpecialFieldsToString} from "../../../shared/convert-special-fields";


@Component({
  selector: 'app-consumer-safety-record-existing',
  templateUrl: './consumer-safety-record-existing.component.html',
  styleUrls: ['./consumer-safety-record-existing.component.css']
})
export class ConsumerSafetyRecordExistingComponent implements OnInit {
  @Input() endpointRecord: ConsumerSafetyExisting;
  @Input() categories: Array<PicklistValue>;
  @Input() categoryPicklists: Array<ConsumerSafetyCategoryPicklists>;
  @Input() allStudyTypes: Array<PicklistValue>;
  @Input() allTestSystemTypes: Array<PicklistValue>;
  @Input() allTestSystemNames: Array<PicklistValue>;
  @Input() allAdditionalFields: Array<ConsumerSafetyAdditionalFields>;
  @Input() allAdditionalFieldValues: any;
  @Input() singleRecordDependencies: Array<SingleRecordDependencies>;
  @Input() substanceTypes: Array<PicklistValue>;
  @Input() relatedSubstances: any;
  @Input() identifiedMetabolites:Array<ConsumerSafetyAdditionalFields>;
  @Input() analyteNames: Array<ConsumerSafetyAdditionalFields>;
  @Input() substanceAsAnalyteNames: Array<ConsumerSafetyAdditionalFields>;
  @Input() singleRecordDependenciesModelAdditionalFieldValues: Array<ConsumerSafetyAdditionalFields>;
  @Input() documents: Array<DocumentMenuItem>;
  @Input() allUsers: any;
  @Input() dataSourceLength: number;
  @Input() subtractNumber: number;

  @Output() reduceRecordCount: EventEmitter<number> = new EventEmitter();
  @Output() refreshEndpoints: EventEmitter<any> = new EventEmitter();

  public categoryStudyTypes: Array<PicklistValue>;
  public categoryTestSystemTypes: Array<PicklistValue>;
  public categoryTestSystemNames: Array<PicklistValue>;
  public categoryAdditionalFields: Array<ConsumerSafetyAdditionalFields>;

  public saveButtonShowing: boolean;
  public category: any;
  public categoryName: string;
  public zindex: number;
  public copiedEndpointsArrayList: Array<ConsumerSafetyEndpointTableItem>;
  private sessionId: string;
  public startingId: number;
  public maxCategoryId: number;
  public successRecords: any;
  public errorMessagesToUser: any[];
  public successMessagesToUser: string;
  loading = false;
  disabled = false;
  showErrorMessage = false;
  showSuccessMessage = false;
  loadingDataHidden: false;
  subscriptions: any;
  public dialogTitle: string;
  public dialogRow1: string;

  constructor(
    private route: ActivatedRoute,
    private serverService: ServerService,
    private exceptionService: ExceptionService,
    private toastrService: ToastrService,
    public dialog: MatDialog
  ) {

    this.zindex = 0;
    this.route.paramMap.subscribe((params) => {
      this.sessionId = params.get('sessionId');
    });
    this.copiedEndpointsArrayList = [];
    this.startingId = 0;
    this.maxCategoryId = 10000000;
    this.saveButtonShowing = false;
    this.successRecords = [];
    this.errorMessagesToUser = [];
    this.successMessagesToUser = '';
    this.dialogTitle = 'Confirm Refresh';
    this.dialogRow1 = 'Please, save all copied Endpoints before refreshing the Existing Endpoints list !';
  }

  ngOnInit(): void {
    this.zindex = this.dataSourceLength - this.subtractNumber;

    this.category = this.categories.find(
      (c) => c.id === this.endpointRecord.category__c);

    this.categoryName = this.category.name__v;

    this.filterStudyTypes();
    this.filterTestSystemNames();
    this.filterTestSystemTypes();
    this.filterAdditionalFields();
  }

  filterStudyTypes(): void {
    this.categoryStudyTypes = this.allStudyTypes.filter(
      (entry) => entry.id === this.category.id
    );
  }

  filterTestSystemTypes(): void {
    this.categoryTestSystemTypes = this.allTestSystemTypes.filter(
      (entry) => entry.id === this.category.id
    );
  }

  filterTestSystemNames(): void {
    this.categoryTestSystemNames = this.allTestSystemNames.filter(
      (entry) => entry.id === this.category.id
    );
  }

  filterAdditionalFields(): void {
    this.categoryAdditionalFields = this.allAdditionalFields.filter(
      (entry) => entry.categoryId === this.category.id
    );
  }

  addNewEndpoint(existingEndpoint): void {

    // make object
    const newEndpointTemplate: ConsumerSafetyEndpointTableItem = {
      id__c: this.generateId(),
      work_area__c: existingEndpoint.work_area__c,
      category__c: existingEndpoint.category__c,
      substance_name__c: existingEndpoint.substance_name__c,
      substance_type__c: existingEndpoint.substance_type__c ?? 'parent__c',
      related_substance__c: existingEndpoint.related_substance__c ?? '',
      study_type__c: existingEndpoint.study_type__c ?? [],
      // additional fields
      test_system_type__c: existingEndpoint.test_system_type__c ?? [],
      test_system_name__c: existingEndpoint.test_system_name__c ?? [],
      residue_type__c: existingEndpoint.residue_type__c ?? '',
      residue_name__c: existingEndpoint.residue_name__c ?? '',
      waiverdata_gap__c: existingEndpoint.waiverdata_gap__c ?? '',
      application_type__c: existingEndpoint.application_type__c ?? '',
      application_rate1__c: existingEndpoint.application_rate1__c ?? '',
      unit_application_rate__c: existingEndpoint.unit_application_rate__c ?? '',
      replant_interval_days__c: existingEndpoint.replant_interval_days__c ?? '',
      open_radio_label_topic__c: existingEndpoint.open_radio_label_topic__c ?? '',
      open_id_rate_topic__c: existingEndpoint.open_id_rate_topic__c ?? '',
      open_isomer_topic__c: existingEndpoint.open_isomer_topic__c ?? '',
      adequate_study__c: existingEndpoint.adequate_study__c ?? '',
      residue_uptake_001_mgkg__c: existingEndpoint.residue_uptake_001_mgkg__c ?? '',
      plateau_reached_in_milkeggs__c: existingEndpoint.plateau_reached_in_milkeggs__c ?? '',
      time_needed_to_reach_plateau_in_milkegg__c: existingEndpoint.time_needed_to_reach_plateau_in_milkegg__c ?? '',
      parent_only__c: existingEndpoint.parent_only__c ?? '',
      identified_metabolites__c: existingEndpoint.identified_metabolites__c ?? '',
      similar_to_metabolism_study__c: existingEndpoint.similar_to_metabolism_study__c ?? '',
      fat_soluble_residues__c: existingEndpoint.fat_soluble_residues__c ?? '',
      similar_to_other_crop_groups__c: existingEndpoint.similar_to_other_crop_groups__c ?? '',
      similar_to_primary_crops__c: existingEndpoint.similar_to_primary_crops__c ?? '',
      similar_to_other_species__c: existingEndpoint.similar_to_other_species__c ?? '',
      similar_to_rat__c: existingEndpoint.similar_to_rat__c ?? '',
      human_unique_or_disproportional_metaboli__c: existingEndpoint.human_unique_or_disproportional_metaboli__c ?? '',
      maximum_abundancy_in_foodedibles_trr__c: existingEndpoint.maximum_abundancy_in_foodedibles_trr__c ?? '',
      maximum_abundancy_in_foodedibles_mg__c: existingEndpoint.maximum_abundancy_in_foodedibles_mg__c ?? '',
      maximum_abundancy_in_feed_trr__c: existingEndpoint.maximum_abundancy_in_feed_trr__c ?? '',
      maximum_abundancy_in_feed_mgkg__c: existingEndpoint.maximum_abundancy_in_feed_mgkg__c ?? '',
      maximum_abundancy_in_inedibles__c: existingEndpoint.maximum_abundancy_in_inedibles__c ?? '',
      fat_soluble__c: existingEndpoint.fat_soluble__c ?? '',
      tox_species_coverage_10_administred_d__c: existingEndpoint.tox_species_coverage_10_administred_d__c ?? '',
      administered_dose__c: existingEndpoint.administered_dose__c ?? '',
      matrix_tox_species__c: existingEndpoint.matrix_tox_species__c ?? '',
      included_in_rd__c: existingEndpoint.included_in_rd__c ?? '',
      rd_components__c: existingEndpoint.rd_components__c ?? [],
      rd_wording__c: existingEndpoint.rd_wording__c ?? '',
      rd_status__c: existingEndpoint.rd_status__c ?? '',
      stability__c: existingEndpoint.stability__c ?? '',
      unit_stability__c: existingEndpoint.unit_stability__c ?? '',
      similar_to_other_commodities_of_same_cat__c: existingEndpoint.similar_to_other_commodities_of_same_cat__c ?? '',
      similar_to_other_categories__c: existingEndpoint.similar_to_other_categories__c ?? '',
      similar_to_other_animalsof_the_same_anim__c: existingEndpoint.similar_to_other_animalsof_the_same_anim__c ?? '',
      similar_to_other_animal_commodities__c: existingEndpoint.similar_to_other_animal_commodities__c ?? '',
      sufficient_data_package__c: existingEndpoint.sufficient_data_package__c ?? '',
      matrix__c: existingEndpoint.matrix__c ?? '',
      analyte_type__c: existingEndpoint.analyte_type__c ?? '',
      analyte_name__c: existingEndpoint.analyte_name__c ?? '',
      residue_001_mgkg__c: existingEndpoint.residue_001_mgkg__c ?? '',
      worst_case_plateau_covered__c: existingEndpoint.worst_case_plateau_covered__c ?? '',
      replant_restrictions__c: existingEndpoint.replant_restrictions__c ?? '',
      replant_restriction_days__c: existingEndpoint.replant_restriction_days__c ?? '',
      rotational_mrls_required__c: existingEndpoint.rotational_mrls_required__c ?? '',
      processing_factor__c: existingEndpoint.processing_factor__c ?? '',
      dose_level_mgkg_dm_diet__c: existingEndpoint.dose_level_mgkg_dm_diet__c ?? '',
      dose_level_mgkg_bwday__c: existingEndpoint.dose_level_mgkg_bwday__c ?? '',
      residues_in_edible_matrices_001_mgkg__c: existingEndpoint.residues_in_edible_matrices_001_mgkg__c ?? '',
      sum_of_analytes_005_mgkg__c: existingEndpoint.sum_of_analytes_005_mgkg__c ?? '',
      included_uses__c: existingEndpoint.included_uses__c ?? '',
      refinement_level__c: existingEndpoint.refinement_level__c ?? '',
      risk_assessment_status__c: existingEndpoint.risk_assessment_status__c ?? '',
      toxicological_endpoint_utilization__c: existingEndpoint.toxicological_endpoint_utilization__c ?? '',
      unittoxicological_endpoint_utilization__c: existingEndpoint.unittoxicological_endpoint_utilization__c ?? '',
      driver_population__c: existingEndpoint.driver_population__c ?? '',
      driver_crop__c: existingEndpoint.driver_crop__c ?? '',
      factors_included_in_the_calculations__c: existingEndpoint.factors_included_in_the_calculations__c ?? '',
      ra_relevant__c: existingEndpoint.ra_relevant__c ?? '',
      comment__c: existingEndpoint.comment__c ?? '',
      // default
      study_type_picklist_name__c: existingEndpoint.study_type_picklist_name__c ?? '', // field used for capturing additional (depended) fields
      document_unbound__c: existingEndpoint.document_unbound__c ?? '',
    };

    this.copiedEndpointsArrayList.push(newEndpointTemplate);
  }

  generateId(): number {
    const newStartingId = this.startingId + 1;
    this.updateStartingId(newStartingId);
    return this.maxCategoryId - newStartingId;
  }

  updateStartingId(newStartingId): void {
    this.startingId = newStartingId;
  }

  copyEndpointInput(receivedEndpoint: any): void {
    this.showSuccessMessage = false;
    this.saveButtonShowing = true;


    // convert record values to string values
    const endpoint = JSON.parse(
      JSON.stringify(receivedEndpoint)
    );

    convertArrayToString(
      receivedEndpoint,
      endpoint,
      []
    );

    convertSpecialFieldsToArray(endpoint, ['study_type__c', 'test_system_type__c', 'test_system_name__c', 'rd_components__c']);

    this.copiedEndpointsArrayList.filter(it => it.id__c === endpoint.id)
      .forEach(newEndpoint => {
        this.addNewEndpoint(newEndpoint);
      });

    this.addNewEndpoint(endpoint);
  }

  saveCopies(): void {
    this.loading = true;
    this.disabled = true;
    this.serverService.isLoading();

    this.showErrorMessage = false;
    this.successRecords = [];
    this.errorMessagesToUser = [];

    console.log('this.copiedEndpointsArrayList: ' + JSON.stringify(this.copiedEndpointsArrayList));

    this.copiedEndpointsArrayList.forEach(copiedRecord => {
      convertSpecialFieldsToString(copiedRecord, ['study_type__c', 'test_system_type__c', 'test_system_name__c', 'rd_components__c']);
    });

    const body = this.copiedEndpointsArrayList;
    this.serverService.postEndpoint(this.sessionId, body).subscribe(
      (success: CreateObjectsResponse) => {
        let created = 0;
        success.data?.forEach((record: CreateObjectResponse) => {
          if (record?.responseStatus === 'SUCCESS') {
            created++;
          }
          if (record?.responseStatus === 'FAILURE') {
            this.showErrorMessage = true;
            record?.errors?.forEach((error: VaultError) => {
              this.toastrService.error(error.message, error.type);
              this.errorMessagesToUser = this.errorMessagesToUser.concat(error.message);
            });
          }
        });
        this.toastrService.info(
          `${created} Endpoint(s) created successfully.`,
          'Info'
        );

        this.loading = false;
        this.disabled = false;
        const response = success.data;

        for (let i = 0; i < this.copiedEndpointsArrayList.length; i++) {
          for (let j = 0; j < response.length; j++) {
            if (i === j) {
              if (response[j].responseStatus === 'SUCCESS') {
                this.successRecords.push(this.copiedEndpointsArrayList[i].id__c);
              }
            }
          }
        }
        this.clearSuccessRecords();
        console.log('this.successRecords: ' + JSON.stringify(this.successRecords));
      },
      (error: HttpErrorResponse) => {
        this.exceptionService.handleError(error);
        this.toastrService.error(
          `An error occurred while saving endpoints.`,
          'Error'
        );
      },
    );
    this.serverService.isNotLoading();
  }

  clearSuccessRecords(): any {
    for (const recordId of this.successRecords) {
      // define new copiedEndpointArrayList by leaving out success records
      this.copiedEndpointsArrayList = this.copiedEndpointsArrayList.filter(
        obj => obj.id__c !== recordId
      );
    }
    // check if there is any endpoints in the list, if not remove 'Save' button
    if (this.copiedEndpointsArrayList.length === 0) {
      this.saveButtonShowing = false;
    }

    if (this.successRecords.length > 0) {
      this.showSuccessMessage = true;
      this.successMessagesToUser = this.successRecords.length + ' Endpoint/s saved successfully !';
    }
  }

  removeEndpointInput(endpoint: ConsumerSafetyEndpointTableItem): void {
    // remove object from array
    for (let index = 0; index < this.copiedEndpointsArrayList.length; index++) {
      const element: ConsumerSafetyEndpointTableItem = this.copiedEndpointsArrayList[index];
      if (element.id__c === endpoint.id__c) {
        this.copiedEndpointsArrayList.splice(index, 1);
      }
    }
    // check if there is any endpoints in the list, if not remove 'Save' button
    if (this.copiedEndpointsArrayList.length === 0) {
      this.saveButtonShowing = false;
    }
  }

  refreshEndpointList(): void {
    this.refreshEndpoints.emit();
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(MatDialogComponent, {
      width: 'auto',
      data: {dialogTitle: this.dialogTitle, dialogRow1: this.dialogRow1},
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      if (result === 'confirmed') {
        this.refreshEndpointList();
      }
    });
  }
}
