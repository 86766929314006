import {Component, OnInit} from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';
import {AbstractControl, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import {ActivatedRoute} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {ExceptionService} from 'src/app/core/exception.service';
import {ServerService} from 'src/app/core/server.service';
import {EndpointServicesService} from '../endpoint-services.service';
import {DocumentMenuItem} from '../models/document-menu-item';
import {SubstanceItem} from '../models/substance-item';
import {PicklistValue} from '../models/picklist-value';
import {RelatedSubstances} from '../models/related-substances';

let me: EndpointHomeComponent;

@Component({
  selector: 'app-endpoint-home',
  templateUrl: './endpoint-home.component.html',
  styleUrls: ['./endpoint-home.component.css'],
})
export class EndpointHomeComponent implements OnInit {
  /**
   * for documents filtering
   */
  searchControl: FormControl = new FormControl();
  filteredOptions: Observable<any>;
  /** */

  subscriptions: any;
  public selectedDocument: DocumentMenuItem;
  public substanceTypeLabel: string;
  public documents: Array<DocumentMenuItem>;
  public substanceItem: SubstanceItem;
  public WorkAreaSelectedLabel: string;
  public WorkAreaSelectedId;
  public workAreas: Array<PicklistValue>;
  public substanceTypes: Array<PicklistValue>;
  public relatedSubstances: Array<RelatedSubstances>;
  selectFormGroup: FormGroup;
  configureFormGroup: FormGroup;
  public substanceId: string;
  private sessionId: string;
  private userId: string;

  constructor(
    private route: ActivatedRoute,
    private serverService: ServerService,
    private endpointService: EndpointServicesService,
    private exceptionService: ExceptionService,
    private toastrService: ToastrService,
    private formBuilder: FormBuilder
  ) {
    me = this;
    this.subscriptions = {};
    this.documents = [];
    this.WorkAreaSelectedLabel = '';
    this.WorkAreaSelectedId = '';
    this.workAreas = [];
    this.substanceTypes = [];
    this.relatedSubstances = [];
    this.route.paramMap.subscribe((params) => {
      let objectId = params.get('objectId');
      if (objectId != undefined && objectId != this.substanceId) {
        this.substanceId = params.get('objectId');
        this.sessionId = params.get('sessionId');
        this.userId = params.get('userId');
      }
    });
    this.subscriptions = {};
    this.substanceItem = new SubstanceItem('', '', '', '');
    this.substanceTypeLabel = '';

    this.selectFormGroup = this.formBuilder.group({
      sourceDocument: '',
      workArea: [{value: '', disabled: true}, Validators.required],
    });
  }

  get workAreaControl(): AbstractControl {
    return this.selectFormGroup.get('workArea');
  }

  ngOnInit(): void {

    this.endpointService.currentDocument.subscribe(
      (doc) => (this.selectedDocument = doc)
    );

    this.getUserData();
    this.searchDocuments(); // find all documents which uses Substance record ID
    this.getObjectRecordData(); // get data (name__v, substance_type__C) from Substance object in Vault, id passed in URL
    this.getWorkAreas();
    this.fetchRelatedSubstances();
    this.getSubstanceTypes();

    setInterval(() => {
      this.keepSessionAlive();
    }, 600000);

  }

  keepSessionAlive(): void {
    this.serverService.isLoading();
    this.subscriptions.getUserById = this.serverService
      .getUserById(this.sessionId, this.userId)
      .subscribe(
        (response: any) => {
          if (response.data && response.data.length > 0) {
            console.log(response.data);
          }
        },
        (error: HttpErrorResponse) => {
          this.exceptionService.handleError(error);
          this.serverService.isNotLoading();
        },
        () => {
          this.serverService.isNotLoading();
          console.log('session refreshed');
        }
      );
  }

  getUserData(): void {
    this.subscriptions.getUserById = this.serverService
      .getUserById(this.sessionId, this.userId)
      .subscribe(
        (response: any) => {
          if (response.data && response.data.length > 0) {
            this.endpointService.setUserprofile(response.data[0].security_profile__v);
          }
        },
        (error: HttpErrorResponse) => {
          this.exceptionService.handleError(error);
        },
      );
  }

  searchDocuments(): void {
    this.serverService.isLoading();
    this.serverService
      .fetchDocumentsUsingObject(this.sessionId, this.substanceId)
      .subscribe(
        (response) => {
          response.data.forEach((document: any) => {
            const doc = new DocumentMenuItem(
              document.id,
              document.name__v,
              document.major_version_number__v,
              document.minor_version_number__v
            );
            this.documents.push(doc);

            this.filteredOptions = this.searchControl.valueChanges.pipe(
              startWith(''),
              map((val) => this.filter(val))
            );
          });
        },

        (error: HttpErrorResponse) => {
          this.exceptionService.handleError(error);
          this.toastrService.error(`Unable to search for Documents !`, 'Error');
        },

        () => {
          this.serverService.isNotLoading();
        }
      );
  }

  /**
   * used for document search (filtering based on user input)
   * @param val = user text field input
   */
  filter(val): any {
    if (this.documents) {
      return this.documents.filter((option) =>
        option.name.toLowerCase().includes(val)
      );
    }
  }

  selectDocument(document): void {
    this.selectedDocument = document;
    // updating selected document via endpoint service
    this.endpointService.changeDocument(this.selectedDocument);
    this.workAreaControl.enable();
  }

  documentInputToNone(): void {
    this.searchControl.setValue('');
  }

  resetDocumentInput(): void {
    this.searchControl.setValue(this.selectedDocument.name);
  }

  getObjectRecordData(): void {
    this.serverService
      .getSubstanceById(this.sessionId, this.substanceId)
      .subscribe(
        (response) => {
          if (response.data[0].substance_type__c === 'parent__c') {
            this.substanceTypeLabel = 'Parent';
          } else if (response.data[0].substance_type__c === 'metabolite__c') {
            this.substanceTypeLabel = 'Metabolite';
          }

          this.substanceItem = new SubstanceItem(
            this.substanceId,
            response.data[0].name__v,
            response.data[0].substance_type__c[0],
            this.substanceTypeLabel
          );
        },

        (error: HttpErrorResponse) => {
          this.exceptionService.handleError(error);
          this.toastrService.error(
            `Unable to retrieve object record data !`,
            'Error'
          );
        },
      );
  }

  getWorkAreas(): void {
    this.subscriptions.getEndpointWorkAreas = this.serverService
      .getEndpointWorkAreas(this.sessionId)
      .subscribe(
        (response) => {
          this.workAreas = response.data.sort((a, b) => a.order_number__c - b.order_number__c) ?? [];
        },
        (error: HttpErrorResponse) => {
          this.exceptionService.handleError(error);
          this.toastrService.error(
            'Unable to get categories for a work area.',
            'Error'
          );
        },
        () => {
          this.serverService.isNotLoading();
        }
      );
  }

  fetchRelatedSubstances(): any {
    this.subscriptions.getRelatedSubstances = this.serverService
      .getRelatedSubstances(this.sessionId, this.substanceId)
      .subscribe(
        (response) => {
          if (response.data && response.data.length > 0) {
            response.data.forEach((substance: any) => {
              const entry = {
                id: substance.id,
                name: substance['related_substance__cr.name__v'],
                substance_type__c: substance.substance_type__c
              };
              this.relatedSubstances.push(entry);
            });
          }
        },
        (error: HttpErrorResponse) => {
          this.exceptionService.handleError(error);
          this.toastrService.error(
            'Unable to get Related Substances.',
            'Error'
          );
        },
      );
  }


  getSubstanceTypes(): void {
    const PicklistName = 'substance_type__c';
    this.subscriptions[PicklistName] = this.serverService
      .getPicklist(this.sessionId, PicklistName)
      .subscribe(
        (response) => {
          if (response.picklistValues && response.picklistValues.length > 0) {
            response.picklistValues.forEach((element: any) => {
              const entry = {
                name__v: '',
                id: '',
                label: element.label,
                name: element.name,
                picklist: PicklistName,
              };
              this.substanceTypes.push(entry);
            });
          }
        },
        (error: HttpErrorResponse) => {
          this.exceptionService.handleError(error);
          this.toastrService.error(
            `Unable to get Substance Types from "substance_type__c" picklist.`,
            'Error'
          );
        },
      );
  }

  pageToLeft(): any {
    window.scroll({
      left: 0,
      behavior: 'smooth',
    });
  }

  pageToRight(): any {
    window.scroll({
      left: 11800,
      behavior: 'smooth',
    });
  }

}
