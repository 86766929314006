import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {map} from 'rxjs/operators';
import {BreakpointObserver, Breakpoints} from '@angular/cdk/layout';
import {Row} from "../../models/row";
import {VaultService} from "../../service/vault.service";

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class DashboardComponent implements OnInit {

  data: Row[];

  /** Based on the screen size, switch from standard to one column per row */
  cardLayout = this.breakpointObserver.observe(Breakpoints.Handset).pipe(
    map(({ matches }) => {
      if (matches) {
        return {
          columns: 1,
          chart: { cols: 1, rows: 3},
          table: { cols: 1, rows: 3}
        };
      }

      return {
        columns: 1,
        chart: {cols: 1, rows: 3},
        table: {cols: 1, rows: 3}
      }
    })
  );

  constructor(
    private breakpointObserver: BreakpointObserver,
    private service: VaultService
  ) {}

  ngOnInit() {
    if (this.service.evaluations != undefined) {
      this.service.getRows().subscribe(response => {
        this.data = response;
      }, error => {
        console.log(error);
      })
    }
  }

}
