import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import {ActiveIngredient} from '../../models/active-ingredient';
import {Evaluation} from '../../models/evaluation';
import {SubstanceSet} from '../../models/substance-set';
import {VaultService} from '../../service/vault.service';
import {SaveSetDialogComponent} from '../save-set-dialog/save-set-dialog.component';
import {SelectSetDialogComponent} from '../select-set-dialog/select-set-dialog.component';
import {UpdateSetDialogComponent} from '../update-set-dialog/update-set-dialog.component';
import {ShareSetDialogComponent} from '../share-set-dialog/share-set-dialog.component';
import {InvalidEntriesDialogComponent} from "../invalid-entries-dialog/invalid-entries-dialog.component";
import {Observable} from "rxjs";
import {WarningDialogComponent} from "../warning-dialog/warning-dialog.component";

@Component({
  selector: 'app-multi-select',
  templateUrl: './select-page.component.html',
  styleUrls: ['./select-page.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class SelectPageComponent implements OnInit {


  panelOpenState = false;
  substances: ActiveIngredient[] = [];
  selectedSubstances: ActiveIngredient[] = [];
  allEvaluations: Evaluation[] = [];
  selectedEvaluations: Evaluation[] = [];
  userId: string = '';
  selectedSet: SubstanceSet = new SubstanceSet();
  setToSave: SubstanceSet = new SubstanceSet();
  savedEvaluations: Evaluation[] = [];
  isLoaded: boolean = false;
  pastedText: string = '';
  isLoading: boolean;

  constructor(
    private vaultService: VaultService,
    public dialog: MatDialog,
    ) {
    this.isLoading = true;
  }

  ngOnInit(): void {
    this.userId = this.vaultService.getUserId();
    this.getAllSubstances().subscribe(response => {
      this.substances = response;
    }, error => {
      console.log(error.message);
      this.isLoading = false;
    }, () => {
      this.isLoading = false;
    });
    if (this.selectedSet.substances != this.vaultService.substanceSet.substances) {
      this.selectedSet = this.vaultService.substanceSet;
      this.selectedSet.substances = this.vaultService.substanceSet.substances.slice();
      this.selectedSubstances = this.selectedSet.substances.slice();
      // this.getAllEvaluations();
    }
    if (this.vaultService.evaluations != this.selectedEvaluations
      && this.vaultService.evaluations != undefined) {
      console.log('selected evaluations: ', this.vaultService.evaluations);
      this.selectedEvaluations = this.vaultService.evaluations.slice();
    }
    if (this.allEvaluations.length <= this.selectedEvaluations.length) {
      this.filterEvaluations();
    }
  }


  getAllSubstances(): Observable<ActiveIngredient[]> {
    return this.vaultService.getSubstances();
  }


  getAllEvaluations(): void {
    this.vaultService.substanceSet.substances = this.selectedSet.substances;
    this.allEvaluations = [];
    for (let substance of this.selectedSet.substances) {
      let evaluations: Evaluation[] = substance.poc_evaluations__cr.data.filter(evaluation => {
        return evaluation['poc_active_ingredient__cr.name__v'] === substance.name__v;
      })
      this.allEvaluations = [...this.allEvaluations, ...evaluations];
    }
    this.allEvaluations = this.allEvaluations.slice();

  }

  filterEvaluations(): void {
    this.allEvaluations = [];
    for (let substance of this.substances){
      let hasSubstance = this.selectedSet.substances.some(s =>
        s.name__v.trim().toLowerCase() === substance.name__v.trim().toLowerCase()
      );
      if (hasSubstance){
      for (let evaluation of substance.poc_evaluations__cr.data) {
        this.allEvaluations.push(evaluation)
      }
      }
    }
    this.allEvaluations = this.allEvaluations.slice();
  }


  saveSet(){
    this.selectedSet.userId = this.vaultService.queryParams.userId;
    const dialogRef = this.dialog.open(SaveSetDialogComponent, {
      height: '320px',
      data: this.setToSave,
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result !=  undefined) {
        this.vaultService.saveSets(result).subscribe(result => {
          this.selectedSet.id = result.id;
          this.isLoaded = true;
          this.selectedSet.substances = this.selectedSet.substances.slice();
        });
      } else {
        this.selectedSet.substances = this.selectedSet.substances.slice();
      }
    });
  }

  loadSet() {
    this.selectedSet.userId = this.vaultService.queryParams.userId;
    console.log('loading set for user with id ', this.vaultService.queryParams.userId)
    const dialogRef = this.dialog.open(SelectSetDialogComponent, {
      height: '280px'
    });
    dialogRef.afterClosed().subscribe((result: SubstanceSet | undefined) => {
      if (result != undefined) {
        this.selectedSet = result;
        this.vaultService.substanceSet = this.selectedSet;
        if (this.selectedSet.id === '') {
          this.isLoaded = false;
        } else {
          this.isLoaded = true;
          this.filterEvaluations();
          this.selectedSubstances = this.selectedSet.substances.slice();
          this.selectedEvaluations = [];
          this.vaultService.evaluations = []
          for (let substance of this.selectedSet.substances) {
            for (let evaluation of substance.poc_evaluations__cr.data) {
              this.selectedEvaluations.push(evaluation);
              this.vaultService.evaluations.push(evaluation)
            }
          }
          this.selectedEvaluations = this.selectedEvaluations.slice();
          this.vaultService.evaluations = this.vaultService.evaluations.slice();
          this.savedEvaluations = this.selectedEvaluations.slice();
        }
      }
      else if(this.selectedSet.substances.length > 0){
        this.getAllEvaluations();
      }
      else {
        this.selectedSet = new SubstanceSet();
        this.selectedSet.userId = this.vaultService.queryParams.userId;
        this.isLoaded = false;
      }
    });
  }

  updateSet(){
    const dialogRef = this.dialog.open(UpdateSetDialogComponent, {
      height: '270px',
      data: this.setToSave
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result !=  undefined) {
        this.vaultService.updateSet(result).subscribe(() => {
        });
      } else {
        this.selectedSet.substances = this.selectedSet.substances.slice();
      }
    });
  }

  share(){
    const dialogRef = this.dialog.open(ShareSetDialogComponent, {
      height: '400px',
      data: this.setToSave
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result != undefined){
        this.vaultService.saveSets(result).subscribe(() => {
        })
      } else {
        this.selectedSet.substances = this.selectedSet.substances.slice();
      }
    })
  }

  clearSubstances(): void {
    const dialogRef = this.dialog.open(WarningDialogComponent, {
      height: '250px',
      data: {
        title: 'Are you sure you want to clear everything?',
        showCancel: true
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.allEvaluations = [];
        this.selectedEvaluations = [];
        this.isLoaded = false;
        this.selectedSet = new SubstanceSet();
        this.vaultService.evaluations = [];
      }
      if (result === undefined) {
        this.selectedSet.substances = this.selectedSubstances.slice();
        this.getAllEvaluations();
        this.selectedEvaluations = this.selectedEvaluations.slice();
      }
    })
  }

  clearEvaluations(): void {
    const dialogRef = this.dialog.open(WarningDialogComponent, {
      data: {
        title: 'Are you sure you want to clear all selected evaluations?',
        showCancel: true
      },
      height: '250px'
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result === undefined) {
        this.selectedEvaluations = this.savedEvaluations.slice();
      }
      if (result) {
        this.selectedEvaluations = [];
      }
    })
  }

  saveSelected(): void {
    if (this.selectedEvaluations.length > 0) {
      console.log('saving evaluations ', this.selectedEvaluations);
      this.vaultService.evaluations = this.selectedEvaluations.slice();
      console.log('saved evaluations ', this.vaultService.evaluations);
    }
  }

  populateSetToSave(isForUpdate: boolean){
    if (isForUpdate){
      this.setToSave.id = this.selectedSet.id;
      this.setToSave.setName = this.selectedSet.setName;
    }
    else {
      this.setToSave.id = '';
      this.setToSave.setName = '';
    }
    this.setToSave.userId = this.vaultService.queryParams.userId;
    this.setToSave.substances = this.selectedSet.substances;
    for (let substance of this.setToSave.substances){
      substance.poc_evaluations__cr.data = [];
      for (let evaluation of this.selectedEvaluations) {
        if (evaluation['poc_active_ingredient__cr.name__v'].trim().toLowerCase() === substance.name__v.trim().toLowerCase()) {
          substance.poc_evaluations__cr.data.push(evaluation)
        }
      }
    }
  }

  drop(event: CdkDragDrop<Evaluation>){
    moveItemInArray(this.selectedEvaluations, event.previousIndex, event.currentIndex);
    this.selectedEvaluations = this.selectedEvaluations.slice();
  }

  updateSelected(): void {
    if (this.selectedSet.substances.length > 0) {
      this.selectedSubstances = this.selectedSet.substances.slice();
    }
  }

  onRemove(): void {
    for (let evaluation of this.selectedEvaluations){
      let hasSubstance = this.selectedSet.substances.some(substance =>
        substance.name__v.trim().toLowerCase() === evaluation['poc_active_ingredient__cr.name__v'].trim().toLowerCase()
      );
      if (!hasSubstance) {
        this.selectedEvaluations = this.selectedEvaluations.filter(e => e['poc_active_ingredient__cr.name__v']
          != evaluation['poc_active_ingredient__cr.name__v']);
        console.log('removing evaluation ', evaluation['poc_active_ingredient__cr.name__v']);
      }
    }
    this.selectedEvaluations = this.selectedEvaluations.slice();
    this.vaultService.evaluations = this.selectedEvaluations;
  }

  getValidatedSubstances(): void {
    let AINames = this.pastedText.split('\n');
    let invalidEntries: String[] = [];
    for (let i = 0; i < AINames.length; i++){
      let foundSubstance = this.substances.find(substance =>
        substance.name__v.toLowerCase().trim() === AINames[i].trim().toLowerCase().trim());
      if ((foundSubstance != undefined) && (this.selectedSet.substances.indexOf(foundSubstance)) === -1) {
        this.selectedSet.substances.push(foundSubstance);
      } else if (foundSubstance === undefined) {
        invalidEntries.push(AINames[i]);
      }
    }
    if (invalidEntries.length > 0){
      this.dialog.open(InvalidEntriesDialogComponent, {
        height: '300px',
        maxHeight: '600px',
        data: invalidEntries
      });
    }

    this.selectedSet.substances = this.selectedSet.substances.slice();
    this.getAllEvaluations();
  }
}

