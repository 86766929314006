import {AfterContentInit, Component, Input, OnInit} from '@angular/core';
import {PicklistValue} from '../../models/picklist-value';
import {SubstanceItem} from '../../models/substance-item';
import {RelatedSubstances} from '../../models/related-substances';
import {DocumentMenuItem} from '../../models/document-menu-item';
import {ConsumerSafetyCategoryPicklists} from '../models/consumer-safety-category-picklists';
import {ConsumerSafetyEndpointTableItem} from '../models/consumer-safety-endpoint-table-item';
import {ActivatedRoute} from '@angular/router';
import {ServerService} from '../../../core/server.service';
import {EndpointServicesService} from '../../endpoint-services.service';
import {MatTableDataSource} from '@angular/material/table';
import {ConsumerSafetyAdditionalFields} from '../models/consumer-safety-additional-fields';
import {SingleRecordDependencies} from "../models/single-record-dependencies";

@Component({
  selector: 'app-consumer-safety-category',
  templateUrl: './consumer-safety-category.component.html',
  styleUrls: ['./consumer-safety-category.component.css']
})
export class ConsumerSafetyCategoryComponent implements OnInit, AfterContentInit {
  @Input() category: any;
  @Input() dataPicklists: Array<ConsumerSafetyCategoryPicklists>;
  @Input() endpointsArrayList: Array<ConsumerSafetyEndpointTableItem>;
  @Input() relatedSubstances: Array<RelatedSubstances>;
  @Input() substance: SubstanceItem;
  @Input() workAreaId: string;
  @Input() workAreaLabel: string;
  @Input() documents: Array<DocumentMenuItem>;
  @Input() receivedAllStudyTypes: Array<PicklistValue>;
  @Input() receivedAllTestSystemTypes: Array<PicklistValue>;
  @Input() receivedAllTestSystemNames: Array<PicklistValue>;
  @Input() substanceTypes: Array<PicklistValue>;
  @Input() receivedAllAdditionalFields: Array<ConsumerSafetyAdditionalFields>;
  @Input() receivedAdditionalFieldValues: Array<ConsumerSafetyAdditionalFields>;
  @Input() singleRecordDependencies: Array<SingleRecordDependencies>;
  @Input() identifiedMetabolites: Array<ConsumerSafetyAdditionalFields>;
  @Input() analyteNames: Array<ConsumerSafetyAdditionalFields>;
  @Input() substanceAsAnalyteNames: Array<ConsumerSafetyAdditionalFields>;

  subscriptions: any;
  public selectedDocument: DocumentMenuItem;
  public filteredPicklists: Array<ConsumerSafetyCategoryPicklists>;
  public categoryEndpointsArrayList: Array<ConsumerSafetyEndpointTableItem>;
  public groupPicklists: Array<ConsumerSafetyCategoryPicklists>;
  public categoryAdditionalFields: Array<ConsumerSafetyAdditionalFields>;
  public categoryStudyTypes: Array<PicklistValue>; // reference list
  public defaultAllTestSystemTypes: Array<PicklistValue>;
  public defaultAllTestSystemNames: Array<PicklistValue>;
  public allTestSystemTypes: Array<PicklistValue>;
  public allTestSystemNames: Array<PicklistValue>;
  public categorySingleRecordDependencies: Array<SingleRecordDependencies>;
  public categoryAdditionalFieldValues: Array<ConsumerSafetyAdditionalFields>;
  public dataSource: MatTableDataSource<ConsumerSafetyEndpointTableItem>;
  public startingId: number;
  public maxCategoryId: number;
  private sessionId: string;

  constructor(private route: ActivatedRoute,
              private serverService: ServerService,
              private endpointService: EndpointServicesService) {
    this.subscriptions = {};
    this.route.paramMap.subscribe((params) => {
      this.sessionId = params.get('sessionId');
    });
    this.categoryEndpointsArrayList = [];
    this.filteredPicklists = [];
    this.groupPicklists = [];
    this.categoryStudyTypes = [];
    this.defaultAllTestSystemTypes = [];
    this.defaultAllTestSystemNames = [];
    this.allTestSystemTypes = [];
    this.allTestSystemNames = [];
    this.categorySingleRecordDependencies = [];
    this.categoryAdditionalFieldValues = [];
    this.dataSource = new MatTableDataSource();
    this.startingId = 0;
    this.maxCategoryId = 0;
  }

  ngOnInit(): void {
    this.endpointService.currentDocument.subscribe(
      (doc) => (this.selectedDocument = doc)
    );
    this.maxCategoryId = (10 - this.category.order_by__c) * 1000000;

    this.categoryAdditionalFields = this.receivedAllAdditionalFields.filter(
      (entry) => entry.categoryId === this.category.id
    );

    this.categorySingleRecordDependencies = this.singleRecordDependencies.filter(
      (entry) => entry.categoryId === this.category.id
    );

  }

  ngAfterContentInit(): void {
    this.filteredPicklists = this.dataPicklists.filter(
      (a) => a.categoryId === this.category.id
    );
    this.categoryStudyTypes = this.receivedAllStudyTypes.filter(
      (b) => b.id === this.category.id
    );

    this.defaultAllTestSystemTypes = this.receivedAllTestSystemTypes.filter(
      (e) => e.id === this.category.id
    );

    this.defaultAllTestSystemNames = this.receivedAllTestSystemNames.filter(
      (e) => e.id === this.category.id
    );

    this.unifyTestSystemTypes();
    this.unifyTestSystemNames();
  }

  unifyTestSystemTypes(): void {
    // remove duplicates
    const array = this.defaultAllTestSystemTypes;
    const map = new Map();
    for (const item of array) {
      if (!map.has(item.name + item.picklist)) {
        map.set(item.name + item.picklist, true); // set any value to Map
        this.allTestSystemTypes.push({
          name__v: item.name__v,
          id: item.id,
          name: item.name,
          label: item.label,
          picklist: item.picklist,
        });
      }
    }
  }

  unifyTestSystemNames(): void {
    // remove duplicates
    const array = this.defaultAllTestSystemNames;
    const map = new Map();
    for (const item of array) {
      if (!map.has(item.name + item.picklist)) {
        map.set(item.name + item.picklist, true); // set any value to Map
        this.allTestSystemNames.push({
          name__v: item.name__v,
          id: item.id,
          name: item.name,
          label: item.label,
          picklist: item.picklist,
        });
      }
    }
  }

  addNewEndpoint(endpoint: any): void {
    // make object
    const newEndpointTemplate: ConsumerSafetyEndpointTableItem = {
      id__c: this.generateId(),
      work_area__c: this.workAreaId,
      category__c: this.category.id,
      substance_name__c: this.substance.id,
      substance_type__c: endpoint.substance_type__c ?? 'parent__c',
      related_substance__c: endpoint.related_substance__c ?? '',
      study_type__c: endpoint.study_type__c ?? '',
      test_system_type__c: endpoint.test_system_type__c ?? [],
      test_system_name__c: endpoint.test_system_name__c ?? [],
      residue_type__c: endpoint.residue_type__c ?? '',
      residue_name__c: endpoint.residue_name__c ?? '',
      // additional (depended) fields
      waiverdata_gap__c: endpoint.waiverdata_gap__c ?? '',
      application_type__c: endpoint.application_type__c ?? '',
      application_rate1__c: endpoint.application_rate1__c ?? '',
      unit_application_rate__c: endpoint.unit_application_rate__c ?? '',
      replant_interval_days__c: endpoint.replant_interval_days__c ?? '',
      open_radio_label_topic__c: endpoint.open_radio_label_topic__c ?? '',
      open_id_rate_topic__c: endpoint.open_id_rate_topic__c ?? '',
      open_isomer_topic__c: endpoint.open_isomer_topic__c ?? '',
      adequate_study__c: endpoint.adequate_study__c ?? '',
      residue_uptake_001_mgkg__c: endpoint.residue_uptake_001_mgkg__c ?? '',
      plateau_reached_in_milkeggs__c: endpoint.plateau_reached_in_milkeggs__c ?? '',
      time_needed_to_reach_plateau_in_milkegg__c: endpoint.time_needed_to_reach_plateau_in_milkegg__c ?? '',
      parent_only__c: endpoint.parent_only__c ?? '',
      identified_metabolites__c: endpoint.identified_metabolites__c ?? [],
      similar_to_metabolism_study__c: endpoint.similar_to_metabolism_study__c ?? '',
      fat_soluble_residues__c: endpoint.fat_soluble_residues__c ?? '',
      similar_to_other_crop_groups__c: endpoint.similar_to_other_crop_groups__c ?? '',
      similar_to_primary_crops__c: endpoint.similar_to_primary_crops__c ?? '',
      similar_to_other_species__c: endpoint.similar_to_other_species__c ?? '',
      similar_to_rat__c: endpoint.similar_to_rat__c ?? '',
      human_unique_or_disproportional_metaboli__c: endpoint.human_unique_or_disproportional_metaboli__c ?? '',
      maximum_abundancy_in_foodedibles_trr__c: endpoint.maximum_abundancy_in_foodedibles_trr__c ?? '',
      maximum_abundancy_in_foodedibles_mg__c: endpoint.maximum_abundancy_in_foodedibles_mg__c ?? '',
      maximum_abundancy_in_feed_trr__c: endpoint.maximum_abundancy_in_feed_trr__c ?? '',
      maximum_abundancy_in_feed_mgkg__c: endpoint.maximum_abundancy_in_feed_mgkg__c ?? '',
      maximum_abundancy_in_inedibles__c: endpoint.maximum_abundancy_in_inedibles__c ?? '',
      fat_soluble__c: endpoint.fat_soluble__c ?? '',
      tox_species_coverage_10_administred_d__c: endpoint.tox_species_coverage_10_administred_d__c ?? '',
      administered_dose__c: endpoint.administered_dose__c ?? '',
      matrix_tox_species__c: endpoint.matrix_tox_species__c ?? '',
      included_in_rd__c: endpoint.included_in_rd__c ?? '',
      rd_components__c: endpoint.rd_components__c ?? [],
      rd_wording__c: endpoint.rd_wording__c ?? '',
      rd_status__c: endpoint.rd_status__c ?? '',
      stability__c: endpoint.stability__c ?? '',
      unit_stability__c: endpoint.unit_stability__c ?? '',
      similar_to_other_commodities_of_same_cat__c: endpoint.similar_to_other_commodities_of_same_cat__c ?? '',
      similar_to_other_categories__c: endpoint.similar_to_other_categories__c ?? '',
      similar_to_other_animalsof_the_same_anim__c: endpoint.similar_to_other_animalsof_the_same_anim__c ?? '',
      similar_to_other_animal_commodities__c: endpoint.similar_to_other_animal_commodities__c ?? '',
      sufficient_data_package__c: endpoint.sufficient_data_package__c ?? '',
      matrix__c: endpoint.matrix__c ?? '',
      analyte_type__c: endpoint.analyte_type__c ?? '',
      analyte_name__c: endpoint.analyte_name__c ?? [],
      residue_001_mgkg__c: endpoint.residue_001_mgkg__c ?? '',
      worst_case_plateau_covered__c: endpoint.worst_case_plateau_covered__c ?? '',
      replant_restrictions__c: endpoint.replant_restrictions__c ?? '',
      replant_restriction_days__c: endpoint.replant_restriction_days__c ?? '',
      rotational_mrls_required__c: endpoint.rotational_mrls_required__c ?? '',
      processing_factor__c: endpoint.processing_factor__c ?? '',
      dose_level_mgkg_dm_diet__c: endpoint.dose_level_mgkg_dm_diet__c ?? '',
      dose_level_mgkg_bwday__c: endpoint.dose_level_mgkg_bwday__c ?? '',
      residues_in_edible_matrices_001_mgkg__c: endpoint.residues_in_edible_matrices_001_mgkg__c ?? '',
      sum_of_analytes_005_mgkg__c: endpoint.sum_of_analytes_005_mgkg__c ?? '',
      included_uses__c: endpoint.included_uses__c ?? '',
      refinement_level__c: endpoint.refinement_level__c ?? '',
      risk_assessment_status__c: endpoint.risk_assessment_status__c ?? '',
      toxicological_endpoint_utilization__c: endpoint.toxicological_endpoint_utilization__c ?? '',
      unittoxicological_endpoint_utilization__c: endpoint.unittoxicological_endpoint_utilization__c ?? '',
      driver_population__c: endpoint.driver_population__c ?? '',
      driver_crop__c: endpoint.driver_crop__c ?? '',
      factors_included_in_the_calculations__c: endpoint.factors_included_in_the_calculations__c ?? '',
      ra_relevant__c: endpoint.ra_relevant__c ?? '',
      comment__c: endpoint.comment__c ?? '',
      study_type_picklist_name__c: endpoint.study_type_picklist_name__c ?? '',
      document_unbound__c: endpoint.document_unbound__c ?? this.selectedDocument.id,
    };

    this.categoryEndpointsArrayList.push(newEndpointTemplate);
    this.endpointsArrayList.push(newEndpointTemplate);
  }

  generateId(): number {
    const newStartingId = this.startingId + 1;
    this.updateStartingId(newStartingId);
    return this.maxCategoryId - newStartingId;
  }

  updateStartingId(newStartingId): void {
    this.startingId = newStartingId;
  }

  removeEndpointInput(endpoint: ConsumerSafetyEndpointTableItem): void {
    // remove object from array
    for (let index = 0; index < this.endpointsArrayList.length; index++) {
      const element: ConsumerSafetyEndpointTableItem = this.endpointsArrayList[index];
      if (element.id__c === endpoint.id__c) {
        this.endpointsArrayList.splice(index, 1);
      }
    }

    for (let index = 0; index < this.categoryEndpointsArrayList.length; index++) {
      const element: ConsumerSafetyEndpointTableItem = this.categoryEndpointsArrayList[index];
      if (element.id__c === endpoint.id__c) {
        this.categoryEndpointsArrayList.splice(index, 1);
      }
    }
  }

  removeSavedEndpoint(endpointId): any {
    this.categoryEndpointsArrayList = this.categoryEndpointsArrayList.filter(obj => obj.id__c !== endpointId);
  }

  copyEndpointInput(endpoint: ConsumerSafetyEndpointTableItem): void {
    this.categoryEndpointsArrayList.filter(it => it.id__c === endpoint.id__c)
      .forEach(newEndpoint => {
        this.addNewEndpoint(newEndpoint);
      });
  }
}
