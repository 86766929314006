<div class="existingEndpointsComponent">

  <mat-accordion>
    <mat-expansion-panel (closed)="destroyEndpoints(); panelOpenState = false"
                         (opened)="getEndpointsWithFilters(this.pageSize, 0); panelOpenState = true"
                         [expanded]="expandPanel"
                         style="border:none; box-shadow: none;">
      <mat-expansion-panel-header class="expansionHeader">
        <mat-panel-title class="expansionTitle">
          Existing "{{workAreaName}}" Endpoints With "{{substance.name}}" Substance.
        </mat-panel-title>
      </mat-expansion-panel-header>

      <ng-container>

        <div class="filterChipsRow">

          <mat-form-field class="example-chip-list">
            <mat-label>Filters</mat-label>
            <mat-chip-list #chipList aria-label="Fields selection">
              <mat-chip (removed)="remove(field)"
                        *ngFor="let field of fields"
                        [removable]="removable"
                        [selectable]="selectable"
                        class="angularFormChipValue">
                {{field}}
                <mat-icon *ngIf="removable" matChipRemove>cancel</mat-icon>
              </mat-chip>
              <input
                #fieldInput
                (matChipInputTokenEnd)="add($event)"
                [formControl]="fieldCtrl"
                [matAutocomplete]="auto"
                [matChipInputFor]="chipList"
                [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                class="angularFormChipValue" placeholder="New filter...">
            </mat-chip-list>

            <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">

              <ng-container *ngFor="let field of filteredFields | async">
                <mat-option *ngIf="!fields.includes(field)" [value]="field" class="angularFormSelector">
                  {{field}}
                </mat-option>
              </ng-container>
            </mat-autocomplete>
          </mat-form-field>
        </div>

        <div class="filterFieldsRowSELECTED" id="filterFields">

          <div class="singleFieldContainer" id='Category'>
            <mat-form-field class="angularSearchWrapper">
              <mat-label>Category</mat-label>
              <mat-select (selectionChange)="picklistValueToArray('category__c', 'Category', category__c)"
                          [(ngModel)]="category__c">
                <mat-option value="">All</mat-option>
                <mat-option *ngFor="let category of receivedCategories" [value]=" category.id">
                  {{ category.name__v}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="singleFieldContainer" id='Related Substance'>
            <mat-form-field class="angularSearchWrapper">
              <mat-label>Related Substance</mat-label>
              <mat-select
                (selectionChange)="picklistValueToArray('related_substance__c', 'Related Substance', related_substance__c)"
                [(ngModel)]="related_substance__c">
                <mat-option value="">All</mat-option>
                <mat-option *ngFor="let relatedSubstance of relatedSubstances" [value]=" relatedSubstance.id">
                  {{ relatedSubstance.name}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

        </div>

        <div class="filterFieldsRow" id="preparedFilterFields">

          <div class="singleFieldContainer" id='Substance Type'>
            <mat-form-field class="angularSearchWrapper">
              <mat-label>Substance Type</mat-label>
              <mat-select
                (selectionChange)="picklistValueToArray('substance_type__c', 'Substance Type', substance_type__c)"
                [(ngModel)]="substance_type__c">
                <mat-option value="">All</mat-option>
                <mat-option *ngFor="let substanceType of substanceTypes" [value]=" substanceType.name">
                  {{ substanceType.label}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="singleFieldContainer" id='Study Type'>
            <mat-form-field class="angularSearchWrapper">
              <mat-label>Study Type</mat-label>
              <mat-select (selectionChange)="picklistValueToArray('study_type__c', 'Study Type', study_type__c)"
                          [(ngModel)]="study_type__c">
                <mat-option value="">All</mat-option>
                <mat-option *ngFor="let filterStudyType of uniqueStudyTypes" [value]=" filterStudyType.name">
                  {{ filterStudyType.label}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="singleFieldContainer" id='Endpoint'>
            <mat-form-field class="angularSearchWrapper">
              <mat-label>Endpoint</mat-label>
              <mat-select (selectionChange)="picklistValueToArray('endpoint__c', 'Endpoint', endpoint__c)"
                          [(ngModel)]="endpoint__c">
                <mat-option value="">All</mat-option>
                <mat-option *ngFor="let filterEndpoint of uniqueEndpoints" [value]=" filterEndpoint.name">
                  {{ filterEndpoint.label}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="singleFieldContainer" id='Endpoint Unit'>
            <mat-form-field class="angularSearchWrapper">
              <mat-label>Endpoint Unit</mat-label>
              <mat-select
                (selectionChange)="picklistValueToArray('endpoint_unit__c', 'Endpoint Unit', endpoint_unit__c)"
                [(ngModel)]="endpoint_unit__c">
                <mat-option value="">All</mat-option>
                <mat-option *ngFor="let filterEndpointUnit of uniqueEndpointUnits" [value]=" filterEndpointUnit.name">
                  {{ filterEndpointUnit.label}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="singleFieldContainer" id='Operator'>
            <mat-form-field class="angularSearchWrapper">
              <mat-label>Operator</mat-label>
              <mat-select (selectionChange)="picklistValueToArray('operator__c', 'Operator', operator__c)"
                          [(ngModel)]="operator__c">
                <mat-option value="">All</mat-option>
                <mat-option *ngFor="let filterOperator of receivedAllOperators" [value]=" filterOperator.name">
                  {{ filterOperator.label}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="singleFieldContainer" id='Value 1'>
            <mat-form-field class="angularSearchWrapper">
              <mat-label>Value 1</mat-label>
              <input (change)="textFieldValueToArray('value_1__c', 'Value 1')"
                     (keyup)="textFieldValueToArray('value_1__c', 'Value 1')" [(ngModel)]="value_1__c"
                     class="orangeTextFieldInput" id="value_1__c" matInput type="number">
            </mat-form-field>
          </div>

          <div class="singleFieldContainer" id='Value 2'>
            <mat-form-field class="angularSearchWrapper">
              <mat-label>Value 2</mat-label>
              <input (change)="textFieldValueToArray('value_2__c', 'Value 2')"
                     (keyup)="textFieldValueToArray('value_2__c', 'Value 2')" [(ngModel)]="value_2__c"
                     class="orangeTextFieldInput" id="value_2__c" matInput type="number">
            </mat-form-field>
          </div>

          <div class="singleFieldContainer" id="Created Date Range">
            <mat-form-field class="angularSearchWrapper">
              <mat-label>Created Date Range</mat-label>
              <mat-date-range-input [formGroup]="createdRange" [rangePicker]="picker">
                <input [(ngModel)]="created_date_start" formControlName="createdDateStart" matStartDate
                       placeholder="Start date">
                <input [(ngModel)]="created_date_end" formControlName="createdDateEnd" matEndDate
                       placeholder="End date">
              </mat-date-range-input>
              <mat-datepicker-toggle [for]="picker" matSuffix></mat-datepicker-toggle>
              <mat-date-range-picker #picker color="accent"></mat-date-range-picker>

              <mat-error *ngIf="createdRange.controls.createdDateStart.hasError('matStartDateInvalid')">Invalid start
                date
              </mat-error>
              <mat-error *ngIf="createdRange.controls.createdDateEnd.hasError('matEndDateInvalid')">Invalid end date
              </mat-error>
            </mat-form-field>
          </div>

          <div class="singleFieldContainer" id="Modified Date Range">
            <mat-form-field class="angularSearchWrapper">
              <mat-label>Modified Date Range</mat-label>
              <mat-date-range-input [formGroup]="modifiedRange" [rangePicker]="modifiedPicker">
                <input [(ngModel)]="modified_date_start" formControlName="modifiedDateStart" matStartDate
                       placeholder="Start date">
                <input [(ngModel)]="modified_date_end" formControlName="modifiedDateEnd" matEndDate
                       placeholder="End date">
              </mat-date-range-input>
              <mat-datepicker-toggle [for]="modifiedPicker" matSuffix></mat-datepicker-toggle>
              <mat-date-range-picker #modifiedPicker color="accent"></mat-date-range-picker>

              <mat-error *ngIf="modifiedRange.controls.modifiedDateStart.hasError('matStartDateInvalid')">Invalid start
                date
              </mat-error>
              <mat-error *ngIf="modifiedRange.controls.modifiedDateEnd.hasError('matEndDateInvalid')">Invalid end date
              </mat-error>
            </mat-form-field>
          </div>


          <div class="singleFieldContainer" id="Created By">
            <div class="form-row">
              <mat-form-field class="angularSearchWrapper">
                <input [(ngModel)]="created_by__v"
                       [formControl]="searchUserCreateControl"
                       [matAutocomplete]="autoCreatedUser"
                       aria-label="User"
                       matInput
                       placeholder="Created By" type="text"
                >
                <mat-autocomplete #autoCreatedUser="matAutocomplete">
                  <mat-option (onSelectionChange)="picklistValueToArray('created_by__v', 'Created By', '')"
                              [value]="All"
                              value="">All
                  </mat-option>
                  <mat-option (onSelectionChange)="picklistValueToArray('created_by__v', 'Created By', user.id)"
                              *ngFor="let user of filteredCreateUsers | async" [value]="user.name"
                              class="angularFormSelector">
                    {{user.name}}
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field>
            </div>
          </div>

          <div class="singleFieldContainer" id="Last Modified By">
            <div class="form-row">
              <mat-form-field class="angularSearchWrapper">
                <input [(ngModel)]="modified_by__v"
                       [formControl]="searchUserModifyControl"
                       [matAutocomplete]="autoModifiedUser"
                       aria-label="User"
                       matInput
                       placeholder="Last Modified By" type="text"
                >
                <mat-autocomplete #autoModifiedUser="matAutocomplete">
                  <mat-option (onSelectionChange)="picklistValueToArray('modified_by__v', 'Last Modified By', '')"
                              [value]="All"
                              value="">All
                  </mat-option>
                  <mat-option (onSelectionChange)="picklistValueToArray('modified_by__v', 'Last Modified By', user.id)"
                              *ngFor="let user of filteredModifiedUsers | async" [value]="user.name"
                              class="angularFormSelector">
                    {{user.name}}
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field>
            </div>
          </div>

          <div class="singleFieldContainer" id="Source">
            <mat-form-field class="angularSearchWrapper">
              <mat-label>Source</mat-label>
              <mat-select (selectionChange)="picklistValueToArray('document_unbound__c', 'Source', selectedDocumentId)"
                          [(ngModel)]="selectedDocumentId">
                <mat-option value="">All</mat-option>
                <mat-option *ngFor="let document of documents" [value]=" document.id">
                  {{ document.name}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="singleFieldContainer" id='FOCUS step'>
            <mat-form-field class="angularSearchWrapper">
              <mat-label>FOCUS step</mat-label>
              <mat-select (selectionChange)="picklistValueToArray('focus_step__c', 'FOCUS step', focus_step__c)"
                          [(ngModel)]="focus_step__c">
                <mat-option value="">All</mat-option>
                <mat-option
                  *ngFor="let optionValue of filterAdditionalFieldValues(receivedAdditionalFieldValues, 'focus_step__c')"
                  [value]="optionValue.name">
                  {{optionValue.label}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="singleFieldContainer" id='P-trigger'>
            <mat-form-field class="angularSearchWrapper">
              <mat-label>P-trigger</mat-label>
              <mat-select (selectionChange)="picklistValueToArray('ptrigger__c', 'P-trigger', ptrigger__c)"
                          [(ngModel)]="ptrigger__c">
                <mat-option value="">All</mat-option>
                <mat-option
                  *ngFor="let optionValue of filterAdditionalFieldValues(receivedAdditionalFieldValues, 'ptrigger__c')"
                  [value]="optionValue.name">
                  {{optionValue.label}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="singleFieldContainer" id='Data Normalization'>
            <mat-form-field class="angularSearchWrapper">
              <mat-label>Data Normalization</mat-label>
              <mat-select
                (selectionChange)="picklistValueToArray('data_normalization__c', 'Data Normalization', data_normalization__c)"
                [(ngModel)]="data_normalization__c">
                <mat-option value="">All</mat-option>
                <mat-option
                  *ngFor="let optionValue of filterAdditionalFieldValues(receivedAdditionalFieldValues, 'data_normalization__c')"
                  [value]="optionValue.name">
                  {{optionValue.label}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="singleFieldContainer" id='Kinetic Model'>
            <mat-form-field class="angularSearchWrapper">
              <mat-label>Kinetic Model</mat-label>
              <mat-select
                (selectionChange)="picklistValueToArray('kinetic_model__c', 'Kinetic Model', kinetic_model__c)"
                [(ngModel)]="kinetic_model__c">
                <mat-option value="">All</mat-option>
                <mat-option
                  *ngFor="let optionValue of filterAdditionalFieldValues(receivedAdditionalFieldValues, 'kinetic_model__c')"
                  [value]="optionValue.name">
                  {{optionValue.label}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="singleFieldContainer" id='pH dependency'>
            <mat-form-field class="angularSearchWrapper">
              <mat-label>pH dependency</mat-label>
              <mat-select
                (selectionChange)="picklistValueToArray('ph_dependency__c', 'pH dependency', ph_dependency__c)"
                [(ngModel)]="ph_dependency__c">
                <mat-option value="">All</mat-option>
                <mat-option
                  *ngFor="let optionValue of filterAdditionalFieldValues(receivedAdditionalFieldValues, 'ph_dependency__c')"
                  [value]="optionValue.name">
                  {{optionValue.label}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="singleFieldContainer" id='Method of calc.'>
            <mat-form-field class="angularSearchWrapper">
              <mat-label>Method of calc.</mat-label>
              <mat-select
                (selectionChange)="picklistValueToArray('method_of_calculation__c', 'Method of calc.', method_of_calculation__c)"
                [(ngModel)]="method_of_calculation__c">
                <mat-option value="">All</mat-option>
                <mat-option
                  *ngFor="let optionValue of filterAdditionalFieldValues(receivedAdditionalFieldValues, 'method_of_calculation__c')"
                  [value]="optionValue.name">
                  {{optionValue.label}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="singleFieldContainer" id='Relevance Trigger'>
            <mat-form-field class="angularSearchWrapper">
              <mat-label>Relevance Trigger</mat-label>
              <mat-select
                (selectionChange)="picklistValueToArray('relevance_trigger__c', 'Relevance Trigger', relevance_trigger__c)"
                [(ngModel)]="relevance_trigger__c">
                <mat-option value="">All</mat-option>
                <mat-option
                  *ngFor="let optionValue of filterAdditionalFieldValues(receivedAdditionalFieldValues, 'relevance_trigger__c')"
                  [value]="optionValue.name">
                  {{optionValue.label}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="singleFieldContainer" id='Toxicological Relevance'>
            <mat-form-field class="angularSearchWrapper">
              <mat-label>Toxicological Relevance</mat-label>
              <mat-select
                (selectionChange)="picklistValueToArray('toxicological_relevance__c', 'Toxicological Relevance', toxicological_relevance__c)"
                [(ngModel)]="toxicological_relevance__c">
                <mat-option value="">All</mat-option>
                <mat-option
                  *ngFor="let optionValue of filterAdditionalFieldValues(receivedAdditionalFieldValues, 'toxicological_relevance__c')"
                  [value]="optionValue.name">
                  {{optionValue.label}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="singleFieldContainer" id='Metabolites included'>
            <mat-form-field class="angularSearchWrapper">
              <mat-label>Metabolites included</mat-label>
              <mat-select
                (selectionChange)="picklistValueToArray('metabolites_included__c', 'Metabolites included', metabolites_included__c)"
                [(ngModel)]="metabolites_included__c">
                <mat-option value="">All</mat-option>
                <mat-option
                  *ngFor="let optionValue of filterAdditionalFieldValues(receivedAdditionalFieldValues, 'metabolites_included__c')"
                  [value]="optionValue.name">
                  {{optionValue.label}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <!-- number fields -->

          <div class="singleFieldContainer" id='Temp. °C'>
            <mat-form-field class="angularSearchWrapper">
              <mat-label>Temp. °C</mat-label>
              <input (change)="textFieldValueToArray('temp_c__c', 'Temp. °C')"
                     (keyup)="textFieldValueToArray('temp_c__c', 'Temp. °C')" [(ngModel)]="temp_c__c"
                     class="orangeTextFieldInput" id="temp_c__c" matInput matNativeControl type="number">
            </mat-form-field>
          </div>

          <div class="singleFieldContainer" id='pH (experiment)'>
            <mat-form-field class="angularSearchWrapper">
              <mat-label>pH (experiment)</mat-label>
              <input (change)="textFieldValueToArray('ph_experiment__c', 'pH (experiment)')"
                     (keyup)="textFieldValueToArray('ph_experiment__c', 'pH (experiment)')"
                     [(ngModel)]="ph_experiment__c"
                     class="orangeTextFieldInput" id="ph_experiment__c" matInput matNativeControl type="number">
            </mat-form-field>
          </div>

          <div class="singleFieldContainer" id='Plant uptake factor used for PECgw'>
            <mat-form-field class="angularSearchWrapper">
              <mat-label>Plant uptake factor used for PECgw</mat-label>
              <input
                (change)="textFieldValueToArray('plant_uptake_factor_used_for_pecgw__c', 'Plant uptake factor used for PECgw')"
                (keyup)="textFieldValueToArray('plant_uptake_factor_used_for_pecgw__c', 'Plant uptake factor used for PECgw')"
                [(ngModel)]="plant_uptake_factor_used_for_pecgw__c"
                class="orangeTextFieldInput" id="plant_uptake_factor_used_for_pecgw__c" matInput matNativeControl
                type="number">
            </mat-form-field>
          </div>

          <div class="singleFieldContainer" id='DT50 used for PECgw'>
            <mat-form-field class="angularSearchWrapper">
              <mat-label>DT50 used for PECgw</mat-label>
              <input (change)="textFieldValueToArray('dt50_used_for_pecgw__c', 'DT50 used for PECgw')"
                     (keyup)="textFieldValueToArray('dt50_used_for_pecgw__c', 'DT50 used for PECgw')"
                     [(ngModel)]="dt50_used_for_pecgw__c"
                     class="orangeTextFieldInput" id="dt50_used_for_pecgw__c" matInput matNativeControl type="number">
            </mat-form-field>
          </div>

          <div class="singleFieldContainer" id='Kfoc used for PECgw'>
            <mat-form-field class="angularSearchWrapper">
              <mat-label>Kfoc used for PECgw</mat-label>
              <input (change)="textFieldValueToArray('kfoc_used_for_pecgw__c', 'Kfoc used for PECgw')"
                     (keyup)="textFieldValueToArray('kfoc_used_for_pecgw__c', 'Kfoc used for PECgw')"
                     [(ngModel)]="kfoc_used_for_pecgw__c"
                     class="orangeTextFieldInput" id="kfoc_used_for_pecgw__c" matInput matNativeControl type="number">
            </mat-form-field>
          </div>


        </div>

        <div class="searchRow">
          <div class="singleFieldContainer">
            <mat-form-field class="angularSearchWrapper">
              <mat-label>Order By</mat-label>
              <mat-select (selectionChange)="defineSortOrder()" [(value)]="sortByFieldName">
                <mat-option value="id">Latest</mat-option>
                <mat-option value="category_name__c">Category</mat-option>
                <mat-option value="substance_type__c">Substance Type</mat-option>
                <mat-option value="related_substance__c">Related Substance</mat-option>
                <mat-option value="study_type__c">Study Type</mat-option>
                <mat-option value="endpoint__c">Endpoint</mat-option>
                <mat-option value="document__c">Source</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="singleFieldContainer">
            <button (click)="getEndpointsWithFilters(this.pageSize, 0)" [hidden]="searchButtonHidden"
                    class="searchButton" data-cy="ResetFilters" title="Apply Filters"><span class="material-icons">search</span>
            </button>
            <div [hidden]="loaderHidden" class="loaderHolder">
              <div class="loader"></div>
            </div>
          </div>
        </div>

        <!-- the list of the records in Vault -->
        <app-existing-efate-endpoint-record (reduceRecordCount)="reduceCount()"
                                            (refreshEndpoints)="getEndpointsWithFilters(this.pageSize, 0);"
                                            *ngFor="let singleRecord of existingEndpoints; index as i;"
                                            [allAdditionalFieldValues]="receivedAdditionalFieldValues"
                                            [allAdditionalFields]="receivedAllAdditionalFields"
                                            [allEndpointUnits]="receivedAllEndpointUnits"
                                            [allEndpoints]="receivedAllEndpoints"
                                            [allOperators]="receivedAllOperators"
                                            [allStudyTypes]="receivedAllStudyTypes"
                                            [allUsers]="allUsers"
                                            [endpointRecord]="singleRecord"
                                            [categories]="receivedCategories"
                                            [categoryPicklists]="receivedCategoryPicklists"
                                            [dataSourceLength]="dataSourceLength"
                                            [documents]="documents"
                                            [relatedSubstances]="relatedSubstances"
                                            [substanceTypes]="substanceTypes"
                                            [subtractNumber]="i"
                                            class="recordsContainer"

        >
        </app-existing-efate-endpoint-record>

        <mat-paginator (page)="pageEvent = $event; onPaginateChange($event)"
                       [length]="dataSourceLength"
                       [pageSizeOptions]="[5, 10, 25, 100]"
                       [pageSize]="pageSize"
                       class="paginator"
                       style="float: left;">
        </mat-paginator>

      </ng-container>

    </mat-expansion-panel>
  </mat-accordion>

</div>
