import {Component, Input, OnInit, QueryList, ViewChildren} from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';
import {ActivatedRoute} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {ExceptionService} from 'src/app/core/exception.service';
import {ServerService} from 'src/app/core/server.service';
import {PicklistValue} from '../../models/picklist-value';
import {ToxEndpointTableItem} from '../../models/tox-endpoint-table-item';
import {CreateObjectResponse, CreateObjectsResponse} from '../../models/create-objects-response';
import {VaultError} from '../../../core/models/vault-error';
import {SubstanceItem} from '../../models/substance-item';
import {ToxAdditionalField} from '../../models/tox-additional-field';
import {RelatedSubstances} from '../../models/related-substances';
import {DocumentMenuItem} from '../../models/document-menu-item';
import {ToxCategoryComponent} from '../tox-category/tox-category.component';
import {ToxCategoryPicklists} from '../../models/tox-category-picklist';

let me: ToxComponent;

@Component({
  selector: 'app-tox',
  templateUrl: './tox.component.html',
  styleUrls: ['./tox.component.css'],
})
export class ToxComponent implements OnInit {
  @Input() substance: SubstanceItem;
  @Input() workAreas: Array<PicklistValue>;
  @Input() workAreaLabel: string;
  @Input() relatedSubstances: Array<RelatedSubstances>;
  @Input() documents: Array<DocumentMenuItem>;
  @Input() substanceTypes: Array<PicklistValue>;
  @ViewChildren('toxCategory') toxicologyCategory: QueryList<ToxCategoryComponent>;
  loading = false;
  disabled = true;
  errorMessage = true;
  public loadingDataHidden: boolean;
  subscriptions: any;
  public currentRequestsNo: number;
  public totalNumberOfRequests: number;
  public workAreaId: string;
  public endpointsArrayList: Array<ToxEndpointTableItem>;
  public categories: Array<PicklistValue>;
  public categoryPicklists: Array<ToxCategoryPicklists>;
  public allEndpoints: Array<PicklistValue>;
  public allStudyTypes: Array<PicklistValue>;
  public allTestSystemTypes: Array<PicklistValue>;
  public allTestSystemNames: Array<PicklistValue>;
  public allRouteOfAdministration: Array<PicklistValue>;
  public allEndpointUnits: Array<PicklistValue>;
  public operators: Array<PicklistValue>;
  public additionalFields: Array<ToxAdditionalField>;
  public additionalPicklistFields: Array<ToxAdditionalField>;
  public uniqueAdditionalPicklist: Array<ToxAdditionalField>;
  public additionalFieldValues: any;
  public successRecords: any[];
  public attemptNumber: number;
  private sessionId: string;
  private haveErrors: Boolean;

  constructor(
    private route: ActivatedRoute,
    private serverService: ServerService,
    private exceptionService: ExceptionService,
    private toastrService: ToastrService
  ) {
    me = this;
    this.workAreaId = '';
    this.subscriptions = {};
    this.endpointsArrayList = [];
    this.categories = [];
    this.categoryPicklists = [];
    this.route.paramMap.subscribe((params) => {
      this.sessionId = params.get('sessionId');
    });
    this.subscriptions = {};
    this.allEndpoints = [];
    this.allStudyTypes = [];
    this.allTestSystemTypes = [];
    this.allTestSystemNames = [];
    this.allRouteOfAdministration = [];
    this.allEndpointUnits = [];
    this.operators = [];
    this.additionalFields = [];
    this.additionalPicklistFields = [];
    this.uniqueAdditionalPicklist = [];
    this.additionalFieldValues = [];
    this.loadingDataHidden = false;
    this.currentRequestsNo = 0;
    this.totalNumberOfRequests = 0;
    this.successRecords = [];
    this.attemptNumber = 0;
  }

  ngOnInit(): void {
    const workAreaObject = this.workAreas.filter(obj => {
      return obj.name__v === this.workAreaLabel;
    });
    this.workAreaId = workAreaObject[0].id;
    this.getAllGroupPicklistNames();
    this.getOperators();
    this.retrieveAdditionalFields();
  }

  getAllGroupPicklistNames(): void {
    this.serverService.isLoading();
    this.subscriptions.getCategoryPicklists = this.serverService
      .getCategoryPicklists(this.sessionId, this.workAreaId)
      .subscribe(
        (response: any) => {
          if (response.data && response.data.length > 0) {
            response.data.forEach((element) => {
              this.categoryPicklists.push({
                categoryId: element.category__c || null,
                studyTypePicklist: element.study_type__c || null,
                endpointPicklist: element.endpoint__c || null,
                endpointUnitPicklist: element.endpoint_unit__c || null,
                testSystemTypesPicklist: element.test_system_type__c || null,
                testSystemNamesPicklist: element.test_system_name__c || null,
                routeOfAdministrationPicklist: element.route_of_administration__c || null,
              });
            });
          }
        },
        (error: HttpErrorResponse) => {
          this.exceptionService.handleError(error);
          this.toastrService.error(
            'Unable to get category picklists.',
            'Error'
          );
        },
        () => {
          const numberOfPicklistRows = this.categoryPicklists.length;
          this.totalNumberOfRequests = numberOfPicklistRows * 6;
          this.getAllPicklistValues();
        }
      );
  }

  getAllPicklistValues(): void {
    this.categoryPicklists.forEach((groupPicklistsT: ToxCategoryPicklists) => {
      this.getEndpointsForGroup(groupPicklistsT);
      this.getStudyTypesForGroup(groupPicklistsT);
      this.getEndpointUnitsForGroup(groupPicklistsT);
      this.getTestSystemTypesForGroup(groupPicklistsT);
      this.getTestSystemNamesForGroup(groupPicklistsT);
      this.getRouteOfAdministrationForGroup(groupPicklistsT);
    });
  }

  getEndpointsForGroup(groupPicklists: ToxCategoryPicklists): void {
    this.subscriptions[
      groupPicklists.endpointPicklist
      ] = this.serverService
      .getPicklist(this.sessionId, groupPicklists.endpointPicklist)
      .subscribe(
        (response) => {
          if (response.picklistValues && response.picklistValues.length > 0) {
            response.picklistValues.forEach((element: any) => {
              const entry = {
                name__v: '',
                id: groupPicklists.categoryId,
                label: element.label,
                name: element.name,
                picklist: groupPicklists.endpointPicklist,
              };
              this.allEndpoints.push(entry);
            });
          }
        },
        (error: HttpErrorResponse) => {
          this.exceptionService.handleError(error);
          this.toastrService.error(
            `Unable to get endpoints for ${groupPicklists.categoryId}.`,
            'Error'
          );
        },
        () => {
          this.currentRequestsNo = this.currentRequestsNo + 1;

          if (this.currentRequestsNo === this.totalNumberOfRequests) {
            this.getCategoriesForWorkArea();
          }
        }
      );
  }

  getStudyTypesForGroup(groupPicklists: ToxCategoryPicklists): void {
    this.subscriptions[
      groupPicklists.studyTypePicklist
      ] = this.serverService
      .getPicklist(this.sessionId, groupPicklists.studyTypePicklist)
      .subscribe(
        (response) => {
          if (response.picklistValues && response.picklistValues.length > 0) {
            response.picklistValues.forEach((element: any) => {
              const entry = {
                name__v: '',
                id: groupPicklists.categoryId,
                label: element.label,
                name: element.name,
                picklist: groupPicklists.studyTypePicklist,
              };
              this.allStudyTypes.push(entry);
            });
          }
        },
        (error: HttpErrorResponse) => {
          this.exceptionService.handleError(error);
          this.toastrService.error(
            `Unable to get study types for ${groupPicklists.categoryId}.`,
            'Error'
          );
        },
        () => {
          this.currentRequestsNo = this.currentRequestsNo + 1;
          if (this.currentRequestsNo === this.totalNumberOfRequests) {
            this.getCategoriesForWorkArea();
          }
        }
      );
  }

  getEndpointUnitsForGroup(groupPicklists: ToxCategoryPicklists): void {
    this.subscriptions[
      groupPicklists.endpointUnitPicklist
      ] = this.serverService
      .getPicklist(this.sessionId, groupPicklists.endpointUnitPicklist)
      .subscribe(
        (response) => {
          if (response.picklistValues && response.picklistValues.length > 0) {
            response.picklistValues.forEach((element: any) => {
              const entry = {
                name__v: '',
                id: groupPicklists.categoryId,
                label: element.label,
                name: element.name,
                picklist: groupPicklists.endpointUnitPicklist,
              };
              this.allEndpointUnits.push(entry);
            });
          }
        },
        (error: HttpErrorResponse) => {
          this.exceptionService.handleError(error);
          this.toastrService.error(
            `Unable to get endpoint units for ${groupPicklists.categoryId}.`,
            'Error'
          );
        },
        () => {
          this.currentRequestsNo = this.currentRequestsNo + 1;
          if (this.currentRequestsNo === this.totalNumberOfRequests) {
            this.getCategoriesForWorkArea();
          }
        }
      );
  }

  getTestSystemTypesForGroup(groupPicklists: ToxCategoryPicklists): void {
    this.subscriptions[
      groupPicklists.testSystemTypesPicklist
      ] = this.serverService
      .getPicklist(this.sessionId, groupPicklists.testSystemTypesPicklist)
      .subscribe(
        (response) => {
          if (response.picklistValues && response.picklistValues.length > 0) {
            response.picklistValues.forEach((element: any) => {
              const entry = {
                name__v: '',
                id: groupPicklists.categoryId,
                name: element.name,
                label: element.label,
                picklist: groupPicklists.testSystemTypesPicklist,
              };
              this.allTestSystemTypes.push(entry);
            });
          }
        },
        (error: HttpErrorResponse) => {
          this.exceptionService.handleError(error);
          this.toastrService.error(
            `Unable to get Test System Types for ${groupPicklists.categoryId}.`,
            'Error'
          );
        },
        () => {
          this.currentRequestsNo = this.currentRequestsNo + 1;
          if (this.currentRequestsNo === this.totalNumberOfRequests) {
            this.getCategoriesForWorkArea();
          }
        }
      );
  }

  getTestSystemNamesForGroup(groupPicklists: ToxCategoryPicklists): void {
    this.subscriptions[
      groupPicklists.testSystemNamesPicklist
      ] = this.serverService
      .getPicklist(this.sessionId, groupPicklists.testSystemNamesPicklist)
      .subscribe(
        (response) => {
          if (response.picklistValues && response.picklistValues.length > 0) {
            response.picklistValues.forEach((element: any) => {
              const entry = {
                name__v: '',
                id: groupPicklists.categoryId,
                name: element.name,
                label: element.label,
                picklist: groupPicklists.testSystemNamesPicklist,
              };
              this.allTestSystemNames.push(entry);
            });
          }
        },
        (error: HttpErrorResponse) => {
          this.exceptionService.handleError(error);
          this.toastrService.error(
            `Unable to get Test Species for ${groupPicklists.categoryId}.`,
            'Error'
          );
        },
        () => {
          this.currentRequestsNo = this.currentRequestsNo + 1;
          if (this.currentRequestsNo === this.totalNumberOfRequests) {
            this.getCategoriesForWorkArea();
          }
        }
      );
  }

  getRouteOfAdministrationForGroup(groupPicklists: ToxCategoryPicklists): void {
    this.subscriptions[
      groupPicklists.routeOfAdministrationPicklist
      ] = this.serverService
      .getPicklist(this.sessionId, groupPicklists.routeOfAdministrationPicklist)
      .subscribe(
        (response) => {
          if (response.picklistValues && response.picklistValues.length > 0) {
            response.picklistValues.forEach((element: any) => {
              const entry = {
                name__v: '',
                id: groupPicklists.categoryId,
                name: element.name,
                label: element.label,
                picklist: groupPicklists.routeOfAdministrationPicklist,
              };
              this.allRouteOfAdministration.push(entry);
            });
          }
        },
        (error: HttpErrorResponse) => {
          this.exceptionService.handleError(error);
          this.toastrService.error(
            `Unable to get Route of Administrations for ${groupPicklists.categoryId}.`,
            'Error'
          );
          console.log('Unable to get Route of Administrations for: ' + groupPicklists.routeOfAdministrationPicklist);
        },
        () => {
          this.currentRequestsNo = this.currentRequestsNo + 1;
          if (this.currentRequestsNo === this.totalNumberOfRequests) {
            this.getCategoriesForWorkArea();
          }
        }
      );
  }

  getOperators(): void {
    this.subscriptions.getOperators = this.serverService
      .getOperators(this.sessionId)
      .subscribe(
        (response) => {
          if (response.picklistValues && response.picklistValues.length > 0) {
            response.picklistValues.forEach((singleOperator) => {
              this.operators.push({
                name__v: '',
                id: '',
                name: singleOperator.name,
                label: singleOperator.label,
                picklist: '',
              });
            });
          }
        },
        (error: HttpErrorResponse) => {
          this.exceptionService.handleError(error);
          this.toastrService.error('Unable to get operators.', 'Error');
        },
        () => {

        }
      );
  }

  retrieveAdditionalFields(): void {
    this.subscriptions.test = this.serverService
      .getAllAdditionalFields(this.sessionId, this.workAreaId)
      .subscribe(
        (response: any) => {
          if (response.data && response.data.length > 0) {
            response.data.forEach((item: any) => {
              const additionalField = {
                categoryId: item?.category__c,
                label: item?.field_label__c ?? 'Unknown field',
                name: item?.save_to_field__c ?? 'unknown_field',
                type: item.field_type__c ?? 'text',
                picklist: item?.source__c,
                group: item?.study_group__c,
                saveTo: item?.save_to_field__c,
                hidden: item?.initially_hidden__c,
                sortNumber: item?.sort_number__c,
              };
              this.additionalFields.push(additionalField);
            });
          }
        },
        (error: HttpErrorResponse) => {
          this.exceptionService.handleError(error);
          this.toastrService.error(
            `Unable to get additional fields for an endpoint.`,
            'Error'
          );
        },
        () => {
          this.uniqueAdditionalSource();
        }
      );
  }

  uniqueAdditionalSource(): void {
    // filter only picklist fields
    this.additionalPicklistFields = this.additionalFields.filter(
      (a) => a.type === 'picklist'
    );
    // remove duplicates
    const array = this.additionalPicklistFields;
    const result = this.uniqueAdditionalPicklist;

    const map = new Map();
    for (const item of array) {
      if (!map.has(item.picklist)) {
        map.set(item.picklist, true); // set any value to Map
        result.push({
          categoryId: item.categoryId,
          label: item.label,
          name: item.name,
          type: item.type,
          picklist: item.picklist,
          group: item.group,
          saveTo: item.saveTo,
          hidden: item.hidden,
          sortNumber: item.sortNumber,
        });
      }
    }
    this.uniqueAdditionalMultiSelectSource();
  }

  uniqueAdditionalMultiSelectSource(): void {
    // filter only multi-picklist fields
    this.additionalPicklistFields = this.additionalFields.filter(
      (b) => b.type === 'multi-picklist'
    );

    // remove duplicates
    const array = this.additionalPicklistFields;
    const map = new Map();
    for (const item of array) {
      if (!map.has(item.picklist)) {
        map.set(item.picklist, true); // set any value to Map
        this.uniqueAdditionalPicklist.push({
          categoryId: item.categoryId,
          label: item.label,
          name: item.name,
          type: item.type,
          picklist: item.picklist,
          group: item.group,
          saveTo: item.saveTo,
          hidden: item.hidden,
          sortNumber: item.sortNumber,
        });
      }
    }
    this.getValuesForAdditionalFieldPicklists();
  }

  getValuesForAdditionalFieldPicklists(): void {
    this.uniqueAdditionalPicklist.forEach((uniqueItem) => {
      this.subscriptions[uniqueItem.picklist] = this.serverService
        .getPicklist(this.sessionId, uniqueItem.picklist)
        .subscribe(
          (response) => {
            if (response.picklistValues && response.picklistValues.length > 0) {
              response.picklistValues.forEach((element: any) => {
                const entry = {
                  name__v: '',
                  id: uniqueItem.group,
                  label: element.label,
                  name: element.name,
                  picklist: uniqueItem.picklist,
                  saveTo: uniqueItem.saveTo,
                };
                this.additionalFieldValues.push(entry);
              });
            }
          },
          (error: HttpErrorResponse) => {
            // re-try
            if (this.attemptNumber === 2) {
              this.exceptionService.handleError(error);
              this.toastrService.error(
                `Unable to get picklist values for ${uniqueItem.picklist}.`,
                'Error'
              );
            } else {
              this.attemptNumber = this.attemptNumber + 1;
              this.additionalFieldValues = []; // clear list
              this.getValuesForAdditionalFieldPicklists();
            }
          },
          () => {

          }
        );
    });
  }

  getCategoriesForWorkArea(): void {
    this.subscriptions.getWorkAreaCategories = this.serverService
      .getEndpointCategories(this.sessionId, this.workAreaId)
      .subscribe(
        (response) => {
          this.categories = response.data.sort((a, b) => a.order_by__c - b.order_by__c) ?? [];
        },
        (error: HttpErrorResponse) => {
          this.exceptionService.handleError(error);
          this.toastrService.error(
            'Unable to get categories for a work area.',
            'Error'
          );
        },
        () => {
          this.serverService.isNotLoading();
          this.disabled = false;
          this.loadingDataHidden = true;
        }
      );
  }

  save(): void {
    this.haveErrors = false; // reset if corrected by the user

    this.loading = true;
    this.disabled = true;
    this.serverService.isLoading();

    document.getElementById('errorMessageHolder').innerHTML =
      'Displayed Endpoints could not be saved due to the following errors:' + '<br>';
    this.errorMessage = true;
    this.successRecords = [];

    this.endpointsArrayList.forEach((record) => {
      const value1 = parseInt(String(record.value_1__c));
      const value2 = parseInt(String(record.value_2__c));

        console.log('record to save ' + JSON.stringify(record));

        if (record.operator__c === 'between__c') {
          if (value2 <= value1 || isNaN(value1) || isNaN(value2)) {
            this.haveErrors = true;
            console.log(value1 + ', ' + value2);
          }
        }
      }
    );

    if (this.haveErrors === true) {
      console.log('saving with errors!');
      this.loading = false;
      this.disabled = false;
      this.serverService.isNotLoading();
      this.toastrService.error('Value 2 must be greater than Value 1', 'Values Error');
    }
    else {
      const body = this.endpointsArrayList;
      this.serverService.postEndpoint(this.sessionId, body).subscribe(
        (success: CreateObjectsResponse) => {
          let created = 0;
          success.data?.forEach((record: CreateObjectResponse) => {
            if (record?.responseStatus === 'SUCCESS') {
              created++;
            }
            if (record?.responseStatus === 'FAILURE') {
              this.errorMessage = false;
              record?.errors?.forEach((error: VaultError) => {
                this.toastrService.error(error.message, error.type);
                document.getElementById('errorMessageHolder').innerHTML =
                  document.getElementById('errorMessageHolder').innerHTML +
                  '<br>' +
                  error.message;
              });
            }
          });
          this.toastrService.info(
            `${created} Endpoint(s) created successfully.`,
            'Info'
          );

          this.loading = false;
          this.disabled = false;
          const response = success.data;

          for (let i = 0; i < this.endpointsArrayList.length; i++) {
            for (let j = 0; j < response.length; j++) {
              if (i === j) {
                if (response[j].responseStatus === 'SUCCESS') {
                  this.successRecords.push(this.endpointsArrayList[i].id__c);
                }
              }
            }
          }
          this.clearSuccessRecords();
        },
        (error: HttpErrorResponse) => {
          this.exceptionService.handleError(error);
          this.toastrService.error(
            `An error occurred while saving endpoints.`,
            'Error'
          );
        },
      );
      this.serverService.isNotLoading();
    }
  }

  clearSuccessRecords(): any {
    for (const recordId of this.successRecords) {
      // define new endpointArrayList by leaving out success records
      this.endpointsArrayList = this.endpointsArrayList.filter(
        obj => obj.id__c !== recordId
      );

      // trigger definition of new categoryEndpointsArrayList
      this.toxicologyCategory.forEach((ToxCategoryComponent) => {
        ToxCategoryComponent.removeSavedEndpoint(recordId);
      });
    }
  }
}
