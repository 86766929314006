import {BrowserModule} from '@angular/platform-browser';
import {HttpClientModule} from '@angular/common/http';
import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {ToastrModule} from 'ngx-toastr';

import {MapModule} from './map/map.module';
import {TimelineModule} from './timeline/timeline.module';
import {SpiderModule} from './spider/spider.module';
import {AppComponent} from './app.component';
import {AppRoutingModule} from './app-routing.module';
import {CoreModule} from './core/core.module';
import {ExceptionService} from './core/exception.service';
import {HeaderComponent} from './core/header/header.component';
import {ServerService} from './core/server.service';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {AiCompareModule} from "./ai-compare/ai-compare.module";
import {MatTooltipModule} from "@angular/material/tooltip";
import {MatListModule} from "@angular/material/list";

@NgModule({
  declarations: [AppComponent, HeaderComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    CoreModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot({
      timeOut: 3000,
      positionClass: 'toast-top-right',
      progressBar: true,
      preventDuplicates: true
    }),
    MatProgressBarModule,
    MapModule,
    SpiderModule,
    TimelineModule,
    AiCompareModule,
    MatTooltipModule,
    MatListModule
  ],
  providers: [ExceptionService, ServerService],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  exports: [
    HeaderComponent
  ]
})
export class AppModule {
}
