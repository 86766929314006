import {Component, ElementRef, Input, OnChanges, OnInit, ViewChild} from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';
import {ServerService} from 'src/app/core/server.service';
import {ToastrService} from 'ngx-toastr';
import {ExceptionService} from 'src/app/core/exception.service';
import {ActivatedRoute} from '@angular/router';
import {PicklistValue} from '../../models/picklist-value';
import {CategoryPicklists} from '../models/category-picklists';
import {AdditionalField} from '../models/additional-field';
import {ExistingEndpoint} from '../models/existing-endpoint';
import {DocumentMenuItem} from '../../models/document-menu-item';
import {SubstanceItem} from '../../models/substance-item';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {MatAutocomplete, MatAutocompleteSelectedEvent, } from '@angular/material/autocomplete';
import {MatChipInputEvent} from '@angular/material/chips';
import {PageEvent} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import {addNewValuesToPicklists} from '../../../shared/add-new-values-to-picklists';


@Component({
  selector: 'app-existing-endpoints',
  templateUrl: './existing-endpoints.component.html',
  styleUrls: ['./existing-endpoints.component.css'],
})

export class ExistingEndpointsComponent implements OnInit,OnChanges {
  dataSource: MatTableDataSource<any>;
  data: Array<any>;
  pageSize: number;
  pageSkip: number;
  dataSourceLength: number;
  loaderHidden = true;
  searchButtonHidden = false;

  // for filters
  visible = true;
  selectable = true;
  removable = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  fieldCtrl = new FormControl();
  filteredFields: Observable<string[]>;
  fields: string[] = ['Category', 'Related Substance'];
  public allFields: string[];

  @ViewChild('fieldInput') fieldInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto') matAutocomplete: MatAutocomplete;

  /**
   * for documents filtering
   */
  searchUserModifyControl: FormControl = new FormControl();
  searchUserCreateControl: FormControl = new FormControl();
  filteredCreateUsers: Observable<any>;
  filteredModifiedUsers: Observable<any>;
  /** */

  subscriptions: any;
  public panelOpenState = false;
  public expandPanel = false;
  public category__c: any;
  public substance_type__c: any;
  public related_substance__c: any;
  public study_type__c: any;
  public endpoint__c: any;
  public endpoint_unit__c: any;
  public operator__c: any;
  public value_1__c: number;
  public value_2__c: number;
  public created_by__v: any;
  public modified_by__v: any;
  public selectedDocumentId: number;

  public crops1__c: any;
  public comment__c: any;
  public focus_step__c: any;
  public scenario_with_highest_concentration__c: any;
  public location_countrysite__c: any;
  public ptrigger__c: any;
  public data_normalization__c: any;
  public no_of_systemssitesconc__c: any;
  public temp_c__c: number;
  public kinetic_model__c: any;
  public ph_dependency__c: any;
  public ph_experiment__c: number;
  public method_of_calc__c: any;
  public dt50_used_for_pecsoil__c: any;
  public no_of_soilssites__c: any;
  public no_of_soils__c: any;
  public no_of_systemssites__c: any;
  public plant_uptake_factor_used_for_pecgw__c: number;
  public method_of_calculation__c: any;
  public dt50_used_for_pecgw__c: number;
  public kfoc_used_for_pecgw__c: number;
  public no_of_sites__c: any;
  public target_crop__c: any;
  public application_rate__c: any;
  public no_of_columns__c: any;
  public ageing_period__c: any;
  public duration__c: any;
  public soil_site__c: any;
  public relevance_trigger__c: any;
  public toxicological_relevance__c: any;
  public no_of_columnssites__c: any;
  public leaching_model__c: any;
  public metabolites_included__c: any;
  public population_group__c: any;
  public no_of_sitescountries__c: any;

  public created_date_start: number;
  public created_date_end: number;
  public modified_date_start: number;
  public modified_date_end: number;

  @Input() receivedCategoryPicklists: Array<CategoryPicklists>;
  @Input() workAreaName: string;
  @Input() workAreaId: string;
  @Input() substance: SubstanceItem;
  @Input() receivedCategories: Array<PicklistValue>;
  @Input() receivedAllStudyTypes: Array<PicklistValue>;
  @Input() receivedAllEndpoints: Array<PicklistValue>;
  @Input() receivedAllEndpointUnits: Array<PicklistValue>;
  @Input() receivedAllOperators: Array<PicklistValue>;
  @Input() receivedAllAdditionalFields: Array<AdditionalField>;
  @Input() receivedAdditionalFieldValues: Array<AdditionalField>;
  @Input() substanceTypes: Array<PicklistValue>;
  @Input() relatedSubstances: any;
  @Input() documents: Array<DocumentMenuItem>;
  public existingEndpoints: Array<ExistingEndpoint>;
  public uniqueAdditionalFields: Array<AdditionalField>;
  public allUsers: any;
  public queryStringConstructor: any;
  public queryStringClause: any;
  public sortByFieldName: string;
  public sortByOrder: string;
  // created date range
  createdRange = new FormGroup({
    createdDateStart: new FormControl(),
    createdDateEnd: new FormControl(),
  });
  // modified date range
  modifiedRange = new FormGroup({
    modifiedDateStart: new FormControl(),
    modifiedDateEnd: new FormControl(),
  });
  pageEvent: PageEvent;
  public uniqueStudyTypes: Array<PicklistValue>;
  public uniqueEndpoints: Array<PicklistValue>;
  public uniqueEndpointUnits: Array<PicklistValue>;
  public All: string;
  private sessionId: string;

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private serverService: ServerService,
    private exceptionService: ExceptionService,
    private toastrService: ToastrService,
    private propagateNewData: addNewValuesToPicklists
  ) {
    this.route.paramMap.subscribe((params) => {
      this.sessionId = params.get('sessionId');
    });

    this.uniqueAdditionalFields = [];
    this.existingEndpoints = [];
    this.uniqueStudyTypes = [];
    this.uniqueEndpoints = [];
    this.uniqueEndpointUnits = [];
    this.sortByFieldName = 'id';
    this.sortByOrder = 'DESC';
    this.allUsers = [];
    this.All = 'All';
    this.queryStringConstructor = [];
    this.queryStringClause = '';
    this.dataSource = new MatTableDataSource();
    this.pageSize = 10;
    this.pageSkip = 0;
    this.dataSourceLength = 0;
    this.category__c = '';
    this.substance_type__c = '';
    this.related_substance__c = '';
    this.study_type__c = '';
    this.endpoint__c = '';
    this.endpoint_unit__c = '';
    this.operator__c = '';
    this.value_1__c = null;
    this.value_2__c = null;
    this.created_by__v = '';
    this.modified_by__v = '';

    this.crops1__c = '';
    this.comment__c = '';
    this.focus_step__c = '';
    this.scenario_with_highest_concentration__c = '';
    this.location_countrysite__c = '';
    this.ptrigger__c = '';
    this.data_normalization__c = '';
    this.no_of_systemssitesconc__c = '';
    this.temp_c__c = null;
    this.kinetic_model__c = '';
    this.ph_dependency__c = '';
    this.ph_experiment__c = null;
    this.method_of_calc__c = '';
    this.dt50_used_for_pecsoil__c = '';
    this.no_of_soilssites__c = '';
    this.no_of_soils__c = '';
    this.no_of_systemssites__c = '';
    this.plant_uptake_factor_used_for_pecgw__c = null;
    this.method_of_calculation__c = '';
    this.dt50_used_for_pecgw__c = null;
    this.kfoc_used_for_pecgw__c = null;
    this.no_of_sites__c = '';
    this.target_crop__c = '';
    this.application_rate__c = '';
    this.no_of_columns__c = '';
    this.ageing_period__c = '';
    this.duration__c = '';
    this.soil_site__c = '';
    this.relevance_trigger__c = '';
    this.toxicological_relevance__c = '';
    this.no_of_columnssites__c = '';
    this.leaching_model__c = '';
    this.metabolites_included__c = '';
    this.population_group__c = '';
    this.no_of_sitescountries__c = '';

    this.selectedDocumentId = null;
    this.created_date_start = null;
    this.created_date_end = null;
    this.modified_date_start = null;
    this.modified_date_end = null;

    /// for filters
    //if (this.workAreaName == 'Environmental Fate') {
    this.allFields = [
      'Category',
      'Related Substance',
      'Substance Type',
      'Created Date Range',
      'Modified Date Range',
      'Source',
      'Created By',
      'Last Modified By',
      'Study Type',
      'Endpoint',
      'Endpoint Unit',
      'Operator',
      'Value 1',
      'Value 2',
      'FOCUS step',
      'P-trigger',
      'Data Normalization',
      'Temp. °C',
      'Kinetic Model',
      'pH dependency',
      'pH (experiment)',
      'Plant uptake factor used for PECgw',
      'Method of calc.',
      'DT50 used for PECgw',
      'Kfoc used for PECgw',
      'Relevance Trigger',
      'Toxicological Relevance',
      'Metabolites included',
    ];

    this.filteredFields = this.fieldCtrl.valueChanges.pipe(
      startWith(null),
      map((field: string | null) =>
        field ? this._filter(field) : this.allFields.slice()
      )
    );
  }

  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    // Add field
    if ((value || '').trim()) {
      this.fields.push(value.trim());
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }

    this.fieldCtrl.setValue(null);
  }

  remove(field: string): void {
    // remove filter form
    document
      .getElementById('preparedFilterFields')
      .appendChild(document.getElementById(field));

    // remove chip
    const index = this.fields.indexOf(field);
    if (index >= 0) {
      this.fields.splice(index, 1);
    }

    // remove from array IF something was selected
    const queryIndex = this.queryStringConstructor
      .map(function(e) {
        return e.fieldLabel;
      })
      .indexOf(field);
    if (queryIndex >= 0) {
      this.queryStringConstructor.splice(queryIndex, 1);
    }

    // reset form selected value
    if (field == 'Category') {
      this.category__c = '';
    }
    if (field == 'Substance Type') {
      this.substance_type__c = '';
    }
    if (field == 'Related Substance') {
      this.related_substance__c = '';
    }
    if (field == 'Study Type') {
      this.study_type__c = '';
    }
    if (field == 'Endpoint') {
      this.endpoint__c = '';
    }
    if (field == 'Endpoint Unit') {
      this.endpoint_unit__c = '';
    }
    if (field == 'Operator') {
      this.operator__c = '';
    }
    if (field == 'Value 1') {
      this.value_1__c = null;
    }
    if (field == 'Value 2') {
      this.value_2__c = null;
    }
    if (field == 'Created By') {
      this.created_by__v = '';
    }
    if (field == 'Last Modified By') {
      this.modified_by__v = '';
    }
    if (field == 'Source') {
      this.selectedDocumentId = null;
    }
    // picklist fields
    if (field == 'FOCUS step') {
      this.focus_step__c = '';
    }
    if (field == 'P-trigger') {
      this.ptrigger__c = '';
    }
    if (field == 'Data Normalization') {
      this.data_normalization__c = '';
    }
    if (field == 'Kinetic Model') {
      this.kinetic_model__c = '';
    }
    if (field == 'pH dependency') {
      this.ph_dependency__c = '';
    }
    if (field == 'Method of calc.') {
      this.method_of_calculation__c = '';
    }
    if (field == 'Relevance Trigger') {
      this.relevance_trigger__c = '';
    }
    if (field == 'Toxicological Relevance') {
      this.toxicological_relevance__c = '';
    }
    if (field == 'Metabolites included') {
      this.metabolites_included__c = '';
    }
    // number fields
    if (field == 'Temp. °C') {
      this.temp_c__c = null;
    }
    if (field == 'pH (experiment)') {
      this.ph_experiment__c = null;
    }
    if (field == 'Plant uptake factor used for PECgw') {
      this.plant_uptake_factor_used_for_pecgw__c = null;
    }
    if (field == 'DT50 used for PECgw') {
      this.dt50_used_for_pecgw__c = null;
    }
    if (field == 'Kfoc used for PECgw') {
      this.kfoc_used_for_pecgw__c = null;
    }

    if (field == 'Created Date Range') {
      this.createdRange.value.createdDateStart = null;
      this.createdRange.value.createdDateEnd = null;
      this.created_date_start = null;
      this.created_date_end = null;
    }
    if (field == 'Modified Date Range') {
      this.modifiedRange.value.modifiedDateStart = null;
      this.modifiedRange.value.modifiedDateEnd = null;
      this.modified_date_start = null;
      this.modified_date_end = null;
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.fields.push(event.option.viewValue);
    this.fieldInput.nativeElement.value = '';
    this.fieldCtrl.setValue(null);

    document
      .getElementById('filterFields')
      .appendChild(document.getElementById(event.option.viewValue));
  }

  ngOnInit(): void {
    this.retrieveAllUsers();
    this.queryStringClause = 'work_area__c = ' + '\'' + this.workAreaId + '\'';

    this.propagateNewData.arrayDataEmitter.subscribe((arrayNamePlusData) => {
      if (arrayNamePlusData[0] === 'endpoint') {
        arrayNamePlusData[1].forEach((picklistValue) => {
          if (this.receivedAllEndpoints.includes(picklistValue)) {
            console.log('already included: ' + JSON.stringify(picklistValue));
          }
          else {
           this.receivedAllEndpoints.push(picklistValue);
           console.log('added: ' + JSON.stringify(picklistValue));
          }
        });
      }
      else if (arrayNamePlusData[0] === 'endpointUnit') {
        arrayNamePlusData[1].forEach((picklistValue) => {
          if (this.receivedAllEndpointUnits.includes(picklistValue)) {
            console.log('already included: ' + JSON.stringify(picklistValue));
          }
          else {
           this.receivedAllEndpointUnits.push(picklistValue);
           console.log('added: ' + JSON.stringify(picklistValue));
          }
        });
      }
    });
  }

  ngOnChanges(): any {
    this.makeUniqueStudyTypes();
    this.makeUniqueEndpoints();
    this.makeUniqueEndpointUnits();
    this.makeUniqueAdditionalFields();
  }

  makeUniqueAdditionalFields(): any {
    // remove duplicates
    const sourceArray = this.receivedAllAdditionalFields;
    const resultArray = this.uniqueAdditionalFields;

    const map = new Map();
    for (const item of sourceArray) {
      if (!map.has(item.saveTo)) {
        map.set(item.saveTo, true); // set any value to Map
        resultArray.push({
          label: item.label,
          name: item.name,
          type: item.type,
          picklist: item.picklist,
          group: item.group,
          saveTo: item.saveTo,
          hidden: item.hidden,
          sortNumber: item.sortNumber,
        });
      }
    }
  }

  makeUniqueStudyTypes(): any {
    const source = this.receivedAllStudyTypes;
    const result = this.uniqueStudyTypes;

    const map = new Map();
    for (const item of source) {
      if (!map.has(item.name)) {
        map.set(item.name, true);
        result.push({
          name__v: '',
          id: '',
          label: item.label,
          name: item.name,
          picklist: item.picklist,
        });
      }
    }
  }

  makeUniqueEndpoints(): any {
    const source = this.receivedAllEndpoints;
    const result = this.uniqueEndpoints;

    const map = new Map();
    for (const item of source) {
      if (!map.has(item.name)) {
        map.set(item.name, true);
        result.push({
          name__v: '',
          id: '',
          label: item.label,
          name: item.name,
          picklist: item.picklist,
        });
      }
    }
  }

  makeUniqueEndpointUnits(): any {
    const source = this.receivedAllEndpointUnits;
    const result = this.uniqueEndpointUnits;

    const map = new Map();
    for (const item of source) {
      if (!map.has(item.name)) {
        map.set(item.name, true);
        result.push({
          name__v: '',
          id: '',
          label: item.label,
          name: item.name,
          picklist: item.picklist,
        });
      }
    }
  }

  getEndpointsWithFilters(LIMIT, OFFSET): any {
    this.loaderHidden = false;
    this.searchButtonHidden = true;
    this.serverService.isLoading();

    let createdDateStart = JSON.stringify(
      this.createdRange.value.createdDateStart
    );
    createdDateStart = createdDateStart.replace('"', '');
    createdDateStart = createdDateStart.replace('"', '');
    let createdDateEnd = JSON.stringify(this.createdRange.value.createdDateEnd);
    createdDateEnd = createdDateEnd.replace('"', '');
    createdDateEnd = createdDateEnd.replace('"', '');

    let modifiedDateStart = JSON.stringify(
      this.modifiedRange.value.modifiedDateStart
    );
    modifiedDateStart = modifiedDateStart.replace('"', '');
    modifiedDateStart = modifiedDateStart.replace('"', '');
    let modifiedDateEnd = JSON.stringify(
      this.modifiedRange.value.modifiedDateEnd
    );
    modifiedDateEnd = modifiedDateEnd.replace('"', '');
    modifiedDateEnd = modifiedDateEnd.replace('"', '');

    // reset condition clauses
    this.queryStringClause =
      'work_area__c = ' +
      '\'' +
      this.workAreaId +
      '\' AND substance_name__c = ' +
      '\'' +
      this.substance.id +
      '\'';

    // unpack conditions array and construct query
    this.queryStringConstructor.forEach((condition: any) => {
      const singleCondition =
        ' AND ' +
        condition.fieldName +
        ' = ' +
        '\'' +
        condition.fieldValue +
        '\'';
      this.queryStringClause = this.queryStringClause + singleCondition;
    });

    if (createdDateStart !== 'null' && createdDateEnd !== 'null') {
      this.queryStringClause =
        this.queryStringClause +
        ' AND created_date__v BETWEEN \'' +
        createdDateStart +
        '\'' +
        ' AND \'' +
        createdDateEnd +
        '\'';
    }

    if (modifiedDateStart !== 'null' && modifiedDateEnd !== 'null') {
      this.queryStringClause =
        this.queryStringClause +
        ' AND modified_date__v BETWEEN \'' +
        modifiedDateStart +
        '\'' +
        ' AND \'' +
        modifiedDateEnd +
        '\'';
    }

    this.queryStringClause =
      this.queryStringClause +
      ' ORDER BY ' +
      this.sortByFieldName +
      ' ' +
      this.sortByOrder +
      ' LIMIT ' +
      LIMIT +
      ' OFFSET ' +
      OFFSET;
    this.subscriptions = this.serverService
      .filterEndpointRecords(
        this.sessionId,
        this.queryStringClause,
        this.workAreaName
      )
      .subscribe(
        (response) => {
          // reset current list
          this.existingEndpoints = [];
          if (response.data && response.data.length > 0) {
            response.data.forEach((it) => this.existingEndpoints.push(it));
            this.dataSourceLength = response.responseDetails.total;
          }
        },
        (error: HttpErrorResponse) => {
          this.exceptionService.handleError(error);
          this.toastrService.error('Unable to get Endpoints.', 'Error');
        },
        () => {
          this.serverService.isNotLoading();
          this.loaderHidden = true;
          this.searchButtonHidden = false;
        }
      );
   // this.checkIfThereIsNewData();


  }

  checkIfThereIsNewData(): void {
    /*
    this.propagateNewData.sendArrayToUpdate.subscribe((arrayName) => {
      console.log('hello array name: ' + JSON.stringify(arrayName));
    });
    */
  }

  retrieveAllUsers(): any {
    this.subscriptions = this.serverService
      .getAllUsers(this.sessionId)
      .subscribe(
        (response) => {
          if (response.responseStatus == 'SUCCESS') {
            response.data.forEach((user: any) => {
              const entry = {
                id: user.id,
                name: user.user_first_name__v + ' ' + user.user_last_name__v,
              };
              this.allUsers.push(entry);

              this.filteredCreateUsers = this.searchUserCreateControl.valueChanges.pipe(
                startWith(''),
                map((val) => this.filter(val))
              );

              this.filteredModifiedUsers = this.searchUserModifyControl.valueChanges.pipe(
                startWith(''),
                map((val) => this.filter(val))
              );
            });
          }
        },
        (error: HttpErrorResponse) => {
          this.exceptionService.handleError(error);
          this.toastrService.error(`Unable to get users.`, 'Error');
        }
      );
  }

  /**
   * used for document search (filtering based on user input)
   * @param val = user text field input
   */
  filter(val) {
    if (this.allUsers) {
      return this.allUsers.filter((option) =>
        option.name.toLowerCase().includes(val)
      );
    }
  }

  picklistValueToArray(name, label, value) {
    const fieldValuePair = {
      fieldName: name,
      fieldLabel: label,
      fieldValue: value,
    };

    // remove from array if something was selected, we need to replace old value with new
    const queryIndex = this.queryStringConstructor
      .map(function(e) {
        return e.fieldLabel;
      })
      .indexOf(label);

    if (queryIndex >= 0) {
      this.queryStringConstructor.splice(queryIndex, 1);
    }

    // add selected condition to array
    if (value !== '') {
      this.queryStringConstructor.push(fieldValuePair);
    }
  }

  textFieldValueToArray(name, label) {
    // @ts-ignore
    const value = document.getElementById(name).value;
    this.picklistValueToArray(name, label, value);
  }

  defineSortOrder(): any {
    if (
      this.sortByFieldName === 'id' ||
      this.sortByFieldName === 'related_substance__c'
    ) {
      this.sortByOrder = 'DESC';
    } else {
      this.sortByOrder = 'ASC';
    }
    this.getEndpointsWithFilters(this.pageSize, 0);
  }

  filterAdditionalFieldValues(
    allAdditionalFieldValues: Array<AdditionalField>,
    filterWord: string
  ): Array<AdditionalField> {
    return allAdditionalFieldValues.filter((item) => item.saveTo == filterWord);
  }

  destroyEndpoints(): any {
    this.existingEndpoints = [];
    // reset filter values (UI)
  }

  onPaginateChange($event: PageEvent) {
    let page = $event.pageIndex;
    const size = $event.pageSize;
    this.pageSize = $event.pageSize;
    const skip = page * size;
    this.pageSkip = skip;
    this.getEndpointsWithFilters(size, skip);
  }

  reduceCount(): void {
    this.dataSourceLength = this.dataSourceLength - 1;
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.allFields.filter(
      (field) => field.toLowerCase().indexOf(filterValue) === 0
    );
  }
}
