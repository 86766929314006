import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {SubstanceSet} from '../../models/substance-set';
import {User} from '../../models/user';
import {VaultService} from '../../service/vault.service';


@Component({
  selector: 'app-share-set-dialog',
  templateUrl: './share-set-dialog.component.html',
  styleUrls: ['./share-set-dialog.component.css']
})
export class ShareSetDialogComponent implements OnInit {

  users: User[];
  selectedUsers: string[] = [];
  setsToShare: SubstanceSet[] = [];


  constructor(
    public dialogRef: MatDialogRef<ShareSetDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: SubstanceSet,
    private service: VaultService
  ) { }

  ngOnInit(): void {
    console.log('data received: ', this.data);
    this.service.getAllUsers().subscribe(response => {
      this.users = response;
    })
  }

  cancel(){
    this.dialogRef.close();
  }

  updateSets(): void{
    for (let i = 0; i < this.selectedUsers.length; i++){
      let hasSelectedId = this.setsToShare.some( set =>
        set.userId === this.selectedUsers[i]);
      if (!hasSelectedId){
        let set: SubstanceSet = new SubstanceSet();
        set.userId = this.selectedUsers[i];
        set.substances = this.data.substances;
        set.setName = this.data.setName;
        this.setsToShare.push(set);
      }
    }
  }

}
