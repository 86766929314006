import {NavItem} from "./nav-item";

export let menu: NavItem[] = [
  {
    displayName: 'Substances',
    route: 'https://sb-basf-doc-mgmt.veevavault.com/ui/#t/0TB000000000R04/all'
  },
  {
    displayName: 'Evaluations',
    route: 'https://sb-basf-doc-mgmt.veevavault.com/ui/#t/0TB000000000S01/all'
  },
  {
    displayName: 'Advanced Search',
    route: 'https://www.google.com/'
  },
  {
    displayName: 'AI Comparison',
    route: 'aicompare',
  },
  {
    displayName: 'Endpoints',
    iconName: 'expand_more',
    route: 'endpoint/',
    child: {
      bindLabel: 'name__v',
      bindValue: 'id',
      heading: 'Please select active ingredient',
      items: []
    }
  },
  {
    displayName: 'Library',
    route: 'https://sb-basf-doc-mgmt.veevavault.com/ui/#t/0TB000000000102/all'
  }
]
