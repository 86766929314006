import {Component, Input, OnInit, QueryList, ViewChildren} from '@angular/core';
import {SubstanceItem} from '../../models/substance-item';
import {PicklistValue} from '../../models/picklist-value';
import {RelatedSubstances} from '../../models/related-substances';
import {DocumentMenuItem} from '../../models/document-menu-item';
import {HttpErrorResponse} from '@angular/common/http';
import {ActivatedRoute} from '@angular/router';
import {ServerService} from '../../../core/server.service';
import {ExceptionService} from '../../../core/exception.service';
import {ToastrService} from 'ngx-toastr';
import {ConsumerSafetyCategoryComponent} from '../consumer-safety-category/consumer-safety-category.component';
import {ConsumerSafetyEndpointTableItem} from '../models/consumer-safety-endpoint-table-item';
import {ConsumerSafetyCategoryPicklists} from '../models/consumer-safety-category-picklists';
import {CreateObjectResponse, CreateObjectsResponse} from '../../models/create-objects-response';
import {VaultError} from '../../../core/models/vault-error';
import {ConsumerSafetyAdditionalFields} from '../models/consumer-safety-additional-fields';
import {SingleRecordDependencies} from "../models/single-record-dependencies";

@Component({
  selector: 'app-consumer-safety',
  templateUrl: './consumer-safety.component.html',
  styleUrls: ['./consumer-safety.component.css'],
})
export class ConsumerSafetyComponent implements OnInit {
  @Input() substance: SubstanceItem;
  @Input() workAreas: Array<PicklistValue>;
  @Input() workAreaLabel: string;
  @Input() relatedSubstances: Array<RelatedSubstances>;
  @Input() documents: Array<DocumentMenuItem>;
  @Input() substanceTypes: Array<PicklistValue>;
  @ViewChildren('consumerSafetyCategory') consumerSafetyCategory: QueryList<ConsumerSafetyCategoryComponent>;
  public loadingDataHidden: boolean;
  loading = false;
  errorMessage = true;
  disabled = true;
  public categories: Array<PicklistValue>;
  public consumerSafetyCategoryPicklists: Array<ConsumerSafetyCategoryPicklists>;
  subscriptions: any;
  public workAreaId: string;
  public totalNumberOfRequests: number;
  public allStudyTypes: Array<PicklistValue>;
  public currentRequestsNo: number;
  public successRecords: any[];
  public endpointsArrayList: Array<ConsumerSafetyEndpointTableItem>;
  public allTestSystemTypes: Array<PicklistValue>;
  public allTestSystemNames: Array<PicklistValue>;
  public singleRecordDependencies: Array<SingleRecordDependencies>;
  public singleRecordDependenciesModelAdditionalFieldValues: Array<ConsumerSafetyAdditionalFields>;
  public additionalFields: Array<ConsumerSafetyAdditionalFields>;
  public additionalPicklistFields: Array<ConsumerSafetyAdditionalFields>;
  public uniqueAdditionalPicklist: Array<ConsumerSafetyAdditionalFields>;
  public additionalFieldValues: Array<ConsumerSafetyAdditionalFields>;
  public identifiedMetabolites: Array<ConsumerSafetyAdditionalFields>; // the list of related substances - converted model
  public analyteNames: Array<ConsumerSafetyAdditionalFields>; // the list of related substances - converted model
  public substanceAsAnalyteNames: Array<ConsumerSafetyAdditionalFields>;
  public attemptNumber: number;
  private sessionId: string;

  constructor(private route: ActivatedRoute,
              private serverService: ServerService,
              private exceptionService: ExceptionService,
              private toastrService: ToastrService) {
    this.route.paramMap.subscribe((params) => {
      this.sessionId = params.get('sessionId');
    });
    this.workAreaId = '';
    this.subscriptions = {};
    this.categories = [];
    this.loadingDataHidden = false;
    this.consumerSafetyCategoryPicklists = [];
    this.totalNumberOfRequests = 0;
    this.allStudyTypes = [];
    this.currentRequestsNo = 0;
    this.successRecords = [];
    this.allTestSystemTypes = [];
    this.allTestSystemNames = [];
    this.singleRecordDependencies = [];
    this.singleRecordDependenciesModelAdditionalFieldValues = [];
    this.endpointsArrayList = [];
    this.additionalFields = [];
    this.additionalPicklistFields = [];
    this.uniqueAdditionalPicklist = [];
    this.additionalFieldValues = [];
    this.identifiedMetabolites = [];
    this.analyteNames = [];
    this.substanceAsAnalyteNames = [];
    this.attemptNumber = 0;
  }

  ngOnInit(): void {

    const workAreaObject = this.workAreas.filter(obj => {
      return obj.name__v === this.workAreaLabel;
    });
    this.workAreaId = workAreaObject[0].id;

    this.getAllGroupPicklistNames();
    this.retrieveAdditionalFields();
    this.retrieveSingleRecordDependencies();
    this.pushRelatedSubstancesIntoIdentifiedMetabolites();
  }


  getAllGroupPicklistNames(): void {
    this.serverService.isLoading();
    this.subscriptions.getCategoryPicklists = this.serverService
      .getCategoryPicklists(this.sessionId, this.workAreaId)
      .subscribe(
        (response: any) => {
          if (response.data && response.data.length > 0) {
            response.data.forEach((element) => {
              this.consumerSafetyCategoryPicklists.push({
                categoryId: element.category__c || null,
                studyTypePicklist: element.study_type__c || null,
                testSystemTypesPicklist: element.test_system_type__c || null,
                testSystemNamesPicklist: element.test_system_name__c || null
              });
            });
          }
        },
        (error: HttpErrorResponse) => {
          this.exceptionService.handleError(error);
          this.toastrService.error(
            'Unable to get category picklists.',
            'Error'
          );
        },
        () => {
          const numberOfPicklistRows = this.consumerSafetyCategoryPicklists.length;
          this.totalNumberOfRequests = numberOfPicklistRows * 3;
          this.getAllPicklistValues();
        }
      );
  }

  getAllPicklistValues(): void {
    this.consumerSafetyCategoryPicklists.forEach((groupPicklist: ConsumerSafetyCategoryPicklists) => {
      this.getStudyTypesForGroup(groupPicklist);
      this.getTestSystemTypesForGroup(groupPicklist);
      this.getTestSystemNamesForGroup(groupPicklist);
    });
  }

  getStudyTypesForGroup(groupPicklists: ConsumerSafetyCategoryPicklists): void {
    if (groupPicklists.studyTypePicklist === 'ghost__c') {
      this.currentRequestsNo = this.currentRequestsNo + 1;
    }
    else if (groupPicklists.studyTypePicklist !== null &&
      groupPicklists.studyTypePicklist !== undefined &&
      groupPicklists.studyTypePicklist !== '') {
      this.subscriptions[groupPicklists.studyTypePicklist] = this.serverService
        .getPicklist(this.sessionId, groupPicklists.studyTypePicklist)
        .subscribe(
          (response) => {
            if (response.picklistValues && response.picklistValues.length > 0) {
              response.picklistValues.forEach((element: any) => {
                const entry = {
                  name__v: '',
                  id: groupPicklists.categoryId,
                  name: element.name,
                  label: element.label,
                  picklist: groupPicklists.studyTypePicklist,
                };
                this.allStudyTypes.push(entry);
              });
            }
          },
          (error: HttpErrorResponse) => {
            this.exceptionService.handleError(error);
            this.toastrService.error(
              `Unable to get study types for ${groupPicklists.categoryId}.`,
              'Error'
            );
          },
          () => {
            this.currentRequestsNo = this.currentRequestsNo + 1;
            if (this.currentRequestsNo === this.totalNumberOfRequests) {
              this.getCategoriesForWorkArea();
            }
          }
        );
    }
  }

  getTestSystemTypesForGroup(groupPicklists: ConsumerSafetyCategoryPicklists): void {
    if (groupPicklists.testSystemTypesPicklist === 'ghost__c') {
      this.currentRequestsNo = this.currentRequestsNo + 1;
    }
    else if (groupPicklists.testSystemTypesPicklist !== null &&
      groupPicklists.testSystemTypesPicklist !== undefined &&
      groupPicklists.testSystemTypesPicklist !== '') {
      this.subscriptions[groupPicklists.testSystemTypesPicklist] = this.serverService
        .getPicklist(this.sessionId, groupPicklists.testSystemTypesPicklist)
        .subscribe(
          (response) => {
            if (response.picklistValues && response.picklistValues.length > 0) {
              response.picklistValues.forEach((element: any) => {
                const entry = {
                  name__v: groupPicklists.studyTypePicklist,
                  id: groupPicklists.categoryId,
                  name: element.name,
                  label: element.label,
                  picklist: groupPicklists.testSystemTypesPicklist,
                };
                this.allTestSystemTypes.push(entry);
              });
            }
          },
          (error: HttpErrorResponse) => {
            this.exceptionService.handleError(error);
            this.toastrService.error(
              `Unable to get endpoints for ${groupPicklists.categoryId}.`,
              'Error'
            );
          },
          () => {
            this.currentRequestsNo = this.currentRequestsNo + 1;

            if (this.currentRequestsNo === this.totalNumberOfRequests) {
              this.getCategoriesForWorkArea();
            }
          }
        );
    }
  }

  getTestSystemNamesForGroup(groupPicklists: ConsumerSafetyCategoryPicklists): void {
    if (groupPicklists.testSystemNamesPicklist === 'ghost__c') {
      this.currentRequestsNo = this.currentRequestsNo + 1;
    }
    else if (groupPicklists.testSystemNamesPicklist !== null &&
      groupPicklists.testSystemNamesPicklist !== undefined)
    {
      this.subscriptions[groupPicklists.testSystemNamesPicklist] = this.serverService
        .getPicklist(this.sessionId, groupPicklists.testSystemNamesPicklist)
        .subscribe(
          (response) => {
            if (response.picklistValues && response.picklistValues.length > 0) {
              response.picklistValues.forEach((element: any) => {
                const entry = {
                  name__v: groupPicklists.studyTypePicklist,
                  id: groupPicklists.categoryId,
                  name: element.name,
                  label: element.label,
                  picklist: groupPicklists.testSystemNamesPicklist,
                };
                this.allTestSystemNames.push(entry);
              });
            }
          },
          (error: HttpErrorResponse) => {
            this.exceptionService.handleError(error);
            this.toastrService.error(
              `Unable to get endpoints for ${groupPicklists.categoryId}.`,
              'Error'
            );
          },
          () => {
            this.currentRequestsNo = this.currentRequestsNo + 1;

            if (this.currentRequestsNo === this.totalNumberOfRequests) {
              this.getCategoriesForWorkArea();
            }
          }
        );
    }
  }

  getCategoriesForWorkArea(): void {
    this.subscriptions.getWorkAreaCategories = this.serverService
      .getEndpointCategories(this.sessionId, this.workAreaId)
      .subscribe(
        (response) => {
          this.categories = response.data.sort((a, b) => a.order_by__c - b.order_by__c) ?? [];
        },
        (error: HttpErrorResponse) => {
          this.exceptionService.handleError(error);
          this.toastrService.error(
            'Unable to get categories for a work area.',
            'Error'
          );
        },
        () => {
          this.serverService.isNotLoading();
          this.disabled = false;
          this.loadingDataHidden = true;
        }
      );
  }

  retrieveAdditionalFields(): void {
    this.subscriptions.retrieveAdditionalFields = this.serverService
      .getAllAdditionalFields(this.sessionId, this.workAreaId)
      .subscribe(
        (response: any) => {
          if (response.data && response.data.length > 0) {
            response.data.forEach((item: any) => {

              const additionalField: ConsumerSafetyAdditionalFields = {
                categoryId: item?.category__c ?? '',
                name: item?.save_to_field__c ?? 'unknown_field',
                label: item?.field_label__c ?? 'Unknown field',
                type: item.field_type__c ?? 'text',
                picklist: item?.source__c,
                saveTo: item?.save_to_field__c,
                group: item?.study_group__c,
                hidden: item?.initially_hidden__c[0],
                sortNumber: item?.sort_number__c,
                dependency: item?.decimal_places__c
              };
              this.additionalFields.push(additionalField);
            });
          }
        },
        (error: HttpErrorResponse) => {
          this.exceptionService.handleError(error);
          this.toastrService.error(
            `Unable to get additional fields for an endpoint.`,
            'Error'
          );
        },
        () => {
          this.uniqueAdditionalSource();
        }
      );
  }

  uniqueAdditionalSource(): void {
    // filter only picklist fields
    this.additionalPicklistFields = this.additionalFields.filter(
      (a) => a.type === 'picklist' || a.type === 'multi-picklist'
    );
    // remove duplicates
    const array = this.additionalPicklistFields;
    const map = new Map();
    for (const item of array) {
      if (!map.has(item.picklist)) {
        map.set(item.picklist, true); // set any value to Map
        this.uniqueAdditionalPicklist.push({
          categoryId: item.categoryId,
          label: item.label,
          name: item.name,
          type: item.type,
          picklist: item.picklist,
          saveTo: item.saveTo,
          group: item.group,
          hidden: item.hidden,
          sortNumber: item.sortNumber,
          dependency: item.dependency
        });
      }
    }
    this.getValuesForAdditionalFieldPicklists();
  }

  getValuesForAdditionalFieldPicklists(): void {
    this.uniqueAdditionalPicklist.forEach((uniqueItem) => {
      if (uniqueItem.picklist !== null && uniqueItem.picklist !== undefined && uniqueItem.picklist !== '' && uniqueItem.picklist !== 'ghost__c') {
        this.subscriptions[uniqueItem.picklist] = this.serverService
          .getPicklist(this.sessionId, uniqueItem.picklist)
          .subscribe(
            (response) => {
              if (response.picklistValues && response.picklistValues.length > 0) {
                response.picklistValues.forEach((element: any) => {
                  const entry: ConsumerSafetyAdditionalFields = {
                    categoryId: uniqueItem.categoryId,
                    label: element.label,
                    name: element.name,
                    type: uniqueItem.type,
                    picklist: uniqueItem.picklist,
                    group: uniqueItem.group,
                    saveTo: uniqueItem.saveTo,
                    hidden: uniqueItem.hidden,
                    sortNumber: uniqueItem.sortNumber,
                    dependency: uniqueItem.dependency
                  };
                  this.additionalFieldValues.push(entry);
                });
              }
            },
            (error: HttpErrorResponse) => {
              // re-try
              if (this.attemptNumber === 2) {
                this.exceptionService.handleError(error);

                this.toastrService.error(
                  `Unable to get picklist values for ${uniqueItem.picklist}.`,
                  'Error'
                );
              } else {
                this.attemptNumber = this.attemptNumber + 1;
                this.additionalFieldValues = []; // clear list
                this.getValuesForAdditionalFieldPicklists();
              }
            },
            () => {
            }
          );
      }
    });
  }

  retrieveSingleRecordDependencies(): void {
    this.subscriptions.getSingleRecordDependencies = this.serverService
      .getSingleRecordDependencies(this.sessionId, this.workAreaId)
      .subscribe(
        (response: any) => {
          if (response.data && response.data.length > 0) {
            response.data.forEach((item: any) => {

              const singleRecordDependency: SingleRecordDependencies = {
                categoryId: item?.category__c ?? '',
                studyTypeGroup: item?.study_type_group__c ?? '',
                dependencyFieldLabel: item?.dependency_field__c ?? '',
                dependencyFieldValue: item.dependency_record__c ?? '',
                dependedFieldLabel: item?.depended_field__c ?? '',
                dependedRecordLabel: item?.label__c ?? '',
                dependedRecordValue: item?.value__c ?? ''
              };
              this.singleRecordDependencies.push(singleRecordDependency);

              const entry: ConsumerSafetyAdditionalFields = {
                categoryId: '',
                label: item?.label__c ?? '',
                name: item?.value__c ?? '',
                type: '',
                picklist: '',
                group: '',
                saveTo: '',
                hidden: '',
                sortNumber: null,
                dependency: ''
              };
              this.singleRecordDependenciesModelAdditionalFieldValues.push(entry);
            });
          }
        },
        (error: HttpErrorResponse) => {
          this.exceptionService.handleError(error);
          this.toastrService.error(
            `Unable to retrieve dependency records.`,
            'Error'
          );
        },
      );
  }

  pushRelatedSubstancesIntoIdentifiedMetabolites(): void {
    this.relatedSubstances.forEach((element: any) => {
      const substance: ConsumerSafetyAdditionalFields = {
        categoryId: '',
        label: element.name,
        name: element.id,
        type: element.substance_type__c[0],
        picklist: '',
        group: '',
        saveTo: '',
        hidden: '',
        sortNumber: null,
        dependency: ''
      };
      this.identifiedMetabolites.push(substance);
    });

    this.substanceToAnalyteNames();
  }

  substanceToAnalyteNames(): void {
    this.analyteNames = this.identifiedMetabolites;

    const substanceAsAFV: ConsumerSafetyAdditionalFields = {
      "categoryId": null,
      "label": this.substance.name,
      "name": this.substance.id,
      "type": '',
      "picklist": '',
      "group": '',
      "saveTo": '',
      "hidden": '',
      "sortNumber": null,
      "dependency": ''
        };

    this.substanceAsAnalyteNames = [substanceAsAFV];
  }


  save(): void {
    this.loading = true;
    this.disabled = true;
    this.serverService.isLoading();

    document.getElementById('errorMessageHolder').innerHTML =
      'Displayed Endpoints are not saved due to the following errors:' + '<br>';
    this.errorMessage = true;
    this.successRecords = [];

    this.endpointsArrayList.forEach((it: ConsumerSafetyEndpointTableItem) => {

      // check for security



      // conversions
      it.study_type__c = it.study_type__c.toString();
      it.test_system_type__c = it.test_system_type__c.toString();
      it.test_system_name__c = it.test_system_name__c.toString();


      if (it.identified_metabolites__c !== '' && it.identified_metabolites__c !== null) {
        it.identified_metabolites__c = ',' + it.identified_metabolites__c.toString();
      }
      if (it.rd_components__c !== '' && it.rd_components__c !== null) {
        it.rd_components__c = ',' + it.rd_components__c.toString();
      }

      it.analyte_type__c = it.analyte_type__c.toString();
      it.analyte_name__c = it.analyte_name__c.toString();
      if (it.analyte_name__c.length > 0) {
        it.analyte_name__c = ',' + it.analyte_name__c;
      }


    });

    const body = this.endpointsArrayList;
    this.serverService.postEndpoint(this.sessionId, body).subscribe(
      (success: CreateObjectsResponse) => {
        let created = 0;
        success.data?.forEach((record: CreateObjectResponse) => {
          if (record?.responseStatus === 'SUCCESS') {
            created++;
          }
          if (record?.responseStatus === 'FAILURE') {
            this.errorMessage = false;
            record?.errors?.forEach((error: VaultError) => {
              this.toastrService.error(error.message, error.type);
              document.getElementById('errorMessageHolder').innerHTML =
                document.getElementById('errorMessageHolder').innerHTML +
                '<br>' +
                error.message;
            });
          }
        });
        this.toastrService.info(
          `${created} Endpoint(s) created successfully.`,
          'Info'
        );

        this.loading = false;
        this.disabled = false;

        const response = success.data;

        for (let i = 0; i < this.endpointsArrayList.length; i++) {
          for (let j = 0; j < response.length; j++) {
            if (i === j) {
              if (response[j].responseStatus === 'SUCCESS') {
                this.successRecords.push(this.endpointsArrayList[i].id__c);
              }
            }
          }
        }
        this.clearSuccessRecords();
      },
      (error: HttpErrorResponse) => {
        this.exceptionService.handleError(error);
        this.toastrService.error(
          `An error occurred while saving endpoints.`,
          'Error'
        );
      }
    );
    this.serverService.isNotLoading();
  }

  clearSuccessRecords(): any {
    for (const recordId of this.successRecords) {
      this.endpointsArrayList = this.endpointsArrayList.filter(
        obj => obj.id__c !== recordId
      );

      this.consumerSafetyCategory.forEach((consumerSafetyCategoryComponent: ConsumerSafetyCategoryComponent) => {
        consumerSafetyCategoryComponent.removeSavedEndpoint(recordId);
      });
    }
  }

}
