import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MapRoutingModule} from './map-routing.module';
import {MapPageComponent} from './map-page/map-page.component';
import {MatRadioModule} from '@angular/material/radio';
import {MatTabsModule} from '@angular/material/tabs';

@NgModule({
  declarations: [MapPageComponent],
  imports: [CommonModule, MapRoutingModule, MatRadioModule, MatTabsModule],
  providers: [],
})
export class MapModule {
}
