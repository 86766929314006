import {Component, OnInit} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {SubstanceSet} from '../../models/substance-set';
import {VaultService} from '../../service/vault.service';

@Component({
  selector: 'app-select-set-dialog',
  templateUrl: './select-set-dialog.component.html',
  styleUrls: ['./select-set-dialog.component.css']
})
export class SelectSetDialogComponent implements OnInit {

  userSets: SubstanceSet[] = []
  selectedSet: SubstanceSet = new SubstanceSet();

  constructor(
    private service: VaultService,
    public dialogRef: MatDialogRef<SelectSetDialogComponent>,
    ) {}

  ngOnInit(): void {
    this.service.getUserSets(this.service.queryParams.userId).subscribe(response => {
      this.userSets = response;
    })
  }

  cancel(): void{
    this.dialogRef.close();
  }

  delete(): void {
    this.service.deleteSet(this.selectedSet.id).subscribe(response => {
      console.log(response);
      this.service.getUserSets(this.service.queryParams.userId).subscribe(response => {
        this.userSets = response;
      });
    });
    this.userSets = this.userSets.slice();
    this.selectedSet = new SubstanceSet();
  }

}
