import {Color} from "chart.js";

export class ChartData {

  data: number[] = [];
  label: string = '';
  backgroundColor: Color = '';
  borderColor: Color = '';
  pointBackgroundColor: Color = ''
  pointBorderColor: Color = '';
  hidden: boolean = false;
  hoverBackgroundColor: string = ''

  addData(score: number) {
    this.data.push(score);
  }

}


