import {NgModule} from '@angular/core';
import {HttpClientModule} from "@angular/common/http";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {CommonModule} from "@angular/common";
import {MatButtonModule} from "@angular/material/button";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatInputModule} from "@angular/material/input";
import {MatToolbarModule} from "@angular/material/toolbar";
import {RouterModule} from "@angular/router";
import {MatListModule} from "@angular/material/list";
import {HomeComponent} from './components/home/home.component';
import {SelectSetDialogComponent} from './components/select-set-dialog/select-set-dialog.component';
import {MatDialogModule} from "@angular/material/dialog";
import {SaveSetDialogComponent} from './components/save-set-dialog/save-set-dialog.component';
import {MatIconModule} from '@angular/material/icon';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {UpdateSetDialogComponent} from './components/update-set-dialog/update-set-dialog.component';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {ShareSetDialogComponent} from './components/share-set-dialog/share-set-dialog.component';
import {MatExpansionModule} from '@angular/material/expansion';
import {SelectPageComponent} from './components/select-page/select-page.component';
import {AiCompareRoutingModule} from './ai-compare-routing.module';
import {NgSelectModule} from '@ng-select/ng-select';
import {DashboardComponent} from './components/dashboard/dashboard.component';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatCardModule} from '@angular/material/card';
import {MatMenuModule} from '@angular/material/menu';
import {LayoutModule} from '@angular/cdk/layout';
import {TableComponent} from './components/table/table.component';
import {MatTableModule} from '@angular/material/table';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatSortModule} from '@angular/material/sort';
import {TableVirtualScrollModule} from 'ng-table-virtual-scroll';
import {CardComponent} from './components/card/card.component';
import {NgApexchartsModule} from "ng-apexcharts";
import {InvalidEntriesDialogComponent} from './components/invalid-entries-dialog/invalid-entries-dialog.component';
import {ChartsComponent} from './components/charts/charts.component';
import {MatTabsModule} from "@angular/material/tabs";
import {NgChartsModule} from "ng2-charts";
import {MatSelectModule} from "@angular/material/select";
import {MatTooltipModule} from "@angular/material/tooltip";
import {LegendItemComponent} from './components/legend-item/legend-item.component';
import {ToastrModule} from "ngx-toastr";
import {HeaderComponent} from './components/header/header.component';
import {WarningDialogComponent} from './components/warning-dialog/warning-dialog.component';


@NgModule({
  declarations: [
    SelectPageComponent,
    HomeComponent,
    SelectSetDialogComponent,
    SaveSetDialogComponent,
    UpdateSetDialogComponent,
    ShareSetDialogComponent,
    DashboardComponent,
    TableComponent,
    CardComponent,
    InvalidEntriesDialogComponent,
    ChartsComponent,
    LegendItemComponent,
    HeaderComponent,
    WarningDialogComponent,

  ],
  imports: [
    ToastrModule.forRoot({
      timeOut: 1000,
      extendedTimeOut: 500,
      preventDuplicates: true
    }),
    HttpClientModule,
    FormsModule,
    NgSelectModule,
    CommonModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatToolbarModule,
    AiCompareRoutingModule,
    MatListModule,
    MatDialogModule,
    MatIconModule,
    MatCheckboxModule,
    DragDropModule,
    MatProgressBarModule,
    MatExpansionModule,
    RouterModule,
    FormsModule,
    MatGridListModule,
    MatCardModule,
    MatMenuModule,
    LayoutModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    TableVirtualScrollModule,
    CommonModule,
    NgApexchartsModule,
    MatTabsModule,
    NgChartsModule,
    MatSelectModule,
    MatTooltipModule,
    ReactiveFormsModule,

  ],
  exports: [
    MatToolbarModule
  ],

})
export class AiCompareModule { }
