/**
 *  Convert special fields to string if they are array
 */

export function convertSpecialFieldsToString(object: any, specialFields: string[]): void {
  specialFields.forEach(it => {
    if (object[it] instanceof Array) {
      object[it] = object[it].join(',');
    }
  });
}


/**
 *  Convert special fields to array
 */

export function convertSpecialFieldsToArray(object: any, specialFields: string[]): void {
  specialFields.forEach(it => {
    if (object[it] !== null && object[it]) {
      object[it] = object[it].split(',');
    }
  });
}
