import {getDateFromString} from './get-date-from-string';

/**
 * Transform date to 'DD Mon YYYY' or 'DD Mon YYYY HH:MM:SS' format
 */
const dateFormatParams = {
  day: 'numeric',
  month: 'short',
  year: 'numeric',
};
const dateTimeFormatParams = {
  ...dateFormatParams,
  hour: '2-digit',
  minute: '2-digit',
  second: '2-digit',
};

export function transformDates(fieldValue: string, fieldName): string {
  const date = getDateFromString(fieldValue);
  if (date instanceof Date) {
    return date
      .toLocaleDateString(
        'en-GB',
        // fieldName.includes('modified_date__v')
        //   ? dateTimeFormatParams
        //   : dateFormatParams
      )
      .replace(/ /g, ' ')
      .replace(',', '');
  } else {
    return fieldValue;
  }
}
