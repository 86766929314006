import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TimelineRoutingModule} from './timeline-routing.module';
import {TimelinePageComponent} from './timeline-page/timeline-page.component';
import {TimelineComponent} from './timeline/timeline.component';
import {HistoryComponent} from './history/history.component';
import {MatTableModule} from '@angular/material/table';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatPaginatorModule} from '@angular/material/paginator';

@NgModule({
  declarations: [TimelinePageComponent, TimelineComponent, HistoryComponent],
  imports: [
    CommonModule,
    TimelineRoutingModule,
    MatTableModule,
    MatTooltipModule,
    MatPaginatorModule,
  ],
  providers: [],
})
export class TimelineModule {
}
