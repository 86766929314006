<div>
  <app-existing-tox-endpoints *ngIf="loadingDataHidden"
                              [documents]="documents"
                              [receivedAdditionalFieldValues]="additionalFieldValues"
                              [receivedAllAdditionalFields]="additionalFields"
                              [receivedAllEndpointUnits]="allEndpointUnits"
                              [receivedAllEndpoints]="allEndpoints"
                              [receivedAllOperators]="operators"
                              [receivedAllStudyTypes]="allStudyTypes"
                              [receivedAllTestSystemNames]="allTestSystemNames"
                              [receivedAllRouteOfAdministration]="allRouteOfAdministration"
                              [receivedAllTestSystemTypes]="allTestSystemTypes"
                              [receivedCategories]="categories"
                              [receivedCategoryPicklists]="categoryPicklists"
                              [relatedSubstances]="relatedSubstances"
                              [substanceTypes]="substanceTypes"
                              [substance]="substance"
                              [workAreaId]="workAreaId"
                              [workAreaName]="workAreaLabel"

  ></app-existing-tox-endpoints>


  <div id="categoryHolder">
    <app-tox-category #toxCategory *ngFor="let toxCategory of categories"
                      [category]="toxCategory"
                      [dataPicklists]="categoryPicklists"
                      [documents]="documents"
                      [endpointsArrayList]="endpointsArrayList"
                      [operators]="operators"
                      [receivedAdditionalFieldValues]="additionalFieldValues"
                      [receivedAllAdditionalFields]="additionalFields"
                      [receivedAllEndpointUnits]="allEndpointUnits"
                      [receivedAllEndpoints]="allEndpoints"
                      [receivedAllStudyTypes]="allStudyTypes"
                      [receivedAllTestSystemNames]="allTestSystemNames"
                      [receivedAllTestSystemTypes]="allTestSystemTypes"
                      [receivedAllRouteOfAdministration]="allRouteOfAdministration"
                      [relatedSubstances]="relatedSubstances"
                      [substanceTypes]="substanceTypes"
                      [substance]="substance"
                      [workAreaId]="workAreaId"
                      [workAreaLabel]="workAreaLabel"
    ></app-tox-category>
  </div>

  <div *ngIf="!loadingDataHidden" class="loadingDataMessage">Loading data...</div>
  <div [hidden]="errorMessage" class="errorMessageHolder" id="errorMessageHolder"></div>
  <div class="controls" id="saveControls">
    <button (click)="save()" [class.spinner]="loading" [disabled]="disabled" class="buttons saveButton" id="saveButton"
            mat-raised-button type="button"><strong>&#10003; </strong>Save Endpoints
    </button>

  </div>

  <div class="spacer"></div>
</div>

