<div class="menu-item-container">
  <a (click)="onItemSelected(item)"
     [ngStyle]="{'border-bottom': this.isItemSelected() ? '2px solid rgba(243, 149, 0, 1)' : ''}"
     class="menu-list-item"
     mat-list-item>
    <mat-icon *ngIf="item.iconName" class="route-icon">{{item.iconName}}</mat-icon>
    <span class="menu-item-text">{{item.displayName}}</span>
  </a>

  <div *ngIf="this.item.expanded" class="expanded-panel">
      <div *ngIf="item.child">
        <h4>{{item.child.heading}}</h4>
        <ng-select (close)="goToLink(item);closeItem();"
                   [(ngModel)]="item.child.selectedId"
                   [items]="item.child.items"
                   appearance="fill"
                   bindLabel="{{item.child.bindLabel}}"
                   bindValue="{{item.child.bindValue}}"
                   class="select-box"
                   groupBy="{{item.child.groupBy}}"
        ></ng-select>
      </div>
  </div>
</div>
