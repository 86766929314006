import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {PicklistValue} from '../../../models/picklist-value';
import {ConsumerSafetyEndpointTableItem} from '../../models/consumer-safety-endpoint-table-item';
import {DocumentMenuItem} from '../../../models/document-menu-item';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute} from '@angular/router';
import {ExceptionService} from '../../../../core/exception.service';
import {ServerService} from '../../../../core/server.service';
import {ToastrService} from 'ngx-toastr';
import {ConsumerSafetyExisting} from '../../models/consumer-safety-existing';
import {convertArrayToString} from '../../../../shared/convert-array-to-string';
import {CreateObjectResponse, CreateObjectsResponse} from '../../../models/create-objects-response';
import {VaultError} from '../../../../core/models/vault-error';
import {HttpErrorResponse} from '@angular/common/http';
import {ConsumerSafetyAdditionalFields} from "../../models/consumer-safety-additional-fields";
import {ConsumerSafetyCategoryPicklists} from "../../models/consumer-safety-category-picklists";
import {SingleRecordDependencies} from "../../models/single-record-dependencies";
import {convertSpecialFieldsToArray, convertSpecialFieldsToString} from "../../../../shared/convert-special-fields";

let me: MagnitudeOfResidueExistingComponent;

@Component({
  selector: 'app-magnitude-of-residue-existing',
  templateUrl: './magnitude-of-residue-existing.component.html',
  styleUrls: ['../../../existing_record.css']
})
export class MagnitudeOfResidueExistingComponent implements OnInit {
  @Input() arrayedEndpointRecord: ConsumerSafetyExisting;
  @Input() category: any;
  @Input() categoryPicklists: Array<ConsumerSafetyCategoryPicklists>;
  @Input() allStudyTypes: Array<PicklistValue>;
  @Input() allTestSystemTypes: Array<PicklistValue>;
  @Input() allTestSystemNames: Array<PicklistValue>;
  @Input() allAdditionalFields: Array<ConsumerSafetyAdditionalFields>;
  @Input() allAdditionalFieldValues: Array<ConsumerSafetyAdditionalFields>;
  @Input() singleRecordDependencies: Array<SingleRecordDependencies>;
  @Input() substanceTypes: Array<PicklistValue>;
  @Input() relatedSubstances: any;
  @Input() analyteNames: Array<ConsumerSafetyAdditionalFields>;
  @Input() substanceAsAnalyteNames: Array<ConsumerSafetyAdditionalFields>;
  @Input() documents: Array<DocumentMenuItem>;
  @Input() allUsers: any;

  @Output() removeInput: EventEmitter<ConsumerSafetyEndpointTableItem> = new EventEmitter();
  @Output() copyExisting: EventEmitter<any> = new EventEmitter();

  subscriptions: any;
  selectFormGroup: FormGroup;
  configureFormGroup: FormGroup;
  public showTestSystemName: boolean;
  public existingEndpointRecord: any;
  public arrayToSave: any;
  public additionalFields: Array<ConsumerSafetyAdditionalFields>;
  public additionalFieldValues: Map<string, any>;
  public hiddenAdditionalFields: Array<ConsumerSafetyAdditionalFields>;
  public testSystemTypes: Array<PicklistValue>;
  public testSystemNames: Array<PicklistValue>;
  public userName: any;
  public createdBy: any;
  public modifiedBy: any;
  public formatCreatedDate: string;
  public formatModifiedDate: string;

  showExistingRecord = true;
  showEditRecord = false;
  public purposeMessage: string;
  private sessionId: string;

  constructor(
    private route: ActivatedRoute,
    private exceptionService: ExceptionService,
    private serverService: ServerService,
    private toastrService: ToastrService,
    private formBuilder: FormBuilder
  ) {
    me = this;
    this.subscriptions = {};
    this.route.paramMap.subscribe((params) => {
      this.sessionId = params.get('sessionId');
    });
    this.selectFormGroup = this.formBuilder.group({
      category__c: [{value: 'Acute Toxicity', disabled: true}],
      substanceType: [],
      relatedSubstance: [{value: '', disabled: true}],
      studyType: [{value: '', disabled: false}, Validators.required],
      testSystemTypeMultiSelect: [{value: [], disabled: true}],
      testSystemNameMultiSelect: [{value: [], disabled: true}],
      // additional (depended) fields
      analyte_name__c: [{value: '', disabled: false}],
      analyte_type__c: [{value: '', disabled: false}],
      application_type__c: [{value: '', disabled: false}],
      comment__c: [{value: '', disabled: false}],
      dose_level_mgkg_bwday__c: [{value: '', disabled: false}],
      dose_level_mgkg_dm_diet__c: [{value: '', disabled: false}],
      matrix__c: [{value: '', disabled: false}],
      processing_factor__c: [{value: '', disabled: false}],
      replant_interval_days__c: [{value: '', disabled: false}],
      replant_restriction_days__c: [{value: '', disabled: false}],
      replant_restrictions__c: [{value: '', disabled: false}],
      residue_001_mgkg__c: [{value: '', disabled: false}],
      residues_in_edible_matrices_001_mgkg__c: [{value: '', disabled: false}],
      rotational_mrls_required__c: [{value: '', disabled: false}],
      sufficient_data_package__c: [{value: '', disabled: false}],
      sum_of_analytes_005_mgkg__c: [{value: '', disabled: false}],
      worst_case_plateau_covered__c: [{value: '', disabled: false}],
      // default
      source: [{value: '', disabled: false}],
    });
    this.showTestSystemName = false;
    this.additionalFields = [];
    this.additionalFieldValues = new Map();
    this.testSystemTypes = [];
    this.testSystemNames = [];

    this.purposeMessage = 'editOnExisting';
  }

  ngOnInit(): void {
    this.selectFormGroup.disable();

    // convert record values to string values
    this.existingEndpointRecord = JSON.parse(
      JSON.stringify(this.arrayedEndpointRecord)
    );

    convertArrayToString(
      this.arrayedEndpointRecord,
      this.existingEndpointRecord,
      []
    );

    convertSpecialFieldsToArray(this.existingEndpointRecord, ['test_system_type__c', 'test_system_name__c']);

    if (this.existingEndpointRecord.created_by__v === 1) {
      this.createdBy = [{name: 'System'}];
    } else {
      this.createdBy = this.allUsers.filter(
        (c) => c.id === this.existingEndpointRecord.created_by__v
      );
    }

    if (this.existingEndpointRecord.modified_by__v === 1) {
      // eslint-disable-next-line quote-props
      this.modifiedBy = [{name: 'System'}];
    } else {
      this.modifiedBy = this.allUsers.filter(
        (m) => m.id === this.existingEndpointRecord.modified_by__v
      );
    }

    this.formatCreatedDate =
      this.existingEndpointRecord.created_date__v.substring(0, 10) +
      ', ' +
      this.existingEndpointRecord.created_date__v.substring(11, 16) +
      'h Z';
    this.formatModifiedDate =
      this.existingEndpointRecord.modified_date__v.substring(0, 10) +
      ', ' +
      this.existingEndpointRecord.modified_date__v.substring(11, 16) +
      'h Z';

    this.selectFormGroup.get('source').setValue(this.existingEndpointRecord.document_unbound__c);
    this.showAdditionalFields();
    Object.keys(this.existingEndpointRecord).forEach((key) => this.checkDependency(key));
    this.setFormFields();
  }

  showAdditionalFields(): void {
    const preAllAdditionalFields = this.allAdditionalFields.filter(
      (entry) =>
        entry.group === this.existingEndpointRecord.study_type_picklist_name__c
    );
    // remove fields mark with 'yes__c' in Initially Hidden field
    const preAdditionalFields = preAllAdditionalFields.filter(obj =>
      obj.hidden !== 'yes__c'
    );
    this.additionalFields = preAdditionalFields.sort((a, b) => a.sortNumber - b.sortNumber);
  }

  checkDependency(fieldName: string): void {
    if (fieldName === 'replant_restrictions__c' && this.existingEndpointRecord.replant_restrictions__c != null) {
      if (this.existingEndpointRecord.replant_restrictions__c === 'yes__c') {
        // show dependency field
        this.showAdditionalField('replant_restriction_days__c');
      }
      else {
        this.existingEndpointRecord.replant_restriction_days__c = null;
        this.selectFormGroup.get('replant_restriction_days__c').setValue('');
        // hide dependency field
        this.hideAdditionalField('replant_restriction_days__c');
      }
    }
  }

  showAdditionalField(fieldName: string): void {
    // get all additional fields for study type group
    const allAdditionalFields = this.allAdditionalFields.filter(
      (entry) =>
        entry.group === this.existingEndpointRecord.study_type_picklist_name__c
    );
    // find field to show
    const fieldToShow = allAdditionalFields.filter(obj =>
      obj.name === fieldName
    );
    this.additionalFields = this.additionalFields.concat(fieldToShow);
    this.additionalFields = this.additionalFields.sort((a, b) => a.sortNumber - b.sortNumber);
  }

  hideAdditionalField(fieldName: string): void {
    const preAdditionalFields = this.additionalFields.filter(obj =>
      obj.name !== fieldName
    );
    this.additionalFields = preAdditionalFields.sort((a, b) => a.sortNumber - b.sortNumber);
  }

  setFormFields(): void {
    this.selectFormGroup.get('category__c').setValue(this.existingEndpointRecord.category__c);
    this.selectFormGroup.get('substanceType').setValue(this.existingEndpointRecord.substance_type__c);
    this.selectFormGroup.get('relatedSubstance').setValue(this.existingEndpointRecord.related_substance__c);
    this.selectFormGroup.get('studyType').setValue(this.existingEndpointRecord.study_type__c);
    if (this.existingEndpointRecord.test_system_type__c) {
      this.selectFormGroup.get('testSystemTypeMultiSelect').setValue(this.existingEndpointRecord.test_system_type__c);
    }
    if (this.existingEndpointRecord.test_system_name__c) {
      this.selectFormGroup.get('testSystemNameMultiSelect').setValue(this.existingEndpointRecord.test_system_name__c);
    }
    // additional fields
    if (this.existingEndpointRecord.analyte_name__c) {
      this.selectFormGroup.get('analyte_name__c').setValue(this.existingEndpointRecord.analyte_name__c.split(','));
    }
    this.selectFormGroup.get('analyte_type__c').setValue(this.existingEndpointRecord.analyte_type__c);
    this.selectFormGroup.get('application_type__c').setValue(this.existingEndpointRecord.application_type__c);
    this.selectFormGroup.get('comment__c').setValue(this.existingEndpointRecord.comment__c);
    this.selectFormGroup.get('dose_level_mgkg_bwday__c').setValue(this.existingEndpointRecord.dose_level_mgkg_bwday__c);
    this.selectFormGroup.get('dose_level_mgkg_dm_diet__c').setValue(this.existingEndpointRecord.dose_level_mgkg_dm_diet__c);
    this.selectFormGroup.get('matrix__c').setValue(this.existingEndpointRecord.matrix__c);
    this.selectFormGroup.get('processing_factor__c').setValue(this.existingEndpointRecord.processing_factor__c);
    if (this.existingEndpointRecord.replant_interval_days__c) {
      this.selectFormGroup.get('replant_interval_days__c').setValue(this.existingEndpointRecord.replant_interval_days__c.split(','));
    }
    this.selectFormGroup.get('replant_restriction_days__c').setValue(this.existingEndpointRecord.replant_restriction_days__c);
    this.selectFormGroup.get('replant_restrictions__c').setValue(this.existingEndpointRecord.replant_restrictions__c);
    this.selectFormGroup.get('residue_001_mgkg__c').setValue(this.existingEndpointRecord.residue_001_mgkg__c);
    this.selectFormGroup.get('residues_in_edible_matrices_001_mgkg__c').setValue(this.existingEndpointRecord.residues_in_edible_matrices_001_mgkg__c);
    this.selectFormGroup.get('rotational_mrls_required__c').setValue(this.existingEndpointRecord.rotational_mrls_required__c);
    this.selectFormGroup.get('sufficient_data_package__c').setValue(this.existingEndpointRecord.sufficient_data_package__c);
    this.selectFormGroup.get('sum_of_analytes_005_mgkg__c').setValue(this.existingEndpointRecord.sum_of_analytes_005_mgkg__c);
    this.selectFormGroup.get('worst_case_plateau_covered__c').setValue(this.existingEndpointRecord.worst_case_plateau_covered__c);
    // default
    this.selectFormGroup.get('source').setValue(this.existingEndpointRecord.document_unbound__c);
  }

  enableEditing(): any {
    this.showExistingRecord = false;
    this.showEditRecord = true;
  }

  cancelEditing(): void {
    this.showExistingRecord = true;
    this.showEditRecord = false;
  }

  saveChanges(endpointRecord): void {
    this.serverService.isLoading();

    delete endpointRecord.created_by__v;
    delete endpointRecord.modified_by__v;
    delete endpointRecord.created_date__v;
    delete endpointRecord.modified_date__v;

    this.arrayToSave = endpointRecord;
    convertSpecialFieldsToString(this.arrayToSave, ['test_system_type__c', 'test_system_name__c']);

    const body = Array(this.arrayToSave);

    this.serverService.updateEndpoint(this.sessionId, body).subscribe(
      (success: CreateObjectsResponse) => {
        let updated = 0;
        success.data?.forEach((record: CreateObjectResponse) => {
          if (record?.responseStatus === 'SUCCESS') {
            updated++;
          }
          if (record?.responseStatus === 'FAILURE') {
            record?.errors?.forEach((error: VaultError) => {
              this.toastrService.error(error.message, error.type);
            });
          }
        });

        this.toastrService.info(
          ` Endpoint updated successfully.`,
          'Info'
        );
        this.serverService.isNotLoading();
      },
      (error: HttpErrorResponse) => {
        this.exceptionService.handleError(error);
        this.toastrService.error(
          `An error occurred while updating endpoint.`,
          'Error'
        );
        this.serverService.isNotLoading();
      }
    );

    this.showExistingRecord = true;
    this.showEditRecord = false;
    convertSpecialFieldsToArray(endpointRecord, ['test_system_type__c', 'test_system_name__c']);
    this.existingEndpointRecord = endpointRecord;
    console.log('updated record: ' + JSON.stringify(this.existingEndpointRecord));
    this.showAdditionalFields();
    Object.keys(this.existingEndpointRecord).forEach((key) => this.checkDependency(key));
    this.setFormFields();
  }

  copyExistingEndpoint(endpoint: ConsumerSafetyExisting): any {
    this.copyExisting.emit(endpoint);
  }

  filterAdditionalFieldValues(
    allAdditionalFieldValues: Array<ConsumerSafetyAdditionalFields>,
    filterWord: string,
  ): Array<ConsumerSafetyAdditionalFields> {

    if (filterWord === 'ghost__c') {
      if (this.existingEndpointRecord.analyte_type__c === 'parent__c') {
        return this.substanceAsAnalyteNames;
      }
      if (this.existingEndpointRecord.analyte_type__c === 'metabolite__c') {
        // filter only metabolites
        return this.analyteNames.filter((analyteName) => analyteName.type === 'metabolite__c');
      }
      if (this.existingEndpointRecord.analyte_type__c === 'rdmo__c' || this.existingEndpointRecord.analyte_type__c === 'rdra__c') {
        return this.analyteNames;
      }
    }
    else {
      return allAdditionalFieldValues.filter(
        (item) => item.picklist === filterWord
      );
    }
  }

}
