<div>
  <app-phys-chem-data-category-existing *ngIf="loadingDataHidden"
                                        [documents]="documents"
                                        [receivedAllAdditionalFields]="additionalFields"
                                        [receivedAllEndpointUnits]="allEndpointUnits"
                                        [receivedAllEndpoints]="allEndpoints"
                                        [receivedAllOperators]="operators"
                                        [receivedAllTestSystemTypes]="allTestSystemTypes"
                                        [receivedCategories]="categories"
                                        [receivedCategoryPicklists]="physChemDataCategoryPicklists"
                                        [relatedSubstances]="relatedSubstances"
                                        [substanceTypes]="substanceTypes"
                                        [substance]="substance"
                                        [workAreaId]="workAreaId"
                                        [workAreaName]="workAreaLabel"
  ></app-phys-chem-data-category-existing>

  <div>
    <app-phys-chem-data-category #physChemDataCategory *ngFor="let ecoCategory of categories"
                                 [category]="ecoCategory"
                                 [dataPicklists]="physChemDataCategoryPicklists"
                                 [documents]="documents"
                                 [endpointsArrayList]="endpointsArrayList"
                                 [operators]="operators"
                                 [receivedAllAdditionalFields]="additionalFields"
                                 [receivedAllEndpointUnits]="allEndpointUnits"
                                 [receivedAllEndpoints]="allEndpoints"
                                 [receivedAllTestSystemTypes]="allTestSystemTypes"
                                 [relatedSubstances]="relatedSubstances"
                                 [substanceTypes]="substanceTypes"
                                 [substance]="substance"
                                 [workAreaId]="workAreaId"
                                 [workAreaLabel]="workAreaLabel"
    ></app-phys-chem-data-category>
  </div>

  <div *ngIf="!loadingDataHidden" class="loadingDataMessage">Loading data...</div>
  <div [hidden]="errorMessage" class="errorMessageHolder" id="errorMessageHolder"></div>
  <div class="controls" id="saveControls">
    <button (click)="save()" [class.spinner]="loading" [disabled]="disabled" class="buttons saveButton" id="saveButton"
            mat-raised-button type="button"><strong>&#10003; </strong>Save Endpoints
    </button>

  </div>

  <div class="spacer"></div>
</div>

