<div class="existingEndpointsComponent">

  <mat-accordion>
    <mat-expansion-panel (closed)="destroyEndpoints(); panelOpenState = false"
                         (opened)="getEndpointsWithFilters(this.pageSize, 0); panelOpenState = true"
                         [expanded]="expandPanel"
                         style="border:none; box-shadow: none;">
      <mat-expansion-panel-header class="expansionHeader">
        <mat-panel-title class="expansionTitle">
          Existing "{{workAreaName}}" Endpoints With "{{substance.name}}" Substance.
        </mat-panel-title>
      </mat-expansion-panel-header>

      <ng-container>

        <div class="filterChipsRow">

          <mat-form-field class="example-chip-list">
            <mat-label>Filters</mat-label>
            <mat-chip-list #chipList aria-label="Fields selection">
              <mat-chip (removed)="remove(field)"
                        *ngFor="let field of fields"
                        [removable]="removable"
                        [selectable]="selectable"
                        class="angularFormChipValue">
                {{field}}
                <mat-icon *ngIf="removable" matChipRemove>cancel</mat-icon>
              </mat-chip>
              <input
                #fieldInput
                (matChipInputTokenEnd)="add($event)"
                [formControl]="fieldCtrl"
                [matAutocomplete]="auto"
                [matChipInputFor]="chipList"
                [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                class="angularFormChipValue" placeholder="New filter...">
            </mat-chip-list>

            <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">

              <ng-container *ngFor="let field of filteredFields | async">
                <mat-option *ngIf="!fields.includes(field)" [value]="field" class="angularFormSelector">
                  {{field}}
                </mat-option>
              </ng-container>
            </mat-autocomplete>
          </mat-form-field>
        </div>

        <div class="filterFieldsRowSELECTED" id="filterFields">

          <div class="singleFieldContainer" id='Category'>
            <mat-form-field class="angularSearchWrapper">
              <mat-label>Category</mat-label>
              <mat-select (selectionChange)="picklistValueToArray('category__c', 'Category', header.category__c)"
                          [(ngModel)]="header.category__c">
                <mat-option value="">All</mat-option>
                <mat-option *ngFor="let category of receivedCategories" [value]=" category.id">
                  {{ category.name__v}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="singleFieldContainer" id='Related Substance'>
            <mat-form-field class="angularSearchWrapper">
              <mat-label>Related Substance</mat-label>
              <mat-select
                (selectionChange)="picklistValueToArray('related_substance__c', 'Related Substance', header.related_substance__c)"
                [(ngModel)]="header.related_substance__c">
                <mat-option value="">All</mat-option>
                <mat-option *ngFor="let relatedSubstance of relatedSubstances" [value]=" relatedSubstance.id">
                  {{ relatedSubstance.name}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

        </div>

        <div class="filterFieldsRow" id="preparedFilterFields">

          <div class="singleFieldContainer" id='Substance Type'>
            <mat-form-field class="angularSearchWrapper">
              <mat-label>Substance Type</mat-label>
              <mat-select
                (selectionChange)="picklistValueToArray('substance_type__c', 'Substance Type', header.substance_type__c)"
                [(ngModel)]="header.substance_type__c">
                <mat-option value="">All</mat-option>
                <mat-option *ngFor="let substanceType of substanceTypes" [value]=" substanceType.name">
                  {{ substanceType.label}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="singleFieldContainer" id='Study Type'>
            <mat-form-field class="angularSearchWrapper">
              <mat-label>Study Type</mat-label>
              <mat-select (selectionChange)="picklistValueToArray('study_type__c', 'Study Type', header.study_type__c)"
                          [(ngModel)]="header.study_type__c">
                <mat-option value="">All</mat-option>
                <mat-option *ngFor="let filterStudyType of uniqueStudyTypes" [value]=" filterStudyType.name">
                  {{ filterStudyType.label}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="singleFieldContainer" id="Created Date Range">
            <mat-form-field class="angularSearchWrapper">
              <mat-label>Created Date Range</mat-label>
              <mat-date-range-input [formGroup]="createdRange" [rangePicker]="picker">
                <input [(ngModel)]="header.created_date_start" formControlName="createdDateStart" matStartDate
                       placeholder="Start date">
                <input [(ngModel)]="header.created_date_end" formControlName="createdDateEnd" matEndDate
                       placeholder="End date">
              </mat-date-range-input>
              <mat-datepicker-toggle [for]="picker" matSuffix></mat-datepicker-toggle>
              <mat-date-range-picker #picker color="accent"></mat-date-range-picker>

              <mat-error *ngIf="createdRange.controls.createdDateStart.hasError('matStartDateInvalid')">Invalid start
                date
              </mat-error>
              <mat-error *ngIf="createdRange.controls.createdDateEnd.hasError('matEndDateInvalid')">Invalid end date
              </mat-error>
            </mat-form-field>
          </div>

          <div class="singleFieldContainer" id="Modified Date Range">
            <mat-form-field class="angularSearchWrapper">
              <mat-label>Modified Date Range</mat-label>
              <mat-date-range-input [formGroup]="modifiedRange" [rangePicker]="modifiedPicker">
                <input [(ngModel)]="header.modified_date_start" formControlName="modifiedDateStart" matStartDate
                       placeholder="Start date">
                <input [(ngModel)]="header.modified_date_end" formControlName="modifiedDateEnd" matEndDate
                       placeholder="End date">
              </mat-date-range-input>
              <mat-datepicker-toggle [for]="modifiedPicker" matSuffix></mat-datepicker-toggle>
              <mat-date-range-picker #modifiedPicker color="accent"></mat-date-range-picker>

              <mat-error *ngIf="modifiedRange.controls.modifiedDateStart.hasError('matStartDateInvalid')">Invalid start
                date
              </mat-error>
              <mat-error *ngIf="modifiedRange.controls.modifiedDateEnd.hasError('matEndDateInvalid')">Invalid end date
              </mat-error>
            </mat-form-field>
          </div>

          <div class="singleFieldContainer" id="Created By">
            <div class="form-row">
              <mat-form-field class="angularSearchWrapper">
                <input [(ngModel)]="header.created_by__v"
                       [formControl]="searchUserCreateControl"
                       [matAutocomplete]="autoCreatedUser"
                       aria-label="User"
                       matInput
                       placeholder="Created By" type="text"
                >
                <mat-autocomplete #autoCreatedUser="matAutocomplete">
                  <mat-option (onSelectionChange)="picklistValueToArray('created_by__v', 'Created By', '')"
                              [value]="All"
                              value="">All
                  </mat-option>
                  <mat-option (onSelectionChange)="picklistValueToArray('created_by__v', 'Created By', user.id)"
                              *ngFor="let user of filteredCreateUsers | async" [value]="user.name"
                              class="angularFormSelector">
                    {{user.name}}
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field>
            </div>
          </div>

          <div class="singleFieldContainer" id="Last Modified By">
            <div class="form-row">
              <mat-form-field class="angularSearchWrapper">
                <input [(ngModel)]="header.modified_by__v"
                       [formControl]="searchUserModifyControl"
                       [matAutocomplete]="autoModifiedUser"
                       aria-label="User"
                       matInput
                       placeholder="Last Modified By" type="text"
                >
                <mat-autocomplete #autoModifiedUser="matAutocomplete">
                  <mat-option (onSelectionChange)="picklistValueToArray('modified_by__v', 'Last Modified By', '')"
                              [value]="All"
                              value="">All
                  </mat-option>
                  <mat-option (onSelectionChange)="picklistValueToArray('modified_by__v', 'Last Modified By', user.id)"
                              *ngFor="let user of filteredModifiedUsers | async" [value]="user.name"
                              class="angularFormSelector">
                    {{user.name}}
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field>
            </div>
          </div>

          <div class="singleFieldContainer" id="Source">
            <mat-form-field class="angularSearchWrapper">
              <mat-label>Source</mat-label>
              <mat-select
                (selectionChange)="picklistValueToArray('document_unbound__c', 'Source', header.selectedDocumentId)"
                [(ngModel)]="header.selectedDocumentId">
                <mat-option value="">All</mat-option>
                <mat-option *ngFor="let document of documents" [value]=" document.id">
                  {{ document.name}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="singleFieldContainer" id='Test System Type'>
            <mat-form-field class="angularSearchWrapper">
              <mat-label>Test System Type</mat-label>
              <mat-select
                (selectionChange)="picklistValueToArray('test_system_type__c', 'Test System Type', header.test_system_type__c)"
                [(ngModel)]="header.test_system_type__c">
                <mat-option value="">All</mat-option>
                <mat-option *ngFor="let testSystemType of uniqueTestSystemTypes" [value]=" testSystemType.name">
                  {{ testSystemType.label}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="singleFieldContainer" id='Test System Name'>
            <mat-form-field class="angularSearchWrapper">
              <mat-label>Test System Name</mat-label>
              <mat-select
                (selectionChange)="picklistValueToArray('test_system_name__c', 'Test System Name', header.test_system_name__c)"
                [(ngModel)]="header.test_system_name__c">
                <mat-option value="">All</mat-option>
                <mat-option *ngFor="let testSystemName of uniqueTestSystemNames" [value]=" testSystemName.name">
                  {{ testSystemName.label}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="singleFieldContainer" id='Application Type'>
            <mat-form-field class="angularSearchWrapper">
              <mat-label>Application Type</mat-label>
              <mat-select
                (selectionChange)="picklistValueToArray('application_type__c', 'Application Type', header.application_type__c)"
                [(ngModel)]="header.application_type__c">
                <mat-option value="">All</mat-option>
                <mat-option
                  *ngFor="let applicationType of uniqueApplicationType" [value]="applicationType.name">
                  {{applicationType.label}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="singleFieldContainer" id='Unit (Application Rate)'>
            <mat-form-field class="angularSearchWrapper">
              <mat-label>Unit (Application Rate)</mat-label>
              <mat-select
                (selectionChange)="picklistValueToArray('unit_application_rate__c', 'Unit (Application Rate)', header.unit_application_rate__c)"
                [(ngModel)]="header.unit_application_rate__c">
                <mat-option value="">All</mat-option>
                <mat-option
                  *ngFor="let additionalField of filterAdditionalFieldValues(receivedAdditionalFieldValues, 'unit_application_rate__c')"
                  [value]="additionalField.name">
                  {{additionalField.label}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="singleFieldContainer" id='Replant Interval (days)'>
            <mat-form-field class="angularSearchWrapper">
              <mat-label>Replant Interval (days)</mat-label>
              <mat-select
                (selectionChange)="picklistValueToArray('replant_interval_days__c', 'Replant Interval (days)', header.replant_interval_days__c)"
                [(ngModel)]="header.replant_interval_days__c">
                <mat-option value="">All</mat-option>
                <mat-option
                  *ngFor="let additionalField of filterAdditionalFieldValues(receivedAdditionalFieldValues, 'replant_interval_days__c')"
                  [value]="additionalField.name">
                  {{additionalField.label}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="singleFieldContainer" id='Open radio label topic?'>
            <mat-form-field class="angularSearchWrapper">
              <mat-label>Open radio label topic?</mat-label>
              <mat-select
                (selectionChange)="picklistValueToArray('open_radio_label_topic__c', 'Open radio label topic?', header.open_radio_label_topic__c)"
                [(ngModel)]="header.open_radio_label_topic__c">
                <mat-option value="">All</mat-option>
                <mat-option
                  *ngFor="let additionalField of filterAdditionalFieldValues(receivedAdditionalFieldValues, 'open_radio_label_topic__c')"
                  [value]="additionalField.name">
                  {{additionalField.label}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="singleFieldContainer" id='Open isomer topic?'>
            <mat-form-field class="angularSearchWrapper">
              <mat-label>Open isomer topic?</mat-label>
              <mat-select
                (selectionChange)="picklistValueToArray('open_isomer_topic__c', 'Open isomer topic?', header.open_isomer_topic__c)"
                [(ngModel)]="header.open_isomer_topic__c">
                <mat-option value="">All</mat-option>
                <mat-option
                  *ngFor="let additionalField of filterAdditionalFieldValues(receivedAdditionalFieldValues, 'open_isomer_topic__c')"
                  [value]="additionalField.name">
                  {{additionalField.label}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="singleFieldContainer" id='Open ID rate topic?'>
            <mat-form-field class="angularSearchWrapper">
              <mat-label>Open ID rate topic?</mat-label>
              <mat-select
                (selectionChange)="picklistValueToArray('open_id_rate_topic__c', 'Open ID rate topic?', header.open_id_rate_topic__c)"
                [(ngModel)]="header.open_id_rate_topic__c">
                <mat-option value="">All</mat-option>
                <mat-option
                  *ngFor="let additionalField of filterAdditionalFieldValues(receivedAdditionalFieldValues, 'open_id_rate_topic__c')"
                  [value]="additionalField.name">
                  {{additionalField.label}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="singleFieldContainer" id='Adequate Study?'>
            <mat-form-field class="angularSearchWrapper">
              <mat-label>Adequate Study?</mat-label>
              <mat-select
                (selectionChange)="picklistValueToArray('adequate_study__c', 'Adequate Study?', header.adequate_study__c)"
                [(ngModel)]="header.adequate_study__c">
                <mat-option value="">All</mat-option>
                <mat-option
                  *ngFor="let additionalField of filterAdditionalFieldValues(receivedAdditionalFieldValues, 'adequate_study__c')"
                  [value]="additionalField.name">
                  {{additionalField.label}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="singleFieldContainer" id='Residue uptake (≥0.01 mg/kg)?'>
            <mat-form-field class="angularSearchWrapper">
              <mat-label>Residue uptake (≥0.01 mg/kg)?</mat-label>
              <mat-select
                (selectionChange)="picklistValueToArray('residue_uptake_001_mgkg__c', 'Residue uptake (≥0.01 mg/kg)?', header.residue_uptake_001_mgkg__c)"
                [(ngModel)]="header.residue_uptake_001_mgkg__c">
                <mat-option value="">All</mat-option>
                <mat-option
                  *ngFor="let additionalField of filterAdditionalFieldValues(receivedAdditionalFieldValues, 'residue_uptake_001_mgkg__c')"
                  [value]="additionalField.name">
                  {{additionalField.label}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="singleFieldContainer" id='Plateau reached in milk/eggs?'>
            <mat-form-field class="angularSearchWrapper">
              <mat-label>Plateau reached in milk/eggs?</mat-label>
              <mat-select
                (selectionChange)="picklistValueToArray('plateau_reached_in_milkeggs__c', 'Plateau reached in milk/eggs?', header.plateau_reached_in_milkeggs__c)"
                [(ngModel)]="header.plateau_reached_in_milkeggs__c">
                <mat-option value="">All</mat-option>
                <mat-option
                  *ngFor="let additionalField of filterAdditionalFieldValues(receivedAdditionalFieldValues, 'plateau_reached_in_milkeggs__c')"
                  [value]="additionalField.name">
                  {{additionalField.label}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="singleFieldContainer" id='Parent only?'>
            <mat-form-field class="angularSearchWrapper">
              <mat-label>Parent only?</mat-label>
              <mat-select
                (selectionChange)="picklistValueToArray('parent_only__c', 'Parent only?', header.parent_only__c)"
                [(ngModel)]="header.parent_only__c">
                <mat-option value="">All</mat-option>
                <mat-option
                  *ngFor="let additionalField of filterAdditionalFieldValues(receivedAdditionalFieldValues, 'parent_only__c')"
                  [value]="additionalField.name">
                  {{additionalField.label}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="singleFieldContainer" id='Identified metabolites'>
            <mat-form-field class="angularSearchWrapper">
              <mat-label>Identified metabolites</mat-label>
              <mat-select
                (selectionChange)="picklistValueToArray('identified_metabolites__c', 'Identified metabolites', header.identified_metabolites__c)"
                [(ngModel)]="header.identified_metabolites__c">
                <mat-option value="">All</mat-option>
                <mat-option
                  *ngFor="let relatedSubstance of relatedSubstances"
                  [value]="relatedSubstance.name">{{relatedSubstance.name}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="singleFieldContainer" id='Similar to other crop groups?'>
            <mat-form-field class="angularSearchWrapper">
              <mat-label>Similar to other crop groups?</mat-label>
              <mat-select
                (selectionChange)="picklistValueToArray('similar_to_other_crop_groups__c', 'Similar to other crop groups?', header.similar_to_other_crop_groups__c)"
                [(ngModel)]="header.similar_to_other_crop_groups__c">
                <mat-option value="">All</mat-option>
                <mat-option
                  *ngFor="let additionalField of filterAdditionalFieldValues(receivedAdditionalFieldValues, 'similar_to_other_crop_groups__c')"
                  [value]="additionalField.name">
                  {{additionalField.label}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="singleFieldContainer" id='Similar to primary crops?'>
            <mat-form-field class="angularSearchWrapper">
              <mat-label>Similar to primary crops?</mat-label>
              <mat-select
                (selectionChange)="picklistValueToArray('similar_to_primary_crops__c', 'Similar to primary crops?', header.similar_to_primary_crops__c)"
                [(ngModel)]="header.similar_to_primary_crops__c">
                <mat-option value="">All</mat-option>
                <mat-option
                  *ngFor="let additionalField of filterAdditionalFieldValues(receivedAdditionalFieldValues, 'similar_to_primary_crops__c')"
                  [value]="additionalField.name">
                  {{additionalField.label}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="singleFieldContainer" id='Fat soluble residues?'>
            <mat-form-field class="angularSearchWrapper">
              <mat-label>Fat soluble residues?</mat-label>
              <mat-select
                (selectionChange)="picklistValueToArray('fat_soluble_residues__c', 'Fat soluble residues?', header.fat_soluble_residues__c)"
                [(ngModel)]="header.fat_soluble_residues__c">
                <mat-option value="">All</mat-option>
                <mat-option
                  *ngFor="let additionalField of filterAdditionalFieldValues(receivedAdditionalFieldValues, 'fat_soluble_residues__c')"
                  [value]="additionalField.name">
                  {{additionalField.label}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="singleFieldContainer" id='Similar to other species?'>
            <mat-form-field class="angularSearchWrapper">
              <mat-label>Similar to other species?</mat-label>
              <mat-select
                (selectionChange)="picklistValueToArray('similar_to_other_species__c', 'Similar to other species?', header.similar_to_other_species__c)"
                [(ngModel)]="header.similar_to_other_species__c">
                <mat-option value="">All</mat-option>
                <mat-option
                  *ngFor="let additionalField of filterAdditionalFieldValues(receivedAdditionalFieldValues, 'similar_to_other_species__c')"
                  [value]="additionalField.name">
                  {{additionalField.label}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="singleFieldContainer" id='Similar to rat?'>
            <mat-form-field class="angularSearchWrapper">
              <mat-label>Similar to rat?</mat-label>
              <mat-select
                (selectionChange)="picklistValueToArray('similar_to_rat__c', 'Similar to rat?', header.similar_to_rat__c)"
                [(ngModel)]="header.similar_to_rat__c">
                <mat-option value="">All</mat-option>
                <mat-option
                  *ngFor="let additionalField of filterAdditionalFieldValues(receivedAdditionalFieldValues, 'similar_to_rat__c')"
                  [value]="additionalField.name">
                  {{additionalField.label}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="singleFieldContainer" id='Similar to metabolism study?'>
            <mat-form-field class="angularSearchWrapper">
              <mat-label>Similar to metabolism study?</mat-label>
              <mat-select
                (selectionChange)="picklistValueToArray('similar_to_metabolism_study__c', 'Similar to metabolism study?', header.similar_to_metabolism_study__c)"
                [(ngModel)]="header.similar_to_metabolism_study__c">
                <mat-option value="">All</mat-option>
                <mat-option
                  *ngFor="let additionalField of filterAdditionalFieldValues(receivedAdditionalFieldValues, 'similar_to_metabolism_study__c')"
                  [value]="additionalField.name">
                  {{additionalField.label}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="singleFieldContainer" id='Human Unique or disproportional metabolite?'>
            <mat-form-field class="angularSearchWrapper">
              <mat-label>Human Unique or disproportional metabolite?</mat-label>
              <mat-select
                (selectionChange)="picklistValueToArray('human_unique_or_disproportional_metaboli__c', 'Human Unique or disproportional metabolite?', header.human_unique_or_disproportional_metaboli__c)"
                [(ngModel)]="header.human_unique_or_disproportional_metaboli__c">
                <mat-option value="">All</mat-option>
                <mat-option
                  *ngFor="let additionalField of filterAdditionalFieldValues(receivedAdditionalFieldValues, 'human_unique_or_disproportional_metaboli__c')"
                  [value]="additionalField.name">
                  {{additionalField.label}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="singleFieldContainer" id='(maximum) Abundancy in Food/Edibles [TRR]'>
            <mat-form-field class="angularSearchWrapper">
              <mat-label>(maximum) Abundancy in Food/Edibles [TRR]</mat-label>
              <mat-select
                (selectionChange)="picklistValueToArray('maximum_abundancy_in_foodedibles_trr__c', '(maximum) Abundancy in Food/Edibles [TRR]', header.maximum_abundancy_in_foodedibles_trr__c)"
                [(ngModel)]="header.maximum_abundancy_in_foodedibles_trr__c">
                <mat-option value="">All</mat-option>
                <mat-option
                  *ngFor="let additionalField of filterAdditionalFieldValues(receivedAdditionalFieldValues, 'maximum_abundancy_in_foodedibles_trr__c')"
                  [value]="additionalField.name">
                  {{additionalField.label}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="singleFieldContainer" id='(maximum) Abundancy in Food/Edibles [mg/kg]'>
            <mat-form-field class="angularSearchWrapper">
              <mat-label>(maximum) Abundancy in Food/Edibles [mg/kg]</mat-label>
              <mat-select
                (selectionChange)="picklistValueToArray('maximum_abundancy_in_foodedibles_mg__c', '(maximum) Abundancy in Food/Edibles [mg/kg]', header.maximum_abundancy_in_foodedibles_mg__c)"
                [(ngModel)]="header.maximum_abundancy_in_foodedibles_mg__c">
                <mat-option value="">All</mat-option>
                <mat-option
                  *ngFor="let additionalField of filterAdditionalFieldValues(receivedAdditionalFieldValues, 'maximum_abundancy_in_foodedibles_mg__c')"
                  [value]="additionalField.name">
                  {{additionalField.label}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="singleFieldContainer" id='(maximum) Abundancy in Feed [TRR]'>
            <mat-form-field class="angularSearchWrapper">
              <mat-label>(maximum) Abundancy in Feed [TRR]</mat-label>
              <mat-select
                (selectionChange)="picklistValueToArray('maximum_abundancy_in_feed_trr__c', '(maximum) Abundancy in Feed [TRR]', header.maximum_abundancy_in_feed_trr__c)"
                [(ngModel)]="header.maximum_abundancy_in_feed_trr__c">
                <mat-option value="">All</mat-option>
                <mat-option
                  *ngFor="let additionalField of filterAdditionalFieldValues(receivedAdditionalFieldValues, 'maximum_abundancy_in_feed_trr__c')"
                  [value]="additionalField.name">
                  {{additionalField.label}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="singleFieldContainer" id='(maximum) Abundancy in Feed [mg/kg]'>
            <mat-form-field class="angularSearchWrapper">
              <mat-label>(maximum) Abundancy in Feed [mg/kg]</mat-label>
              <mat-select
                (selectionChange)="picklistValueToArray('maximum_abundancy_in_feed_mgkg__c', '(maximum) Abundancy in Feed [mg/kg]', header.maximum_abundancy_in_feed_mgkg__c)"
                [(ngModel)]="header.maximum_abundancy_in_feed_mgkg__c">
                <mat-option value="">All</mat-option>
                <mat-option
                  *ngFor="let additionalField of filterAdditionalFieldValues(receivedAdditionalFieldValues, 'maximum_abundancy_in_feed_mgkg__c')"
                  [value]="additionalField.name">
                  {{additionalField.label}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="singleFieldContainer" id='(maximum) Abundancy in Inedibles?'>
            <mat-form-field class="angularSearchWrapper">
              <mat-label>(maximum) Abundancy in Inedibles?</mat-label>
              <mat-select
                (selectionChange)="picklistValueToArray('maximum_abundancy_in_inedibles__c', '(maximum) Abundancy in Inedibles?', header.maximum_abundancy_in_inedibles__c)"
                [(ngModel)]="header.maximum_abundancy_in_inedibles__c">
                <mat-option value="">All</mat-option>
                <mat-option
                  *ngFor="let additionalField of filterAdditionalFieldValues(receivedAdditionalFieldValues, 'maximum_abundancy_in_inedibles__c')"
                  [value]="additionalField.name">
                  {{additionalField.label}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="singleFieldContainer" id='Fat soluble?'>
            <mat-form-field class="angularSearchWrapper">
              <mat-label>Fat soluble?</mat-label>
              <mat-select
                (selectionChange)="picklistValueToArray('fat_soluble__c', 'Fat soluble?', header.fat_soluble__c)"
                [(ngModel)]="header.fat_soluble__c">
                <mat-option value="">All</mat-option>
                <mat-option
                  *ngFor="let additionalField of filterAdditionalFieldValues(receivedAdditionalFieldValues, 'fat_soluble__c')"
                  [value]="additionalField.name">
                  {{additionalField.label}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="singleFieldContainer" id='Tox Species Coverage (≥10% administred dose)?'>
            <mat-form-field class="angularSearchWrapper">
              <mat-label>Tox Species Coverage (≥10% administred dose)?</mat-label>
              <mat-select
                (selectionChange)="picklistValueToArray('tox_species_coverage_10_administred_d__c', 'Tox Species Coverage (≥10% administred dose)?', header.tox_species_coverage_10_administred_d__c)"
                [(ngModel)]="header.tox_species_coverage_10_administred_d__c">
                <mat-option value="">All</mat-option>
                <mat-option
                  *ngFor="let additionalField of filterAdditionalFieldValues(receivedAdditionalFieldValues, 'tox_species_coverage_10_administred_d__c')"
                  [value]="additionalField.name">
                  {{additionalField.label}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="singleFieldContainer" id='Matrix (tox species)'>
            <mat-form-field class="angularSearchWrapper">
              <mat-label>Matrix (tox species)</mat-label>
              <mat-select
                (selectionChange)="picklistValueToArray('matrix_tox_species__c', 'Matrix (tox species)', header.matrix_tox_species__c)"
                [(ngModel)]="header.matrix_tox_species__c">
                <mat-option value="">All</mat-option>
                <mat-option
                  *ngFor="let additionalField of filterAdditionalFieldValues(receivedAdditionalFieldValues, 'matrix_tox_species__c')"
                  [value]="additionalField.name">
                  {{additionalField.label}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="singleFieldContainer" id='Included in RD?'>
            <mat-form-field class="angularSearchWrapper">
              <mat-label>Included in RD?</mat-label>
              <mat-select
                (selectionChange)="picklistValueToArray('included_in_rd__c', 'Included in RD?', header.included_in_rd__c)"
                [(ngModel)]="header.included_in_rd__c">
                <mat-option value="">All</mat-option>
                <mat-option
                  *ngFor="let additionalField of filterAdditionalFieldValues(receivedAdditionalFieldValues, 'included_in_rd__c')"
                  [value]="additionalField.name">
                  {{additionalField.label}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="singleFieldContainer" id='RD components'>
            <mat-form-field class="angularSearchWrapper">
              <mat-label>RD components</mat-label>
              <mat-select
                (selectionChange)="picklistValueToArray('rd_components__c', 'RD components', header.rd_components__c)"
                [(ngModel)]="header.rd_components__c">
                <mat-option value="">All</mat-option>
                <mat-option
                  *ngFor="let relatedSubstance of relatedSubstances"
                  [value]="relatedSubstance.name">{{relatedSubstance.name}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="singleFieldContainer" id='RD Status'>
            <mat-form-field class="angularSearchWrapper">
              <mat-label>RD Status</mat-label>
              <mat-select (selectionChange)="picklistValueToArray('rd_status__c', 'RD Status', header.rd_status__c)"
                          [(ngModel)]="header.rd_status__c">
                <mat-option value="">All</mat-option>
                <mat-option
                  *ngFor="let additionalField of filterAdditionalFieldValues(receivedAdditionalFieldValues, 'rd_status__c')"
                  [value]="additionalField.name">
                  {{additionalField.label}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="singleFieldContainer" id='Unit (Stability)'>
            <mat-form-field class="angularSearchWrapper">
              <mat-label>Unit (Stability)</mat-label>
              <mat-select
                (selectionChange)="picklistValueToArray('unit_stability__c', 'Unit (Stability)', header.unit_stability__c)"
                [(ngModel)]="header.unit_stability__c">
                <mat-option value="">All</mat-option>
                <mat-option
                  *ngFor="let additionalField of filterAdditionalFieldValues(receivedAdditionalFieldValues, 'unit_stability__c')"
                  [value]="additionalField.name">
                  {{additionalField.label}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="singleFieldContainer" id='Similar to other Commodities of same Category?'>
            <mat-form-field class="angularSearchWrapper">
              <mat-label>Similar to other Commodities of same Category?</mat-label>
              <mat-select
                (selectionChange)="picklistValueToArray('similar_to_other_commodities_of_same_cat__c', 'Similar to other Commodities of same Category?', header.similar_to_other_commodities_of_same_cat__c)"
                [(ngModel)]="header.similar_to_other_commodities_of_same_cat__c">
                <mat-option value="">All</mat-option>
                <mat-option
                  *ngFor="let additionalField of filterAdditionalFieldValues(receivedAdditionalFieldValues, 'similar_to_other_commodities_of_same_cat__c')"
                  [value]="additionalField.name">
                  {{additionalField.label}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="singleFieldContainer" id='Similar to other  Categories?'>
            <mat-form-field class="angularSearchWrapper">
              <mat-label>Similar to other Categories?</mat-label>
              <mat-select
                (selectionChange)="picklistValueToArray('similar_to_other_categories__c', 'Similar to other  Categories?', header.similar_to_other_categories__c)"
                [(ngModel)]="header.similar_to_other_categories__c">
                <mat-option value="">All</mat-option>
                <mat-option
                  *ngFor="let additionalField of filterAdditionalFieldValues(receivedAdditionalFieldValues, 'similar_to_other_categories__c')"
                  [value]="additionalField.name">
                  {{additionalField.label}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="singleFieldContainer" id='Similar to other Animalsof the same Animal Commodities?'>
            <mat-form-field class="angularSearchWrapper">
              <mat-label>Similar to other Animalsof the same Animal Commodities?</mat-label>
              <mat-select
                (selectionChange)="picklistValueToArray('similar_to_other_animalsof_the_same_anim__c', 'Similar to other Animalsof the same Animal Commodities?', header.similar_to_other_animalsof_the_same_anim__c)"
                [(ngModel)]="header.similar_to_other_animalsof_the_same_anim__c">
                <mat-option value="">All</mat-option>
                <mat-option
                  *ngFor="let additionalField of filterAdditionalFieldValues(receivedAdditionalFieldValues, 'similar_to_other_animalsof_the_same_anim__c')"
                  [value]="additionalField.name">
                  {{additionalField.label}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="singleFieldContainer" id='Similar to other Animal Commodities?'>
            <mat-form-field class="angularSearchWrapper">
              <mat-label>Similar to other Animal Commodities?</mat-label>
              <mat-select
                (selectionChange)="picklistValueToArray('similar_to_other_animal_commodities__c', 'Similar to other Animal Commodities?', header.similar_to_other_animal_commodities__c)"
                [(ngModel)]="header.similar_to_other_animal_commodities__c">
                <mat-option value="">All</mat-option>
                <mat-option
                  *ngFor="let additionalField of filterAdditionalFieldValues(receivedAdditionalFieldValues, 'similar_to_other_animal_commodities__c')"
                  [value]="additionalField.name">
                  {{additionalField.label}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="singleFieldContainer" id='Sufficient data package?'>
            <mat-form-field class="angularSearchWrapper">
              <mat-label>Sufficient data package?</mat-label>
              <mat-select
                (selectionChange)="picklistValueToArray('sufficient_data_package__c', 'Sufficient data package?', header.sufficient_data_package__c)"
                [(ngModel)]="header.sufficient_data_package__c">
                <mat-option value="">All</mat-option>
                <mat-option
                  *ngFor="let additionalField of filterAdditionalFieldValues(receivedAdditionalFieldValues, 'sufficient_data_package__c')"
                  [value]="additionalField.name">
                  {{additionalField.label}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="singleFieldContainer" id='Matrix'>
            <mat-form-field class="angularSearchWrapper">
              <mat-label>Matrix</mat-label>
              <mat-select (selectionChange)="picklistValueToArray('matrix__c', 'Matrix', header.matrix__c)"
                          [(ngModel)]="header.matrix__c">
                <mat-option value="">All</mat-option>
                <mat-option
                  *ngFor="let matrix of uniqueMatrix" [value]="matrix.name">
                  {{matrix.label}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="singleFieldContainer" id='Analyte Type'>
            <mat-form-field class="angularSearchWrapper">
              <mat-label>Analyte Type</mat-label>
              <mat-select
                (selectionChange)="picklistValueToArray('analyte_type__c', 'Analyte Type', header.analyte_type__c)"
                [(ngModel)]="header.analyte_type__c">
                <mat-option value="">All</mat-option>
                <mat-option
                  *ngFor="let additionalField of filterAdditionalFieldValues(receivedAdditionalFieldValues, 'analyte_type__c')"
                  [value]="additionalField.name">
                  {{additionalField.label}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="singleFieldContainer" id='Analyte Name'>
            <mat-form-field class="angularSearchWrapper">
              <mat-label>Analyte Name</mat-label>
              <mat-select
                (selectionChange)="picklistValueToArray('analyte_name__c', 'Analyte Name', header.analyte_name__c)"
                [(ngModel)]="header.analyte_name__c">
                <mat-option value="">All</mat-option>
                <mat-option
                  *ngFor="let relatedSubstance of relatedSubstances"
                  [value]="relatedSubstance.name">{{relatedSubstance.name}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="singleFieldContainer" id='Residue (≥0.01 mg/kg)?'>
            <mat-form-field class="angularSearchWrapper">
              <mat-label>Residue (≥0.01 mg/kg)?</mat-label>
              <mat-select
                (selectionChange)="picklistValueToArray('residue_001_mgkg__c', 'Residue (≥0.01 mg/kg)?', header.residue_001_mgkg__c)"
                [(ngModel)]="header.residue_001_mgkg__c">
                <mat-option value="">All</mat-option>
                <mat-option
                  *ngFor="let additionalField of filterAdditionalFieldValues(receivedAdditionalFieldValues, 'residue_001_mgkg__c')"
                  [value]="additionalField.name">
                  {{additionalField.label}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="singleFieldContainer" id='Worst case plateau covered?'>
            <mat-form-field class="angularSearchWrapper">
              <mat-label>Worst case plateau covered?</mat-label>
              <mat-select
                (selectionChange)="picklistValueToArray('worst_case_plateau_covered__c', 'Worst case plateau covered?', header.worst_case_plateau_covered__c)"
                [(ngModel)]="header.worst_case_plateau_covered__c">
                <mat-option value="">All</mat-option>
                <mat-option
                  *ngFor="let additionalField of filterAdditionalFieldValues(receivedAdditionalFieldValues, 'worst_case_plateau_covered__c')"
                  [value]="additionalField.name">
                  {{additionalField.label}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="singleFieldContainer" id='Replant restrictions?'>
            <mat-form-field class="angularSearchWrapper">
              <mat-label>Replant restrictions?</mat-label>
              <mat-select
                (selectionChange)="picklistValueToArray('replant_restrictions__c', 'Replant restrictions?', header.replant_restrictions__c)"
                [(ngModel)]="header.replant_restrictions__c">
                <mat-option value="">All</mat-option>
                <mat-option
                  *ngFor="let additionalField of filterAdditionalFieldValues(receivedAdditionalFieldValues, 'replant_restrictions__c')"
                  [value]="additionalField.name">
                  {{additionalField.label}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="singleFieldContainer" id='Rotational MRLs required?'>
            <mat-form-field class="angularSearchWrapper">
              <mat-label>Rotational MRLs required?</mat-label>
              <mat-select
                (selectionChange)="picklistValueToArray('rotational_mrls_required__c', 'Rotational MRLs required?', header.rotational_mrls_required__c)"
                [(ngModel)]="header.rotational_mrls_required__c">
                <mat-option value="">All</mat-option>
                <mat-option
                  *ngFor="let additionalField of filterAdditionalFieldValues(receivedAdditionalFieldValues, 'rotational_mrls_required__c')"
                  [value]="additionalField.name">
                  {{additionalField.label}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="singleFieldContainer" id='Residues in edible matrices (≥0,01 mg/kg)?'>
            <mat-form-field class="angularSearchWrapper">
              <mat-label>Residues in edible matrices (≥0,01 mg/kg)?</mat-label>
              <mat-select
                (selectionChange)="picklistValueToArray('residues_in_edible_matrices_001_mgkg__c', 'Residues in edible matrices (≥0,01 mg/kg)?', header.residues_in_edible_matrices_001_mgkg__c)"
                [(ngModel)]="header.residues_in_edible_matrices_001_mgkg__c">
                <mat-option value="">All</mat-option>
                <mat-option
                  *ngFor="let additionalField of filterAdditionalFieldValues(receivedAdditionalFieldValues, 'residues_in_edible_matrices_001_mgkg__c')"
                  [value]="additionalField.name">
                  {{additionalField.label}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="singleFieldContainer" id='Sum of analytes ≥ 0.05 mg/kg?'>
            <mat-form-field class="angularSearchWrapper">
              <mat-label>Sum of analytes ≥ 0.05 mg/kg?</mat-label>
              <mat-select
                (selectionChange)="picklistValueToArray('sum_of_analytes_005_mgkg__c', 'Sum of analytes ≥ 0.05 mg/kg?', header.sum_of_analytes_005_mgkg__c)"
                [(ngModel)]="header.sum_of_analytes_005_mgkg__c">
                <mat-option value="">All</mat-option>
                <mat-option
                  *ngFor="let additionalField of filterAdditionalFieldValues(receivedAdditionalFieldValues, 'sum_of_analytes_005_mgkg__c')"
                  [value]="additionalField.name">
                  {{additionalField.label}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="singleFieldContainer" id='Included uses'>
            <mat-form-field class="angularSearchWrapper">
              <mat-label>Included uses</mat-label>
              <mat-select
                (selectionChange)="picklistValueToArray('included_uses__c', 'Included uses', header.included_uses__c)"
                [(ngModel)]="header.included_uses__c">
                <mat-option value="">All</mat-option>
                <mat-option
                  *ngFor="let additionalField of filterAdditionalFieldValues(receivedAdditionalFieldValues, 'included_uses__c')"
                  [value]="additionalField.name">
                  {{additionalField.label}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="singleFieldContainer" id='Refinement Level'>
            <mat-form-field class="angularSearchWrapper">
              <mat-label>Refinement Level</mat-label>
              <mat-select
                (selectionChange)="picklistValueToArray('refinement_level__c', 'Refinement Level', header.refinement_level__c)"
                [(ngModel)]="header.refinement_level__c">
                <mat-option value="">All</mat-option>
                <mat-option
                  *ngFor="let additionalField of filterAdditionalFieldValues(receivedAdditionalFieldValues, 'refinement_level__c')"
                  [value]="additionalField.name">
                  {{additionalField.label}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="singleFieldContainer" id='Risk Assessment Status'>
            <mat-form-field class="angularSearchWrapper">
              <mat-label>Risk Assessment Status</mat-label>
              <mat-select
                (selectionChange)="picklistValueToArray('risk_assessment_status__c', 'Risk Assessment Status', header.risk_assessment_status__c)"
                [(ngModel)]="header.risk_assessment_status__c">
                <mat-option value="">All</mat-option>
                <mat-option
                  *ngFor="let additionalField of filterAdditionalFieldValues(receivedAdditionalFieldValues, 'risk_assessment_status__c')"
                  [value]="additionalField.name">
                  {{additionalField.label}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="singleFieldContainer" id='Unit (Toxicological endpoint utilization)'>
            <mat-form-field class="angularSearchWrapper">
              <mat-label>Unit (Toxicological endpoint utilization)</mat-label>
              <mat-select
                (selectionChange)="picklistValueToArray('unittoxicological_endpoint_utilization__c', 'Unit (Toxicological endpoint utilization)', header.unittoxicological_endpoint_utilization__c)"
                [(ngModel)]="header.unittoxicological_endpoint_utilization__c">
                <mat-option value="">All</mat-option>
                <mat-option
                  *ngFor="let additionalField of filterAdditionalFieldValues(receivedAdditionalFieldValues, 'unittoxicological_endpoint_utilization__c')"
                  [value]="additionalField.name">
                  {{additionalField.label}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="singleFieldContainer" id='Driver Population'>
            <mat-form-field class="angularSearchWrapper">
              <mat-label>Driver Population</mat-label>
              <mat-select
                (selectionChange)="picklistValueToArray('driver_population__c', 'Driver Population', header.driver_population__c)"
                [(ngModel)]="header.driver_population__c">
                <mat-option value="">All</mat-option>
                <mat-option
                  *ngFor="let driverPopulation of uniqueDriverPopulation" [value]="driverPopulation.name">
                  {{driverPopulation.label}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="singleFieldContainer" id='Driver Crop'>
            <mat-form-field class="angularSearchWrapper">
              <mat-label>Driver Crop</mat-label>
              <mat-select
                (selectionChange)="picklistValueToArray('driver_crop__c', 'Driver Crop', header.driver_crop__c)"
                [(ngModel)]="header.driver_crop__c">
                <mat-option value="">All</mat-option>
                <mat-option
                  *ngFor="let additionalField of filterAdditionalFieldValues(receivedAdditionalFieldValues, 'driver_crop__c')"
                  [value]="additionalField.name">
                  {{additionalField.label}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="singleFieldContainer" id='RA relevant?'>
            <mat-form-field class="angularSearchWrapper">
              <mat-label>RA relevant?</mat-label>
              <mat-select
                (selectionChange)="picklistValueToArray('ra_relevant__c', 'RA relevant?', header.ra_relevant__c)"
                [(ngModel)]="header.ra_relevant__c">
                <mat-option value="">All</mat-option>
                <mat-option
                  *ngFor="let additionalField of filterAdditionalFieldValues(receivedAdditionalFieldValues, 'ra_relevant__c')"
                  [value]="additionalField.name">
                  {{additionalField.label}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <!--NUMBER FIELDS-->

          <div class="singleFieldContainer" id='Application Rate'>
            <mat-form-field class="angularSearchWrapper">
              <mat-label>Application Rate</mat-label>
              <input (change)="textFieldValueToArray('application_rate1__c', 'Application Rate')"
                     (keyup)="textFieldValueToArray('application_rate1__c', 'Application Rate')"
                     [(ngModel)]="header.application_rate1__c"
                     class="orangeTextFieldInput"
                     id="application_rate1__c"
                     matInput matNativeControl type="number">
            </mat-form-field>
          </div>

          <div class="singleFieldContainer" id='Time needed to reach plateau in milk/eggs (days)'>
            <mat-form-field class="angularSearchWrapper">
              <mat-label>Time needed to reach plateau in milk/eggs (days)</mat-label>
              <input (change)="textFieldValueToArray('time_needed_to_reach_plateau_in_milkegg__c',
              'Time needed to reach plateau in milk/eggs (days)')"
                     (keyup)="textFieldValueToArray('time_needed_to_reach_plateau_in_milkegg__c',
                     'Time needed to reach plateau in milk/eggs (days)')"
                     [(ngModel)]="header.time_needed_to_reach_plateau_in_milkegg__c"
                     class="orangeTextFieldInput"
                     id="time_needed_to_reach_plateau_in_milkegg__c"
                     matInput matNativeControl type="number">
            </mat-form-field>
          </div>

          <div class="singleFieldContainer" id='% administered dose'>
            <mat-form-field class="angularSearchWrapper">
              <mat-label>% administered dose</mat-label>
              <input (change)="textFieldValueToArray('administered_dose__c',
              '% administered dose')"
                     (keyup)="textFieldValueToArray('administered_dose__c',
                     '% administered dose')"
                     [(ngModel)]="header.administered_dose__c"
                     class="orangeTextFieldInput"
                     id="administered_dose__c"
                     matInput matNativeControl type="number">
            </mat-form-field>
          </div>

          <div class="singleFieldContainer" id='Stability'>
            <mat-form-field class="angularSearchWrapper">
              <mat-label>Stability</mat-label>
              <input (change)="textFieldValueToArray('stability__c',
              'Stability')"
                     (keyup)="textFieldValueToArray('stability__c',
                     'Stability')"
                     [(ngModel)]="header.stability__c"
                     class="orangeTextFieldInput"
                     id="stability__c"
                     matInput matNativeControl type="number">
            </mat-form-field>
          </div>

          <div class="singleFieldContainer" id='Replant restriction (days)'>
            <mat-form-field class="angularSearchWrapper">
              <mat-label>Replant restriction (days)</mat-label>
              <input (change)="textFieldValueToArray('replant_restriction_days__c',
              'Replant restriction (days)')"
                     (keyup)="textFieldValueToArray('replant_restriction_days__c',
                     'Replant restriction (days)')"
                     [(ngModel)]="header.replant_restriction_days__c"
                     class="orangeTextFieldInput"
                     id="replant_restriction_days__c"
                     matInput matNativeControl type="number">
            </mat-form-field>
          </div>

          <div class="singleFieldContainer" id='Processing Factor'>
            <mat-form-field class="angularSearchWrapper">
              <mat-label>Processing Factor</mat-label>
              <input (change)="textFieldValueToArray('processing_factor__c',
              'Processing Factor')"
                     (keyup)="textFieldValueToArray('processing_factor__c',
                     'Processing Factor')"
                     [(ngModel)]="header.processing_factor__c"
                     class="orangeTextFieldInput"
                     id="processing_factor__c"
                     matInput matNativeControl type="number">
            </mat-form-field>
          </div>

          <div class="singleFieldContainer" id='Dose Level (mg/kg DM diet)'>
            <mat-form-field class="angularSearchWrapper">
              <mat-label>Dose Level (mg/kg DM diet)</mat-label>
              <input (change)="textFieldValueToArray('dose_level_mgkg_dm_diet__c',
              'Dose Level (mg/kg DM diet)')"
                     (keyup)="textFieldValueToArray('dose_level_mgkg_dm_diet__c',
                     'Dose Level (mg/kg DM diet)')"
                     [(ngModel)]="header.dose_level_mgkg_dm_diet__c"
                     class="orangeTextFieldInput"
                     id="dose_level_mgkg_dm_diet__c"
                     matInput matNativeControl type="number">
            </mat-form-field>
          </div>

          <div class="singleFieldContainer" id='Dose Level (mg/kg bw/day)'>
            <mat-form-field class="angularSearchWrapper">
              <mat-label>Dose Level (mg/kg bw/day)</mat-label>
              <input (change)="textFieldValueToArray('dose_level_mgkg_bwday__c',
              'Dose Level (mg/kg bw/day)')"
                     (keyup)="textFieldValueToArray('dose_level_mgkg_bwday__c',
                     'Dose Level (mg/kg bw/day)')"
                     [(ngModel)]="header.dose_level_mgkg_bwday__c"
                     class="orangeTextFieldInput"
                     id="dose_level_mgkg_bwday__c"
                     matInput matNativeControl type="number">
            </mat-form-field>
          </div>

          <div class="singleFieldContainer" id='Toxicological endpoint utilization'>
            <mat-form-field class="angularSearchWrapper">
              <mat-label>Toxicological endpoint utilization</mat-label>
              <input (change)="textFieldValueToArray('toxicological_endpoint_utilization__c',
              'Toxicological endpoint utilization')"
                     (keyup)="textFieldValueToArray('toxicological_endpoint_utilization__c',
                     'Toxicological endpoint utilization')"
                     [(ngModel)]="header.toxicological_endpoint_utilization__c"
                     class="orangeTextFieldInput"
                     id="toxicological_endpoint_utilization__c"
                     matInput matNativeControl type="number">
            </mat-form-field>
          </div>

        </div>

        <div class="searchRow">
          <div class="singleFieldContainer">
            <mat-form-field class="angularSearchWrapper">
              <mat-label>Order By</mat-label>
              <mat-select (selectionChange)="defineSortOrder()" [(value)]="sortByFieldName">
                <mat-option value="id">Latest</mat-option>
                <mat-option value="category_name__c">Category</mat-option>
                <mat-option value="substance_type__c">Substance Type</mat-option>
                <mat-option value="related_substance__c">Related Substance</mat-option>
                <mat-option value="study_type__c">Study Type</mat-option>
                <mat-option value="endpoint__c">Endpoint</mat-option>
                <mat-option value="document__c">Source</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="singleFieldContainer">
            <button (click)="getEndpointsWithFilters(this.pageSize, 0)" [hidden]="searchButtonHidden"
                    class="searchButton" data-cy="ResetFilters" title="Apply Filters"><span class="material-icons">search</span>
            </button>
            <div [hidden]="loaderHidden" class="loaderHolder">
              <div class="loader"></div>
            </div>
          </div>
        </div>

        <!--the list of the records in Vault-->
        <app-consumer-safety-record-existing (reduceRecordCount)="reduceCount()"
                                             (refreshEndpoints)="getEndpointsWithFilters(this.pageSize, 0);"
                                             *ngFor="let singleRecord of existingEndpoints; index as i;"
                                             [singleRecordDependencies]="singleRecordDependencies"
                                             [allAdditionalFieldValues]="receivedAdditionalFieldValues"
                                             [singleRecordDependenciesModelAdditionalFieldValues]="singleRecordDependenciesModelAdditionalFieldValues"
                                             [allAdditionalFields]="receivedAllAdditionalFields"
                                             [allStudyTypes]="receivedAllStudyTypes"
                                             [allTestSystemNames]="receivedAllTestSystemNames"
                                             [allTestSystemTypes]="receivedAllTestSystemTypes"
                                             [allUsers]="allUsers"
                                             [endpointRecord]="singleRecord"
                                             [categories]="receivedCategories"
                                             [categoryPicklists]="receivedCategoryPicklists"
                                             [dataSourceLength]="dataSourceLength"
                                             [documents]="documents"
                                             [relatedSubstances]="relatedSubstances"
                                             [identifiedMetabolites]="identifiedMetabolites"
                                             [analyteNames]="analyteNames"
                                             [substanceAsAnalyteNames]="substanceAsAnalyteNames"
                                             [substanceTypes]="substanceTypes"
                                             [subtractNumber]="i"
                                             class="recordsContainer"
        ></app-consumer-safety-record-existing>


        <mat-paginator (page)="pageEvent = $event; onPaginateChange($event)"
                       [length]="dataSourceLength"
                       [pageSizeOptions]="[5, 10, 25, 100]"
                       [pageSize]="pageSize"
                       class="paginator"
                       style="float: left;">
        </mat-paginator>

      </ng-container>

    </mat-expansion-panel>
  </mat-accordion>

</div>
