export class TimelineItem {
  group: number;
  start: Date;
  content: string;
  title: string;

  constructor(group: number, start: Date, content: string, title: string) {
    this.group = group;
    this.start = start;
    this.content = content;
    this.title = title;
  }
}
