import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AbstractControl, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ToastrService} from 'ngx-toastr';
import {ExceptionService} from 'src/app/core/exception.service';
import {ActivatedRoute} from '@angular/router';
import {ServerService} from 'src/app/core/server.service';
import {PicklistValue} from 'src/app/endpoint/models/picklist-value';
import {ToxAdditionalField} from '../../../models/tox-additional-field';
import {SubstanceItem} from '../../../models/substance-item';
import {DocumentMenuItem} from '../../../models/document-menu-item';
import {ToxEndpointTableItem} from '../../../models/tox-endpoint-table-item';

let me: MetabolitesImpuritiesComponent;

@Component({
  selector: 'app-metabolites-impurities',
  templateUrl: './metabolites-impurities.component.html',
  styleUrls: ['../../../record_style.css']
})
export class MetabolitesImpuritiesComponent implements OnInit {
  @Input() singleEndpointRecord: ToxEndpointTableItem;
  @Input() category: PicklistValue;
  @Input() allStudyTypes: Array<PicklistValue>;
  @Input() allTestSystemNames: Array<PicklistValue>;
  @Input() allRouteOfAdministration: Array<PicklistValue>;
  @Input() allEndpoints: Array<PicklistValue>;
  @Input() allEndpointUnits: Array<PicklistValue>;
  @Input() allOperators: Array<PicklistValue>;
  @Input() allAdditionalFields: Array<ToxAdditionalField>;
  @Input() allAdditionalFieldValues: Array<PicklistValue>;
  @Input() substance: SubstanceItem;
  @Input() substanceTypes: Array<PicklistValue>;
  @Input() relatedSubstances: any;
  @Input() documents: Array<DocumentMenuItem>;
  @Input() purpose: string;

  @Output() removeInput: EventEmitter<ToxEndpointTableItem> = new EventEmitter();
  @Output() copyInput: EventEmitter<ToxEndpointTableItem> = new EventEmitter();
  @Output() cancelEdit: EventEmitter<ToxEndpointTableItem> = new EventEmitter();
  @Output() saveChanges: EventEmitter<ToxEndpointTableItem> = new EventEmitter();

  subscriptions: any;
  selectFormGroup: FormGroup;
  configureFormGroup: FormGroup;
  public categoryAdditionalFields: Array<ToxAdditionalField>;
  public addFields: any;
  public additionalFields: Array<ToxAdditionalField>;
  public additionalFieldValues: Map<string, any>;
  public studyTypes: Array<PicklistValue>;
  public testSystemNames: Array<PicklistValue>;
  public endpoints: Array<PicklistValue>;
  public endpointUnits: Array<PicklistValue>;
  public operators: Array<PicklistValue>;
  public values3: Array<any>;
  public filteredRelatedSubstances: any;
  public isValue1Disabled: boolean;
  public isValue2Disabled: boolean;
  public isValue3Disabled: boolean;
  public studyTypeColor: string;
  public routeOfAdministrationColor: string;
  private sessionId: string;
  public showCopyOptions: boolean;
  public showEditOptions: boolean;
  public errorOnValue2: Boolean;

  constructor(
    private route: ActivatedRoute,
    private exceptionService: ExceptionService,
    private serverService: ServerService,
    private toastrService: ToastrService,
    private formBuilder: FormBuilder
  ) {
    me = this;
    this.subscriptions = {};
    this.route.paramMap.subscribe((params) => {
      this.sessionId = params.get('sessionId');
    });
    this.selectFormGroup = this.formBuilder.group({
      substanceType: [],
      relatedSubstance: [{value: '', disabled: true}],
      studyType: [{value: '', disabled: false}, Validators.required],
      route_of_administration__c: [{value: '', disabled: false}],
      test_system_name__c: [{value: '', disabled: false}],
      endpoint: [{value: '', disabled: false}, Validators.required],
      endpointUnit: [{value: '', disabled: false}, Validators.required],
      operator: ['', Validators.required],
      value1: [{value: '', disabled: false}],
      value2: [{value: '', disabled: false}],
      value3: [{value: '', disabled: false}],
      // additional fields
      test_method_skin_sensitization__c: [{value: '', disabled: false}],
      ec3__c: [{value: '', disabled: false}],
      ec15__c: [{value: '', disabled: false}],
      target_organ__c: [{value: '', disabled: false}],
      noael_carcinogenicity_mgkg_bwd__c: [{value: '', disabled: false}],
      loael_carcinogenicity_mgkg_bwd__c: [{value: '', disabled: false}],
      tumor_site_male__c: [{value: '', disabled: false}],
      tumor_site_female__c: [{value: '', disabled: false}],
      noael_fertility_mgkg_bwd__c: [{value: '', disabled: false}],
      loael_fertility_mgkg_bwd__c: [{value: '', disabled: false}],
      noael_offspring_mgkg_bwd__c: [{value: '', disabled: false}],
      loael_offspring_mgkg_bwd__c: [{value: '', disabled: false}],
      noael_maternal_mgkg_bwd__c: [{value: '', disabled: false}],
      loael_maternal_mgkg_bwd__c: [{value: '', disabled: false}],
      noael_developmental_mgkg_bwd__c: [{value: '', disabled: false}],
      loael_developmental_mgkg_bwd__c: [{value: '', disabled: false}],
      eats_and_developmental_parameters_affect__c: [{value: '', disabled: false}],
      ed_mechanistic_study__c: [{value: '', disabled: false}],
      // default
      selectedDocument: [{value: '', disabled: false}],
    });
    this.testSystemNames = [];
    this.addFields = [];
    this.categoryAdditionalFields = [];
    this.additionalFields = [];
    this.additionalFieldValues = new Map();
    this.studyTypeColor = '';
    this.routeOfAdministrationColor = '';
    this.filteredRelatedSubstances = [];
    this.isValue1Disabled = true;
    this.isValue2Disabled = true;
    this.isValue3Disabled = true;
    this.operators = [];
    this.values3 = ['yes', 'no', 'vulnerable'];
    this.errorOnValue2 = false;
  }

  get relatedSubstanceControl(): AbstractControl {
    return this.selectFormGroup.get('relatedSubstance');
  }
  get endpointControl(): AbstractControl {
    return this.selectFormGroup.get('endpoint');
  }
  get value1Control(): AbstractControl {
    return this.selectFormGroup.get('value1');
  }
  get value2Control(): AbstractControl {
    return this.selectFormGroup.get('value2');
  }
  get value3Control(): AbstractControl {
    return this.selectFormGroup.get('value3');
  }
  get sourceControl(): AbstractControl {
    return this.selectFormGroup.get('selectedDocument');
  }

  ngOnInit(): void {

    if (this.purpose === 'editOnExisting') {
      this.showCopyOptions = false;
      this.showEditOptions = true;
    }
    else {
      this.showCopyOptions = true;
      this.showEditOptions = false;
    }

    this.sourceControl.setValue(this.singleEndpointRecord.document_unbound__c);
    this.studyTypes = this.allStudyTypes;
    this.testSystemNames = this.allTestSystemNames;

    const filteredOperators = [
      'a1595852199787__c',
      'a1595852189563__c',
      'a1595852194618__c',
      'a1595852268945__c',
      'a1595852266201__c',
      'between__c',
      'a1595852359285__c',
      'text__c'
    ];

    filteredOperators.forEach(operator => {
        const singleOperator = this.allOperators.filter(
          (entry) => entry.name === operator
        );
        this.operators = this.operators.concat(singleOperator);
      }
    );

    // prepare additional fields
    const preAllAdditionalFields = this.allAdditionalFields.filter(
      (entry) =>
        entry.group === 'study_type_metabolitesimpurities__c'
    );
    this.categoryAdditionalFields = preAllAdditionalFields.sort((a, b) => a.sortNumber - b.sortNumber);

    if (this.singleEndpointRecord.study_type_picklist_name__c) {
      // COPY record
      switch (this.singleEndpointRecord.operator__c) {
        case 'between__c':
          this.isValue1Disabled = false;
          this.isValue2Disabled = false;
          this.isValue3Disabled = true;
          this.selectFormGroup.get('value1').enable();
          this.selectFormGroup.get('value2').enable();
          this.selectFormGroup.get('value3').disable();
          this.singleEndpointRecord.value_3__c = '';
          this.value3Control.setValue('');
          break;
        case 'text__c':
          this.isValue1Disabled = true;
          this.isValue2Disabled = true;
          this.isValue3Disabled = false;
          this.selectFormGroup.get('value1').disable();
          this.selectFormGroup.get('value2').disable();
          this.selectFormGroup.get('value3').enable();
          this.value1Control.setValue(null);
          this.value2Control.setValue(null);
          this.singleEndpointRecord.value_1__c = null;
          this.singleEndpointRecord.value_2__c = null;
          break;
        case '':
          this.isValue1Disabled = true;
          this.isValue2Disabled = true;
          this.isValue3Disabled = true;
          this.selectFormGroup.get('value1').disable();
          this.selectFormGroup.get('value2').disable();
          this.selectFormGroup.get('value3').disable();
          this.value1Control.setValue(null);
          this.value2Control.setValue(null);
          this.value3Control.setValue('');
          this.singleEndpointRecord.value_1__c = null;
          this.singleEndpointRecord.value_2__c = null;
          this.singleEndpointRecord.value_3__c = '';
          break;
        default:
          this.isValue1Disabled = false;
          this.isValue2Disabled = true;
          this.isValue3Disabled = true;
          this.selectFormGroup.get('value1').enable();
          this.selectFormGroup.get('value2').disable();
          this.selectFormGroup.get('value3').disable();
          this.value2Control.setValue(null);
          this.value3Control.setValue('');
          this.singleEndpointRecord.value_2__c = null;
          this.singleEndpointRecord.value_3__c = '';
          break;
      }

      // parent (or none) cannot have Related Substance
      if (this.singleEndpointRecord.substance_type__c === 'parent__c' || this.singleEndpointRecord.substance_type__c === '') {
        this.selectFormGroup.get('relatedSubstance').disable();
      } else {
        // filter Related Substances by the selected Substance Type
        this.filteredRelatedSubstances = this.relatedSubstances.filter(
          (f) => f.substance_type__c[0] === this.singleEndpointRecord.substance_type__c
        );
        this.selectFormGroup.get('relatedSubstance').enable();
      }

      // NEUTRAL
      if (this.singleEndpointRecord.study_type__c === 'acute_oral__c' ||
        this.singleEndpointRecord.study_type__c === 'acute_dermal__c' ||
        this.singleEndpointRecord.study_type__c === 'acute_inhalation__c' ||
        this.singleEndpointRecord.study_type__c === 'skin_irritation__c' ||
        this.singleEndpointRecord.study_type__c === 'eye_irritation__c' ||
        this.singleEndpointRecord.study_type__c === 'genotoxicity_in_vitro__c' ||
        this.singleEndpointRecord.study_type__c === 'genotoxicity_in_vivo__c'
      ) {
        this.additionalFields = [];
        this.studyTypeColor = '';
      }

      // ORANGE
      else if (this.singleEndpointRecord.study_type__c === 'skin_sensitization__c') {
        this.addFields = [
          'test_method_skin_sensitization__c',
          'ec3__c',
          'ec15__c'
        ];
        this.studyTypeColor = 'orange';
      }
      // BLUE
      else if (this.singleEndpointRecord.study_type__c === '28day__c' ||
        this.singleEndpointRecord.study_type__c === '90day__c' ||
        this.singleEndpointRecord.study_type__c === '18month__c' ||
        this.singleEndpointRecord.study_type__c === '2year__c' ||
        this.singleEndpointRecord.study_type__c === 'mechanistic_study__c'
      )
      {
        this.addFields = [
          'target_organ__c',
          'noael_carcinogenicity_mgkg_bwd__c',
          'loael_carcinogenicity_mgkg_bwd__c',
          'tumor_site_male__c',
          'tumor_site_female__c'
        ];
        this.studyTypeColor = 'blue';
      }
      // GREEN
      else if (this.singleEndpointRecord.study_type__c === 'reproductive_toxicity_study__c') {
        this.addFields = [
            'noael_fertility_mgkg_bwd__c',
            'loael_fertility_mgkg_bwd__c',
            'noael_offspring_mgkg_bwd__c',
            'loael_offspring_mgkg_bwd__c',
            'noael_maternal_mgkg_bwd__c',
            'loael_maternal_mgkg_bwd__c',
            'noael_developmental_mgkg_bwd__c',
            'loael_developmental_mgkg_bwd__c',
            'target_organ__c',
            'eats_and_developmental_parameters_affect__c'
        ];
        this.studyTypeColor = 'green';
      }
      // PURPLE
      else if (this.singleEndpointRecord.study_type__c === 'mechanistic_study_for_ed__c') {
        this.studyTypeColor = 'purple';
        this.addFields = ['ed_mechanistic_study__c'];
      }

      this.addFields.forEach(field => {
        const singleField = this.categoryAdditionalFields.filter(
          (entry) => entry.name === field
        );
        this.additionalFields = this.additionalFields.concat(singleField);
      }
      );
      this.additionalFields = this.additionalFields.sort((a, b) => a.sortNumber - b.sortNumber);

      this.filterEndpointsAndEndpointUnits('copyOrEdit');
      this.setFormFields();
    }
    // end if copy record
    else {
      this.selectFormGroup.get('substanceType').setValue('parent__c');
      this.selectFormGroup.get('endpoint').disable();
      this.selectFormGroup.get('endpointUnit').disable();
    }
  }

  getSelectedOptionPicklist(event: any): string {
    return event.target.options[event.target.options.selectedIndex].title;
  }

  onSelectSubstanceType(event: any): any {
    // update object
    this.singleEndpointRecord.substance_type__c = event.target.value;

    // clear Related Substance value because the group of Related Substances has changed
    this.singleEndpointRecord.related_substance__c = '';
    // also clear Related Substance form field
    this.relatedSubstanceControl.setValue('');

    // parent (or none) cannot have Related Substance
    if (event.target.value === 'parent__c' || event.target.value === '') {
      this.selectFormGroup.get('relatedSubstance').disable();
    } else {
      // filter Related Substances by the selected Substance Type
      this.filteredRelatedSubstances = this.relatedSubstances.filter(
        (f) => f.substance_type__c[0] === event.target.value
      );
      this.selectFormGroup.get('relatedSubstance').enable();
    }
  }

  onSelectStudyType(event: any): any {
    this.singleEndpointRecord.study_type__c = event.target.value;

    if (event.target.value !== '') {
      this.singleEndpointRecord.study_type_picklist_name__c = this.getSelectedOptionPicklist(
        event
      );
      this.filterGroupFields(event.target.value);
    }
    else {
      // Study Type cleared
      this.singleEndpointRecord.study_type_picklist_name__c = '';
      this.additionalFields = [];
      this.clearAdditionalFields();
      this.studyTypeColor = '';
    }
  }

  filterGroupFields(studyTypeValue): any {
    // NEUTRAL
    if (studyTypeValue === 'acute_oral__c' ||
      studyTypeValue === 'acute_dermal__c' ||
      studyTypeValue === 'acute_inhalation__c' ||
      studyTypeValue === 'skin_irritation__c' ||
      studyTypeValue === 'eye_irritation__c' ||
      studyTypeValue === 'genotoxicity_in_vitro__c' ||
      studyTypeValue === 'genotoxicity_in_vivo__c'
    ) {
      this.additionalFields = [];
      this.clearAdditionalFields();
      this.studyTypeColor = '';
    }

    // ORANGE
    else if (studyTypeValue === 'skin_sensitization__c') {
      if (this.studyTypeColor !== 'orange') {
        // applying only if changing group
        this.clearAdditionalFields();
        const addFields = [
          'test_method_skin_sensitization__c',
          'ec3__c',
          'ec15__c'
        ];
        this.addSpecifiedFields(addFields);
        this.studyTypeColor = 'orange';
      }
    }

    // BLUE
    else if (studyTypeValue === '28day__c' ||
      studyTypeValue === '90day__c' ||
      studyTypeValue === '18month__c' ||
      studyTypeValue === '2year__c' ||
      studyTypeValue === 'mechanistic_study__c'
    ) {
      if (this.studyTypeColor !== 'blue') {
        // applying only if changing group
        this.clearAdditionalFields();
        const addFields = [
          'target_organ__c',
          'noael_carcinogenicity_mgkg_bwd__c',
          'loael_carcinogenicity_mgkg_bwd__c',
          'tumor_site_male__c',
          'tumor_site_female__c'
        ];
        this.addSpecifiedFields(addFields);
        this.studyTypeColor = 'blue';
      }
    }

    // GREEN
    else if (studyTypeValue === 'reproductive_toxicity_study__c') {
      if (this.studyTypeColor !== 'green') {
        // applying only if changing group
        this.clearAdditionalFields();
        const addFields = [
          'noael_fertility_mgkg_bwd__c',
          'loael_fertility_mgkg_bwd__c',
          'noael_offspring_mgkg_bwd__c',
          'loael_offspring_mgkg_bwd__c',
          'noael_maternal_mgkg_bwd__c',
          'loael_maternal_mgkg_bwd__c',
          'noael_developmental_mgkg_bwd__c',
          'loael_developmental_mgkg_bwd__c',
          'target_organ__c',
          'eats_and_developmental_parameters_affect__c'
        ];
        this.addSpecifiedFields(addFields);
        this.studyTypeColor = 'green';
      }
    }

    // PURPLE
    else if (studyTypeValue === 'mechanistic_study_for_ed__c') {
      if (this.studyTypeColor !== 'purple') {
        // applying only if changing group
        this.clearAdditionalFields();
        const addFields = ['ed_mechanistic_study__c'];
        this.addSpecifiedFields(addFields);
        this.studyTypeColor = 'purple';
      }
    }
  }

  addSpecifiedFields(arrayOfFieldNames): any {
    this.additionalFields = [];
    arrayOfFieldNames.forEach(field => {
        const singleField = this.categoryAdditionalFields.filter(
          (entry) => entry.name === field
        );
        this.additionalFields = this.additionalFields.concat(singleField);
      }
    );
    this.additionalFields = this.additionalFields.sort((a, b) => a.sortNumber - b.sortNumber);
  }

  updateFieldValue(newValue): any {
    const fieldName = newValue.title;
    this.singleEndpointRecord[fieldName] = newValue.value;

    if (fieldName === 'route_of_administration__c') {
      this.filterEndpointsAndEndpointUnits('manualUpdate' );
    }
  }

  filterEndpointsAndEndpointUnits(calledFrom): any {
    if (this.singleEndpointRecord.route_of_administration__c === ''
    ) {
        this.singleEndpointRecord.endpoint__c = '';
        this.singleEndpointRecord.endpoint_unit__c = '';
        this.selectFormGroup.get('endpoint').setValue('');
        this.selectFormGroup.get('endpointUnit').setValue('');
        this.selectFormGroup.get('endpoint').disable();
        this.selectFormGroup.get('endpointUnit').disable();
        this.routeOfAdministrationColor = '';
    }
    else if (this.singleEndpointRecord.route_of_administration__c === 'oral_gavage__c' ||
      this.singleEndpointRecord.route_of_administration__c === 'oral_diet__c' ||
      this.singleEndpointRecord.route_of_administration__c === 'oral_drinking_water__c' ||
      this.singleEndpointRecord.route_of_administration__c === 'dermal__c'
    ) {
        if (calledFrom === 'manualUpdate' && this.routeOfAdministrationColor !== 'white') {
          // changing group - reset
          this.singleEndpointRecord.endpoint__c = '';
          this.singleEndpointRecord.endpoint_unit__c = '';
          this.selectFormGroup.get('endpoint').setValue('');
          this.selectFormGroup.get('endpointUnit').setValue('');
          this.selectFormGroup.get('endpoint').enable();
          this.selectFormGroup.get('endpointUnit').enable();
        }

        const endpointsToAdd = [
          'toxicological_relevance__c',
          'ld50__c',
          'lc50__c',
          'irritation__c',
          'sensitization__c',
          'corrosivity__c'
        ];
        this.doEndpointFiltering(endpointsToAdd);

        const endpointUnitsToAdd = [
          'mgkg_bw__c',
          'mgkg_bwd__c',
          'mgl__c',
          'mgm3__c'
        ];
        this.doEndpointUnitFiltering(endpointUnitsToAdd);

        this.routeOfAdministrationColor = 'white';
    }
    else if (this.singleEndpointRecord.route_of_administration__c === 'oral_capsule__c' ||
      this.singleEndpointRecord.route_of_administration__c === '4h_nose_only__c' ||
      this.singleEndpointRecord.route_of_administration__c === 'whole_body_4h_or_24h__c'
    ) {
      if (calledFrom === 'manualUpdate' && this.routeOfAdministrationColor !== 'red') {
        // changing group - reset
        this.singleEndpointRecord.endpoint__c = '';
        this.singleEndpointRecord.endpoint_unit__c = '';
        this.selectFormGroup.get('endpoint').setValue('');
        this.selectFormGroup.get('endpointUnit').setValue('');
        this.selectFormGroup.get('endpoint').enable();
        this.selectFormGroup.get('endpointUnit').enable();
      }
      const endpointsToAdd = [
        'loael_oral_stot__c',
        'loael_dermal_stot__c',
        'loael_inhalation__c',
        'genotoxicity__c'
      ];
      this.doEndpointFiltering(endpointsToAdd);

      const endpointUnitsToAdd = [
        'm__c'
      ];
      this.doEndpointUnitFiltering(endpointUnitsToAdd);

      this.routeOfAdministrationColor = 'red';
    }
  }

  doEndpointFiltering(endpoints): void {
    this.endpoints = [];
    endpoints.forEach(endpointValue => {
        const singleEndpoint = this.allEndpoints.filter(
          (entry) => entry.name === endpointValue
        );
        this.endpoints = this.endpoints.concat(singleEndpoint);
      }
    );
  }

  doEndpointUnitFiltering(endpointUnits): void {
    this.endpointUnits = [];
    endpointUnits.forEach(endpointUnitValue => {
        const singleEndpointUnit = this.allEndpointUnits.filter(
          (entry) => entry.name === endpointUnitValue
        );
        this.endpointUnits = this.endpointUnits.concat(singleEndpointUnit);
      }
    );
  }

  filterAdditionalFieldValues(allAdditionalFieldValues: Array<PicklistValue>, filterWord: string): Array<PicklistValue> {
    return allAdditionalFieldValues.filter(
      (item) => item.picklist === filterWord
    );
  }

  removeEndpoint(singleEndpointRecord): any {
    this.removeInput.emit(singleEndpointRecord);
  }

  updateValue1(newValue): any {
    if (newValue.value < 0) {
      this.selectFormGroup.get('value1').setValue('');
    }
    else {
      this.singleEndpointRecord.value_1__c = newValue.value;
    }

    // checking if value 2 is bigger than value 1
    const value1 = parseInt(String(newValue.value));
    const value2 = parseInt(String(this.singleEndpointRecord.value_2__c));

    if (value2 <= value1 || isNaN(value1)) {
      this.errorOnValue2 = true;
      console.log('error: ' + value1 + ', ' + value2);
    }
    else {
      this.errorOnValue2 = false;
      console.log('clear ' + value1 + ', ' + value2);
    }
  }

  updateValue2(newValue): any {
    if (newValue.value < 0) {
      this.selectFormGroup.get('value2').setValue('');
    } else {
      this.singleEndpointRecord.value_2__c = newValue.value;
    }

     // checking if value 2 is bigger than value 1
    const value1 = parseInt(String(this.singleEndpointRecord.value_1__c));
    const value2 = parseInt(String(newValue.value));

    if (value2 <= value1 || isNaN(value2)) {
      this.errorOnValue2 = true;
      console.log('error: ' + value1 + ', ' + value2);
    }
    else {
      this.errorOnValue2 = false;
      console.log('clear ' + value1 + ', ' + value2);
    }
  }

  updateValue3(newValue): any {
    this.singleEndpointRecord.value_3__c = newValue.value;
  }

  updateNumberFieldValue(newValue): any {
    const fieldName = newValue.title;

    if (fieldName === 'temp_c__c') {
      this.singleEndpointRecord[fieldName] = newValue.value;
    } else {
      if (newValue.value < 0) {
        this.singleEndpointRecord[fieldName] = '';
        this.selectFormGroup.get(fieldName).setValue('');
      } else {
        this.singleEndpointRecord[fieldName] = newValue.value;
      }
    }
  }

  copyEndpoint(singleEndpointRecord: ToxEndpointTableItem): void {
    this.copyInput.emit(singleEndpointRecord);
  }

  updateMultiPicklistFieldValue(newValue, fieldName): void {
    this.singleEndpointRecord[fieldName] = newValue.value.toString();
  }

  setFormFields(): void {
    this.selectFormGroup.get('substanceType').setValue(this.singleEndpointRecord.substance_type__c);
    this.selectFormGroup.get('relatedSubstance').setValue(this.singleEndpointRecord.related_substance__c);
    this.selectFormGroup.get('studyType').setValue(this.singleEndpointRecord.study_type__c);
    this.selectFormGroup.get('route_of_administration__c').setValue(this.singleEndpointRecord.route_of_administration__c);
    this.selectFormGroup.get('test_system_name__c').setValue(this.singleEndpointRecord.test_system_name__c);
    this.selectFormGroup.get('endpoint').setValue(this.singleEndpointRecord.endpoint__c);
    this.selectFormGroup.get('endpointUnit').setValue(this.singleEndpointRecord.endpoint_unit__c);
    this.selectFormGroup.get('operator').setValue(this.singleEndpointRecord.operator__c);
    this.selectFormGroup.get('value1').setValue(this.singleEndpointRecord.value_1__c);
    this.selectFormGroup.get('value2').setValue(this.singleEndpointRecord.value_2__c);
    this.selectFormGroup.get('value3').setValue(this.singleEndpointRecord.value_3__c);
    // additional fields
    this.selectFormGroup.get('test_method_skin_sensitization__c').setValue(this.singleEndpointRecord.test_method_skin_sensitization__c);
    this.selectFormGroup.get('ec3__c').setValue(this.singleEndpointRecord.ec3__c);
    this.selectFormGroup.get('ec15__c').setValue(this.singleEndpointRecord.ec15__c);
    if (this.singleEndpointRecord.target_organ__c) {
      this.selectFormGroup.get('target_organ__c').setValue(this.singleEndpointRecord.target_organ__c.split(','));
    }
    this.selectFormGroup.get('noael_carcinogenicity_mgkg_bwd__c').setValue(this.singleEndpointRecord.noael_carcinogenicity_mgkg_bwd__c);
    this.selectFormGroup.get('loael_carcinogenicity_mgkg_bwd__c').setValue(this.singleEndpointRecord.loael_carcinogenicity_mgkg_bwd__c);
    if (this.singleEndpointRecord.tumor_site_male__c) {
      this.selectFormGroup.get('tumor_site_male__c').setValue(this.singleEndpointRecord.tumor_site_male__c.split(','));
    }
    if (this.singleEndpointRecord.tumor_site_female__c) {
      this.selectFormGroup.get('tumor_site_female__c').setValue(this.singleEndpointRecord.tumor_site_female__c.split(','));
    }
    this.selectFormGroup.get('noael_fertility_mgkg_bwd__c').setValue(this.singleEndpointRecord.noael_fertility_mgkg_bwd__c);
    this.selectFormGroup.get('loael_fertility_mgkg_bwd__c').setValue(this.singleEndpointRecord.loael_fertility_mgkg_bwd__c);
    this.selectFormGroup.get('noael_offspring_mgkg_bwd__c').setValue(this.singleEndpointRecord.noael_offspring_mgkg_bwd__c);
    this.selectFormGroup.get('loael_offspring_mgkg_bwd__c').setValue(this.singleEndpointRecord.loael_offspring_mgkg_bwd__c);
    this.selectFormGroup.get('noael_maternal_mgkg_bwd__c').setValue(this.singleEndpointRecord.noael_maternal_mgkg_bwd__c);
    this.selectFormGroup.get('loael_maternal_mgkg_bwd__c').setValue(this.singleEndpointRecord.loael_maternal_mgkg_bwd__c);
    this.selectFormGroup.get('noael_developmental_mgkg_bwd__c').setValue(this.singleEndpointRecord.noael_developmental_mgkg_bwd__c);
    this.selectFormGroup.get('loael_developmental_mgkg_bwd__c').setValue(this.singleEndpointRecord.loael_developmental_mgkg_bwd__c);

    if (this.singleEndpointRecord.eats_and_developmental_parameters_affect__c) {
      this.selectFormGroup.get('eats_and_developmental_parameters_affect__c').setValue(this.singleEndpointRecord.eats_and_developmental_parameters_affect__c.split(','));
    }
    this.selectFormGroup.get('ed_mechanistic_study__c').setValue(this.singleEndpointRecord.ed_mechanistic_study__c);
    // default
    this.selectFormGroup.get('selectedDocument').setValue(this.singleEndpointRecord.document_unbound__c);
  }

  clearAdditionalFields(): any {
    this.singleEndpointRecord.test_method_skin_sensitization__c = '';
    this.singleEndpointRecord.ec3__c = '';
    this.singleEndpointRecord.ec15__c = '';
    this.singleEndpointRecord.target_organ__c = '';
    this.singleEndpointRecord.noael_carcinogenicity_mgkg_bwd__c = '';
    this.singleEndpointRecord.loael_carcinogenicity_mgkg_bwd__c = '';
    this.singleEndpointRecord.tumor_site_male__c = '';
    this.singleEndpointRecord.tumor_site_female__c = '';
    this.singleEndpointRecord.noael_fertility_mgkg_bwd__c = '';
    this.singleEndpointRecord.loael_fertility_mgkg_bwd__c = '';
    this.singleEndpointRecord.noael_offspring_mgkg_bwd__c = '';
    this.singleEndpointRecord.loael_offspring_mgkg_bwd__c = '';
    this.singleEndpointRecord.noael_maternal_mgkg_bwd__c = '';
    this.singleEndpointRecord.loael_maternal_mgkg_bwd__c = '';
    this.singleEndpointRecord.noael_developmental_mgkg_bwd__c = '';
    this.singleEndpointRecord.loael_developmental_mgkg_bwd__c = '';
    this.singleEndpointRecord.eats_and_developmental_parameters_affect__c = '';
    this.singleEndpointRecord.ed_mechanistic_study__c = '';

    this.selectFormGroup.get('test_method_skin_sensitization__c').setValue('');
    this.selectFormGroup.get('ec3__c').setValue('');
    this.selectFormGroup.get('ec15__c').setValue('');
    this.selectFormGroup.get('target_organ__c').setValue('');
    this.selectFormGroup.get('noael_carcinogenicity_mgkg_bwd__c').setValue('');
    this.selectFormGroup.get('loael_carcinogenicity_mgkg_bwd__c').setValue('');
    this.selectFormGroup.get('tumor_site_male__c').setValue('');
    this.selectFormGroup.get('tumor_site_female__c').setValue('');
    this.selectFormGroup.get('noael_fertility_mgkg_bwd__c').setValue('');
    this.selectFormGroup.get('loael_fertility_mgkg_bwd__c').setValue('');
    this.selectFormGroup.get('noael_offspring_mgkg_bwd__c').setValue('');
    this.selectFormGroup.get('loael_offspring_mgkg_bwd__c').setValue('');
    this.selectFormGroup.get('noael_maternal_mgkg_bwd__c').setValue('');
    this.selectFormGroup.get('loael_maternal_mgkg_bwd__c').setValue('');
    this.selectFormGroup.get('noael_developmental_mgkg_bwd__c').setValue('');
    this.selectFormGroup.get('loael_developmental_mgkg_bwd__c').setValue('');
    // eslint-disable-next-line max-len
    this.selectFormGroup.get('eats_and_developmental_parameters_affect__c').setValue('');
    this.selectFormGroup.get('ed_mechanistic_study__c').setValue('');
  }

  onSelectOperator(event: any): any {
    if (event.target !== undefined) {
      this.singleEndpointRecord.operator__c = event.target.value;
    }

    switch (this.singleEndpointRecord.operator__c) {
      case 'between__c':
        this.isValue1Disabled = false;
        this.isValue2Disabled = false;
        this.isValue3Disabled = true;
        this.selectFormGroup.get('value1').enable();
        this.selectFormGroup.get('value2').enable();
        this.selectFormGroup.get('value3').disable();
        this.singleEndpointRecord.value_3__c = '';
        this.value3Control.setValue('');
        break;
      case 'text__c':
        this.isValue1Disabled = true;
        this.isValue2Disabled = true;
        this.isValue3Disabled = false;
        this.selectFormGroup.get('value1').disable();
        this.selectFormGroup.get('value2').disable();
        this.selectFormGroup.get('value3').enable();
        this.value1Control.setValue(null);
        this.value2Control.setValue(null);
        this.singleEndpointRecord.value_1__c = null;
        this.singleEndpointRecord.value_2__c = null;
        break;
      case '':
        this.isValue1Disabled = true;
        this.isValue2Disabled = true;
        this.isValue3Disabled = true;
        this.selectFormGroup.get('value1').disable();
        this.selectFormGroup.get('value2').disable();
        this.selectFormGroup.get('value3').disable();
        this.value1Control.setValue(null);
        this.value2Control.setValue(null);
        this.value3Control.setValue('');
        this.singleEndpointRecord.value_1__c = null;
        this.singleEndpointRecord.value_2__c = null;
        this.singleEndpointRecord.value_3__c = '';
        break;
      default:
        this.isValue1Disabled = false;
        this.isValue2Disabled = true;
        this.isValue3Disabled = true;
        this.selectFormGroup.get('value1').enable();
        this.selectFormGroup.get('value2').disable();
        this.selectFormGroup.get('value3').disable();
        this.value2Control.setValue(null);
        this.value3Control.setValue('');
        this.singleEndpointRecord.value_2__c = null;
        this.singleEndpointRecord.value_3__c = '';
        break;
    }
  }

  cancelEditing(endpointRecord): void {
    this.cancelEdit.emit(endpointRecord);
  }

  saveEndpointChanges(endpointRecord): void {
    this.saveChanges.emit(endpointRecord);
  }

}


