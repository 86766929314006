<div class="grid-container">
<!--  <h1 class="mat-h1">Dashboard</h1>-->
  <mat-grid-list cols="{{ (cardLayout | async)?.columns }}" rowHeight="300px">
<!--    Spiderweb Report Card    -->
    <mat-grid-tile [colspan]="(cardLayout | async)?.chart.cols"
                   [rowspan]="(cardLayout | async)?.chart.rows">
      <app-card [title]="'Regulatory Risk Scores'">
        <app-charts [rows]="data"></app-charts>
      </app-card>
    </mat-grid-tile>
<!--    Comment Table Card    -->
    <mat-grid-tile [colspan]="(cardLayout | async)?.table.cols"
                   [rowspan]="(cardLayout | async)?.table.rows">
      <app-card [title]="'Comments'">
        <app-table [rows]=data></app-table>
      </app-card>
    </mat-grid-tile>
  </mat-grid-list>
</div>
