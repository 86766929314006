import {Injectable} from '@angular/core';
import {HttpResponse} from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class ExceptionService {
  constructor() {
  }

  /**
   * Handle error response
   * @param error Response | any
   */
  public handleError(error: HttpResponse<any> | any): HttpResponse<any> | any {
    const message = `${error.url}: ${error.status} ${error.statusText}: ${error.error.message}`;
    console.error(message);
    return error;
  }
}
