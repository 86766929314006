import {AfterContentInit, Component, Input, OnInit, ViewChild, } from '@angular/core';
import {PicklistValue} from '../../models/picklist-value';
import {SubstanceItem} from '../../models/substance-item';
import {RelatedSubstances} from '../../models/related-substances';
import {DocumentMenuItem} from '../../models/document-menu-item';
import {ActivatedRoute} from '@angular/router';
import {ServerService} from '../../../core/server.service';
import {EndpointServicesService} from '../../endpoint-services.service';
import {PhysChemDataCategoryPicklists} from '../models/phys-chem-data-category-picklists';
import {PhysChemDataEndpointTableItem} from '../models/phys-chem-data-endpoint-table-item';
import {PhysChemDataAdditionalField} from '../models/phys-chem-data-additional-field';
import {PhysChemDataRecordComponent} from '../phys-chem-data-record/phys-chem-data-record.component';

@Component({
  selector: 'app-phys-chem-data-category',
  templateUrl: './phys-chem-data-category.component.html',
  styleUrls: ['./phys-chem-data-category.component.css']
})
export class PhysChemDataCategoryComponent implements OnInit, AfterContentInit {
  @Input() workAreaId: string;
  @Input() workAreaLabel: string;
  @Input() category: any;
  @Input() dataPicklists: Array<PhysChemDataCategoryPicklists>;
  @Input() operators: Array<PicklistValue>;
  @Input() endpointsArrayList: Array<PhysChemDataEndpointTableItem>;
  @Input() substance: SubstanceItem;
  @Input() relatedSubstances: Array<RelatedSubstances>;
  @Input() substanceTypes: Array<PicklistValue>;
  @Input() receivedAllEndpoints: Array<PicklistValue>;
  @Input() receivedAllEndpointUnits: Array<PicklistValue>;
  @Input() receivedAllTestSystemTypes: Array<PicklistValue>;
  @Input() documents: Array<DocumentMenuItem>;
  @Input() receivedAllAdditionalFields: Array<PhysChemDataAdditionalField>;

  @ViewChild(PhysChemDataRecordComponent) physChemDataRecord: PhysChemDataRecordComponent;

  public selectedDocument: DocumentMenuItem;
  public filteredPicklists: Array<PhysChemDataCategoryPicklists>;
  public categoryEndpointsArrayList: Array<PhysChemDataEndpointTableItem>;
  public defaultAllEndpoints: Array<PicklistValue>;
  public defaultAllEndpointUnits: Array<PicklistValue>;
  public defaultAllTestSystemTypes: Array<PicklistValue>;
  public allEndpoints: Array<PicklistValue>;
  public allEndpointUnits: Array<PicklistValue>;
  public allTestSystemTypes: Array<PicklistValue>;
  public startingId: number;
  public maxCategoryId: number;
  private sessionId: string;

  constructor(
    private route: ActivatedRoute,
    private serverService: ServerService,
    private endpointService: EndpointServicesService
  ) {
    this.route.paramMap.subscribe((params) => {
      this.sessionId = params.get('sessionId');
    });
    this.categoryEndpointsArrayList = [];
    this.filteredPicklists = [];
    this.defaultAllEndpoints = [];
    this.defaultAllEndpointUnits = [];
    this.defaultAllTestSystemTypes = [];
    this.allEndpoints = [];
    this.allEndpointUnits = [];
    this.allTestSystemTypes = [];
    this.startingId = 0;
    this.maxCategoryId = 0;
  }

  ngOnInit(): void {
    this.endpointService.currentDocument.subscribe(
      (doc) => (this.selectedDocument = doc)
    );
    this.maxCategoryId = (10 - this.category.order_by__c) * 1000000;
  }

  ngAfterContentInit(): void {
    this.filteredPicklists = this.dataPicklists.filter(
      (a) => a.categoryId === this.category.id
    );
    this.defaultAllEndpoints = this.receivedAllEndpoints.filter(
      (c) => c.id === this.category.id
    );

    this.defaultAllEndpointUnits = this.receivedAllEndpointUnits.filter(
      (d) => d.id === this.category.id
    );
    this.defaultAllTestSystemTypes = this.receivedAllTestSystemTypes.filter(
      (e) => e.id === this.category.id
    );

    this.unifyEndpoints();
    this.unifyEndpointUnits();
    this.unifyTestSystemTypes();
  }

  unifyEndpoints(): void {
    // remove duplicates
    const arrayEndpoints = this.defaultAllEndpoints;
    const map = new Map();
    for (const item of arrayEndpoints) {
      if (!map.has(item.name)) {
        map.set(item.name, true); // set any value to Map
        this.allEndpoints.push({
          name__v: item.name__v,
          id: item.id,
          name: item.name,
          label: item.label,
          picklist: item.picklist,
        });
      }
    }
  }

  unifyEndpointUnits(): void {
    // remove duplicates
    const array = this.defaultAllEndpointUnits;
    const map = new Map();
    for (const item of array) {
      if (!map.has(item.name)) {
        map.set(item.name, true); // set any value to Map
        this.allEndpointUnits.push({
          name__v: item.name__v,
          id: item.id,
          name: item.name,
          label: item.label,
          picklist: item.picklist,
        });
      }
    }
  }

  unifyTestSystemTypes(): void {
    // remove duplicates
    const array = this.defaultAllTestSystemTypes;
    const map = new Map();
    for (const item of array) {
      if (!map.has(item.name)) {
        map.set(item.name, true); // set any value to Map
        this.allTestSystemTypes.push({
          name__v: item.name__v,
          id: item.id,
          name: item.name,
          label: item.label,
          picklist: item.picklist,
        });
      }
    }
  }

  addNewEndpoint(endpoint: any): void {
    const newEndpointTemplate: PhysChemDataEndpointTableItem = {
      id__c: this.generateId(),
      work_area__c: this.workAreaId,
      category__c: this.category.id,
      substance_name__c: this.substance.id,
      substance_type__c: endpoint.substance_type__c ?? 'parent__c',
      related_substance__c: endpoint.related_substance__c ?? '',
      endpoint__c: endpoint.endpoint__c ?? '',
      endpoint_unit__c: endpoint.endpoint_unit__c ?? '',
      test_system_type__c: endpoint.test_system_type__c ?? '',
      operator__c: endpoint.operator__c ?? '',
      value_1__c: endpoint.value_1__c ?? null,
      value_2__c: endpoint.value_2__c ?? null,
      value_3__c: endpoint.value_3__c ?? '',
      // additional (depended) fields
      ph__c: endpoint.ph__c ?? '',
      purity__c: endpoint.purity__c ?? '',
      temp_c__c: endpoint.temp_c__c ?? null,
      comment__c: endpoint.comment__c ?? '',
      document_unbound__c: endpoint.document_unbound__c ?? this.selectedDocument.id,
    };

    this.categoryEndpointsArrayList.push(newEndpointTemplate);
    this.endpointsArrayList.push(newEndpointTemplate);
  }

  removeEndpointInput(endpoint: PhysChemDataEndpointTableItem): void {
    // remove object from array
    for (let index = 0; index < this.endpointsArrayList.length; index++) {
      const element: PhysChemDataEndpointTableItem = this.endpointsArrayList[index];
      if (element.id__c === endpoint.id__c) {
        this.endpointsArrayList.splice(index, 1);
      }
    }

    for (let index = 0; index < this.categoryEndpointsArrayList.length; index++) {
      const element: PhysChemDataEndpointTableItem = this.categoryEndpointsArrayList[index];
      if (element.id__c === endpoint.id__c) {
        this.categoryEndpointsArrayList.splice(index, 1);
      }
    }
  }

  removeSavedEndpoint(endpointId): any {
    this.categoryEndpointsArrayList = this.categoryEndpointsArrayList.filter(obj => obj.id__c !== endpointId);
  }

  generateId(): number {
    const newStartingId = this.startingId + 1;
    this.updateStartingId(newStartingId);
    return this.maxCategoryId - newStartingId;
  }

  updateStartingId(newStartingId): void {
    this.startingId = newStartingId;
  }

  copyEndpointInput(endpoint: PhysChemDataEndpointTableItem): void {
    this.categoryEndpointsArrayList.filter(it => it.id__c === endpoint.id__c)
      .forEach(newEndpoint => {
        this.addNewEndpoint(newEndpoint);
      });
  }
}
