export const timeline = [
  'application_date__c',
  'actual_submission_date__c',
  'dardrar_date__c',
  'efsa_conclusion_date__c',
  '1st_commission_proposal_date__c',
  'entry_into_force__c',
  'expiry_date__c',
  'estimated_application_date__c',
  'estimated_submission_date__c',
  'estimated_efsa_conclusion_date__c',
  'estimated_dardrar_date__c',
  'estimated_1st_commission_proposal_date__c',
  'estimated_entry_into_force_date__c',
];
