import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {PicklistValue} from '../../models/picklist-value';
import {DocumentMenuItem} from '../../models/document-menu-item';
import {PhysChemDataCategoryPicklists} from '../models/phys-chem-data-category-picklists';
import {PhysChemDataAdditionalField} from '../models/phys-chem-data-additional-field';
import {PhysChemDataEndpointTableItem} from '../models/phys-chem-data-endpoint-table-item';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute} from '@angular/router';
import {ExceptionService} from '../../../core/exception.service';
import {ServerService} from '../../../core/server.service';
import {ToastrService} from 'ngx-toastr';
import {ToxEndpointTableItem} from "../../models/tox-endpoint-table-item";

import {MatDialog} from '@angular/material/dialog';
import {AddDataMatDialogComponent} from '../../../shared/add-data-mat-dialog/add-data-mat-dialog.component';
import {EndpointServicesService} from '../../endpoint-services.service';

@Component({
  selector: 'app-phys-chem-data-record',
  templateUrl: './phys-chem-data-record.component.html',
  styleUrls: ['../../record_style.css']
})
export class PhysChemDataRecordComponent implements OnInit {
  @Input() singleEndpointRecord: PhysChemDataEndpointTableItem;
  @Input() category: PicklistValue;
  @Input() categoryPicklists: Array<PhysChemDataCategoryPicklists>;
  @Input() allEndpoints: Array<PicklistValue>;
  @Input() allEndpointUnits: Array<PicklistValue>;
  @Input() allTestSystemTypes: Array<PicklistValue>;
  @Input() allOperators: Array<PicklistValue>;
  @Input() allAdditionalFields: Array<PhysChemDataAdditionalField>;
  @Input() substanceTypes: Array<PicklistValue>;
  @Input() relatedSubstances: any;
  @Input() documents: Array<DocumentMenuItem>;
  @Input() purpose: string;

  @Output() removeInput: EventEmitter<PhysChemDataEndpointTableItem> = new EventEmitter();
  @Output() copyInput: EventEmitter<PhysChemDataEndpointTableItem> = new EventEmitter();
  @Output() cancelEdit: EventEmitter<ToxEndpointTableItem> = new EventEmitter();
  @Output() saveChanges: EventEmitter<ToxEndpointTableItem> = new EventEmitter();

  selectFormGroup: FormGroup;
  public additionalFields: Array<PhysChemDataAdditionalField>;
  public endpoints: Array<PicklistValue>;
  public endpointUnits: Array<PicklistValue>;
  public testSystemTypes: Array<PicklistValue>;
  public operators: Array<PicklistValue>;
  public isEndpointUnitHidden: boolean;
  public isValue1Disabled: boolean;
  public isValue2Disabled: boolean;
  public isValue3Disabled: boolean;
  public filteredRelatedSubstances: any;
  private sessionId: string;
  public showCopyOptions: boolean;
  public showEditOptions: boolean;
  public errorOnValue2: Boolean;
  public dialogTitle: string;
  public dialogRow1: string;
  public selectedPicklist: any;
  public currentList: any;
  private currentUserProfile: string;
  public editPicklistOption: boolean;

  constructor(
    private route: ActivatedRoute,
    private exceptionService: ExceptionService,
    private serverService: ServerService,
    private toastrService: ToastrService,
    private formBuilder: FormBuilder,
    public dialog: MatDialog,
    private endpointService: EndpointServicesService
  ) {
    this.route.paramMap.subscribe((params) => {
      this.sessionId = params.get('sessionId');
    });
    this.selectFormGroup = this.formBuilder.group({
      substanceType: [],
      relatedSubstance: [{value: '', disabled: true}],
      endpoint: [{value: '', disabled: false}, Validators.required],
      endpointUnit: [{value: '', disabled: false}, Validators.required],
      testSystemType: [{value: '', disabled: false}, Validators.required],
      operator: ['', Validators.required],
      value1: [{value: null, disabled: false}],
      value2: [{value: null, disabled: false}],
      value3: [{value: '', disabled: false}],
      // additional (depended) fields
      ph__c: [{value: '', disabled: false}],
      purity__c: [{value: '', disabled: false}],
      temp_c__c: [{value: '', disabled: false}],
      comment__c: [{value: '', disabled: false}],
      selectedDocument: [{value: '', disabled: false}],
    });
    this.operators = [];
    this.testSystemTypes = [];
    this.additionalFields = [];
    this.isEndpointUnitHidden = false;
    this.isValue1Disabled = true;
    this.isValue2Disabled = true;
    this.isValue3Disabled = true;
    this.filteredRelatedSubstances = [];
    this.errorOnValue2 = false;
    this.dialogTitle = 'Edit ';
    this.dialogRow1 = '';
    this.selectedPicklist = [];
    this.currentList = [];
    this.editPicklistOption = false;
  }

  ngOnInit(): void {

    if (this.purpose === 'editOnExisting') {
      this.showCopyOptions = false;
      this.showEditOptions = true;
    }
    else {
      this.showCopyOptions = true;
      this.showEditOptions = false;
    }

    const filteredOperators = [
      'a1595852199787__c',
      'a1595852189563__c',
      'a1595852194618__c',
      'a1595852268945__c',
      'a1595852266201__c',
      'between__c',
      'a1595852359285__c',
      'text__c'
    ];

    filteredOperators.forEach(operator => {
        const singleOperator = this.allOperators.filter(
          (entry) => entry.name === operator
        );
        this.operators = this.operators.concat(singleOperator);
      }
    );

    if (this.singleEndpointRecord.substance_type__c !== '') {
      this.onSelectOperator(this.singleEndpointRecord.operator__c);
      this.setFormFields();
    } else {
      this.onSelectOperator(this.singleEndpointRecord.operator__c);
      this.selectFormGroup.get('substanceType').setValue('parent__c');
    }
    this.setEndpointUnit(this.singleEndpointRecord.endpoint__c);
    this.setRelatedSubstance(this.singleEndpointRecord.substance_type__c);
    this.endpoints = this.allEndpoints;
    this.endpointUnits = this.allEndpointUnits;
    this.testSystemTypes = this.allTestSystemTypes;
    this.checkVisibility();
    const preAdditionalFields = this.allAdditionalFields.filter(obj => obj.hidden !== 'yes__c');
    this.additionalFields = preAdditionalFields.sort((a, b) => a.sortNumber - b.sortNumber);
    this.selectFormGroup.get('selectedDocument').setValue(this.singleEndpointRecord.document_unbound__c);

    this.endpointService.userProfile.subscribe(
      (profile) => (this.currentUserProfile = profile)
    );

    this.editPicklistOption = this.currentUserProfile === 'vault_owner__v'
    || this.currentUserProfile === 'super_user__c';
  }

  checkVisibility(): void {
    const phField = this.allAdditionalFields.filter((it) => it.name === 'ph__c');
    if (this.category.name__v !== 'Vapour Pressure' && this.category.name__v !== 'Henry\'s Law Constant') {
      phField.forEach((it) => it.hidden = 'no__c');
    } else {
      phField.forEach((it) => it.hidden = 'yes__c');
    }

    const tempField = this.allAdditionalFields.filter((it) => it.name === 'temp_c__c');
    if (this.category.name__v !== 'Dissociation Constant' &&
      this.category.name__v !== 'UV/Vis Absorption' &&
      this.category.name__v !== 'Stereochemistry') {
      tempField.forEach((it) => it.hidden = 'no__c');
    } else {
      tempField.forEach((it) => it.hidden = 'yes__c');
    }

    this.isEndpointUnitHidden = !(this.category.name__v == 'Vapour Pressure' ||
      this.category.name__v == 'Henry\'s Law Constant' ||
      this.category.name__v == 'Solubility' ||
      this.category.name__v == 'UV/Vis Absorption');
  }

  onSelectSubstanceType(event: any): any {
    this.singleEndpointRecord.substance_type__c = event.target.value;

    // clear Related Substance value because the group of Related Substances has changed
    this.singleEndpointRecord.related_substance__c = '';
    // also clear Related Substance form field
    this.selectFormGroup.get('relatedSubstance').setValue('');

    this.setRelatedSubstance(event.target.value);
  }

  setRelatedSubstance(substanceType: string): void {
    // parent (or none) cannot have Related Substance
    if (substanceType === 'parent__c' || substanceType === '') {
      this.selectFormGroup.get('relatedSubstance').disable();
    } else {
      // filter Related Substances by the selected Substance Type
      this.filteredRelatedSubstances = this.relatedSubstances.filter(
        (f) => f.substance_type__c[0] === substanceType
      );
      this.selectFormGroup.get('relatedSubstance').enable();
    }
  }

  setEndpointUnit(endpoint: string): void {
    const endpointUnit = this.selectFormGroup.get('endpointUnit');
    if (endpoint !== '') {
      endpointUnit.enable();
    } else {
      endpointUnit.disable();
      endpointUnit.setValue('');
      this.singleEndpointRecord.endpoint_unit__c = '';
    }
  }

  onSelectEndpoint(event: any): any {
   if (event.target.value !== '') {
      if (event.target.value === 'addNewRecord') {
        this.currentList = this.endpoints;
        this.openDialog('Endpoint', 'endpoint', this.endpoints[0].picklist, 'endpoint__c');
      }
      else {
        this.singleEndpointRecord.endpoint__c = event.target.value;
        this.setEndpointUnit(this.singleEndpointRecord.endpoint__c);
      }
    }
    else {
      this.singleEndpointRecord.endpoint__c = '';
      this.setEndpointUnit('');
    }
  }

  onSelectUnit(event: any): any {
    if (event.target.value === 'addNewRecord') {
      this.currentList = this.endpointUnits;
      this.openDialog('Endpoint Unit', 'endpointUnit', this.endpointUnits[0].picklist, 'endpoint_unit__c');
    } else {
      this.singleEndpointRecord.endpoint_unit__c = event.target.value;
    }
  }

  onSelectOperator(operator): void {
    if (operator) {
      if (typeof operator !== 'string') {
        this.singleEndpointRecord.operator__c = operator.value;
      } else {
        this.singleEndpointRecord.operator__c = operator;
      }
      const value1 = this.selectFormGroup.get('value1');
      const value2 = this.selectFormGroup.get('value2');
      const value3 = this.selectFormGroup.get('value3');
      switch (this.singleEndpointRecord.operator__c) {
        case 'between__c':
          this.isValue1Disabled = false;
          this.isValue2Disabled = false;
          this.isValue3Disabled = true;
          value1.enable();
          value2.enable();
          value3.disable();
          value3.setValue('');
          this.singleEndpointRecord.value_3__c = '';
          break;
        case 'text__c':
          this.isValue1Disabled = true;
          this.isValue2Disabled = true;
          this.isValue3Disabled = false;
          value1.disable();
          value2.disable();
          value3.enable();
          value1.setValue(null);
          value2.setValue(null);
          this.singleEndpointRecord.value_1__c = null;
          this.singleEndpointRecord.value_2__c = null;
          break;
        case '':
          this.isValue1Disabled = true;
          this.isValue2Disabled = true;
          this.isValue3Disabled = true;
          value1.disable();
          value2.disable();
          value3.disable();
          value1.setValue(null);
          value2.setValue(null);
          value3.setValue('');
          this.singleEndpointRecord.value_1__c = null;
          this.singleEndpointRecord.value_2__c = null;
          this.singleEndpointRecord.value_3__c = '';
          break;
        default:
          this.isValue1Disabled = false;
          this.isValue2Disabled = true;
          this.isValue3Disabled = true;
          value1.enable();
          value2.disable();
          value3.disable();
          value2.setValue(null);
          value3.setValue('');
          this.singleEndpointRecord.value_2__c = null;
          this.singleEndpointRecord.value_3__c = '';
          break;
      }
    }
    else {
      this.isValue1Disabled = true;
      this.isValue2Disabled = true;
      this.isValue3Disabled = true;
      this.selectFormGroup.get('value1').disable();
      this.selectFormGroup.get('value2').disable();
      this.selectFormGroup.get('value3').disable();
    }
  }


  filterEndpointUnits(picklist: string): any {
    this.endpointUnits = this.allEndpointUnits.filter(
      (entry) => entry.picklist === picklist
    );
  }

  filterTestSystemTypes(picklist: string): any {
    this.testSystemTypes = [];
    this.testSystemTypes = this.allTestSystemTypes.filter(
      (entry) => entry.picklist === picklist
    );
  }

  removeEndpoint(singleEndpointRecord): any {
    this.removeInput.emit(singleEndpointRecord);
  }

  updateValue1(newValue): any {
    if (newValue.value < 0) {
      this.selectFormGroup.get('value1').setValue('');
    }
    else {
      this.singleEndpointRecord.value_1__c = newValue.value;
    }

    // checking if value 2 is bigger than value 1
    const value1 = parseInt(String(newValue.value));
    const value2 = parseInt(String(this.singleEndpointRecord.value_2__c));

    if (value2 <= value1 || isNaN(value1)) {
      this.errorOnValue2 = true;
      console.log('error: ' + value1 + ', ' + value2);
    }
    else {
      this.errorOnValue2 = false;
      console.log('clear ' + value1 + ', ' + value2);
    }
  }

  updateValue2(newValue): any {
    if (newValue.value < 0) {
      this.selectFormGroup.get('value2').setValue('');
    } else {
      this.singleEndpointRecord.value_2__c = newValue.value;
    }

     // checking if value 2 is bigger than value 1
    const value1 = parseInt(String(this.singleEndpointRecord.value_1__c));
    const value2 = parseInt(String(newValue.value));

    if (value2 <= value1 || isNaN(value2)) {
      this.errorOnValue2 = true;
      console.log('error: ' + value1 + ', ' + value2);
    }
    else {
      this.errorOnValue2 = false;
      console.log('clear ' + value1 + ', ' + value2);
    }
  }

  updateValue3(newValue): any {
    this.singleEndpointRecord.value_3__c = newValue.value;
  }

  updateFieldValue(newValue): any {
    const fieldName = newValue.title;

    if (fieldName === 'test_system_type__c' && newValue.value === 'addNewRecord') {
      this.singleEndpointRecord[fieldName] = '';
      this.currentList = this.testSystemTypes;
      this.openDialog('Test System Type', 'testSystemType', this.testSystemTypes[0].picklist, 'test_system_type__c');
    }
    else {
      this.singleEndpointRecord[fieldName] = newValue.value;
    }

  }

  updateNumberFieldValue(newValue): any {
    const fieldName = newValue.title;

    if (fieldName === 'temp_c__c') {
      this.singleEndpointRecord[fieldName] = newValue.value;
    } else {
      if (newValue.value < 0) {
        this.singleEndpointRecord[fieldName] = '';
        this.selectFormGroup.get(fieldName).setValue('');
      } else {
        this.singleEndpointRecord[fieldName] = newValue.value;
      }
    }
  }

  copyEndpoint(singleEndpointRecord: PhysChemDataEndpointTableItem): void {
    this.copyInput.emit(singleEndpointRecord);
  }

  setFormFields(): void {
    this.selectFormGroup.get('substanceType').setValue(this.singleEndpointRecord.substance_type__c);
    this.selectFormGroup.get('relatedSubstance').setValue(this.singleEndpointRecord.related_substance__c);
    this.selectFormGroup.get('endpoint').setValue(this.singleEndpointRecord.endpoint__c);
    this.selectFormGroup.get('endpointUnit').setValue(this.singleEndpointRecord.endpoint_unit__c);
    this.selectFormGroup.get('testSystemType').setValue(this.singleEndpointRecord.test_system_type__c);
    this.selectFormGroup.get('operator').setValue(this.singleEndpointRecord.operator__c);
    this.selectFormGroup.get('value1').setValue(this.singleEndpointRecord.value_1__c);
    this.selectFormGroup.get('value2').setValue(this.singleEndpointRecord.value_2__c);
    this.selectFormGroup.get('value3').setValue(this.singleEndpointRecord.value_3__c);
    this.selectFormGroup.get('ph__c').setValue(this.singleEndpointRecord.ph__c);
    this.selectFormGroup.get('purity__c').setValue(this.singleEndpointRecord.purity__c);
    this.selectFormGroup.get('temp_c__c').setValue(this.singleEndpointRecord.temp_c__c);
    this.selectFormGroup.get('comment__c').setValue(this.singleEndpointRecord.comment__c);
    this.selectFormGroup.get('selectedDocument').setValue(this.singleEndpointRecord.document_unbound__c);
  }

  cancelEditing(endpointRecord): void {
    this.cancelEdit.emit(endpointRecord);
  }

  saveEndpointChanges(endpointRecord): void {
    this.saveChanges.emit(endpointRecord);
  }

  openDialog(fieldLabel, fieldName, picklistName, targetPicklist): void {
    const dialogRef = this.dialog.open(AddDataMatDialogComponent, {
      width: 'auto',
      data: {
        sessionId: this.sessionId,
        dialogTitle: this.dialogTitle,
        typeOfDependency: 'group',
        dialogFieldLabel: fieldLabel,
        dialogFieldName: fieldName,
        currentList:this.currentList,
        sourcePicklist: picklistName,
        targetPicklist: targetPicklist,
        categoryId: this.category.id
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === 'canceled') {
        // user has canceled operation
      }
      else {
        if (fieldName === 'endpoint') {
          this.endpoints = this.endpoints.concat(result);
        }
        else if (fieldName === 'endpointUnit') {
          this.endpointUnits = this.endpointUnits.concat(result);
        }
        else if (fieldName === 'testSystemType') {
          this.testSystemTypes = this.testSystemTypes.concat(result);
        }
      }
    });
  }

}
