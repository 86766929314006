export class SubstanceItem {
  id: string;
  name: string;
  type: string;
  typeLabel: string;

  constructor(
    id: string = '',
    name: string = '',
    type: string,
    typeLabel: string = ''
  ) {
    this.id = id;
    this.name = name;
    this.type = type;
    this.typeLabel = typeLabel;
  }
}
