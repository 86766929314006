<div class="page-container container">
  <div *ngIf="isLoading" class="ball-loader">
    <div class="ball-loader-ball ball1"></div>
    <div class="ball-loader-ball ball2"></div>
    <div class="ball-loader-ball ball3"></div>
  </div>
  <mat-radio-group *ngIf="!isLoading" class="filter-risk-container">
    <mat-radio-button (change)="changeCategory($event.value)" checked class="filter-risk-category" value="current">
      Current risk
    </mat-radio-button>
    <mat-radio-button (change)="changeCategory($event.value)" class="filter-risk-category" value="future">
      Future risk
    </mat-radio-button>
  </mat-radio-group>
  <div class="map-container">
    <div [ngClass]="{'map-background' : !isLoading}" id="map"></div>
  </div>
  <mat-tab-group *ngIf="!isLoading" animationDuration="0ms" class="regions">
    <div *ngFor="let registration of registrations">
      <mat-tab label="{{registration}}">
        <div class="tab-content">
          <h3><b>Loss of use</b></h3>
          <p></p>
          <h3><b>Socio/political pressure</b></h3>
          <p></p>
        </div>
      </mat-tab>
    </div>
  </mat-tab-group>
</div>
