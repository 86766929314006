import {ActiveIngredient} from "./active-ingredient";

export class SubstanceSet {

  id: string = '';

  userId: string = '';

  setName: string = '';

  substances: ActiveIngredient[] = [];
}
