<h2 class="title" mat-dialog-title>Invalid pasted AI names: </h2>

<div mat-dialog-content class="mat-typography">
  <mat-list role="list" *ngFor="let substance of data">
    <mat-list-item role="listitem"><span class="bold">{{substance}}</span></mat-list-item>
  </mat-list>
</div>

<div mat-dialog-actions class="button-holder">
  <button mat-button class="confirm-button" (click)="close()">OK</button>
</div>
