import {Component, EventEmitter, HostBinding, Input, OnInit, Output} from '@angular/core';
import {Router} from '@angular/router';
import {NavItem} from "../../models/nav-item";
import {NavigationService} from '../../navigation.service';
import {ServerService} from "../../../server.service";

@Component({
  selector: 'app-menu-list-item',
  templateUrl: './menu-list-item.component.html',
  styleUrls: ['./menu-list-item.component.css']
})
export class MenuListItemComponent implements OnInit {

  expanded: boolean = false;
  registrationRegion: string;
  isSelected: boolean;

  @HostBinding('attr.aria-expanded') ariaExpanded = this.expanded;
  @Input() item: NavItem;
  @Output() newItemClickEvent = new EventEmitter<NavItem>();

  constructor(
    public navService: NavigationService,
    private serverService: ServerService,
    private router: Router,
  ) {

  }

  ngOnInit(): void {
    this.navService.currentUrl.subscribe(url => {
      if (this.item.route && url) {
        this.isSelected = url.indexOf(`/${this.item.route}`) === 0;
      }
    });
    this.setItemQueryParams();
    this.item.expanded = this.expanded;
  }

  isItemSelected(): boolean {
    this.navService.currentUrl.subscribe(url => {
      if (this.item.route && url) {
        this.isSelected = (url.indexOf(`/${this.item.route}`) === 0);
      }
    })
    return this.isSelected;
  }


  onItemSelected(item: NavItem) {
    if (item.displayName !== 'Endpoints') {
      this.item.expanded = false;
      this.goToLink(item)
    }
    if (item.child) {
      this.item.expanded = !this.item.expanded;
    }
    this.newItemClickEvent.emit(item)
  }

  goToLink(item: NavItem) {
    if (item.displayName === 'AI Comparison') {
      const url = '/#' + this.router.serializeUrl(
        this.router.createUrlTree([item.route], {
          queryParams: item.queryParams,

        })
      )
      window.open(url, '_blank');
    } else if (item.displayName === 'Endpoints') {
      if (item.child.selectedId != undefined) {
        const url = '/#' + this.router.serializeUrl(
          this.router.createUrlTree([this.item.route, this.item.child.selectedId,
            this.item.queryParams.userId, this.item.queryParams.sessionId])
        )
        window.open(url, '_blank')
      }
    } else {
      window.open(item.route, '_blank');
    }
  }


  setItemQueryParams(): void {
    this.item.queryParams = {
      userId: sessionStorage.getItem('userId'),
      userMail: sessionStorage.getItem('userMail'),
      userName: sessionStorage.getItem('userName'),
      sessionId: sessionStorage.getItem('sessionId')
    }
  }


  closeItem(): void {
    this.item.expanded = false;
  }


}
