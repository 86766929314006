import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {SubstanceSet} from '../../models/substance-set';

@Component({
  selector: 'app-update-set-dialog',
  templateUrl: './update-set-dialog.component.html',
  styleUrls: ['./update-set-dialog.component.css']
})
export class UpdateSetDialogComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<UpdateSetDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: SubstanceSet
  ) { }

  ngOnInit(): void {
    console.log('data received: ', this.data);
  }

  cancel(): void {
    this.dialogRef.close();
  }

  remove(index: number): void{
    this.data.substances.splice(index, 1);
  }

}
