import {AfterViewChecked, Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ChartDataset, ChartOptions, ChartType} from 'chart.js';
import {ActivatedRoute} from '@angular/router';
import domtoimage from 'dom-to-image';
import {ToastrService} from 'ngx-toastr';
import {ServerService} from '../../core/server.service';
import {ExceptionService} from '../../core/exception.service';
import {WorkArea} from '../models/work-area';
import {CategoryRisk} from '../models/category-risk';
import {RegionCategory} from '../models/region-category';
import {PbtAssessment} from '../models/pbt-assessment';
import {Region} from '../../shared/region';
import {VaultService} from "../../ai-compare/service/vault.service";
import * as FileSaver from "file-saver";

let me: SpiderPageComponent;

@Component({
  selector: 'app-spider-page',
  templateUrl: './spider-page.component.html',
  styleUrls: ['./spider-page.component.css'],
})
export class SpiderPageComponent
  implements AfterViewChecked,
    OnInit,
    OnDestroy {
  public chartType: ChartType;
  public labels: any[];
  public options: ChartOptions;
  public data: ChartDataset[];
  private evaluationId: string;
  public isLoading: boolean;
  private evaluationName: string;
  private riskLevelMap: Map<string, number>;
  private sessionId: string;
  @ViewChild('chartParent') chartParent: ElementRef;
  private diagramContainer: any;
  public categories: Array<string>;
  private readonly workAreaCategories: Map<string, Array<string>>;
  public showPbtAssessment: boolean;
  private categoryRisks: Array<CategoryRisk>;
  private filteredCategoryRisks: Array<CategoryRisk>;
  public isHidden: boolean;
  private region: string;
  public categoryColors: Map<string, string>;
  public plugins: any;
  private readonly subscriptions: any;

  constructor(
    private route: ActivatedRoute,
    private exceptionService: ExceptionService,
    private serverService: ServerService,
    private toastrService: ToastrService,
    private exportService: VaultService
  ) {
    me = this;
    this.categoryColors = new Map();
    this.categoryColors.set('Environmental Fate', 'black');
    this.categoryColors.set('Ecotoxicology', '#555555');
    this.categoryColors.set('Consumer Safety', 'black');
    this.categoryColors.set('Toxicology', '#555555');
    this.categoryColors.set('PBT Assessment', 'grey');

    this.categoryRisks = Array();
    this.subscriptions = {};

    this.isLoading = true;
    this.chartType = 'radar';
    const greyColor = Array(32).fill('#808080');
    const greenColor = Array(124).fill('#15FF00');
    const yellowColor = Array(160).fill('#FFE600');
    const orangeColor = Array(160).fill('#FF7300');
    const redColor = Array(160).fill('#FF0F00');
    const whiteColor = Array(159).fill('#FFFFFF');
    const blackColor = ['#000000'];

    this.options = {
      responsive: true,
      aspectRatio: 1.8,
      plugins: {
        title: {
          display: false,
          text: 'Spider web report',
        },
        legend: {
          labels: { // title
            font: {
              size: 16,
            },
            padding: 10,
            usePointStyle: true,
            boxWidth: 12,
            color: 'black',
          },
          position: 'right',
          align: 'start',
          fullSize: false,
        },
      },
      elements: {
        point: {
          radius: 6,
          hoverRadius: 6
        },
        line: {
          borderWidth: 3
        }
      },
      scales: {
        r: {
          beginAtZero: true,
          max: 4,
          min: 0,
          ticks: {
            display: false,
            stepSize: 0.00625,
          },
          pointLabels: {
            font: {
              size: 16,
              lineHeight: 1.5,
              weight: 'bold',
            },
            color: 'black',
            backdropPadding: 2
          },
          grid: {
            display: true,
            circular: true,
            color: [
              ...greyColor,
              ...greenColor,
              ...yellowColor,
              ...orangeColor,
              ...redColor,
              ...whiteColor,
              ...blackColor
            ],
          },
          angleLines: {
            color: 'rgba(255, 255, 255, 0.5)'
          }
        }
      },
    };

    this.plugins = [{
      beforeUpdate: chart => {
        // update point label background
        // chart.config.options.scales.r.pointLabels.backdropColor = this.filteredCategoryRisks
        //   .map((it) => this.categoryColors.get(it.workArea));

        // update point label color
        chart.config.options.scales.r.pointLabels.color = this.filteredCategoryRisks
          .map((it) => this.categoryColors.get(it.workArea));

        // update point label padding
        chart.config.options.scales.r.pointLabels.padding = this.getPaddingList(this.filteredCategoryRisks);
      }
    }];

    this.route.paramMap.subscribe((params) => {
      this.evaluationId = params.get('id');
      this.sessionId = params.get('sessionId');
    });

    this.riskLevelMap = new Map<string, number>();
    this.workAreaCategories = new Map<string, Array<string>>();
    this.showPbtAssessment = false;
    this.categoryRisks = Array();
    this.filteredCategoryRisks = Array();
    this.isHidden = false;
    this.region = '';
  }

  ngOnInit(): void {
    this.riskLevelMap.set('1__c', 0.5);
    this.riskLevelMap.set('2__c', 1.5);
    this.riskLevelMap.set('3__c', 2.5);
    this.riskLevelMap.set('4__c', 3.5);
    this.riskLevelMap.set('5__c', 4.5);

    this.subscriptions.postEvaluation = this.serverService
      .postEvaluation(this.sessionId, this.evaluationId)
      .subscribe(
        (response) => {
          this.evaluationName = this.getEvaluationName(response);
          this.region = this.getEvaluationRegion(response);

          this.subscriptions.getRegionCategories = this.serverService
            .getRegionCategories(this.sessionId, this.region)
            .subscribe(
              (response1) => {
                response1.data
                  .sort((a, b) => a['work_area__cr.order_number__c'] > b['work_area__cr.order_number__c'] ? 1 : -1)
                  .forEach((element: RegionCategory) => {
                    if (
                      this.workAreaCategories.has(
                        element['work_area__cr.name__v']
                      )
                    ) {
                      this.workAreaCategories
                        .get(element['work_area__cr.name__v'])
                        .push(element.name__v);
                    } else {
                      const list = Array();
                      list.push(element.name__v);
                      this.workAreaCategories.set(
                        element['work_area__cr.name__v'],
                        list
                      );
                    }
                  });
                this.categoryRisks = this.getCategoryRisks(
                  response,
                  this.workAreaCategories
                );
                if (this.region === Region.EU) {
                  const pbtAssessmentCategoryRisk = this.createPbtAssessmentCategoryRisk(response);
                  if (pbtAssessmentCategoryRisk !== undefined) {
                    this.showPbtAssessment = true;
                    this.categoryRisks.push(pbtAssessmentCategoryRisk);
                  }
                }
                this.filteredCategoryRisks = this.filterCategories(
                  this.categoryRisks
                );
                this.labels = this.getLabels(this.filteredCategoryRisks);
                this.categories = this.getCategories(
                  this.filteredCategoryRisks
                );
                this.data = this.getData(this.filteredCategoryRisks);
                this.isLoading = false;
              },
              (error) => {
                this.exceptionService.handleError(error);
                this.toastrService.error(
                  'Unable to get categories for a work area.',
                  'Error'
                );
              }
            );
        },
        (error) => {
          this.isLoading = false;
          this.exceptionService.handleError(error);
          this.toastrService.error('Unable to get evaluation data.', 'Error');
        },
        () => console.log('Get evaluation request completed.')
      );
  }

  ngAfterViewChecked(): void {
    if (this.chartParent != null) {
      this.diagramContainer = this.chartParent.nativeElement;
    }
  }

  ngOnDestroy(): void {
    // prevent memory leak when component destroyed
    for (const subscription in this.subscriptions) {
      if (this.subscriptions.hasOwnProperty(subscription)) {
        this.subscriptions[subscription].unsubscribe();
      }
    }
  }

  getCategories(categoryRisks: Array<CategoryRisk>): Array<string> {
    const list = Array();
    for (const categoryRisk of categoryRisks) {
      list.push(categoryRisk.category);
    }

    return list;
  }

  getLabels(list: Array<CategoryRisk>): Array<string> {
    const labels = new Array<string>();
    for (const categoryRisk of list) {
      labels.push(categoryRisk.category);
    }
    return labels;
  }

  getEvaluationName(data: any): string {
    if (data.length > 0 && data[1].data.length > 0) {
      return data[1].data[0]['evaluation__cr.name__v'];
    } else {
      return 'Evaluation';
    }
  }

  getEvaluationRegion(data: any): string {
    if (data.length > 0 && data[1].data.length > 0) {
      return data[1].data[0]['region__cr.name__v'];
    } else {
      return Region.EU;
    }
  }

  getCategoryRisks(
    data: any,
    workAreaCategories: Map<string, any[]>
  ): Array<CategoryRisk> {
    const categoryRisks = Array();
    const categoryValueEntries = data[1].data;

    workAreaCategories.forEach((categories, workAreaName) => {
      categories.forEach((category) => {
        categoryRisks.push({
          workArea: workAreaName,
          category,
          currentRisk: 0,
          futureRisk: 0,
        });
      });
    });

    categoryRisks.forEach((element: any) => {
      categoryValueEntries.forEach((entry: WorkArea) => {
        if (
          element.category.toLowerCase() ===
          entry.substance_category__cr.data[0].name__v.toLowerCase()
        ) {
          element.currentRisk = this.getRiskLevelValue(entry.current__c !== null ? entry.current__c[0] : '');
          element.futureRisk = this.getRiskLevelValue(entry.future__c !== null ? entry.future__c[0] : '');
        }
      });
    });

    return categoryRisks;
  }

  getRiskLevelValue(level: string): number {
    return this.riskLevelMap.get(level) || 0;
  }

  getData(categoryRisks: Array<CategoryRisk>): ChartDataset[] {
    const currentRisks: Array<number> = Array();
    const futureRisks: Array<number> = Array();

    categoryRisks.forEach((categoryRisk) => {
      currentRisks.push(categoryRisk.currentRisk);
      futureRisks.push(categoryRisk.futureRisk);
    });

    return [
      {
        label: this.evaluationName + ' (current)',
        borderColor: 'rgba(0, 0, 0, 1.0)',
        backgroundColor: 'rgba(255, 255, 255, 0.0)',
        pointBorderColor: 'rgba(255, 255, 255, 1.0)',
        pointBackgroundColor: 'rgba(0, 0, 0, 1.0)',
        data: currentRisks,
      },
      {
        label: this.evaluationName + ' (future)',
        borderColor: 'rgba(69, 137, 216, 1.0)',
        backgroundColor: 'rgba(255, 255, 255, 0.0)',
        pointBorderColor: 'rgba(255, 255, 255, 1.0)',
        pointBackgroundColor: 'rgba(69, 137, 216, 1.0)',
        data: futureRisks,
      },
    ];
  }

  saveDiagram(): void {
    this.isHidden = true;
    domtoimage
      .toJpeg(this.diagramContainer, {quality: 1.0, bgcolor: '#ffffff'})
      .then((dataUrl: string) => {
        const link = document.createElement('a');
        link.download = this.generateFileName();
        link.href = dataUrl;
        link.click();
      })
      .catch((error: any) => {
        console.error('Unable to download diagram!', error);
        this.exceptionService.handleError(error);
        this.toastrService.error('Unable to download diagram!', 'Error');
      });
    setTimeout(() => {
      this.isHidden = false;
    }, 1);
  }

  uploadDiagram(): void {
    this.isHidden = true;
    domtoimage
      .toJpeg(this.diagramContainer, {quality: 0.95, bgcolor: '#ffffff'})
      .then((dataUrl: string) => {
        this.subscriptions.postEvaluationImage = this.serverService
          .postEvaluationImage(
            this.sessionId,
            this.evaluationId,
            dataUrl,
            this.generateFileName()
          )
          .subscribe(
            () => {
              this.toastrService.success(
                'Saved diagram as attachment!',
                'Success'
              );
            },
            (error) => {
              console.error(error);
              this.toastrService.error('Unable to save diagram!', 'Error');
            },
            () => console.log('Post evaluation image request completed.')
          );
      });

    setTimeout(() => {
      this.isHidden = false;
    }, 1);
  }

  generateFileName(): string {
    return this.evaluationName + '.jpg';
  }

  filterCategories(categoryRisks: Array<CategoryRisk>): Array<CategoryRisk> {
    const filteredList: Array<CategoryRisk> = Array();

    categoryRisks.forEach((categoryRisk) => {
      if (
        (categoryRisk.workArea.toLowerCase() === 'consumer safety') ||
        (categoryRisk.workArea.toLowerCase() === 'ecotoxicology') ||
        (categoryRisk.workArea.toLowerCase() === 'environmental fate') ||
        (categoryRisk.workArea.toLowerCase() === 'toxicology') ||
        (categoryRisk.workArea.toLowerCase() === 'pbt assessment')
      ) {
        filteredList.push(categoryRisk);
      }
    });

    return filteredList;
  }

  createPbtAssessmentCategoryRisk(response: any): CategoryRisk {
    if (response.length > 0 && response[2].data.length > 0) {
      const pbtAssessment: PbtAssessment = response[2].data[0];
      return {
        workArea: 'PBT Assessment',
        category: 'PBT Assessment',
        currentRisk: this.getRiskLevelValue(pbtAssessment.current_risk_score__c !== null ?
          pbtAssessment.current_risk_score__c[0] : ''),
        futureRisk: this.getRiskLevelValue(pbtAssessment.future_risk_score__c !== null ?
          pbtAssessment.future_risk_score__c[0] : ''),
      };
    }
    return;
  }

  getPaddingList(categoryRisks: CategoryRisk[]): number[] {
    switch (this.region) {
      case Region.EU: {
        return this.getPaddingForEu(categoryRisks);
      }
      case Region.APA:
      case Region.APS: {
        return this.getPaddingForApaAps(categoryRisks);
      }
      case Region.APN: {
        return this.getPaddingForApn(categoryRisks);
      }
      default:
        return [];
    }
  }

  getPaddingForEu(categoryRisks: CategoryRisk[]): number[] {
    const defaultPadding = Array(categoryRisks.length).fill(0);
    defaultPadding[0] = 15;
    defaultPadding[1] = 5;
    defaultPadding[16] = 5;
    defaultPadding[17] = 20;
    defaultPadding[18] = 5;
    defaultPadding[33] = 5;
    defaultPadding[34] = 5;
    return [...defaultPadding];
  }

  getPaddingForApaAps(categoryRisks: CategoryRisk[]): number[] {
    const defaultPadding = Array(categoryRisks.length).fill(0);
    defaultPadding[0] = 20;
    defaultPadding[1] = 5;
    defaultPadding[15] = 10;
    defaultPadding[16] = 10;
    defaultPadding[30] = 5;
    return [...defaultPadding];
  }

  getPaddingForApn(categoryRisks: CategoryRisk[]): number[] {
    const defaultPadding = Array(categoryRisks.length).fill(0);
    defaultPadding[0] = 15;
    defaultPadding[14] = 15;
    return [...defaultPadding];
  }

  async saveChartToLocalStorage(): Promise<any> {

    let chartContainer = document.getElementById('radar-chart');
    try {
      const dataUrl = await domtoimage.toPng(chartContainer, {quality: 1.0, bgcolor: '#ffffff'})
      sessionStorage.setItem('radar-chart.png', dataUrl);
    } catch (error) {
      if (error) {
        console.log('error saving chart to sessionStorage: ', error.message);
      }
    }
  }

  async export() {
    await this.saveChartToLocalStorage();
    let radarBase = sessionStorage.getItem('radar-chart.png');
    const today = new Date();
    const fileName = 'report' + '-' + today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate()
      + this.evaluationName + '.xlsx';
    this.exportService.exportEvaluationToExcel(radarBase, this.evaluationId).subscribe(response => {
      let blob = new Blob([response], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8"'});
      FileSaver.saveAs(blob, fileName);
    })
  }
}
