import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {ChartType, LegendItem} from "chart.js";

export interface DatasetIndexes {
  previousDataIndex: number | undefined,
  currentDataIndex: number | undefined
}

@Component({
  selector: 'app-legend-item',
  templateUrl: './legend-item.component.html',
  styleUrls: ['./legend-item.component.css']
})
export class LegendItemComponent implements OnInit, OnChanges {

  @Input() legendItems: LegendItem[] = [];
  @Input() index: number;
  @Input() numOfDropdowns: number;
  @Input() chartType: ChartType;
  selectedItem: LegendItem;

  availableLegendItems: LegendItem[];
  placeholder: string;

  @Output() itemsEmitted: EventEmitter<DatasetIndexes> = new EventEmitter<DatasetIndexes>();

  barChartColors = ['rgba(0,40,117, 1.0)', 'rgba(0,74,150, 1.0)', 'rgba(68, 114, 170, 1.0)', 'rgba(124,160,198, 1.0)',
    'rgba(166, 192, 218,1.0)', 'rgba(224, 233, 242, 1.0)'];

  radarChartColors = ['rgba(0,74,150, 1.0)', 'rgba(124,160,198, 1.0)', 'rgba(224, 233, 242, 1.0)', 'rgba(0,0,0,1)'];

  constructor() {
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.legendItems.firstChange) {
      if (this.index < this.numOfDropdowns - 2) {
        this.selectedItem = this.legendItems[this.index];
      }
    } else {
      if (this.selectedItem != undefined) {
      }
    }
  }


  onSelectionChange(event: any) {
    let previousIndex;
    let currentIndex;
    if (this.selectedItem === undefined) {
      previousIndex = undefined;
    } else {
      previousIndex = this.selectedItem.datasetIndex;
    }
    if (event === undefined) {
      currentIndex = undefined;
    } else {
      currentIndex = event.datasetIndex;
    }

    let data = {
      previousDataIndex: previousIndex,
      currentDataIndex: currentIndex
    };
    this.itemsEmitted.emit(data);
    if (event != undefined) {
      this.selectedItem = event;
      this.selectedItem.hidden = false;
    }
    if (previousIndex != undefined) {
      this.legendItems[previousIndex].hidden = true;
    }
  }

  getDotStyle(type: ChartType) {
    if (type === 'radar') {
      return {
        'height': '25px',
        'width': '25px',
        'border-radius': '50%',
        'display': 'inline-flex',
        'margin-right': '20px',
        'margin-top': '20px'
      }
    } else {
      return {
        'height': '25px',
        'width': '10px',
        'display': 'inline-flex',
        'margin-right': '10px',
        'margin-top': '20px',
        'margin-bottom': '0px',
        'padding-bottom': '0px'
      }
    }
  }

  getDropdownStyle(type: ChartType) {
    if (type === 'radar') {
      return {
        'min-width': '350px',
        'max-width': '350px'
      }
    } else {
      return {
        'min-width': '250px',
        'max-width': '250px',
        'margin-right': '20px'
      }
    }
  }

}
