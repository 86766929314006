import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {PicklistValue} from '../../../models/picklist-value';
import {ConsumerSafetyEndpointTableItem} from '../../models/consumer-safety-endpoint-table-item';
import {DocumentMenuItem} from '../../../models/document-menu-item';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute} from '@angular/router';
import {ExceptionService} from '../../../../core/exception.service';
import {ServerService} from '../../../../core/server.service';
import {ToastrService} from 'ngx-toastr';
import {ConsumerSafetyExisting} from '../../models/consumer-safety-existing';
import {convertArrayToString} from '../../../../shared/convert-array-to-string';
import {CreateObjectResponse, CreateObjectsResponse} from '../../../models/create-objects-response';
import {VaultError} from '../../../../core/models/vault-error';
import {HttpErrorResponse} from '@angular/common/http';
import {ConsumerSafetyCategoryPicklists} from "../../models/consumer-safety-category-picklists";
import {convertSpecialFieldsToArray, convertSpecialFieldsToString} from "../../../../shared/convert-special-fields";

let me: WaiverAndDataGapsExistingComponent;

@Component({
  selector: 'app-waiver-and-data-gaps-existing',
  templateUrl: './waiver-and-data-gaps-existing.component.html',
  styleUrls: ['../../../existing_record.css']
})
export class WaiverAndDataGapsExistingComponent implements OnInit {
  @Input() arrayedEndpointRecord: ConsumerSafetyExisting;
  @Input() category: any;
  @Input() categoryPicklists: Array<ConsumerSafetyCategoryPicklists>;
  @Input() allStudyTypes: Array<PicklistValue>;
  @Input() substanceTypes: Array<PicklistValue>;
  @Input() relatedSubstances: any;
  @Input() documents: Array<DocumentMenuItem>;
  @Input() allUsers: any;

  @Output() removeInput: EventEmitter<ConsumerSafetyEndpointTableItem> = new EventEmitter();
  @Output() copyExisting: EventEmitter<any> = new EventEmitter();

  subscriptions: any;
  selectFormGroup: FormGroup;
  configureFormGroup: FormGroup;
  public existingEndpointRecord: any;
  public arrayToSave: any;
  public userName: any;
  public createdBy: any;
  public modifiedBy: any;
  public formatCreatedDate: string;
  public formatModifiedDate: string;
  public waiverDataGaps: any;
  showExistingRecord = true;
  showEditRecord = false;
  public purposeMessage: string;
  private sessionId: string;

  constructor(
    private route: ActivatedRoute,
    private exceptionService: ExceptionService,
    private serverService: ServerService,
    private toastrService: ToastrService,
    private formBuilder: FormBuilder
  ) {
    me = this;
    this.subscriptions = {};
    this.route.paramMap.subscribe((params) => {
      this.sessionId = params.get('sessionId');
    });
    this.selectFormGroup = this.formBuilder.group({
      category__c: [{value: 'Acute Toxicity', disabled: true}],
      studyType: [{value: '', disabled: false}, Validators.required],
      waiverdata_gap__c: [{value: '', disabled: false}],
      comment__c: [{value: '', disabled: false}],
      // default
      source: [{value: '', disabled: false}],
    });
    this.waiverDataGaps = [{label: 'Waiver', name: 'waiver__c'}, {label: 'Data Gap', name: 'data_gap__c'}];
    this.purposeMessage = 'editOnExisting';
  }

  ngOnInit(): void {
    this.selectFormGroup.disable();

    // convert record values to string values
    this.existingEndpointRecord = JSON.parse(
      JSON.stringify(this.arrayedEndpointRecord)
    );

    convertArrayToString(
      this.arrayedEndpointRecord,
      this.existingEndpointRecord,
      []
    );

    convertSpecialFieldsToArray(this.existingEndpointRecord, ['study_type__c']);

    if (this.existingEndpointRecord.created_by__v === 1) {
      this.createdBy = [{name: 'System'}];
    } else {
      this.createdBy = this.allUsers.filter(
        (c) => c.id === this.existingEndpointRecord.created_by__v
      );
    }

    if (this.existingEndpointRecord.modified_by__v === 1) {
      // eslint-disable-next-line quote-props
      this.modifiedBy = [{name: 'System'}];
    } else {
      this.modifiedBy = this.allUsers.filter(
        (m) => m.id === this.existingEndpointRecord.modified_by__v
      );
    }

    this.formatCreatedDate =
      this.existingEndpointRecord.created_date__v.substring(0, 10) +
      ', ' +
      this.existingEndpointRecord.created_date__v.substring(11, 16) +
      'h Z';
    this.formatModifiedDate =
      this.existingEndpointRecord.modified_date__v.substring(0, 10) +
      ', ' +
      this.existingEndpointRecord.modified_date__v.substring(11, 16) +
      'h Z';

    this.selectFormGroup.get('source').setValue(this.existingEndpointRecord.document_unbound__c);
    this.setFormFields();
  }



  setFormFields(): void {
    this.selectFormGroup.get('category__c').setValue(this.existingEndpointRecord.category__c);
    if (this.existingEndpointRecord.study_type__c) {
      this.selectFormGroup.get('studyType').setValue(this.existingEndpointRecord.study_type__c);
    }

    this.selectFormGroup.get('waiverdata_gap__c').setValue(this.existingEndpointRecord.waiverdata_gap__c);
    this.selectFormGroup.get('comment__c').setValue(this.existingEndpointRecord.comment__c);
    // default
    this.selectFormGroup.get('source').setValue(this.existingEndpointRecord.document_unbound__c);
  }

  enableEditing(): any {
    this.showExistingRecord = false;
    this.showEditRecord = true;
  }

  cancelEditing(): void {
    this.showExistingRecord = true;
    this.showEditRecord = false;
  }

  saveChanges(endpointRecord): void {
    this.serverService.isLoading();

    delete endpointRecord.created_by__v;
    delete endpointRecord.modified_by__v;
    delete endpointRecord.created_date__v;
    delete endpointRecord.modified_date__v;

    this.arrayToSave = endpointRecord;
    convertSpecialFieldsToString(this.arrayToSave, ['study_type__c']);

    const body = Array(this.arrayToSave);

    this.serverService.updateEndpoint(this.sessionId, body).subscribe(
      (success: CreateObjectsResponse) => {
        let updated = 0;
        success.data?.forEach((record: CreateObjectResponse) => {
          if (record?.responseStatus === 'SUCCESS') {
            updated++;
          }
          if (record?.responseStatus === 'FAILURE') {
            record?.errors?.forEach((error: VaultError) => {
              this.toastrService.error(error.message, error.type);
            });
          }
        });

        this.toastrService.info(
          ` Endpoint updated successfully.`,
          'Info'
        );
        this.serverService.isNotLoading();
      },
      (error: HttpErrorResponse) => {
        this.exceptionService.handleError(error);
        this.toastrService.error(
          `An error occurred while updating endpoint.`,
          'Error'
        );
        this.serverService.isNotLoading();
      }
    );

    this.showExistingRecord = true;
    this.showEditRecord = false;
    convertSpecialFieldsToArray(endpointRecord, ['study_type__c']);
    this.existingEndpointRecord = endpointRecord;
    console.log('updated record: ' + JSON.stringify(this.existingEndpointRecord));
    this.setFormFields();
  }

  copyExistingEndpoint(endpoint: ConsumerSafetyExisting): any {
    this.copyExisting.emit(endpoint);
  }



}
