import {Component, ElementRef, Input, OnChanges, OnInit, ViewChild} from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';
import {ServerService} from 'src/app/core/server.service';
import {ToastrService} from 'ngx-toastr';
import {ExceptionService} from 'src/app/core/exception.service';
import {ActivatedRoute} from '@angular/router';
import {PicklistValue} from '../../models/picklist-value';
import {ToxCategoryPicklists} from '../../models/tox-category-picklist';
import {ToxAdditionalField} from '../../models/tox-additional-field';
import {ToxExistingEndpoint} from '../../models/tox-existing-endpoint';
import {DocumentMenuItem} from '../../models/document-menu-item';
import {SubstanceItem} from '../../models/substance-item';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {MatAutocomplete, MatAutocompleteSelectedEvent} from '@angular/material/autocomplete';
import {MatChipInputEvent} from '@angular/material/chips';
import {PageEvent} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';

@Component({
  selector: 'app-existing-tox-endpoints',
  templateUrl: './existing-tox-endpoints.component.html',
  styleUrls: ['./existing-tox-endpoints.component.css'],
})

export class ExistingToxEndpointsComponent implements OnInit,OnChanges {
  dataSource: MatTableDataSource<any>;
  data: Array<any>;
  pageSize: number;
  pageSkip: number;
  dataSourceLength: number;
  loaderHidden = true;
  searchButtonHidden = false;

  // for filters
  visible = true;
  selectable = true;
  removable = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  fieldCtrl = new FormControl();
  filteredFields: Observable<string[]>;
  fields: string[] = ['Category', 'Related Substance'];
  public allFields: string[];

  @ViewChild('fieldInput') fieldInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto') matAutocomplete: MatAutocomplete;

  /**
   * for documents filtering
   */
  searchUserModifyControl: FormControl = new FormControl();
  searchUserCreateControl: FormControl = new FormControl();
  filteredCreateUsers: Observable<any>;
  filteredModifiedUsers: Observable<any>;
  /** */

  subscriptions: any;
  public panelOpenState = false;
  public expandPanel = false;
  public category__c: any;
  public substance_type__c: any;
  public related_substance__c: any;
  public study_type__c: any;
  public test_system_type__c: any;
  public test_system_name__c: any;
  public endpoint__c: any;
  public endpoint_unit__c: any;
  public operator__c: any;
  public value_1__c: number;
  public value_2__c: number;
  public created_by__v: any;
  public modified_by__v: any;
  public selectedDocumentId: number;
  // additional picklist fields
  public study_endpoint__c: string;
  public study__c: string;
  public crops__c: string;
  public application_method__c: string;
  public ppe_required__c: string;
  public additional_fqpa_us_epa__c: string;
  public species__c: string;
  public tumor_site_female__c: string;
  public tumor_site_male__c: string;
  public chromosome_aberration_hamster__c: string;
  public chromosome_aberration_mouse__c: string;
  public chromosome_aberration_rat__c: string;
  public uds_in_vivo__c: string;
  public micronucleus_test_mouse__c: string;
  public micronucleus_test_rat__c: string;
  public micronucleus_test_in_vitro__c: string;
  public comet_assay_in_vivo__c: string;
  public uds_in_vitro__c: string;
  public chromosome_aberration_nonhuman_cells__c: string;
  public chromosome_aberration_human_lymphocytes__c: string;
  public mouse_lymphoma_assay__c: string;
  public hprt_test__c: string;
  public ames_test__c: string;
  public target_organ__c: string;
  public test_method_skin_sensitization__c: string;
  public route_of_administration__c: string;
  public transgenic_rodent_assay_in_vitro__c: string;
  public comet_assay_in_vitro__c: string;
  public transgenic_rodent_assay_in_vivo__c: string;
  public ed_mechanistic_study__c: string;
  public eats_and_developmental_parameters_affect__c: string;
  public mechanistic_studies__c: string;
  public mechanism_tmodality__c: string;
  public ed_modality__c: string;
  public loael_immunotoxicity_mgkg_bwd__c: string;
  public noael_immunotoxicity_mgkg_bwd__c: string;
  public nk_cell_activity_test__c: string;
  public lymphocyte_subpopulation__c: string;
  public srbc_assay__c: string;
  public t_trigger__c: string;
  public qstar_tumor_site__c: string;
  public us_cancer_ra__c: string;

  // mutual fields
  public created_date_start: number;
  public created_date_end: number;
  public modified_date_start: number;
  public modified_date_end: number;

  @Input() receivedCategoryPicklists: Array<ToxCategoryPicklists>;
  @Input() workAreaName: string;
  @Input() workAreaId: string;
  @Input() substance: SubstanceItem;
  @Input() receivedCategories: Array<PicklistValue>;
  @Input() receivedAllStudyTypes: Array<PicklistValue>;
  @Input() receivedAllTestSystemTypes: Array<PicklistValue>;
  @Input() receivedAllRouteOfAdministration: Array<PicklistValue>;
  @Input() receivedAllTestSystemNames: Array<PicklistValue>;
  @Input() receivedAllEndpoints: Array<PicklistValue>;
  @Input() receivedAllEndpointUnits: Array<PicklistValue>;
  @Input() receivedAllOperators: Array<PicklistValue>;
  @Input() receivedAllAdditionalFields: Array<ToxAdditionalField>;
  @Input() receivedAdditionalFieldValues: Array<ToxAdditionalField>;
  @Input() substanceTypes: Array<PicklistValue>;
  @Input() relatedSubstances: any;
  @Input() documents: Array<DocumentMenuItem>;
  public existingEndpoints: Array<ToxExistingEndpoint>;
  public uniqueAdditionalFields: Array<ToxAdditionalField>;
  public allUsers: any;
  public queryStringConstructor: any;
  public queryStringClause: any;
  public sortByFieldName: string;
  public sortByOrder: string;
  // created date range
  createdRange = new FormGroup({
    createdDateStart: new FormControl(),
    createdDateEnd: new FormControl(),
  });
  // modified date range
  modifiedRange = new FormGroup({
    modifiedDateStart: new FormControl(),
    modifiedDateEnd: new FormControl(),
  });
  pageEvent: PageEvent;
  public uniqueStudyTypes: Array<PicklistValue>;
  public uniqueTestSystemTypes: Array<PicklistValue>;
  public uniqueTestSystemNames: Array<PicklistValue>;
  public uniqueEndpoints: Array<PicklistValue>;
  public uniqueEndpointUnits: Array<PicklistValue>;
  public uniqueAdditionalFieldValues: Array<ToxAdditionalField>;
  public All: string;
  private sessionId: string;

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private serverService: ServerService,
    private exceptionService: ExceptionService,
    private toastrService: ToastrService
  ) {
    this.route.paramMap.subscribe((params) => {
      this.sessionId = params.get('sessionId');
    });
    this.uniqueAdditionalFields = [];
    this.existingEndpoints = [];
    this.uniqueStudyTypes = [];
    this.uniqueTestSystemTypes = [];
    this.uniqueTestSystemNames = [];
    this.uniqueEndpoints = [];
    this.uniqueEndpointUnits = [];
    this.uniqueAdditionalFieldValues = [];
    this.sortByFieldName = 'id';
    this.sortByOrder = 'DESC';
    this.allUsers = [];
    this.All = 'All';
    this.queryStringConstructor = [];
    this.queryStringClause = '';
    this.dataSource = new MatTableDataSource();
    this.pageSize = 10;
    this.pageSkip = 0;
    this.dataSourceLength = 0;
    this.category__c = '';
    this.substance_type__c = '';
    this.related_substance__c = '';
    this.study_type__c = '';
    this.test_system_type__c = '';
    this.test_system_name__c = '';
    this.endpoint__c = '';
    this.endpoint_unit__c = '';
    this.operator__c = '';
    this.value_1__c = null;
    this.value_2__c = null;
    // additional fields
    this.study_endpoint__c = '';
    this.study__c = '';
    this.crops__c = '';
    this.application_method__c = '';
    this.ppe_required__c = '';
    this.additional_fqpa_us_epa__c = '';
    this.species__c = '';
    this.tumor_site_female__c = '';
    this.tumor_site_male__c = '';
    this.chromosome_aberration_hamster__c = '';
    this.chromosome_aberration_mouse__c = '';
    this.chromosome_aberration_rat__c = '';
    this.uds_in_vivo__c = '';
    this.micronucleus_test_mouse__c = '';
    this.micronucleus_test_rat__c = '';
    this.micronucleus_test_in_vitro__c = '';
    this.comet_assay_in_vivo__c = '';
    this.uds_in_vitro__c = '';
    this.chromosome_aberration_nonhuman_cells__c = '';
    this.chromosome_aberration_human_lymphocytes__c = '';
    this.mouse_lymphoma_assay__c = '';
    this.hprt_test__c = '';
    this.ames_test__c = '';
    this.target_organ__c = '';
    this.test_method_skin_sensitization__c = '';
    this.route_of_administration__c = '';
    this.transgenic_rodent_assay_in_vitro__c = '';
    this.comet_assay_in_vitro__c = '';
    this.transgenic_rodent_assay_in_vivo__c = '';
    this.ed_mechanistic_study__c = '';
    this.eats_and_developmental_parameters_affect__c = '';
    this.mechanistic_studies__c = '';
    this.mechanism_tmodality__c = '';
    this.ed_modality__c = '';
    this.loael_immunotoxicity_mgkg_bwd__c = '';
    this.noael_immunotoxicity_mgkg_bwd__c = '';
    this.nk_cell_activity_test__c = '';
    this.lymphocyte_subpopulation__c = '';
    this.srbc_assay__c = '';
    this.t_trigger__c = '';
    this.qstar_tumor_site__c = '';
    this.us_cancer_ra__c = '';

    // mutual fields
    this.created_by__v = '';
    this.modified_by__v = '';
    this.selectedDocumentId = null;
    this.created_date_start = null;
    this.created_date_end = null;
    this.modified_date_start = null;
    this.modified_date_end = null;

    /// for filters
    this.allFields = [
      'Category',
      'Related Substance',
      'Substance Type',
      'Created Date Range',
      'Modified Date Range',
      'Source',
      'Created By',
      'Last Modified By',
      'Study Type',
      'Test System Type',
      'Test Species',
      'Endpoint',
      'Endpoint Unit',
      'Operator',
      'Value 1',
      'Value 2',
      'Study Endpoint',
      'Study',
      'Crops',
      'Application Method',
      'PPE required ?',
      'Additional FQPA (US EPA)',
      'Transgenic Rodent Assay, in vitro',
      'Comet Assay, in vitro',
      'Transgenic Rodent Assay, in vivo',
      'Classification (proposed, vulnerable or classified)',
      'ED Mechanistic Study',
      'Additional UF',
      'Species',
      'Tumor Site (female)',
      'Tumor Site (male)',
      'Chromosome Aberration, Hamster',
      'Chromosome Aberration, Mouse',
      'Chromosome Aberration, Rat',
      'UDS, in vivo',
      'Micronucleus Test, Mouse',
      'Micronucleus Test, Rat',
      'Micronucleus Test, in vitro',
      'Comet Assay, in vivo',
      'UDS, in vitro',
      'Chromosome Aberration, Non-Human Cells',
      'Chromosome Aberration, Human Lymphocytes',
      'Mouse Lymphoma Assay',
      'HPRT Test',
      'Ames Test',
      'Target Organ',
      'Test Method Skin Sensitization',
      'Route of Administration',
      'EATS and Developmental Parameters Affected',
      'Mechanistic Studies',
      'Mechanism T-Modality',
      'ED Modality',
      'LOAEL Immunotoxicity (mg/kg bw/d)',
      'NOAEL Immunotoxicity (mg/kg bw/d)',
      'NK Cell Activity Test',
      'Lymphocyte Subpopulation',
      'SRBC Assay',
      'T Trigger',
      'Qstar Tumor Site',
      'US Cancer RA',
    ];

    this.filteredFields = this.fieldCtrl.valueChanges.pipe(
      startWith(null),
      map((field: string | null) =>
        field ? this._filter(field) : this.allFields.slice()
      )
    );
  }

  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    // Add field
    if ((value || '').trim()) {
      this.fields.push(value.trim());
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }

    this.fieldCtrl.setValue(null);
  }

  remove(field: string): void {
    // remove filter form
    document
      .getElementById('preparedFilterFields')
      .appendChild(document.getElementById(field));

    // remove chip
    const index = this.fields.indexOf(field);
    if (index >= 0) {
      this.fields.splice(index, 1);
    }

    // remove from array IF something was selected
    const queryIndex = this.queryStringConstructor
      .map(function(e) {
        return e.fieldLabel;
      })
      .indexOf(field);
    if (queryIndex >= 0) {
      this.queryStringConstructor.splice(queryIndex, 1);
    }

    // reset form selected value
    if (field == 'Category') {
      this.category__c = '';
    }
    if (field == 'Substance Type') {
      this.substance_type__c = '';
    }
    if (field == 'Related Substance') {
      this.related_substance__c = '';
    }
    if (field == 'Study Type') {
      this.study_type__c = '';
    }
    if (field == 'Test System Type') {
      this.test_system_type__c = '';
    }
    if (field == 'Test System Species') {
      this.test_system_name__c = '';
    }
    if (field == 'Endpoint') {
      this.endpoint__c = '';
    }
    if (field == 'Endpoint Unit') {
      this.endpoint_unit__c = '';
    }
    if (field == 'Operator') {
      this.operator__c = '';
    }
    if (field == 'Value 1') {
      this.value_1__c = null;
    }
    if (field == 'Value 2') {
      this.value_2__c = null;
    }
    if (field == 'Created By') {
      this.created_by__v = '';
    }
    if (field == 'Last Modified By') {
      this.modified_by__v = '';
    }
    if (field == 'Source') {
      this.selectedDocumentId = null;
    }
    // picklist fields
    if (field == 'Study Endpoint') {
      this.study_endpoint__c = '';
    }
    if (field == 'Study') {
      this.study__c = '';
    }
    if (field == 'Crops') {
      this.crops__c = '';
    }
    if (field == 'Application Method') {
      this.application_method__c = '';
    }
    if (field == 'PPE required ?') {
      this.ppe_required__c = '';
    }
    if (field == 'Additional FQPA (US EPA)') {
      this.additional_fqpa_us_epa__c = '';
    }
    if (field == 'Species') {
      this.species__c = '';
    }
    if (field == 'Tumor Site (female)') {
      this.tumor_site_female__c = '';
    }
    if (field == 'Tumor Site (male)') {
      this.tumor_site_male__c = '';
    }
    if (field == 'Chromosome Aberration, Hamster') {
      this.chromosome_aberration_hamster__c = '';
    }
    if (field == 'Chromosome Aberration, Mouse') {
      this.chromosome_aberration_mouse__c = '';
    }
    if (field == 'Chromosome Aberration, Rat') {
      this.chromosome_aberration_rat__c = '';
    }
    if (field == 'UDS, in vivo') {
      this.uds_in_vivo__c = '';
    }
    if (field == 'Micronucleus Test, Mouse') {
      this.micronucleus_test_mouse__c = '';
    }
    if (field == 'Micronucleus Test, Rat') {
      this.micronucleus_test_rat__c = '';
    }
    if (field == 'Micronucleus Test, in vitro') {
      this.micronucleus_test_in_vitro__c = '';
    }
    if (field == 'Comet Assay, in vivo') {
      this.comet_assay_in_vivo__c = '';
    }
    if (field == 'UDS, in vitro') {
      this.uds_in_vitro__c = '';
    }
    if (field == 'Chromosome Aberration, Non-Human Cells') {
      this.chromosome_aberration_nonhuman_cells__c = '';
    }
    if (field == 'Chromosome Aberration, Human Lymphocytes') {
      this.chromosome_aberration_human_lymphocytes__c = '';
    }
    if (field == 'Mouse Lymphoma Assay') {
      this.mouse_lymphoma_assay__c = '';
    }
    if (field == 'HPRT Test') {
      this.hprt_test__c = '';
    }
    if (field == 'Ames Test') {
      this.ames_test__c = '';
    }
    if (field == 'Target Organ') {
      this.target_organ__c = '';
    }
    if (field == 'Test Method Skin Sensitization') {
      this.test_method_skin_sensitization__c = '';
    }
    if (field == 'Route of Administration') {
      this.route_of_administration__c = '';
    }
    if (field == 'Transgenic Rodent Assay, in vitro') {
      this.transgenic_rodent_assay_in_vitro__c = '';
    }
    if (field == 'Comet Assay, in vitro') {
      this.comet_assay_in_vitro__c = '';
    }
    if (field == 'Transgenic Rodent Assay, in vivo') {
      this.transgenic_rodent_assay_in_vivo__c = '';
    }
    if (field == 'ED Mechanistic Study') {
      this.ed_mechanistic_study__c = '';
    }
    if (field == 'EATS and Developmental Parameters Affected') {
      this.eats_and_developmental_parameters_affect__c = '';
    }
    if (field == 'Mechanistic Studies') {
      this.mechanistic_studies__c = '';
    }
    if (field == 'Mechanism T-Modality') {
      this.mechanism_tmodality__c = '';
    }

    if (field == 'ED Modality') {
      this.ed_modality__c = '';
    }
    if (field == 'LOAEL Immunotoxicity (mg/kg bw/d)') {
      this.loael_immunotoxicity_mgkg_bwd__c = '';
    }
    if (field == 'NOAEL Immunotoxicity (mg/kg bw/d)') {
      this.noael_immunotoxicity_mgkg_bwd__c = '';
    }
    if (field == 'NK Cell Activity Test') {
      this.nk_cell_activity_test__c = '';
    }
    if (field == 'Lymphocyte Subpopulation') {
      this.lymphocyte_subpopulation__c = '';
    }
    if (field == 'SRBC Assay') {
      this.srbc_assay__c = '';
    }
    if (field == 'T Trigger') {
      this.t_trigger__c = '';
    }
    if (field == 'Qstar Tumor Site') {
      this.qstar_tumor_site__c = '';
    }
    if (field == 'US Cancer RA') {
      this.us_cancer_ra__c = '';
    }
    // number fields

    //

    if (field == 'Created Date Range') {
      this.createdRange.value.createdDateStart = null;
      this.createdRange.value.createdDateEnd = null;
      this.created_date_start = null;
      this.created_date_end = null;
    }
    if (field == 'Modified Date Range') {
      this.modifiedRange.value.modifiedDateStart = null;
      this.modifiedRange.value.modifiedDateEnd = null;
      this.modified_date_start = null;
      this.modified_date_end = null;
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.fields.push(event.option.viewValue);
    this.fieldInput.nativeElement.value = '';
    this.fieldCtrl.setValue(null);

    document
      .getElementById('filterFields')
      .appendChild(document.getElementById(event.option.viewValue));
  }

  ngOnInit(): void {
    this.retrieveAllUsers();
    this.queryStringClause = 'work_area__c = ' + '\'' + this.workAreaId + '\'';
  }

  ngOnChanges(): any {
    this.makeUniqueStudyTypes();
    this.makeUniqueTestSystemTypes();
    this.makeUniqueTestSystemNames();
    this.makeUniqueEndpoints();
    this.makeUniqueEndpointUnits();
    this.makeUniqueAdditionalFields();
    this.makeUniqueAdditionalFieldValues();
  }

  makeUniqueAdditionalFields(): any {
    // remove duplicates
    const sourceArray = this.receivedAllAdditionalFields;
    const resultArray = this.uniqueAdditionalFields;

    const map = new Map();
    for (const item of sourceArray) {
      if (!map.has(item.saveTo)) {
        map.set(item.saveTo, true); // set any value to Map
        resultArray.push({
          categoryId: item.categoryId,
          label: item.label,
          name: item.name,
          type: item.type,
          picklist: item.picklist,
          group: item.group,
          saveTo: item.saveTo,
          hidden: item.hidden,
          sortNumber: item.sortNumber,
        });
      }
    }
  }

  makeUniqueStudyTypes(): any {
    const source = this.receivedAllStudyTypes;
    const result = this.uniqueStudyTypes;

    const map = new Map();
    for (const item of source) {
      if (!map.has(item.name)) {
        map.set(item.name, true);
        result.push({
          name__v: '',
          id: '',
          label: item.label,
          name: item.name,
          picklist: item.picklist,
        });
      }
    }
  }

  makeUniqueTestSystemTypes(): any {
    const source = this.receivedAllTestSystemTypes;
    const result = this.uniqueTestSystemTypes;

    const map = new Map();
    for (const item of source) {
      if (!map.has(item.name)) {
        map.set(item.name, true);
        result.push({
          name__v: '',
          id: '',
          label: item.label,
          name: item.name,
          picklist: item.picklist,
        });
      }
    }
  }

  makeUniqueTestSystemNames(): any {
    const source = this.receivedAllTestSystemNames;
    const result = this.uniqueTestSystemNames;

    const map = new Map();
    for (const item of source) {
      if (!map.has(item.name)) {
        map.set(item.name, true);
        result.push({
          name__v: '',
          id: '',
          label: item.label,
          name: item.name,
          picklist: item.picklist,
        });
      }
    }
  }

  makeUniqueEndpoints(): any {
    const source = this.receivedAllEndpoints;
    const result = this.uniqueEndpoints;

    const map = new Map();
    for (const item of source) {
      if (!map.has(item.name)) {
        map.set(item.name, true);
        result.push({
          name__v: '',
          id: '',
          label: item.label,
          name: item.name,
          picklist: item.picklist,
        });
      }
    }
  }

  makeUniqueEndpointUnits(): any {
    const source = this.receivedAllEndpointUnits;
    const result = this.uniqueEndpointUnits;

    const map = new Map();
    for (const item of source) {
      if (!map.has(item.name)) {
        map.set(item.name, true);
        result.push({
          name__v: '',
          id: '',
          label: item.label,
          name: item.name,
          picklist: item.picklist,
        });
      }
    }
  }

  makeUniqueAdditionalFieldValues(): any {
    const source = this.receivedAdditionalFieldValues;
    const result = this.uniqueAdditionalFieldValues;

    const map = new Map();
    for (const item of source) {
      if (!map.has(item.name)) {
        map.set(item.name, true);
        result.push({
          categoryId: item.categoryId,
          label: item.label,
          name: item.name,
          type: item.type,
          picklist: item.picklist,
          group: item.group,
          saveTo: item.saveTo,
          hidden: item.hidden,
          sortNumber: item.sortNumber,
        });
      }
    }
  }

  getEndpointsWithFilters(LIMIT, OFFSET) {
    this.loaderHidden = false;
    this.searchButtonHidden = true;
    this.serverService.isLoading();

    let createdDateStart = JSON.stringify(
      this.createdRange.value.createdDateStart
    );
    createdDateStart = createdDateStart.replace('"', '');
    createdDateStart = createdDateStart.replace('"', '');
    let createdDateEnd = JSON.stringify(this.createdRange.value.createdDateEnd);
    createdDateEnd = createdDateEnd.replace('"', '');
    createdDateEnd = createdDateEnd.replace('"', '');

    let modifiedDateStart = JSON.stringify(
      this.modifiedRange.value.modifiedDateStart
    );
    modifiedDateStart = modifiedDateStart.replace('"', '');
    modifiedDateStart = modifiedDateStart.replace('"', '');
    let modifiedDateEnd = JSON.stringify(
      this.modifiedRange.value.modifiedDateEnd
    );
    modifiedDateEnd = modifiedDateEnd.replace('"', '');
    modifiedDateEnd = modifiedDateEnd.replace('"', '');

    // reset condition clauses
    this.queryStringClause =
      'work_area__c = ' +
      '\'' +
      this.workAreaId +
      '\' AND substance_name__c = ' +
      '\'' +
      this.substance.id +
      '\'';

    // unpack conditions array and construct query
    this.queryStringConstructor.forEach((condition: any) => {
      const singleCondition =
        ' AND ' +
        condition.fieldName +
        ' = ' +
        '\'' +
        condition.fieldValue +
        '\'';
      this.queryStringClause = this.queryStringClause + singleCondition;
    });

    if (createdDateStart !== 'null' && createdDateEnd !== 'null') {
      this.queryStringClause =
        this.queryStringClause +
        ' AND created_date__v BETWEEN \'' +
        createdDateStart +
        '\'' +
        ' AND \'' +
        createdDateEnd +
        '\'';
    }

    if (modifiedDateStart !== 'null' && modifiedDateEnd !== 'null') {
      this.queryStringClause =
        this.queryStringClause +
        ' AND modified_date__v BETWEEN \'' +
        modifiedDateStart +
        '\'' +
        ' AND \'' +
        modifiedDateEnd +
        '\'';
    }

    this.queryStringClause =
      this.queryStringClause +
      ' ORDER BY ' +
      this.sortByFieldName +
      ' ' +
      this.sortByOrder +
      ' LIMIT ' +
      LIMIT +
      ' OFFSET ' +
      OFFSET;

    this.subscriptions = this.serverService
      .filterEndpointRecords(
        this.sessionId,
        this.queryStringClause,
        this.workAreaName
      )
      .subscribe(
        (response) => {
          // reset current list
          this.existingEndpoints = [];
          if (response.data && response.data.length > 0) {
            response.data.forEach((it) => this.existingEndpoints.push(it));
            this.dataSourceLength = response.responseDetails.total;
          }
        },
        (error: HttpErrorResponse) => {
          this.exceptionService.handleError(error);
          this.toastrService.error('Unable to get Endpoints.', 'Error');
        },
        () => {
          this.serverService.isNotLoading();
          this.loaderHidden = true;
          this.searchButtonHidden = false;
        }
      );
  }

  retrieveAllUsers(): any {
    this.subscriptions = this.serverService
      .getAllUsers(this.sessionId)
      .subscribe(
        (response) => {
          if (response.responseStatus == 'SUCCESS') {
            response.data.forEach((user: any) => {
              const entry = {
                id: user.id,
                name: user.user_first_name__v + ' ' + user.user_last_name__v,
              };
              this.allUsers.push(entry);

              this.filteredCreateUsers = this.searchUserCreateControl.valueChanges.pipe(
                startWith(''),
                map((val) => this.filter(val))
              );

              this.filteredModifiedUsers = this.searchUserModifyControl.valueChanges.pipe(
                startWith(''),
                map((val) => this.filter(val))
              );
            });
          }
        },
        (error: HttpErrorResponse) => {
          this.exceptionService.handleError(error);
          this.toastrService.error(`Unable to get users.`, 'Error');
        },
        () => {
        }
      );
  }

  /**
   * used for document search (filtering based on user input)
   * @param val = user text field input
   */
  filter(val) {
    if (this.allUsers) {
      return this.allUsers.filter((option) =>
        option.name.toLowerCase().includes(val)
      );
    }
  }

  picklistValueToArray(name, label, value) {
    const fieldValuePair = {
      fieldName: name,
      fieldLabel: label,
      fieldValue: value,
    };

    // remove from array if something was selected, we need to replace old value with new
    const queryIndex = this.queryStringConstructor
      .map(function(e) {
        return e.fieldLabel;
      })
      .indexOf(label);

    if (queryIndex >= 0) {
      this.queryStringConstructor.splice(queryIndex, 1);
    }

    // add selected condition to array
    if (value !== '') {
      this.queryStringConstructor.push(fieldValuePair);
    }
  }

  textFieldValueToArray(name, label) {
    // @ts-ignore
    const value = document.getElementById(name).value;
    this.picklistValueToArray(name, label, value);
  }

  defineSortOrder(): any {
    if (
      this.sortByFieldName === 'id' ||
      this.sortByFieldName === 'related_substance__c'
    ) {
      this.sortByOrder = 'DESC';
    } else {
      this.sortByOrder = 'ASC';
    }
    this.getEndpointsWithFilters(this.pageSize, 0);
  }

  filterAdditionalFieldValues(
    allAdditionalFieldValues: Array<ToxAdditionalField>,
    filterWord: string
  ): Array<ToxAdditionalField> {
    return allAdditionalFieldValues.filter((item) => item.saveTo == filterWord);
  }

  destroyEndpoints(): any {
    this.existingEndpoints = [];
    // reset filter values (UI)
  }

  onPaginateChange($event: PageEvent) {
    let page = $event.pageIndex;
    const size = $event.pageSize;
    this.pageSize = $event.pageSize;
    const skip = page * size;
    this.pageSkip = skip;

    this.getEndpointsWithFilters(size, skip);
  }

  reduceCount(): void {
    this.dataSourceLength = this.dataSourceLength - 1;
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.allFields.filter(
      (field) => field.toLowerCase().indexOf(filterValue) === 0
    );
  }
}
