import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AbstractControl, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ToastrService} from 'ngx-toastr';
import {ExceptionService} from 'src/app/core/exception.service';
import {ActivatedRoute} from '@angular/router';
import {ServerService} from 'src/app/core/server.service';
import {PicklistValue} from 'src/app/endpoint/models/picklist-value';
import {ToxAdditionalField} from '../../../models/tox-additional-field';
import {SubstanceItem} from '../../../models/substance-item';
import {DocumentMenuItem} from '../../../models/document-menu-item';
import {ToxEndpointTableItem} from '../../../models/tox-endpoint-table-item';

let me: ReproductionDevelopmentalToxicityComponent;

@Component({
  selector: 'app-reproduction-developmental-toxicity',
  templateUrl: './reproduction-developmental-toxicity.component.html',
  styleUrls: ['../../../record_style.css']
})

export class ReproductionDevelopmentalToxicityComponent implements OnInit {
  @Input() singleEndpointRecord: ToxEndpointTableItem;
  @Input() category: PicklistValue;
  @Input() allStudyTypes: Array<PicklistValue>;
  @Input() allTestSystemTypes: Array<PicklistValue>;
  @Input() allRouteOfAdministration: Array<PicklistValue>;
  @Input() allTestSystemNames: Array<PicklistValue>;
  @Input() allEndpoints: Array<PicklistValue>;
  @Input() allAdditionalFields: Array<ToxAdditionalField>;
  @Input() allAdditionalFieldValues: Array<PicklistValue>;
  @Input() substance: SubstanceItem;
  @Input() substanceTypes: Array<PicklistValue>;
  @Input() relatedSubstances: any;
  @Input() documents: Array<DocumentMenuItem>;
  @Input() purpose: string;

  @Output() removeInput: EventEmitter<ToxEndpointTableItem> = new EventEmitter();
  @Output() copyInput: EventEmitter<ToxEndpointTableItem> = new EventEmitter();
  @Output() cancelEdit: EventEmitter<ToxEndpointTableItem> = new EventEmitter();
  @Output() saveChanges: EventEmitter<ToxEndpointTableItem> = new EventEmitter();

  subscriptions: any;
  selectFormGroup: FormGroup;
  configureFormGroup: FormGroup;
  public categoryAdditionalFields: Array<ToxAdditionalField>;
  public mutualAdditionalFieldNames: Array<any>;
  public mutualAdditionalFields: Array<ToxAdditionalField>;
  public dependedAdditionalFields: Array<ToxAdditionalField>;
  public additionalFields: Array<ToxAdditionalField>;
  public additionalFieldValues: Map<string, any>;
  public studyTypes: Array<PicklistValue>;
  public testSystemTypes: Array<PicklistValue>;
  public testSystemNames: Array<PicklistValue>;
  public endpoints: Array<PicklistValue>;
  public value3: any;
  public endpointUnits: Array<PicklistValue>;
  public filteredRelatedSubstances: any;
  private sessionId: string;
  public showCopyOptions: boolean;
  public showEditOptions: boolean;

  constructor(
    private route: ActivatedRoute,
    private exceptionService: ExceptionService,
    private serverService: ServerService,
    private toastrService: ToastrService,
    private formBuilder: FormBuilder
  ) {
    me = this;
    this.subscriptions = {};
    this.route.paramMap.subscribe((params) => {
      this.sessionId = params.get('sessionId');
    });
    this.selectFormGroup = this.formBuilder.group({
      substanceType: [],
      relatedSubstance: [{value: '', disabled: true}],
      studyType: [{value: '', disabled: false}, Validators.required],
      test_system_type__c: [{value: '', disabled: false}],
      route_of_administration__c: [{value: '', disabled: false}],
      test_system_name__c: [{value: '', disabled: false}],
      endpoint: [{value: '', disabled: false}, Validators.required],
      value_3__c: [{value: '', disabled: false}],
      // additional fields
      noael_parental_mgkg_bwd__c: [{value: '', disabled: false}],
      loael_parental_mgkg_bwd__c: [{value: '', disabled: false}],
      noael_fertility_mgkg_bwd__c: [{value: '', disabled: false}],
      loael_fertility_mgkg_bwd__c: [{value: '', disabled: false}],
      noael_offspring_mgkg_bwd__c: [{value: '', disabled: false}],
      loael_offspring_mgkg_bwd__c: [{value: '', disabled: false}],
      noael_maternal_mgkg_bwd__c: [{value: '', disabled: false}],
      loael_maternal_mgkg_bwd__c: [{value: '', disabled: false}],
      noael_developmental_mgkg_bwd__c: [{value: '', disabled: false}],
      loael_developmental_mgkg_bwd__c: [{value: '', disabled: false}],
      mechanistic_studies__c: [{value: '', disabled: false}],
      target_organ__c: [{value: '', disabled: false}],
      eats_and_developmental_parameters_affect__c: [{value: '', disabled: false}],
      t_trigger__c: [{value: '', disabled: false}],
      // default
      selectedDocument: [{value: '', disabled: false}],
    });
    this.testSystemTypes = [];
    this.testSystemNames = [];
    this.value3 = [{label: 'Yes', name: 'yes'}, {label: 'No', name: 'no'}, {label: 'Equivocal', name: 'equivocal'}];
    this.categoryAdditionalFields = [];
    this.mutualAdditionalFields = [];
    this.mutualAdditionalFieldNames =
      [
        'mechanistic_studies__c',
        'target_organ__c',
        'eats_and_developmental_parameters_affect__c',
        't_trigger__c'
      ];
    this.dependedAdditionalFields = [];
    this.additionalFields = [];
    this.additionalFieldValues = new Map();
    this.filteredRelatedSubstances = [];
  }

  get relatedSubstanceControl(): AbstractControl {
    return this.selectFormGroup.get('relatedSubstance');
  }
  get endpointControl(): AbstractControl {
    return this.selectFormGroup.get('endpoint');
  }
  get studyTypeControl(): AbstractControl {
    return this.selectFormGroup.get('studyType');
  }
  get sourceControl(): AbstractControl {
    return this.selectFormGroup.get('selectedDocument');
  }

  ngOnInit(): void {

    if (this.purpose === 'editOnExisting') {
      this.showCopyOptions = false;
      this.showEditOptions = true;
    }
    else {
      this.showCopyOptions = true;
      this.showEditOptions = false;
    }

    this.categoryAdditionalFields = this.allAdditionalFields.filter(
      (entry) =>
        entry.group === 'study_type_reproduction_developmental_t__c'
    );

    this.mutualAdditionalFieldNames.forEach(field => {
        const singleMutualField = this.categoryAdditionalFields.filter(
          (entry) => entry.name === field
        );

        this.mutualAdditionalFields = this.mutualAdditionalFields.concat(singleMutualField);
      }
    );

    if (this.singleEndpointRecord.study_type_picklist_name__c) {
      // COPY record
      if (this.singleEndpointRecord.test_system_type__c === 'reproduction_toxicity__c') {
        const dependedAdditionalFieldNames =
          [
            'noael_parental_mgkg_bwd__c',
            'loael_parental_mgkg_bwd__c',
            'noael_fertility_mgkg_bwd__c',
            'loael_fertility_mgkg_bwd__c',
            'noael_offspring_mgkg_bwd__c',
            'loael_offspring_mgkg_bwd__c'
          ];
        dependedAdditionalFieldNames.forEach(field => {
            const singleField = this.categoryAdditionalFields.filter(
              (entry) => entry.name === field
            );
            this.dependedAdditionalFields = this.dependedAdditionalFields.concat(singleField);
          }
        );
        const additionalFieldsNoOrder = this.dependedAdditionalFields.concat(this.mutualAdditionalFields);
        this.additionalFields = additionalFieldsNoOrder.sort((a, b) => a.sortNumber - b.sortNumber);
      }
      else if (this.singleEndpointRecord.test_system_type__c === 'developmental_toxicity__c') {
        const dependedAdditionalFieldNames =
          [
            'noael_maternal_mgkg_bwd__c',
            'loael_maternal_mgkg_bwd__c',
            'noael_developmental_mgkg_bwd__c',
            'loael_developmental_mgkg_bwd__c'
          ];
        dependedAdditionalFieldNames.forEach(field => {
            const singleField = this.categoryAdditionalFields.filter(
              (entry) => entry.name === field
            );
            this.dependedAdditionalFields = this.dependedAdditionalFields.concat(singleField);
          }
        );
        const additionalFieldsNoOrder = this.dependedAdditionalFields.concat(this.mutualAdditionalFields);
        this.additionalFields = additionalFieldsNoOrder.sort((a, b) => a.sortNumber - b.sortNumber);
      }

      // parent (or none) cannot have Related Substance
      if (this.singleEndpointRecord.substance_type__c === 'parent__c' || this.singleEndpointRecord.substance_type__c === '') {
        this.selectFormGroup.get('relatedSubstance').disable();
      } else {
        // filter Related Substances by the selected Substance Type
        this.filteredRelatedSubstances = this.relatedSubstances.filter(
          (f) => f.substance_type__c[0] === this.singleEndpointRecord.substance_type__c
        );
        this.selectFormGroup.get('relatedSubstance').enable();
      }

      this.setFormFields();
    }
    else {
      this.selectFormGroup.get('substanceType').setValue('parent__c');
    }

    this.sourceControl.setValue(this.singleEndpointRecord.document_unbound__c);
    this.studyTypes = this.allStudyTypes;
    this.testSystemTypes = this.allTestSystemTypes;
    this.testSystemNames = this.allTestSystemNames;
    this.endpoints = this.allEndpoints;
  }

  getSelectedOptionPicklist(event: any): string {
    return event.target.options[event.target.options.selectedIndex].title;
  }

  onSelectSubstanceType(event: any): any {
    // update object
    this.singleEndpointRecord.substance_type__c = event.target.value;

    // clear Related Substance value because the group of Related Substances has changed
    this.singleEndpointRecord.related_substance__c = '';
    // also clear Related Substance form field
    this.relatedSubstanceControl.setValue('');

    // parent (or none) cannot have Related Substance
    if (event.target.value === 'parent__c' || event.target.value === '') {
      this.selectFormGroup.get('relatedSubstance').disable();
    } else {
      // filter Related Substances by the selected Substance Type
      this.filteredRelatedSubstances = this.relatedSubstances.filter(
        (f) => f.substance_type__c[0] === event.target.value
      );
      this.selectFormGroup.get('relatedSubstance').enable();
    }
  }

  onSelectStudyType(event: any): any {
    this.singleEndpointRecord.study_type__c = event.target.value;

    if (event.target.value !== '') {
      this.singleEndpointRecord.study_type_picklist_name__c = this.getSelectedOptionPicklist(
        event
      );
    }
    else {
      this.singleEndpointRecord.study_type_picklist_name__c = '';
    }
  }

  onSelectTestSystemType(event: any): any {
    this.singleEndpointRecord.test_system_type__c = event.target.value;
    this.dependedAdditionalFields = [];
    this.resetDependedAdditionalFieldValues();
    this.additionalFields = []; // always reset because every change requires it, plus clear concat function

    if (event.target.value !== '') {
      // Test System Type selected
      if (this.singleEndpointRecord.test_system_type__c === 'reproduction_toxicity__c') {
        const dependedAdditionalFieldNames =
        [
          'noael_parental_mgkg_bwd__c',
          'loael_parental_mgkg_bwd__c',
          'noael_fertility_mgkg_bwd__c',
          'loael_fertility_mgkg_bwd__c',
          'noael_offspring_mgkg_bwd__c',
          'loael_offspring_mgkg_bwd__c'
        ];

        dependedAdditionalFieldNames.forEach(field => {
            const singleField = this.categoryAdditionalFields.filter(
              (entry) => entry.name === field
            );

            this.dependedAdditionalFields = this.dependedAdditionalFields.concat(singleField);
          }
        );
        const additionalFieldsNoOrder = this.dependedAdditionalFields.concat(this.mutualAdditionalFields);
        this.additionalFields = additionalFieldsNoOrder.sort((a, b) => a.sortNumber - b.sortNumber);
      }
      else if (this.singleEndpointRecord.test_system_type__c === 'developmental_toxicity__c') {
        const dependedAdditionalFieldNames =
          [
            'noael_maternal_mgkg_bwd__c',
            'loael_maternal_mgkg_bwd__c',
            'noael_developmental_mgkg_bwd__c',
            'loael_developmental_mgkg_bwd__c'
          ];

        dependedAdditionalFieldNames.forEach(field => {
            const singleField = this.categoryAdditionalFields.filter(
              (entry) => entry.name === field
            );
            this.dependedAdditionalFields = this.dependedAdditionalFields.concat(singleField);
          }
        );
        const additionalFieldsNoOrder = this.dependedAdditionalFields.concat(this.mutualAdditionalFields);
        this.additionalFields = additionalFieldsNoOrder.sort((a, b) => a.sortNumber - b.sortNumber);
      }
    }
    else {
      // Test System Type cleared
      this.resetAllAdditionalFieldValues();
    }
  }

  filterAdditionalFieldValues(allAdditionalFieldValues: Array<PicklistValue>, filterWord: string): Array<PicklistValue> {
    return allAdditionalFieldValues.filter(
      (item) => item.picklist === filterWord
    );
  }

  removeEndpoint(singleEndpointRecord): any {
    this.removeInput.emit(singleEndpointRecord);
  }

  updateFieldValue(newValue): any {
    const fieldName = newValue.title;
    this.singleEndpointRecord[fieldName] = newValue.value;
  }

  updateNumberFieldValue(newValue): any {
    const fieldName = newValue.title;

    if (fieldName === 'temp_c__c') {
      this.singleEndpointRecord[fieldName] = newValue.value;
    } else {
      if (newValue.value < 0) {
        this.singleEndpointRecord[fieldName] = '';
        this.selectFormGroup.get(fieldName).setValue('');
      } else {
        this.singleEndpointRecord[fieldName] = newValue.value;
      }
    }
  }

  copyEndpoint(singleEndpointRecord: ToxEndpointTableItem): void {
    this.copyInput.emit(singleEndpointRecord);
  }

  updateMultiPicklistFieldValue(newValue, fieldName): void {
    this.singleEndpointRecord[fieldName] = newValue.value.toString();
  }

  setFormFields(): void {
    this.selectFormGroup.get('substanceType').setValue(this.singleEndpointRecord.substance_type__c);
    this.selectFormGroup.get('relatedSubstance').setValue(this.singleEndpointRecord.related_substance__c);
    this.selectFormGroup.get('studyType').setValue(this.singleEndpointRecord.study_type__c);
    this.selectFormGroup.get('test_system_type__c').setValue(this.singleEndpointRecord.test_system_type__c);
    this.selectFormGroup.get('route_of_administration__c').setValue(this.singleEndpointRecord.route_of_administration__c);
    this.selectFormGroup.get('test_system_name__c').setValue(this.singleEndpointRecord.test_system_name__c);
    this.selectFormGroup.get('endpoint').setValue(this.singleEndpointRecord.endpoint__c);
    this.selectFormGroup.get('value_3__c').setValue(this.singleEndpointRecord.value_3__c);
    // additional fields
    this.selectFormGroup.get('noael_parental_mgkg_bwd__c').setValue(this.singleEndpointRecord.noael_parental_mgkg_bwd__c);
    this.selectFormGroup.get('loael_parental_mgkg_bwd__c').setValue(this.singleEndpointRecord.loael_parental_mgkg_bwd__c);
    this.selectFormGroup.get('noael_fertility_mgkg_bwd__c').setValue(this.singleEndpointRecord.noael_fertility_mgkg_bwd__c);
    this.selectFormGroup.get('loael_fertility_mgkg_bwd__c').setValue(this.singleEndpointRecord.loael_fertility_mgkg_bwd__c);
    this.selectFormGroup.get('noael_offspring_mgkg_bwd__c').setValue(this.singleEndpointRecord.noael_offspring_mgkg_bwd__c);
    this.selectFormGroup.get('loael_offspring_mgkg_bwd__c').setValue(this.singleEndpointRecord.loael_offspring_mgkg_bwd__c);
    this.selectFormGroup.get('noael_maternal_mgkg_bwd__c').setValue(this.singleEndpointRecord.noael_maternal_mgkg_bwd__c);
    this.selectFormGroup.get('loael_maternal_mgkg_bwd__c').setValue(this.singleEndpointRecord.loael_maternal_mgkg_bwd__c);
    this.selectFormGroup.get('noael_developmental_mgkg_bwd__c').setValue(this.singleEndpointRecord.noael_developmental_mgkg_bwd__c);
    this.selectFormGroup.get('loael_developmental_mgkg_bwd__c').setValue(this.singleEndpointRecord.loael_developmental_mgkg_bwd__c);
    this.selectFormGroup.get('mechanistic_studies__c').setValue(this.singleEndpointRecord.mechanistic_studies__c);
    if (this.singleEndpointRecord.target_organ__c) {
      this.selectFormGroup.get('target_organ__c').setValue(this.singleEndpointRecord.target_organ__c.split(',')); // multi value
    }
    if (this.singleEndpointRecord.eats_and_developmental_parameters_affect__c) {
      this.selectFormGroup.get('eats_and_developmental_parameters_affect__c').
      setValue(this.singleEndpointRecord.eats_and_developmental_parameters_affect__c.split(',')); // multi value
    }
    this.selectFormGroup.get('t_trigger__c').setValue(this.singleEndpointRecord.t_trigger__c);
    // default
    this.selectFormGroup.get('selectedDocument').setValue(this.singleEndpointRecord.document_unbound__c);
  }

  resetAllAdditionalFieldValues(): void {
    // all additional fields: record array
    this.singleEndpointRecord.noael_parental_mgkg_bwd__c = '';
    this.singleEndpointRecord.loael_parental_mgkg_bwd__c = '';
    this.singleEndpointRecord.noael_fertility_mgkg_bwd__c = '';
    this.singleEndpointRecord.loael_fertility_mgkg_bwd__c = '';
    this.singleEndpointRecord.noael_offspring_mgkg_bwd__c = '';
    this.singleEndpointRecord.loael_offspring_mgkg_bwd__c = '';
    this.singleEndpointRecord.noael_maternal_mgkg_bwd__c = '';
    this.singleEndpointRecord.loael_maternal_mgkg_bwd__c = '';
    this.singleEndpointRecord.noael_developmental_mgkg_bwd__c = '';
    this.singleEndpointRecord.loael_developmental_mgkg_bwd__c = '';
    this.singleEndpointRecord.mechanistic_studies__c = '';
    this.singleEndpointRecord.target_organ__c = '';
    this.singleEndpointRecord.eats_and_developmental_parameters_affect__c = '';
    this.singleEndpointRecord.t_trigger__c = '';
    // all additional fields: form
    this.selectFormGroup.get('noael_parental_mgkg_bwd__c').setValue('');
    this.selectFormGroup.get('loael_parental_mgkg_bwd__c').setValue('');
    this.selectFormGroup.get('noael_fertility_mgkg_bwd__c').setValue('');
    this.selectFormGroup.get('loael_fertility_mgkg_bwd__c').setValue('');
    this.selectFormGroup.get('noael_offspring_mgkg_bwd__c').setValue('');
    this.selectFormGroup.get('loael_offspring_mgkg_bwd__c').setValue('');
    this.selectFormGroup.get('noael_maternal_mgkg_bwd__c').setValue('');
    this.selectFormGroup.get('loael_maternal_mgkg_bwd__c').setValue('');
    this.selectFormGroup.get('noael_developmental_mgkg_bwd__c').setValue('');
    this.selectFormGroup.get('loael_developmental_mgkg_bwd__c').setValue('');
    this.selectFormGroup.get('mechanistic_studies__c').setValue('');
    this.selectFormGroup.get('target_organ__c').setValue(''); // multi value
    this.selectFormGroup.get('eats_and_developmental_parameters_affect__c').setValue(''); // multi value
    this.selectFormGroup.get('t_trigger__c').setValue('');
  }

  resetDependedAdditionalFieldValues(): void {
    // depended additional fields: record array
    this.singleEndpointRecord.noael_parental_mgkg_bwd__c = '';
    this.singleEndpointRecord.loael_parental_mgkg_bwd__c = '';
    this.singleEndpointRecord.noael_fertility_mgkg_bwd__c = '';
    this.singleEndpointRecord.loael_fertility_mgkg_bwd__c = '';
    this.singleEndpointRecord.noael_offspring_mgkg_bwd__c = '';
    this.singleEndpointRecord.loael_offspring_mgkg_bwd__c = '';
    this.singleEndpointRecord.noael_maternal_mgkg_bwd__c = '';
    this.singleEndpointRecord.loael_maternal_mgkg_bwd__c = '';
    this.singleEndpointRecord.noael_developmental_mgkg_bwd__c = '';
    this.singleEndpointRecord.loael_developmental_mgkg_bwd__c = '';
    // depended additional fields: form
    this.selectFormGroup.get('noael_parental_mgkg_bwd__c').setValue('');
    this.selectFormGroup.get('loael_parental_mgkg_bwd__c').setValue('');
    this.selectFormGroup.get('noael_fertility_mgkg_bwd__c').setValue('');
    this.selectFormGroup.get('loael_fertility_mgkg_bwd__c').setValue('');
    this.selectFormGroup.get('noael_offspring_mgkg_bwd__c').setValue('');
    this.selectFormGroup.get('loael_offspring_mgkg_bwd__c').setValue('');
    this.selectFormGroup.get('noael_maternal_mgkg_bwd__c').setValue('');
    this.selectFormGroup.get('loael_maternal_mgkg_bwd__c').setValue('');
    this.selectFormGroup.get('noael_developmental_mgkg_bwd__c').setValue('');
    this.selectFormGroup.get('loael_developmental_mgkg_bwd__c').setValue('');
  }

  cancelEditing(endpointRecord): void {
    this.cancelEdit.emit(endpointRecord);
  }

  saveEndpointChanges(endpointRecord): void {
    this.saveChanges.emit(endpointRecord);
  }

}


