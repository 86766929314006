import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {SubstanceSet} from '../../models/substance-set';
import {Evaluation} from '../../models/evaluation';
import {User} from "../../models/user";
import {VaultService} from "../../service/vault.service";


@Component({
  selector: 'app-save-set-dialog',
  templateUrl: './save-set-dialog.component.html',
  styleUrls: ['./save-set-dialog.component.css']
})
export class SaveSetDialogComponent implements OnInit {

  users: User[];
  isShared: boolean = false;
  selectedIds: string[] = [];
  setsToSave: SubstanceSet[] = [];
  selectedEvaluations: Evaluation[] = [];


  constructor(
    public dialogRef: MatDialogRef<SaveSetDialogComponent>,
    private service: VaultService,
    @Inject(MAT_DIALOG_DATA) public data: SubstanceSet
  ) { }

  ngOnInit(): void {
    this.service.getAllUsers().subscribe(response => {
      this.users = response;
    })
    console.log('data received: ', this.data);
    this.setsToSave.push(this.data);
    for (let substance of this.data.substances) {
      for (let evaluation of substance.poc_evaluations__cr.data) {
        this.selectedEvaluations.push(evaluation)
      }
    }

  }

  cancel(): void {
    this.dialogRef.close();
  }

  updateSets(): void{
    for (let i = 0; i < this.selectedIds.length; i++){
      let hasSelectedId = this.setsToSave.some( set =>
        set.userId === this.selectedIds[i]);
      if (!hasSelectedId){
        let set: SubstanceSet = new SubstanceSet();
        set.userId = this.selectedIds[i];
        set.substances = this.data.substances;
        set.setName = this.data.setName;
        this.setsToSave.push(set);
      }
    }
  }
}
