import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable, Subject, timer} from 'rxjs';
import {map, shareReplay, switchMap} from 'rxjs/operators';

import {AuthenticationResponse} from './models/authentication-response';
import {TimelineDatesResponse} from '../timeline/models/timeline-dates-response';
import {RegistrationHistoryListResponse} from '../registration/models/registration-response';
import {Registration} from '../registration/models/registration';
import {PicklistResponse} from '../registration/models/picklist-response';
import {FieldMetadata, ObjectMetadataResponse} from '../registration/models/object-metadata-response';
import {ExistingEndpoint} from '../endpoint/environmental-fate/models/existing-endpoint';
import {EcotoxExistingEndpoint} from '../endpoint/ecotoxicology/models/ecotox-existing-endpoint';
import {Picklist} from '../shared/picklist';
import {ToxExistingEndpoint} from '../endpoint/models/tox-existing-endpoint';
import {ConsumerSafetyExisting} from '../endpoint/consumer-safety/models/consumer-safety-existing';
import {PhysChemDataExisting} from '../endpoint/phys-chem-data/models/phys-chem-data-existing';
import {environment} from "../../environments/environment";

@Injectable({
  providedIn: 'root',
})
export class ServerService {
  headers: HttpHeaders;
  // Observable string sources
  private isLoadingSource: Subject<boolean> = new Subject<boolean>();
  // Observable string streams
  isLoading$ = this.isLoadingSource.asObservable();
  private cacheStatusPicklistApaAps$: Observable<PicklistResponse>;
  private cacheStatusPicklistApe$: Observable<PicklistResponse>;
  private cacheStatusPicklistApn$: Observable<PicklistResponse>;
  private cacheCutOffPicklist$: Observable<PicklistResponse>;
  private decisionTypePicklist$: Observable<PicklistResponse>;
  private programmePicklist$: Observable<PicklistResponse>;
  private cacheFieldNamesApa$: Observable<ObjectMetadataResponse>;
  private cacheFieldNamesApe$: Observable<ObjectMetadataResponse>;
  private cacheFieldNamesAps$: Observable<ObjectMetadataResponse>;
  private cacheFieldNamesApn$: Observable<ObjectMetadataResponse>;
  private cacheFieldNamesTimeline$: Observable<ObjectMetadataResponse>;
  private compareApiUrl: string = environment.apiUrl;

  constructor(private http: HttpClient) {
    this.headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Accept', 'application/json');
  }

  /**
   * Set loading status to true.
   */
  isLoading(): boolean {
    this.isLoadingSource.next(true);
    return true;
  }

  /**
   * Set loading status to false.
   */
  isNotLoading(): boolean {
    this.isLoadingSource.next(false);
    return false;
  }

  getVersion(): Observable<any> {
    return this.http.get<AuthenticationResponse>(`api/version`, {
      headers: this.headers,
    });
  }

  auth(): Observable<AuthenticationResponse> {
    return this.http.get<AuthenticationResponse>(`api/auth`, {
      headers: this.headers,
    });
  }

  postTimelineDates(
    sessionId: string,
    id: string
  ): Observable<TimelineDatesResponse> {
    const body = {
      sessionId,
      id,
    };

    return this.http.post<TimelineDatesResponse>(
      `api/registration/dates`,
      body,
      {headers: this.headers}
    );
  }

  postTimelineImage(
    sessionId: string,
    id: string,
    image: string
  ): Observable<any> {
    const body = {
      sessionId,
      id,
      image,
    };

    return this.http.post(`api/registration/image`, body, {
      headers: this.headers,
    });
  }

  postEvaluation(sessionId: string, id: string): Observable<any> {
    const body = {
      sessionId,
      id,
    };

    return this.http.post(`api/evaluation`, body, {headers: this.headers});
  }

  postEvaluationImage(
    sessionId: string,
    id: string,
    image: string,
    fileName: string
  ): Observable<any> {
    const body = {
      sessionId,
      id,
      image,
      fileName,
    };

    return this.http.post(`api/evaluation/image`, body, {
      headers: this.headers,
    });
  }

  getWorkAreaTypes(sessionId: string): Observable<any> {
    const body = {
      sessionId,
    };
    return this.http.post('api/work-area/types', body, {
      headers: this.headers,
    });
  }

  getRelatedSubstances(
    sessionId: string,
    substanceId: string
  ): Observable<any> {
    const body = {
      sessionId,
      substanceId,
    };
    return this.http.post('api/related_substances/records', body, {
      headers: this.headers,
    });
  }

  getEndpointWorkAreas(sessionId: string): Observable<any> {
    const body = {
      sessionId,
    };
    return this.http.post('api/endpoint-work_areas', body, {
      headers: this.headers,
    });
  }

  getEndpointCategories(sessionId: string, areaId: string): Observable<any> {
    const body = {
      sessionId,
      areaId,
    };
    return this.http.post('api/work-area/endpoint-categories', body, {
      headers: this.headers,
    });
  }

  getRegionCategories(sessionId: string, region: string): Observable<any> {
    const body = {
      sessionId,
      region,
    };
    return this.http.post('api/region/categories', body, {
      headers: this.headers,
    });
  }

  getCountries(sessionId: string, id: string): Observable<any> {
    const body = {
      sessionId,
      id,
    };
    return this.http.post('api/active-ingredient/countries', body, {
      headers: this.headers,
    });
  }

  getRegionRegistrations(sessionId: string, id: string): Observable<any> {
    const body = {
      sessionId,
      id,
    };
    return this.http.post('api/active-ingredient/registrations', body, {
      headers: this.headers,
    });
  }

  getPolygonData(): Observable<any> {
    return this.http.get('assets/countries.geojson');
  }

  getAllActiveIngredients(sessionId: string): Observable<any> {
    const body = {
      sessionId,
    };
    return this.http.post('api/active-ingredient/all', body, {
      headers: this.headers,
    });
  }

  getSubstanceType(sessionId: string): Observable<any> {
    const body = {
      sessionId,
    };
    return this.http.post('api/substance/type', body, {
      headers: this.headers,
    });
  }

  getSubstanceById(sessionId: string, substanceId: string): Observable<any> {
    const body = {
      sessionId,
      substanceId,
    };
    return this.http
      .post('api/substanceObjectRecord/data', body, {headers: this.headers})
      .pipe(
        map((res: Response) => {
          return res;
        })
      );
  }

  getUserById(
    sessionId: string,
    userId: string,
  ): Observable<any> {
    const body = {
      sessionId,
      userId,
    };
    return this.http
      .post('api/user/data', body, {headers: this.headers})
      .pipe(
        map((res: Response) => {
          return res;
        })
      );
  }

  getOperators(sessionId: string): Observable<any> {
    const body = {
      sessionId,
    };
    return this.http.post('api/operator', body, {headers: this.headers});
  }

  cachePicklist(
    sessionId: string,
    picklistName: string
  ): Observable<PicklistResponse> {
    let cachePicklist;
    switch (picklistName) {
      case Picklist.statusApaAps: {
        cachePicklist = this.cacheStatusPicklistApaAps$;
        break;
      }
      case Picklist.statusApe: {
        cachePicklist = this.cacheStatusPicklistApe$;
        break;
      }
      case Picklist.statusApn: {
        cachePicklist = this.cacheStatusPicklistApn$;
        break;
      }
      case Picklist.cutoffCriteria: {
        cachePicklist = this.cacheCutOffPicklist$;
        break;
      }
      case Picklist.decisionType: {
        cachePicklist = this.decisionTypePicklist$;
        break;
      }
      case Picklist.programme: {
        cachePicklist = this.programmePicklist$;
        break;
      }
      default:
        break;
    }
    if (!cachePicklist) {
      // Set up timer that ticks every 30 minutes
      const timer$ = timer(0, 1800000);
      // For each tick make an http request to fetch new data and update the cache
      cachePicklist = timer$.pipe(
        switchMap((_) => this.getPicklist(sessionId, picklistName)),
        shareReplay(1)
      );
    }
    return cachePicklist;
  }

  getPicklist(
    sessionId: string,
    picklistName: string
  ): Observable<PicklistResponse> {
    const body = {
      sessionId,
      picklistName,
    };
    return this.http.post<PicklistResponse>('api/picklist', body, {
      headers: this.headers,
    });
  }

  saveValuesToPicklist(sessionId: string, picklist: string, picklistValues: string): Observable<any> {
    const body = {
      sessionId,
      picklist,
      picklistValues
    };
    return this.http.post(`api/picklistValues/save`, body, {
      headers: this.headers,
    });
  }



  fetchDocumentsUsingObject(
    sessionId: string,
    objectId: string
  ): Observable<any> {
    const body = {
      sessionId,
      objectId,
    };

    return this.http
      .post('api/getDocumentsUsingObject', body, {headers: this.headers})

      .pipe(
        map((res: Response) => {
          return res;
        })
      );
  }

  search(sessionId: string, keyword: string): Observable<any> {
    const body = {
      sessionId,
      keyword,
    };

    return this.http.post('api/search', body, {headers: this.headers}).pipe(
      map((res: Response) => {
        return res;
      })
    );
  }

  getCategoryPicklists(sessionId: string, workArea: string): Observable<any> {
    const body = {
      sessionId,
      workArea,
    };
    return this.http.post('api/category/picklists', body, {
      headers: this.headers,
    });
  }

  getSingleRecordDependencies(
    sessionId: string,
    workAreaID: string
  ): Observable<any> {
    const body = {
      sessionId,
      workAreaID,
    };
    return this.http.post('api/single-record-dependencies/WA-records', body, {
      headers: this.headers,
    });
  }

  getAdditionalFields(
    sessionId: string,
    picklistName: string
  ): Observable<any> {
    const body = {
      sessionId,
      picklistName,
    };
    return this.http.post('api/endpoint/additional_fields', body, {
      headers: this.headers,
    });
  }

  getAllAdditionalFields(sessionId: string, workArea: string): Observable<any> {
    const body = {
      sessionId,
      workArea,
    };
    return this.http.post('api/endpoint/all_additional_fields', body, {
      headers: this.headers,
    });
  }

  getTestGuidelines(sessionId: string): Observable<any> {
    const body = {
      sessionId,
    };
    return this.http.post('api/endpoint/test_guidelines', body, {
      headers: this.headers,
    });
  }

  filterEndpointRecords(
    sessionId: string,
    whereConditions: string,
    workAreaName
  ): Observable<any> {
    const body = {
      sessionId,
      whereConditions,
      workAreaName,
    };
    return this.http.post('api/endpoints/records-dynamical-condition', body, {
      headers: this.headers,
    });
  }

  postEndpoint(sessionId: string, endpoints: Array<any>): Observable<any> {
    const body = {
      sessionId,
      endpoints: JSON.stringify(endpoints),
    };
    return this.http.post(`api/endpoints/save`, body, {
      headers: this.headers,
    });
  }

  updateEndpoint(
    sessionId: string,
    endpoints: Array<ExistingEndpoint> |
      Array<EcotoxExistingEndpoint> |
      Array<ToxExistingEndpoint> |
      Array<ConsumerSafetyExisting> |
      Array<PhysChemDataExisting>
  ): Observable<any> {
    const body = {
      sessionId,
      endpoints: JSON.stringify(endpoints),
    };
    return this.http.post(`api/endpoints/update`, body, {
      headers: this.headers,
    });
  }

   updatePicklist(sessionId: string, picklistName: string, picklistValueName: string, modification: string): Observable<any> {
    const body = {
      sessionId,
      picklistName,
      picklistValueName,
      modification
    };
    return this.http.post(`api/updateSinglePicklistValue`, body, {
      headers: this.headers,
    });
  }

  deactivatePicklistValue(sessionId: string, picklistName: string, picklistValueName: string): Observable<any> {
    const body = {
      sessionId,
      picklistName,
      picklistValueName
    };
    return this.http.post(`api/deleteSinglePicklistValue`, body, {
      headers: this.headers,
    });
  }

  deleteObjectRecord(sessionId: string, recordId: string): Observable<any> {
    const body = {
      sessionId,
      recordId,
    };
    return this.http.post(`api/endpoints/delete_object_record`, body, {
      headers: this.headers,
    });
  }

  getAllUsers(sessionId: string): Observable<any> {
    const body = {
      sessionId,
    };
    return this.http.post('api/get_all_users', body, {headers: this.headers});
  }

  postTimelineHistoryDates(
    sessionId: string,
    id: string
  ): Observable<TimelineDatesResponse> {
    const body = {
      sessionId,
      id,
    };

    return this.http.post<TimelineDatesResponse>(`api/timeline/history`, body, {
      headers: this.headers,
    });
  }

  downloadExcel(
    objectList: Array<object>,
    columnNames: Array<string>,
    fileName: string
  ): Observable<any> {
    const body = {
      objectList: JSON.stringify(objectList),
      columnNames: JSON.stringify(columnNames),
      fileName,
    };
    const header = new HttpHeaders();
    header
      .set('Content-Type', 'application/json')
      .set('Accept', 'application/octet-stream');
    return this.http.post(`api/excel`, body, {
      headers: header,
      responseType: 'blob',
    });
  }

  postRegistrationHistory(
    sessionId: string,
    id: string,
    region: Registration,
    fields: Array<string>
  ): Observable<RegistrationHistoryListResponse> {
    const body = {
      sessionId,
      id,
      region,
      fields: fields.join(', '),
    };

    return this.http.post<RegistrationHistoryListResponse>(
      `api/registration/history`,
      body,
      {headers: this.headers}
    );
  }

  cacheFieldNames(sessionId: string, objectName: string): Observable<any> {
    let cacheFieldNames;
    switch (objectName.substring(0, 3)) {
      case Registration.APA: {
        cacheFieldNames = this.cacheFieldNamesApa$;
        break;
      }
      case Registration.APE: {
        if (objectName.includes('history')) {
          cacheFieldNames = this.cacheFieldNamesApe$;
        } else {
          cacheFieldNames = this.cacheFieldNamesTimeline$;
        }
        break;
      }
      case Registration.APS: {
        cacheFieldNames = this.cacheFieldNamesAps$;
        break;
      }
      case Registration.APN: {
        cacheFieldNames = this.cacheFieldNamesApn$;
        break;
      }
      default:
        break;
    }
    if (!cacheFieldNames) {
      // Set up timer that ticks every 30 minutes
      const timer$ = timer(0, 1800000);
      // For each tick make an http request to fetch new data and update the cache
      cacheFieldNames = timer$.pipe(
        switchMap((_) => this.getFieldNames(sessionId, objectName)),
        shareReplay(1)
      );
    }
    return cacheFieldNames;
  }

  getFieldNames(
    sessionId: string,
    objectName: string
  ): Observable<Array<FieldMetadata>> {
    const body = {
      sessionId,
      objectName,
    };

    return this.http
      .post<ObjectMetadataResponse>(`api/object-metadata`, body, {
        headers: this.headers,
      })
      .pipe(
        map((response) => {
          return response.object.fields;
        })
      );
  }

  getRecordCollection(objectName: string): Observable<any> {
    return this.http.get(this.compareApiUrl + '/objects/' + objectName);
  }



}
