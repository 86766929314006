<div class="legend-item">
  <span *ngIf="chartType === 'radar'" [ngStyle]="getDotStyle(chartType)"
        [style.background-color]="this.radarChartColors[index]"></span>
  <span *ngIf="chartType !== 'radar'" [ngStyle]="getDotStyle(chartType)"
        [style.background-color]="this.barChartColors[index]"></span>

  <mat-form-field appearance="fill" [ngStyle]="getDropdownStyle(chartType)">
    <!--          <mat-label>Chart Type</mat-label>-->
    <mat-select


      (ngModelChange)="onSelectionChange($event)"
      [(ngModel)]="selectedItem">
      <mat-option [value]="undefined">None</mat-option>
      <mat-option [disabled]="!legendItem.hidden" [value]="legendItem"
                  *ngFor="let legendItem of legendItems ; let i = index">
        {{legendItem.text}}
      </mat-option>
    </mat-select>
  </mat-form-field>
</div>
