import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {PicklistValue} from '../../../models/picklist-value';
import {ToxEndpointTableItem} from '../../../models/tox-endpoint-table-item';
import {ToxAdditionalField} from '../../../models/tox-additional-field';
import {DocumentMenuItem} from '../../../models/document-menu-item';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute} from '@angular/router';
import {ExceptionService} from '../../../../core/exception.service';
import {ServerService} from '../../../../core/server.service';
import {ToastrService} from 'ngx-toastr';
import {ToxExistingEndpoint} from '../../../models/tox-existing-endpoint';
import {convertArrayToString} from '../../../../shared/convert-array-to-string';
import {CreateObjectResponse, CreateObjectsResponse} from '../../../models/create-objects-response';
import {VaultError} from '../../../../core/models/vault-error';
import {HttpErrorResponse} from '@angular/common/http';

let me: NeuroImmunotoxicityExistingComponent;

@Component({
  selector: 'app-neuro-immunotoxicity-existing',
  templateUrl: './neuro-immunotoxicity-existing.component.html',
  styleUrls: ['../../../existing_record.css']
})
export class NeuroImmunotoxicityExistingComponent implements OnInit {
  @Input() arrayedEndpointRecord: ToxExistingEndpoint;
  @Input() category: any;
  @Input() allStudyTypes: Array<PicklistValue>;
  @Input() allRouteOfAdministration: Array<PicklistValue>;
  @Input() allTestSystemTypes: Array<PicklistValue>;
  @Input() allTestSystemNames: Array<PicklistValue>;
  @Input() allEndpoints: Array<PicklistValue>;
  @Input() allAdditionalFields: Array<ToxAdditionalField>;
  @Input() allAdditionalFieldValues: Array<PicklistValue>;
  @Input() substanceTypes: Array<PicklistValue>;
  @Input() relatedSubstances: any;
  @Input() documents: Array<DocumentMenuItem>;
  @Input() allUsers: any;

  @Output() removeInput: EventEmitter<ToxEndpointTableItem> = new EventEmitter();
  @Output() copyExisting: EventEmitter<any> = new EventEmitter();

  subscriptions: any;
  selectFormGroup: FormGroup;
  configureFormGroup: FormGroup;
  public value3: any;
  public existingEndpointRecord: any;
  public preAdditionalFields: Array<ToxAdditionalField>;
  public additionalFields: Array<ToxAdditionalField>;
  public additionalFieldValues: Map<string, any>;
  public hiddenAdditionalFields: Array<ToxAdditionalField>;
  public userName: any;
  public createdBy: any;
  public modifiedBy: any;
  public formatCreatedDate: string;
  public formatModifiedDate: string;
  private haveErrors: Boolean;
  showExistingRecord = true;
  showEditRecord = false;
  public purposeMessage: string;
  private sessionId: string;

  constructor(
    private route: ActivatedRoute,
    private exceptionService: ExceptionService,
    private serverService: ServerService,
    private toastrService: ToastrService,
    private formBuilder: FormBuilder
  ) {
    me = this;
    this.subscriptions = {};
    this.route.paramMap.subscribe((params) => {
      this.sessionId = params.get('sessionId');
    });
    this.selectFormGroup = this.formBuilder.group({
      category__c: [{disabled: true}],
      substanceType: [],
      relatedSubstance: [{value: '', disabled: true}],
      studyType: [{value: '', disabled: false}, Validators.required],
      test_system_type__c: [{value: '', disabled: false}],
      route_of_administration__c: [{value: '', disabled: false}],
      test_system_name__c: [{value: '', disabled: false}],
      endpoint: [{value: '', disabled: false}, Validators.required],
      value_3__c: [{value: '', disabled: false}],
      // additional fields
      noael_neurotoxicity_mgkg_bwd__c: [{value: '', disabled: false}],
      loael_neurotoxicity_mgkg_bwd__c: [{value: '', disabled: false}],
      srbc_assay__c: [{value: '', disabled: false}],
      lymphocyte_subpopulation__c: [{value: '', disabled: false}],
      nk_cell_activity_test__c: [{value: '', disabled: false}],
      noael_immunotoxicity_mgkg_bwd__c: [{value: '', disabled: false}],
      loael_immunotoxicity_mgkg_bwd__c: [{value: '', disabled: false}],
      target_organ__c: [{value: '', disabled: false}],
      // default
      source: [{value: '', disabled: false}],
    });
    this.value3 = [{label: 'Yes', name: 'yes'}, {label: 'No', name: 'no'}, {label: 'Equivocal', name: 'equivocal'}];
    this.preAdditionalFields = [];
    this.additionalFields = [];
    this.additionalFieldValues = new Map();
    this.purposeMessage = 'editOnExisting';
  }

  ngOnInit(): void {

    this.selectFormGroup.disable();

    // convert record values to string values
    this.existingEndpointRecord = JSON.parse(
      JSON.stringify(this.arrayedEndpointRecord)
    );

    convertArrayToString(
      this.arrayedEndpointRecord,
      this.existingEndpointRecord,
      []
    );

    if (this.existingEndpointRecord.created_by__v === 1) {

      this.createdBy = [{name: 'System'}];
    } else {
      this.createdBy = this.allUsers.filter(
        (c) => c.id === this.existingEndpointRecord.created_by__v
      );
    }

    if (this.existingEndpointRecord.modified_by__v === 1) {
      // eslint-disable-next-line quote-props
      this.modifiedBy = [{name: 'System'}];
    } else {
      this.modifiedBy = this.allUsers.filter(
        (m) => m.id === this.existingEndpointRecord.modified_by__v
      );
    }

    this.formatCreatedDate =
      this.existingEndpointRecord.created_date__v.substring(0, 10) +
      ', ' +
      this.existingEndpointRecord.created_date__v.substring(11, 16) +
      'h Z';
    this.formatModifiedDate =
      this.existingEndpointRecord.modified_date__v.substring(0, 10) +
      ', ' +
      this.existingEndpointRecord.modified_date__v.substring(11, 16) +
      'h Z';

    this.selectFormGroup.get('source').setValue(this.existingEndpointRecord.document_unbound__c);

    this.setAdditionalFields();
    this.setFormFields();
  }

  setAdditionalFields(): any {
    this.additionalFields = [];
    const filteredAdditionalFields = this.allAdditionalFields.filter(
      (entry) =>
        entry.group === this.existingEndpointRecord.study_type_picklist_name__c
    );
    this.additionalFields = filteredAdditionalFields.sort((a, b) => a.sortNumber - b.sortNumber);
  }

  enableEditing(): any {
    this.showExistingRecord = false;
    this.showEditRecord = true;
  }

  cancelEditing(): void {
    this.showExistingRecord = true;
    this.showEditRecord = false;
  }

  saveChanges(endpointRecord): void {
    this.haveErrors = false;

    this.serverService.isLoading();

    delete endpointRecord.created_by__v;
    delete endpointRecord.modified_by__v;
    delete endpointRecord.created_date__v;
    delete endpointRecord.modified_date__v;

    const value1 = parseInt(String(endpointRecord.value_1__c));
    const value2 = parseInt(String(endpointRecord.value_2__c));

    if (endpointRecord.operator__c === 'between__c') {
      if (value2 <= value1 || isNaN(value1) || isNaN(value2)) {
        this.haveErrors = true;
        console.log(value1 + ', ' + value2);
      }
    }

    if (this.haveErrors === true) {
      console.log('saving with errors!');
      this.serverService.isNotLoading();
      this.toastrService.error('Value 2 must be greater than Value 1', 'Values Error');
    }
    else {
      const body = Array(endpointRecord);

      this.serverService.updateEndpoint(this.sessionId, body).subscribe(
        (success: CreateObjectsResponse) => {
          let updated = 0;
          success.data?.forEach((record: CreateObjectResponse) => {
            if (record?.responseStatus === 'SUCCESS') {
              updated++;
            }
            if (record?.responseStatus === 'FAILURE') {
              record?.errors?.forEach((error: VaultError) => {
                this.toastrService.error(error.message, error.type);
              });
            }
          });

          this.toastrService.info(
            ` Endpoint updated successfully.`,
            'Info'
          );
          this.serverService.isNotLoading();
        },
        (error: HttpErrorResponse) => {
          this.exceptionService.handleError(error);
          this.toastrService.error(
            `An error occurred while updating endpoint.`,
            'Error'
          );
          this.serverService.isNotLoading();
        }
      );

      this.showExistingRecord = true;
      this.showEditRecord = false;
      this.existingEndpointRecord = endpointRecord;
      this.setAdditionalFields();
      this.setFormFields();
    }
  }

  copyExistingEndpoint(endpoint: ToxExistingEndpoint): any {
    this.copyExisting.emit(endpoint);
  }

  filterAdditionalFieldValues(
    allAdditionalFieldValues: Array<PicklistValue>,
    filterWord: string
  ): Array<PicklistValue> {
    return allAdditionalFieldValues.filter(
      (item) => item.picklist === filterWord
    );
  }

  setFormFields(): void {
    this.selectFormGroup.get('category__c').setValue(this.existingEndpointRecord.category__c);
    this.selectFormGroup.get('substanceType').setValue(this.existingEndpointRecord.substance_type__c);
    this.selectFormGroup.get('relatedSubstance').setValue(this.existingEndpointRecord.related_substance__c);
    this.selectFormGroup.get('studyType').setValue(this.existingEndpointRecord.study_type__c);
    this.selectFormGroup.get('test_system_type__c').setValue(this.existingEndpointRecord.test_system_type__c);
    this.selectFormGroup.get('route_of_administration__c').setValue(this.existingEndpointRecord.route_of_administration__c);
    this.selectFormGroup.get('test_system_name__c').setValue(this.existingEndpointRecord.test_system_name__c);
    this.selectFormGroup.get('endpoint').setValue(this.existingEndpointRecord.endpoint__c);
    this.selectFormGroup.get('value_3__c').setValue(this.existingEndpointRecord.value_3__c);
    // additional fields
    this.selectFormGroup.get('noael_neurotoxicity_mgkg_bwd__c').setValue(this.existingEndpointRecord.noael_neurotoxicity_mgkg_bwd__c);
    this.selectFormGroup.get('loael_neurotoxicity_mgkg_bwd__c').setValue(this.existingEndpointRecord.loael_neurotoxicity_mgkg_bwd__c);
    this.selectFormGroup.get('srbc_assay__c').setValue(this.existingEndpointRecord.srbc_assay__c);
    this.selectFormGroup.get('lymphocyte_subpopulation__c').setValue(this.existingEndpointRecord.lymphocyte_subpopulation__c);
    this.selectFormGroup.get('nk_cell_activity_test__c').setValue(this.existingEndpointRecord.nk_cell_activity_test__c);
    this.selectFormGroup.get('noael_immunotoxicity_mgkg_bwd__c').setValue(this.existingEndpointRecord.noael_immunotoxicity_mgkg_bwd__c);
    this.selectFormGroup.get('loael_immunotoxicity_mgkg_bwd__c').setValue(this.existingEndpointRecord.loael_immunotoxicity_mgkg_bwd__c);
    if (this.existingEndpointRecord.target_organ__c) {
      this.selectFormGroup.get('target_organ__c').setValue(this.existingEndpointRecord.target_organ__c.split(','));
    } else {
      this.selectFormGroup.get('target_organ__c').setValue('');
    }
    // default
    this.selectFormGroup.get('source').setValue(this.existingEndpointRecord.document_unbound__c);
  }

}


