import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ConsumerSafetyEndpointTableItem} from '../../models/consumer-safety-endpoint-table-item';
import {PicklistValue} from '../../../models/picklist-value';
import {ConsumerSafetyCategoryPicklists} from '../../models/consumer-safety-category-picklists';
import {SubstanceItem} from '../../../models/substance-item';
import {RelatedSubstances} from '../../../models/related-substances';
import {DocumentMenuItem} from '../../../models/document-menu-item';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {ExceptionService} from '../../../../core/exception.service';
import {ServerService} from '../../../../core/server.service';

@Component({
  selector: 'app-waiver-and-data-gaps',
  templateUrl: './waiver-and-data-gaps.component.html',
  styleUrls: ['../../../record_style.css']
})
export class WaiverAndDataGapsComponent implements OnInit {
  @Input() singleEndpointRecord: ConsumerSafetyEndpointTableItem;
  @Input() category: PicklistValue;
  @Input() categoryPicklists: Array<ConsumerSafetyCategoryPicklists>;
  @Input() allStudyTypes: Array<PicklistValue>;
  @Input() substance: SubstanceItem;
  @Input() substanceTypes: Array<PicklistValue>;
  @Input() relatedSubstances: Array<RelatedSubstances>;
  @Input() documents: Array<DocumentMenuItem>;
  @Input() purpose: string;

  @Output() removeInput: EventEmitter<ConsumerSafetyEndpointTableItem> = new EventEmitter();
  @Output() copyInput: EventEmitter<ConsumerSafetyEndpointTableItem> = new EventEmitter();
  @Output() cancelEdit: EventEmitter<ConsumerSafetyEndpointTableItem> = new EventEmitter();
  @Output() saveChanges: EventEmitter<ConsumerSafetyEndpointTableItem> = new EventEmitter();

  subscriptions: any;
  selectFormGroup: FormGroup;
  configureFormGroup: FormGroup;
  public studyTypes: Array<PicklistValue>;
  public filteredRelatedSubstances: any;
  private sessionId: string;
  public showCopyOptions: boolean;
  public showEditOptions: boolean;
  public waiverDataGaps: any;

  constructor(private route: ActivatedRoute,
              private exceptionService: ExceptionService,
              private serverService: ServerService,
              private toastrService: ToastrService,
              private formBuilder: FormBuilder) {
    this.subscriptions = {};
    this.route.paramMap.subscribe((params) => {
      this.sessionId = params.get('sessionId');
    });
    this.selectFormGroup = this.formBuilder.group({
      waiverdata_gap__c: [{value: '', disabled: false}],
      studyType: [{value: '', disabled: false}, Validators.required],
      // additional (depended) fields
      comment__c: [{value: '', disabled: false}],
      // default
      selectedDocument: [{value: '', disabled: false}],
    });
    this.filteredRelatedSubstances = [];
    this.waiverDataGaps = [{label: 'Waiver', name: 'waiver__c'}, {label: 'Data Gap', name: 'data_gap__c'}];
  }

  ngOnInit(): void {
    this.singleEndpointRecord.study_type_picklist_name__c = 'study_type_waiver_data_gaps__c';

    console.log('record: ' + JSON.stringify(this.singleEndpointRecord));

    if (this.purpose === 'editOnExisting') {
      this.showCopyOptions = false;
      this.showEditOptions = true;
      this.singleEndpointRecord.study_type__c.toString();
    }
    else {
      this.showCopyOptions = true;
      this.showEditOptions = false;
    }

    this.selectFormGroup.get('selectedDocument').setValue(this.singleEndpointRecord.document_unbound__c);
    this.studyTypes = this.allStudyTypes;

    this.setFormFields();
  }


  onSelectStudyType(event: any): any {
    this.singleEndpointRecord.study_type__c = event.value;
  }

  getSelectedOptionPicklist(event: any): string {
    return event.target.options[event.target.options.selectedIndex].title;
  }

  updateFieldValue(event: any): any {
    const fieldName = event.title;
    this.singleEndpointRecord[fieldName] = event.value;
  }


  removeEndpoint(singleEndpointRecord: ConsumerSafetyEndpointTableItem): any {
    this.removeInput.emit(singleEndpointRecord);
  }

  copyEndpoint(singleEndpointRecord: ConsumerSafetyEndpointTableItem): void {
    this.copyInput.emit(singleEndpointRecord);
  }

  setFormFields(): void {
    this.selectFormGroup.get('waiverdata_gap__c').setValue(this.singleEndpointRecord.waiverdata_gap__c);
    this.selectFormGroup.get('studyType').setValue(this.singleEndpointRecord.study_type__c);
    this.selectFormGroup.get('comment__c').setValue(this.singleEndpointRecord.comment__c);
    // default
    this.selectFormGroup.get('selectedDocument').setValue(this.singleEndpointRecord.document_unbound__c);
  }

  cancelEditing(endpointRecord): void {
    this.cancelEdit.emit(endpointRecord);
  }

  saveEndpointChanges(endpointRecord): void {
    this.saveChanges.emit(endpointRecord);
  }

}
