import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {PicklistValue} from '../../../models/picklist-value';
import {ToxEndpointTableItem} from '../../../models/tox-endpoint-table-item';
import {ToxAdditionalField} from '../../../models/tox-additional-field';
import {DocumentMenuItem} from '../../../models/document-menu-item';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute} from '@angular/router';
import {ExceptionService} from '../../../../core/exception.service';
import {ServerService} from '../../../../core/server.service';
import {ToastrService} from 'ngx-toastr';
import {ToxExistingEndpoint} from '../../../models/tox-existing-endpoint';
import {convertArrayToString} from '../../../../shared/convert-array-to-string';
import {CreateObjectResponse, CreateObjectsResponse} from '../../../models/create-objects-response';
import {VaultError} from '../../../../core/models/vault-error';
import {HttpErrorResponse} from '@angular/common/http';

let me: ReferenceDoseLevelsExistingComponent;

@Component({
  selector: 'app-reference-dose-levels-existing',
  templateUrl: './reference-dose-levels-existing.component.html',
  styleUrls: ['../../../existing_record.css']
})

export class ReferenceDoseLevelsExistingComponent implements OnInit {
  @Input() arrayedEndpointRecord: ToxExistingEndpoint;
  @Input() category: any;
  @Input() allStudyTypes: Array<PicklistValue>;
  @Input() allEndpoints: Array<PicklistValue>;
  @Input() allEndpointUnits: Array<PicklistValue>;
  @Input() allOperators: Array<PicklistValue>;
  @Input() allAdditionalFields: Array<ToxAdditionalField>;
  @Input() allAdditionalFieldValues: Array<PicklistValue>;
  @Input() substanceTypes: Array<PicklistValue>;
  @Input() relatedSubstances: any;
  @Input() documents: Array<DocumentMenuItem>;
  @Input() allUsers: any;

  @Output() removeInput: EventEmitter<ToxEndpointTableItem> = new EventEmitter();
  @Output() copyExisting: EventEmitter<any> = new EventEmitter();

  subscriptions: any;
  selectFormGroup: FormGroup;
  configureFormGroup: FormGroup;
  public existingEndpointRecord: any;
  public preAdditionalFields: Array<ToxAdditionalField>;
  public additionalFields: Array<ToxAdditionalField>;
  public additionalFieldValues: Map<string, any>;
  public hiddenAdditionalFields: Array<ToxAdditionalField>;
  public operators: Array<PicklistValue>;
  public filteredRelatedSubstances: any;
  public isValue1Disabled: boolean;
  public isValue2Disabled: boolean;
  public industryAuthorities: Array<PicklistValue>;
  public allSourceNames: Array<PicklistValue>;
  public userName: any;
  public createdBy: any;
  public modifiedBy: any;
  public formatCreatedDate: string;
  public formatModifiedDate: string;
  private haveErrors: Boolean;
  showExistingRecord = true;
  showEditRecord = false;
  public purposeMessage: string;
  private sessionId: string;

  constructor(
    private route: ActivatedRoute,
    private exceptionService: ExceptionService,
    private serverService: ServerService,
    private toastrService: ToastrService,
    private formBuilder: FormBuilder
  ) {
    me = this;
    this.subscriptions = {};
    this.route.paramMap.subscribe((params) => {
      this.sessionId = params.get('sessionId');
    });
    this.selectFormGroup = this.formBuilder.group({
      category__c: [{disabled: true}],
      substanceType: [],
      relatedSubstance: [{value: '', disabled: true}],
      studyType: [{value: '', disabled: false}, Validators.required],
      industryauthority__c: [{value: '', disabled: false}],
      source_name__c: [{value: '', disabled: false}],
      endpoint: [{value: '', disabled: false}, Validators.required],
      endpointUnit: [{value: '', disabled: false}, Validators.required],
      operator: ['', Validators.required],
      value_1__c: [{value: '', disabled: false}],
      value_2__c: [{value: '', disabled: false}],
      // additional fields
      safety_factor__c: [{value: '', disabled: false}],
      study_endpoint__c: [{value: '', disabled: false}],
      study__c: [{value: '', disabled: false}],
      species__c: [{value: '', disabled: false}],
      oral_absorption__c: [{value: '', disabled: false}],
      additional_fqpa_us_epa__c: [{value: '', disabled: false}],
      // default
      source: [{value: '', disabled: false}],
    });
    this.industryAuthorities = [
      {name__v: '', id: 'V5Z000000007004', name: 'authority__c', label: 'Authority', picklist: 'industryauthority__c'},
      {name__v: '', id: 'V5Z000000007004', name: 'industry__c', label: 'Industry', picklist: 'industryauthority__c'}
    ];
    this.allSourceNames = [
      {name__v: '', id: 'V5Z000000007004', name: 'eufinal__c', label: 'EU-final', picklist: 'source_name__c'},
      {name__v: '', id: 'V5Z000000007004', name: 'epa__c', label: 'EPA', picklist: 'source_name__c'},
      {name__v: '', id: 'V5Z000000007004', name: 'eurms__c', label: 'EU-RMS', picklist: 'source_name__c'},
      {name__v: '', id: 'V5Z000000007004', name: 'jmpr__c', label: 'JMPR', picklist: 'source_name__c'},
      {name__v: '', id: 'V5Z000000007004', name: 'basf__c', label: 'BASF', picklist: 'source_name__c'},
      {name__v: '', id: 'V5Z000000007004', name: 'other_industry_than_basf__c', label: 'Other industry than BASF', picklist: 'source_name__c'}
    ];
    this.preAdditionalFields = [];
    this.additionalFields = [];
    this.additionalFieldValues = new Map();
    this.isValue1Disabled = true;
    this.isValue2Disabled = true;
    this.operators = [];
    this.purposeMessage = 'editOnExisting';
  }

  ngOnInit(): void {

    this.selectFormGroup.disable();

    // convert record values to string values
    this.existingEndpointRecord = JSON.parse(
      JSON.stringify(this.arrayedEndpointRecord)
    );

    convertArrayToString(
      this.arrayedEndpointRecord,
      this.existingEndpointRecord,
      []
    );

    const filteredOperators = [
      'a1595852199787__c',
      'a1595852189563__c',
      'a1595852194618__c',
      'a1595852268945__c',
      'a1595852266201__c',
      'between__c',
      'a1595852359285__c'
    ];

    filteredOperators.forEach(operator => {
        const singleOperator = this.allOperators.filter(
          (entry) => entry.name === operator
        );
        this.operators = this.operators.concat(singleOperator);
      }
    );

    if (this.existingEndpointRecord.created_by__v === 1) {

      this.createdBy = [{name: 'System'}];
    } else {
      this.createdBy = this.allUsers.filter(
        (c) => c.id === this.existingEndpointRecord.created_by__v
      );
    }

    if (this.existingEndpointRecord.modified_by__v === 1) {
      // eslint-disable-next-line quote-props
      this.modifiedBy = [{name: 'System'}];
    } else {
      this.modifiedBy = this.allUsers.filter(
        (m) => m.id === this.existingEndpointRecord.modified_by__v
      );
    }

    this.formatCreatedDate =
      this.existingEndpointRecord.created_date__v.substring(0, 10) +
      ', ' +
      this.existingEndpointRecord.created_date__v.substring(11, 16) +
      'h Z';
    this.formatModifiedDate =
      this.existingEndpointRecord.modified_date__v.substring(0, 10) +
      ', ' +
      this.existingEndpointRecord.modified_date__v.substring(11, 16) +
      'h Z';

    this.selectFormGroup.get('source').setValue(this.existingEndpointRecord.document_unbound__c);

    this.setAdditionalFields();
    this.setFormFields();
  }

  setAdditionalFields(): any {
    this.additionalFields = [];
    switch (this.existingEndpointRecord.operator__c) {
      case 'between__c':
        this.isValue1Disabled = false;
        this.isValue2Disabled = false;
        this.selectFormGroup.get('value_1__c').enable();
        this.selectFormGroup.get('value_2__c').enable();
        break;
      case '':
        this.isValue1Disabled = true;
        this.isValue2Disabled = true;
        this.selectFormGroup.get('value_1__c').disable();
        this.selectFormGroup.get('value_2__c').disable();
        this.selectFormGroup.get('value_1__c').setValue(null);
        this.selectFormGroup.get('value_2__c').setValue(null);
        this.existingEndpointRecord.value_1__c = null;
        this.existingEndpointRecord.value_2__c = null;
        break;
      default:
        this.isValue1Disabled = false;
        this.isValue2Disabled = true;
        this.selectFormGroup.get('value_1__c').enable();
        this.selectFormGroup.get('value_2__c').disable();
        this.selectFormGroup.get('value_2__c').setValue(null);
        this.existingEndpointRecord.value_2__c = null;
        break;
    }

    if (this.existingEndpointRecord.source_name__c === 'epa__c') {
      // show all fields
      const preAllAdditionalFields = this.allAdditionalFields.filter(
        (entry) =>
          entry.group === this.existingEndpointRecord.study_type_picklist_name__c
      );
      this.additionalFields = preAllAdditionalFields.sort((a, b) => a.sortNumber - b.sortNumber);
    }
    else {
      const preAllAdditionalFields = this.allAdditionalFields.filter(
        (entry) =>
          entry.group === this.existingEndpointRecord.study_type_picklist_name__c
      );

      // remove fields mark with 'yes__c' in Initially Hidden field
      const preAdditionalFields = preAllAdditionalFields.filter(obj =>
        obj.hidden[0] !== 'yes__c'
      );
      this.additionalFields = preAdditionalFields.sort((a, b) => a.sortNumber - b.sortNumber);
    }
  }

  enableEditing(): any {
    this.showExistingRecord = false;
    this.showEditRecord = true;
  }

  cancelEditing(): void {
    this.showExistingRecord = true;
    this.showEditRecord = false;
  }

  saveChanges(endpointRecord): void {
    this.haveErrors = false;

    this.serverService.isLoading();

    delete endpointRecord.created_by__v;
    delete endpointRecord.modified_by__v;
    delete endpointRecord.created_date__v;
    delete endpointRecord.modified_date__v;

    const value1 = parseInt(String(endpointRecord.value_1__c));
    const value2 = parseInt(String(endpointRecord.value_2__c));

    if (endpointRecord.operator__c === 'between__c') {
      if (value2 <= value1 || isNaN(value1) || isNaN(value2)) {
        this.haveErrors = true;
        console.log(value1 + ', ' + value2);
      }
    }

    if (this.haveErrors === true) {
      console.log('saving with errors!');
      this.serverService.isNotLoading();
      this.toastrService.error('Value 2 must be greater than Value 1', 'Values Error');
    }
    else {
      const body = Array(endpointRecord);

      this.serverService.updateEndpoint(this.sessionId, body).subscribe(
        (success: CreateObjectsResponse) => {
          let updated = 0;
          success.data?.forEach((record: CreateObjectResponse) => {
            if (record?.responseStatus === 'SUCCESS') {
              updated++;
            }
            if (record?.responseStatus === 'FAILURE') {
              record?.errors?.forEach((error: VaultError) => {
                this.toastrService.error(error.message, error.type);
              });
            }
          });

          this.toastrService.info(
            ` Endpoint updated successfully.`,
            'Info'
          );
          this.serverService.isNotLoading();
        },
        (error: HttpErrorResponse) => {
          this.exceptionService.handleError(error);
          this.toastrService.error(
            `An error occurred while updating endpoint.`,
            'Error'
          );
          this.serverService.isNotLoading();
        }
      );

      this.showExistingRecord = true;
      this.showEditRecord = false;
      this.existingEndpointRecord = endpointRecord;
      this.setAdditionalFields();
      this.setFormFields();
    }
  }

  copyExistingEndpoint(endpoint: ToxExistingEndpoint): any {
    this.copyExisting.emit(endpoint);
  }

  filterAdditionalFieldValues(
    allAdditionalFieldValues: Array<PicklistValue>,
    filterWord: string
  ): Array<PicklistValue> {
    return allAdditionalFieldValues.filter(
      (item) => item.picklist === filterWord
    );
  }

  setFormFields(): void {
    this.selectFormGroup.get('category__c').setValue(this.existingEndpointRecord.category__c);
    this.selectFormGroup.get('substanceType').setValue(this.existingEndpointRecord.substance_type__c);
    this.selectFormGroup.get('relatedSubstance').setValue(this.existingEndpointRecord.related_substance__c);
    this.selectFormGroup.get('studyType').setValue(this.existingEndpointRecord.study_type__c);
    this.selectFormGroup.get('industryauthority__c').setValue(this.existingEndpointRecord.industryauthority__c);
    this.selectFormGroup.get('source_name__c').setValue(this.existingEndpointRecord.source_name__c);
    this.selectFormGroup.get('endpoint').setValue(this.existingEndpointRecord.endpoint__c);
    this.selectFormGroup.get('endpointUnit').setValue(this.existingEndpointRecord.endpoint_unit__c);
    this.selectFormGroup.get('operator').setValue(this.existingEndpointRecord.operator__c);
    this.selectFormGroup.get('value_1__c').setValue(this.existingEndpointRecord.value_1__c);
    this.selectFormGroup.get('value_2__c').setValue(this.existingEndpointRecord.value_2__c);
    // additional fields
    this.selectFormGroup.get('safety_factor__c').setValue(this.existingEndpointRecord.safety_factor__c);
    this.selectFormGroup.get('study_endpoint__c').setValue(this.existingEndpointRecord.study_endpoint__c);
    if (this.existingEndpointRecord.study__c) {
      this.selectFormGroup.get('study__c').setValue(this.existingEndpointRecord.study__c.split(','));
    } else {
      this.selectFormGroup.get('study__c').setValue('');
    }
    this.selectFormGroup.get('species__c').setValue(this.existingEndpointRecord.species__c);
    this.selectFormGroup.get('oral_absorption__c').setValue(this.existingEndpointRecord.oral_absorption__c);
    this.selectFormGroup.get('additional_fqpa_us_epa__c').setValue(this.existingEndpointRecord.additional_fqpa_us_epa__c);
    // default
    this.selectFormGroup.get('source').setValue(this.existingEndpointRecord.document_unbound__c);
  }

}


