import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {ServerService} from '../../core/server.service';
import {ActivatedRoute} from '@angular/router';
import {ExceptionService} from '../../core/exception.service';
import {ToastrService} from 'ngx-toastr';
import {TimelineDatesData} from '../models/timeline-dates-response';
import {MatPaginator} from '@angular/material/paginator';
import {transformDates} from '../../shared/transform-dates';
import {convertBinaryDataToExcel} from '../../shared/convert-binary-to-excel';
import {Subscription} from 'rxjs';
import {Picklist} from '../../shared/picklist';
import {CalculationType} from '../models/calculation-type';

@Component({
  selector: 'app-history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.css'],
})
export class HistoryComponent implements OnInit, OnDestroy {
  subscriptions: any;
  displayedColumns: Array<string>;
  dataSource: MatTableDataSource<any>;
  selectedId: string;
  showLoading = true;
  pageSize: number;
  dataSourceLength: number;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  sessionId: string;
  fileName = 'timeline history dates';
  decisionTypePicklistName = Picklist.decisionType;
  decisionTypeMap: Map<string, string>;

  constructor(
    private route: ActivatedRoute,
    private exceptionService: ExceptionService,
    private serverService: ServerService,
    private toastrService: ToastrService
  ) {
    this.subscriptions = {};
    this.route.paramMap.subscribe((params) => {
      this.selectedId = params.get('id');
      this.sessionId = params.get('sessionId');
    });

    this.pageSize = 20;
    this.dataSourceLength = 0;

    this.displayedColumns = [
      'Last Modified Date',
      'Expiration Date',
      'Registration Timeline',
      'Application',
      'Submission',
      'DAR/dRAR',
      'Public Consultation Open',
      'Public Consultation Close',
      'EFSA Conclusion Deadline',
      'EFSA Conclusion',
      '1st Commission Proposal',
      'Entry into Force Date',
      'Decision Type',
      'Latest Confirmatory Data Deadline',
    ];
    this.dataSource = new MatTableDataSource();

    this.decisionTypeMap = new Map<string, string>();
    this.subscriptions.getStatusPicklist = this.getPicklist(
      this.decisionTypeMap,
      this.decisionTypePicklistName,
      this.sessionId
    );
  }

  ngOnInit(): void {
    this.subscriptions.postTimelineHistoryDates = this.serverService
      .postTimelineHistoryDates(this.sessionId, this.selectedId)
      .subscribe(
        (response) => {
          if ((response.data as Array<TimelineDatesData>).length < 1) {
            this.toastrService.warning(
              'No timeline history records to show!',
              'Warning'
            );
          }
          (response.data as Array<TimelineDatesData>).forEach((it) => {
            this.prepareDates(it);
          });
          this.loadTable(response.data as Array<TimelineDatesData>);
        },
        (error) => {
          this.showLoading = false;
          console.log(error);
          this.dataSource.data = [];
          this.toastrService.error(
            'Unable to get timeline history data!',
            'Error'
          );
        },
        () => {
          this.showLoading = false;
          console.log('Get timeline history dates request completed.');
        }
      );
  }

  ngOnDestroy(): void {
    // prevent memory leak when component destroyed
    for (const subscription in this.subscriptions) {
      if (this.subscriptions.hasOwnProperty(subscription)) {
        this.subscriptions[subscription].unsubscribe();
      }
    }
  }

  loadTable(histories: Array<TimelineDatesData>): void {
    const list: Array<TimelineDatesData> = [];
    for (const history of histories) {
      history.registration_timeline = 'Actual';
      list.push(history);

      const adjustedRow: any = {};
      adjustedRow.registration_timeline = 'Adjusted';
      const estimationRow: any = {};
      estimationRow.registration_timeline = 'Estimated';

      this.setEstimatedAndAdjustedFields(
        history.application_calculation_type__c,
        estimationRow,
        adjustedRow,
        'application_date__c',
        history.estimated_application_date__c);

      this.setEstimatedAndAdjustedFields(
        history.submission_calculation_type__c,
        estimationRow,
        adjustedRow,
        'actual_submission_date__c',
        history.estimated_submission_date__c);

      this.setEstimatedAndAdjustedFields(
        history.dardrar_calculation_type__c,
        estimationRow,
        adjustedRow,
        'dardrar_date__c',
        history.estimated_dardrar_date__c);

      this.setEstimatedAndAdjustedFields(
        history.efsa_conclusion_calculation_type__c,
        estimationRow,
        adjustedRow,
        'efsa_conclusion_date__c',
        history.estimated_efsa_conclusion_date__c);

      this.setEstimatedAndAdjustedFields(
        history['1st_commission_proposal_calculation_type__c'],
        estimationRow,
        adjustedRow,
        '1st_commission_proposal_date__c',
        history.estimated_1st_commission_proposal_date__c);

      this.setEstimatedAndAdjustedFields(
        history.entry_into_force_calculation_type__c,
        estimationRow,
        adjustedRow,
        'entry_into_force__c',
        history.estimated_entry_into_force_date__c);

      list.push(adjustedRow);
      list.push(estimationRow);
    }

    // load 10 entries initially to speed up and after a timeout load entire list
    const shortList = list.slice(0, 10);
    this.dataSourceLength = shortList.length;
    this.dataSource.data = shortList;

    setTimeout(() => {
      this.dataSourceLength = list.length;
      this.dataSource.data = list;
      this.dataSource.paginator = this.paginator;
    }, 1000);
  }

  setEstimatedAndAdjustedFields(type: string, estimationRow: any, adjustedRow: any, fieldName: string, fieldValue: string): void {
    if (type === CalculationType.BASIC) {
      estimationRow[fieldName] = fieldValue;
      adjustedRow[fieldName] = '';
    } else if (type === CalculationType.ADJUSTED) {
      adjustedRow[fieldName] = fieldValue;
      estimationRow[fieldName] = '';
    } else {
      estimationRow[fieldName] = '';
      adjustedRow[fieldName] = '';
    }
  }

  prepareDates(data: TimelineDatesData): void {
    for (const property in data) {
      if (
        Object.prototype.hasOwnProperty.call(data, property) &&
        data[property] != null
      ) {
        if (
          property.includes('decision_type__c') &&
          this.decisionTypeMap.get(data[property][0]) !== undefined
        ) {
          data[property] = this.decisionTypeMap.get(data[property][0]);
        } else {
          data[property] = transformDates(data[property], property);
        }
      }
    }
  }

  getPicklist(
    map: Map<string, string>,
    statusPicklistName: string,
    sessionId: string
  ): Subscription {
    return this.serverService
      .cachePicklist(sessionId, statusPicklistName)
      .subscribe(
        (response) => {
          response.picklistValues.forEach((item) =>
            map.set(item.name, item.label)
          );
        },
        (error) => {
          console.error(error);
        }
      );
  }

  downloadExcel(): void {
    this.subscriptions.downloadExcel = this.serverService
      .downloadExcel(this.dataSource.data, this.displayedColumns, this.fileName)
      .subscribe(
        (response) => {
          convertBinaryDataToExcel(response, this.fileName);
        },
        (error) => {
          console.log(error);
          this.toastrService.error('Unable to download excel file.', 'Error');
        },
        () => console.log('Downloading excel file is completed.')
      );
  }
}
