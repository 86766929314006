<div>

  <div *ngIf="showExistingRecord" class="fieldsHolder" id="viewRecord">

    <div class="copyHolder">
      <button (click)="copyExistingEndpoint(this.existingEndpointRecord)" class="copy" data-cy="CopyEndpoint"
              title="Copy Endpoint"><span class="material-icons">content_copy</span></button>
    </div>

    <form [formGroup]="selectFormGroup">

      <div (click)="enableEditing()" class="singleFieldContainer">
        <div class="formItemTitle">Category:</div>
        <div (click)="enableEditing()">
          <select class="formSelector" name="category" formControlName="category__c">
            <option value="{{category.id}}">{{category.name__v}}</option>
          </select>
        </div>
      </div>

      <div class="singleFieldContainer">
        <div class="formItemTitle">Source:</div>
        <div (click)="enableEditing()">
          <select class="disabledDocumentFormSelector" formControlName="source" name="source"
                  title="document_unbound__c">
            <option *ngFor="let document of documents" value="{{ document.id }}">{{ document.name }}</option>
          </select>
        </div>
      </div>

      <div class="singleFieldContainer">
        <div class="formItemTitle">Substance Type:</div>
        <div (click)="enableEditing()">
          <select class="formSelector" formControlName="substanceType" name="substanceType">
            <option value=""></option>
            <option *ngFor="let substaneType of substanceTypes"
                    [value]="substaneType.name">{{ substaneType.label }}</option>
          </select>
        </div>
      </div>

      <div class="singleFieldContainer">
        <div class="formItemTitle">Related Substance:</div>
        <div (click)="enableEditing()">
          <select class="formSelector" formControlName="relatedSubstance" name="relatedSubstance"
                  title="related_substance__c">
            <option selected value=""></option>
            <option *ngFor="let relSubstance of relatedSubstances"
                    value="{{relSubstance.id}}">{{relSubstance.name}}</option>
          </select>
        </div>
      </div>

      <div class="singleFieldContainer">
        <div class="formItemTitle">Study Type:</div>
        <div (click)="enableEditing()">
          <select class="formSelector" formControlName="studyType">
            <option value=""></option>
            <option *ngFor="let studyType of allStudyTypes" [title]="studyType.picklist"
                    value="{{ studyType.name }}">{{ studyType.label }}</option>
          </select>
        </div>
      </div>

      <div class="singleFieldContainer">
        <div class="formItemTitle">Endpoint:</div>
        <div (click)="enableEditing()">
          <select class="formSelector" formControlName="endpoint" name="endpoint">
            <option value=""></option>
            <option *ngFor="let endpoint of allEndpoints" [title]="endpoint.picklist"
                    value="{{ endpoint.name }}">{{ endpoint.label }}</option>
          </select>
        </div>
      </div>

      <div class="singleFieldContainer" [hidden]="isEndpointUnitHidden">
        <div class="formItemTitle">Endpoint Unit:</div>
        <div (click)="enableEditing()">
          <select class="formSelector" formControlName="endpointUnit" name="endpoint-unit">
            <option value=""></option>
            <option *ngFor="let unit of allEndpointUnits" value="{{ unit.name }}">{{ unit.label }}</option>
          </select>
        </div>
      </div>

      <div class="singleFieldContainer">
        <div class="formItemTitle">Operator:</div>
        <div (click)="enableEditing()">
          <select class="formSelector" formControlName="operator" name="operator">
            <option value=""></option>
            <option *ngFor="let operator of allOperators" value="{{ operator.name }}">{{ operator.label }}</option>
          </select>
        </div>
      </div>

      <div class="singleFieldContainer" [hidden]="isValue1Disabled">
        <div class="formItemTitle">Value 1:</div>
        <div (click)="enableEditing()">
          <input class="disabledNumberSelector" formControlName="value1"
                 name="value_1__c" placeholder="Number" type="number"/>
        </div>
      </div>

      <div class="singleFieldContainer" [hidden]="isValue2Disabled">
        <div class="formItemTitle">Value 2:</div>
        <div (click)="enableEditing()">
          <input class="disabledNumberSelector" formControlName="value2"
                 name="value_2__c" placeholder="Number 2" type="number"/>
        </div>
      </div>
      <div class="singleFieldContainer" [hidden]="isValue3Disabled">
        <div class="formItemTitle">Value 3:</div>
        <div (click)="enableEditing()">
          <input class="disabledFormFreeText" formControlName="value3"
                 name="value_3__c" placeholder="Free Text" type="text"/>
        </div>
      </div>

      <div *ngFor="let field of additionalFields" [formGroup]="selectFormGroup" class="singleFieldContainer">
        <div class="formItemTitle">{{field.label}}:</div>
        <div (click)="enableEditing()" [ngSwitch]="field.type">
          <textarea *ngSwitchCase="'text'" [formControlName]="field.name" [title]="field.name"
                    class="disabledFormFreeText" rows="1"></textarea>
          <input *ngSwitchCase="'number'" [formControlName]="field.name" [title]="field.name"
                 class="disabledNumberSelector" type="number">
          <select  *ngSwitchCase="'picklist'"
                   [formControlName]="field.name" [title]="field.name" class="formSelector">
            <option value=""></option>
            <option *ngFor="let opt of allAdditionalFieldValues"
                    [value]="opt.name">{{opt.label}}</option>
          </select>
          <mat-form-field *ngSwitchCase="'multi-picklist'" class="multiPicklistFormSelector">
            <mat-select
              [formControlName]="field.name"
              [title]="field.name"
              class="formSelector"
              multiple ngDefaultControl>
              <mat-option *ngFor="let topping of allAdditionalFieldValues"
                          [value]="topping.name">{{topping.label}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>

      <div class="rightConnerView">
        <div class="singleFieldContainer">
          <div class="authorInfo">Created by {{createdBy[0].name}}
            <br>at {{formatCreatedDate}}
          </div>
        </div>

        <div class="singleFieldContainer">
          <div class="authorInfo">Last Modified by {{modifiedBy[0].name}}
            <br>at {{formatModifiedDate}}
          </div>
        </div>
      </div>

    </form>

  </div>

  <div *ngIf="showEditRecord" class="editableFieldsHolder" id="editable">
    <app-e-fate-record
      (cancelEdit)="cancelEditing()"
      (saveChanges)="saveChanges($event)"
      [singleEndpointRecord]="existingEndpointRecord"
      [purpose]="purposeMessage"
      [category]="category"
      [substanceTypes]="substanceTypes"
      [relatedSubstances]="relatedSubstances"
      [preAllStudyTypes]="allStudyTypes"
      [categoryPicklists]="categoryPicklists"
      [allEndpoints]="allEndpoints"
      [allEndpointUnits]="allEndpointUnits"
      [operators]="allOperators"
      [allAdditionalFields]="allAdditionalFields"
      [allAdditionalFieldValues]="allAdditionalFieldValues"
      [documents]="documents"
    ></app-e-fate-record>
  </div>

</div>
