import {Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {ServerService} from '../../core/server.service';
import {getDateFromString} from '../../shared/get-date-from-string';
import {ExceptionService} from '../../core/exception.service';
import {TimelineDatesData} from '../models/timeline-dates-response';
import {ToastrService} from 'ngx-toastr';
import {Subscription} from 'rxjs';
import {TimelineItem} from '../models/timeline-item';
import {timeline} from '../utils/fields';
import {Picklist} from '../../shared/picklist';

@Component({
  selector: 'app-timeline-page',
  templateUrl: './timeline-page.component.html',
  styleUrls: ['./timeline-page.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class TimelinePageComponent implements OnInit, OnDestroy {
  data: TimelineItem[];
  selectedId: string;
  showLoading = true;
  sessionId: string;
  subscriptions: any;
  programmeMap: Map<string, string>;
  programmePicklistName = Picklist.programme;
  fieldsMap: Map<string, string>;
  objectName = 'ape_registration__c';

  constructor(
    private route: ActivatedRoute,
    private exceptionService: ExceptionService,
    private serverService: ServerService,
    private toastrService: ToastrService
  ) {
    this.route.paramMap.subscribe((params) => {
      this.selectedId = params.get('id');
      this.sessionId = params.get('sessionId');
    });
    this.subscriptions = {};
    this.programmeMap = new Map<string, string>();
    this.subscriptions.getProgrammePicklist = this.getPicklist(
      this.programmeMap,
      this.programmePicklistName,
      this.sessionId
    );
  }

  ngOnInit(): void {
    this.subscriptions.getFieldNames = this.serverService
      .cacheFieldNames(this.sessionId, this.objectName)
      .subscribe(
        (response) => {
          this.fieldsMap = new Map(
            response
              .filter((item) => timeline.includes(item.name))
              .map((item) => [item.name, item.label])
          );
          this.getTimelineDates();
        },
        (error) => {
          console.error(error);
        }
      );
  }

  ngOnDestroy(): void {
    // prevent memory leak when component destroyed
    for (const subscription in this.subscriptions) {
      if (this.subscriptions.hasOwnProperty(subscription)) {
        this.subscriptions[subscription].unsubscribe();
      }
    }
  }

  getPicklist(
    map: Map<string, string>,
    statusPicklistName: string,
    sessionId: string
  ): Subscription {
    return this.serverService
      .cachePicklist(sessionId, statusPicklistName)
      .subscribe(
        (response) => {
          response.picklistValues.forEach((item) =>
            map.set(item.name, item.label)
          );
        },
        (error) => {
          this.showLoading = false;
          console.log(error);
          this.toastrService.error('Unable to load data.', 'Error');
        }
      );
  }

  getTimelineDates(): void {
    this.subscriptions.postTimelineDates = this.serverService
      .postTimelineDates(this.sessionId, this.selectedId)
      .subscribe(
        (response) => {
          this.data = this.createTimelineItems(
            response.data as TimelineDatesData
          );
        },
        (error) => {
          this.showLoading = false;
          console.log(error);
          this.toastrService.error('Unable to get timeline data!', 'Error');
        },
        () => {
          this.showLoading = false;
          console.log('Get timeline dates request completed.');
        }
      );
  }

  createTimelineItems(data: TimelineDatesData): TimelineItem[] {
    const result = Array<TimelineItem>();
    for (const [name, label] of this.fieldsMap.entries()) {
      const date = data[name];
      if (date !== null) {
        const group = name.includes('estimated') ? 2 : 1;
        let trimLabel = label.replace('Estimated ', '');
        if (trimLabel === 'Application Date') {
          trimLabel = this.filterField(data.programme__c[0]);
          if (trimLabel === '') {
            continue;
          }
        }
        const timelineItem = new TimelineItem(
          group,
          getDateFromString(date),
          this.getContent(trimLabel, getDateFromString(date)),
          trimLabel
        );
        result.push(timelineItem);
      }
    }
    return result;
  }

  filterField(programme: string): string {
    const programmeLabel = this.programmeMap.get(programme);
    if (programmeLabel === 'Old Registration') {
      return 'Application';
    } else if (programmeLabel === 'New Registration') {
      return '';
    } else {
      return 'Renewal Application';
    }
  }

  getContent(label: string, date: Date): string {
    return (
      label +
      '</br>' +
      date.getDate() +
      ' ' +
      date.toLocaleDateString(undefined, {month: 'short'}) +
      ' ' +
      date.getFullYear()
    );
  }

  saveImage(imageDataUrl: string): void {
    const link = document.createElement('a');
    link.download = 'timeline - ' + this.formatDate(new Date()) + '.png';
    link.href = imageDataUrl;
    link.click();
  }

  formatDate(date: Date) {
    return (
      [
        date.getFullYear(),
        this.padTo2Digits(date.getMonth() + 1),
        this.padTo2Digits(date.getDate()),
      ].join('-') +
      ' ' +
      [
        this.padTo2Digits(date.getHours()),
        this.padTo2Digits(date.getMinutes()),
        this.padTo2Digits(date.getSeconds())
      ].join(':')
    );
  }

  padTo2Digits(num: number) {
    return num.toString().padStart(2, '0');
  }
}
