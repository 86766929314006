<div class="page-container timeline-page-container">
  <div *ngIf="(showLoading);else timeline" class="ball-loader">
    <div class="ball-loader-ball ball1"></div>
    <div class="ball-loader-ball ball2"></div>
    <div class="ball-loader-ball ball3"></div>
  </div>
  <ng-template #timeline>
    <app-timeline (saveImage)="saveImage($event)" [data]="data"></app-timeline>
  </ng-template>

</div>
