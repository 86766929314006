<div class="header-container">
  <div class="header"><img alt="BASF-logo" class="logo" src="../../../assets/images/BASFw_wh_tr.svg" width="120"></div>
  <div class="navigation-bar">
    <mat-toolbar class="toolbar" color="white">

      <a [queryParams]="{mail:getQueryParamFromSession('userMail'), userId: getQueryParamFromSession('userId'),
        userName:getQueryParamFromSession('userName'), sessionId: getQueryParamFromSession('sessionId')}"
         class="homepage"
         routerLink="home"
         routerLinkActive="active">
        <img alt="home-icon" class="home-icon" height="20px" src="../../../assets/images/home.svg">
        BASF Crystal
      </a>

      <div>
        <mat-nav-list class="nav-item-list">
          <app-menu-list-item (newItemClickEvent)="handleItemExpansion($event)" *ngFor="let item of menu"
                              [item]="item"></app-menu-list-item>
        </mat-nav-list>
      </div>

    </mat-toolbar>
  </div>
</div>
