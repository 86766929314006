import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AbstractControl, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ToastrService} from 'ngx-toastr';
import {ExceptionService} from 'src/app/core/exception.service';
import {ActivatedRoute} from '@angular/router';
import {ServerService} from 'src/app/core/server.service';
import {PicklistValue} from 'src/app/endpoint/models/picklist-value';
import {ToxAdditionalField} from '../../../models/tox-additional-field';
import {SubstanceItem} from '../../../models/substance-item';
import {DocumentMenuItem} from '../../../models/document-menu-item';
import {ToxEndpointTableItem} from '../../../models/tox-endpoint-table-item';

let me: ReferenceDoseLevelsComponent;

@Component({
  selector: 'app-reference-dose-levels',
  templateUrl: './reference-dose-levels.component.html',
  styleUrls: ['../../../record_style.css']
})
export class ReferenceDoseLevelsComponent implements OnInit {
  @Input() singleEndpointRecord: ToxEndpointTableItem;
  @Input() category: PicklistValue;
  @Input() allStudyTypes: Array<PicklistValue>;
  @Input() allEndpoints: Array<PicklistValue>;
  @Input() allEndpointUnits: Array<PicklistValue>;
  @Input() allOperators: Array<PicklistValue>;
  @Input() allAdditionalFields: Array<ToxAdditionalField>;
  @Input() allAdditionalFieldValues: Array<PicklistValue>;
  @Input() substance: SubstanceItem;
  @Input() substanceTypes: Array<PicklistValue>;
  @Input() relatedSubstances: any;
  @Input() documents: Array<DocumentMenuItem>;
  @Input() purpose: string;

  @Output() removeInput: EventEmitter<ToxEndpointTableItem> = new EventEmitter();
  @Output() copyInput: EventEmitter<ToxEndpointTableItem> = new EventEmitter();
  @Output() cancelEdit: EventEmitter<ToxEndpointTableItem> = new EventEmitter();
  @Output() saveChanges: EventEmitter<ToxEndpointTableItem> = new EventEmitter();

  subscriptions: any;
  selectFormGroup: FormGroup;
  configureFormGroup: FormGroup;
  public additionalFields: Array<ToxAdditionalField>;
  public additionalFieldValues: Map<string, any>;
  public studyTypes: Array<PicklistValue>;
  public industryAuthorities: Array<PicklistValue>;
  public allSourceNames: Array<PicklistValue>;
  public sourceNames: Array<PicklistValue>;
  public endpoints: Array<PicklistValue>;
  public endpointUnits: Array<PicklistValue>;
  public operators: Array<PicklistValue>;
  public filteredRelatedSubstances: any;
  public isValue1Disabled: boolean;
  public isValue2Disabled: boolean;
  private sessionId: string;
  public showCopyOptions: boolean;
  public showEditOptions: boolean;
  public studyTypeGroup: string;
  public errorOnValue2: Boolean;

  constructor(
    private route: ActivatedRoute,
    private exceptionService: ExceptionService,
    private serverService: ServerService,
    private toastrService: ToastrService,
    private formBuilder: FormBuilder
  ) {
    me = this;
    this.subscriptions = {};
    this.route.paramMap.subscribe((params) => {
      this.sessionId = params.get('sessionId');
    });
    this.selectFormGroup = this.formBuilder.group({
      substanceType: [],
      relatedSubstance: [{value: '', disabled: true}],
      studyType: [{value: '', disabled: false}, Validators.required],
      industryauthority__c: [{value: '', disabled: false}],
      source_name__c: [{value: '', disabled: false}],
      endpoint: [{value: '', disabled: false}, Validators.required],
      endpointUnit: [{value: '', disabled: false}, Validators.required],
      operator: ['', Validators.required],
      value1: [{value: '', disabled: false}],
      value2: [{value: '', disabled: false}],
      // additional fields
      safety_factor__c: [{value: '', disabled: false}],
      study_endpoint__c: [{value: '', disabled: false}],
      study__c: [{value: '', disabled: false}],
      species__c: [{value: '', disabled: false}],
      oral_absorption__c: [{value: '', disabled: false}],
      additional_fqpa_us_epa__c: [{value: '', disabled: false}],
      // default
      selectedDocument: [{value: '', disabled: false}],
    });

    this.industryAuthorities = [
      {name__v: '', id: 'V5Z000000007004', name: 'authority__c', label: 'Authority', picklist: 'industryauthority__c'},
      {name__v: '', id: 'V5Z000000007004', name: 'industry__c', label: 'Industry', picklist: 'industryauthority__c'}
    ];
    this.allSourceNames = [
      {name__v: '', id: 'V5Z000000007004', name: 'eufinal__c', label: 'EU-final', picklist: 'source_name__c'},
      {name__v: '', id: 'V5Z000000007004', name: 'epa__c', label: 'EPA', picklist: 'source_name__c'},
      {name__v: '', id: 'V5Z000000007004', name: 'eurms__c', label: 'EU-RMS', picklist: 'source_name__c'},
      {name__v: '', id: 'V5Z000000007004', name: 'jmpr__c', label: 'JMPR', picklist: 'source_name__c'},
      {name__v: '', id: 'V5Z000000007004', name: 'basf__c', label: 'BASF', picklist: 'source_name__c'},
      {name__v: '', id: 'V5Z000000007004', name: 'other_industry_than_basf__c', label: 'Other industry than BASF', picklist: 'source_name__c'}
    ];
    this.sourceNames = [];
    this.additionalFields = [];
    this.additionalFieldValues = new Map();
    this.filteredRelatedSubstances = [];
    this.isValue1Disabled = true;
    this.isValue2Disabled = true;
    this.operators = [];
    this.studyTypeGroup = 'study_type_reference_dose_levels__c';
    this.errorOnValue2 = false;
  }

  get relatedSubstanceControl(): AbstractControl {
    return this.selectFormGroup.get('relatedSubstance');
  }
  get endpointControl(): AbstractControl {
    return this.selectFormGroup.get('endpoint');
  }
  get value1Control(): AbstractControl {
    return this.selectFormGroup.get('value1');
  }
  get value2Control(): AbstractControl {
    return this.selectFormGroup.get('value2');
  }
  get sourceControl(): AbstractControl {
    return this.selectFormGroup.get('selectedDocument');
  }

  ngOnInit(): void {

    if (this.purpose === 'editOnExisting') {
      this.showCopyOptions = false;
      this.showEditOptions = true;
    }
    else {
      this.showCopyOptions = true;
      this.showEditOptions = false;
    }

    const filteredOperators = [
      'a1595852199787__c',
      'a1595852189563__c',
      'a1595852194618__c',
      'a1595852268945__c',
      'a1595852266201__c',
      'between__c',
      'a1595852359285__c'
    ];

    filteredOperators.forEach(operator => {
        const singleOperator = this.allOperators.filter(
          (entry) => entry.name === operator
        );
        this.operators = this.operators.concat(singleOperator);
      }
    );

    if (this.singleEndpointRecord.study_type_picklist_name__c) {
      // COPY record
      switch (this.singleEndpointRecord.operator__c) {
        case 'between__c':
          this.isValue1Disabled = false;
          this.isValue2Disabled = false;
          this.selectFormGroup.get('value1').enable();
          this.selectFormGroup.get('value2').enable();
          break;
        case '':
          this.isValue1Disabled = true;
          this.isValue2Disabled = true;
          this.selectFormGroup.get('value1').disable();
          this.selectFormGroup.get('value2').disable();
          this.value1Control.setValue(null);
          this.value2Control.setValue(null);
          this.singleEndpointRecord.value_1__c = null;
          this.singleEndpointRecord.value_2__c = null;
          break;
        default:
          this.isValue1Disabled = false;
          this.isValue2Disabled = true;
          this.selectFormGroup.get('value1').enable();
          this.selectFormGroup.get('value2').disable();
          this.value2Control.setValue(null);
          this.singleEndpointRecord.value_2__c = null;
          break;
      }

      if (this.singleEndpointRecord.source_name__c === 'epa__c') {
        // show all fields
        const preAllAdditionalFields = this.allAdditionalFields.filter(
          (entry) =>
            entry.group === this.singleEndpointRecord.study_type_picklist_name__c
        );
        this.additionalFields = preAllAdditionalFields.sort((a, b) => a.sortNumber - b.sortNumber);
      }
      else {
        const preAllAdditionalFields = this.allAdditionalFields.filter(
          (entry) =>
            entry.group === this.singleEndpointRecord.study_type_picklist_name__c
        );

        // remove fields mark with 'yes__c' in Initially Hidden field
        const preAdditionalFields = preAllAdditionalFields.filter(obj =>
          obj.hidden[0] !== 'yes__c'
        );
        this.additionalFields = preAdditionalFields.sort((a, b) => a.sortNumber - b.sortNumber);
      }

      // parent (or none) cannot have Related Substance
      if (this.singleEndpointRecord.substance_type__c === 'parent__c' || this.singleEndpointRecord.substance_type__c === '') {
        this.selectFormGroup.get('relatedSubstance').disable();
      } else {
        // filter Related Substances by the selected Substance Type
        this.filteredRelatedSubstances = this.relatedSubstances.filter(
          (f) => f.substance_type__c[0] === this.singleEndpointRecord.substance_type__c
        );
        this.selectFormGroup.get('relatedSubstance').enable();
      }
      this.filterSourceNames(this.singleEndpointRecord.industryauthority__c);
      this.setFormFields();
    }
    else {
      // NEW record
      this.selectFormGroup.get('substanceType').setValue('parent__c');

      const preAllAdditionalFields = this.allAdditionalFields.filter(
        (entry) =>
          entry.group === this.studyTypeGroup
      );

      // remove fields mark with 'yes__c' in Initially Hidden field
      const preAdditionalFields = preAllAdditionalFields.filter(obj =>
        obj.hidden[0] !== 'yes__c'
      );
      this.additionalFields = preAdditionalFields.sort((a, b) => a.sortNumber - b.sortNumber);
    }


    this.sourceControl.setValue(this.singleEndpointRecord.document_unbound__c);
    this.studyTypes = this.allStudyTypes;

    this.endpoints = this.allEndpoints;
    this.endpointUnits = this.allEndpointUnits;
  }

  getSelectedOptionPicklist(event: any): string {
    return event.target.options[event.target.options.selectedIndex].title;
  }

  onSelectSubstanceType(event: any): any {
    // update object
    this.singleEndpointRecord.substance_type__c = event.target.value;

    // clear Related Substance value because the group of Related Substances has changed
    this.singleEndpointRecord.related_substance__c = '';
    // also clear Related Substance form field
    this.relatedSubstanceControl.setValue('');

    // parent (or none) cannot have Related Substance
    if (event.target.value === 'parent__c' || event.target.value === '') {
      this.selectFormGroup.get('relatedSubstance').disable();
    } else {
      // filter Related Substances by the selected Substance Type
      this.filteredRelatedSubstances = this.relatedSubstances.filter(
        (f) => f.substance_type__c[0] === event.target.value
      );
      this.selectFormGroup.get('relatedSubstance').enable();
    }
  }

  onSelectStudyType(event: any): any {
    this.singleEndpointRecord.study_type__c = event.target.value;
    if (event.target.value !== '') {
      this.singleEndpointRecord.study_type_picklist_name__c = this.getSelectedOptionPicklist(
        event
      );
    }
    else {
      this.singleEndpointRecord.study_type_picklist_name__c = '';
    }
  }

  onIndustryAuthority(event: any): any {
    if (event.target.value === '') {
      this.sourceNames = [];
      this.singleEndpointRecord.source_name__c = '';
      this.selectFormGroup.get('source_name__c').setValue('');

      this.singleEndpointRecord.additional_fqpa_us_epa__c = '';
      this.selectFormGroup.get('additional_fqpa_us_epa__c').setValue('');

      // hide Additional FQPA (US EPA)
      const preAllAdditionalFields = this.allAdditionalFields.filter(
        (entry) =>
          entry.group === this.studyTypeGroup
      );
      // remove fields mark with 'yes__c' in Initially Hidden field
      const preAdditionalFields = preAllAdditionalFields.filter(obj =>
        obj.hidden[0] !== 'yes__c'
      );
      this.additionalFields = preAdditionalFields.sort((a, b) => a.sortNumber - b.sortNumber);
    }
    else {
      // Industry Authority selected
      this.sourceNames = [];
      this.singleEndpointRecord.source_name__c = '';
      this.selectFormGroup.get('source_name__c').setValue('');
      this.filterSourceNames(event.target.value);

      if (event.target.value === 'industry__c') {
        // in "industry" group, field Additional FQPA (US EPA) should not be available
        this.singleEndpointRecord.additional_fqpa_us_epa__c = '';
        this.selectFormGroup.get('additional_fqpa_us_epa__c').setValue('');

        // hide Additional FQPA (US EPA)
        const preAllAdditionalFields = this.allAdditionalFields.filter(
          (entry) =>
            entry.group === this.studyTypeGroup
        );
        // remove fields mark with 'yes__c' in Initially Hidden field
        const preAdditionalFields = preAllAdditionalFields.filter(obj =>
          obj.hidden[0] !== 'yes__c'
        );
        this.additionalFields = preAdditionalFields.sort((a, b) => a.sortNumber - b.sortNumber);
      }
    }
    this.singleEndpointRecord.industryauthority__c = event.target.value;
  }

  filterSourceNames(IndustryAuthorityValue): void {
    if (IndustryAuthorityValue === 'authority__c') {
      const dependedSourceNames = ['eufinal__c', 'epa__c', 'eurms__c', 'jmpr__c'];
      dependedSourceNames.forEach(record => {
          const singleRecord = this.allSourceNames.filter(
            (entry) => entry.name === record
          );
          this.sourceNames = this.sourceNames.concat(singleRecord);
        }
      );
    }
    else if (IndustryAuthorityValue === 'industry__c') {
      const dependedSourceNames = ['basf__c', 'other_industry_than_basf__c'];
      dependedSourceNames.forEach(record => {
          const singleRecord = this.allSourceNames.filter(
            (entry) => entry.name === record
          );
          this.sourceNames = this.sourceNames.concat(singleRecord);
        }
      );
    }
  }

  onSourceName(event: any): any {
    this.singleEndpointRecord.source_name__c = event.target.value;

    if (event.target.value === 'epa__c') {
      // show all fields
      const preAllAdditionalFields = this.allAdditionalFields.filter(
        (entry) =>
          entry.group === this.studyTypeGroup
      );
      this.additionalFields = preAllAdditionalFields.sort((a, b) => a.sortNumber - b.sortNumber);
    }
    else {
      this.singleEndpointRecord.additional_fqpa_us_epa__c = '';
      this.selectFormGroup.get('additional_fqpa_us_epa__c').setValue('');

      // hide Additional FQPA (US EPA)
      const preAllAdditionalFields = this.allAdditionalFields.filter(
        (entry) =>
          entry.group === this.studyTypeGroup
      );

      // remove fields mark with 'yes__c' in Initially Hidden field
      const preAdditionalFields = preAllAdditionalFields.filter(obj =>
        obj.hidden[0] !== 'yes__c'
      );
      this.additionalFields = preAdditionalFields.sort((a, b) => a.sortNumber - b.sortNumber);
    }
  }

  updateFieldValue(newValue): any {
    const fieldName = newValue.title;
    this.singleEndpointRecord[fieldName] = newValue.value;
  }

  filterAdditionalFieldValues(allAdditionalFieldValues: Array<PicklistValue>, filterWord: string): Array<PicklistValue> {
    return allAdditionalFieldValues.filter(
      (item) => item.picklist === filterWord
    );
  }

  removeEndpoint(singleEndpointRecord): any {
    this.removeInput.emit(singleEndpointRecord);
  }

   updateValue1(newValue): any {
    if (newValue.value < 0) {
      this.selectFormGroup.get('value1').setValue('');
    }
    else {
      this.singleEndpointRecord.value_1__c = newValue.value;
    }

    // checking if value 2 is bigger than value 1
    const value1 = parseInt(String(newValue.value));
    const value2 = parseInt(String(this.singleEndpointRecord.value_2__c));

    if (value2 <= value1 || isNaN(value1)) {
      this.errorOnValue2 = true;
      console.log('error: ' + value1 + ', ' + value2);
    }
    else {
      this.errorOnValue2 = false;
      console.log('clear ' + value1 + ', ' + value2);
    }
  }

  updateValue2(newValue): any {
    if (newValue.value < 0) {
      this.selectFormGroup.get('value2').setValue('');
    } else {
      this.singleEndpointRecord.value_2__c = newValue.value;
    }

     // checking if value 2 is bigger than value 1
    const value1 = parseInt(String(this.singleEndpointRecord.value_1__c));
    const value2 = parseInt(String(newValue.value));

    if (value2 <= value1 || isNaN(value2)) {
      this.errorOnValue2 = true;
      console.log('error: ' + value1 + ', ' + value2);
    }
    else {
      this.errorOnValue2 = false;
      console.log('clear ' + value1 + ', ' + value2);
    }
  }

  updateValue3(newValue): any {
    this.singleEndpointRecord.value_3__c = newValue.value;
  }



  updateNumberFieldValue(newValue): any {
    const fieldName = newValue.title;

    if (fieldName === 'temp_c__c') {
      this.singleEndpointRecord[fieldName] = newValue.value;
    } else {
      if (newValue.value < 0) {
        this.singleEndpointRecord[fieldName] = '';
        this.selectFormGroup.get(fieldName).setValue('');
      } else {
        this.singleEndpointRecord[fieldName] = newValue.value;
      }
    }
  }

  copyEndpoint(singleEndpointRecord: ToxEndpointTableItem): void {
    this.copyInput.emit(singleEndpointRecord);
  }

  updateMultiPicklistFieldValue(newValue, fieldName): void {
    this.singleEndpointRecord[fieldName] = newValue.value.toString();
  }

  setFormFields(): void {
    this.selectFormGroup.get('substanceType').setValue(this.singleEndpointRecord.substance_type__c);
    this.selectFormGroup.get('relatedSubstance').setValue(this.singleEndpointRecord.related_substance__c);
    this.selectFormGroup.get('studyType').setValue(this.singleEndpointRecord.study_type__c);
    this.selectFormGroup.get('industryauthority__c').setValue(this.singleEndpointRecord.industryauthority__c);
    this.selectFormGroup.get('source_name__c').setValue(this.singleEndpointRecord.source_name__c);
    this.selectFormGroup.get('endpoint').setValue(this.singleEndpointRecord.endpoint__c);
    this.selectFormGroup.get('endpointUnit').setValue(this.singleEndpointRecord.endpoint_unit__c);
    this.selectFormGroup.get('operator').setValue(this.singleEndpointRecord.operator__c);
    this.selectFormGroup.get('value1').setValue(this.singleEndpointRecord.value_1__c);
    this.selectFormGroup.get('value2').setValue(this.singleEndpointRecord.value_2__c);
    // additional fields
    this.selectFormGroup.get('safety_factor__c').setValue(this.singleEndpointRecord.safety_factor__c);
    this.selectFormGroup.get('study_endpoint__c').setValue(this.singleEndpointRecord.study_endpoint__c);
    if (this.singleEndpointRecord.study__c) {
      this.selectFormGroup.get('study__c').setValue(this.singleEndpointRecord.study__c.split(','));
    }
    this.selectFormGroup.get('species__c').setValue(this.singleEndpointRecord.species__c);
    this.selectFormGroup.get('oral_absorption__c').setValue(this.singleEndpointRecord.oral_absorption__c);
    this.selectFormGroup.get('additional_fqpa_us_epa__c').setValue(this.singleEndpointRecord.additional_fqpa_us_epa__c);
    // default
    this.selectFormGroup.get('selectedDocument').setValue(this.singleEndpointRecord.document_unbound__c);
  }

  onSelectOperator(event: any): any {
    if (event.target !== undefined) {
      this.singleEndpointRecord.operator__c = event.target.value;
    }

    switch (this.singleEndpointRecord.operator__c) {
      case 'between__c':
        this.isValue1Disabled = false;
        this.isValue2Disabled = false;
        this.selectFormGroup.get('value1').enable();
        this.selectFormGroup.get('value2').enable();
        break;
      case '':
        this.isValue1Disabled = true;
        this.isValue2Disabled = true;
        this.selectFormGroup.get('value1').disable();
        this.selectFormGroup.get('value2').disable();
        this.value1Control.setValue(null);
        this.value2Control.setValue(null);
        this.singleEndpointRecord.value_1__c = null;
        this.singleEndpointRecord.value_2__c = null;
        break;
      default:
        this.isValue1Disabled = false;
        this.isValue2Disabled = true;
        this.selectFormGroup.get('value1').enable();
        this.selectFormGroup.get('value2').disable();
        this.value2Control.setValue(null);
        this.singleEndpointRecord.value_2__c = null;
        break;
    }
  }

  cancelEditing(endpointRecord): void {
    this.cancelEdit.emit(endpointRecord);
  }

  saveEndpointChanges(endpointRecord): void {
    this.saveChanges.emit(endpointRecord);
  }

}




