import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {PicklistValue} from '../../../models/picklist-value';
import {ConsumerSafetyEndpointTableItem} from '../../models/consumer-safety-endpoint-table-item';
import {DocumentMenuItem} from '../../../models/document-menu-item';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute} from '@angular/router';
import {ExceptionService} from '../../../../core/exception.service';
import {ServerService} from '../../../../core/server.service';
import {ToastrService} from 'ngx-toastr';
import {ConsumerSafetyExisting} from '../../models/consumer-safety-existing';
import {convertArrayToString} from '../../../../shared/convert-array-to-string';
import {CreateObjectResponse, CreateObjectsResponse} from '../../../models/create-objects-response';
import {VaultError} from '../../../../core/models/vault-error';
import {HttpErrorResponse} from '@angular/common/http';
import {ConsumerSafetyAdditionalFields} from "../../models/consumer-safety-additional-fields";
import {ConsumerSafetyCategoryPicklists} from "../../models/consumer-safety-category-picklists";
import {SingleRecordDependencies} from "../../models/single-record-dependencies";
import {convertSpecialFieldsToArray, convertSpecialFieldsToString} from "../../../../shared/convert-special-fields";

let me: DietaryRiskAssessmentExistingComponent;

@Component({
  selector: 'app-dietary-risk-assessment-existing',
  templateUrl: './dietary-risk-assessment-existing.component.html',
  styleUrls: ['../../../existing_record.css']
})
export class DietaryRiskAssessmentExistingComponent implements OnInit {
  @Input() arrayedEndpointRecord: ConsumerSafetyExisting;
  @Input() category: any;
  @Input() categoryPicklists: Array<ConsumerSafetyCategoryPicklists>;
  @Input() allStudyTypes: Array<PicklistValue>;
  @Input() allTestSystemTypes: Array<PicklistValue>;
  @Input() allTestSystemNames: Array<PicklistValue>;
  @Input() allAdditionalFields: Array<ConsumerSafetyAdditionalFields>;
  @Input() allAdditionalFieldValues: Array<ConsumerSafetyAdditionalFields>;
  @Input() singleRecordDependenciesModelAdditionalFieldValues: Array<ConsumerSafetyAdditionalFields>;
  @Input() singleRecordDependencies: Array<SingleRecordDependencies>;
  @Input() substanceTypes: Array<PicklistValue>;
  @Input() relatedSubstances: any;
  @Input() documents: Array<DocumentMenuItem>;
  @Input() allUsers: any;

  @Output() removeInput: EventEmitter<ConsumerSafetyEndpointTableItem> = new EventEmitter();
  @Output() copyExisting: EventEmitter<any> = new EventEmitter();

  subscriptions: any;
  selectFormGroup: FormGroup;
  configureFormGroup: FormGroup;
  public showTestSystemName: boolean;
  public existingEndpointRecord: any;
  public arrayToSave: any;
  public additionalFields: Array<ConsumerSafetyAdditionalFields>;
  public additionalFieldValues: Map<string, any>;
  public testSystemTypes: Array<PicklistValue>;
  public testSystemNames: Array<PicklistValue>;
  public userName: any;
  public createdBy: any;
  public modifiedBy: any;
  public formatCreatedDate: string;
  public formatModifiedDate: string;

  showExistingRecord = true;
  showEditRecord = false;
  public purposeMessage: string;
  private sessionId: string;

  constructor(
    private route: ActivatedRoute,
    private exceptionService: ExceptionService,
    private serverService: ServerService,
    private toastrService: ToastrService,
    private formBuilder: FormBuilder
  ) {
    me = this;
    this.subscriptions = {};
    this.route.paramMap.subscribe((params) => {
      this.sessionId = params.get('sessionId');
    });
    this.selectFormGroup = this.formBuilder.group({
      category__c: [{value: 'Acute Toxicity', disabled: true}],
      substanceType: [],
      relatedSubstance: [{value: '', disabled: true}],
      studyType: [{value: '', disabled: false}, Validators.required],
      testSystemTypeMultiSelect: [{value: [], disabled: true}],
      testSystemNameMultiSelect: [{value: [], disabled: true}],
      // additional (depended) fields
      comment__c: [{value: '', disabled: false}],
      driver_crop__c: [{value: '', disabled: false}],
      driver_population__c: [{value: '', disabled: false}],
      factors_included_in_the_calculations__c: [{value: '', disabled: false}],
      included_uses__c: [{value: '', disabled: false}],
      ra_relevant__c: [{value: '', disabled: false}],
      refinement_level__c: [{value: '', disabled: false}],
      risk_assessment_status__c: [{value: '', disabled: false}],
      toxicological_endpoint_utilization__c: [{value: '', disabled: false}],
      unittoxicological_endpoint_utilization__c: [{value: '', disabled: false}],
      // default
      source: [{value: '', disabled: false}],
    });
    this.showTestSystemName = false;
    this.additionalFields = [];
    this.additionalFieldValues = new Map();
    this.testSystemTypes = [];
    this.testSystemNames = [];

    this.purposeMessage = 'editOnExisting';
  }

  ngOnInit(): void {
    this.selectFormGroup.disable();

    // convert record values to string values
    this.existingEndpointRecord = JSON.parse(
      JSON.stringify(this.arrayedEndpointRecord)
    );

    convertArrayToString(
      this.arrayedEndpointRecord,
      this.existingEndpointRecord,
      []
    );

    convertSpecialFieldsToArray(this.existingEndpointRecord, ['test_system_type__c', 'test_system_name__c']);

    if (this.existingEndpointRecord.created_by__v === 1) {
      this.createdBy = [{name: 'System'}];
    } else {
      this.createdBy = this.allUsers.filter(
        (c) => c.id === this.existingEndpointRecord.created_by__v
      );
    }

    if (this.existingEndpointRecord.modified_by__v === 1) {
      // eslint-disable-next-line quote-props
      this.modifiedBy = [{name: 'System'}];
    } else {
      this.modifiedBy = this.allUsers.filter(
        (m) => m.id === this.existingEndpointRecord.modified_by__v
      );
    }

    this.formatCreatedDate =
      this.existingEndpointRecord.created_date__v.substring(0, 10) +
      ', ' +
      this.existingEndpointRecord.created_date__v.substring(11, 16) +
      'h Z';
    this.formatModifiedDate =
      this.existingEndpointRecord.modified_date__v.substring(0, 10) +
      ', ' +
      this.existingEndpointRecord.modified_date__v.substring(11, 16) +
      'h Z';

    this.selectFormGroup.get('source').setValue(this.existingEndpointRecord.document_unbound__c);
    this.showAdditionalFields();
    this.setFormFields();
  }

  showAdditionalFields(): void {
    const preAdditionalFields = this.allAdditionalFields.filter(
      (entry) =>
        entry.group === this.existingEndpointRecord.study_type_picklist_name__c
    );

    this.additionalFields = preAdditionalFields.sort((a, b) => a.sortNumber - b.sortNumber);
    this.showTestSystemName = this.existingEndpointRecord.study_type_picklist_name__c === 'study_type_dietary_risk_assessment_2__c';
  }

  setFormFields(): void {
    this.selectFormGroup.get('category__c').setValue(this.existingEndpointRecord.category__c);
    this.selectFormGroup.get('substanceType').setValue(this.existingEndpointRecord.substance_type__c);
    this.selectFormGroup.get('relatedSubstance').setValue(this.existingEndpointRecord.related_substance__c);
    this.selectFormGroup.get('studyType').setValue(this.existingEndpointRecord.study_type__c);
    if (this.existingEndpointRecord.test_system_type__c) {
      this.selectFormGroup.get('testSystemTypeMultiSelect').setValue(this.existingEndpointRecord.test_system_type__c);
    }
    if (this.existingEndpointRecord.test_system_name__c) {
      this.selectFormGroup.get('testSystemNameMultiSelect').setValue(this.existingEndpointRecord.test_system_name__c);
    }
    // additional fields
    this.selectFormGroup.get('comment__c').setValue(this.existingEndpointRecord.comment__c);
    this.selectFormGroup.get('driver_crop__c').setValue(this.existingEndpointRecord.driver_crop__c);
    this.selectFormGroup.get('driver_population__c').setValue(this.existingEndpointRecord.driver_population__c);
    this.selectFormGroup.get('factors_included_in_the_calculations__c').setValue(this.existingEndpointRecord.factors_included_in_the_calculations__c);
    this.selectFormGroup.get('included_uses__c').setValue(this.existingEndpointRecord.included_uses__c);
    this.selectFormGroup.get('ra_relevant__c').setValue(this.existingEndpointRecord.ra_relevant__c);
    this.selectFormGroup.get('refinement_level__c').setValue(this.existingEndpointRecord.refinement_level__c);
    this.selectFormGroup.get('risk_assessment_status__c').setValue(this.existingEndpointRecord.risk_assessment_status__c);
    this.selectFormGroup.get('toxicological_endpoint_utilization__c').setValue(this.existingEndpointRecord.toxicological_endpoint_utilization__c);
    this.selectFormGroup.get('unittoxicological_endpoint_utilization__c').setValue(this.existingEndpointRecord.unittoxicological_endpoint_utilization__c);
    // default
    this.selectFormGroup.get('source').setValue(this.existingEndpointRecord.document_unbound__c);
  }

  enableEditing(): any {
    this.showExistingRecord = false;
    this.showEditRecord = true;
  }

  cancelEditing(): void {
    this.showExistingRecord = true;
    this.showEditRecord = false;
  }

  saveChanges(endpointRecord): void {
    this.serverService.isLoading();

    delete endpointRecord.created_by__v;
    delete endpointRecord.modified_by__v;
    delete endpointRecord.created_date__v;
    delete endpointRecord.modified_date__v;

    this.arrayToSave = endpointRecord;
    convertSpecialFieldsToString(this.arrayToSave, ['test_system_type__c', 'test_system_name__c']);

    const body = Array(this.arrayToSave);

    this.serverService.updateEndpoint(this.sessionId, body).subscribe(
      (success: CreateObjectsResponse) => {
        let updated = 0;
        success.data?.forEach((record: CreateObjectResponse) => {
          if (record?.responseStatus === 'SUCCESS') {
            updated++;
          }
          if (record?.responseStatus === 'FAILURE') {
            record?.errors?.forEach((error: VaultError) => {
              this.toastrService.error(error.message, error.type);
            });
          }
        });

        this.toastrService.info(
          ` Endpoint updated successfully.`,
          'Info'
        );
        this.serverService.isNotLoading();
      },
      (error: HttpErrorResponse) => {
        this.exceptionService.handleError(error);
        this.toastrService.error(
          `An error occurred while updating endpoint.`,
          'Error'
        );
        this.serverService.isNotLoading();
      }
    );

    this.showExistingRecord = true;
    this.showEditRecord = false;
    convertSpecialFieldsToArray(endpointRecord, ['test_system_type__c', 'test_system_name__c']);
    this.existingEndpointRecord = endpointRecord;
    console.log('updated record: ' + JSON.stringify(this.existingEndpointRecord));
    this.showAdditionalFields();
    this.setFormFields();
  }

  copyExistingEndpoint(endpoint: ConsumerSafetyExisting): any {
    this.copyExisting.emit(endpoint);
  }

  filterAdditionalFieldValues(
    allAdditionalFieldValues: Array<ConsumerSafetyAdditionalFields>,
    filterWord: string,
  ): Array<ConsumerSafetyAdditionalFields> {

    if (filterWord === 'ghost__c') {
      return this.singleRecordDependenciesModelAdditionalFieldValues;
    }
    else {
      return allAdditionalFieldValues.filter(
        (item) => item.picklist === filterWord
      );
    }
  }

}
