<div *ngIf="isLoading" class="ball-loader">
  <div class="ball-loader-ball ball1"></div>
  <div class="ball-loader-ball ball2"></div>
  <div class="ball-loader-ball ball3"></div>
</div>

<div *ngIf="!isLoading" class="container">
    <button mat-button
            color="blue"
            class="load-button"
            (click)="loadSet()">
      <mat-icon>file_upload</mat-icon>
      Load Set
    </button>

  <mat-divider class="horizontal-divider"></mat-divider>

<div class="multi-select-container">

  <div class="ai-evaluation-dropdown-wrapper">
    <div class="expansion-panel">
      <mat-expansion-panel (opened)="panelOpenState = true"
                           (closed)="panelOpenState = false"
      >
        <mat-expansion-panel-header>
          <mat-panel-title *ngIf="!panelOpenState">
            Paste AI Names
          </mat-panel-title>
          <mat-panel-description *ngIf="!panelOpenState">
            Click here to paste Active Ingredient Names
          </mat-panel-description>
        </mat-expansion-panel-header>
        <div class="paste-wrapper">
          <div class="paste">
            <h4>Paste AI names here separated by <strong>Enter Key</strong></h4>
            <div style="font-size: 14px">
              <mat-form-field class="paste-box" appearance="fill" [(ngModel)]="pastedText" ngDefaultControl>
  <textarea matInput
            cdkAutosizeMinRows="10"
            cdkTextareaAutosize >
  </textarea>
              </mat-form-field>
            </div>
          </div>
<!--          <mat-action-row>-->
            <div>
              <button *ngIf="pastedText" mat-button color="blue"
                      class="validate-button"
                      (click)="getValidatedSubstances()">
                <mat-icon>done</mat-icon>
                Validate
              </button>
            </div>
<!--          </mat-action-row>-->
        </div>
      </mat-expansion-panel>
    </div>

    <div class="active-ingredient-selector">
<h4>Select Active Ingredients</h4>
<ng-select class="substance-search"
           appearance="fill"
           [items]="substances"
           bindLabel="name__v"
           appendTo="body"
           [multiple]="true"
           [closeOnSelect]="false"
           [(ngModel)]="selectedSet.substances"
           (clear)="clearSubstances()"
           (remove)="onRemove(); getAllEvaluations();"
           (ngModelChange)="getAllEvaluations(); updateSelected(); saveSelected()">
</ng-select>
</div>
  <div class="evaluation-select-box">
<h4>Select Evaluations</h4>
<ng-select class="evaluation-search"
           [items]="allEvaluations"
           appearance="fill"
           bindLabel="name__v"
           groupBy="poc_active_ingredient__cr.name__v"
           [(ngModel)]="selectedEvaluations"
           (ngModelChange)="saveSelected();"
           (clear)="clearEvaluations()"
           [multiple]="true"
           (remove)="saveSelected()"
           [closeOnSelect]="false">
</ng-select>
  </div>
  <div class="save-update-share-buttons">
    <button mat-button
            color="blue"
            class="save-button"
            *ngIf="selectedEvaluations.length > 0"
            (click)="populateSetToSave(false); saveSet()">
      <mat-icon>save</mat-icon>
      Save Set
    </button>
    <button mat-button
            color="blue"
            class="update-button"
            *ngIf="isLoaded && selectedEvaluations.length > 0"
            (click)="populateSetToSave(true); updateSet()">
      <mat-icon>update</mat-icon>
      Update Set
    </button>

    <button mat-button
            color="blue"
            class="share-button"
            *ngIf="selectedEvaluations.length > 0"
            (click)="populateSetToSave(true); share()">
      <mat-icon>share</mat-icon>
      Share Set
    </button>
  </div>
  </div>
  <mat-divider [vertical]="true" class="vertical-divider"></mat-divider>
  <div class="drag-and-drop-wrapper">
    <!--   <div class="select-evaluations-heading">-->
    <h4 *ngIf="selectedEvaluations.length > 0">Selected Evaluations (drag and drop to rearrange):</h4>
    <!--   </div>-->
    <!--  <div class="evaluation-list-container" *ngIf="selectedEvaluations.length > 0">-->
    <div cdkDropList class="evaluation-list" (cdkDropListDropped)="drop($event)" *ngIf="selectedEvaluations.length > 0">
      <div class="evaluation-box" *ngFor="let evaluation of selectedEvaluations"
           cdkDrag>{{evaluation.name__v}}</div>
    </div>
    <!--  </div>-->
  </div>
</div>
</div>
