<div #chartParent class="page-container spider-page-container">
  <div *ngIf="isLoading" class="ball-loader">
    <div class="ball-loader-ball ball1"></div>
    <div class="ball-loader-ball ball2"></div>
    <div class="ball-loader-ball ball3"></div>
  </div>

  <div *ngIf="!isLoading" class="content">

    <div class="chart">
      <div class="canvas">
        <canvas [datasets]="data" [labels]="labels" [options]="options" [plugins]="plugins"
                [type]="chartType"
                id="radar-chart"
                baseChart>
        </canvas>
      </div>
    </div>

  </div>

  <aside *ngIf="!isLoading" class="sidebar">
    <div [ngClass]="{'buttons-container': true, 'hidden': isHidden}">
      <button (click)="uploadDiagram()" id="save-attach">Save to Vault</button>
      <button (click)="saveDiagram()" id="save-locally">Save to computer</button>
      <button (click)="export()">Export to Excel</button>
    </div>
    <div class="legend-controls">
      <div class="legend">
        <div class="legend-row">
          <div class="cube grey"></div>
          <span>No data</span>
        </div>
        <div class="legend-row">
          <div class="cube green"></div>
          <span>No risk: High likelihood to maintain or expand registrations.</span>
        </div>
        <div class="legend-row">
          <div class="cube yellow"></div>
          <span>Minor risk: High likelihood to maintain or expand registrations. Additional studies may be required, positive outcome expected.</span>
        </div>
        <div class="legend-row">
          <div class="cube orange"></div>
          <span>Moderate risk: Registration possible with additional effort and/or with restrictions.</span>
        </div>
        <div class="legend-row">
          <div class="cube red"></div>
          <span>High risk: Loss of key uses and/or registrations likely. Data generation unlikely to improve outcome, or is not feasible.</span>
        </div>
      </div>

    </div>
  </aside>

</div>
