import {AfterContentInit, Component, Input, OnInit} from '@angular/core';
import {ServerService} from 'src/app/core/server.service';
import {ToxCategoryPicklists} from '../../models/tox-category-picklist';
import {ActivatedRoute} from '@angular/router';
import {PicklistValue} from '../../models/picklist-value';
import {DocumentMenuItem} from '../../models/document-menu-item';
import {SubstanceItem} from '../../models/substance-item';
import {EndpointServicesService} from '../../endpoint-services.service';
import {RelatedSubstances} from '../../models/related-substances';
import {ToxAdditionalField} from '../../models/tox-additional-field';
import {ToxEndpointTableItem} from '../../models/tox-endpoint-table-item';

let me: ToxCategoryComponent;

@Component({
  selector: 'app-tox-category',
  templateUrl: './tox-category.component.html',
  styleUrls: ['./tox-category.component.css'],
})
export class ToxCategoryComponent implements OnInit, AfterContentInit {
  @Input() workAreaId: string;
  @Input() workAreaLabel: string;
  @Input() category: any;
  @Input() dataPicklists: Array<ToxCategoryPicklists>;
  @Input() endpointsArrayList: Array<ToxEndpointTableItem>;
  @Input() substance: SubstanceItem;
  @Input() relatedSubstances: Array<RelatedSubstances>;
  @Input() substanceTypes: Array<PicklistValue>;
  @Input() receivedAllTestSystemTypes: Array<PicklistValue>;
  @Input() receivedAllTestSystemNames: Array<PicklistValue>;
  @Input() receivedAllRouteOfAdministration: Array<PicklistValue>;
  @Input() receivedAllEndpoints: Array<PicklistValue>;
  @Input() receivedAllStudyTypes: Array<PicklistValue>;
  @Input() receivedAllEndpointUnits: Array<PicklistValue>;
  @Input() operators: Array<PicklistValue>;
  @Input() documents: Array<DocumentMenuItem>;
  @Input() receivedAllAdditionalFields: Array<ToxAdditionalField>;
  @Input() receivedAdditionalFieldValues: Array<PicklistValue>;
  // @ViewChild(ToxRecordComponent) eRecord: ToxRecordComponent;

  subscriptions: any;
  public selectedDocument: DocumentMenuItem;
  public filteredPicklists: Array<ToxCategoryPicklists>;
  public categoryEndpointsArrayList: Array<ToxEndpointTableItem>;
  public groupPicklists: Array<ToxCategoryPicklists>;
  public defaultAllStudyTypes: Array<PicklistValue>; // reference list
  public defaultAllEndpoints: Array<PicklistValue>; // reference list
  public defaultAllEndpointUnits: Array<PicklistValue>; // reference list
  public defaultAllTestSystemTypes: Array<PicklistValue>;
  public defaultAllRouteOfAdministration: Array<PicklistValue>;
  public defaultAllTestSystemNames: Array<PicklistValue>;
  public allTestSystemTypes: Array<PicklistValue>;
  public allTestSystemNames: Array<PicklistValue>;
  public startingId: number;
  public maxCategoryId: number;
  private sessionId: string;

  constructor(
    private route: ActivatedRoute,
    private serverService: ServerService,
    private endpointService: EndpointServicesService
  ) {
    me = this;
    this.subscriptions = {};
    this.route.paramMap.subscribe((params) => {
      this.sessionId = params.get('sessionId');
    });
    this.categoryEndpointsArrayList = [];
    this.filteredPicklists = [];
    this.groupPicklists = [];
    this.defaultAllStudyTypes = [];
    this.defaultAllEndpoints = [];
    this.defaultAllEndpointUnits = [];
    this.defaultAllTestSystemTypes = [];
    this.defaultAllRouteOfAdministration = [];
    this.defaultAllTestSystemNames = [];
    this.allTestSystemTypes = [];
    this.allTestSystemNames = [];
    this.startingId = 0;
    this.maxCategoryId = 0;
  }

  ngOnInit(): void {
    this.endpointService.currentDocument.subscribe(
      (doc) => (this.selectedDocument = doc)
    );

    this.maxCategoryId = (11 - this.category.order_by__c) * 1000000;
  }

  ngAfterContentInit(): void {
    this.filteredPicklists = this.dataPicklists.filter(
      (a) => a.categoryId === this.category.id
    );
    this.defaultAllStudyTypes = this.receivedAllStudyTypes.filter(
      (b) => b.id === this.category.id
    );
    this.defaultAllTestSystemTypes = this.receivedAllTestSystemTypes.filter(
      (e) => e.id === this.category.id
    );
    this.defaultAllRouteOfAdministration = this.receivedAllRouteOfAdministration.filter(
      (f) => f.id === this.category.id
    );
    this.defaultAllTestSystemNames = this.receivedAllTestSystemNames.filter(
      (f) => f.id === this.category.id
    );
    this.defaultAllEndpoints = this.receivedAllEndpoints.filter(
      (c) => c.id === this.category.id
    );
    this.defaultAllEndpointUnits = this.receivedAllEndpointUnits.filter(
      (d) => d.id === this.category.id
    );

    this.unifyTestSystemTypes();
    this.unifyTestSystemNames();
  }

  unifyTestSystemTypes(): void {
    // remove duplicates
    const array = this.defaultAllTestSystemTypes;
    const map = new Map();
    for (const item of array) {
      if (!map.has(item.name)) {
        map.set(item.name, true); // set any value to Map
        this.allTestSystemTypes.push({
          name__v: item.name__v,
          id: item.id,
          name: item.name,
          label: item.label,
          picklist: item.picklist,
        });
      }
    }
  }

  unifyTestSystemNames(): void {
    // remove duplicates
    const array = this.defaultAllTestSystemNames;
    const map = new Map();
    for (const item of array) {
      if (!map.has(item.name)) {
        map.set(item.name, true); // set any value to Map
        this.allTestSystemNames.push({
          name__v: item.name__v,
          id: item.id,
          name: item.name,
          label: item.label,
          picklist: item.picklist,
        });
      }
    }
  }

  addNewEndpoint(endpoint: any): void {

    // make object
    const newEndpointTemplate: ToxEndpointTableItem = {
      id__c: this.generateId(),
      work_area__c: this.workAreaId,
      category__c: this.category.id,
      substance_name__c: this.substance.id,
      substance_type__c: endpoint.substance_type__c ?? 'parent__c',
      related_substance__c: endpoint.related_substance__c ?? '',
      study_type__c: endpoint.study_type__c ?? '',
      industryauthority__c: endpoint.industryauthority__c ?? '',
      source_name__c: endpoint.source_name__c ?? '',
      test_system_type__c: endpoint.test_system_type__c ?? '',
      test_system_name__c: endpoint.test_system_name__c ?? '',
      endpoint__c: endpoint.endpoint__c ?? '',
      endpoint_unit__c: endpoint.endpoint_unit__c ?? '',
      operator__c: endpoint.operator__c ?? '',
      value_1__c: endpoint.value_1__c ?? '',
      value_2__c: endpoint.value_2__c ?? '',
      value_3__c: endpoint.value_3__c ?? '',
      // additional (depended) fields
      study_endpoint__c: endpoint.study_endpoint__c ?? '',
      study__c: endpoint.study__c ?? '',
      crops__c: endpoint.crops__c ?? '',
      application_method__c: endpoint.application_method__c ?? '',
      ppe_required__c: endpoint.ppe_required__c ?? '',
      additional_fqpa_us_epa__c: endpoint.additional_fqpa_us_epa__c ?? '',
      oral_absorption__c: endpoint.oral_absorption__c ?? '',
      species__c: endpoint.species__c ?? '',
      safety_factor__c: endpoint.safety_factor__c ?? '',
      tumor_site_female__c: endpoint.tumor_site_female__c ?? '',
      tumor_site_male__c: endpoint.tumor_site_male__c ?? '',
      loael_carcinogenicity_mgkg_bwd__c: endpoint.loael_carcinogenicity_mgkg_bwd__c ?? '',
      noael_carcinogenicity_mgkg_bwd__c: endpoint.noael_carcinogenicity_mgkg_bwd__c ?? '',
      chromosome_aberration_hamster__c: endpoint.chromosome_aberration_hamster__c ?? '',
      chromosome_aberration_mouse__c: endpoint.chromosome_aberration_mouse__c ?? '',
      chromosome_aberration_rat__c: endpoint.chromosome_aberration_rat__c ?? '',
      uds_in_vivo__c: endpoint.uds_in_vivo__c ?? '',
      micronucleus_test_mouse__c: endpoint.micronucleus_test_mouse__c ?? '',
      micronucleus_test_rat__c: endpoint.micronucleus_test_rat__c ?? '',
      micronucleus_test_in_vitro__c: endpoint.micronucleus_test_in_vitro__c ?? '',
      comet_assay_in_vivo__c: endpoint.comet_assay_in_vivo__c ?? '',
      uds_in_vitro__c: endpoint.uds_in_vitro__c ?? '',
      chromosome_aberration_nonhuman_cells__c: endpoint.chromosome_aberration_nonhuman_cells__c ?? '',
      chromosome_aberration_human_lymphocytes__c: endpoint.chromosome_aberration_human_lymphocytes__c ?? '',
      mouse_lymphoma_assay__c: endpoint.mouse_lymphoma_assay__c ?? '',
      hprt_test__c: endpoint.hprt_test__c ?? '',
      ames_test__c: endpoint.ames_test__c ?? '',
      target_organ__c: endpoint.target_organ__c ?? '',
      ec15__c: endpoint.ec15__c ?? '',
      ec3__c: endpoint.ec3__c ?? '',
      test_method_skin_sensitization__c: endpoint.test_method_skin_sensitization__c ?? '',
      route_of_administration__c: endpoint.route_of_administration__c ?? '',
      transgenic_rodent_assay_in_vitro__c: endpoint.transgenic_rodent_assay_in_vitro__c ?? '',
      comet_assay_in_vitro__c: endpoint.comet_assay_in_vitro__c ?? '',
      transgenic_rodent_assay_in_vivo__c: endpoint.transgenic_rodent_assay_in_vivo__c ?? '',
      ed_mechanistic_study__c: endpoint.ed_mechanistic_study__c ?? '',
      eats_and_developmental_parameters_affect__c: endpoint.eats_and_developmental_parameters_affect__c ?? '',
      mechanistic_studies__c: endpoint.mechanistic_studies__c ?? '',
      mechanism_tmodality__c: endpoint.mechanism_tmodality__c ?? '',
      ed_modality__c: endpoint.ed_modality__c ?? '',
      loael_immunotoxicity_mgkg_bwd__c: endpoint.loael_immunotoxicity_mgkg_bwd__c ?? '',
      noael_immunotoxicity_mgkg_bwd__c: endpoint.noael_immunotoxicity_mgkg_bwd__c ?? '',
      nk_cell_activity_test__c: endpoint.nk_cell_activity_test__c ?? '',
      lymphocyte_subpopulation__c: endpoint.lymphocyte_subpopulation__c ?? '',
      srbc_assay__c: endpoint.srbc_assay__c ?? '',
      loael_neurotoxicity_mgkg_bwd__c: endpoint.loael_neurotoxicity_mgkg_bwd__c ?? '',
      noael_neurotoxicity_mgkg_bwd__c: endpoint.noael_neurotoxicity_mgkg_bwd__c ?? '',
      t_trigger__c: endpoint.t_trigger__c ?? '',
      loael_developmental_mgkg_bwd__c: endpoint.loael_developmental_mgkg_bwd__c ?? '',
      noael_developmental_mgkg_bwd__c: endpoint.noael_developmental_mgkg_bwd__c ?? '',
      loael_maternal_mgkg_bwd__c: endpoint.loael_maternal_mgkg_bwd__c ?? '',
      noael_maternal_mgkg_bwd__c: endpoint.noael_maternal_mgkg_bwd__c ?? '',
      loael_offspring_mgkg_bwd__c: endpoint.loael_offspring_mgkg_bwd__c ?? '',
      noael_offspring_mgkg_bwd__c: endpoint.noael_offspring_mgkg_bwd__c ?? '',
      loael_fertility_mgkg_bwd__c: endpoint.loael_fertility_mgkg_bwd__c ?? '',
      noael_fertility_mgkg_bwd__c: endpoint.noael_fertility_mgkg_bwd__c ?? '',
      loael_parental_mgkg_bwd__c: endpoint.loael_parental_mgkg_bwd__c ?? '',
      noael_parental_mgkg_bwd__c: endpoint.noael_parental_mgkg_bwd__c ?? '',
      qstar_tumor_site__c: endpoint.qstar_tumor_site__c ?? '',
      q_value__c: endpoint.q_value__c ?? '',
      us_cancer_ra__c: endpoint.us_cancer_ra__c ?? '',
      other__c: endpoint.other__c ?? '',
      population__c: endpoint.population__c ?? '',
      // default
      study_type_picklist_name__c: endpoint.study_type_picklist_name__c ?? '', // field used for capturing additional (dependency) fields
      document_unbound__c: endpoint.document_unbound__c ?? this.selectedDocument.id,
    };

    this.categoryEndpointsArrayList.push(newEndpointTemplate);
    this.endpointsArrayList.push(newEndpointTemplate);

  }

  removeEndpointInput(endpoint: ToxEndpointTableItem): void {
    // remove object from array
    for (let index = 0; index < this.endpointsArrayList.length; index++) {
      const element: ToxEndpointTableItem = this.endpointsArrayList[index];
      if (element.id__c === endpoint.id__c) {
        this.endpointsArrayList.splice(index, 1);
      }
    }

    for (let index = 0; index < this.categoryEndpointsArrayList.length; index++) {
      const element: ToxEndpointTableItem = this.categoryEndpointsArrayList[index];
      if (element.id__c === endpoint.id__c) {
        this.categoryEndpointsArrayList.splice(index, 1);
      }
    }
  }

  removeSavedEndpoint(endpointId): any {
    this.categoryEndpointsArrayList = this.categoryEndpointsArrayList.filter(
      obj => obj.id__c !== endpointId
    );
  }

  generateId(): number {
    const newStartingId = this.startingId + 1;
    this.updateStartingId(newStartingId);
    return this.maxCategoryId - newStartingId;
  }

  updateStartingId(newStartingId): void {
    this.startingId = newStartingId;
  }

  copyEndpointInput(endpoint: ToxEndpointTableItem): void {
    this.categoryEndpointsArrayList.filter(it => it.id__c === endpoint.id__c)
      .forEach(newEndpoint => {
        this.addNewEndpoint(newEndpoint);
      });
  }
}
