import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ConsumerSafetyEndpointTableItem} from '../../models/consumer-safety-endpoint-table-item';
import {PicklistValue} from '../../../models/picklist-value';
import {ConsumerSafetyCategoryPicklists} from '../../models/consumer-safety-category-picklists';
import {SubstanceItem} from '../../../models/substance-item';
import {RelatedSubstances} from '../../../models/related-substances';
import {DocumentMenuItem} from '../../../models/document-menu-item';
import {ConsumerSafetyAdditionalFields} from '../../models/consumer-safety-additional-fields';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {ExceptionService} from '../../../../core/exception.service';
import {ServerService} from '../../../../core/server.service';
import {SingleRecordDependencies} from "../../models/single-record-dependencies";
import {MatDialog} from '@angular/material/dialog';

@Component({
  selector: 'app-metabolism',
  templateUrl: './metabolism.component.html',
  styleUrls: ['../../../record_style.css']
})
export class MetabolismComponent implements OnInit {
  @Input() singleEndpointRecord: ConsumerSafetyEndpointTableItem;
  @Input() category: PicklistValue;
  @Input() categoryPicklists: Array<ConsumerSafetyCategoryPicklists>;
  @Input() allStudyTypes: Array<PicklistValue>;
  @Input() allTestSystemTypes: Array<PicklistValue>;
  @Input() allTestSystemNames: Array<PicklistValue>;
  @Input() substance: SubstanceItem;
  @Input() substanceTypes: Array<PicklistValue>;
  @Input() relatedSubstances: Array<RelatedSubstances>;
  @Input() documents: Array<DocumentMenuItem>;
  @Input() allAdditionalFields: Array<ConsumerSafetyAdditionalFields>;
  @Input() allAdditionalFieldValues: Array<ConsumerSafetyAdditionalFields>;
  @Input() singleRecordDependencies: Array<SingleRecordDependencies>;
  @Input() identifiedMetabolites:Array<ConsumerSafetyAdditionalFields>;
  @Input() purpose: string;

  @Output() removeInput: EventEmitter<ConsumerSafetyEndpointTableItem> = new EventEmitter();
  @Output() copyInput: EventEmitter<ConsumerSafetyEndpointTableItem> = new EventEmitter();
  @Output() cancelEdit: EventEmitter<ConsumerSafetyEndpointTableItem> = new EventEmitter();
  @Output() saveChanges: EventEmitter<ConsumerSafetyEndpointTableItem> = new EventEmitter();

  subscriptions: any;
  selectFormGroup: FormGroup;
  configureFormGroup: FormGroup;
  public studyTypes: Array<PicklistValue>;
  public categoryTestSystemTypes: Array<PicklistValue>;
  public testSystemTypes: Array<PicklistValue>;
  public testSystemNames: Array<PicklistValue>;
  public filteredRelatedSubstances: Array<RelatedSubstances>;
  public additionalFields: Array<ConsumerSafetyAdditionalFields>;
  public hiddenAdditionalFields: Array<ConsumerSafetyAdditionalFields>;
  private sessionId: string;
  public testSystemTypeSingleField: boolean;
  public testSystemTypeMultiField: boolean;
  public testSystemNameSingleField: boolean;
  public testSystemNameMultiField: boolean;
  public showCopyOptions: boolean;
  public showEditOptions: boolean;
  public dialogTitle: string;
  public dialogRow1: string;
  public dialogRow2: string;

  constructor(private route: ActivatedRoute,
              private exceptionService: ExceptionService,
              private serverService: ServerService,
              private toastrService: ToastrService,
              private formBuilder: FormBuilder,
              public dialog: MatDialog
  ) {
    this.subscriptions = {};
    this.route.paramMap.subscribe((params) => {
      this.sessionId = params.get('sessionId');
    });
    this.dialogTitle = 'Add New Test System Type';
    this.dialogRow1 = "Test template for adding new data entries to Endpoints.";
    this.dialogRow2 = "";


    this.selectFormGroup = this.formBuilder.group({
      substanceType: [],
      relatedSubstance: [{value: '', disabled: true}],
      studyType: [{value: '', disabled: false}, Validators.required],
      testSystemTypeSingleSelect: [{value: '', disabled: true}],
      testSystemTypeMultiSelect: [{value: [], disabled: true}],
      testSystemNameSingleSelect: [{value: '', disabled: true}],
      testSystemNameMultiSelect: [{value: [], disabled: true}],
      // additional (depended) fields
      adequate_study__c: [{value: '', disabled: false}],
      application_rate1__c: [{value: '', disabled: false}],
      application_type__c: [{value: '', disabled: false}],
      comment__c: [{value: '', disabled: false}],
      fat_soluble_residues__c: [{value: '', disabled: false}],
      human_unique_or_disproportional_metaboli__c: [{value: '', disabled: false}],
      identified_metabolites__c: [{value: '', disabled: false}],
      open_id_rate_topic__c: [{value: '', disabled: false}],
      open_isomer_topic__c: [{value: '', disabled: false}],
      open_radio_label_topic__c: [{value: '', disabled: false}],
      parent_only__c: [{value: '', disabled: false}],
      plateau_reached_in_milkeggs__c: [{value: '', disabled: false}],
      replant_interval_days__c: [{value: '', disabled: false}],
      residue_uptake_001_mgkg__c: [{value: '', disabled: false}],
      similar_to_metabolism_study__c: [{value: '', disabled: false}],
      similar_to_other_crop_groups__c: [{value: '', disabled: false}],
      similar_to_other_species__c: [{value: '', disabled: false}],
      similar_to_primary_crops__c: [{value: '', disabled: false}],
      similar_to_rat__c: [{value: '', disabled: false}],
      time_needed_to_reach_plateau_in_milkegg__c: [{value: '', disabled: false}],
      unit_application_rate__c: [{value: '', disabled: false}],
      // default
      selectedDocument: [{value: '', disabled: false}],
    });

    this.testSystemTypes = [];
    this.testSystemNames = [];
    this.filteredRelatedSubstances = [];
    this.additionalFields = [];
    this.hiddenAdditionalFields = [];
    this.testSystemTypeSingleField = false;
    this.testSystemTypeMultiField = true;
    this.testSystemNameSingleField = false;
    this.testSystemNameMultiField = true;
  }

  ngOnInit(): void {
    if (this.purpose === 'editOnExisting') {
      this.showCopyOptions = false;
      this.showEditOptions = true;
    }
    else {
      this.showCopyOptions = true;
      this.showEditOptions = false;
    }

    this.selectFormGroup.get('selectedDocument').setValue(this.singleEndpointRecord.document_unbound__c);
    this.studyTypes = this.allStudyTypes;
    this.studyTypes = this.allStudyTypes.sort((a, b) => a.name > b.name ? 1 : -1);

    this.categoryTestSystemTypes = this.allTestSystemTypes.filter(
      (entry) => entry.id === this.category.id);

    if (this.singleEndpointRecord.study_type_picklist_name__c !== '') {
    // COPY or EDIT record
      console.log('copy or edit: ' + JSON.stringify(this.singleEndpointRecord));
      if (this.singleEndpointRecord.substance_type__c === 'parent__c'
        || this.singleEndpointRecord.substance_type__c === '') {
        this.selectFormGroup.get('relatedSubstance').disable();
      }
      else {
        this.selectFormGroup.get('relatedSubstance').enable();
        this.filteredRelatedSubstances = this.relatedSubstances.filter(
          (f) => f.substance_type__c[0] === this.singleEndpointRecord.substance_type__c
        );
      }

      // returns group picklists
      const categoryPicklists = this.categoryPicklists.filter(
        (entry) =>
          entry.studyTypePicklist === this.singleEndpointRecord.study_type_picklist_name__c
      )[0];
      this.filterTestSystemTypes(categoryPicklists.testSystemTypesPicklist); // will determinate type of TST field (single, multi)
      this.filterTestSystemNamesFromCopyOrEdit();

      this.showGroupAdditionalFields();
      Object.keys(this.singleEndpointRecord).forEach((key) => this.checkDependency(key));
      this.setFormFields();
    }
    else {
    // NEW record
      // set default value
      this.selectFormGroup.get('substanceType').setValue('parent__c');
      this.singleEndpointRecord.substance_type__c = 'parent__c';
    }
  }

  getSelectedOptionPicklist(event: any): string {
    return event.target.options[event.target.options.selectedIndex].title;
  }

  onSelectSubstanceType(event: any): any {
    // update object
    this.singleEndpointRecord.substance_type__c = event.target.value;
    // this function is triggered only if value is changed, meaning the group is changed

    this.singleEndpointRecord.related_substance__c = '';
    this.selectFormGroup.get('relatedSubstance').setValue('');

    // parent cannot have Related Substance
    if (event.target.value === 'parent__c') {
      this.selectFormGroup.get('relatedSubstance').disable();
    } else {
      // filter Related Substances by the selected Substance Type
      this.filteredRelatedSubstances = this.relatedSubstances.filter(
        (f) => f.substance_type__c[0] === event.target.value
      );
      this.selectFormGroup.get('relatedSubstance').enable();
    }
  }

  onSelectRelatedSubstance(event: any): any {
    this.singleEndpointRecord.related_substance__c = event.target.value;
  }

  onSelectStudyType(event: any): any {
    this.singleEndpointRecord.study_type__c = event.target.value;
    this.clearTestSystemType(); // 1 Study Type value makes one group, Test System Types depends on this group
    this.clearTestSystemName(); // Test System Names follows Test System Types because they depend on them

    if (event.target.value !== '') {

      this.clearAdditionalFields(); // changing the study type group
      this.singleEndpointRecord.study_type_picklist_name__c = this.getSelectedOptionPicklist(event);
      // returns group picklists
      const categoryPicklists = this.categoryPicklists.filter(
        (entry) =>
          entry.studyTypePicklist === this.getSelectedOptionPicklist(event)
      )[0];
      this.filterTestSystemTypes(categoryPicklists.testSystemTypesPicklist); // will determinate type of TST field (single, multi)
      this.showGroupAdditionalFields();
    }
    else {
      // Study Type = ''
      this.singleEndpointRecord.study_type_picklist_name__c = '';
      this.clearAdditionalFields();
    }
  }

  filterTestSystemTypes(picklist: string): any {
    this.testSystemTypes = [];
    if (picklist === 'test_system_type_metabolism_4__c') {
      // multi select
      this.testSystemTypeSingleField = true; // hidden
      this.testSystemTypeMultiField = false;
      this.selectFormGroup.get('testSystemTypeSingleSelect').disable();
      this.selectFormGroup.get('testSystemTypeMultiSelect').enable();
    } else {
      // single select
      this.testSystemTypeSingleField = false;
      this.testSystemTypeMultiField = true; // hidden
      this.selectFormGroup.get('testSystemTypeSingleSelect').enable();
      this.selectFormGroup.get('testSystemTypeMultiSelect').disable();
    }
    this.testSystemTypes = this.categoryTestSystemTypes.filter(
      (entry) => entry.picklist === picklist
    ).sort((a, b) => a.name > b.name ? 1 : -1);
  }

  onSelectTestSystemType(event: any, fieldType): void {
    // preparation for adding new data to endpoint application
    if (fieldType === 'singleSelect') {
      this.singleEndpointRecord.test_system_type__c = [event.value];
    }
    else if (fieldType === 'multiSelect') {
      this.singleEndpointRecord.test_system_type__c = event.value;
    }

    if (event.value === '') {
      this.clearTestSystemName();
    } else {
      this.filterTestSystemNames(); // will enable field after it filters records
    }
  }

  filterTestSystemNames(): any {

    if (this.singleEndpointRecord.study_type_picklist_name__c === 'study_type_metabolism_4__c') {
      // no need to reset
      // multi select
      this.testSystemNameSingleField = true; // hidden
      this.testSystemNameMultiField = false;
      this.selectFormGroup.get('testSystemNameSingleSelect').disable();
      this.selectFormGroup.get('testSystemNameMultiSelect').enable();
    }
    else if (this.singleEndpointRecord.study_type_picklist_name__c === 'study_type_metabolism_5__c') {
      if (this.singleEndpointRecord.test_system_type__c[0] === 'in_vivo__c') {
        // reset
        this.clearTestSystemName();
        // single
        this.selectFormGroup.get('testSystemNameSingleSelect').enable();
      }
      else {
        //reset
        this.clearTestSystemName();
        // multi select
        this.testSystemNameSingleField = true; // hidden
        this.testSystemNameMultiField = false;
        this.selectFormGroup.get('testSystemNameSingleSelect').disable();
        this.selectFormGroup.get('testSystemNameMultiSelect').enable();
      }
    }
    else {
      // reset
      this.clearTestSystemName();
      // single
      this.selectFormGroup.get('testSystemNameSingleSelect').enable();
    }

    const categoryPicklists = this.categoryPicklists.filter(
      (entry) =>
        entry.studyTypePicklist === this.singleEndpointRecord.study_type_picklist_name__c
    )[0];

    if (categoryPicklists.studyTypePicklist === 'study_type_metabolism_4__c' ||
      categoryPicklists.studyTypePicklist === 'study_type_metabolism_5__c') {
      // filter based on Study Type Group
      this.testSystemNames = this.allTestSystemNames.filter(
      (entry) => entry.name__v === categoryPicklists.studyTypePicklist && entry.picklist === categoryPicklists.testSystemNamesPicklist
      ).sort((a, b) => a.name > b.name ? 1 : -1);
    }
    else {
      this.testSystemNames = []; // must be reset because of "push" function, shouldn't be reset in "if" statement
      if (this.singleEndpointRecord.test_system_type__c[0]) {
        // filter from Single Record Dependencies
        this.singleRecordDependencies.filter((entry) =>
          entry.studyTypeGroup === this.singleEndpointRecord.study_type_picklist_name__c &&
          entry.dependencyFieldValue === this.singleEndpointRecord.test_system_type__c[0]
        ).forEach((element: SingleRecordDependencies) => {
          const entry: PicklistValue = {
            name__v: '',
            id: this.category.id,
            name: element.dependedRecordValue,
            label: element.dependedRecordLabel,
            picklist: ''
          };
          this.testSystemNames.push(entry);
        });
      }
    }
  }

  filterTestSystemNamesFromCopyOrEdit(): any {
    if (this.singleEndpointRecord.study_type_picklist_name__c === 'study_type_metabolism_4__c') {
      // no need to reset
      // multi select
      this.testSystemNameSingleField = true; // hidden
      this.testSystemNameMultiField = false;
      this.selectFormGroup.get('testSystemNameSingleSelect').disable();
      this.selectFormGroup.get('testSystemNameMultiSelect').enable();
    }
    else if (this.singleEndpointRecord.study_type_picklist_name__c === 'study_type_metabolism_5__c') {
      if (this.singleEndpointRecord.test_system_type__c[0] === 'in_vivo__c') {
        // reset
        // single
        this.selectFormGroup.get('testSystemNameSingleSelect').enable();
      }
      else {
        //reset
        // multi select
        this.testSystemNameSingleField = true; // hidden
        this.testSystemNameMultiField = false;
        this.selectFormGroup.get('testSystemNameSingleSelect').disable();
        this.selectFormGroup.get('testSystemNameMultiSelect').enable();
      }
    }
    else {
      this.selectFormGroup.get('testSystemNameSingleSelect').enable();
    }

    if (this.singleEndpointRecord.study_type_picklist_name__c === 'study_type_metabolism_4__c' ||
      this.singleEndpointRecord.study_type_picklist_name__c === 'study_type_metabolism_5__c') {
      // filter based on category (important because categories share ) & Study Type Group

      const categoryPicklists = this.categoryPicklists.filter(
        (entry) =>
          entry.studyTypePicklist === this.singleEndpointRecord.study_type_picklist_name__c
      )[0];

      this.testSystemNames = this.allTestSystemNames.filter(
      (entry) => entry.name__v === categoryPicklists.studyTypePicklist && entry.picklist === categoryPicklists.testSystemNamesPicklist
      ).sort((a, b) => a.name > b.name ? 1 : -1);
    }
    else {
      this.testSystemNames = []; // must be reset because of "push" function, shouldn't be reset in "if" statement
      if (this.singleEndpointRecord.test_system_type__c != null) {
        // filter from Single Record Dependencies
        this.singleRecordDependencies.filter((entry) =>
          entry.studyTypeGroup === this.singleEndpointRecord.study_type_picklist_name__c &&
          entry.dependencyFieldValue === this.singleEndpointRecord.test_system_type__c[0]
        ).forEach((element: SingleRecordDependencies) => {
          const entry: PicklistValue = {
            name__v: '',
            id: this.category.id,
            name: element.dependedRecordValue,
            label: element.dependedRecordLabel,
            picklist: ''
          };
          this.testSystemNames.push(entry);
        });
      }
    }
  }

  onSelectTestSystemName(event: any, fieldType): void {
    if (fieldType === 'singleSelect') {
        this.singleEndpointRecord.test_system_name__c = [event.value];
      }
      else if (fieldType === 'multiSelect') {
        this.singleEndpointRecord.test_system_name__c = event.value;
      }
    this.checkDependency('test_system_name__c');
  }

  updateFieldValue(event: any): any {
    const fieldName = event.title;
    this.singleEndpointRecord[fieldName] = event.value;
    this.checkDependency(fieldName);
  }

  updateMultiPicklistFieldValue(newValue, field: ConsumerSafetyAdditionalFields): void {
    this.singleEndpointRecord[field.name] = newValue.value.toString();
  }

  updateNumberFieldValue(newValue): any {
    const fieldName = newValue.title;

    if (fieldName === 'temp_c__c') {
      this.singleEndpointRecord[fieldName] = newValue.value;
    } else {
      if (newValue.value < 0) {
        this.singleEndpointRecord[fieldName] = '';
        this.selectFormGroup.get(fieldName).setValue('');
      } else {
        this.singleEndpointRecord[fieldName] = newValue.value;
      }
    }
  }

  checkDependency(fieldName: string): void {
    if (fieldName === 'parent_only__c' && this.singleEndpointRecord.parent_only__c != null) {
      if (this.singleEndpointRecord.parent_only__c === 'no__c') {
        // show dependency field
        this.showAdditionalField('identified_metabolites__c');
      }
      else {
        this.singleEndpointRecord.identified_metabolites__c = '';
        this.selectFormGroup.get('identified_metabolites__c').setValue('');
        // hide dependency field
        this.hideAdditionalField('identified_metabolites__c');
      }
    }

    if (fieldName === 'plateau_reached_in_milkeggs__c' && this.singleEndpointRecord.plateau_reached_in_milkeggs__c != null) {
      if (this.singleEndpointRecord.plateau_reached_in_milkeggs__c === 'yes__c') {
        // show dependency field
        this.showAdditionalField('time_needed_to_reach_plateau_in_milkegg__c');
      }
      else {
        this.singleEndpointRecord.time_needed_to_reach_plateau_in_milkegg__c = null;
        this.selectFormGroup.get('time_needed_to_reach_plateau_in_milkegg__c').setValue('');
        // hide dependency field
        this.hideAdditionalField('time_needed_to_reach_plateau_in_milkegg__c');
      }
    }

    if (fieldName === 'test_system_name__c' && this.singleEndpointRecord.test_system_name__c != null) {
      if (this.singleEndpointRecord.test_system_name__c.includes('human__c')) {

        const fieldToShow = this.additionalFields.filter(obj =>
        obj.name === 'human_unique_or_disproportional_metaboli__c');

        if (fieldToShow.length > 0) {
          // nothing
        }
        else {
          this.showAdditionalField('human_unique_or_disproportional_metaboli__c');
        }

      }
      else {
        this.singleEndpointRecord.human_unique_or_disproportional_metaboli__c = '';
        this.selectFormGroup.get('human_unique_or_disproportional_metaboli__c').setValue('');
        // hide dependency field
        this.hideAdditionalField('human_unique_or_disproportional_metaboli__c');
      }
    }

  }

  filterAdditionalFieldValues(
    allAdditionalFieldValues: Array<ConsumerSafetyAdditionalFields>,
    filterWord: string,
  ): Array<ConsumerSafetyAdditionalFields> {

    if (filterWord === 'ghost__c') {
      return this.identifiedMetabolites;
    }
    else {
      return allAdditionalFieldValues.filter(
        (item) => item.picklist === filterWord
      );
    }
  }

  clearAdditionalFields(): void {
    // endpoint values
    this.singleEndpointRecord.adequate_study__c = '';
    this.singleEndpointRecord.application_rate1__c = null;
    this.singleEndpointRecord.application_type__c = '';
    this.singleEndpointRecord.comment__c = '';
    this.singleEndpointRecord.fat_soluble_residues__c = '';
    this.singleEndpointRecord.human_unique_or_disproportional_metaboli__c = '';
    this.singleEndpointRecord.identified_metabolites__c = '';
    this.singleEndpointRecord.open_id_rate_topic__c = '';
    this.singleEndpointRecord.open_isomer_topic__c = '';
    this.singleEndpointRecord.open_radio_label_topic__c = '';
    this.singleEndpointRecord.parent_only__c = '';
    this.singleEndpointRecord.plateau_reached_in_milkeggs__c = '';
    this.singleEndpointRecord.replant_interval_days__c = '';
    this.singleEndpointRecord.residue_uptake_001_mgkg__c = '';
    this.singleEndpointRecord.similar_to_metabolism_study__c = '';
    this.singleEndpointRecord.similar_to_other_crop_groups__c = '';
    this.singleEndpointRecord.similar_to_other_species__c = '';
    this.singleEndpointRecord.similar_to_primary_crops__c = '';
    this.singleEndpointRecord.similar_to_rat__c = '';
    this.singleEndpointRecord.time_needed_to_reach_plateau_in_milkegg__c = null;
    this.singleEndpointRecord.unit_application_rate__c = '';
    // form values
    this.selectFormGroup.get('adequate_study__c').setValue('');
    this.selectFormGroup.get('application_rate1__c').setValue('');
    this.selectFormGroup.get('application_type__c').setValue('');
    this.selectFormGroup.get('comment__c').setValue('');
    this.selectFormGroup.get('fat_soluble_residues__c').setValue('');
    this.selectFormGroup.get('human_unique_or_disproportional_metaboli__c').setValue('');
    this.selectFormGroup.get('identified_metabolites__c').setValue('');
    this.selectFormGroup.get('open_id_rate_topic__c').setValue('');
    this.selectFormGroup.get('open_isomer_topic__c').setValue('');
    this.selectFormGroup.get('open_radio_label_topic__c').setValue('');
    this.selectFormGroup.get('parent_only__c').setValue('');
    this.selectFormGroup.get('plateau_reached_in_milkeggs__c').setValue('');
    this.selectFormGroup.get('replant_interval_days__c').setValue('');
    this.selectFormGroup.get('residue_uptake_001_mgkg__c').setValue('');
    this.selectFormGroup.get('similar_to_metabolism_study__c').setValue('');
    this.selectFormGroup.get('similar_to_other_crop_groups__c').setValue('');
    this.selectFormGroup.get('similar_to_other_species__c').setValue('');
    this.selectFormGroup.get('similar_to_primary_crops__c').setValue('');
    this.selectFormGroup.get('similar_to_rat__c').setValue('');
    this.selectFormGroup.get('time_needed_to_reach_plateau_in_milkegg__c').setValue('');
    this.selectFormGroup.get('unit_application_rate__c').setValue('');

    this.additionalFields = [];
  }

  showGroupAdditionalFields(): void {
    const preAllAdditionalFields = this.allAdditionalFields.filter(
      (entry) =>
        entry.group === this.singleEndpointRecord.study_type_picklist_name__c
    );
    // remove fields mark with 'yes__c' in Initially Hidden field
    const preAdditionalFields = preAllAdditionalFields.filter(obj =>
      obj.hidden !== 'yes__c'
    );
    this.additionalFields = preAdditionalFields.sort((a, b) => a.sortNumber - b.sortNumber);
  }

  showAdditionalField (fieldName: string): void {
    // get all additional fields for study type group
    const allAdditionalFields = this.allAdditionalFields.filter(
      (entry) =>
        entry.group === this.singleEndpointRecord.study_type_picklist_name__c
    );
    // find field to show
    const fieldToShow = allAdditionalFields.filter(obj =>
      obj.name === fieldName
    );
    this.additionalFields = this.additionalFields.concat(fieldToShow);
    this.additionalFields = this.additionalFields.sort((a, b) => a.sortNumber - b.sortNumber);
  }

  hideAdditionalField(fieldName: string): void {
    const preAdditionalFields = this.additionalFields.filter(obj =>
      obj.name !== fieldName
    );
    this.additionalFields = preAdditionalFields.sort((a, b) => a.sortNumber - b.sortNumber);
  }

  setFormFields(): void {
    this.selectFormGroup.get('substanceType').setValue(this.singleEndpointRecord.substance_type__c);
    this.selectFormGroup.get('relatedSubstance').setValue(this.singleEndpointRecord.related_substance__c);
    this.selectFormGroup.get('studyType').setValue(this.singleEndpointRecord.study_type__c);
    if (this.singleEndpointRecord.test_system_type__c) {
      this.selectFormGroup.get('testSystemTypeSingleSelect').setValue(this.singleEndpointRecord.test_system_type__c.toString());
      this.selectFormGroup.get('testSystemTypeMultiSelect').setValue(this.singleEndpointRecord.test_system_type__c);
    }
    if (this.singleEndpointRecord.test_system_name__c)
    {
      this.selectFormGroup.get('testSystemNameSingleSelect').setValue(this.singleEndpointRecord.test_system_name__c.toString());
      this.selectFormGroup.get('testSystemNameMultiSelect').setValue(this.singleEndpointRecord.test_system_name__c);
    }
    // additional fields
    this.selectFormGroup.get('adequate_study__c').setValue(this.singleEndpointRecord.adequate_study__c);
    this.selectFormGroup.get('application_rate1__c').setValue(this.singleEndpointRecord.application_rate1__c);
    this.selectFormGroup.get('application_type__c').setValue(this.singleEndpointRecord.application_type__c);
    this.selectFormGroup.get('comment__c').setValue(this.singleEndpointRecord.comment__c);
    this.selectFormGroup.get('fat_soluble_residues__c').setValue(this.singleEndpointRecord.fat_soluble_residues__c);
    this.selectFormGroup.get('human_unique_or_disproportional_metaboli__c')
      .setValue(this.singleEndpointRecord.human_unique_or_disproportional_metaboli__c);
     if (this.singleEndpointRecord.identified_metabolites__c) {
      this.selectFormGroup.get('identified_metabolites__c')
        .setValue(this.singleEndpointRecord.identified_metabolites__c.split(','));
    }
    this.selectFormGroup.get('open_id_rate_topic__c').setValue(this.singleEndpointRecord.open_id_rate_topic__c);
    this.selectFormGroup.get('open_isomer_topic__c').setValue(this.singleEndpointRecord.open_isomer_topic__c);
    this.selectFormGroup.get('open_radio_label_topic__c').setValue(this.singleEndpointRecord.open_radio_label_topic__c);
    this.selectFormGroup.get('parent_only__c').setValue(this.singleEndpointRecord.parent_only__c);
    this.selectFormGroup.get('plateau_reached_in_milkeggs__c').setValue(this.singleEndpointRecord.plateau_reached_in_milkeggs__c);
    if (this.singleEndpointRecord.replant_interval_days__c) {
      this.selectFormGroup.get('replant_interval_days__c')
        .setValue(this.singleEndpointRecord.replant_interval_days__c.split(','));
    }
    this.selectFormGroup.get('residue_uptake_001_mgkg__c').setValue(this.singleEndpointRecord.residue_uptake_001_mgkg__c);
    this.selectFormGroup.get('similar_to_metabolism_study__c').setValue(this.singleEndpointRecord.similar_to_metabolism_study__c);
    this.selectFormGroup.get('similar_to_other_crop_groups__c').setValue(this.singleEndpointRecord.similar_to_other_crop_groups__c);
    this.selectFormGroup.get('similar_to_other_species__c').setValue(this.singleEndpointRecord.similar_to_other_species__c);
    this.selectFormGroup.get('similar_to_primary_crops__c').setValue(this.singleEndpointRecord.similar_to_primary_crops__c);
    this.selectFormGroup.get('similar_to_rat__c').setValue(this.singleEndpointRecord.similar_to_rat__c);
    this.selectFormGroup.get('time_needed_to_reach_plateau_in_milkegg__c').setValue(this.singleEndpointRecord.time_needed_to_reach_plateau_in_milkegg__c);
    this.selectFormGroup.get('unit_application_rate__c').setValue(this.singleEndpointRecord.unit_application_rate__c);
    // default
    this.selectFormGroup.get('selectedDocument')
      .setValue(this.singleEndpointRecord.document_unbound__c);
  }

  clearTestSystemType(): void {
    this.testSystemTypes = [];
    this.singleEndpointRecord.test_system_type__c = [];
    this.selectFormGroup.get('testSystemTypeSingleSelect').setValue('');
    this.selectFormGroup.get('testSystemTypeMultiSelect').setValue('');
    this.selectFormGroup.get('testSystemTypeSingleSelect').disable();
    this.selectFormGroup.get('testSystemTypeMultiSelect').disable();
    this.testSystemTypeSingleField = false;
    this.testSystemTypeMultiField = true;
  }

  clearTestSystemName(): void {
    this.testSystemNames = [];
    this.singleEndpointRecord.test_system_name__c = [];
    this.selectFormGroup.get('testSystemNameSingleSelect').setValue('');
    this.selectFormGroup.get('testSystemNameMultiSelect').setValue('');
    this.selectFormGroup.get('testSystemNameSingleSelect').disable();
    this.selectFormGroup.get('testSystemNameMultiSelect').disable();
    this.testSystemNameSingleField = false;
    this.testSystemNameMultiField = true;

    this.singleEndpointRecord.human_unique_or_disproportional_metaboli__c = '';
    this.selectFormGroup.get('human_unique_or_disproportional_metaboli__c').setValue('');
    // hide dependency field
    this.hideAdditionalField('human_unique_or_disproportional_metaboli__c');

  }

  removeEndpoint(singleEndpointRecord: ConsumerSafetyEndpointTableItem): any {
    this.removeInput.emit(singleEndpointRecord);
  }

  copyEndpoint(singleEndpointRecord: ConsumerSafetyEndpointTableItem): void {

    this.copyInput.emit(singleEndpointRecord);
  }

  cancelEditing(endpointRecord): void {
    this.cancelEdit.emit(endpointRecord);
  }

  saveEndpointChanges(endpointRecord): void {
    this.saveChanges.emit(endpointRecord);
  }
}
