import {NgModule} from '@angular/core';
import {RouterModule, Routes} from "@angular/router";
import {SelectPageComponent} from "./components/select-page/select-page.component";
import {HomeComponent} from "./components/home/home.component";
import {DashboardComponent} from './components/dashboard/dashboard.component';

const childModuleRoutes: Routes = [
  {
    path: '',
    component: HomeComponent,
    children: [
      {
        path: 'select',
        component: SelectPageComponent
      },
      {
        path: 'dashboard',
        component: DashboardComponent
      },
      {
        path: '', pathMatch: 'full', redirectTo: 'select'
      }
    ]
  },
  {path: '', pathMatch: 'full', component: HomeComponent},
]

@NgModule({
  declarations: [],
  imports: [
    RouterModule.forChild(childModuleRoutes),

  ],
  exports: [RouterModule]
})
export class AiCompareRoutingModule { }
