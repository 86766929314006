import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {addDialogData} from './export-data-interface';
import {ENTER} from '@angular/cdk/keycodes';
import {MatChipInputEvent} from '@angular/material/chips';
import {PicklistValue} from "../../endpoint/models/picklist-value";
import {EndpointServicesService} from "../../endpoint/endpoint-services.service";
import {ToastrService} from 'ngx-toastr';
import {ExceptionService} from 'src/app/core/exception.service';
import {ServerService} from 'src/app/core/server.service';
import {ActivatedRoute} from "@angular/router";
import {addNewValuesToPicklists} from "../add-new-values-to-picklists";

@Component({
  selector: 'app-add-data-mat-dialog',
  templateUrl: './add-data-mat-dialog.component.html',
  styleUrls: ['./add-data-mat-dialog.component.css']
})
export class AddDataMatDialogComponent implements OnInit {
  private currentUserProfile: string;
  public oldList: any;
  public newList: any = [];
  public sourceListToSave: string;
  public targetListToSave: string;
  public targetPicklistValues: any;
  subscriptions: any;
  loading = false;
  disabled = true;
  errorMessage = true;
  addOnBlur = true;
  readonly separatorKeysCodes = [ENTER] as const;
  allValues: PicklistValue[] = []; // this creates chips
  public attemptNumber: number;

  constructor(
    private route: ActivatedRoute,
    private serverService: ServerService,
    private exceptionService: ExceptionService,
    private toastrService: ToastrService,
    public dialogRef: MatDialogRef<AddDataMatDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: addDialogData,
    private endpointService: EndpointServicesService,
    private propagateNewData: addNewValuesToPicklists
  ) {
    this.subscriptions = {};
    this.oldList = [];
    this.sourceListToSave = '';
    this.targetListToSave = '';
    this.attemptNumber = 0;
  }

  ngOnInit(): void {


    console.log('typeOfDependency : ' + JSON.stringify(this.data.typeOfDependency));
    console.log('currentList : ' + JSON.stringify(this.data.currentList));
    console.log('sourcePicklist : ' + JSON.stringify(this.data.sourcePicklist));
    console.log('targetPicklist : ' + JSON.stringify(this.data.targetPicklist));
    console.log('categoryId : ' + JSON.stringify(this.data.categoryId));

    this.endpointService.userProfile.subscribe(
      (profile) => (this.currentUserProfile = profile)
    );

    this.getTargetPicklistValues(this.data.targetPicklist);

    if (this.data.typeOfDependency === 'group') {
      this.prepareGroupList();
    }
    else if (this.data.typeOfDependency === 'singleRecordDependency') {
      this.prepareSRDList();
    }
  }

  getTargetPicklistValues(picklistName): void {
    this.targetPicklistValues = [];
    this.serverService
      .getPicklist(this.data.sessionId, picklistName)
      .subscribe(
        (response) => {
          if (response.picklistValues && response.picklistValues.length > 0) {
            response.picklistValues.forEach((element: any) => {
              const entry = {
                name__v: '',
                id: element.group,
                label: element.label,
                name: element.name,
                picklist: element.picklist,
                saveTo: element.saveTo,
              };
              this.targetPicklistValues.push(entry);
            });
          }
        },
        () => {
          if (this.attemptNumber >= 2) {
            this.toastrService.error(
              `Unable to get picklist values for ${picklistName}.`,
              'Error'
            );
          }
          else {
            this.attemptNumber = this.attemptNumber + 1;
            this.getTargetPicklistValues(this.data.targetPicklist);
          }
        },
      );
  }

  prepareGroupList(): void {
    // source = picklist
    this.data.currentList.forEach((element: any) => {
         this.oldList.push({name__v: '', id: '', name: element.name, label: element.label, picklist: element.picklist});
         this.allValues.push({name__v: '', id: '', label: element.label, name: element.name, picklist: element.picklist});
        }
      );
  }

  prepareSRDList(): void {
    // source = object (single record dependency)

  }

  onNoClick(): void {
    this.resetAllLists();
    this.dialogRef.close('canceled');
  }

  resetAllLists(): void {
    this.oldList = [];
    this.allValues = [];
    this.newList = [];
    this.sourceListToSave = '';
    this.targetListToSave = '';
  }

  add(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();
    // Add our Value
    if (value) {
      // check if value is already in the list
      if (this.allValues.some(function(el) {
        return el.label === value;
      })
      ) {
        console.log('already exist');
      }
      else {
        this.allValues.push({name__v: '', id: '', label: value, name: '', picklist: this.data.sourcePicklist});
        this.newList.push({name__v: '', id: this.data.categoryId, label: value, name: '', picklist: this.data.sourcePicklist});
        console.log('added TEST');
      }
    }
    // Clear the input value
    event.chipInput!.clear();
  }

  onConfirm(): void {
    // if ok: this.dialogRef.close('confirmed');
    if (this.data.typeOfDependency === 'group') {
      this.savePicklistSource();
    } else if (this.data.typeOfDependency === 'singleRecordDependency') {
      this.saveSingleRecord();
    }
  }

  savePicklistSource(): void {
    this.sourceListToSave = ''; // resetting because of concat
    this.targetListToSave = ''; // resetting because of concat

    if (this.currentUserProfile === 'vault_owner__v'
    || this.currentUserProfile === 'super_user__c' ) {
      let startPointSource = 1;
      let startPointTarget = 1;

      if (this.newList.length > 0) {
        // if anything has been added
        if (this.data.sourcePicklist != this.data.targetPicklist) {
          // if target is different from source
          // construct query for source list
          this.newList.forEach((element: any) => {
              this.sourceListToSave = this.sourceListToSave.concat('value_' + (startPointSource++) + '=' + element.label + '&');
            }
          );
          this.addPicklistValues(this.sourceListToSave, this.data.sourcePicklist);
        }
        // construct query for target picklist
        this.newList.forEach((newPicklistValue) => {
          if (this.targetPicklistValues.some(function(targetValue) {
            return targetValue.label === newPicklistValue.label;
          })
          ) {
            // skipping, already in target picklist
             console.log('value already exist in target picklist: "' + newPicklistValue.label + '"');
          }
          else {
            this.targetListToSave = this.targetListToSave.concat('value_' + (startPointTarget ++) + '=' + newPicklistValue.label + '&');
          }
        });
        // if anything has been added to target picklist
        if (this.targetListToSave.length > 0) {
          this.addPicklistValues(this.targetListToSave, this.data.targetPicklist);
        }
      }
      else {
        console.log('nothing new to save');
      }
    }
    else {
     alert('Access denied!');
    }
  }

  saveSingleRecord(): void {
    if (this.currentUserProfile === 'vault_owner__v'
    || this.currentUserProfile === 'super_user__c' ) {
      // SAVE

    }
    else {
     alert('Access denied!');
    }
  }

  addPicklistValues(valuesToSave, picklistName): void {
    this.serverService.saveValuesToPicklist(this.data.sessionId, picklistName, valuesToSave).subscribe({
      next: (success) => {
        success.picklistValues?.forEach((record) => {
          this.newList.map(picklistRecord => {
            if (picklistRecord.label === record.label) {
              picklistRecord.name = record.name;
            }
          })
          console.log('Picklist: ' + picklistName + ', Label: ' + record.label + ', Name: ' + record.name);
        });
      },
      error: (error) => {
        this.exceptionService.handleError(error);
        this.toastrService.error(
          `An error occurred while saving picklist.`,
          'Error'
        );
      },
      complete: () => {
        console.log('updated new list after save: ' + JSON.stringify(this.newList));
        this.serverService.isNotLoading();
        // send to shared service
        this.propagateNewData.receiveArrayName([this.data.dialogFieldName, this.newList]);
        this.dialogRef.close(this.newList);
      }
    });
  }


}
