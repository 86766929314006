import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AppComponent} from './app.component';
import {HomePageComponent} from './core/home-page/home-page.component';
import {NotFoundComponent} from './core/not-found/not-found.component';

const routes: Routes = [
  {path: '', pathMatch: 'full', component: AppComponent},
  {
    path: 'timeline',
    loadChildren: () =>
      import('./timeline/timeline.module').then((m) => m.TimelineModule),
  },
  {
    path: 'report/spider',
    loadChildren: () =>
      import('./spider/spider.module').then((m) => m.SpiderModule),
  },
  {
    path: 'map',
    loadChildren: () => import('./map/map.module').then((m) => m.MapModule),
  },
  {
    path: 'endpoint',
    loadChildren: () =>
      import('./endpoint/endpoint.module').then((m) => m.EndpointModule),
  },
  {
    path: 'registration',
    loadChildren: () =>
      import('./registration/registration.module').then(
        (m) => m.RegistrationModule
      ),
  },
  {
    path: 'aicompare',
    loadChildren: () =>
      import('./ai-compare/ai-compare.module').then(
        (m) => m.AiCompareModule
      ),
  },
  {
    path: 'home', pathMatch: 'full', component: HomePageComponent
  },
  {path: '**', pathMatch: 'full', component: NotFoundComponent},
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: true,
      relativeLinkResolution: 'legacy',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
