import {Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild, ViewEncapsulation} from '@angular/core';
import {MatTable, MatTableDataSource} from '@angular/material/table';
import {Evaluation} from '../../models/evaluation';
import {VaultService} from '../../service/vault.service';
import {Row} from '../../models/row';
import {TableRow} from '../../models/table-row';
import {MatDialog} from "@angular/material/dialog";
import {RowSpanComputer, Span} from "./row-span-computer";

export interface rowSpan {
  workArea: string,
  rowspan: number
}

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class TableComponent implements OnInit, OnChanges {

  @ViewChild(MatTable) table: MatTable<TableRow>;
  isLoading: boolean;
  evaluations: Evaluation[];
  @Input() rows: Row[];
  commentsData: TableRow[] = [];
  overallFuturesData: TableRow[] = [];
  singleRiskScoreData: TableRow[] = [];
  /** Columns displayed in the table. Columns IDs can be added, removed, or reordered. */
  commentsTableCols: string[] = [];
  overallFuturesCols: string[] = [];
  singleRiskScoreCols: string[] = ['Active Ingredient', 'Region', 'Evaluation', 'Single Risk Score Current',
    'Single Risk Score Future'];
  commentsDatasource: MatTableDataSource<any>;
  overallFuturesDatasource: MatTableDataSource<any>;
  singleRiskScoresDatasource: MatTableDataSource<any>;
  rowSpans: Array<Span[]>;

  private rowSpanComputer = new RowSpanComputer();

  constructor(
    private vaultService: VaultService,
    public dialog: MatDialog
  ) {
    this.evaluations = this.vaultService.evaluations;
    this.commentsDatasource = new MatTableDataSource();
  }

  ngOnInit(): void {
    this.isLoading = true;
    this.evaluations = this.vaultService.evaluations;
    if (this.evaluations != undefined && this.evaluations.length > 0) {
      this.getDisplayedColumns();
      this.vaultService.getOverallFuturesRows().subscribe(response => {
        this.overallFuturesData = response;
        this.overallFuturesDatasource = new MatTableDataSource<TableRow>(this.overallFuturesData);
      });
      this.vaultService.getSingleRiskScoresTableData().subscribe(response => {
        this.singleRiskScoreData = response;
        this.singleRiskScoresDatasource = new MatTableDataSource<TableRow>(this.singleRiskScoreData);
      })
    }
  }

  ngOnChanges(changes: SimpleChanges){
    if (!changes.rows.firstChange) {
      this.commentsData = this.vaultService.getTableData(changes.rows.currentValue);
      this.rowSpans = this.rowSpanComputer.compute(this.commentsData, this.commentsTableCols.slice(0, 1));
      this.commentsDatasource = new MatTableDataSource<TableRow>(this.commentsData);
      this.isLoading = false;
    }
  }

  getDisplayedColumns(): void {
    this.commentsTableCols.push('Work Area');
    this.commentsTableCols.push("Category");
    this.overallFuturesCols.push("Work Area");
    this.evaluations.forEach(e => {
      let currentColumnHeader = e.name__v + ` (current)`;
      let futureColumnHeader = e.name__v + ` (future)`;
      this.commentsTableCols.push(currentColumnHeader);
      this.commentsTableCols.push(futureColumnHeader);
      this.overallFuturesCols.push(e.name__v);
    });
  }
}
