import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {PicklistValue} from '../../../models/picklist-value';
import {ToxEndpointTableItem} from '../../../models/tox-endpoint-table-item';
import {ToxAdditionalField} from '../../../models/tox-additional-field';
import {DocumentMenuItem} from '../../../models/document-menu-item';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute} from '@angular/router';
import {ExceptionService} from '../../../../core/exception.service';
import {ServerService} from '../../../../core/server.service';
import {ToastrService} from 'ngx-toastr';
import {ToxExistingEndpoint} from '../../../models/tox-existing-endpoint';
import {convertArrayToString} from '../../../../shared/convert-array-to-string';
import {CreateObjectResponse, CreateObjectsResponse} from '../../../models/create-objects-response';
import {VaultError} from '../../../../core/models/vault-error';
import {HttpErrorResponse} from '@angular/common/http';

let me: MetabolitesImpuritiesExistingComponent;

@Component({
  selector: 'app-metabolites-impurities-existing',
  templateUrl: './metabolites-impurities-existing.component.html',
  styleUrls: ['../../../existing_record.css']
})
export class MetabolitesImpuritiesExistingComponent implements OnInit {
  @Input() arrayedEndpointRecord: ToxExistingEndpoint;
  @Input() category: any;
  @Input() allStudyTypes: Array<PicklistValue>;
  @Input() allRouteOfAdministration: Array<PicklistValue>;
  @Input() allTestSystemNames: Array<PicklistValue>;
  @Input() allEndpoints: Array<PicklistValue>;
  @Input() allEndpointUnits: Array<PicklistValue>;
  @Input() allOperators: Array<PicklistValue>;
  @Input() allAdditionalFields: Array<ToxAdditionalField>;
  @Input() allAdditionalFieldValues: Array<PicklistValue>;
  @Input() substanceTypes: Array<PicklistValue>;
  @Input() relatedSubstances: any;
  @Input() documents: Array<DocumentMenuItem>;
  @Input() allUsers: any;

  @Output() removeInput: EventEmitter<ToxEndpointTableItem> = new EventEmitter();
  @Output() copyExisting: EventEmitter<any> = new EventEmitter();

  subscriptions: any;
  selectFormGroup: FormGroup;
  configureFormGroup: FormGroup;
  public existingEndpointRecord: any;
  public preAdditionalFields: Array<ToxAdditionalField>;
  public additionalFields: Array<ToxAdditionalField>;
  public addFields: any;
  public additionalFieldValues: Map<string, any>;
  public operators: Array<PicklistValue>;
  public values3: Array<any>;
  public isEndpointUnitDisabled: boolean;
  public hiddenAdditionalFields: Array<ToxAdditionalField>;
  public isValue1Disabled: boolean;
  public isValue2Disabled: boolean;
  public isValue3Disabled: boolean;
  public userName: any;
  public createdBy: any;
  public modifiedBy: any;
  public formatCreatedDate: string;
  public formatModifiedDate: string;
  private haveErrors: Boolean;
  showExistingRecord = true;
  showEditRecord = false;
  public purposeMessage: string;
  private sessionId: string;

  constructor(
    private route: ActivatedRoute,
    private exceptionService: ExceptionService,
    private serverService: ServerService,
    private toastrService: ToastrService,
    private formBuilder: FormBuilder
  ) {
    me = this;
    this.subscriptions = {};
    this.route.paramMap.subscribe((params) => {
      this.sessionId = params.get('sessionId');
    });
    this.selectFormGroup = this.formBuilder.group({
      category__c: [{disabled: true}],
      substanceType: [],
      relatedSubstance: [{value: '', disabled: true}],
      studyType: [{value: '', disabled: false}, Validators.required],
      route_of_administration__c: [{value: '', disabled: false}],
      test_system_name__c: [{value: '', disabled: false}],
      endpoint: [{value: '', disabled: false}, Validators.required],
      endpointUnit: [{value: '', disabled: false}, Validators.required],
      operator: ['', Validators.required],
      value1: [{value: '', disabled: false}],
      value2: [{value: '', disabled: false}],
      value3: [{value: '', disabled: false}],
      // additional fields
      test_method_skin_sensitization__c: [{value: '', disabled: false}],
      ec3__c: [{value: '', disabled: false}],
      ec15__c: [{value: '', disabled: false}],
      target_organ__c: [{value: '', disabled: false}],
      noael_carcinogenicity_mgkg_bwd__c: [{value: '', disabled: false}],
      loael_carcinogenicity_mgkg_bwd__c: [{value: '', disabled: false}],
      tumor_site_male__c: [{value: '', disabled: false}],
      tumor_site_female__c: [{value: '', disabled: false}],
      noael_fertility_mgkg_bwd__c: [{value: '', disabled: false}],
      loael_fertility_mgkg_bwd__c: [{value: '', disabled: false}],
      noael_offspring_mgkg_bwd__c: [{value: '', disabled: false}],
      loael_offspring_mgkg_bwd__c: [{value: '', disabled: false}],
      noael_maternal_mgkg_bwd__c: [{value: '', disabled: false}],
      loael_maternal_mgkg_bwd__c: [{value: '', disabled: false}],
      noael_developmental_mgkg_bwd__c: [{value: '', disabled: false}],
      loael_developmental_mgkg_bwd__c: [{value: '', disabled: false}],
      eats_and_developmental_parameters_affect__c: [{value: '', disabled: false}],
      ed_mechanistic_study__c: [{value: '', disabled: false}],
      // default
      source: [{value: '', disabled: false}],
    });
    this.operators = [];
    this.values3 = ['yes', 'no', 'vulnerable'];
    this.preAdditionalFields = [];
    this.additionalFields = [];
    this.addFields = [];
    this.additionalFieldValues = new Map();
    this.isEndpointUnitDisabled = false;
    this.isValue1Disabled = true;
    this.isValue2Disabled = true;
    this.isValue3Disabled = true;
    this.purposeMessage = 'editOnExisting';
  }

  ngOnInit(): void {

    this.selectFormGroup.disable();

    // convert record values to string values
    this.existingEndpointRecord = JSON.parse(
      JSON.stringify(this.arrayedEndpointRecord)
    );

    convertArrayToString(
      this.arrayedEndpointRecord,
      this.existingEndpointRecord,
      []
    );

    if (this.existingEndpointRecord.created_by__v === 1) {

      this.createdBy = [{name: 'System'}];
    } else {
      this.createdBy = this.allUsers.filter(
        (c) => c.id === this.existingEndpointRecord.created_by__v
      );
    }

    if (this.existingEndpointRecord.modified_by__v === 1) {
      // eslint-disable-next-line quote-props
      this.modifiedBy = [{name: 'System'}];
    } else {
      this.modifiedBy = this.allUsers.filter(
        (m) => m.id === this.existingEndpointRecord.modified_by__v
      );
    }

    this.formatCreatedDate =
      this.existingEndpointRecord.created_date__v.substring(0, 10) +
      ', ' +
      this.existingEndpointRecord.created_date__v.substring(11, 16) +
      'h Z';
    this.formatModifiedDate =
      this.existingEndpointRecord.modified_date__v.substring(0, 10) +
      ', ' +
      this.existingEndpointRecord.modified_date__v.substring(11, 16) +
      'h Z';

    this.selectFormGroup.get('source').setValue(this.existingEndpointRecord.document_unbound__c);

    const filteredOperators = [
      'a1595852199787__c',
      'a1595852189563__c',
      'a1595852194618__c',
      'a1595852268945__c',
      'a1595852266201__c',
      'between__c',
      'a1595852359285__c',
      'text__c'
    ];

    filteredOperators.forEach(operator => {
        const singleOperator = this.allOperators.filter(
          (entry) => entry.name === operator
        );
        this.operators = this.operators.concat(singleOperator);
      }
    );

    switch (this.existingEndpointRecord.operator__c) {
      case 'between__c':
        this.isValue1Disabled = false;
        this.isValue2Disabled = false;
        this.isValue3Disabled = true;
        break;
      case 'text__c':
        this.isValue1Disabled = true;
        this.isValue2Disabled = true;
        this.isValue3Disabled = false;
        break;
      case null:
        this.isValue1Disabled = true;
        this.isValue2Disabled = true;
        this.isValue3Disabled = true;
        break;
      default:
        this.isValue1Disabled = false;
        this.isValue2Disabled = true;
        this.isValue3Disabled = true;
        break;
    }

    this.defineAdditionalFields();
  }

  defineAdditionalFields(): void {
    this.preAdditionalFields = [];
    this.additionalFields = [];
    // NEUTRAL
    if (this.existingEndpointRecord.study_type__c === 'acute_oral__c' ||
      this.existingEndpointRecord.study_type__c === 'acute_dermal__c' ||
      this.existingEndpointRecord.study_type__c === 'acute_inhalation__c' ||
      this.existingEndpointRecord.study_type__c === 'skin_irritation__c' ||
      this.existingEndpointRecord.study_type__c === 'eye_irritation__c' ||
      this.existingEndpointRecord.study_type__c === 'genotoxicity_in_vitro__c' ||
      this.existingEndpointRecord.study_type__c === 'genotoxicity_in_vivo__c'
    ) {
      this.additionalFields = [];
    }

    // ORANGE
    else if (this.existingEndpointRecord.study_type__c === 'skin_sensitization__c') {
      this.addFields = [
        'test_method_skin_sensitization__c',
        'ec3__c',
        'ec15__c'
      ];
    }
    // BLUE
    else if (this.existingEndpointRecord.study_type__c === '28day__c' ||
      this.existingEndpointRecord.study_type__c === '90day__c' ||
      this.existingEndpointRecord.study_type__c === '18month__c' ||
      this.existingEndpointRecord.study_type__c === '2year__c' ||
      this.existingEndpointRecord.study_type__c === 'mechanistic_study__c'
    )
    {
      this.addFields = [
        'target_organ__c',
        'noael_carcinogenicity_mgkg_bwd__c',
        'loael_carcinogenicity_mgkg_bwd__c',
        'tumor_site_male__c',
        'tumor_site_female__c'
      ];
    }
    // GREEN
    else if (this.existingEndpointRecord.study_type__c === 'reproductive_toxicity_study__c') {
      this.addFields = [
        'noael_fertility_mgkg_bwd__c',
        'loael_fertility_mgkg_bwd__c',
        'noael_offspring_mgkg_bwd__c',
        'loael_offspring_mgkg_bwd__c',
        'noael_maternal_mgkg_bwd__c',
        'loael_maternal_mgkg_bwd__c',
        'noael_developmental_mgkg_bwd__c',
        'loael_developmental_mgkg_bwd__c',
        'target_organ__c',
        'eats_and_developmental_parameters_affect__c'
      ];
    }
    // PURPLE
    else if (this.existingEndpointRecord.study_type__c === 'mechanistic_study_for_ed__c') {
      this.addFields = ['ed_mechanistic_study__c'];
    }

    this.addFields.forEach(field => {
        const singleField = this.allAdditionalFields.filter(
          (entry) => entry.name === field
        );
        this.preAdditionalFields = this.preAdditionalFields.concat(singleField);
      }
    );

    this.makeUniqueAdditionalFields();
    this.setFormFields();
  }

  makeUniqueAdditionalFields(): void {
    const source = this.preAdditionalFields;
    const result = this.additionalFields;

    const map = new Map();
    for (const item of source) {
      if (!map.has(item.name)) {
        map.set(item.name, true);
        result.push({
          categoryId: item.categoryId,
          label: item.label,
          name: item.name,
          type: item.type,
          picklist: item.picklist,
          group: item.group,
          saveTo: item.saveTo,
          hidden: item.hidden,
          sortNumber: item.sortNumber
        });
      }
    }

    this.additionalFields = this.additionalFields.sort((a, b) => a.sortNumber - b.sortNumber);

  }

  enableEditing(): any {
    this.showExistingRecord = false;
    this.showEditRecord = true;
  }

  cancelEditing(): void {
    this.showExistingRecord = true;
    this.showEditRecord = false;
  }

  saveChanges(endpointRecord): void {
    this.haveErrors = false;

    this.serverService.isLoading();

    delete endpointRecord.created_by__v;
    delete endpointRecord.modified_by__v;
    delete endpointRecord.created_date__v;
    delete endpointRecord.modified_date__v;

    const value1 = parseInt(String(endpointRecord.value_1__c));
    const value2 = parseInt(String(endpointRecord.value_2__c));

    if (endpointRecord.operator__c === 'between__c') {
      if (value2 <= value1 || isNaN(value1) || isNaN(value2)) {
        this.haveErrors = true;
        console.log(value1 + ', ' + value2);
      }
    }

    if (this.haveErrors === true) {
      console.log('saving with errors!');
      this.serverService.isNotLoading();
      this.toastrService.error('Value 2 must be greater than Value 1', 'Values Error');
    }
    else {
      const body = Array(endpointRecord);

      this.serverService.updateEndpoint(this.sessionId, body).subscribe(
        (success: CreateObjectsResponse) => {
          let updated = 0;
          success.data?.forEach((record: CreateObjectResponse) => {
            if (record?.responseStatus === 'SUCCESS') {
              updated++;
            }
            if (record?.responseStatus === 'FAILURE') {
              record?.errors?.forEach((error: VaultError) => {
                this.toastrService.error(error.message, error.type);
              });
            }
          });

          this.toastrService.info(
            ` Endpoint updated successfully.`,
            'Info'
          );
          this.serverService.isNotLoading();
        },
        (error: HttpErrorResponse) => {
          this.exceptionService.handleError(error);
          this.toastrService.error(
            `An error occurred while updating endpoint.`,
            'Error'
          );
          this.serverService.isNotLoading();
        }
      );

      this.showExistingRecord = true;
      this.showEditRecord = false;
      this.existingEndpointRecord = endpointRecord;
      this.defineAdditionalFields();
    }
  }

  copyExistingEndpoint(endpoint: ToxExistingEndpoint): any {
    this.copyExisting.emit(endpoint);
  }

  filterAdditionalFieldValues(
    allAdditionalFieldValues: Array<PicklistValue>,
    filterWord: string
  ): Array<PicklistValue> {
    return allAdditionalFieldValues.filter(
      (item) => item.picklist === filterWord
    );
  }

  setFormFields(): void {
    this.selectFormGroup.get('category__c').setValue(this.existingEndpointRecord.category__c);
    this.selectFormGroup.get('substanceType').setValue(this.existingEndpointRecord.substance_type__c);
    this.selectFormGroup.get('relatedSubstance').setValue(this.existingEndpointRecord.related_substance__c);
    this.selectFormGroup.get('studyType').setValue(this.existingEndpointRecord.study_type__c);
    this.selectFormGroup.get('route_of_administration__c').setValue(this.existingEndpointRecord.route_of_administration__c);
    this.selectFormGroup.get('test_system_name__c').setValue(this.existingEndpointRecord.test_system_name__c);
    this.selectFormGroup.get('endpoint').setValue(this.existingEndpointRecord.endpoint__c);
    this.selectFormGroup.get('endpointUnit').setValue(this.existingEndpointRecord.endpoint_unit__c);
    this.selectFormGroup.get('operator').setValue(this.existingEndpointRecord.operator__c);
    this.selectFormGroup.get('value1').setValue(this.existingEndpointRecord.value_1__c);
    this.selectFormGroup.get('value2').setValue(this.existingEndpointRecord.value_2__c);
    this.selectFormGroup.get('value3').setValue(this.existingEndpointRecord.value_3__c);
    // additional fields
    this.selectFormGroup.get('test_method_skin_sensitization__c').setValue(this.existingEndpointRecord.test_method_skin_sensitization__c);
    this.selectFormGroup.get('ec3__c').setValue(this.existingEndpointRecord.ec3__c);
    this.selectFormGroup.get('ec15__c').setValue(this.existingEndpointRecord.ec15__c);
    if (this.existingEndpointRecord.target_organ__c) {
      this.selectFormGroup.get('target_organ__c').setValue(this.existingEndpointRecord.target_organ__c.split(','));
    } else {
      this.selectFormGroup.get('target_organ__c').setValue('');
    }
    this.selectFormGroup.get('noael_carcinogenicity_mgkg_bwd__c').setValue(this.existingEndpointRecord.noael_carcinogenicity_mgkg_bwd__c);
    this.selectFormGroup.get('loael_carcinogenicity_mgkg_bwd__c').setValue(this.existingEndpointRecord.loael_carcinogenicity_mgkg_bwd__c);
    if (this.existingEndpointRecord.tumor_site_male__c) {
      this.selectFormGroup.get('tumor_site_male__c').setValue(this.existingEndpointRecord.tumor_site_male__c.split(','));
    } else {
      this.selectFormGroup.get('tumor_site_male__c').setValue('');
    }
    if (this.existingEndpointRecord.tumor_site_female__c) {
      this.selectFormGroup.get('tumor_site_female__c').setValue(this.existingEndpointRecord.tumor_site_female__c.split(','));
    } else {
      this.selectFormGroup.get('tumor_site_female__c').setValue('');
    }
    this.selectFormGroup.get('noael_fertility_mgkg_bwd__c').setValue(this.existingEndpointRecord.noael_fertility_mgkg_bwd__c);
    this.selectFormGroup.get('loael_fertility_mgkg_bwd__c').setValue(this.existingEndpointRecord.loael_fertility_mgkg_bwd__c);
    this.selectFormGroup.get('noael_offspring_mgkg_bwd__c').setValue(this.existingEndpointRecord.noael_offspring_mgkg_bwd__c);
    this.selectFormGroup.get('loael_offspring_mgkg_bwd__c').setValue(this.existingEndpointRecord.loael_offspring_mgkg_bwd__c);
    this.selectFormGroup.get('noael_maternal_mgkg_bwd__c').setValue(this.existingEndpointRecord.noael_maternal_mgkg_bwd__c);
    this.selectFormGroup.get('loael_maternal_mgkg_bwd__c').setValue(this.existingEndpointRecord.loael_maternal_mgkg_bwd__c);
    this.selectFormGroup.get('noael_developmental_mgkg_bwd__c').setValue(this.existingEndpointRecord.noael_developmental_mgkg_bwd__c);
    this.selectFormGroup.get('loael_developmental_mgkg_bwd__c').setValue(this.existingEndpointRecord.loael_developmental_mgkg_bwd__c);
    // eslint-disable-next-line max-len
    if (this.existingEndpointRecord.eats_and_developmental_parameters_affect__c) {
      this.selectFormGroup.get('eats_and_developmental_parameters_affect__c').setValue(this.existingEndpointRecord.eats_and_developmental_parameters_affect__c.split(','));
    } else {
      this.selectFormGroup.get('eats_and_developmental_parameters_affect__c').setValue('');
    }
    this.selectFormGroup.get('ed_mechanistic_study__c').setValue(this.existingEndpointRecord.ed_mechanistic_study__c);    // default
    this.selectFormGroup.get('source').setValue(this.existingEndpointRecord.document_unbound__c);
  }

}


