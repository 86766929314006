<div class="flex-container">
  <div class="footer-container">
    <p>Copyright © BASF SE 2022</p>
    <p><a href="mailto:aida.schmid@basf.com">Aida Schmid</a></p>
    <p><a href="mailto:Pelican-PRS-support@basf.com">Technical Contact</a></p>
    <p><a href="https://www.basf.com/global/en/legal/data-protection.html" target="_blank">Privacy policy</a></p>
    <p><a href="https://www.basf.com/global/en/legal/disclaimer.html" target="_blank">Disclaimer</a></p>
    <p>Version | {{appVersion}}</p>
  </div>
</div>
