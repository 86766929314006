<div [ngStyle]="{'z-index':this.zindex}">
  <div [ngSwitch]="categoryName">

    <div *ngSwitchCase="'Metabolism'" class="recordsContainer">
      <app-metabolism-existing
        (copyExisting)="this.copyEndpointInput(this.endpointRecord)"
        [arrayedEndpointRecord]="endpointRecord"
        [category]="category"
        [categoryPicklists]="categoryPicklists"
        [substanceTypes]="substanceTypes"
        [relatedSubstances]="relatedSubstances"
        [allStudyTypes]="categoryStudyTypes"
        [allTestSystemTypes]="categoryTestSystemTypes"
        [allTestSystemNames]="categoryTestSystemNames"
        [allAdditionalFields]="categoryAdditionalFields"
        [allAdditionalFieldValues]="allAdditionalFieldValues"
        [singleRecordDependencies]="singleRecordDependencies"
        [identifiedMetabolites]="identifiedMetabolites"
        [documents]="documents"
        [allUsers]="allUsers"
      >
      </app-metabolism-existing>
    </div>

    <div *ngSwitchCase="'Nature of Residues'" class="recordsContainer">
      <app-nature-of-residues-existing
        (copyExisting)="this.copyEndpointInput(this.endpointRecord)"
        [arrayedEndpointRecord]="endpointRecord"
        [category]="category"
        [categoryPicklists]="categoryPicklists"
        [relatedSubstances]="relatedSubstances"
        [allStudyTypes]="categoryStudyTypes"
        [allTestSystemTypes]="categoryTestSystemTypes"
        [allTestSystemNames]="categoryTestSystemNames"
        [allAdditionalFields]="categoryAdditionalFields"
        [allAdditionalFieldValues]="allAdditionalFieldValues"
        [singleRecordDependencies]="singleRecordDependencies"
        [identifiedMetabolites]="identifiedMetabolites"
        [documents]="documents"
        [allUsers]="allUsers"
      >
      </app-nature-of-residues-existing>
    </div>

    <div *ngSwitchCase="'Residue Definition'">
      <app-residue-definition-existing
        (copyExisting)="this.copyEndpointInput(this.endpointRecord)"
        [arrayedEndpointRecord]="endpointRecord"
        [category]="category"
        [substanceTypes]="substanceTypes"
        [relatedSubstances]="relatedSubstances"
        [allStudyTypes]="categoryStudyTypes"
        [allTestSystemTypes]="categoryTestSystemTypes"
        [allTestSystemNames]="categoryTestSystemNames"
        [allAdditionalFields]="categoryAdditionalFields"
        [allAdditionalFieldValues]="allAdditionalFieldValues"
        [documents]="documents"
        [allUsers]="allUsers"
      >
      </app-residue-definition-existing>
    </div>

    <div *ngSwitchCase="'Stability of Residues'">
      <app-stability-of-residue-existing
        (copyExisting)="this.copyEndpointInput(this.endpointRecord)"
        [arrayedEndpointRecord]="endpointRecord"
        [category]="category"
        [categoryPicklists]="categoryPicklists"
        [substanceTypes]="substanceTypes"
        [relatedSubstances]="relatedSubstances"
        [allStudyTypes]="categoryStudyTypes"
        [allTestSystemTypes]="categoryTestSystemTypes"
        [allTestSystemNames]="categoryTestSystemNames"
        [allAdditionalFields]="categoryAdditionalFields"
        [allAdditionalFieldValues]="allAdditionalFieldValues"
        [singleRecordDependencies]="singleRecordDependencies"
        [documents]="documents"
        [allUsers]="allUsers"
        >
      </app-stability-of-residue-existing>
    </div>

    <div *ngSwitchCase="'Magnitude of Residue'">
      <app-magnitude-of-residue-existing
        (copyExisting)="this.copyEndpointInput(this.endpointRecord)"
        [arrayedEndpointRecord]="endpointRecord"
        [category]="category"
        [categoryPicklists]="categoryPicklists"
        [substanceTypes]="substanceTypes"
        [relatedSubstances]="relatedSubstances"
        [allStudyTypes]="categoryStudyTypes"
        [allTestSystemTypes]="categoryTestSystemTypes"
        [allTestSystemNames]="categoryTestSystemNames"
        [allAdditionalFields]="categoryAdditionalFields"
        [allAdditionalFieldValues]="allAdditionalFieldValues"
        [singleRecordDependencies]="singleRecordDependencies"
        [analyteNames]="analyteNames"
        [substanceAsAnalyteNames]="substanceAsAnalyteNames"
        [documents]="documents"
        [allUsers]="allUsers"
      >
      </app-magnitude-of-residue-existing>
    </div>

    <div *ngSwitchCase="'Dietary Risk Assessment'">
      <app-dietary-risk-assessment-existing
        (copyExisting)="this.copyEndpointInput(this.endpointRecord)"
        [arrayedEndpointRecord]="endpointRecord"
        [category]="category"
        [categoryPicklists]="categoryPicklists"
        [substanceTypes]="substanceTypes"
        [relatedSubstances]="relatedSubstances"
        [allStudyTypes]="categoryStudyTypes"
        [allTestSystemTypes]="categoryTestSystemTypes"
        [allTestSystemNames]="categoryTestSystemNames"
        [allAdditionalFields]="categoryAdditionalFields"
        [allAdditionalFieldValues]="allAdditionalFieldValues"
        [singleRecordDependencies]="singleRecordDependencies"
        [singleRecordDependenciesModelAdditionalFieldValues]="singleRecordDependenciesModelAdditionalFieldValues"
        [documents]="documents"
        [allUsers]="allUsers"
      >
      </app-dietary-risk-assessment-existing>
    </div>

    <div *ngSwitchCase="'Waiver & Data Gaps'">
      <app-waiver-and-data-gaps-existing
        (copyExisting)="this.copyEndpointInput(this.endpointRecord)"
        [arrayedEndpointRecord]="endpointRecord"
        [category]="category"
        [substanceTypes]="substanceTypes"
        [relatedSubstances]="relatedSubstances"
        [allStudyTypes]="categoryStudyTypes"
        [documents]="documents"
        [allUsers]="allUsers"
      >
      </app-waiver-and-data-gaps-existing>
    </div>
  </div>

  <!-- /////////////////////////////////////////////////////////// -->

  <div class="copiedRecords">
    <div [ngSwitch]="categoryName">
      <div *ngSwitchCase="'Metabolism'">
        <app-metabolism
          (copyInput)="copyEndpointInput($event)"
          (removeInput)="removeEndpointInput($event)"
          *ngFor="let endpoint of copiedEndpointsArrayList"
          [singleEndpointRecord]="endpoint"
          [category]="category"
          [categoryPicklists]="categoryPicklists"
          [substanceTypes]="substanceTypes"
          [relatedSubstances]="relatedSubstances"
          [allStudyTypes]="categoryStudyTypes"
          [allTestSystemTypes]="allTestSystemTypes"
          [allTestSystemNames]="allTestSystemNames"
          [allAdditionalFields]="categoryAdditionalFields"
          [allAdditionalFieldValues]="allAdditionalFieldValues"
          [singleRecordDependencies]="singleRecordDependencies"
          [identifiedMetabolites]="identifiedMetabolites"
          [documents]="documents"
        >
        </app-metabolism>
      </div>

      <div *ngSwitchCase="'Nature of Residues'">
        <app-nature-of-residues
          (copyInput)="copyEndpointInput($event)"
          (removeInput)="removeEndpointInput($event)"
          *ngFor="let endpoint of copiedEndpointsArrayList"
          [singleEndpointRecord]="endpoint"
          [category]="category"
          [categoryPicklists]="categoryPicklists"
          [relatedSubstances]="relatedSubstances"
          [allStudyTypes]="categoryStudyTypes"
          [allTestSystemTypes]="allTestSystemTypes"
          [allTestSystemNames]="allTestSystemNames"
          [allAdditionalFields]="categoryAdditionalFields"
          [allAdditionalFieldValues]="allAdditionalFieldValues"
          [singleRecordDependencies]="singleRecordDependencies"
          [identifiedMetabolites]="identifiedMetabolites"
          [documents]="documents"
        >
        </app-nature-of-residues>
      </div>

      <div *ngSwitchCase="'Residue Definition'">
        <app-residue-definition
          (copyInput)="copyEndpointInput($event)"
          (removeInput)="removeEndpointInput($event)"
          *ngFor="let endpoint of copiedEndpointsArrayList"
          [singleEndpointRecord]="endpoint"
          [category]="category"
          [substanceTypes]="substanceTypes"
          [relatedSubstances]="relatedSubstances"
          [allStudyTypes]="allStudyTypes"
          [allTestSystemTypes]="allTestSystemTypes"
          [allTestSystemNames]="allTestSystemNames"
          [allAdditionalFields]="categoryAdditionalFields"
          [allAdditionalFieldValues]="allAdditionalFieldValues"
          [documents]="documents"
        >
        </app-residue-definition>
      </div>

      <div *ngSwitchCase="'Stability of Residues'">
        <app-stability-of-residues
          (copyInput)="copyEndpointInput($event)"
          (removeInput)="removeEndpointInput($event)"
          *ngFor="let endpoint of copiedEndpointsArrayList"
          [singleEndpointRecord]="endpoint"
          [category]="category"
          [categoryPicklists]="categoryPicklists"
          [substanceTypes]="substanceTypes"
          [relatedSubstances]="relatedSubstances"
          [allStudyTypes]="categoryStudyTypes"
          [allTestSystemTypes]="allTestSystemTypes"
          [allTestSystemNames]="allTestSystemNames"
          [allAdditionalFields]="categoryAdditionalFields"
          [allAdditionalFieldValues]="allAdditionalFieldValues"
          [singleRecordDependencies]="singleRecordDependencies"
          [documents]="documents"
        >
        </app-stability-of-residues>
      </div>

      <div *ngSwitchCase="'Magnitude of Residue'">
        <app-magnitude-of-residue
          (copyInput)="copyEndpointInput($event)"
          (removeInput)="removeEndpointInput($event)"
          *ngFor="let endpoint of copiedEndpointsArrayList"
          [singleEndpointRecord]="endpoint"
          [category]="category"
          [substanceTypes]="substanceTypes"
          [categoryPicklists]="categoryPicklists"
          [relatedSubstances]="relatedSubstances"
          [allStudyTypes]="categoryStudyTypes"
          [allTestSystemTypes]="allTestSystemTypes"
          [allTestSystemNames]="allTestSystemNames"
          [allAdditionalFields]="categoryAdditionalFields"
          [allAdditionalFieldValues]="allAdditionalFieldValues"
          [singleRecordDependencies]="singleRecordDependencies"
          [analyteNames]="analyteNames"
          [substanceAsAnalyteNames]="substanceAsAnalyteNames"
          [documents]="documents"
        >
        </app-magnitude-of-residue>
      </div>

      <div *ngSwitchCase="'Dietary Risk Assessment'">
        <app-dietary-risk-assessment
          (copyInput)="copyEndpointInput($event)"
          (removeInput)="removeEndpointInput($event)"
          *ngFor="let endpoint of copiedEndpointsArrayList"
          [singleEndpointRecord]="endpoint"
          [category]="category"
          [substanceTypes]="substanceTypes"
          [categoryPicklists]="categoryPicklists"
          [relatedSubstances]="relatedSubstances"
          [allStudyTypes]="categoryStudyTypes"
          [allTestSystemTypes]="allTestSystemTypes"
          [allTestSystemNames]="allTestSystemNames"
          [allAdditionalFields]="categoryAdditionalFields"
          [allAdditionalFieldValues]="allAdditionalFieldValues"
          [singleRecordDependencies]="singleRecordDependencies"
          [documents]="documents"
        >
        </app-dietary-risk-assessment>
      </div>

      <div *ngSwitchCase="'Waiver & Data Gaps'">
        <app-waiver-and-data-gaps
          (copyInput)="copyEndpointInput($event)"
          (removeInput)="removeEndpointInput($event)"
          *ngFor="let endpoint of copiedEndpointsArrayList"
          [singleEndpointRecord]="endpoint"
          [category]="category"
          [substanceTypes]="substanceTypes"
          [categoryPicklists]="categoryPicklists"
          [relatedSubstances]="relatedSubstances"
          [allStudyTypes]="categoryStudyTypes"
          [documents]="documents"
          ></app-waiver-and-data-gaps>
      </div>

    </div>

    <div *ngIf="showErrorMessage" class="errorMessageHolder">
      <p>Displayed Endpoints could not be saved due to the following errors:</p>
      <div *ngFor="let message of this.errorMessagesToUser">
        <p>{{message}}</p>
      </div>
    </div>
    <div *ngIf="showSuccessMessage" class="successMessageHolder">
      <p class="marginLeft3">{{successMessagesToUser}}</p>
      <div class="buttonHolder" (click)="openDialog()" >
        <button *ngIf="showSuccessMessage" class="buttonsRefresh">
          <span class="material-icons">refresh</span>
        </button>
        Refresh Endpoint List
      </div>
    </div>

    <button id="{{this.zindex}}" *ngIf="saveButtonShowing" class="buttons saveButton" [class.spinner]="loading" [disabled]="disabled"
            (click)="saveCopies();"
            mat-raised-button type="button">
      <strong>&#10003; </strong>Save Endpoints
    </button>
  </div>

  <div class="orangeLine" *ngIf="saveButtonShowing"></div>
</div>
