<div [ngStyle]="{'z-index':this.zindex}">

  <div class="recordsContainer">
    <app-efate-all-categories-existing
      (copyExisting)="this.copyEndpointInput($event)"
      [arrayedEndpointRecord]="this.endpointRecord"
      [category]="category"
      [substanceTypes]="substanceTypes"
      [relatedSubstances]="relatedSubstances"
      [categoryPicklists]="categoryPicklists"
      [allStudyTypes]="uniqueStudyTypes"
      [allEndpoints]="categoryEndpoints"
      [allEndpointUnits]="categoryEndpointUnits"
      [allOperators]="allOperators"
      [allAdditionalFields]="allAdditionalFields"
      [allAdditionalFieldValues]="allAdditionalFieldValues"
      [documents]="documents"
      [allUsers]="allUsers"
    >
    </app-efate-all-categories-existing>
  </div>

  <div class="copiedRecords">

    <app-e-fate-record
      (copyInput)="copyEndpointInput($event)"
      (removeInput)="removeEndpointInput($event)"
      *ngFor="let endpoint of copiedEndpointsArrayList"
      [singleEndpointRecord]="endpoint"
      [category]="category"
      [allAdditionalFieldValues]="allAdditionalFieldValues"
      [preAllStudyTypes]="uniqueStudyTypes"
      [categoryPicklists]="categoryPicklists"
      [substanceTypes]="substanceTypes"
      [relatedSubstances]="relatedSubstances"
      [allEndpoints]="categoryEndpoints"
      [allEndpointUnits]="categoryEndpointUnits"
      [operators]="allOperators"
      [allAdditionalFields]="allAdditionalFields"
      [documents]="documents"
    >
    </app-e-fate-record>

    <div *ngIf="showErrorMessage" class="errorMessageHolder">
      <p>Displayed Endpoints could not be saved due to the following errors:</p>
      <div *ngFor="let message of this.errorMessagesToUser">
        <p>{{message}}</p>
      </div>
    </div>
    <div *ngIf="showSuccessMessage" class="successMessageHolder">
      <p class="marginLeft3">{{successMessagesToUser}}</p>
      <div class="buttonHolder" (click)="openDialog()" >
        <button *ngIf="showSuccessMessage" class="buttonsRefresh">
          <span class="material-icons">refresh</span>
        </button>
        Refresh Endpoint List
      </div>
    </div>

    <button id="{{this.zindex}}" *ngIf="saveButtonShowing" class="buttons saveButton" [class.spinner]="loading" [disabled]="disabled"
            (click)="saveCopies();"
            mat-raised-button type="button">
      <strong>&#10003; </strong>Save Endpoints
    </button>
  </div>

  <div class="orangeLine" *ngIf="saveButtonShowing"></div>
</div>
