import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ConsumerSafetyEndpointTableItem} from '../../models/consumer-safety-endpoint-table-item';
import {PicklistValue} from '../../../models/picklist-value';
import {ConsumerSafetyCategoryPicklists} from '../../models/consumer-safety-category-picklists';
import {SubstanceItem} from '../../../models/substance-item';
import {RelatedSubstances} from '../../../models/related-substances';
import {DocumentMenuItem} from '../../../models/document-menu-item';
import {ConsumerSafetyAdditionalFields} from '../../models/consumer-safety-additional-fields';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {ExceptionService} from '../../../../core/exception.service';
import {ServerService} from '../../../../core/server.service';
import {SingleRecordDependencies} from "../../models/single-record-dependencies";
import {MatDialog} from '@angular/material/dialog';

@Component({
  selector: 'app-dietary-risk-assessment',
  templateUrl: './dietary-risk-assessment.component.html',
  styleUrls: ['../../../record_style.css']
})
export class DietaryRiskAssessmentComponent implements OnInit {
  @Input() singleEndpointRecord: ConsumerSafetyEndpointTableItem;
  @Input() category: PicklistValue;
  @Input() categoryPicklists: Array<ConsumerSafetyCategoryPicklists>;
  @Input() allStudyTypes: Array<PicklistValue>;
  @Input() allTestSystemTypes: Array<PicklistValue>;
  @Input() allTestSystemNames: Array<PicklistValue>;
  @Input() substance: SubstanceItem;
  @Input() substanceTypes: Array<PicklistValue>;
  @Input() relatedSubstances: Array<RelatedSubstances>;
  @Input() documents: Array<DocumentMenuItem>;
  @Input() allAdditionalFields: Array<ConsumerSafetyAdditionalFields>;
  @Input() allAdditionalFieldValues: Array<ConsumerSafetyAdditionalFields>;
  @Input() singleRecordDependencies: Array<SingleRecordDependencies>;
  @Input() purpose: string;

  @Output() removeInput: EventEmitter<ConsumerSafetyEndpointTableItem> = new EventEmitter();
  @Output() copyInput: EventEmitter<ConsumerSafetyEndpointTableItem> = new EventEmitter();
  @Output() cancelEdit: EventEmitter<ConsumerSafetyEndpointTableItem> = new EventEmitter();
  @Output() saveChanges: EventEmitter<ConsumerSafetyEndpointTableItem> = new EventEmitter();

  subscriptions: any;
  selectFormGroup: FormGroup;
  configureFormGroup: FormGroup;
  public studyTypes: Array<PicklistValue>;
  public categoryTestSystemTypes: Array<PicklistValue>;
  public testSystemTypes: Array<PicklistValue>;
  public testSystemNames: Array<PicklistValue>;
  public filteredRelatedSubstances: Array<RelatedSubstances>;
  public additionalFields: Array<ConsumerSafetyAdditionalFields>;
  public driverPopulations: Array<ConsumerSafetyAdditionalFields>;
  public oldStudyTypeGroup: string;
  private sessionId: string;
  public testSystemNameSingleField: boolean;
  public showCopyOptions: boolean;
  public showEditOptions: boolean;
  public dialogTitle: string;
  public dialogRow1: string;
  public dialogRow2: string;

  constructor(private route: ActivatedRoute,
              private exceptionService: ExceptionService,
              private serverService: ServerService,
              private toastrService: ToastrService,
              private formBuilder: FormBuilder,
              public dialog: MatDialog
  ) {
    this.subscriptions = {};
    this.route.paramMap.subscribe((params) => {
      this.sessionId = params.get('sessionId');
    });
    this.dialogTitle = 'Add New Test System Type';
    this.dialogRow1 = "Test template for adding new data entries to Endpoints.";
    this.dialogRow2 = "";


    this.selectFormGroup = this.formBuilder.group({
      substanceType: [],
      relatedSubstance: [{value: '', disabled: true}],
      studyType: [{value: '', disabled: false}, Validators.required],
      testSystemTypeSingleSelect: [{value: '', disabled: false}],
      testSystemNameSingleSelect: [{value: '', disabled: false}],
      // additional (depended) fields
      comment__c: [{value: '', disabled: false}],
      driver_crop__c: [{value: '', disabled: false}],
      driver_population__c: [{value: '', disabled: false}],
      factors_included_in_the_calculations__c: [{value: '', disabled: false}],
      included_uses__c: [{value: '', disabled: false}],
      ra_relevant__c: [{value: '', disabled: false}],
      refinement_level__c: [{value: '', disabled: false}],
      risk_assessment_status__c: [{value: '', disabled: false}],
      toxicological_endpoint_utilization__c: [{value: '', disabled: false}],
      unittoxicological_endpoint_utilization__c: [{value: '', disabled: false}],
      // default
      selectedDocument: [{value: '', disabled: false}],
    });
    this.oldStudyTypeGroup = '';
    this.testSystemTypes = [];
    this.testSystemNames = [];
    this.filteredRelatedSubstances = [];
    this.additionalFields = [];
    this.driverPopulations = [];
    this.testSystemNameSingleField = false;
  }

  ngOnInit(): void {
    if (this.purpose === 'editOnExisting') {
      this.showCopyOptions = false;
      this.showEditOptions = true;
    }
    else {
      this.showCopyOptions = true;
      this.showEditOptions = false;
    }

    this.selectFormGroup.get('selectedDocument').setValue(this.singleEndpointRecord.document_unbound__c);
    this.studyTypes = this.allStudyTypes;
    this.studyTypes = this.allStudyTypes.sort((a, b) => a.name > b.name ? 1 : -1);

    this.categoryTestSystemTypes = this.allTestSystemTypes.filter(
      (entry) => entry.id === this.category.id);

    if (this.singleEndpointRecord.study_type_picklist_name__c !== '') {
    // COPY or EDIT record
      console.log('copy or edit: ' + JSON.stringify(this.singleEndpointRecord));
      if (this.singleEndpointRecord.substance_type__c === 'parent__c'
        || this.singleEndpointRecord.substance_type__c === '') {
        this.selectFormGroup.get('relatedSubstance').disable();
      }
      else {
        this.selectFormGroup.get('relatedSubstance').enable();
        this.filteredRelatedSubstances = this.relatedSubstances.filter(
          (f) => f.substance_type__c[0] === this.singleEndpointRecord.substance_type__c
        );
      }

      this.filterTestSystemTypes();
      this.filterTestSystemNames();
      this.filterDriverPopulation();
      this.showGroupAdditionalFields();
      this.setFormFields();
    }
    else {
    // NEW record
      // set default value
      this.selectFormGroup.get('substanceType').setValue('parent__c');
      this.singleEndpointRecord.substance_type__c = 'parent__c';
    }
  }

  getSelectedOptionPicklist(event: any): string {
    return event.target.options[event.target.options.selectedIndex].title;
  }

  onSelectSubstanceType(event: any): any {
    // update object
    this.singleEndpointRecord.substance_type__c = event.target.value;
    // this function is triggered only if value is changed, meaning the group is changed

    this.singleEndpointRecord.related_substance__c = '';
    this.selectFormGroup.get('relatedSubstance').setValue('');

    // parent cannot have Related Substance
    if (event.target.value === 'parent__c') {
      this.selectFormGroup.get('relatedSubstance').disable();
    } else {
      // filter Related Substances by the selected Substance Type
      this.filteredRelatedSubstances = this.relatedSubstances.filter(
        (f) => f.substance_type__c[0] === event.target.value
      );
      this.selectFormGroup.get('relatedSubstance').enable();
    }
  }

  onSelectRelatedSubstance(event: any): any {
    this.singleEndpointRecord.related_substance__c = event.target.value;
  }

  onSelectStudyType(event: any): any {
    this.singleEndpointRecord.study_type__c = event.target.value;
    this.singleEndpointRecord.study_type_picklist_name__c = this.getSelectedOptionPicklist(event);

    if (event.target.value !== '') {
      if (this.oldStudyTypeGroup !== this.getSelectedOptionPicklist(event)) {
        // changing Study Type Group
        this.clearTestSystemType();
        this.clearTestSystemName();
        this.clearAdditionalFields(); // changing the study type group

        this.oldStudyTypeGroup = this.getSelectedOptionPicklist(event);
        this.filterTestSystemTypes();
        this.filterTestSystemNames();
        this.showGroupAdditionalFields();
      }
      /**
       * on every Study Type value change
       */
      // Driver Population is in one-to-one relations with Study Type,
      // therefor needs to be reset every time Study Type value changes
      this.singleEndpointRecord.driver_population__c = '';
      this.selectFormGroup.get('driver_population__c').setValue('');
      this.filterDriverPopulation();
    }
    else {
      // Study Type = ''
      this.singleEndpointRecord.study_type_picklist_name__c = '';
      this.clearTestSystemType();
      this.clearTestSystemName();
      this.clearAdditionalFields();
    }
  }

  filterTestSystemTypes(): any {
    const categoryPicklists = this.categoryPicklists.filter(
      (entry) =>
        entry.studyTypePicklist === this.singleEndpointRecord.study_type_picklist_name__c
    )[0];

    this.testSystemTypes = this.categoryTestSystemTypes.filter(
      (entry) => entry.picklist === categoryPicklists.testSystemTypesPicklist
    ).sort((a, b) => a.name > b.name ? 1 : -1);
  }

  onSelectTestSystemType(event: any): void {
    this.singleEndpointRecord.test_system_type__c = [event.value];
  }

  filterTestSystemNames(): any {
    const categoryPicklists = this.categoryPicklists.filter(
      (entry) =>
        entry.studyTypePicklist === this.singleEndpointRecord.study_type_picklist_name__c
    )[0];

    this.testSystemNames = this.allTestSystemNames.filter(
    (entry) => entry.picklist === categoryPicklists.testSystemNamesPicklist
    ).sort((a, b) => a.name > b.name ? 1 : -1);
  }

  onSelectTestSystemName(event: any): void {
    this.singleEndpointRecord.test_system_name__c = [event.value];
  }

  filterDriverPopulation(): void {
    this.driverPopulations = [];
    if (this.singleEndpointRecord.study_type__c === 'acute_risk_assessment__c' || this.singleEndpointRecord.study_type__c === 'chronic_risk_assessment__c') {
      // filter Driver Population from singleRecordDependencies
        this.singleRecordDependencies.filter((entry) =>
          entry.studyTypeGroup === this.singleEndpointRecord.study_type_picklist_name__c &&
          entry.dependencyFieldValue === this.singleEndpointRecord.study_type__c
        ).forEach((element: SingleRecordDependencies) => {

          const entry: ConsumerSafetyAdditionalFields = {
            categoryId: this.category.id,
            label: element.dependedRecordLabel,
            name: element.dependedRecordValue,
            type: '',
            picklist: '',
            group: '',
            saveTo: '',
            hidden: '',
            sortNumber: null,
            dependency: ''
          };
          this.driverPopulations.push(entry);
        });
      this.driverPopulations = this.driverPopulations.sort((a, b) => a.name > b.name ? 1 : -1);
    }
  }

  updateFieldValue(event: any): any {
    const fieldName = event.title;
    this.singleEndpointRecord[fieldName] = event.value;
  }

  updateMultiPicklistFieldValue(newValue, field: ConsumerSafetyAdditionalFields): void {
    this.singleEndpointRecord[field.name] = newValue.value.toString();
  }

  updateNumberFieldValue(newValue): any {
    const fieldName = newValue.title;

    if (fieldName === 'temp_c__c') {
      this.singleEndpointRecord[fieldName] = newValue.value;
    } else {
      if (newValue.value < 0) {
        this.singleEndpointRecord[fieldName] = '';
        this.selectFormGroup.get(fieldName).setValue('');
      } else {
        this.singleEndpointRecord[fieldName] = newValue.value;
      }
    }
  }

  filterAdditionalFieldValues(
    allAdditionalFieldValues: Array<ConsumerSafetyAdditionalFields>,
    filterWord: string,
  ): Array<ConsumerSafetyAdditionalFields> {
    if (filterWord === 'ghost__c') {
      return this.driverPopulations;
    }
    else {
      return allAdditionalFieldValues.filter(
        (item) => item.picklist === filterWord
      );
    }
  }

  clearAdditionalFields(): void {
    // endpoint values
    this.singleEndpointRecord.comment__c = '';
    this.singleEndpointRecord.driver_crop__c = '';
    this.singleEndpointRecord.driver_population__c = '';
    this.singleEndpointRecord.factors_included_in_the_calculations__c = '';
    this.singleEndpointRecord.included_uses__c = '';
    this.singleEndpointRecord.ra_relevant__c = '';
    this.singleEndpointRecord.refinement_level__c = '';
    this.singleEndpointRecord.risk_assessment_status__c = '';
    this.singleEndpointRecord.toxicological_endpoint_utilization__c = null;
    this.singleEndpointRecord.unittoxicological_endpoint_utilization__c = '';
    // form values
    this.selectFormGroup.get('comment__c').setValue('');
    this.selectFormGroup.get('driver_crop__c').setValue('');
    this.selectFormGroup.get('driver_population__c').setValue('');
    this.selectFormGroup.get('factors_included_in_the_calculations__c').setValue('');
    this.selectFormGroup.get('included_uses__c').setValue('');
    this.selectFormGroup.get('ra_relevant__c').setValue('');
    this.selectFormGroup.get('refinement_level__c').setValue('');
    this.selectFormGroup.get('risk_assessment_status__c').setValue('');
    this.selectFormGroup.get('toxicological_endpoint_utilization__c').setValue('');
    this.selectFormGroup.get('unittoxicological_endpoint_utilization__c').setValue('');

    this.additionalFields = [];
  }

  showGroupAdditionalFields(): void {
    const preAdditionalFields = this.allAdditionalFields.filter(
      (entry) =>
        entry.group === this.singleEndpointRecord.study_type_picklist_name__c
    );
    this.additionalFields = preAdditionalFields.sort((a, b) => a.sortNumber - b.sortNumber);
    this.testSystemNameSingleField = this.singleEndpointRecord.study_type_picklist_name__c === 'study_type_dietary_risk_assessment_2__c';
  }

  setFormFields(): void {
    this.selectFormGroup.get('substanceType').setValue(this.singleEndpointRecord.substance_type__c);
    this.selectFormGroup.get('relatedSubstance').setValue(this.singleEndpointRecord.related_substance__c);
    this.selectFormGroup.get('studyType').setValue(this.singleEndpointRecord.study_type__c);
    if (this.singleEndpointRecord.test_system_type__c) {
      this.selectFormGroup.get('testSystemTypeSingleSelect').setValue(this.singleEndpointRecord.test_system_type__c.toString());
    }
    if (this.singleEndpointRecord.test_system_name__c)
    {
      this.selectFormGroup.get('testSystemNameSingleSelect').setValue(this.singleEndpointRecord.test_system_name__c.toString());
    }
    // additional fields
    this.selectFormGroup.get('comment__c').setValue(this.singleEndpointRecord.comment__c);
    this.selectFormGroup.get('driver_crop__c').setValue(this.singleEndpointRecord.driver_crop__c);
    this.selectFormGroup.get('driver_population__c').setValue(this.singleEndpointRecord.driver_population__c);
    this.selectFormGroup.get('factors_included_in_the_calculations__c').setValue(this.singleEndpointRecord.factors_included_in_the_calculations__c);
    this.selectFormGroup.get('included_uses__c').setValue(this.singleEndpointRecord.included_uses__c);
    this.selectFormGroup.get('ra_relevant__c').setValue(this.singleEndpointRecord.ra_relevant__c);
    this.selectFormGroup.get('refinement_level__c').setValue(this.singleEndpointRecord.refinement_level__c);
    this.selectFormGroup.get('risk_assessment_status__c').setValue(this.singleEndpointRecord.risk_assessment_status__c);
    this.selectFormGroup.get('toxicological_endpoint_utilization__c').setValue(this.singleEndpointRecord.toxicological_endpoint_utilization__c);
    this.selectFormGroup.get('unittoxicological_endpoint_utilization__c').setValue(this.singleEndpointRecord.unittoxicological_endpoint_utilization__c);
    // default
    this.selectFormGroup.get('selectedDocument').setValue(this.singleEndpointRecord.document_unbound__c);
  }

  clearTestSystemType(): void {
    this.testSystemTypes = [];
    this.singleEndpointRecord.test_system_type__c = [];
    this.selectFormGroup.get('testSystemTypeSingleSelect').setValue('');
  }

  clearTestSystemName(): void {
    this.testSystemNames = [];
    this.singleEndpointRecord.test_system_name__c = [];
    this.selectFormGroup.get('testSystemNameSingleSelect').setValue('');
  }

  removeEndpoint(singleEndpointRecord: ConsumerSafetyEndpointTableItem): any {
    this.removeInput.emit(singleEndpointRecord);
  }

  copyEndpoint(singleEndpointRecord: ConsumerSafetyEndpointTableItem): void {

    this.copyInput.emit(singleEndpointRecord);
  }

  cancelEditing(endpointRecord): void {
    this.cancelEdit.emit(endpointRecord);
  }

  saveEndpointChanges(endpointRecord): void {
    this.saveChanges.emit(endpointRecord);
  }
}
