import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {PicklistValue} from '../../../models/picklist-value';
import {ToxEndpointTableItem} from '../../../models/tox-endpoint-table-item';
import {ToxAdditionalField} from '../../../models/tox-additional-field';
import {DocumentMenuItem} from '../../../models/document-menu-item';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute} from '@angular/router';
import {ExceptionService} from '../../../../core/exception.service';
import {ServerService} from '../../../../core/server.service';
import {ToastrService} from 'ngx-toastr';
import {ToxExistingEndpoint} from '../../../models/tox-existing-endpoint';
import {convertArrayToString} from '../../../../shared/convert-array-to-string';
import {CreateObjectResponse, CreateObjectsResponse} from '../../../models/create-objects-response';
import {VaultError} from '../../../../core/models/vault-error';
import {HttpErrorResponse} from '@angular/common/http';
let me: OccupationalRiskAssessmentExistingComponent;

@Component({
  selector: 'app-occupational-risk-assessment-existing',
  templateUrl: './occupational-risk-assessment-existing.component.html',
  styleUrls: ['../../../existing_record.css']
})
export class OccupationalRiskAssessmentExistingComponent implements OnInit {
  @Input() arrayedEndpointRecord: ToxExistingEndpoint;
  @Input() category: any;
  @Input() allStudyTypes: Array<PicklistValue>;
  @Input() allTestSystemTypes: Array<PicklistValue>;
  @Input() allEndpoints: Array<PicklistValue>;
  @Input() allEndpointUnits: Array<PicklistValue>;
  @Input() allOperators: Array<PicklistValue>;
  @Input() allAdditionalFields: Array<ToxAdditionalField>;
  @Input() allAdditionalFieldValues: Array<PicklistValue>;
  @Input() substanceTypes: Array<PicklistValue>;
  @Input() relatedSubstances: any;
  @Input() documents: Array<DocumentMenuItem>;
  @Input() allUsers: any;

  @Output() removeInput: EventEmitter<ToxEndpointTableItem> = new EventEmitter();
  @Output() copyExisting: EventEmitter<any> = new EventEmitter();

  subscriptions: any;
  selectFormGroup: FormGroup;
  configureFormGroup: FormGroup;
  public existingEndpointRecord: any;
  public populations: PicklistValue[];
  public preAdditionalFields: Array<ToxAdditionalField>;
  public additionalFields: Array<ToxAdditionalField>;
  public additionalFieldValues: Map<string, any>;
  public operators: Array<PicklistValue>;
  public isEndpointUnitDisabled: boolean;
  public hiddenAdditionalFields: Array<ToxAdditionalField>;
  public isValue1Disabled: boolean;
  public isValue2Disabled: boolean;
  public userName: any;
  public createdBy: any;
  public modifiedBy: any;
  public formatCreatedDate: string;
  public formatModifiedDate: string;
  private haveErrors: Boolean;
  showExistingRecord = true;
  showEditRecord = false;
  public purposeMessage: string;
  private sessionId: string;

  constructor(
    private route: ActivatedRoute,
    private exceptionService: ExceptionService,
    private serverService: ServerService,
    private toastrService: ToastrService,
    private formBuilder: FormBuilder
  ) {
    me = this;
    this.subscriptions = {};
    this.route.paramMap.subscribe((params) => {
      this.sessionId = params.get('sessionId');
    });
    this.selectFormGroup = this.formBuilder.group({
      category__c: [{disabled: true}],
      substanceType: [],
      relatedSubstance: [{value: '', disabled: true}],
      studyType: [{value: '', disabled: false}, Validators.required],
      test_system_type__c: [{value: '', disabled: false}],
      population__c: [{value: '', disabled: false}],
      endpoint: [{value: '', disabled: false}, Validators.required],
      endpointUnit: [{value: '', disabled: false}, Validators.required],
      operator: ['', Validators.required],
      value1: [{value: '', disabled: false}],
      value2: [{value: '', disabled: false}],
      // additional fields
      ppe_required__c: [{value: '', disabled: false}],
      application_method__c: [{value: '', disabled: false}],
      crops__c: [{value: '', disabled: false}],
      // default
      source: [{value: '', disabled: false}],
    });
    this.operators = [];
    this.populations = [
      {name__v:"",id:"",name:"operator__c",label:"Operator",picklist:"population__c"},
      {name__v:"",id:"",name:"reentry_worker__c",label:"Re-entry worker",picklist:"population__c"},
      {name__v:"",id:"",name:"resident__c",label:"Resident",picklist:"population__c"},
      {name__v:"",id:"",name:"bystander__c",label:"Bystander",picklist:"population__c"}
    ];
    this.preAdditionalFields = [];
    this.additionalFields = [];
    this.additionalFieldValues = new Map();
    this.isEndpointUnitDisabled = false;
    this.isValue1Disabled = true;
    this.isValue2Disabled = true;
    this.purposeMessage = 'editOnExisting';
  }

  ngOnInit(): void {
    this.selectFormGroup.disable();

    // convert record values to string values
    this.existingEndpointRecord = JSON.parse(
      JSON.stringify(this.arrayedEndpointRecord)
    );

    convertArrayToString(
      this.arrayedEndpointRecord,
      this.existingEndpointRecord,
      []
    );

    if (this.existingEndpointRecord.created_by__v === 1) {

      this.createdBy = [{name: 'System'}];
    } else {
      this.createdBy = this.allUsers.filter(
        (c) => c.id === this.existingEndpointRecord.created_by__v
      );
    }

    if (this.existingEndpointRecord.modified_by__v === 1) {
      // eslint-disable-next-line quote-props
      this.modifiedBy = [{name: 'System'}];
    } else {
      this.modifiedBy = this.allUsers.filter(
        (m) => m.id === this.existingEndpointRecord.modified_by__v
      );
    }

    this.formatCreatedDate =
      this.existingEndpointRecord.created_date__v.substring(0, 10) +
      ', ' +
      this.existingEndpointRecord.created_date__v.substring(11, 16) +
      'h Z';
    this.formatModifiedDate =
      this.existingEndpointRecord.modified_date__v.substring(0, 10) +
      ', ' +
      this.existingEndpointRecord.modified_date__v.substring(11, 16) +
      'h Z';

    this.selectFormGroup.get('source').setValue(this.existingEndpointRecord.document_unbound__c);

    const filteredOperators = [
      'a1595852199787__c',
      'a1595852189563__c',
      'a1595852194618__c',
      'a1595852268945__c',
      'a1595852266201__c',
      'between__c',
      'a1595852359285__c'
    ];

    filteredOperators.forEach(operator => {
        const singleOperator = this.allOperators.filter(
          (entry) => entry.name === operator
        );
        this.operators = this.operators.concat(singleOperator);
      }
    );

    switch (this.existingEndpointRecord.operator__c) {
      case 'between__c':
        this.isValue1Disabled = false;
        this.isValue2Disabled = false;
        this.selectFormGroup.get('value1').enable();
        this.selectFormGroup.get('value2').enable();
        break;
      case '':
        this.isValue1Disabled = true;
        this.isValue2Disabled = true;
        this.selectFormGroup.get('value1').disable();
        this.selectFormGroup.get('value2').disable();
        this.selectFormGroup.get('value1').setValue(null);
        this.selectFormGroup.get('value2').setValue(null);
        this.existingEndpointRecord.value_1__c = null;
        this.existingEndpointRecord.value_2__c = null;
        break;
      default:
        this.isValue1Disabled = false;
        this.isValue2Disabled = true;
        this.selectFormGroup.get('value1').enable();
        this.selectFormGroup.get('value2').disable();
        this.selectFormGroup.get('value2').setValue(null);
        this.existingEndpointRecord.value_2__c = null;
        break;
    }

    this.defineAdditionalFields();
    this.setFormFields();
  }

  defineAdditionalFields(): void {
    const preAllAdditionalFields = this.allAdditionalFields.filter(
      (entry) =>
        entry.group === this.existingEndpointRecord.study_type_picklist_name__c
    );
    this.additionalFields = preAllAdditionalFields.sort((a, b) => a.sortNumber - b.sortNumber);
  }

  enableEditing(): any {
    this.showExistingRecord = false;
    this.showEditRecord = true;
  }

  cancelEditing(): void {
    this.showExistingRecord = true;
    this.showEditRecord = false;
  }

  saveChanges(endpointRecord): void {
    this.haveErrors = false;

    this.serverService.isLoading();

    delete endpointRecord.created_by__v;
    delete endpointRecord.modified_by__v;
    delete endpointRecord.created_date__v;
    delete endpointRecord.modified_date__v;

    const value1 = parseInt(String(endpointRecord.value_1__c));
    const value2 = parseInt(String(endpointRecord.value_2__c));

    if (endpointRecord.operator__c === 'between__c') {
      if (value2 <= value1 || isNaN(value1) || isNaN(value2)) {
        this.haveErrors = true;
        console.log(value1 + ', ' + value2);
      }
    }

    if (this.haveErrors === true) {
      console.log('saving with errors!');
      this.serverService.isNotLoading();
      this.toastrService.error('Value 2 must be greater than Value 1', 'Values Error');
    }
    else {
      const body = Array(endpointRecord);

      this.serverService.updateEndpoint(this.sessionId, body).subscribe(
        (success: CreateObjectsResponse) => {
          let updated = 0;
          success.data?.forEach((record: CreateObjectResponse) => {
            if (record?.responseStatus === 'SUCCESS') {
              updated++;
            }
            if (record?.responseStatus === 'FAILURE') {
              record?.errors?.forEach((error: VaultError) => {
                this.toastrService.error(error.message, error.type);
              });
            }
          });

          this.toastrService.info(
            ` Endpoint updated successfully.`,
            'Info'
          );
          this.serverService.isNotLoading();
        },
        (error: HttpErrorResponse) => {
          this.exceptionService.handleError(error);
          this.toastrService.error(
            `An error occurred while updating endpoint.`,
            'Error'
          );
          this.serverService.isNotLoading();
        }
      );

      this.showExistingRecord = true;
      this.showEditRecord = false;
      this.existingEndpointRecord = endpointRecord;
      this.defineAdditionalFields();
      this.setFormFields();
    }
  }

  copyExistingEndpoint(endpoint: ToxExistingEndpoint): any {
    this.copyExisting.emit(endpoint);
  }

  filterAdditionalFieldValues(
    allAdditionalFieldValues: Array<PicklistValue>,
    filterWord: string
  ): Array<PicklistValue> {
    return allAdditionalFieldValues.filter(
      (item) => item.picklist === filterWord
    );
  }

  setFormFields(): void {
    this.selectFormGroup.get('category__c').setValue(this.existingEndpointRecord.category__c);
    this.selectFormGroup.get('substanceType').setValue(this.existingEndpointRecord.substance_type__c);
    this.selectFormGroup.get('relatedSubstance').setValue(this.existingEndpointRecord.related_substance__c);
    this.selectFormGroup.get('studyType').setValue(this.existingEndpointRecord.study_type__c);
    this.selectFormGroup.get('test_system_type__c').setValue(this.existingEndpointRecord.test_system_type__c);
    this.selectFormGroup.get('population__c').setValue(this.existingEndpointRecord.population__c);
    this.selectFormGroup.get('endpoint').setValue(this.existingEndpointRecord.endpoint__c);
    this.selectFormGroup.get('endpointUnit').setValue(this.existingEndpointRecord.endpoint_unit__c);
    this.selectFormGroup.get('operator').setValue(this.existingEndpointRecord.operator__c);
    this.selectFormGroup.get('value1').setValue(this.existingEndpointRecord.value_1__c);
    this.selectFormGroup.get('value2').setValue(this.existingEndpointRecord.value_2__c);
    // additional fields
    this.selectFormGroup.get('ppe_required__c').setValue(this.existingEndpointRecord.ppe_required__c);
    this.selectFormGroup.get('application_method__c').setValue(this.existingEndpointRecord.application_method__c);
    if (this.existingEndpointRecord.crops__c) {
      this.selectFormGroup.get('crops__c').setValue(this.existingEndpointRecord.crops__c.split(','));
    } else {
      this.selectFormGroup.get('crops__c').setValue('');
    }
    // default
    this.selectFormGroup.get('source').setValue(this.existingEndpointRecord.document_unbound__c);
  }

}



