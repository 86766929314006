import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {PicklistValue} from '../../models/picklist-value';
import {ToxExistingEndpoint} from '../../models/tox-existing-endpoint';
import {ToxCategoryPicklists} from '../../models/tox-category-picklist';
import {ToxAdditionalField} from '../../models/tox-additional-field';
import {DocumentMenuItem} from '../../models/document-menu-item';
import {ToxEndpointTableItem} from '../../models/tox-endpoint-table-item';
import {convertArrayToString} from '../../../shared/convert-array-to-string';
import {CreateObjectResponse, CreateObjectsResponse} from '../../models/create-objects-response';
import {VaultError} from '../../../core/models/vault-error';
import {HttpErrorResponse} from '@angular/common/http';
import {ServerService} from '../../../core/server.service';
import {ExceptionService} from '../../../core/exception.service';
import {ToastrService} from 'ngx-toastr';
import {MatDialog} from '@angular/material/dialog';
import {MatDialogComponent} from '../../mat-dialog/mat-dialog.component';


@Component({
  selector: 'app-existing-tox-endpoint-record',
  templateUrl: './existing-tox-endpoint-record.component.html',
  styleUrls: ['./existing-tox-endpoint-record.component.css'],
})
export class ExistingToxEndpointRecordComponent implements OnInit {
  @Input() endpointRecord: ToxExistingEndpoint;
  @Input() categories: Array<PicklistValue>;
  @Input() categoryPicklists: Array<ToxCategoryPicklists>;
  @Input() allEndpoints: Array<PicklistValue>;
  @Input() allStudyTypes: Array<PicklistValue>;
  @Input() allTestSystemTypes: Array<PicklistValue>;
  @Input() allRouteOfAdministrations: Array<PicklistValue>;
  @Input() allTestSystemNames: Array<PicklistValue>;
  @Input() allEndpointUnits: Array<PicklistValue>;
  @Input() operators: Array<PicklistValue>;
  @Input() allAdditionalFields: Array<ToxAdditionalField>;
  @Input() allAdditionalFieldValues: any;
  @Input() substanceTypes: Array<PicklistValue>;
  @Input() relatedSubstances: any;
  @Input() documents: Array<DocumentMenuItem>;
  @Input() allUsers: any;
  @Input() dataSourceLength: number;
  @Input() subtractNumber: number;

  @Output() reduceRecordCount: EventEmitter<number> = new EventEmitter();
  @Output() refreshEndpoints: EventEmitter<any> = new EventEmitter();


  public categoryEndpoints: Array<PicklistValue>;
  public categoryStudyTypes: Array<PicklistValue>;
  public categoryEndpointUnits: Array<PicklistValue>;
  public categoryTestSystemTypes: Array<PicklistValue>;
  public categoryTestSystemNames: Array<PicklistValue>;
  public categoryRouteOfAdministrations: Array<PicklistValue>;
  public categoryAdditionalFields: Array<ToxAdditionalField>;

  public saveButtonShowing: boolean;
  public category: any;
  public categoryName: string;
  public zindex: number;
  public copiedEndpointsArrayList: Array<ToxEndpointTableItem>;
  private sessionId: string;
  public startingId: number;
  public maxCategoryId: number;
  public successRecords: any;
  public errorMessagesToUser: any[];
  public successMessagesToUser: string;
  private haveErrors: Boolean;
  loading = false;
  disabled = false;
  showErrorMessage = false;
  showSuccessMessage = false;
  loadingDataHidden: false;
  subscriptions: any;
  public dialogTitle: string;
  public dialogRow1: string;

  constructor(
    private route: ActivatedRoute,
    private serverService: ServerService,
    private exceptionService: ExceptionService,
    private toastrService: ToastrService,
    public dialog: MatDialog
  ) {

    this.zindex = 0;
    this.route.paramMap.subscribe((params) => {
      this.sessionId = params.get('sessionId');
    });
    this.copiedEndpointsArrayList = [];
    this.startingId = 0;
    this.maxCategoryId = 10000000;
    this.saveButtonShowing = false;
    this.successRecords = [];
    this.errorMessagesToUser = [];
    this.successMessagesToUser = '';
    this.dialogTitle = 'Confirm Refresh';
    this.dialogRow1 = 'Please, save all copied Endpoints before refreshing the Existing Endpoints list !';
  }

  ngOnInit(): void {
    this.zindex = this.dataSourceLength - this.subtractNumber;

    this.category = this.categories.find(
      (c) => c.id === this.endpointRecord.category__c);

    this.categoryName = this.category.name__v;

    this.filterStudyTypes();
    this.filterEndpoints();
    this.filterEndpointUnits();
    this.filterTestSystemTypes();
    this.filterTestSystemNames();
    this.filterRouteOfAdministrations();
    this.filterAdditionalFields();
  }

  filterEndpoints(): void {
    this.categoryEndpoints = this.allEndpoints.filter(
      (entry) => entry.id === this.category.id
    );
  }

  filterStudyTypes(): void {
    this.categoryStudyTypes = this.allStudyTypes.filter(
      (entry) => entry.id === this.category.id
    );
  }

  filterEndpointUnits(): void {
    this.categoryEndpointUnits = this.allEndpointUnits.filter(
      (entry) => entry.id === this.category.id
    );
  }

  filterTestSystemTypes(): void {
    this.categoryTestSystemTypes = this.allTestSystemTypes.filter(
      (entry) => entry.id === this.category.id
    );
  }

  filterTestSystemNames(): void {
    this.categoryTestSystemNames = this.allTestSystemNames.filter(
      (entry) => entry.id === this.category.id
    );
  }

  filterRouteOfAdministrations(): void {
    this.categoryRouteOfAdministrations = this.allRouteOfAdministrations.filter(
      (entry) => entry.id === this.category.id
    );
  }

  filterAdditionalFields(): void {
    this.categoryAdditionalFields = this.allAdditionalFields.filter(
      (entry) => entry.categoryId === this.category.id
    );
  }

  addNewEndpoint(existingEndpoint): void {

    // make object
    const newEndpointTemplate: ToxEndpointTableItem = {
      id__c: this.generateId(),
      work_area__c: existingEndpoint.work_area__c,
      category__c: existingEndpoint.category__c,
      substance_name__c: existingEndpoint.substance_name__c,
      substance_type__c: existingEndpoint.substance_type__c ?? 'parent__c',
      related_substance__c: existingEndpoint.related_substance__c ?? '',
      study_type__c: existingEndpoint.study_type__c ?? '',
      industryauthority__c: existingEndpoint.industryauthority__c ?? '',
      source_name__c: existingEndpoint.source_name__c ?? '',
      test_system_type__c: existingEndpoint.test_system_type__c ?? '',
      test_system_name__c: existingEndpoint.test_system_name__c ?? '',
      endpoint__c: existingEndpoint.endpoint__c ?? '',
      endpoint_unit__c: existingEndpoint.endpoint_unit__c ?? '',
      operator__c: existingEndpoint.operator__c ?? '',
      value_1__c: existingEndpoint.value_1__c ?? '',
      value_2__c: existingEndpoint.value_2__c ?? '',
      value_3__c: existingEndpoint.value_3__c ?? '',
      // additional (depended) fields
      study_endpoint__c: existingEndpoint.study_endpoint__c ?? '',
      study__c: existingEndpoint.study__c ?? '',
      crops__c: existingEndpoint.crops__c ?? '',
      application_method__c: existingEndpoint.application_method__c ?? '',
      ppe_required__c: existingEndpoint.ppe_required__c ?? '',
      additional_fqpa_us_epa__c: existingEndpoint.additional_fqpa_us_epa__c ?? '',
      oral_absorption__c: existingEndpoint.oral_absorption__c ?? '',
      species__c: existingEndpoint.species__c ?? '',
      safety_factor__c: existingEndpoint.safety_factor__c ?? '',
      tumor_site_female__c: existingEndpoint.tumor_site_female__c ?? '',
      tumor_site_male__c: existingEndpoint.tumor_site_male__c ?? '',
      loael_carcinogenicity_mgkg_bwd__c: existingEndpoint.loael_carcinogenicity_mgkg_bwd__c ?? '',
      noael_carcinogenicity_mgkg_bwd__c: existingEndpoint.noael_carcinogenicity_mgkg_bwd__c ?? '',
      chromosome_aberration_hamster__c: existingEndpoint.chromosome_aberration_hamster__c ?? '',
      chromosome_aberration_mouse__c: existingEndpoint.chromosome_aberration_mouse__c ?? '',
      chromosome_aberration_rat__c: existingEndpoint.chromosome_aberration_rat__c ?? '',
      uds_in_vivo__c: existingEndpoint.uds_in_vivo__c ?? '',
      micronucleus_test_mouse__c: existingEndpoint.micronucleus_test_mouse__c ?? '',
      micronucleus_test_rat__c: existingEndpoint.micronucleus_test_rat__c ?? '',
      micronucleus_test_in_vitro__c: existingEndpoint.micronucleus_test_in_vitro__c ?? '',
      comet_assay_in_vivo__c: existingEndpoint.comet_assay_in_vivo__c ?? '',
      uds_in_vitro__c: existingEndpoint.uds_in_vitro__c ?? '',
      chromosome_aberration_nonhuman_cells__c: existingEndpoint.chromosome_aberration_nonhuman_cells__c ?? '',
      chromosome_aberration_human_lymphocytes__c: existingEndpoint.chromosome_aberration_human_lymphocytes__c ?? '',
      mouse_lymphoma_assay__c: existingEndpoint.mouse_lymphoma_assay__c ?? '',
      hprt_test__c: existingEndpoint.hprt_test__c ?? '',
      ames_test__c: existingEndpoint.ames_test__c ?? '',
      target_organ__c: existingEndpoint.target_organ__c ?? '',
      ec15__c: existingEndpoint.ec15__c ?? '',
      ec3__c: existingEndpoint.ec3__c ?? '',
      test_method_skin_sensitization__c: existingEndpoint.test_method_skin_sensitization__c ?? '',
      route_of_administration__c: existingEndpoint.route_of_administration__c ?? '',
      transgenic_rodent_assay_in_vitro__c: existingEndpoint.transgenic_rodent_assay_in_vitro__c ?? '',
      comet_assay_in_vitro__c: existingEndpoint.comet_assay_in_vitro__c ?? '',
      transgenic_rodent_assay_in_vivo__c: existingEndpoint.transgenic_rodent_assay_in_vivo__c ?? '',
      ed_mechanistic_study__c: existingEndpoint.ed_mechanistic_study__c ?? '',
      eats_and_developmental_parameters_affect__c: existingEndpoint.eats_and_developmental_parameters_affect__c ?? '',
      mechanistic_studies__c: existingEndpoint.mechanistic_studies__c ?? '',
      mechanism_tmodality__c: existingEndpoint.mechanism_tmodality__c ?? '',
      ed_modality__c: existingEndpoint.ed_modality__c ?? '',
      loael_immunotoxicity_mgkg_bwd__c: existingEndpoint.loael_immunotoxicity_mgkg_bwd__c ?? '',
      noael_immunotoxicity_mgkg_bwd__c: existingEndpoint.noael_immunotoxicity_mgkg_bwd__c ?? '',
      nk_cell_activity_test__c: existingEndpoint.nk_cell_activity_test__c ?? '',
      lymphocyte_subpopulation__c: existingEndpoint.lymphocyte_subpopulation__c ?? '',
      srbc_assay__c: existingEndpoint.srbc_assay__c ?? '',
      loael_neurotoxicity_mgkg_bwd__c: existingEndpoint.loael_neurotoxicity_mgkg_bwd__c ?? '',
      noael_neurotoxicity_mgkg_bwd__c: existingEndpoint.noael_neurotoxicity_mgkg_bwd__c ?? '',
      t_trigger__c: existingEndpoint.t_trigger__c ?? '',
      loael_developmental_mgkg_bwd__c: existingEndpoint.loael_developmental_mgkg_bwd__c ?? '',
      noael_developmental_mgkg_bwd__c: existingEndpoint.noael_developmental_mgkg_bwd__c ?? '',
      loael_maternal_mgkg_bwd__c: existingEndpoint.loael_maternal_mgkg_bwd__c ?? '',
      noael_maternal_mgkg_bwd__c: existingEndpoint.noael_maternal_mgkg_bwd__c ?? '',
      loael_offspring_mgkg_bwd__c: existingEndpoint.loael_offspring_mgkg_bwd__c ?? '',
      noael_offspring_mgkg_bwd__c: existingEndpoint.noael_offspring_mgkg_bwd__c ?? '',
      loael_fertility_mgkg_bwd__c: existingEndpoint.loael_fertility_mgkg_bwd__c ?? '',
      noael_fertility_mgkg_bwd__c: existingEndpoint.noael_fertility_mgkg_bwd__c ?? '',
      loael_parental_mgkg_bwd__c: existingEndpoint.loael_parental_mgkg_bwd__c ?? '',
      noael_parental_mgkg_bwd__c: existingEndpoint.noael_parental_mgkg_bwd__c ?? '',
      qstar_tumor_site__c: existingEndpoint.qstar_tumor_site__c ?? '',
      q_value__c: existingEndpoint.q_value__c ?? '',
      us_cancer_ra__c: existingEndpoint.us_cancer_ra__c ?? '',
      other__c: existingEndpoint.other__c ?? '',
      population__c: existingEndpoint.population__c ?? '',
      // default

      study_type_picklist_name__c: existingEndpoint.study_type_picklist_name__c ?? '', // field used for capturing additional (depended) fields
      document_unbound__c: existingEndpoint.document_unbound__c ?? '',
    };

    this.copiedEndpointsArrayList.push(newEndpointTemplate);
  }

  generateId(): number {
    const newStartingId = this.startingId + 1;
    this.updateStartingId(newStartingId);
    return this.maxCategoryId - newStartingId;
  }

  updateStartingId(newStartingId): void {
    this.startingId = newStartingId;
  }

  copyEndpointInput(receivedEndpoint: any): void {
    this.showSuccessMessage = false;
    this.saveButtonShowing = true;
    // convert record values to string values
    const endpoint = JSON.parse(
      JSON.stringify(receivedEndpoint)
    );

    convertArrayToString(
      receivedEndpoint,
      endpoint,
      []
    );

    this.copiedEndpointsArrayList.filter(it => it.id__c === endpoint.id)
      .forEach(newEndpoint => {
        this.addNewEndpoint(newEndpoint);
      });

    this.addNewEndpoint(endpoint);
  }

  saveCopies(): void {
    this.haveErrors = false;

    this.loading = true;
    this.disabled = true;
    this.serverService.isLoading();

    this.showErrorMessage = false;
    this.successRecords = [];
    this.errorMessagesToUser = [];

    this.copiedEndpointsArrayList.forEach((record) => {
      const value1 = parseInt(String(record.value_1__c));
      const value2 = parseInt(String(record.value_2__c));

        console.log('record to save ' + JSON.stringify(record));

        if (record.operator__c === 'between__c') {
          if (value2 <= value1 || isNaN(value1) || isNaN(value2)) {
            this.haveErrors = true;
            console.log(value1 + ', ' + value2);
          }
        }
      }
    );

    if (this.haveErrors === true) {
      console.log('saving with errors!');
      this.loading = false;
      this.disabled = false;
      this.serverService.isNotLoading();
      this.toastrService.error('Value 2 must be greater than Value 1', 'Values Error');
    }
    else {

      const body = this.copiedEndpointsArrayList;
      this.serverService.postEndpoint(this.sessionId, body).subscribe(
        (success: CreateObjectsResponse) => {
          let created = 0;
          success.data?.forEach((record: CreateObjectResponse) => {
            if (record?.responseStatus === 'SUCCESS') {
              created++;
            }
            if (record?.responseStatus === 'FAILURE') {
              this.showErrorMessage = true;
              record?.errors?.forEach((error: VaultError) => {
                this.toastrService.error(error.message, error.type);
                this.errorMessagesToUser = this.errorMessagesToUser.concat(error.message);
              });
            }
          });
          this.toastrService.info(
            `${created} Endpoint(s) created successfully.`,
            'Info'
          );

          this.loading = false;
          this.disabled = false;
          const response = success.data;

          for (let i = 0; i < this.copiedEndpointsArrayList.length; i++) {
            for (let j = 0; j < response.length; j++) {
              if (i === j) {
                if (response[j].responseStatus === 'SUCCESS') {
                  this.successRecords.push(this.copiedEndpointsArrayList[i].id__c);
                }
              }
            }
          }
          this.clearSuccessRecords();
          console.log('this.successRecords: ' + JSON.stringify(this.successRecords));
        },
        (error: HttpErrorResponse) => {
          this.exceptionService.handleError(error);
          this.toastrService.error(
            `An error occurred while saving endpoints.`,
            'Error'
          );
        },
      );
      this.serverService.isNotLoading();
    }
  }

  clearSuccessRecords(): any {
    for (const recordId of this.successRecords) {
      // define new copiedEndpointArrayList by leaving out success records
      this.copiedEndpointsArrayList = this.copiedEndpointsArrayList.filter(
        obj => obj.id__c !== recordId
      );
    }
    // check if there is any endpoints in the list, if not remove 'Save' button
    if (this.copiedEndpointsArrayList.length === 0) {
      this.saveButtonShowing = false;
    }

    if (this.successRecords.length > 0) {
      this.showSuccessMessage = true;
      this.successMessagesToUser = this.successRecords.length + ' Endpoint/s saved successfully !';
    }
  }

  removeEndpointInput(endpoint: ToxEndpointTableItem): void {
    // remove object from array
    for (let index = 0; index < this.copiedEndpointsArrayList.length; index++) {
      const element: ToxEndpointTableItem = this.copiedEndpointsArrayList[index];
      if (element.id__c === endpoint.id__c) {
        this.copiedEndpointsArrayList.splice(index, 1);
      }
    }
    // check if there is any endpoints in the list, if not remove 'Save' button
    if (this.copiedEndpointsArrayList.length === 0) {
      this.saveButtonShowing = false;
    }
  }

  refreshEndpointList(): void {
    this.refreshEndpoints.emit();
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(MatDialogComponent, {
      width: 'auto',
      data: {dialogTitle: this.dialogTitle, dialogRow1: this.dialogRow1},
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      if (result === 'confirmed') {
        this.refreshEndpointList();
      }
    });
  }
}
