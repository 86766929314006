import {Component, ElementRef, Input, OnChanges, OnInit, ViewChild, } from '@angular/core';
import {PicklistValue} from '../../models/picklist-value';
import {EcotoxAdditionalField} from '../../ecotoxicology/models/ecotox-additional-field';
import {DocumentMenuItem} from '../../models/document-menu-item';
import {SubstanceItem} from '../../models/substance-item';
import {ConsumerSafetyCategoryPicklists} from '../models/consumer-safety-category-picklists';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {ActivatedRoute} from '@angular/router';
import {ServerService} from '../../../core/server.service';
import {ExceptionService} from '../../../core/exception.service';
import {ToastrService} from 'ngx-toastr';
import {HttpErrorResponse} from '@angular/common/http';
import {MatTableDataSource} from '@angular/material/table';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {Observable} from 'rxjs';
import {MatAutocomplete, MatAutocompleteSelectedEvent, } from '@angular/material/autocomplete';
import {ConsumerSafetyExisting} from '../models/consumer-safety-existing';
import {PageEvent} from '@angular/material/paginator';
import {ConsumerSafetyAdditionalFields} from '../models/consumer-safety-additional-fields';
import {ConsumerSafetyExistingHeader} from '../models/consumer-safety-existing-header';
import {map, startWith} from 'rxjs/operators';
import {MatChipInputEvent} from '@angular/material/chips';
import {RelatedSubstances} from '../../models/related-substances';
import {SingleRecordDependencies} from "../models/single-record-dependencies";

@Component({
  selector: 'app-consumer-safety-existing',
  templateUrl: './consumer-safety-existing.component.html',
  styleUrls: ['./consumer-safety-existing.component.css'],
})

export class ConsumerSafetyExistingComponent implements OnInit, OnChanges {
  dataSource: MatTableDataSource<any>;
  data: Array<any>;
  pageSize: number;
  pageSkip: number;
  dataSourceLength: number;
  public loaderHidden: boolean;
  public searchButtonHidden: boolean;

  // for filters
  visible = true;
  selectable = true;
  removable = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  fieldCtrl = new FormControl();
  filteredFields: Observable<string[]>;
  fields: string[] = ['Category', 'Related Substance'];
  public allFields: string[];

  @ViewChild('fieldInput') fieldInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto') matAutocomplete: MatAutocomplete;

  /**
   * for documents filtering
   */
  searchUserModifyControl: FormControl = new FormControl();
  searchUserCreateControl: FormControl = new FormControl();
  filteredCreateUsers: Observable<any>;
  filteredModifiedUsers: Observable<any>;
  /** */

  subscriptions: any;
  public panelOpenState: boolean;
  public expandPanel: boolean;
  public header: ConsumerSafetyExistingHeader;

  @Input() receivedCategoryPicklists: Array<ConsumerSafetyCategoryPicklists>;
  @Input() workAreaName: string;
  @Input() workAreaId: string;
  @Input() substance: SubstanceItem;
  @Input() receivedCategories: Array<PicklistValue>;
  @Input() receivedAllStudyTypes: Array<PicklistValue>;
  @Input() receivedAllTestSystemTypes: Array<PicklistValue>;
  @Input() receivedAllTestSystemNames: Array<PicklistValue>;
  @Input() receivedAllAdditionalFields: Array<ConsumerSafetyAdditionalFields>;
  @Input() receivedAdditionalFieldValues: Array<ConsumerSafetyAdditionalFields>;
  @Input() singleRecordDependencies: Array<SingleRecordDependencies>;
  @Input() identifiedMetabolites:Array<ConsumerSafetyAdditionalFields>;
  @Input() analyteNames: Array<ConsumerSafetyAdditionalFields>;
  @Input() substanceAsAnalyteNames: Array<ConsumerSafetyAdditionalFields>;
  @Input() singleRecordDependenciesModelAdditionalFieldValues: Array<ConsumerSafetyAdditionalFields>;
  @Input() substanceTypes: Array<PicklistValue>;
  @Input() relatedSubstances: Array<RelatedSubstances>;
  @Input() documents: Array<DocumentMenuItem>;
  public existingEndpoints: Array<ConsumerSafetyExisting>;
  public uniqueAdditionalFields: Array<ConsumerSafetyAdditionalFields>;
  public allUsers: any;
  public queryStringConstructor: any;
  public queryStringClause: any;
  public sortByFieldName: string;
  public sortByOrder: string;

  // created date range
  createdRange = new FormGroup({
    createdDateStart: new FormControl(),
    createdDateEnd: new FormControl(),
  });
  // modified date range
  modifiedRange = new FormGroup({
    modifiedDateStart: new FormControl(),
    modifiedDateEnd: new FormControl(),
  });
  pageEvent: PageEvent;
  public uniqueStudyTypes: Array<PicklistValue>;
  public uniqueTestSystemTypes: Array<PicklistValue>;
  public uniqueTestSystemNames: Array<PicklistValue>;
  public uniqueApplicationType: Array<PicklistValue>;
  public uniqueMatrix: Array<PicklistValue>;
  public uniqueDriverPopulation: Array<PicklistValue>;
  public multiSelectPicklist: Array<string>;
  public All: string;
  private sessionId: string;

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private serverService: ServerService,
    private exceptionService: ExceptionService,
    private toastrService: ToastrService
  ) {
    this.route.paramMap.subscribe((params) => {
      this.sessionId = params.get('sessionId');
    });
    this.uniqueAdditionalFields = [];
    this.existingEndpoints = [];
    this.uniqueStudyTypes = [];
    this.uniqueTestSystemTypes = [];
    this.uniqueTestSystemNames = [];
    this.uniqueApplicationType = [];
    this.uniqueDriverPopulation = [];
    this.uniqueMatrix = [];
    this.sortByFieldName = 'id';
    this.sortByOrder = 'DESC';
    this.allUsers = [];
    this.All = 'All';
    this.queryStringConstructor = [];
    this.queryStringClause = '';
    this.dataSource = new MatTableDataSource();
    this.pageSize = 10;
    this.pageSkip = 0;
    this.dataSourceLength = 0;
    this.header = {
      category__c: '',
      substance_type__c: '',
      related_substance__c: '',
      study_type__c: '',
      test_system_type__c: '',
      test_system_name__c: '',
      // additional fields
      application_type__c: '',
      application_rate1__c: null,
      unit_application_rate__c: '',
      replant_interval_days__c: '',
      open_radio_label_topic__c: '',
      open_id_rate_topic__c: '',
      open_isomer_topic__c: '',
      adequate_study__c: '',
      residue_uptake_001_mgkg__c: '',
      plateau_reached_in_milkeggs__c: '',
      time_needed_to_reach_plateau_in_milkegg__c: null,
      parent_only__c: '',
      identified_metabolites__c: '',
      similar_to_metabolism_study__c: '',
      fat_soluble_residues__c: '',
      similar_to_other_crop_groups__c: '',
      similar_to_primary_crops__c: '',
      similar_to_other_species__c: '',
      similar_to_rat__c: '',
      human_unique_or_disproportional_metaboli__c: '',

      maximum_abundancy_in_foodedibles_trr__c: '',
      maximum_abundancy_in_foodedibles_mg__c: '',
      maximum_abundancy_in_feed_trr__c: '',
      maximum_abundancy_in_feed_mgkg__c: '',
      maximum_abundancy_in_inedibles__c: '',
      fat_soluble__c: '',
      tox_species_coverage_10_administred_d__c: '',
      administered_dose__c: null,
      matrix_tox_species__c: '',
      included_in_rd__c: '',

      rd_components__c: '',
      rd_wording__c: '',
      rd_status__c: '',

      stability__c: null,
      unit_stability__c: '',
      similar_to_other_commodities_of_same_cat__c: '',
      similar_to_other_categories__c: '',
      similar_to_other_animalsof_the_same_anim__c: '',
      similar_to_other_animal_commodities__c: '',

      sufficient_data_package__c: '',
      matrix__c: '',
      analyte_type__c: '',
      analyte_name__c: '',
      residue_001_mgkg__c: '',
      worst_case_plateau_covered__c: '',
      replant_restrictions__c: '',
      replant_restriction_days__c: null,
      rotational_mrls_required__c: '',
      processing_factor__c: null,
      dose_level_mgkg_dm_diet__c: null,
      dose_level_mgkg_bwday__c: null,
      residues_in_edible_matrices_001_mgkg__c: '',
      sum_of_analytes_005_mgkg__c: '',

      included_uses__c: '',
      refinement_level__c: '',
      risk_assessment_status__c: '',
      toxicological_endpoint_utilization__c: null,
      unittoxicological_endpoint_utilization__c: '',
      driver_population__c: '',
      driver_crop__c: '',
      factors_included_in_the_calculations__c: '',
      ra_relevant__c: '',

      comment__c: '',
      selectedDocumentId: '',
      created_date_start: null,
      created_date_end: null,
      modified_date_start: null,
      modified_date_end: null,
      created_by__v: '',
      modified_by__v: '',
    };

    /// for filters
    this.allFields = [
      'Category',
      'Related Substance',
      'Substance Type',
      'Created Date Range',
      'Modified Date Range',
      'Source',
      'Created By',
      'Last Modified By',
      'Study Type',
      'Test System Type',
      'Test System Name',

      'Test Item Type',
      'Test Item Name',
      'Application Type',
      'Application Rate', // number
      'Unit (Application Rate)',
      'Replant Interval (days)',
      'Open radio label topic?',
      'Open isomer topic?',
      'Open ID rate topic?',
      'Adequate Study?',
      'Residue uptake (≥0.01 mg/kg)?',
      'Plateau reached in milk/eggs?',
      'Time needed to reach plateau in milk/eggs (days)', // number
      'Parent only?',
      'Identified metabolites',
      'Similar to other crop groups?',
      'Similar to primary crops?',
      'Fat soluble residues?',
      'Similar to other species?',
      'Similar to rat?',
      'Similar to metabolism study?',
      'Human Unique or disproportional metabolite?',

      '(maximum) Abundancy in Food/Edibles [TRR]',
      '(maximum) Abundancy in Food/Edibles [mg/kg]',
      '(maximum) Abundancy in Feed [TRR]',
      '(maximum) Abundancy in Feed [mg/kg]',
      '(maximum) Abundancy in Inedibles?',
      'Fat soluble?',
      'Tox Species Coverage (≥10% administred dose)?',
      '% administered dose', // number
      'Matrix (tox species)',
      'Included in RD?',

      'RD components',
      // 'RD wording', //string
      'RD Status',

      'Stability', // number
      'Unit (Stability)',
      'Similar to other Commodities of same Category?',
      'Similar to other  Categories?',
      'Similar to other Animalsof the same Animal Commodities?',
      'Similar to other Animal Commodities?',

      'Sufficient data package?',
      'Matrix',
      'Analyte Type',
      'Analyte Name',
      'Residue (≥0.01 mg/kg)?',
      'Worst case plateau covered?',
      'Replant restrictions?',
      'Replant restriction (days)', // number
      'Rotational MRLs required?',
      'Processing Factor', // number
      'Dose Level (mg/kg DM diet)', // number
      'Dose Level (mg/kg bw/day)', // number
      'Residues in edible matrices (≥0,01 mg/kg)?',
      'Sum of analytes ≥ 0.05 mg/kg?',

      'Included uses',
      'Refinement Level',
      'Risk Assessment Status',
      'Toxicological endpoint utilization', // number
      'Unit (Toxicological endpoint utilization)',
      'Driver Population',
      'Driver Crop',
      // 'Factors included in the calculations', //string
      'RA relevant?',
    ];

    this.multiSelectPicklist = ['Identified metabolites', 'RD components', 'Analyte Name', 'Test Item Name'];

    this.filteredFields = this.fieldCtrl.valueChanges.pipe(
      startWith(null),
      map((field: string | null) =>
        field ? this._filter(field) : this.allFields.slice()
      )
    );
  }

  ngOnInit(): void {
    this.retrieveAllUsers();
    this.queryStringClause = 'work_area__c = ' + '\'' + this.workAreaId + '\'';
    this.makeUniqueTestSystemNames();

  }

  ngOnChanges(): any {
    this.makeUniqueStudyTypes();
    this.makeUniqueTestSystemTypes();

    this.makeUniqueApplicationType();
    this.makeUniqueMatrix();
    this.makeUniqueDriverPopulation();

    this.makeUniqueAdditionalFields();
  }

  addSingleRecordDependenciesToAllTestSystemNames(): void {
    // filter from Single Record Dependencies
    this.singleRecordDependencies.filter((entry) =>
      entry.dependedFieldLabel[0] === 'test_system_name__c'
    ).forEach((element: SingleRecordDependencies) => {
      const entry: PicklistValue = {
        name__v: '',
        id: element.categoryId,
        name: element.dependedRecordValue,
        label: element.dependedRecordLabel,
        picklist: ''
      };
      this.receivedAllTestSystemNames.push(entry);
    });
  }

  makeUniqueStudyTypes(): any {
    const source = this.receivedAllStudyTypes;
    const result = this.uniqueStudyTypes;

    const studyTypeMap = new Map();
    for (const item of source) {
      if (!studyTypeMap.has(item.name)) {
        studyTypeMap.set(item.name, true);
        result.push({
          name__v: '',
          id: '',
          label: item.label,
          name: item.name,
          picklist: item.picklist,
        });
      }
    }
  }

  makeUniqueTestSystemTypes(): any {
    const source = this.receivedAllTestSystemTypes;
    const result = this.uniqueTestSystemTypes;

    const testSystemTypeMap = new Map();
    for (const item of source) {
      if (!testSystemTypeMap.has(item.name)) {
        testSystemTypeMap.set(item.name, true);
        result.push({
          name__v: '',
          id: '',
          label: item.label,
          name: item.name,
          picklist: item.picklist,
        });
      }
    }
  }

  makeUniqueTestSystemNames(): any {
    const source = this.receivedAllTestSystemNames;
    const result = this.uniqueTestSystemNames;

    const testSystemNameMap = new Map();
    for (const item of source) {
      if (!testSystemNameMap.has(item.name)) {
        testSystemNameMap.set(item.name, true);
        result.push({
          name__v: '',
          id: '',
          label: item.label,
          name: item.name,
          picklist: item.picklist,
        });
      }
    }
    this.addSingleRecordDependenciesToAllTestSystemNames();
  }

  makeUniqueApplicationType(): void {
    const source = this.receivedAdditionalFieldValues.filter(
      (d) => d.saveTo === 'application_type__c'
    );

    const result = this.uniqueApplicationType;

    const applicationTypeMap = new Map();
    for (const item of source) {
      if (!applicationTypeMap.has(item.name)) {
        applicationTypeMap.set(item.name, true);
        result.push({
          name__v: '',
          id: '',
          label: item.label,
          name: item.name,
          picklist: item.picklist,
        });
      }
    }
  }

  makeUniqueMatrix(): void {
    const source = this.receivedAdditionalFieldValues.filter(
      (d) => d.saveTo === 'matrix__c'
    );

    const result = this.uniqueMatrix;

    const matrixMap = new Map();
    for (const item of source) {
      if (!matrixMap.has(item.name)) {
        matrixMap.set(item.name, true);
        result.push({
          name__v: '',
          id: '',
          label: item.label,
          name: item.name,
          picklist: item.picklist,
        });
      }
    }
  }

  makeUniqueDriverPopulation(): void {
    const source = this.receivedAdditionalFieldValues.filter(
      (d) => d.saveTo === 'driver_population__c'
    );

    const result = this.uniqueDriverPopulation;

    const matrixMap = new Map();
    for (const item of source) {
      if (!matrixMap.has(item.name)) {
        matrixMap.set(item.name, true);
        result.push({
          name__v: '',
          id: '',
          label: item.label,
          name: item.name,
          picklist: item.picklist,
        });
      }
    }
  }

  makeUniqueAdditionalFields(): any {
    // remove duplicates
    const sourceArray = this.receivedAllAdditionalFields;
    const resultArray = this.uniqueAdditionalFields;

    const additionalFieldsMap = new Map();
    for (const item of sourceArray) {
      if (!additionalFieldsMap.has(item.saveTo)) {
        additionalFieldsMap.set(item.saveTo, true); // set any value to Map
        const field: ConsumerSafetyAdditionalFields = {
          categoryId: item.categoryId,
          label: item.label,
          name: item.name,
          type: item.type,
          picklist: item.picklist,
          saveTo: item.saveTo,
          group: item.group,
          hidden: item.hidden,
          sortNumber: item.sortNumber,
          dependency: item.dependency
        };
        resultArray.push(field);
      }
    }
  }

  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    // Add field
    if ((value || '').trim()) {
      this.fields.push(value.trim());
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }

    this.fieldCtrl.setValue(null);
  }

  remove(field: string): void {
    // remove filter form
    document
      .getElementById('preparedFilterFields')
      .appendChild(document.getElementById(field));

    // remove chip
    const index = this.fields.indexOf(field);
    if (index >= 0) {
      this.fields.splice(index, 1);
    }

    // remove from array IF something was selected
    const queryIndex = this.queryStringConstructor
      .map((e) => {
        return e.fieldLabel;
      })
      .indexOf(field);
    if (queryIndex >= 0) {
      this.queryStringConstructor.splice(queryIndex, 1);
    }

    // reset form selected value
    if (field === 'Category') {
      this.header.category__c = '';
    } else if (field === 'Substance Type') {
      this.header.substance_type__c = '';
    } else if (field === 'Related Substance') {
      this.header.related_substance__c = '';
    } else if (field === 'Study Type') {
      this.header.study_type__c = '';
    } else if (field === 'Created By') {
      this.header.created_by__v = '';
    } else if (field === 'Last Modified By') {
      this.header.modified_by__v = '';
    } else if (field === 'Source') {
      this.header.selectedDocumentId = null;
    }

    // picklist fields
    else if (field === 'Test System Type') {
      this.header.test_system_type__c = '';
    } else if (field === 'Test System Name') {
      this.header.test_system_name__c = '';
    } else if (field === 'Application Type') {
      this.header.application_type__c = '';
    } else if (field === 'Unit (Application Rate)') {
      this.header.unit_application_rate__c = '';
    } else if (field === 'Replant Interval (days)') {
      // multiselect
      this.header.replant_restriction_days__c = '';
    } else if (field === 'Open radio label topic?') {
      this.header.open_radio_label_topic__c = '';
    } else if (field === 'Open isomer topic?') {
      this.header.open_isomer_topic__c = '';
    } else if (field === 'Open ID rate topic?') {
      this.header.open_id_rate_topic__c = '';
    } else if (field === 'Adequate Study?') {
      this.header.adequate_study__c = '';
    } else if (field === 'Residue uptake (≥0.01 mg/kg)?') {
      this.header.residue_uptake_001_mgkg__c = '';
    } else if (field === 'Plateau reached in milk/eggs?') {
      this.header.plateau_reached_in_milkeggs__c = '';
    } else if (field === 'Parent only?') {
      this.header.parent_only__c = '';
    } else if (field === 'Identified metabolites') {
      // multiselect
      this.header.identified_metabolites__c = '';
    } else if (field === 'Similar to other crop groups?') {
      this.header.similar_to_other_crop_groups__c = '';
    } else if (field === 'Similar to primary crops?') {
      this.header.similar_to_primary_crops__c = '';
    } else if (field === 'Fat soluble residues?') {
      this.header.fat_soluble_residues__c = '';
    } else if (field === 'Similar to other species?') {
      this.header.similar_to_other_species__c = '';
    } else if (field === 'Similar to rat?') {
      this.header.similar_to_rat__c = '';
    } else if (field === 'Similar to metabolism study?') {
      this.header.similar_to_metabolism_study__c = '';
    } else if (field === 'Human Unique or disproportional metabolite?') {
      this.header.human_unique_or_disproportional_metaboli__c = '';
    } else if (field === '(maximum) Abundancy in Food/Edibles [TRR]') {
      this.header.maximum_abundancy_in_foodedibles_trr__c = '';
    } else if (field === '(maximum) Abundancy in Food/Edibles [mg/kg]') {
      this.header.maximum_abundancy_in_foodedibles_mg__c = '';
    } else if (field === '(maximum) Abundancy in Feed [TRR]') {
      this.header.maximum_abundancy_in_feed_trr__c = '';
    } else if (field === '(maximum) Abundancy in Feed [mg/kg]') {
      this.header.maximum_abundancy_in_feed_mgkg__c = '';
    } else if (field === '(maximum) Abundancy in Inedibles?') {
      this.header.maximum_abundancy_in_inedibles__c = '';
    } else if (field === 'Fat soluble?') {
      this.header.fat_soluble__c = '';
    } else if (field === 'Tox Species Coverage (≥10% administred dose)?') {
      this.header.tox_species_coverage_10_administred_d__c = '';
    } else if (field === 'Matrix (tox species)') {
      this.header.matrix_tox_species__c = '';
    } else if (field === 'Included in RD?') {
      this.header.included_in_rd__c = '';
    } else if (field === 'RD components') {
      // multiselect
      this.header.rd_components__c = '';
    } else if (field === 'RD Status') {
      this.header.rd_status__c = '';
    } else if (field === 'Unit (Stability)') {
      this.header.unit_stability__c = '';
    } else if (field === 'Similar to other Commodities of same Category?') {
      this.header.similar_to_other_commodities_of_same_cat__c = '';
    } else if (field === 'Similar to other Categories?') {
      this.header.similar_to_other_categories__c = '';
    } else if (
      field === 'Similar to other Animalsof the same Animal Commodities?'
    ) {
      this.header.similar_to_other_animalsof_the_same_anim__c = '';
    } else if (field === 'Similar to other Animal Commodities?') {
      this.header.similar_to_other_animal_commodities__c = '';
    } else if (field === 'Sufficient data package?') {
      this.header.sufficient_data_package__c = '';
    } else if (field === 'Matrix') {
      this.header.matrix__c = '';
    } else if (field === 'Analyte Type') {
      this.header.analyte_type__c = '';
    } else if (field === 'Analyte Name') {
      this.header.analyte_name__c = '';
    } else if (field === 'Residue (≥0.01 mg/kg)?') {
      this.header.residue_001_mgkg__c = '';
    } else if (field === 'Worst case plateau covered?') {
      this.header.worst_case_plateau_covered__c = '';
    } else if (field === 'Replant restrictions?') {
      this.header.replant_restrictions__c = '';
    } else if (field === 'Rotational MRLs required?') {
      this.header.rotational_mrls_required__c = '';
    } else if (field === 'Residues in edible matrices (≥0,01 mg/kg)?') {
      this.header.residues_in_edible_matrices_001_mgkg__c = '';
    } else if (field === 'Sum of analytes ≥ 0.05 mg/kg?') {
      this.header.sum_of_analytes_005_mgkg__c = '';
    } else if (field === 'Included uses') {
      this.header.included_uses__c = '';
    } else if (field === 'Refinement Level') {
      this.header.refinement_level__c = '';
    } else if (field === 'Risk Assessment Status') {
      this.header.risk_assessment_status__c = '';
    } else if (field === 'Unit (Toxicological endpoint utilization)') {
      this.header.unittoxicological_endpoint_utilization__c = '';
    } else if (field === 'Driver Population') {
      this.header.driver_population__c = '';
    } else if (field === 'Driver Crop') {
      this.header.driver_crop__c = '';
    } else if (field === 'RA relevant?') {
      this.header.ra_relevant__c = '';
    }

    // number fields
    else if (field === 'Application Rate') {
      this.header.application_rate1__c = null;
    } else if (field === 'Time needed to reach plateau in milk/eggs (days)') {
      this.header.time_needed_to_reach_plateau_in_milkegg__c = null;
    } else if (field === '% administered dose') {
      this.header.administered_dose__c = null;
    } else if (field === 'Stability') {
      this.header.stability__c = null;
    } else if (field === 'Replant restriction (days)') {
      this.header.replant_restriction_days__c = null;
    } else if (field === 'Processing Factor') {
      this.header.processing_factor__c = null;
    } else if (field === 'Dose Level (mg/kg DM diet)') {
      this.header.dose_level_mgkg_dm_diet__c = null;
    } else if (field === 'Dose Level (mg/kg bw/day)') {
      this.header.dose_level_mgkg_bwday__c = null;
    } else if (field === 'Toxicological endpoint utilization') {
      this.header.toxicological_endpoint_utilization__c = null;
    } else if (field === 'Created Date Range') {
      this.createdRange.value.createdDateStart = null;
      this.createdRange.value.createdDateEnd = null;
      this.header.created_date_start = null;
      this.header.created_date_end = null;
    } else if (field === 'Modified Date Range') {
      this.modifiedRange.value.modifiedDateStart = null;
      this.modifiedRange.value.modifiedDateEnd = null;
      this.header.modified_date_start = null;
      this.header.modified_date_end = null;
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.fields.push(event.option.viewValue);
    this.fieldInput.nativeElement.value = '';
    this.fieldCtrl.setValue(null);

    document
      .getElementById('filterFields')
      .appendChild(document.getElementById(event.option.viewValue));
  }

  getEndpointsWithFilters(limit, offset): void {
    this.loaderHidden = false;
    this.searchButtonHidden = true;
    this.serverService.isLoading();

    let createdDateStart = JSON.stringify(
      this.createdRange.value.createdDateStart
    );
    createdDateStart = createdDateStart.replace('"', '');
    createdDateStart = createdDateStart.replace('"', '');
    let createdDateEnd = JSON.stringify(this.createdRange.value.createdDateEnd);
    createdDateEnd = createdDateEnd.replace('"', '');
    createdDateEnd = createdDateEnd.replace('"', '');

    let modifiedDateStart = JSON.stringify(
      this.modifiedRange.value.modifiedDateStart
    );
    modifiedDateStart = modifiedDateStart.replace('"', '');
    modifiedDateStart = modifiedDateStart.replace('"', '');
    let modifiedDateEnd = JSON.stringify(
      this.modifiedRange.value.modifiedDateEnd
    );
    modifiedDateEnd = modifiedDateEnd.replace('"', '');
    modifiedDateEnd = modifiedDateEnd.replace('"', '');

    // reset condition clauses
    this.queryStringClause =
      'work_area__c = ' +
      '\'' +
      this.workAreaId +
      '\' AND substance_name__c = ' +
      '\'' +
      this.substance.id +
      '\'';

    // unpack conditions array and construct query
    this.queryStringConstructor.forEach((condition: any) => {
      let singleCondition;
      if (this.multiSelectPicklist.includes(condition.fieldLabel)) {
        singleCondition =
          ' AND ' +
          condition.fieldName +
          ' LIKE ' +
          '\',%' +
          condition.fieldValue +
          '%\'';
      } else {
        singleCondition =
          ' AND ' +
          condition.fieldName +
          ' = ' +
          '\'' +
          condition.fieldValue +
          '\'';
      }

      this.queryStringClause = this.queryStringClause + singleCondition;
    });

    if (createdDateStart !== 'null' && createdDateEnd !== 'null') {
      this.queryStringClause =
        this.queryStringClause +
        ' AND created_date__v BETWEEN \'' +
        createdDateStart +
        '\'' +
        ' AND \'' +
        createdDateEnd +
        '\'';
    }

    if (modifiedDateStart !== 'null' && modifiedDateEnd !== 'null') {
      this.queryStringClause =
        this.queryStringClause +
        ' AND modified_date__v BETWEEN \'' +
        modifiedDateStart +
        '\'' +
        ' AND \'' +
        modifiedDateEnd +
        '\'';
    }

    this.queryStringClause =
      this.queryStringClause +
      ' ORDER BY ' +
      this.sortByFieldName +
      ' ' +
      this.sortByOrder +
      ' LIMIT ' +
      limit +
      ' OFFSET ' +
      offset;
    this.subscriptions = this.serverService
      .filterEndpointRecords(
        this.sessionId,
        this.queryStringClause,
        this.workAreaName
      )
      .subscribe(
        (response) => {
          // reset current list
          this.existingEndpoints = [];
          if (response.data && response.data.length > 0) {
            response.data.forEach((it) => this.existingEndpoints.push(it));
            this.dataSourceLength = response.responseDetails.total;
          }
        },
        (error: HttpErrorResponse) => {
          this.exceptionService.handleError(error);
          this.toastrService.error('Unable to get Endpoints.', 'Error');
        },
        () => {
          this.serverService.isNotLoading();
          this.loaderHidden = true;
          this.searchButtonHidden = false;
        }
      );
  }

  retrieveAllUsers(): any {
    this.subscriptions = this.serverService
      .getAllUsers(this.sessionId)
      .subscribe(
        (response) => {
          if (response.responseStatus === 'SUCCESS') {
            response.data.forEach((user: any) => {
              const entry = {
                id: user.id,
                name: user.user_first_name__v + ' ' + user.user_last_name__v,
              };
              this.allUsers.push(entry);

              this.filteredCreateUsers = this.searchUserCreateControl.valueChanges.pipe(
                startWith(''),
                map((val) => this.filter(val))
              );

              this.filteredModifiedUsers = this.searchUserModifyControl.valueChanges.pipe(
                startWith(''),
                map((val) => this.filter(val))
              );
            });
          }
        },
        (error: HttpErrorResponse) => {
          this.exceptionService.handleError(error);
          this.toastrService.error(`Unable to get users.`, 'Error');
        }
      );
  }

  /**
   * used for document search (filtering based on user input)
   * @param val = user text field input
   */
  filter(val): any {
    if (this.allUsers) {
      return this.allUsers.filter((option) =>
        option.name.toLowerCase().includes(val)
      );
    }
  }

  picklistValueToArray(name, label, value): void {
    const fieldValuePair = {
      fieldName: name,
      fieldLabel: label,
      fieldValue: value,
    };

    // remove from array if something was selected, we need to replace old value with new
    const queryIndex = this.queryStringConstructor
      .map((e) => {
        return e.fieldLabel;
      })
      .indexOf(label);

    if (queryIndex >= 0) {
      this.queryStringConstructor.splice(queryIndex, 1);
    }

    // add selected condition to array
    if (value !== '') {
      this.queryStringConstructor.push(fieldValuePair);
    }
  }

  textFieldValueToArray(name: string, label: string): void {
    const value = (document.getElementById(name) as HTMLInputElement).value;
    this.picklistValueToArray(name, label, value);
  }

  defineSortOrder(): any {
    if (
      this.sortByFieldName === 'id' ||
      this.sortByFieldName === 'related_substance__c'
    ) {
      this.sortByOrder = 'DESC';
    } else {
      this.sortByOrder = 'ASC';
    }

    this.getEndpointsWithFilters(this.pageSize, 0);
  }

  filterAdditionalFieldValues(
    allAdditionalFieldValues: Array<EcotoxAdditionalField>,
    filterWord: string
  ): Array<EcotoxAdditionalField> {
    return allAdditionalFieldValues.filter(
      (item) => item.picklist === filterWord
    );
  }

  destroyEndpoints(): any {
    this.existingEndpoints = [];
    // reset filter values (UI)
  }

  onPaginateChange($event: PageEvent): void {
    const page = $event.pageIndex;
    const size = $event.pageSize;
    this.pageSize = $event.pageSize;
    const skip = page * size;
    this.pageSkip = skip;

    this.getEndpointsWithFilters(size, skip);
  }

  reduceCount(): void {
    this.dataSourceLength = this.dataSourceLength - 1;
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.allFields.filter(
      (field) => field.toLowerCase().indexOf(filterValue) === 0
    );
  }
}
