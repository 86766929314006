<div>

  <div *ngIf="showExistingRecord" class="fieldsHolder" id="viewRecord">

    <div class="copyHolder">
      <button (click)="copyExistingEndpoint(this.existingEndpointRecord)" class="copy" data-cy="CopyEndpoint"
              title="Copy Endpoint"><span class="material-icons">content_copy</span></button>
    </div>

    <form [formGroup]="selectFormGroup">

      <div (click)="enableEditing()" class="singleFieldContainer">
        <div class="formItemTitle">Category:</div>
        <div (click)="enableEditing()">
          <select class="formSelector" name="category" formControlName="category__c">
            <option value="{{category.id}}">{{category['name__v']}}</option>
          </select>
        </div>
      </div>

      <div class="singleFieldContainer">
        <div class="formItemTitle">Source:</div>
        <div (click)="enableEditing()">
          <select class="disabledDocumentFormSelector" formControlName="source" name="source"
                  title="document_unbound__c">
            <option *ngFor="let document of documents" value="{{ document.id }}">{{ document.name }}</option>
          </select>
        </div>
      </div>

      <div class="singleFieldContainer">
        <div class="formItemTitle">Waiver/Data Gap:</div>
        <div (click)="enableEditing();">
          <select class="formSelector"
                  formControlName="waiverdata_gap__c"
                  title="waiverdata_gap__c"
          >
            <option value=""></option>
            <option *ngFor="let waiverDataGap of waiverDataGaps"
                    [value]="waiverDataGap.name">{{ waiverDataGap.label }}</option>
          </select>
        </div>
      </div>

      <div class="singleFieldContainer">
      <div class="formItemTitle">Study Type:</div>
      <div (click)="enableEditing()">
        <mat-form-field class="multiPicklistFormSelector">
          <mat-select
            class="angularFormSelector"
            formControlName="studyType"
            multiple
            ngDefaultControl title="study_type__c">
            <mat-option *ngFor="let listedStudyType of allStudyTypes"
                        [title]="listedStudyType.picklist"
                        [value]=" listedStudyType.name ">{{ listedStudyType.label }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>

    <div class="singleFieldContainer">
      <div class="formItemTitle">Comment:</div>
      <div (click)="enableEditing();">
        <textarea formControlName="comment__c" title="comment__c" class="disabledFormFreeText" rows="1"></textarea>
      </div>
    </div>

    <div class="rightConnerView">
      <div class="singleFieldContainer">
        <div class="authorInfo">Created by {{createdBy[0].name}}
          <br>at {{formatCreatedDate}}
        </div>
      </div>

      <div class="singleFieldContainer">
        <div class="authorInfo">Last Modified by {{modifiedBy[0].name}}
          <br>at {{formatModifiedDate}}
        </div>
      </div>
    </div>

    </form>

  </div>

  <div *ngIf="showEditRecord" class="editableFieldsHolder">
    <app-waiver-and-data-gaps
      (cancelEdit)="cancelEditing()"
      (saveChanges)="saveChanges($event)"
      [singleEndpointRecord]="existingEndpointRecord"
      [purpose]="purposeMessage"
      [category]="category"
      [categoryPicklists]="categoryPicklists"
      [substanceTypes]="substanceTypes"
      [relatedSubstances]="relatedSubstances"
      [allStudyTypes]="allStudyTypes"
      [documents]="documents"
    ></app-waiver-and-data-gaps>
  </div>

</div>

