<div [ngStyle]="{'z-index':this.zindex}">
  <div [ngSwitch]="categoryName">

    <div *ngSwitchCase="'Acute Toxicity'" class="recordsContainer">
      <app-acute-toxicity-existing
        (copyExisting)="this.copyEndpointInput(this.endpointRecord)"
        [arrayedEndpointRecord]="endpointRecord"
        [category]="category"
        [substanceTypes]="substanceTypes"
        [relatedSubstances]="relatedSubstances"
        [allStudyTypes]="categoryStudyTypes"
        [testSystemTypes]="categoryTestSystemTypes"
        [allRouteOfAdministration]="categoryRouteOfAdministrations"
        [allTestSystemNames]="categoryTestSystemNames"
        [allEndpoints]="categoryEndpoints"
        [allEndpointUnits]="categoryEndpointUnits"
        [allOperators]="operators"
        [allAdditionalFields]="categoryAdditionalFields"
        [allAdditionalFieldValues]="allAdditionalFieldValues"
        [documents]="documents"
        [allUsers]="allUsers"
      >
      </app-acute-toxicity-existing>
    </div>

    <div *ngSwitchCase="'Specific Target Organ Toxicity (STOT)'">
      <app-stot-existing
        (copyExisting)="this.copyEndpointInput(this.endpointRecord)"
        [arrayedEndpointRecord]="endpointRecord"
        [category]="category"
        [substanceTypes]="substanceTypes"
        [relatedSubstances]="relatedSubstances"
        [allStudyTypes]="categoryStudyTypes"
        [allTestSystemTypes]="categoryTestSystemTypes"
        [allRouteOfAdministration]="categoryRouteOfAdministrations"
        [allTestSystemNames]="categoryTestSystemNames"
        [allEndpoints]="categoryEndpoints"
        [allEndpointUnits]="categoryEndpointUnits"
        [allOperators]="operators"
        [allAdditionalFields]="categoryAdditionalFields"
        [allAdditionalFieldValues]="allAdditionalFieldValues"
        [documents]="documents"
        [allUsers]="allUsers"
      >
      </app-stot-existing>
    </div>

    <div *ngSwitchCase="'Genotoxicity'">
      <app-genotoxicity-existing
        (copyExisting)="this.copyEndpointInput(this.endpointRecord)"
        [arrayedEndpointRecord]="endpointRecord"
        [category]="category"
        [substanceTypes]="substanceTypes"
        [relatedSubstances]="relatedSubstances"
        [allAdditionalFields]="categoryAdditionalFields"
        [allAdditionalFieldValues]="allAdditionalFieldValues"
        [documents]="documents"
        [allUsers]="allUsers"
      >
      </app-genotoxicity-existing>
    </div>

    <div *ngSwitchCase="'Carcinogenicity'">
      <app-carcinogenicity-existing
        (copyExisting)="this.copyEndpointInput(this.endpointRecord)"
        [arrayedEndpointRecord]="endpointRecord"
        [category]="category"
        [substanceTypes]="substanceTypes"
        [relatedSubstances]="relatedSubstances"
        [allStudyTypes]="categoryStudyTypes"
        [allTestSystemTypes]="categoryTestSystemTypes"
        [allRouteOfAdministration]="categoryRouteOfAdministrations"
        [allTestSystemNames]="categoryTestSystemNames"
        [allEndpoints]="categoryEndpoints"
        [allAdditionalFields]="categoryAdditionalFields"
        [allAdditionalFieldValues]="allAdditionalFieldValues"
        [documents]="documents"
        [allUsers]="allUsers"
      >
      </app-carcinogenicity-existing>
    </div>

    <div *ngSwitchCase="'Reproduction Developmental Toxicity'">
      <app-reproduction-developmental-toxicity-existing
        (copyExisting)="this.copyEndpointInput(this.endpointRecord)"
        [arrayedEndpointRecord]="endpointRecord"
        [category]="category"
        [substanceTypes]="substanceTypes"
        [relatedSubstances]="relatedSubstances"
        [allStudyTypes]="categoryStudyTypes"
        [allTestSystemTypes]="categoryTestSystemTypes"
        [allRouteOfAdministration]="categoryRouteOfAdministrations"
        [allTestSystemNames]="categoryTestSystemNames"
        [allEndpoints]="categoryEndpoints"
        [allAdditionalFields]="categoryAdditionalFields"
        [allAdditionalFieldValues]="allAdditionalFieldValues"
        [documents]="documents"
        [allUsers]="allUsers"
      >
      </app-reproduction-developmental-toxicity-existing>
    </div>

    <div *ngSwitchCase="'Neuro/Immunotoxicity'">
      <app-neuro-immunotoxicity-existing
        (copyExisting)="this.copyEndpointInput(this.endpointRecord)"
        [arrayedEndpointRecord]="endpointRecord"
        [category]="category"
        [substanceTypes]="substanceTypes"
        [relatedSubstances]="relatedSubstances"
        [allStudyTypes]="categoryStudyTypes"
        [allTestSystemTypes]="categoryTestSystemTypes"
        [allRouteOfAdministration]="categoryRouteOfAdministrations"
        [allTestSystemNames]="categoryTestSystemNames"
        [allEndpoints]="categoryEndpoints"
        [allAdditionalFields]="categoryAdditionalFields"
        [allAdditionalFieldValues]="allAdditionalFieldValues"
        [documents]="documents"
        [allUsers]="allUsers"
      >
      </app-neuro-immunotoxicity-existing>
    </div>

    <div *ngSwitchCase="'Endocrine Disruption'">
      <app-endocrine-disruption-existing
        (copyExisting)="this.copyEndpointInput(this.endpointRecord)"
        [arrayedEndpointRecord]="endpointRecord"
        [category]="category"
        [substanceTypes]="substanceTypes"
        [relatedSubstances]="relatedSubstances"
        [allStudyTypes]="categoryStudyTypes"
        [allTestSystemTypes]="categoryTestSystemTypes"
        [allRouteOfAdministration]="categoryRouteOfAdministrations"
        [allTestSystemNames]="categoryTestSystemNames"
        [allEndpoints]="categoryEndpoints"
        [allAdditionalFields]="categoryAdditionalFields"
        [allAdditionalFieldValues]="allAdditionalFieldValues"
        [documents]="documents"
        [allUsers]="allUsers"
      >
      </app-endocrine-disruption-existing>
    </div>

    <div *ngSwitchCase="'Metabolites/Impurities'">
      <app-metabolites-impurities-existing
        (copyExisting)="this.copyEndpointInput(this.endpointRecord)"
        [arrayedEndpointRecord]="endpointRecord"
        [category]="category"
        [substanceTypes]="substanceTypes"
        [relatedSubstances]="relatedSubstances"
        [allStudyTypes]="categoryStudyTypes"
        [allRouteOfAdministration]="categoryRouteOfAdministrations"
        [allTestSystemNames]="categoryTestSystemNames"
        [allEndpoints]="categoryEndpoints"
        [allEndpointUnits]="categoryEndpointUnits"
        [allOperators]="operators"
        [allAdditionalFields]="categoryAdditionalFields"
        [allAdditionalFieldValues]="allAdditionalFieldValues"
        [documents]="documents"
        [allUsers]="allUsers"
      >
      </app-metabolites-impurities-existing>
    </div>

    <div *ngSwitchCase="'Reference Dose Levels'">
      <app-reference-dose-levels-existing
        (copyExisting)="this.copyEndpointInput(this.endpointRecord)"
        [arrayedEndpointRecord]="endpointRecord"
        [category]="category"
        [substanceTypes]="substanceTypes"
        [relatedSubstances]="relatedSubstances"
        [allStudyTypes]="categoryStudyTypes"
        [allEndpoints]="categoryEndpoints"
        [allEndpointUnits]="categoryEndpointUnits"
        [allOperators]="operators"
        [allAdditionalFields]="categoryAdditionalFields"
        [allAdditionalFieldValues]="allAdditionalFieldValues"
        [documents]="documents"
        [allUsers]="allUsers"
      >
      </app-reference-dose-levels-existing>
    </div>

    <div *ngSwitchCase="'Occupational Risk Assessment'">
      <app-occupational-risk-assessment-existing
        (copyExisting)="this.copyEndpointInput(this.endpointRecord)"
        [arrayedEndpointRecord]="endpointRecord"
        [category]="category"
        [substanceTypes]="substanceTypes"
        [relatedSubstances]="relatedSubstances"
        [allStudyTypes]="categoryStudyTypes"
        [allTestSystemTypes]="categoryTestSystemTypes"
        [allEndpoints]="categoryEndpoints"
        [allEndpointUnits]="categoryEndpointUnits"
        [allOperators]="operators"
        [allAdditionalFields]="categoryAdditionalFields"
        [allAdditionalFieldValues]="allAdditionalFieldValues"
        [documents]="documents"
        [allUsers]="allUsers"
      >
      </app-occupational-risk-assessment-existing>
    </div>

  </div>

  <div class="copiedRecords">
    <div [ngSwitch]="categoryName">
      <div *ngSwitchCase="'Acute Toxicity'">
        <app-acute-toxicity
          (copyInput)="copyEndpointInput($event)"
          (removeInput)="removeEndpointInput($event)"
          *ngFor="let endpoint of copiedEndpointsArrayList"
          [singleEndpointRecord]="endpoint"
          [category]="category"
          [substanceTypes]="substanceTypes"
          [relatedSubstances]="relatedSubstances"
          [allStudyTypes]="allStudyTypes"
          [testSystemTypes]="allTestSystemTypes"
          [allRouteOfAdministration]="allRouteOfAdministrations"
          [allTestSystemNames]="allTestSystemNames"
          [allEndpoints]="allEndpoints"
          [allEndpointUnits]="allEndpointUnits"
          [allOperators]="operators"
          [allAdditionalFields]="categoryAdditionalFields"
          [allAdditionalFieldValues]="allAdditionalFieldValues"
          [documents]="documents"
        >
        </app-acute-toxicity>
      </div>

      <div *ngSwitchCase="'Specific Target Organ Toxicity (STOT)'">
        <app-stot
          (copyInput)="copyEndpointInput($event)"
          (removeInput)="removeEndpointInput($event)"
          *ngFor="let endpoint of copiedEndpointsArrayList"
          [singleEndpointRecord]="endpoint"
          [category]="category"
          [substanceTypes]="substanceTypes"
          [relatedSubstances]="relatedSubstances"
          [allStudyTypes]="allStudyTypes"
          [allTestSystemTypes]="allTestSystemTypes"
          [allRouteOfAdministration]="allRouteOfAdministrations"
          [allTestSystemNames]="allTestSystemNames"
          [allEndpoints]="allEndpoints"
          [allEndpointUnits]="allEndpointUnits"
          [allOperators]="operators"
          [allAdditionalFields]="categoryAdditionalFields"
          [allAdditionalFieldValues]="allAdditionalFieldValues"
          [documents]="documents"
        >
        </app-stot>
      </div>

      <div *ngSwitchCase="'Genotoxicity'">
        <app-genotoxicity
          (copyInput)="copyEndpointInput($event)"
          (removeInput)="removeEndpointInput($event)"
          *ngFor="let endpoint of copiedEndpointsArrayList"
          [singleEndpointRecord]="endpoint"
          [category]="category"
          [substanceTypes]="substanceTypes"
          [relatedSubstances]="relatedSubstances"
          [allAdditionalFields]="categoryAdditionalFields"
          [allAdditionalFieldValues]="allAdditionalFieldValues"
          [documents]="documents"
        >
        </app-genotoxicity>
      </div>

      <div *ngSwitchCase="'Carcinogenicity'">
        <app-carcinogenicity
          (copyInput)="copyEndpointInput($event)"
          (removeInput)="removeEndpointInput($event)"
          *ngFor="let endpoint of copiedEndpointsArrayList"
          [singleEndpointRecord]="endpoint"
          [category]="category"
          [substanceTypes]="substanceTypes"
          [relatedSubstances]="relatedSubstances"
          [allStudyTypes]="allStudyTypes"
          [allTestSystemTypes]="allTestSystemTypes"
          [allRouteOfAdministration]="allRouteOfAdministrations"
          [allTestSystemNames]="allTestSystemNames"
          [allEndpoints]="allEndpoints"
          [allAdditionalFields]="categoryAdditionalFields"
          [allAdditionalFieldValues]="allAdditionalFieldValues"
          [documents]="documents"
        >
        </app-carcinogenicity>
      </div>

      <div *ngSwitchCase="'Reproduction Developmental Toxicity'">
        <app-reproduction-developmental-toxicity
          (copyInput)="copyEndpointInput($event)"
          (removeInput)="removeEndpointInput($event)"
          *ngFor="let endpoint of copiedEndpointsArrayList"
          [singleEndpointRecord]="endpoint"
          [category]="category"
          [substanceTypes]="substanceTypes"
          [relatedSubstances]="relatedSubstances"
          [allStudyTypes]="allStudyTypes"
          [allTestSystemTypes]="allTestSystemTypes"
          [allRouteOfAdministration]="allRouteOfAdministrations"
          [allTestSystemNames]="allTestSystemNames"
          [allEndpoints]="allEndpoints"
          [allAdditionalFields]="categoryAdditionalFields"
          [allAdditionalFieldValues]="allAdditionalFieldValues"
          [documents]="documents"
        >
        </app-reproduction-developmental-toxicity>
      </div>

      <div *ngSwitchCase="'Neuro/Immunotoxicity'">
        <app-neuro-immunotoxicity
          (copyInput)="copyEndpointInput($event)"
          (removeInput)="removeEndpointInput($event)"
          *ngFor="let endpoint of copiedEndpointsArrayList"
          [singleEndpointRecord]="endpoint"
          [category]="category"
          [substanceTypes]="substanceTypes"
          [relatedSubstances]="relatedSubstances"
          [allStudyTypes]="allStudyTypes"
          [allTestSystemTypes]="allTestSystemTypes"
          [allRouteOfAdministration]="allRouteOfAdministrations"
          [allTestSystemNames]="allTestSystemNames"
          [allEndpoints]="allEndpoints"
          [allAdditionalFields]="categoryAdditionalFields"
          [allAdditionalFieldValues]="allAdditionalFieldValues"
          [documents]="documents"
        >
        </app-neuro-immunotoxicity>
      </div>

      <div *ngSwitchCase="'Endocrine Disruption'">
        <app-endocrine-disruption
          (copyInput)="copyEndpointInput($event)"
          (removeInput)="removeEndpointInput($event)"
          *ngFor="let endpoint of copiedEndpointsArrayList"
          [singleEndpointRecord]="endpoint"
          [category]="category"
          [substanceTypes]="substanceTypes"
          [relatedSubstances]="relatedSubstances"
          [allStudyTypes]="allStudyTypes"
          [allTestSystemTypes]="categoryTestSystemTypes"
          [allRouteOfAdministration]="allRouteOfAdministrations"
          [allTestSystemNames]="allTestSystemNames"
          [allEndpoints]="allEndpoints"
          [allAdditionalFields]="categoryAdditionalFields"
          [allAdditionalFieldValues]="allAdditionalFieldValues"
          [documents]="documents"
        >
        </app-endocrine-disruption>
      </div>

      <div *ngSwitchCase="'Metabolites/Impurities'">
        <app-metabolites-impurities
          (copyInput)="copyEndpointInput($event)"
          (removeInput)="removeEndpointInput($event)"
          *ngFor="let endpoint of copiedEndpointsArrayList"
          [singleEndpointRecord]="endpoint"
          [category]="category"
          [substanceTypes]="substanceTypes"
          [relatedSubstances]="relatedSubstances"
          [allStudyTypes]="allStudyTypes"
          [allRouteOfAdministration]="allRouteOfAdministrations"
          [allTestSystemNames]="allTestSystemNames"
          [allEndpoints]="allEndpoints"
          [allEndpointUnits]="allEndpointUnits"
          [allOperators]="operators"
          [allAdditionalFields]="categoryAdditionalFields"
          [allAdditionalFieldValues]="allAdditionalFieldValues"
          [documents]="documents"
        >
        </app-metabolites-impurities>
      </div>

      <div *ngSwitchCase="'Reference Dose Levels'">
        <app-reference-dose-levels
          (copyInput)="copyEndpointInput($event)"
          (removeInput)="removeEndpointInput($event)"
          *ngFor="let endpoint of copiedEndpointsArrayList"
          [singleEndpointRecord]="endpoint"
          [category]="category"
          [substanceTypes]="substanceTypes"
          [relatedSubstances]="relatedSubstances"
          [allStudyTypes]="allStudyTypes"
          [allEndpoints]="allEndpoints"
          [allEndpointUnits]="allEndpointUnits"
          [allOperators]="operators"
          [allAdditionalFields]="categoryAdditionalFields"
          [allAdditionalFieldValues]="allAdditionalFieldValues"
          [documents]="documents"
        >
        </app-reference-dose-levels>
      </div>

      <div *ngSwitchCase="'Occupational Risk Assessment'">
        <app-occupational-risk-assessment
          (copyInput)="copyEndpointInput($event)"
          (removeInput)="removeEndpointInput($event)"
          *ngFor="let endpoint of copiedEndpointsArrayList"
          [singleEndpointRecord]="endpoint"
          [category]="category"
          [substanceTypes]="substanceTypes"
          [relatedSubstances]="relatedSubstances"
          [allStudyTypes]="categoryStudyTypes"
          [allTestSystemTypes]="categoryTestSystemTypes"
          [allEndpoints]="categoryEndpoints"
          [allEndpointUnits]="categoryEndpointUnits"
          [allOperators]="operators"
          [allAdditionalFields]="categoryAdditionalFields"
          [allAdditionalFieldValues]="allAdditionalFieldValues"
          [documents]="documents"
        >
        </app-occupational-risk-assessment>
      </div>


    </div>

    <div *ngIf="showErrorMessage" class="errorMessageHolder">
      <p>Displayed Endpoints could not be saved due to the following errors:</p>
      <div *ngFor="let message of this.errorMessagesToUser">
        <p>{{message}}</p>
      </div>
    </div>
    <div *ngIf="showSuccessMessage" class="successMessageHolder">
      <p class="marginLeft3">{{successMessagesToUser}}</p>
      <div class="buttonHolder" (click)="openDialog()" >
        <button *ngIf="showSuccessMessage" class="buttonsRefresh">
          <span class="material-icons">refresh</span>
        </button>
        Refresh Endpoint List
      </div>
    </div>

    <button id="{{this.zindex}}" *ngIf="saveButtonShowing" class="buttons saveButton" [class.spinner]="loading" [disabled]="disabled"
            (click)="saveCopies();"
            mat-raised-button type="button">
      <strong>&#10003; </strong>Save Endpoints
    </button>
  </div>

  <div class="orangeLine" *ngIf="saveButtonShowing"></div>
</div>
