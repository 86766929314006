import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AbstractControl, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ToastrService} from 'ngx-toastr';
import {ExceptionService} from 'src/app/core/exception.service';
import {ActivatedRoute} from '@angular/router';
import {ServerService} from 'src/app/core/server.service';
import {PicklistValue} from 'src/app/endpoint/models/picklist-value';
import {ToxAdditionalField} from '../../../models/tox-additional-field';
import {SubstanceItem} from '../../../models/substance-item';
import {DocumentMenuItem} from '../../../models/document-menu-item';
import {ToxEndpointTableItem} from '../../../models/tox-endpoint-table-item';

let me: EndocrineDisruptionComponent;

@Component({
  selector: 'app-endocrine-disruption',
  templateUrl: './endocrine-disruption.component.html',
  styleUrls: ['../../../record_style.css']
})
export class EndocrineDisruptionComponent implements OnInit {
  @Input() singleEndpointRecord: ToxEndpointTableItem;
  @Input() category: PicklistValue;
  @Input() allStudyTypes: Array<PicklistValue>;
  @Input() allTestSystemTypes: Array<PicklistValue>;
  @Input() allRouteOfAdministration: Array<PicklistValue>;
  @Input() allTestSystemNames: Array<PicklistValue>;
  @Input() allEndpoints: Array<PicklistValue>;
  @Input() allAdditionalFields: Array<ToxAdditionalField>;
  @Input() allAdditionalFieldValues: Array<PicklistValue>;
  @Input() substance: SubstanceItem;
  @Input() substanceTypes: Array<PicklistValue>;
  @Input() relatedSubstances: any;
  @Input() documents: Array<DocumentMenuItem>;
  @Input() purpose: string;

  @Output() removeInput: EventEmitter<ToxEndpointTableItem> = new EventEmitter();
  @Output() copyInput: EventEmitter<ToxEndpointTableItem> = new EventEmitter();
  @Output() cancelEdit: EventEmitter<ToxEndpointTableItem> = new EventEmitter();
  @Output() saveChanges: EventEmitter<ToxEndpointTableItem> = new EventEmitter();

  subscriptions: any;
  selectFormGroup: FormGroup;
  configureFormGroup: FormGroup;
  public categoryAdditionalFields: Array<ToxAdditionalField>;
  public additionalFields: Array<ToxAdditionalField>;
  public additionalFieldValues: Map<string, any>;
  public studyTypes: Array<PicklistValue>;
  public testSystemTypes: Array<PicklistValue>;
  public testSystemNames: Array<PicklistValue>;
  public endpoints: Array<PicklistValue>;
  public operators: Array<PicklistValue>;
  public value3: any;
  public filteredRelatedSubstances: any;
  public hiddenAdditionalFields: Array<ToxAdditionalField>;
  public routeOfAdministration: Array<PicklistValue>;
  public studyTypeColor: string;
  private sessionId: string;
  public showCopyOptions: boolean;
  public showEditOptions: boolean;

  constructor(
    private route: ActivatedRoute,
    private exceptionService: ExceptionService,
    private serverService: ServerService,
    private toastrService: ToastrService,
    private formBuilder: FormBuilder
  ) {
    me = this;
    this.subscriptions = {};
    this.route.paramMap.subscribe((params) => {
      this.sessionId = params.get('sessionId');
    });
    this.selectFormGroup = this.formBuilder.group({
      substanceType: [],
      relatedSubstance: [{value: '', disabled: true}],
      studyType: [{value: '', disabled: false}, Validators.required],
      test_system_type__c: [{value: '', disabled: false}],
      route_of_administration__c: [{value: '', disabled: false}],
      test_system_name__c: [{value: '', disabled: false}],
      endpoint: [{value: '', disabled: false}, Validators.required],
      value_3__c: [{value: '', disabled: false}],
      // additional fields
      ed_modality__c: [{value: '', disabled: false}],
      mechanism_tmodality__c: [{value: '', disabled: false}],
      target_organ__c: [{value: '', disabled: false}],
      eats_and_developmental_parameters_affect__c: [{value: '', disabled: false}],
      t_trigger__c: [{value: '', disabled: false}],
      // default
      selectedDocument: [{value: '', disabled: false}],
    });
    this.endpoints = [];
    this.testSystemTypes = [];
    this.testSystemNames = [];
    this.routeOfAdministration = [];
    this.value3 = [{label: 'Yes', name: 'yes'}, {label: 'No', name: 'no'}, {label: 'Equivocal', name: 'equivocal'}];
    this.categoryAdditionalFields = [];
    this.additionalFields = [];
    this.additionalFieldValues = new Map();
    this.hiddenAdditionalFields = [];
    this.filteredRelatedSubstances = [];
    this.studyTypeColor = '';
  }

  get relatedSubstanceControl(): AbstractControl {
    return this.selectFormGroup.get('relatedSubstance');
  }
  get endpointControl(): AbstractControl {
    return this.selectFormGroup.get('endpoint');
  }
  get studyTypeControl(): AbstractControl {
    return this.selectFormGroup.get('studyType');
  }
  get sourceControl(): AbstractControl {
    return this.selectFormGroup.get('selectedDocument');
  }

  ngOnInit(): void {

    if (this.purpose === 'editOnExisting') {
      this.showCopyOptions = false;
      this.showEditOptions = true;
    }
    else {
      this.showCopyOptions = true;
      this.showEditOptions = false;
    }

    this.sourceControl.setValue(this.singleEndpointRecord.document_unbound__c);
    this.studyTypes = this.allStudyTypes;
    this.endpoints = this.allEndpoints;
    // prepare additional fields
    const preAllAdditionalFields = this.allAdditionalFields.filter(
      (entry) =>
        entry.group === 'study_type_endocrine_disruption__c'
    );
    this.categoryAdditionalFields = preAllAdditionalFields.sort((a, b) => a.sortNumber - b.sortNumber);

    this.defaultAdditionalFields();
    if (this.singleEndpointRecord.study_type_picklist_name__c) {
      // COPY record

      // parent (or none) cannot have Related Substance
      if (this.singleEndpointRecord.substance_type__c === 'parent__c' || this.singleEndpointRecord.substance_type__c === '') {
        this.selectFormGroup.get('relatedSubstance').disable();
      } else {
        // filter Related Substances by the selected Substance Type
        this.filteredRelatedSubstances = this.relatedSubstances.filter(
          (f) => f.substance_type__c[0] === this.singleEndpointRecord.substance_type__c
        );
        this.selectFormGroup.get('relatedSubstance').enable();
      }
      this.prepareRecord();
    }
    else {
      // NOT COPYING record
      this.selectFormGroup.get('substanceType').setValue('parent__c');
    }
  }

  getSelectedOptionPicklist(event: any): string {
    return event.target.options[event.target.options.selectedIndex].title;
  }

  onSelectSubstanceType(event: any): any {
    // update object
    this.singleEndpointRecord.substance_type__c = event.target.value;

    // clear Related Substance value because the group of Related Substances has changed
    this.singleEndpointRecord.related_substance__c = '';
    // also clear Related Substance form field
    this.relatedSubstanceControl.setValue('');

    // parent (or none) cannot have Related Substance
    if (event.target.value === 'parent__c' || event.target.value === '') {
      this.selectFormGroup.get('relatedSubstance').disable();
    } else {
      // filter Related Substances by the selected Substance Type
      this.filteredRelatedSubstances = this.relatedSubstances.filter(
        (f) => f.substance_type__c[0] === event.target.value
      );
      this.selectFormGroup.get('relatedSubstance').enable();
    }
  }

  onSelectStudyType(event: any): any {
    this.singleEndpointRecord.study_type__c = event.target.value;

    if (event.target.value !== '') {
      // Study Type value selected
      this.singleEndpointRecord.study_type_picklist_name__c = this.getSelectedOptionPicklist(
        event
      );
      this.filterGroupValues(event.target.value);
    }
    else {
      // Study Type cleared
      this.singleEndpointRecord.study_type_picklist_name__c = '';
      this.studyTypeColor = '';
      this.clearGroupValues();
      this.defaultAdditionalFields();
    }
  }

  filterGroupValues(studyTypeValue): any {
    if (studyTypeValue === 'erbindingactivation__c' ||
      studyTypeValue === 'arbindingactivation__c' ||
      studyTypeValue === 'steroidogenesis__c' ||
      studyTypeValue === 'aromatase_assay__c' ||
      studyTypeValue === 'nis__c' ||
      studyTypeValue === 'thyroid_receptor_binding__c' ||
      studyTypeValue === 'iodine_discharge_assay__c' ||
      studyTypeValue === 'diodinase_assay__c' ||
      studyTypeValue === 'other_in_vitro_assay__c'
    )
    {
      if (this.studyTypeColor !== 'orange')
      {
        // resetting only if changing to orange group
        this.testSystemTypes = [];
        this.testSystemNames = [];
        this.routeOfAdministration = [];
        this.singleEndpointRecord.target_organ__c = '';
        this.singleEndpointRecord.eats_and_developmental_parameters_affect__c = '';
        this.selectFormGroup.get('target_organ__c').setValue('');
        this.selectFormGroup.get('eats_and_developmental_parameters_affect__c').setValue('');
        for (let index = 0; index < this.additionalFields.length; index++) {
          const element: ToxAdditionalField = this.additionalFields[index];
          if (element.name === 'target_organ__c') {
            this.additionalFields.splice(index, 1);
          }
        }

        for (let index = 0; index < this.additionalFields.length; index++) {
          const element: ToxAdditionalField = this.additionalFields[index];
          if (element.name === 'eats_and_developmental_parameters_affect__c') {
            this.additionalFields.splice(index, 1);
          }
        }
        this.clearGroupValues();

        // filter Test System Types
        const dependedTestSystemTypes = ['endocrine_disruption_in_vitro__c'];
        dependedTestSystemTypes.forEach(record => {
            const singleRecord = this.allTestSystemTypes.filter(
              (entry) => entry.name === record
            );
            this.testSystemTypes = this.testSystemTypes.concat(singleRecord);
          }
        );
        // filter Test System Names
        const dependedTestSystemNames = ['in_vitro_cell_culture__c'];
        dependedTestSystemNames.forEach(record => {
            const singleRecord = this.allTestSystemNames.filter(
              (entry) => entry.name === record
            );
            this.testSystemNames = this.testSystemNames.concat(singleRecord);
          }
        );
        // filter Route Of Administration
        const dependedRouteOfAdministration = ['cell_culture__c'];
        dependedRouteOfAdministration.forEach(record => {
            const singleRecord = this.allRouteOfAdministration.filter(
              (entry) => entry.name === record
            );
            this.routeOfAdministration = this.routeOfAdministration.concat(singleRecord);
          }
        );
        this.studyTypeColor = 'orange';
      }
    }
    else if (studyTypeValue === 'hershberger__c' ||
      studyTypeValue === 'uerotrophic__c' ||
      studyTypeValue === 'malefemale_pubertal__c' ||
      studyTypeValue === '2generation__c' ||
      studyTypeValue === 'eogrts__c' ||
      studyTypeValue === 'developmental_thyroid__c'
    )
    {
      if (this.studyTypeColor !== 'yellow') {
        // resetting fields only if changing to yellow group
        this.testSystemTypes = [];
        this.testSystemNames = [];
        this.routeOfAdministration = [];
        this.clearGroupValues();
        // filter Test System Types
        const dependedTestSystemTypes = ['endocrine_disruption_in_vivo__c'];
        dependedTestSystemTypes.forEach(record => {
            const singleRecord = this.allTestSystemTypes.filter(
              (entry) => entry.name === record
            );
            this.testSystemTypes = this.testSystemTypes.concat(singleRecord);
          }
        );
        // filter Test System Names
        const dependedTestSystemNames = ['mouse__c', 'rat__c'];
        dependedTestSystemNames.forEach(record => {
            const singleRecord = this.allTestSystemNames.filter(
              (entry) => entry.name === record
            );
            this.testSystemNames = this.testSystemNames.concat(singleRecord);
          }
        );
        // filter Route Of Administration
        const dependedRouteOfAdministration = ['oral_gavage__c', 'oral_diet__c', 'oral_drinking_water__c', 'oral_capsule__c'];
        dependedRouteOfAdministration.forEach(record => {
            const singleRecord = this.allRouteOfAdministration.filter(
              (entry) => entry.name === record
            );
            this.routeOfAdministration = this.routeOfAdministration.concat(singleRecord);
          }
        );
        this.studyTypeColor = 'yellow';
      }
    }
  }

  clearGroupValues(): any {
    this.singleEndpointRecord.test_system_type__c = '';
    this.singleEndpointRecord.test_system_name__c = '';
    this.singleEndpointRecord.route_of_administration__c = '';

    this.selectFormGroup.get('test_system_type__c').setValue('');
    this.selectFormGroup.get('test_system_name__c').setValue('');
    this.selectFormGroup.get('route_of_administration__c').setValue('');
  }

  defaultAdditionalFields(): any {
    this.additionalFields = [];
    // remove fields mark with 'yes__c' in Initially Hidden field
    const preAdditionalFields = this.categoryAdditionalFields.filter(obj =>
      obj.hidden[0] !== 'yes__c'
    );
    this.additionalFields = preAdditionalFields.sort((a, b) => a.sortNumber - b.sortNumber);
  }

  filterAdditionalFieldValues(allAdditionalFieldValues: Array<PicklistValue>, filterWord: string): Array<PicklistValue> {
    return allAdditionalFieldValues.filter(
      (item) => item.picklist === filterWord
    );
  }

  removeEndpoint(singleEndpointRecord): any {
    this.removeInput.emit(singleEndpointRecord);
  }

  updateFieldValue(newValue): any {
    const fieldName = newValue.title;
    this.singleEndpointRecord[fieldName] = newValue.value;

    if (fieldName === 'value_3__c') {
      if (newValue.value === 'yes' || newValue.value === 'equivocal') {
        if (this.singleEndpointRecord.test_system_type__c === 'endocrine_disruption_in_vivo__c') {
          const dependedAdditionalFields = ['ed_modality__c', 'target_organ__c', 'eats_and_developmental_parameters_affect__c'];
          this.addSpecifiedFields(dependedAdditionalFields);
        }
        else {
          const dependedAdditionalFields = ['ed_modality__c'];
          this.addSpecifiedFields(dependedAdditionalFields);
        }
      }
      else {
        // reset array and form values for green fields and depended on green fields
        this.singleEndpointRecord.ed_modality__c = '';
        this.singleEndpointRecord.mechanism_tmodality__c = '';
        this.singleEndpointRecord.target_organ__c = '';
        this.singleEndpointRecord.eats_and_developmental_parameters_affect__c = '';

        this.selectFormGroup.get('ed_modality__c').setValue('');
        this.selectFormGroup.get('mechanism_tmodality__c').setValue('');
        this.selectFormGroup.get('target_organ__c').setValue('');
        this.selectFormGroup.get('eats_and_developmental_parameters_affect__c').setValue('');
        // re-define additional fields
        this.defaultAdditionalFields(); // remove green fields and depended on values of green fields
      }
    }
    else if (fieldName === 'ed_modality__c') {
      if (newValue.value === 'tmodality__c') {
        // show mechanism_tmodality__c field
        const dependedAdditionalFields = ['mechanism_tmodality__c'];
        this.addSpecifiedFields(dependedAdditionalFields);
      }
      else {
        // reset record value,form value and remove field from fields array
        this.singleEndpointRecord.mechanism_tmodality__c = '';
        this.selectFormGroup.get('mechanism_tmodality__c').setValue('');

        for (let index = 0; index < this.additionalFields.length; index++) {
          const element: ToxAdditionalField = this.additionalFields[index];
          if (element.name === 'mechanism_tmodality__c') {
            this.additionalFields.splice(index, 1);
          }
        }
      }
    }
    else if (fieldName === 'test_system_type__c') {
      if (newValue.value === 'endocrine_disruption_in_vivo__c') {
        if (this.singleEndpointRecord.value_3__c === 'yes' || this.singleEndpointRecord.value_3__c === 'equivocal') {
          const dependedAdditionalFields = ['target_organ__c', 'eats_and_developmental_parameters_affect__c'];
          this.addSpecifiedFields(dependedAdditionalFields);
        }
      }
      else {
        this.singleEndpointRecord.target_organ__c = '';
        this.singleEndpointRecord.eats_and_developmental_parameters_affect__c = '';
        this.selectFormGroup.get('target_organ__c').setValue('');
        this.selectFormGroup.get('eats_and_developmental_parameters_affect__c').setValue('');

        for (let index = 0; index < this.additionalFields.length; index++) {
          const element: ToxAdditionalField = this.additionalFields[index];
          if (element.name === 'target_organ__c') {
            this.additionalFields.splice(index, 1);
          }
        }

        for (let index = 0; index < this.additionalFields.length; index++) {
          const element: ToxAdditionalField = this.additionalFields[index];
          if (element.name === 'eats_and_developmental_parameters_affect__c') {
            this.additionalFields.splice(index, 1);
          }
        }
      }
    }
  }

  addSpecifiedFields(arrayOfGreenFieldNames): any {
    this.additionalFields = [];

    const singleField = this.categoryAdditionalFields.filter(
      (entry) => entry.name === 't_trigger__c'
    );
    this.additionalFields = this.additionalFields.concat(singleField);


    arrayOfGreenFieldNames.forEach(field => {
        const singleField = this.categoryAdditionalFields.filter(
          (entry) => entry.name === field
        );
        this.additionalFields = this.additionalFields.concat(singleField);
      }
    );
    this.additionalFields = this.additionalFields.sort((a, b) => a.sortNumber - b.sortNumber);
  }

  updateNumberFieldValue(newValue): any {
    const fieldName = newValue.title;

    if (fieldName === 'temp_c__c') {
      this.singleEndpointRecord[fieldName] = newValue.value;
    } else {
      if (newValue.value < 0) {
        this.singleEndpointRecord[fieldName] = '';
        this.selectFormGroup.get(fieldName).setValue('');
      } else {
        this.singleEndpointRecord[fieldName] = newValue.value;
      }
    }
  }

  copyEndpoint(singleEndpointRecord: ToxEndpointTableItem): void {
    this.copyInput.emit(singleEndpointRecord);
  }

  updateMultiPicklistFieldValue(newValue, fieldName): void {
    this.singleEndpointRecord[fieldName] = newValue.value.toString();
  }

  prepareRecord(): void {
    this.setGroupValues();
    this.setAdditionalFields();
    this.setFormFields();
  }

  setGroupValues(): any {
    if (this.singleEndpointRecord.study_type__c === 'erbindingactivation__c' ||
      this.singleEndpointRecord.study_type__c === 'arbindingactivation__c' ||
      this.singleEndpointRecord.study_type__c === 'steroidogenesis__c' ||
      this.singleEndpointRecord.study_type__c === 'aromatase_assay__c' ||
      this.singleEndpointRecord.study_type__c === 'nis__c' ||
      this.singleEndpointRecord.study_type__c === 'thyroid_receptor_binding__c' ||
      this.singleEndpointRecord.study_type__c === 'iodine_discharge_assay__c' ||
      this.singleEndpointRecord.study_type__c === 'diodinase_assay__c' ||
      this.singleEndpointRecord.study_type__c === 'other_in_vitro_assay__c'
    )
    {
      // filter Test System Types
      const dependedTestSystemTypes = ['endocrine_disruption_in_vitro__c'];
      dependedTestSystemTypes.forEach(record => {
          const singleRecord = this.allTestSystemTypes.filter(
            (entry) => entry.name === record
          );
          this.testSystemTypes = this.testSystemTypes.concat(singleRecord);
        }
      );
      // filter Test System Names
      const dependedTestSystemNames = ['in_vitro_cell_culture__c'];
      dependedTestSystemNames.forEach(record => {
          const singleRecord = this.allTestSystemNames.filter(
            (entry) => entry.name === record
          );
          this.testSystemNames = this.testSystemNames.concat(singleRecord);
        }
      );
      // filter Route Of Administration
      const dependedRouteOfAdministration = ['cell_culture__c'];
      dependedRouteOfAdministration.forEach(record => {
          const singleRecord = this.allRouteOfAdministration.filter(
            (entry) => entry.name === record
          );
          this.routeOfAdministration = this.routeOfAdministration.concat(singleRecord);
        }
      );
      this.studyTypeColor = 'orange';
    }
    else if (this.singleEndpointRecord.study_type__c === 'hershberger__c' ||
      this.singleEndpointRecord.study_type__c === 'uerotrophic__c' ||
      this.singleEndpointRecord.study_type__c === 'malefemale_pubertal__c' ||
      this.singleEndpointRecord.study_type__c === '2generation__c' ||
      this.singleEndpointRecord.study_type__c === 'eogrts__c' ||
      this.singleEndpointRecord.study_type__c === 'developmental_thyroid__c'
    )
    {
      // filter Test System Types
      const dependedTestSystemTypes = ['endocrine_disruption_in_vivo__c'];
      dependedTestSystemTypes.forEach(record => {
          const singleRecord = this.allTestSystemTypes.filter(
            (entry) => entry.name === record
          );
          this.testSystemTypes = this.testSystemTypes.concat(singleRecord);
        }
      );
      // filter Test System Names
      const dependedTestSystemNames = ['mouse__c', 'rat__c'];
      dependedTestSystemNames.forEach(record => {
          const singleRecord = this.allTestSystemNames.filter(
            (entry) => entry.name === record
          );
          this.testSystemNames = this.testSystemNames.concat(singleRecord);
        }
      );
      // filter Route Of Administration
      const dependedRouteOfAdministration = ['oral_gavage__c', 'oral_diet__c', 'oral_drinking_water__c', 'oral_capsule__c'];
      dependedRouteOfAdministration.forEach(record => {
          const singleRecord = this.allRouteOfAdministration.filter(
            (entry) => entry.name === record
          );
          this.routeOfAdministration = this.routeOfAdministration.concat(singleRecord);
        }
      );
      this.studyTypeColor = 'yellow';
    }
  }

  setAdditionalFields(): any {
    if (this.singleEndpointRecord.value_3__c === 'yes' || this.singleEndpointRecord.value_3__c === 'equivocal') {
      if (this.singleEndpointRecord.test_system_type__c === 'endocrine_disruption_in_vivo__c') {
        const dependedAdditionalFields = ['ed_modality__c', 'target_organ__c', 'eats_and_developmental_parameters_affect__c'];
        this.addSpecifiedFields(dependedAdditionalFields);
      }
      else {
        const dependedAdditionalFields = ['ed_modality__c'];
        this.addSpecifiedFields(dependedAdditionalFields);
      }
      if (this.singleEndpointRecord.ed_modality__c === 'tmodality__c') {
        const dependedAdditionalFields = ['mechanism_tmodality__c'];
        this.addSpecifiedFields(dependedAdditionalFields);
      }
    }
  }

  setFormFields(): void {
    this.selectFormGroup.get('substanceType').setValue(this.singleEndpointRecord.substance_type__c);
    this.selectFormGroup.get('relatedSubstance').setValue(this.singleEndpointRecord.related_substance__c);
    this.selectFormGroup.get('studyType').setValue(this.singleEndpointRecord.study_type__c);
    this.selectFormGroup.get('test_system_type__c').setValue(this.singleEndpointRecord.test_system_type__c);
    this.selectFormGroup.get('route_of_administration__c').setValue(this.singleEndpointRecord.route_of_administration__c);
    this.selectFormGroup.get('test_system_name__c').setValue(this.singleEndpointRecord.test_system_name__c);
    this.selectFormGroup.get('endpoint').setValue(this.singleEndpointRecord.endpoint__c);
    this.selectFormGroup.get('value_3__c').setValue(this.singleEndpointRecord.value_3__c);
    // additional fields
    this.selectFormGroup.get('ed_modality__c').setValue(this.singleEndpointRecord.ed_modality__c);
    this.selectFormGroup.get('mechanism_tmodality__c').setValue(this.singleEndpointRecord.mechanism_tmodality__c);
    if (this.singleEndpointRecord.target_organ__c) {
      this.selectFormGroup.get('target_organ__c').setValue(this.singleEndpointRecord.target_organ__c.split(','));
    }
    if (this.singleEndpointRecord.eats_and_developmental_parameters_affect__c) {
      this.selectFormGroup.get('eats_and_developmental_parameters_affect__c').setValue(this.singleEndpointRecord.eats_and_developmental_parameters_affect__c.split(','));
    }
    this.selectFormGroup.get('t_trigger__c').setValue(this.singleEndpointRecord.t_trigger__c);
    // default
    this.selectFormGroup.get('selectedDocument').setValue(this.singleEndpointRecord.document_unbound__c);
  }

  cancelEditing(endpointRecord): void {
    this.cancelEdit.emit(endpointRecord);
  }

  saveEndpointChanges(endpointRecord): void {
    this.saveChanges.emit(endpointRecord);
  }

}
