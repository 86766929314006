<div class="endpointContent">
  <div class="endpointCategoryTitle">
    <button (click)="addNewEndpoint({})" class="buttons" data-cy="addNew"
            style="font-family: Arial, Helvetica, sans-serif; color: #3781b5; background-color: #e4ebef; margin-left: 5px;">
      + Add New
    </button>
    {{ category["name__v"]}}
  </div>
  <div [ngSwitch]="category['name__v']">
    <div *ngSwitchCase="'Metabolism'">
      <app-metabolism
        (copyInput)="copyEndpointInput($event)"
        (removeInput)="removeEndpointInput($event)"
        *ngFor="let endpoint of categoryEndpointsArrayList"
        [allAdditionalFieldValues]="receivedAdditionalFieldValues"
        [allAdditionalFields]="categoryAdditionalFields"
        [allStudyTypes]="categoryStudyTypes"
        [allTestSystemNames]="allTestSystemNames"
        [allTestSystemTypes]="allTestSystemTypes"
        [categoryPicklists]="filteredPicklists"
        [category]="category"
        [documents]="documents"
        [relatedSubstances]="relatedSubstances"
        [singleEndpointRecord]="endpoint"
        [substanceTypes]="substanceTypes"
        [substance]="substance"
        [singleRecordDependencies]="categorySingleRecordDependencies"
        [identifiedMetabolites]="identifiedMetabolites"
      ></app-metabolism>
    </div>

    <div *ngSwitchCase="'Nature of Residues'">
      <app-nature-of-residues
        (copyInput)="copyEndpointInput($event)"
        (removeInput)="removeEndpointInput($event)"
        *ngFor="let endpoint of categoryEndpointsArrayList"
        [allAdditionalFieldValues]="receivedAdditionalFieldValues"
        [allAdditionalFields]="categoryAdditionalFields"
        [allStudyTypes]="categoryStudyTypes"
        [allTestSystemNames]="allTestSystemNames"
        [allTestSystemTypes]="allTestSystemTypes"
        [categoryPicklists]="filteredPicklists"
        [category]="category"
        [documents]="documents"
        [relatedSubstances]="relatedSubstances"
        [singleEndpointRecord]="endpoint"
        [substance]="substance"
        [singleRecordDependencies]="singleRecordDependencies"
        [identifiedMetabolites]="identifiedMetabolites"
      ></app-nature-of-residues>
    </div>

    <div *ngSwitchCase="'Residue Definition'">
      <app-residue-definition
        (copyInput)="copyEndpointInput($event)"
        (removeInput)="removeEndpointInput($event)"
        *ngFor="let endpoint of categoryEndpointsArrayList"
        [allAdditionalFieldValues]="receivedAdditionalFieldValues"
        [allAdditionalFields]="categoryAdditionalFields"
        [allStudyTypes]="categoryStudyTypes"
        [allTestSystemNames]="allTestSystemNames"
        [allTestSystemTypes]="allTestSystemTypes"
        [category]="category"
        [documents]="documents"
        [relatedSubstances]="relatedSubstances"
        [singleEndpointRecord]="endpoint"
        [substanceTypes]="substanceTypes"
        [substance]="substance"
      ></app-residue-definition>
    </div>

    <div *ngSwitchCase="'Stability of Residues'">
      <app-stability-of-residues
        (copyInput)="copyEndpointInput($event)"
        (removeInput)="removeEndpointInput($event)"
        *ngFor="let endpoint of categoryEndpointsArrayList"
        [singleRecordDependencies]="singleRecordDependencies"
        [allAdditionalFieldValues]="receivedAdditionalFieldValues"
        [allAdditionalFields]="categoryAdditionalFields"
        [allStudyTypes]="categoryStudyTypes"
        [allTestSystemNames]="allTestSystemNames"
        [allTestSystemTypes]="allTestSystemTypes"
        [categoryPicklists]="filteredPicklists"
        [category]="category"
        [documents]="documents"
        [relatedSubstances]="relatedSubstances"
        [singleEndpointRecord]="endpoint"
        [substanceTypes]="substanceTypes"
        [substance]="substance"
      ></app-stability-of-residues>
    </div>

    <div *ngSwitchCase="'Magnitude of Residue'">
      <app-magnitude-of-residue
       (copyInput)="copyEndpointInput($event)"
        (removeInput)="removeEndpointInput($event)"
        *ngFor="let endpoint of categoryEndpointsArrayList"
        [allAdditionalFieldValues]="receivedAdditionalFieldValues"
        [allAdditionalFields]="categoryAdditionalFields"
        [allStudyTypes]="categoryStudyTypes"
        [allTestSystemNames]="allTestSystemNames"
        [allTestSystemTypes]="allTestSystemTypes"
        [categoryPicklists]="filteredPicklists"
        [category]="category"
        [documents]="documents"
        [relatedSubstances]="relatedSubstances"
        [singleEndpointRecord]="endpoint"
        [substanceTypes]="substanceTypes"
        [substance]="substance"
        [singleRecordDependencies]="categorySingleRecordDependencies"
        [analyteNames]="analyteNames"
        [substanceAsAnalyteNames]="substanceAsAnalyteNames"
      ></app-magnitude-of-residue>
    </div>

    <div *ngSwitchCase="'Dietary Risk Assessment'">
      <app-dietary-risk-assessment
        (copyInput)="copyEndpointInput($event)"
        (removeInput)="removeEndpointInput($event)"
        *ngFor="let endpoint of categoryEndpointsArrayList"
        [allAdditionalFieldValues]="receivedAdditionalFieldValues"
        [allAdditionalFields]="categoryAdditionalFields"
        [allStudyTypes]="categoryStudyTypes"
        [allTestSystemNames]="allTestSystemNames"
        [allTestSystemTypes]="allTestSystemTypes"
        [categoryPicklists]="filteredPicklists"
        [category]="category"
        [documents]="documents"
        [relatedSubstances]="relatedSubstances"
        [singleEndpointRecord]="endpoint"
        [substanceTypes]="substanceTypes"
        [substance]="substance"
        [singleRecordDependencies]="categorySingleRecordDependencies"
      ></app-dietary-risk-assessment>
    </div>

    <div *ngSwitchCase="'Waiver & Data Gaps'">
      <app-waiver-and-data-gaps
        (copyInput)="copyEndpointInput($event)"
        (removeInput)="removeEndpointInput($event)"
        *ngFor="let endpoint of categoryEndpointsArrayList"
        [allStudyTypes]="categoryStudyTypes"
        [categoryPicklists]="filteredPicklists"
        [category]="category"
        [documents]="documents"
        [relatedSubstances]="relatedSubstances"
        [singleEndpointRecord]="endpoint"
        [substanceTypes]="substanceTypes"
        [substance]="substance"
      ></app-waiver-and-data-gaps>
    </div>
  </div>

  <div class="orangeLine"></div>
</div>
