export class DocumentMenuItem {
  id: string;
  name: string;
  majorVersionNumber: number;
  minorVersionNumber: number;

  constructor(
    id: string = '',
    name: string = '',
    majorVersionNumber: number = 0,
    minorVersionNumber: number = 1
  ) {
    this.id = id;
    this.name = name;
    this.majorVersionNumber = majorVersionNumber;
    this.minorVersionNumber = minorVersionNumber;
  }
}
