<div class="dialog-wrapper">
  <h2 class="title" mat-dialog-title>Sharing Active Ingredient Set</h2>
  <div mat-dialog-content>
    <mat-form-field appearance="fill" class="full-width">
      <mat-label>Set Name</mat-label>
      <input matInput required [(ngModel)]="data.setName">
    </mat-form-field>
    <div [hidden]="data.setName.length > 0"
         class="ui-icon-alert">
      Name is required
    </div>
    <p>Select users to share set:</p>
      <ng-select class="substance-search"
                 appearance="fill"
                 [items]="users"
                 bindLabel="user_name__v"
                 bindValue="id"
                 appendTo="body"
                 [multiple]="true"
                 [closeOnSelect]="false"
                 [(ngModel)]="selectedUsers"
                 (ngModelChange)="updateSets()">
      </ng-select>

    <div mat-dialog-actions class="dialog-buttons">
      <button (click)="cancel()" class="cancel-button" mat-button>Cancel</button>
      <button [mat-dialog-close]="setsToShare" class="confirm-button" mat-button>Ok</button>
    </div>
  </div>
</div>
