<div class="endpointContent">
  <div class="endpointCategoryTitle">
    <button (click)="addNewEndpoint({})" class="buttons" data-cy="addNew"
            style="font-family: Arial, Helvetica, sans-serif; color: #3781b5; background-color: #e4ebef; margin-left: 5px;">
      + Add New
    </button>
    {{ category["name__v"]}}

  </div>
  <app-ecotox-record
    (copyInput)="copyEndpointInput($event)"
    (removeInput)="removeEndpointInput($event)"
    *ngFor="let endpoint of categoryEndpointsArrayList"
    [allAdditionalFieldValues]="receivedAdditionalFieldValues"
    [allAdditionalFields]="receivedAllAdditionalFields"
    [allEndpointUnits]="allEndpointUnits"
    [allEndpoints]="allEndpoints"
    [allStudyTypes]="defaultAllStudyTypes"
    [allTestGuidelines]="allTestGuidelines"
    [allTestSpecies]="allTestSpecies"
    [allTestSystemTypes]="allTestSystemTypes"
    [categoryPicklists]="filteredPicklists"
    [category]="category"
    [documents]="documents"
    [operators]="operators"
    [relatedSubstances]="relatedSubstances"
    [singleEndpointRecord]="endpoint"
    [substanceTypes]="substanceTypes"
    [testGuidelinePicklists]="testGuidelinePicklists"
  >
  </app-ecotox-record>
  <div class="orangeLine"></div>
</div>
