import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {ToastrService} from 'ngx-toastr';
import {ServerService} from './core/server.service';
import {environment} from '../environments/environment';
import {ActivatedRoute, ParamMap, Router,} from "@angular/router";
import {NavItem} from './core/navigation/models/nav-item';
import {menu} from './core/navigation/models/menu';
import {map, Observable,} from "rxjs";
import {NavigationService} from "./core/navigation/navigation.service";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit {
  subscriptions: any;
  isHidden = true;
  isProduction = environment.production;
  version: string;
  userName$: Observable<string>;
  userMail$: Observable<string>;
  userId$: Observable<string>;
  sessionId$: Observable<string>;
  menu: NavItem[] = menu;
  title: string = 'crystal-demo';
  url: string;
  isHeaderVisible: boolean;


  constructor(
    private serverService: ServerService,
    private toastrService: ToastrService,
    private navService: NavigationService,
    private changeDetectorRef: ChangeDetectorRef,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.subscriptions = {};
    this.subscriptions.product = this.serverService.isLoading$.subscribe(
      (result) => {
        this.isHidden = !result;
        this.changeDetectorRef.detectChanges();
      },
      (error) => {
        this.toastrService.error('An error occurred while loading.');
        console.error(error);
      }
    );

  }

  ngOnInit() {
    this.navService.currentUrl.subscribe(url => {
      this.url = url;
    })
    this.sessionId$ = this.getQueryParam('sessionId');
    this.sessionId$.subscribe(result => {
      if (result != null) {
        this.saveParamToSession(result, 'sessionId');
      }
    })
    this.userId$ = this.getQueryParam('userId');
    this.userId$.subscribe(result => {
      if (result != null) {
        this.saveParamToSession(result, 'userId');
      }
    })
    this.userMail$ = this.getQueryParam('mail');
    this.userMail$.subscribe(result => {
      if (result != null) {
        this.saveParamToSession(result, 'userMail');
      }
    })
    this.userName$ = this.getQueryParam('userName');
    this.userName$.subscribe(result => {
      if (result != null) {
        this.saveParamToSession(result, 'userName');
      }
    })
    // if ((sessionStorage.getItem('sessionId') && sessionStorage.getItem('userName')
    //   && sessionStorage.getItem('userId') && sessionStorage.getItem('userMail')) != null) {
    //   this.navigateToHome();
    // }
    this.getEndpointData();
  }


  getQueryParam(key: string) {
    return this.route.queryParamMap.pipe(
      map((params: ParamMap) => params.get(key))
    );
  }

  saveParamToSession(param: string, key: string): void {
    sessionStorage.setItem(key, param);
  }


  getEndpointData() {
    this.serverService.getRecordCollection('poc_active_ingredient__c').subscribe(
      response => {
        this.menu[4].child.items = response;
      }
    )
  }

  checkHeaderVisibility(): boolean {
    if (this.url != undefined) {
      return this.url.indexOf('home') !== -1;
    }
  }
}
