import {Component, ElementRef, Input, OnChanges, OnInit, ViewChild, } from '@angular/core';
import {PicklistValue} from '../../models/picklist-value';
import {DocumentMenuItem} from '../../models/document-menu-item';
import {SubstanceItem} from '../../models/substance-item';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {ActivatedRoute} from '@angular/router';
import {ServerService} from '../../../core/server.service';
import {ExceptionService} from '../../../core/exception.service';
import {ToastrService} from 'ngx-toastr';
import {HttpErrorResponse} from '@angular/common/http';
import {MatTableDataSource} from '@angular/material/table';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {Observable} from 'rxjs';
import {MatAutocomplete, MatAutocompleteSelectedEvent, } from '@angular/material/autocomplete';
import {PageEvent} from '@angular/material/paginator';
import {map, startWith} from 'rxjs/operators';
import {MatChipInputEvent} from '@angular/material/chips';
import {RelatedSubstances} from '../../models/related-substances';
import {PhysChemDataCategoryPicklists} from '../models/phys-chem-data-category-picklists';
import {PhysChemDataAdditionalField} from '../models/phys-chem-data-additional-field';
import {PhysChemDataExisting} from '../models/phys-chem-data-existing';
import {PhysChemDataExistingHeader} from '../models/phys-chem-data-existing-header';
import {addNewValuesToPicklists} from '../../../shared/add-new-values-to-picklists';

@Component({
  selector: 'app-phys-chem-data-category-existing',
  templateUrl: './phys-chem-data-category-existing.component.html',
  styleUrls: ['./phys-chem-data-category-existing.component.css'],
})

export class PhysChemDataCategoryExistingComponent
  implements OnInit, OnChanges {
  dataSource: MatTableDataSource<any>;
  data: Array<any>;
  pageSize: number;
  pageSkip: number;
  dataSourceLength: number;

  public loaderHidden: boolean;
  public searchButtonHidden: boolean;

  // for filters
  visible = true;
  selectable = true;
  removable = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  fieldCtrl = new FormControl();
  filteredFields: Observable<string[]>;
  fields: string[] = ['Category', 'Related Substance'];
  public allFields: string[];

  @ViewChild('fieldInput') fieldInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto') matAutocomplete: MatAutocomplete;

  /**
   * for documents filtering
   */
  searchUserModifyControl: FormControl = new FormControl();
  searchUserCreateControl: FormControl = new FormControl();
  filteredCreateUsers: Observable<any>;
  filteredModifiedUsers: Observable<any>;
  /** */

  subscriptions: any;
  public panelOpenState: boolean;
  public expandPanel: boolean;
  public header: PhysChemDataExistingHeader;

  @Input() receivedCategoryPicklists: Array<PhysChemDataCategoryPicklists>;
  @Input() workAreaName: string;
  @Input() workAreaId: string;
  @Input() substance: SubstanceItem;
  @Input() receivedCategories: Array<PicklistValue>;
  @Input() receivedAllEndpoints: Array<PicklistValue>;
  @Input() receivedAllEndpointUnits: Array<PicklistValue>;
  @Input() receivedAllOperators: Array<PicklistValue>;
  @Input() receivedAllTestSystemTypes: Array<PicklistValue>;
  @Input() receivedAllAdditionalFields: Array<PhysChemDataAdditionalField>;
  @Input() substanceTypes: Array<PicklistValue>;
  @Input() relatedSubstances: Array<RelatedSubstances>;
  @Input() documents: Array<DocumentMenuItem>;
  public existingEndpoints: Array<PhysChemDataExisting>;
  public allUsers: any;
  public queryStringConstructor: any;
  public queryStringClause: any;
  public sortByFieldName: string;
  public sortByOrder: string;

  // created date range
  createdRange = new FormGroup({
    createdDateStart: new FormControl(),
    createdDateEnd: new FormControl(),
  });
  // modified date range
  modifiedRange = new FormGroup({
    modifiedDateStart: new FormControl(),
    modifiedDateEnd: new FormControl(),
  });
  pageEvent: PageEvent;
  public uniqueTestSystemTypes: Array<PicklistValue>;
  public uniqueEndpoints: Array<PicklistValue>;
  public uniqueEndpointUnits: Array<PicklistValue>;
  public All: string;
  private sessionId: string;

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private serverService: ServerService,
    private exceptionService: ExceptionService,
    private toastrService: ToastrService,
    private propagateNewData: addNewValuesToPicklists
  ) {
    this.route.paramMap.subscribe((params) => {
      this.sessionId = params.get('sessionId');
    });
    this.existingEndpoints = [];
    this.uniqueTestSystemTypes = [];
    this.uniqueEndpoints = [];
    this.uniqueEndpointUnits = [];
    this.sortByFieldName = 'id';
    this.sortByOrder = 'DESC';
    this.allUsers = [];
    this.All = 'All';
    this.queryStringConstructor = [];
    this.queryStringClause = '';
    this.dataSource = new MatTableDataSource();
    this.pageSize = 10;
    this.pageSkip = 0;
    this.dataSourceLength = 0;
    this.header = {
      category__c: '',
      substance_type__c: '',
      related_substance__c: '',
      endpoint__c: '',
      endpoint_unit__c: '',
      test_system_type__c: '',
      operator__c: '',
      value_1__c: null,
      value_2__c: null,
      value_3__c: '',
      // additional fields
      ph__c: '',
      purity__c: '',
      temp_c__c: null,
      comment__c: '',
      selectedDocumentId: '',
      created_date_start: null,
      created_date_end: null,
      modified_date_start: null,
      modified_date_end: null,
      created_by__v: '',
      modified_by__v: '',
    };

    /// for filters
    this.allFields = [
      'Category',
      'Related Substance',
      'Substance Type',
      'Created Date Range',
      'Modified Date Range',
      'Source',
      'Created By',
      'Last Modified By',
      'Endpoint',
      'Endpoint Unit',
      'Test System Type',
      'Operator',
      'Value 1',
      'Value 2',
      'Temp. °C',
    ];

    this.filteredFields = this.fieldCtrl.valueChanges.pipe(
      startWith(<string>null),
      map((field: string | null) =>
        field ? this._filter(field) : this.allFields.slice()
      )
    );
  }

  ngOnInit(): void {
    this.retrieveAllUsers();
    this.queryStringClause = 'work_area__c = ' + '\'' + this.workAreaId + '\'';

    this.propagateNewData.arrayDataEmitter.subscribe((arrayNamePlusData) => {
      if (arrayNamePlusData[0] === 'endpoint') {
        arrayNamePlusData[1].forEach((picklistValue) => {
          if (this.receivedAllEndpoints.includes(picklistValue)) {
            console.log('already included: ' + JSON.stringify(picklistValue));
          }
          else {
           this.receivedAllEndpoints.push(picklistValue);
           console.log('added: ' + JSON.stringify(picklistValue));
          }
        });
      }
      else if (arrayNamePlusData[0] === 'endpointUnit') {
        arrayNamePlusData[1].forEach((picklistValue) => {
          if (this.receivedAllEndpointUnits.includes(picklistValue)) {
            console.log('already included: ' + JSON.stringify(picklistValue));
          }
          else {
           this.receivedAllEndpointUnits.push(picklistValue);
           console.log('added: ' + JSON.stringify(picklistValue));
          }
        });
      }
      else if (arrayNamePlusData[0] === 'testSystemType') {
        arrayNamePlusData[1].forEach((picklistValue) => {
          if (this.receivedAllTestSystemTypes.includes(picklistValue)) {
            console.log('already included: ' + JSON.stringify(picklistValue));
          }
          else {
           this.receivedAllTestSystemTypes.push(picklistValue);
           console.log('added: ' + JSON.stringify(picklistValue));
          }
        });
      }
    });
  }


  ngOnChanges(): any {
    this.makeUniqueEndpoints();
    this.makeUniqueEndpointUnits();
    this.makeUniqueTestSystemTypes();
  }

  makeUniqueTestSystemTypes(): any {
    const source = this.receivedAllTestSystemTypes;
    const result = this.uniqueTestSystemTypes;

    const testSystemTypeMap = new Map();
    for (const item of source) {
      if (!testSystemTypeMap.has(item.name)) {
        testSystemTypeMap.set(item.name, true);
        result.push({
          name__v: '',
          id: '',
          label: item.label,
          name: item.name,
          picklist: item.picklist,
        });
      }
    }
  }

  makeUniqueEndpoints(): any {
    const source = this.receivedAllEndpoints;

    const endpointMap = new Map();
    for (const item of source) {
      if (!endpointMap.has(item.name)) {
        endpointMap.set(item.name, true);
        this.uniqueEndpoints.push({
          name__v: '',
          id: '',
          label: item.label,
          name: item.name,
          picklist: item.picklist,
        });
      }
    }
  }

  makeUniqueEndpointUnits(): any {
    const source = this.receivedAllEndpointUnits;
    const result = this.uniqueEndpointUnits;

    const endpointUnitMap = new Map();
    for (const item of source) {
      if (!endpointUnitMap.has(item.name)) {
        endpointUnitMap.set(item.name, true);
        result.push({
          name__v: '',
          id: '',
          label: item.label,
          name: item.name,
          picklist: item.picklist,
        });
      }
    }
  }

  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    // Add field
    if ((value || '').trim()) {
      this.fields.push(value.trim());
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }

    this.fieldCtrl.setValue(null);
  }

  remove(field: string): void {
    // remove filter form
    document
      .getElementById('preparedFilterFields')
      .appendChild(document.getElementById(field));

    // remove chip
    const index = this.fields.indexOf(field);
    if (index >= 0) {
      this.fields.splice(index, 1);
    }

    // remove from array IF something was selected
    const queryIndex = this.queryStringConstructor
      .map((e) => {
        return e.fieldLabel;
      })
      .indexOf(field);
    if (queryIndex >= 0) {
      this.queryStringConstructor.splice(queryIndex, 1);
    }

    // reset form selected value
    if (field === 'Category') {
      this.header.category__c = '';
    } else if (field === 'Substance Type') {
      this.header.substance_type__c = '';
    } else if (field === 'Related Substance') {
      this.header.related_substance__c = '';
    } else if (field === 'Endpoint') {
      this.header.endpoint__c = '';
    } else if (field === 'Endpoint Unit') {
      this.header.endpoint_unit__c = '';
    } else if (field === 'Operator') {
      this.header.operator__c = '';
    } else if (field === 'Value 1') {
      this.header.value_1__c = null;
    } else if (field === 'Value 2') {
      this.header.value_2__c = null;
    } else if (field === 'Test System Type') {
      this.header.test_system_type__c = '';
    } else if (field === 'Created By') {
      this.header.created_by__v = '';
    } else if (field === 'Last Modified By') {
      this.header.modified_by__v = '';
    } else if (field === 'Source') {
      this.header.selectedDocumentId = null;
    }

    // number fields
    else if (field === 'Temp. °C') {
      this.header.temp_c__c = null;
    } else if (field === 'Created Date Range') {
      this.createdRange.value.createdDateStart = null;
      this.createdRange.value.createdDateEnd = null;
      this.header.created_date_start = null;
      this.header.created_date_end = null;
    } else if (field === 'Modified Date Range') {
      this.modifiedRange.value.modifiedDateStart = null;
      this.modifiedRange.value.modifiedDateEnd = null;
      this.header.modified_date_start = null;
      this.header.modified_date_end = null;
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.fields.push(event.option.viewValue);
    this.fieldInput.nativeElement.value = '';
    this.fieldCtrl.setValue(null);

    document
      .getElementById('filterFields')
      .appendChild(document.getElementById(event.option.viewValue));
  }

  getEndpointsWithFilters(limit, offset): void {
    this.loaderHidden = false;
    this.searchButtonHidden = true;
    this.serverService.isLoading();

    let createdDateStart = JSON.stringify(
      this.createdRange.value.createdDateStart
    );
    createdDateStart = createdDateStart.replace('"', '');
    createdDateStart = createdDateStart.replace('"', '');
    let createdDateEnd = JSON.stringify(this.createdRange.value.createdDateEnd);
    createdDateEnd = createdDateEnd.replace('"', '');
    createdDateEnd = createdDateEnd.replace('"', '');

    let modifiedDateStart = JSON.stringify(
      this.modifiedRange.value.modifiedDateStart
    );
    modifiedDateStart = modifiedDateStart.replace('"', '');
    modifiedDateStart = modifiedDateStart.replace('"', '');
    let modifiedDateEnd = JSON.stringify(
      this.modifiedRange.value.modifiedDateEnd
    );
    modifiedDateEnd = modifiedDateEnd.replace('"', '');
    modifiedDateEnd = modifiedDateEnd.replace('"', '');

    // reset condition clauses
    this.queryStringClause =
      'work_area__c = ' +
      '\'' +
      this.workAreaId +
      '\' AND substance_name__c = ' +
      '\'' +
      this.substance.id +
      '\'';

    // unpack conditions array and construct query
    this.queryStringConstructor.forEach((condition: any) => {
      const singleCondition =
        ' AND ' +
        condition.fieldName +
        ' = ' +
        '\'' +
        condition.fieldValue +
        '\'';
      this.queryStringClause = this.queryStringClause + singleCondition;
    });

    if (createdDateStart !== 'null' && createdDateEnd !== 'null') {
      this.queryStringClause =
        this.queryStringClause +
        ' AND created_date__v BETWEEN \'' +
        createdDateStart +
        '\'' +
        ' AND \'' +
        createdDateEnd +
        '\'';
    }

    if (modifiedDateStart !== 'null' && modifiedDateEnd !== 'null') {
      this.queryStringClause =
        this.queryStringClause +
        ' AND modified_date__v BETWEEN \'' +
        modifiedDateStart +
        '\'' +
        ' AND \'' +
        modifiedDateEnd +
        '\'';
    }

    this.queryStringClause =
      this.queryStringClause +
      ' ORDER BY ' +
      this.sortByFieldName +
      ' ' +
      this.sortByOrder +
      ' limit ' +
      limit +
      ' offset ' +
      offset;
    this.subscriptions = this.serverService
      .filterEndpointRecords(
        this.sessionId,
        this.queryStringClause,
        this.workAreaName
      )
      .subscribe(
        (response) => {
          // reset current list
          this.existingEndpoints = [];
          if (response.data && response.data.length > 0) {
            response.data.forEach((it) => this.existingEndpoints.push(it));
            this.dataSourceLength = response.responseDetails.total;
          }
        },
        (error: HttpErrorResponse) => {
          this.exceptionService.handleError(error);
          this.toastrService.error('Unable to get Endpoints.', 'Error');
        },
        () => {
          this.serverService.isNotLoading();
          this.loaderHidden = true;
          this.searchButtonHidden = false;
        }
      );
  }

  retrieveAllUsers(): any {
    this.subscriptions = this.serverService
      .getAllUsers(this.sessionId)
      .subscribe(
        (response) => {
          if (response.responseStatus === 'SUCCESS') {
            response.data.forEach((user: any) => {
              const entry = {
                id: user.id,
                name: user.user_first_name__v + ' ' + user.user_last_name__v,
              };
              this.allUsers.push(entry);

              this.filteredCreateUsers = this.searchUserCreateControl.valueChanges.pipe(
                startWith(''),
                map((val) => this.filter(val))
              );

              this.filteredModifiedUsers = this.searchUserModifyControl.valueChanges.pipe(
                startWith(''),
                map((val) => this.filter(val))
              );
            });
          }
        },
        (error: HttpErrorResponse) => {
          this.exceptionService.handleError(error);
          this.toastrService.error(`Unable to get users.`, 'Error');
        }
      );
  }

  /**
   * used for document search (filtering based on user input)
   * @param val = user text field input
   */
  filter(val): any {
    if (this.allUsers) {
      return this.allUsers.filter((option) =>
        option.name.toLowerCase().includes(val)
      );
    }
  }

  picklistValueToArray(name, label, value): void {
    const fieldValuePair = {
      fieldName: name,
      fieldLabel: label,
      fieldValue: value,
    };

    // remove from array if something was selected, we need to replace old value with new
    const queryIndex = this.queryStringConstructor
      .map((e) => {
        return e.fieldLabel;
      })
      .indexOf(label);
    if (queryIndex >= 0) {
      this.queryStringConstructor.splice(queryIndex, 1);
    }

    // add selected condition to array
    if (value !== '') {
      this.queryStringConstructor.push(fieldValuePair);
    }
  }

  textFieldValueToArray(name: string, label: string): void {
    const value = (document.getElementById(name) as HTMLInputElement).value;
    this.picklistValueToArray(name, label, value);
  }

  defineSortOrder(): any {
    if (
      this.sortByFieldName === 'id' ||
      this.sortByFieldName === 'related_substance__c'
    ) {
      this.sortByOrder = 'DESC';
    } else {
      this.sortByOrder = 'ASC';
    }
    this.getEndpointsWithFilters(this.pageSize, 0);
  }

  filterAdditionalFieldValues(
    allAdditionalFieldValues: Array<PhysChemDataAdditionalField>,
    filterWord: string
  ): Array<PhysChemDataAdditionalField> {
    return allAdditionalFieldValues.filter(
      (item) => item.picklist === filterWord
    );
  }

  destroyEndpoints(): any {
    this.existingEndpoints = [];
    // reset filter values (UI)
  }

  onPaginateChange($event: PageEvent): void {
    const page = $event.pageIndex;
    const size = $event.pageSize;
    this.pageSize = $event.pageSize;
    const skip = page * size;
    this.pageSkip = skip;

    this.getEndpointsWithFilters(size, skip);
  }

  reduceCount(): void {
    this.dataSourceLength = this.dataSourceLength - 1;
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.allFields.filter(
      (field) => field.toLowerCase().indexOf(filterValue) === 0
    );
  }
}
