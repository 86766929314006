import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SpiderRoutingModule} from './spider-routing.module';
import {SpiderPageComponent} from './spider-page/spider-page.component';
import {FormsModule} from '@angular/forms';
import {SharedModule} from '../shared/shared.module';

@NgModule({
  declarations: [SpiderPageComponent],
  imports: [CommonModule, SpiderRoutingModule, SharedModule, FormsModule],
  providers: [],
})
export class SpiderModule {
}
