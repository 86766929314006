<div>
  <app-consumer-safety-existing *ngIf="loadingDataHidden"
                                [documents]="documents"
                                [receivedAdditionalFieldValues]="additionalFieldValues"
                                [receivedAllAdditionalFields]="additionalFields"
                                [receivedAllStudyTypes]="allStudyTypes"
                                [receivedAllTestSystemNames]="allTestSystemNames"
                                [receivedAllTestSystemTypes]="allTestSystemTypes"
                                [receivedCategories]="categories"
                                [receivedCategoryPicklists]="consumerSafetyCategoryPicklists"
                                [relatedSubstances]="relatedSubstances"
                                [substanceTypes]="substanceTypes"
                                [substance]="substance"
                                [workAreaId]="workAreaId"
                                [workAreaName]="workAreaLabel"
                                [singleRecordDependencies]="singleRecordDependencies"
                                [identifiedMetabolites]="identifiedMetabolites"
                                [analyteNames]="analyteNames"
                                [substanceAsAnalyteNames]="substanceAsAnalyteNames"
                                [singleRecordDependenciesModelAdditionalFieldValues]="singleRecordDependenciesModelAdditionalFieldValues"
  ></app-consumer-safety-existing>

  <div id="categoryHolder">
    <app-consumer-safety-category #consumerSafetyCategory *ngFor="let consumerCategories of categories"
                                  [category]="consumerCategories"
                                  [dataPicklists]="consumerSafetyCategoryPicklists"
                                  [documents]="documents"
                                  [endpointsArrayList]="endpointsArrayList"
                                  [receivedAdditionalFieldValues]="additionalFieldValues"
                                  [receivedAllAdditionalFields]="additionalFields"
                                  [receivedAllStudyTypes]="allStudyTypes"
                                  [receivedAllTestSystemNames]="allTestSystemNames"
                                  [receivedAllTestSystemTypes]="allTestSystemTypes"
                                  [relatedSubstances]="relatedSubstances"
                                  [substanceTypes]="substanceTypes"
                                  [substance]="substance"
                                  [workAreaId]="workAreaId"
                                  [workAreaLabel]="workAreaLabel"
                                  [singleRecordDependencies]="singleRecordDependencies"
                                  [identifiedMetabolites]="identifiedMetabolites"
                                  [analyteNames]="analyteNames"
                                  [substanceAsAnalyteNames]="substanceAsAnalyteNames"
    ></app-consumer-safety-category>
  </div>

  <div *ngIf="!loadingDataHidden" class="loadingDataMessage">Loading data...</div>
  <div [hidden]="errorMessage" class="errorMessageHolder" id="errorMessageHolder"></div>
  <div class="controls" id="saveControls">
    <button (click)="save()" [class.spinner]="loading" [disabled]="disabled" class="buttons saveButton" id="saveButton"
            mat-raised-button type="button"><strong>&#10003; </strong>Save Endpoints
    </button>

  </div>

  <div class="spacer"></div>
</div>
