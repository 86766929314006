import {AfterViewInit, Component, OnInit, ViewEncapsulation,} from '@angular/core';
import * as L from 'leaflet';
import {ServerService} from '../../core/server.service';
import {ActivatedRoute} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {ExceptionService} from '../../core/exception.service';
import {Country} from '../models/country';
import {Region} from '../../shared/region';

@Component({
  selector: 'app-map-page',
  templateUrl: './map-page.component.html',
  styleUrls: ['./map-page.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class MapPageComponent implements OnInit, AfterViewInit {
  public isLoading: boolean;
  public apeRegistration: string;
  public registrations: Array<string>;
  private map: any;
  private data: any;
  private info: any;
  private legend: any;
  private layer: any;
  private activeIngredientId: string;
  private sessionId: string;
  private labelsAndColors: Map<string, string>;
  private riskCategory: string;
  private countries: Array<Country>;

  constructor(
    private route: ActivatedRoute,
    private exceptionService: ExceptionService,
    private serverService: ServerService,
    private toastrService: ToastrService
  ) {
    this.isLoading = true;
    this.route.paramMap.subscribe((params) => {
      this.activeIngredientId = params.get('id');
      this.sessionId = params.get('sessionId');
    });
    this.labelsAndColors = new Map<string, string>();
    this.countries = Array();
    this.registrations = Array();
    this.riskCategory = 'current';
    this.apeRegistration = '';
  }

  ngOnInit(): void {
    this.labelsAndColors.set(
      'registration_not_possible_or_revoked__c',
      '#808080'
    );
    this.labelsAndColors.set('no_risk__c', '#15FF00');
    this.labelsAndColors.set('minor_risk__c', '#FFE600');
    this.labelsAndColors.set('moderate_risk__c', '#FF7300');
    this.labelsAndColors.set('high_risk__c', '#FF0000');

    this.map = L.map('map', {
      zoom: 2,
      center: [40, 0],
      minZoom: 2,
      maxZoom: 18,
    });
    this.initLegend();
    this.initInfo();
    this.serverService.getPolygonData().subscribe(
      (data) => {
        this.isLoading = false;
        this.data = data;
        this.initStatesLayer();
      },
      (error) => {
        this.toastrService.error('Unable to load map.', 'Error');
        this.exceptionService.handleError(error);
      }
    );
  }

  ngAfterViewInit(): void {
    this.serverService
      .getCountries(this.sessionId, this.activeIngredientId)
      .subscribe(
        (response) => {
          if (!response.data.length) {
            this.toastrService.warning('No data to display', 'Warning');
          } else {
            response.data[0].poc_single_risk_score_regionscountries__cr.data.forEach(
              (element: Country) => {
                if (element.current__c !== null && element.current__c.length) {
                  this.countries.push(element);
                }
              }
            );
            this.map.removeLayer(this.layer);
            this.initStatesLayer();
          }
        },
        (error) => {
          this.exceptionService.handleError(error);
          this.toastrService.error('Unable to get countries.', 'Error');
        }
      );

    this.serverService
      .getRegionRegistrations(this.sessionId, this.activeIngredientId)
      .subscribe(
        (response) => {
          this.registrations.push(Region.EU);
          this.registrations.push(Region.APN);
          this.registrations.push(Region.APS);
          this.registrations.push(Region.APA);
          if (
            response.data.length > 0 &&
            response.data[0].poc_registrations__cr.data > 0
          ) {
            this.apeRegistration =
              response.data[0].poc_registrations__cr.data[0].loss_of_use__c;
          }
        },
        (error) => {
          this.exceptionService.handleError(error);
          this.toastrService.error('Unable to get registrations.', 'Error');
        }
      );
  }

  initStatesLayer(): void {
    this.layer = L.geoJSON(this.data, {
      style: (feature) => this.style(feature),
      onEachFeature: (feature, layer) =>
        layer.on({
          mouseover: (e) => this.highlightFeature(e, feature),
          mouseout: (e) => this.resetFeature(e, feature),
        }),
    }).addTo(this.map);
  }

  highlightFeature(e, feature): void {
    const layer = e.target;
    layer.setStyle({
      weight: 2,
      color: 'black',
      fillOpacity: 0.7,
    });
    if (!L.Browser.ie && !L.Browser.opera) {
      layer.bringToFront();
    }
    this.info.update(feature.properties);
  }

  resetFeature(e, feature): void {
    const layer = e.target;
    layer.setStyle(this.style(feature));
    this.info.update();
  }

  initLegend(): void {
    const legend = L.control({position: 'bottomleft'});
    legend.onAdd = () => {
      const div = L.DomUtil.create('div', 'info legend');
      this.labelsAndColors.forEach((value: string, key: string) => {
        const label =
          key.charAt(0).toUpperCase() +
          key
            .slice(1)
            .substring(0, key.length - 3)
            .split('_')
            .join(' ');
        div.innerHTML +=
          '<i style="background: ' +
          value +
          '; border: solid black 1px"></i> ' +
          label +
          '<br>';
      });
      return div;
    };
    this.legend = legend;
    this.legend.addTo(this.map);
  }

  initInfo(): void {
    this.info = L.control();
    let div;
    this.info.onAdd = () => {
      div = L.DomUtil.create('div', 'info');
      this.info.update();
      return div;
    };

    this.info.update = (props) => {
      if (props) {
        const country = this.countries.filter(
          (it) => it['country__cr.country_name__c'] === props.name
        );
        if (country.length) {
          country.forEach((it) => {
            let futureComment = '';
            let currentComment = '';
            if (it.future1__c !== null) {
              futureComment = it.future1__c;
            }
            if (it.current1__c !== null) {
              currentComment = it.current1__c;
            }
            div.innerHTML =
              '<div class="info-label"><b>Country name: ' +
              props.name +
              '</b></div><br />' +
              '<div class="info-label"><b><label>Current risk: </label></b>' +
              '<i class="color" style="background: ' +
              this.labelsAndColors.get(it.current__c[0]) +
              '; border: solid black 1px"></i>' +
              '</div>' +
              '<br />' +
              '<div class="info-label"><b>Current comment: </b><br />' +
              currentComment +
              '</div><br />' +
              '<div class="info-label"><b><label>Future risk: </label></b></b>' +
              '<i class="color" style="background: ' +
              this.labelsAndColors.get(it.future__c[0]) +
              '; border: solid black 1px"></i>' +
              '</div>' +
              '<br />' +
              '<div class="info-label"><b>Future comment: </b><br />' +
              futureComment +
              '</div><br />';
          });
        } else {
          div.innerHTML =
            '<div class="info-label"><b>Country name: ' +
            props.name +
            '</b></div>';
        }
      } else {
        div.innerHTML = '<b>Hover over a state</b>' + '<br />';
      }
    };

    this.info.addTo(this.map);
  }

  style(feature): object {
    return {
      fillColor: this.getColor(feature.properties.name),
      color: 'white',
      weight: 1,
      opacity: 1,
      dashArray: '2',
      fillOpacity: 0.6,
    };
  }

  getColor(name: string): string {
    const country = this.countries.filter(
      (it) => it['country__cr.country_name__c'] === name
    );
    if (country.length) {
      if (this.riskCategory === 'current') {
        return this.labelsAndColors.get(country[0].current__c[0]);
      } else {
        return this.labelsAndColors.get(country[0].future__c[0]);
      }
    } else {
      return '#EFEFEF';
    }
  }

  changeCategory(value: string): void {
    this.riskCategory = value;
    this.map.removeLayer(this.layer);
    this.initStatesLayer();
    // this.map.removeControl(this.legend);
    // this.initLegend();
    // this.map.removeControl(this.info);
    // this.initInfo();
  }
}
