import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {PicklistValue} from '../../../models/picklist-value';
import {DocumentMenuItem} from '../../../models/document-menu-item';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute} from '@angular/router';
import {ExceptionService} from '../../../../core/exception.service';
import {ServerService} from '../../../../core/server.service';
import {ToastrService} from 'ngx-toastr';
import {convertArrayToString} from '../../../../shared/convert-array-to-string';
import {CreateObjectResponse, CreateObjectsResponse} from '../../../models/create-objects-response';
import {VaultError} from '../../../../core/models/vault-error';
import {HttpErrorResponse} from '@angular/common/http';
import {EcotoxExistingEndpoint} from "../../models/ecotox-existing-endpoint";
import {EcotoxAdditionalField} from "../../models/ecotox-additional-field";
import {EcotoxEndpointTableItem} from "../../models/ecotox-endpoint-table-item";
import {EcotoxCategoryPicklists} from "../../models/ecotox-category-picklists";
import {TestGuidelines} from "../../../models/test-guidelines";


let me: EcotoxAllCategoriesExistingComponent;

@Component({
  selector: 'app-ecotox-all-categories-existing',
  templateUrl: './ecotox-all-categories-existing.component.html',
  styleUrls: ['../../../existing_record.css']
})
export class EcotoxAllCategoriesExistingComponent implements OnInit {
  @Input() arrayedEndpointRecord: EcotoxExistingEndpoint;
  @Input() category: any;
  @Input() substanceTypes: Array<PicklistValue>;
  @Input() relatedSubstances: any;
  @Input() categoryPicklists: Array<EcotoxCategoryPicklists>;
  @Input() allStudyTypes: Array<PicklistValue>;
  @Input() allEndpoints: Array<PicklistValue>;
  @Input() allEndpointUnits: Array<PicklistValue>;
  @Input() allTestSystemTypes: Array<PicklistValue>;
  @Input() allTestSpecies: Array<PicklistValue>;
  @Input() allOperators: Array<PicklistValue>;
  @Input() allAdditionalFields: Array<EcotoxAdditionalField>;
  @Input() allAdditionalFieldValues: Array<EcotoxAdditionalField>;
  @Input() testGuidelinePicklists: Array<TestGuidelines>;
  @Input() allTestGuidelines: Array<PicklistValue>;
  @Input() documents: Array<DocumentMenuItem>;
  @Input() allUsers: any;

  @Output() removeInput: EventEmitter<EcotoxEndpointTableItem> = new EventEmitter();
  @Output() copyExisting: EventEmitter<any> = new EventEmitter();

  subscriptions: any;
  selectFormGroup: FormGroup;
  configureFormGroup: FormGroup;
  public existingEndpointRecord: any;
  public additionalFields: Array<EcotoxAdditionalField>;
  public additionalFieldValues: Map<string, any>;
  public values31: Array<any>;
  public isEndpointUnitHidden: boolean;
  public hiddenAdditionalFields: Array<EcotoxAdditionalField>;
  public isValue1Disabled: boolean;
  public isValue2Disabled: boolean;
  public isValue3Disabled: boolean;
  public userName: any;
  public createdBy: any;
  public modifiedBy: any;
  public formatCreatedDate: string;
  public formatModifiedDate: string;
  private haveErrors: Boolean;
  showExistingRecord = true;
  showEditRecord = false;
  public purposeMessage: string;
  private sessionId: string;

  constructor(
    private route: ActivatedRoute,
    private exceptionService: ExceptionService,
    private serverService: ServerService,
    private toastrService: ToastrService,
    private formBuilder: FormBuilder
  ) {
    me = this;
    this.subscriptions = {};
    this.route.paramMap.subscribe((params) => {
      this.sessionId = params.get('sessionId');
    });
    this.selectFormGroup = this.formBuilder.group({
      category__c: [{value: 'Acute Toxicity', disabled: true}],
      substanceType: [],
      relatedSubstance: [{value: '', disabled: true}],
      studyType: [{value: '', disabled: false}, Validators.required],
      endpoint: [{value: '', disabled: false}, Validators.required],
      endpointUnit: [{value: '', disabled: false}, Validators.required],
      testSystemType: [{value: '', disabled: false}, Validators.required],
      testSpecies: [{value: '', disabled: false}, Validators.required],
      testGuideline: [{value: '', disabled: false}],
      operator: ['', Validators.required],
      value1: [{value: null, disabled: false}],
      value2: [{value: null, disabled: false}],
      value3: [{value: '', disabled: false}],
      // additional (depended) fields
      exposure_duration__c: [{value: null, disabled: false}],
      exposure_duration_unit__c: [{value: '', disabled: false}],
      exposure_design__c: [{value: '', disabled: false}],
      ed_relevant__c: [{value: '', disabled: false}],
      trigger_ed__c: [{value: '', disabled: false}],
      ed_modality__c: [{value: '', disabled: false}],
      ed_mechanisms__c: [{value: '', disabled: false}],
      used_in_risk_assessment__c: [{value: '', disabled: false}],
      endpoint_analytically_based_on__c: [{value: '', disabled: false}],
      t_trigger__c: [{value: '', disabled: false}],
      affected_ed__c: [{value: '', disabled: false}],
      trr__c: [{value: '', disabled: false}],
      normalization__c: [{value: '', disabled: false}],
      b_trigger__c: [{value: '', disabled: false}],
      substrate__c: [{value: '', disabled: false}],
      recovery_time__c: [{value: null, disabled: false}],
      recovery_time_unit__c: [{value: '', disabled: false}],
      peat_organic_matter__c: [{value: null, disabled: false}],
      comment__c: [{value: '', disabled: false}],
      basf_docid__c: [{value: null, disabled: false}],
      // default
      source: [{value: '', disabled: false}],
    });
    this.values31 = [];
    this.additionalFields = [];
    this.isEndpointUnitHidden = false;
    this.isValue1Disabled = true;
    this.isValue2Disabled = true;
    this.isValue3Disabled = true;
    this.purposeMessage = 'editOnExisting';
  }

  ngOnInit(): void {

    this.selectFormGroup.disable();

    // convert record values to string values
    this.existingEndpointRecord = JSON.parse(
      JSON.stringify(this.arrayedEndpointRecord)
    );

    convertArrayToString(
      this.arrayedEndpointRecord,
      this.existingEndpointRecord,
      []
    );

    if (this.existingEndpointRecord.created_by__v === 1) {

      this.createdBy = [{name: 'System'}];
    } else {
      this.createdBy = this.allUsers.filter(
        (c) => c.id === this.existingEndpointRecord.created_by__v
      );
    }

    if (this.existingEndpointRecord.modified_by__v === 1) {
      // eslint-disable-next-line quote-props
      this.modifiedBy = [{name: 'System'}];
    } else {
      this.modifiedBy = this.allUsers.filter(
        (m) => m.id === this.existingEndpointRecord.modified_by__v
      );
    }

    this.formatCreatedDate =
      this.existingEndpointRecord.created_date__v.substring(0, 10) +
      ', ' +
      this.existingEndpointRecord.created_date__v.substring(11, 16) +
      'h Z';
    this.formatModifiedDate =
      this.existingEndpointRecord.modified_date__v.substring(0, 10) +
      ', ' +
      this.existingEndpointRecord.modified_date__v.substring(11, 16) +
      'h Z';

    this.selectFormGroup.get('source').setValue(this.existingEndpointRecord.document_unbound__c);

    this.setAdditionalFields();
    this.setValuesVisibility(this.existingEndpointRecord.operator__c);
    this.setFormFields();
  }

  setAdditionalFields(): void {

    if (this.existingEndpointRecord.ed_relevant__c === 'yes__c') {
      const preAdditionalFields = this.allAdditionalFields.filter(
        (entry) =>
          entry.group === this.existingEndpointRecord.study_type_picklist_name__c
      );
      this.additionalFields = preAdditionalFields.sort((a, b) => a.sortNumber - b.sortNumber);
    } else {
      const preAllAdditionalFields = this.allAdditionalFields.filter(
        (entry) =>
          entry.group === this.existingEndpointRecord.study_type_picklist_name__c
      );
      // remove fields mark with 'yes__c' in Initially Hidden field
      const preAdditionalFields = preAllAdditionalFields.filter(obj =>
        obj.hidden !== 'yes__c'
      );
      this.additionalFields = preAdditionalFields.sort((a, b) => a.sortNumber - b.sortNumber);
    }
  }

  setValuesVisibility(operatorValue): void {
    if (operatorValue) {
      switch (operatorValue) {
        case 'between__c':
          this.isValue1Disabled = false;
          this.isValue2Disabled = false;
          this.isValue3Disabled = true;
          break;
        case 'text__c':
          this.isValue1Disabled = true;
          this.isValue2Disabled = true;
          this.isValue3Disabled = false;
          break;
        case null:
          this.isValue1Disabled = true;
          this.isValue2Disabled = true;
          this.isValue3Disabled = true;
          break;
        default:
          this.isValue1Disabled = false;
          this.isValue2Disabled = true;
          this.isValue3Disabled = true;
          break;
      }
    }
    else {
      this.isValue1Disabled = true;
      this.isValue2Disabled = true;
      this.isValue3Disabled = true;
    }
  }

  setFormFields(): void {
    this.selectFormGroup.get('category__c').setValue(this.existingEndpointRecord.category__c);
    this.selectFormGroup.get('substanceType').setValue(this.existingEndpointRecord.substance_type__c);
    this.selectFormGroup.get('relatedSubstance').setValue(this.existingEndpointRecord.related_substance__c);
    this.selectFormGroup.get('endpoint').setValue(this.existingEndpointRecord.endpoint__c);
    this.selectFormGroup.get('studyType').setValue(this.existingEndpointRecord.study_type__c);
    this.selectFormGroup.get('endpointUnit').setValue(this.existingEndpointRecord.endpoint_unit__c);
    this.selectFormGroup.get('testSystemType').setValue(this.existingEndpointRecord.test_system_type__c);
    this.selectFormGroup.get('testSpecies').setValue(this.existingEndpointRecord.test_species__c);
    this.selectFormGroup.get('operator').setValue(this.existingEndpointRecord.operator__c);
    this.selectFormGroup.get('value1').setValue(this.existingEndpointRecord.value_1__c);
    this.selectFormGroup.get('value2').setValue(this.existingEndpointRecord.value_2__c);
    this.selectFormGroup.get('value3').setValue(this.existingEndpointRecord.value_3__c);
    if (this.existingEndpointRecord.test_guideline__c) {
      this.selectFormGroup.get('testGuideline').setValue(this.existingEndpointRecord.test_guideline__c.split(','));
    } else {
      this.selectFormGroup.get('testGuideline').setValue('');
    }
    this.selectFormGroup.get('exposure_duration__c').setValue(this.existingEndpointRecord.exposure_duration__c);
    this.selectFormGroup.get('exposure_duration_unit__c').setValue(this.existingEndpointRecord.exposure_duration_unit__c);
    this.selectFormGroup.get('exposure_design__c').setValue(this.existingEndpointRecord.exposure_design__c);
    this.selectFormGroup.get('ed_relevant__c').setValue(this.existingEndpointRecord.ed_relevant__c);
    this.selectFormGroup.get('trigger_ed__c').setValue(this.existingEndpointRecord.trigger_ed__c);
    if (this.existingEndpointRecord.ed_modality__c) {
      this.selectFormGroup.get('ed_modality__c').setValue(this.existingEndpointRecord.ed_modality__c.split(','));
    } else
    {
      this.selectFormGroup.get('ed_modality__c').setValue('');
    }
    this.selectFormGroup.get('ed_mechanisms__c').setValue(this.existingEndpointRecord.ed_mechanisms__c);
    this.selectFormGroup.get('used_in_risk_assessment__c').setValue(this.existingEndpointRecord.used_in_risk_assessment__c);
    this.selectFormGroup.get('endpoint_analytically_based_on__c').setValue(this.existingEndpointRecord.endpoint_analytically_based_on__c);
    this.selectFormGroup.get('t_trigger__c').setValue(this.existingEndpointRecord.t_trigger__c);
    if (this.existingEndpointRecord.affected_ed__c) {
      this.selectFormGroup.get('affected_ed__c').setValue(this.existingEndpointRecord.affected_ed__c.split(','));
    } else {
      this.selectFormGroup.get('affected_ed__c').setValue('');
    }
    this.selectFormGroup.get('trr__c').setValue(this.existingEndpointRecord.trr__c);
    this.selectFormGroup.get('normalization__c').setValue(this.existingEndpointRecord.normalization__c);
    this.selectFormGroup.get('b_trigger__c').setValue(this.existingEndpointRecord.b_trigger__c);
    this.selectFormGroup.get('substrate__c').setValue(this.existingEndpointRecord.substrate__c);
    this.selectFormGroup.get('recovery_time__c').setValue(this.existingEndpointRecord.recovery_time__c);
    this.selectFormGroup.get('recovery_time_unit__c').setValue(this.existingEndpointRecord.recovery_time_unit__c);
    this.selectFormGroup.get('peat_organic_matter__c').setValue(this.existingEndpointRecord.peat_organic_matter__c);
    this.selectFormGroup.get('comment__c').setValue(this.existingEndpointRecord.comment__c);
    this.selectFormGroup.get('basf_docid__c').setValue(this.existingEndpointRecord.basf_docid__c);
    // default
    this.selectFormGroup.get('source').setValue(this.existingEndpointRecord.document_unbound__c);
  }

  enableEditing(): any {
    this.showExistingRecord = false;
    this.showEditRecord = true;
  }

  cancelEditing(): void {
    this.showExistingRecord = true;
    this.showEditRecord = false;
  }

  saveChanges(endpointRecord): void {
    this.haveErrors = false;

    this.serverService.isLoading();

    delete endpointRecord.created_by__v;
    delete endpointRecord.modified_by__v;
    delete endpointRecord.created_date__v;
    delete endpointRecord.modified_date__v;

    const value1 = parseInt(String(endpointRecord.value_1__c));
    const value2 = parseInt(String(endpointRecord.value_2__c));

    if (endpointRecord.operator__c === 'between__c') {
      if (value2 <= value1 || isNaN(value1) || isNaN(value2)) {
        this.haveErrors = true;
        console.log(value1 + ', ' + value2);
      }
    }

    if (this.haveErrors === true) {
      console.log('saving with errors!');
      this.serverService.isNotLoading();
      this.toastrService.error('Value 2 must be greater than Value 1', 'Values Error');
    }
    else {
      const body = Array(endpointRecord);

      this.serverService.updateEndpoint(this.sessionId, body).subscribe(
        (success: CreateObjectsResponse) => {
          let updated = 0;
          success.data?.forEach((record: CreateObjectResponse) => {
            if (record?.responseStatus === 'SUCCESS') {
              updated++;
            }
            if (record?.responseStatus === 'FAILURE') {
              record?.errors?.forEach((error: VaultError) => {
                this.toastrService.error(error.message, error.type);
              });
            }
          });

          this.toastrService.info(
            ` Endpoint updated successfully.`,
            'Info'
          );
          this.serverService.isNotLoading();
        },
        (error: HttpErrorResponse) => {
          this.exceptionService.handleError(error);
          this.toastrService.error(
            `An error occurred while updating endpoint.`,
            'Error'
          );
          this.serverService.isNotLoading();
        }
      );

      this.showExistingRecord = true;
      this.showEditRecord = false;
      this.existingEndpointRecord = endpointRecord;
      this.setValuesVisibility(this.existingEndpointRecord.operator__c);
      this.setFormFields();
    }
  }

  copyExistingEndpoint(endpoint: EcotoxExistingEndpoint): any {
    this.copyExisting.emit(endpoint);
  }

  filterAdditionalFieldValues(
    allAdditionalFieldValues: Array<PicklistValue>,
    filterWord: string
  ): Array<PicklistValue> {
    return allAdditionalFieldValues.filter(
      (item) => item.picklist === filterWord
    );
  }

}


