<div *ngIf="isLoading" class="ball-loader">
  <div class="ball-loader-ball ball1"></div>
  <div class="ball-loader-ball ball2"></div>
  <div class="ball-loader-ball ball3"></div>
</div>
<mat-tab-group *ngIf="!isLoading" class="tab-custom-overflow">
  <mat-tab label="Evaluations">
    <div class="mat-elevation-z8 small-table">
      <table mat-table [dataSource]="commentsDatasource" matSort class="comments-table" aria-label="Elements">

        <ng-container *ngFor="let col of commentsTableCols.slice(0,1); let iCol = index"
                      [matColumnDef]="col" sticky>
          <th mat-header-cell *matHeaderCellDef
              class="work-area-header"
          ></th>
          <td mat-cell *matCellDef="let row; let iRow = index" [attr.rowspan]="rowSpans[iCol][iRow].span"
              [style.display]="rowSpans[iCol][iRow].span === 0 ? 'none'  : ''"
              [style.background-color]="row['Work Area-color']"
              class="work-area-cell"

          ><strong>{{row[col]}}</strong></td>
        </ng-container>

        <ng-container *ngFor="let col of commentsTableCols.slice(1);">
          <ng-container matColumnDef="{{ col }}">
            <th mat-header-cell *matHeaderCellDef class="header">
              <strong> {{col}} </strong>
            </th>

            <td mat-cell *matCellDef="let row;"
                [style.background-color]="row[col+'-color']"
                class="column"
            >{{ row[col] }}</td>
          </ng-container>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="commentsTableCols; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: commentsTableCols"></tr>

      </table>
    </div>
    <div class="table-legend">
      <svg width="800px" height="170px">
        <g>
          <rect fill="#9CCF00" height="25" width="130" x="10" y="15"></rect>
          <text font-size="14" x="150" y="32">No risk: High likelihood to maintain or expand registrations.</text>
        </g>
        <g>
          <rect x="10" y="45" width="130" height="25" fill="#FFFF00"></rect>
          <text font-size="14" x="150" y="62">Minor risk: High likelihood to maintain or expand registrations.
            Additional studies may be required, positive outcome expected.
          </text>
        </g>
        <g>
          <rect x="10" y="75" width="130" height="25" fill="#FF9A00"></rect>
          <text font-size="14" x="150" y="92">Moderate risk: Registration possible with additional effort
            and/or with restrictions.
          </text>
        </g>
        <g>
          <rect x="10" y="105" width="130" height="25" fill="#FF0000"></rect>
          <text font-size="14" x="150" y="122">High risk: Loss of key uses and/or registrations likely.
            Data generation unlikely to improve outcome, or is not feasible.
          </text>
        </g>

      </svg>
    </div>
  </mat-tab>
  <mat-tab label="Overall Future Comments">
    <table mat-table [dataSource]="overallFuturesDatasource" class="overall-futures-table">
      <ng-container *ngFor="let col of overallFuturesCols">
        <ng-container matColumnDef="{{col}}">
          <th mat-header-cell *matHeaderCellDef class="header">{{col}}</th>
          <td mat-cell *matCellDef="let row"
              [style.background-color]="row[col+'-color']"
              [style.color]="row[col+'-text-color']"
              class="column">{{row[col]}}</td>
        </ng-container>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="overallFuturesCols; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: overallFuturesCols"></tr>
    </table>
    <div class="table-legend">
      <svg width="800px" height="170px">
        <g>
          <rect x="10" y="10" width="130" height="25" fill="#9CCF00"></rect>
          <text font-size="14" x="150" y="27">No risk: High likelihood to maintain or expand registrations.</text>
        </g>
        <g>
          <rect x="10" y="45" width="130" height="25" fill="#FFFF00"></rect>
          <text font-size="14" x="150" y="62">Minor risk: High likelihood to maintain or expand registrations.
            Additional studies may be required, positive outcome expected.
          </text>
        </g>
        <g>
          <rect x="10" y="75" width="130" height="25" fill="#FF9A00"></rect>
          <text font-size="14" x="150" y="92">Moderate risk: Registration possible with additional effort
            and/or with restrictions.
          </text>
        </g>
        <g>
          <rect x="10" y="105" width="130" height="25" fill="#FF0000"></rect>
          <text font-size="14" x="150" y="122">High risk: Loss of key uses and/or registrations likely.
            Data generation unlikely to improve outcome, or is not feasible.
          </text>
        </g>
        <g>
          <rect fill="'black'" height="25" width="130" x="10" y="135"></rect>
          <text font-size="14" x="150" y="152">Registration not possible or revoked.</text>
        </g>
      </svg>
    </div>
  </mat-tab>
  <mat-tab label="Single Risk Scores">
    <table mat-table [dataSource]="singleRiskScoresDatasource" class="overall-futures-table">
      <ng-container *ngFor="let col of singleRiskScoreCols">
        <ng-container matColumnDef="{{col}}">
          <th mat-header-cell *matHeaderCellDef class="header">{{col}}</th>
          <td mat-cell *matCellDef="let row"
              [style.background-color]="row[col+'-color']"
              [style.color]="row[col+'-text-color']"
              class="column">{{row[col]}}</td>
        </ng-container>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="singleRiskScoreCols; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: singleRiskScoreCols"></tr>
    </table>
    <div class="table-legend">
      <svg width="800px" height="170px">
        <g>
          <rect x="10" y="10" width="130" height="25" fill="#9CCF00"></rect>
          <text font-size="14" x="150" y="27">No risk: High likelihood to maintain or expand registrations.</text>
        </g>
        <g>
          <rect x="10" y="45" width="130" height="25" fill="#FFFF00"></rect>
          <text font-size="14" x="150" y="62">Minor risk: High likelihood to maintain or expand registrations.
            Additional studies may be required, positive outcome expected.
          </text>
        </g>
        <g>
          <rect x="10" y="75" width="130" height="25" fill="#FF9A00"></rect>
          <text font-size="14" x="150" y="92">Moderate risk: Registration possible with additional effort
            and/or with restrictions.
          </text>
        </g>
        <g>
          <rect x="10" y="105" width="130" height="25" fill="#FF0000"></rect>
          <text font-size="14" x="150" y="122">High risk: Loss of key uses and/or registrations likely.
            Data generation unlikely to improve outcome, or is not feasible.
          </text>
        </g>
        <g>
          <rect x="10" y="135" width="130" height="25" fill="#000000"></rect>
          <text x="150" y="152" font-size="14">Registration not possible or revoked</text>
        </g>
      </svg>
    </div>
  </mat-tab>
</mat-tab-group>


<!--<ngx-spinner-->
<!--  size="medium"-->
<!--  color="#F0F0F0"-->
<!--  type="ball-scale-multiple"-->
<!--  [fullScreen]="false"-->
<!--&gt;-->
<!--  <p style="font-size: 20px; color: #F0F0F0">Loading Data...</p>-->
<!--</ngx-spinner>-->
