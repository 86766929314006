<div>
  <app-existing-endpoints *ngIf="loadingDataHidden"
                          [documents]="documents"
                          [receivedAdditionalFieldValues]="additionalFieldValues"
                          [receivedAllAdditionalFields]="additionalFields"
                          [receivedAllEndpointUnits]="allEndpointUnits"
                          [receivedAllEndpoints]="allEndpoints"
                          [receivedAllOperators]="operators"
                          [receivedAllStudyTypes]="allStudyTypes"
                          [receivedCategories]="categories"
                          [receivedCategoryPicklists]="categoryPicklists"
                          [relatedSubstances]="relatedSubstances"
                          [substanceTypes]="substanceTypes"
                          [substance]="substance"
                          [workAreaId]="workAreaId"
                          [workAreaName]="workAreaLabel"

  ></app-existing-endpoints>

  <div>
    <app-efate-category #eFateCategory *ngFor="let efatecategory of categories" [category]="efatecategory"
                        [dataPicklists]="categoryPicklists"
                        [documents]="documents"
                        [endpointsArrayList]="endpointsArrayList"
                        [operators]="operators"
                        [receivedAdditionalFieldValues]="additionalFieldValues"
                        [receivedAllAdditionalFields]="additionalFields"
                        [receivedAllEndpointUnits]="allEndpointUnits"
                        [receivedAllEndpoints]="allEndpoints"
                        [receivedAllStudyTypes]="allStudyTypes"
                        [relatedSubstances]="relatedSubstances"
                        [substanceTypes]="substanceTypes"
                        [substance]="substance"
                        [workAreaId]="workAreaId"
                        [workAreaLabel]="workAreaLabel"
                        id="categoryHolder"
    ></app-efate-category>
  </div>

  <div *ngIf="!loadingDataHidden" class="loadingDataMessage">Loading data...</div>
  <div [hidden]="errorMessage" class="errorMessageHolder" id="errorMessageHolder"></div>
  <div class="controls" id="saveControls">
    <button (click)="save()" [class.spinner]="loading" [disabled]="disabled" class="buttons saveButton" id="saveButton"
            mat-raised-button type="button"><strong>&#10003; </strong>Save Endpoints
    </button>

  </div>

  <div class="spacer"></div>
</div>

