<div [ngStyle]="{'z-index':singleEndpointRecord.id__c}" class="fieldsHolder" id="{{singleEndpointRecord.id__c}}">
  <form [formGroup]="selectFormGroup">
    <div class="rightConner">
      <button *ngIf="showCopyOptions" (click)="removeEndpoint(singleEndpointRecord)" class="delete" data-cy="RemoveEndpoint"
              title="Remove Endpoint"><span class="material-icons">remove_circle_outline</span></button>
      <button *ngIf="showCopyOptions" (click)="copyEndpoint(singleEndpointRecord)" class="copy" data-cy="CopyEndpoint"
              title="Copy Endpoint"><span class="material-icons">content_copy</span></button>
      <button *ngIf="showEditOptions" (click)="cancelEditing(singleEndpointRecord)" class="delete" data-cy="RemoveEndpoint"
              title="Remove Endpoint"><span class="material-icons">cancel</span></button>
      <button *ngIf="showEditOptions" (click)="saveEndpointChanges(singleEndpointRecord)" class="copy" data-cy="SaveEndpoint"
              title="Save Changes"><span class="material-icons">check_circle</span></button>
    </div>

    <div class="singleFieldContainer">
      <div class="formItemTitle">Category:</div>
      <div>
        <select class="formSelector" name="category">
          <option value="{{category.id}}">{{category.name__v}}</option>
        </select>
      </div>
    </div>

     <div class="singleFieldContainer">
      <div class="formItemTitle">Source:</div>
      <select (change)="updateFieldValue($event.target.valueOf())" class="formSelectorForDocument"
              formControlName="selectedDocument" name="source" title="document_unbound__c">
        <option *ngFor="let document of documents" value="{{ document.id }}">{{ document.name }}</option>
      </select>
    </div>

    <div class="singleFieldContainer">
      <div class="formItemTitle">Substance Type:</div>
      <div>
        <select (change)="onSelectSubstanceType($event)" class="formSelector" formControlName="substanceType"
                name="substanceType">
          <option value=""></option>
          <option *ngFor="let substaneType of substanceTypes"
                  [value]="substaneType.name">{{ substaneType.label }}</option>
        </select>
      </div>
    </div>

    <div class="singleFieldContainer">
      <div class="formItemTitle">Related Substance:</div>
      <div>
        <select (change)="updateFieldValue($event.target.valueOf())" class="formSelector"
                formControlName="relatedSubstance" name="relatedSubstance" title="related_substance__c">
          <option selected value=""></option>
          <option *ngFor="let relSubstance of filteredRelatedSubstances"
                  value="{{relSubstance.id}}">{{relSubstance.name}}</option>
        </select>
      </div>
    </div>

    <div class="singleFieldContainer">
      <div class="formItemTitle">Study Type:</div>
      <div>
        <select (change)="onSelectStudyType($event);" class="formSelector" formControlName="studyType"
                name="study-type">
          <option value=""></option>
          <option *ngFor="let studyType of studyTypes"
                  [title]="studyType.picklist"
                  [value]="studyType.name">{{ studyType.label }}</option>
        </select>
      </div>
    </div>

    <div class="singleFieldContainer">
      <div class="formItemTitle">Test System Type:</div>
      <select (change)="onSelectTestSystemType($event)" class="formSelector" formControlName="test_system_type__c">
        <option value=""></option>
        <option *ngFor="let testSystemType of testSystemTypes" [title]="testSystemType.picklist"
                value="{{ testSystemType.name }}">{{ testSystemType.label }}</option>
      </select>
    </div>

    <div class="singleFieldContainer">
      <div class="formItemTitle">Route of Administration:</div>
      <div>
        <select (change)="updateFieldValue($event.target.valueOf())" class="formSelector"
                formControlName="route_of_administration__c" title="route_of_administration__c">
          <option value=""></option>
          <option *ngFor="let routeOfAdmin of routeOfAdministration" [title]="routeOfAdmin.picklist"
                  [value]="routeOfAdmin.name">{{ routeOfAdmin.label }}</option>
        </select>
      </div>
    </div>

    <div class="singleFieldContainer">
      <div class="formItemTitle">Test Species:</div>
      <div>
        <select (change)="updateFieldValue($event.target.valueOf())" class="formSelector"
                formControlName="test_system_name__c" title="test_system_name__c">
          <option value=""></option>
          <option *ngFor="let testSystemName of testSystemNames" [title]="testSystemName.picklist"
                  [value]="testSystemName.name">{{ testSystemName.label }}</option>
        </select>
      </div>
    </div>

    <div class="singleFieldContainer">
      <div class="formItemTitle">Endpoint:</div>
      <select (change)="updateFieldValue($event.target.valueOf())" class="formSelector" formControlName="endpoint"
              title="endpoint__c">
        <option value=""></option>
        <option *ngFor="let endpoint of endpoints" [title]="endpoint.picklist"
                value="{{ endpoint.name }}">{{ endpoint.label }}</option>
      </select>
    </div>

    <div class="singleFieldContainer">
      <div class="formItemTitle">Endpoint Unit:</div>
      <div>
        <select (change)="updateFieldValue($event.target.valueOf())" class="formSelector" formControlName="endpointUnit"
                title="endpoint_unit__c">
          <option value=""></option>
          <option *ngFor="let unit of endpointUnits" value="{{ unit.name }}">{{ unit.label }}</option>
        </select>
      </div>
    </div>

    <div class="singleFieldContainer" [hidden]="operatorHidden" >
      <div class="formItemTitle">Operator:</div>
      <div>
        <select  (change)="onSelectOperator($event);" class="formSelector" formControlName="operator" name="operator">
          <option value=""></option>
          <option *ngFor="let operator of operators" value="{{ operator.name }}">{{ operator.label }}</option>
        </select>
      </div>
    </div>

    <div class="singleFieldContainer" [hidden]="isValue1Disabled">
      <div class="formItemTitle">Value 1:</div>
      <input (change)="updateValue1($event.target.valueOf())" (keyup)="updateValue1($event.target.valueOf())"
             class="numberSelector" formControlName="value1"
             name="value_1__c" placeholder="Number" type="number"/>
    </div>

    <div class="singleFieldContainer" [hidden]="isValue2Disabled">
      <div class="formItemTitle">Value 2:</div>
      <input (change)="updateValue2($event.target.valueOf())" (keyup)="updateValue2($event.target.valueOf())"
             [className]="errorOnValue2 ? 'numberSelectorRed' : 'numberSelector'"
             formControlName="value2"
             name="value_2__c" placeholder="Number 2" type="number"/>
    </div>

    <div class="singleFieldContainer" [hidden]="isValue3Disabled">
      <div class="formItemTitle">Value 3:</div>
      <input (change)="updateValue3($event.target.valueOf())" (keyup)="updateValue3($event.target.valueOf())"
             class="formFreeText" formControlName="value3" name="value_3__c" placeholder="Free Text" type="text"/>
    </div>

    <div class="singleFieldContainer" [hidden]="isValue31Disabled">
      <div class="formItemTitle">Value 3:</div>
      <select (change)="updateValue3($event.target.valueOf())" class="formSelector" formControlName="value31" >
        <option value=""></option>
        <option *ngFor="let value31 of values31" value="{{ value31 }}">{{ value31 }}</option>
      </select>
    </div>



    <div *ngFor="let field of additionalFields" class="singleFieldContainer" id="{{field.name}}">
      <div class="formItemTitle">{{field.label}}:</div>

      <div [ngSwitch]="field.type">
        <textarea (change)="updateFieldValue($event.target.valueOf())"
                  (keyup)="updateFieldValue($event.target.valueOf())" *ngSwitchCase="'text'"
                  [formControlName]="field.name" [title]="field.name" class="formFreeText" rows="1"></textarea>
        <input (change)="updateNumberFieldValue($event.target.valueOf())"
               (keyup)="updateNumberFieldValue($event.target.valueOf())"
               *ngSwitchCase="'number'" [formControlName]="field.name" [title]="field.name" class="numberSelector"
               min=0 type="number">

        <select (change)="updateFieldValue($event.target.valueOf())" *ngSwitchCase="'picklist'"
                [formControlName]="field.name" [title]="field.name" class="formSelector">
          <option value=""></option>
          <option *ngFor="let opt of filterAdditionalFieldValues(allAdditionalFieldValues, field.picklist)"
                  [value]="opt.name">{{opt.label}}</option>
        </select>

        <mat-form-field *ngSwitchCase="'multi-picklist'" class="multiPicklistFormSelector">
          <mat-select
            (selectionChange)="updateMultiPicklistFieldValue($event, field.name)"
            [formControlName]="field.name"
            [title]="field.name"
            class="formSelector"
            multiple ngDefaultControl>
            <mat-option *ngFor="let topping of filterAdditionalFieldValues(allAdditionalFieldValues, field.picklist)"
                        [value]="topping.name">{{topping.label}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>

  </form>

</div>
