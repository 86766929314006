import {AfterContentInit, Component, Input, OnInit, ViewChild, } from '@angular/core';
import {ServerService} from 'src/app/core/server.service';
import {EndpointTableItem} from 'src/app/endpoint/environmental-fate/models/endpoint-table-item';
import {CategoryPicklists} from '../models/category-picklists';
import {ActivatedRoute} from '@angular/router';
import {PicklistValue} from '../../models/picklist-value';
import {DocumentMenuItem} from '../../models/document-menu-item';
import {SubstanceItem} from '../../models/substance-item';
import {EndpointServicesService} from '../../endpoint-services.service';
import {RelatedSubstances} from '../../models/related-substances';
import {AdditionalField} from '../models/additional-field';
import {EFateRecordComponent} from '../e-fate-record/e-fate-record.component';

let me: EfateCategoryComponent;

@Component({
  selector: 'app-efate-category',
  templateUrl: './efate-category.component.html',
  styleUrls: ['./efate-category.component.css'],
})
export class EfateCategoryComponent implements OnInit, AfterContentInit {
  @Input() workAreaId: string;
  @Input() workAreaLabel: string;
  @Input() category: any;
  @Input() dataPicklists: Array<CategoryPicklists>;
  @Input() operators: Array<PicklistValue>;
  @Input() endpointsArrayList: Array<EndpointTableItem>;
  @Input() substance: SubstanceItem;
  @Input() relatedSubstances: Array<RelatedSubstances>;
  @Input() substanceTypes: Array<PicklistValue>;
  @Input() receivedAllEndpoints: Array<PicklistValue>;
  @Input() receivedAllStudyTypes: Array<PicklistValue>;
  @Input() receivedAllEndpointUnits: Array<PicklistValue>;
  @Input() documents: Array<DocumentMenuItem>;
  @Input() receivedAllAdditionalFields: Array<AdditionalField>;
  @Input() receivedAdditionalFieldValues: Array<AdditionalField>;
  @ViewChild(EFateRecordComponent) eRecord: EFateRecordComponent;

  subscriptions: any;
  public selectedDocument: DocumentMenuItem;
  public filteredPicklists: Array<CategoryPicklists>;
  public categoryEndpointsArrayList: Array<EndpointTableItem>;
  public groupPicklists: Array<CategoryPicklists>;
  public defaultAllStudyTypes: Array<PicklistValue>; // reference list
  public defaultAllEndpoints: Array<PicklistValue>; // reference list
  public defaultAllEndpointUnits: Array<PicklistValue>; // reference list
  public startingId: number;
  public maxCategoryId: number;
  private sessionId: string;

  constructor(
    private route: ActivatedRoute,
    private serverService: ServerService,
    private endpointService: EndpointServicesService
  ) {
    me = this;
    this.subscriptions = {};
    this.route.paramMap.subscribe((params) => {
      this.sessionId = params.get('sessionId');
    });
    this.categoryEndpointsArrayList = [];
    this.filteredPicklists = [];
    this.groupPicklists = [];
    this.defaultAllStudyTypes = [];
    this.defaultAllEndpoints = [];
    this.defaultAllEndpointUnits = [];
    this.startingId = 0;
    this.maxCategoryId = 0;
  }

  ngOnInit(): void {
    this.endpointService.currentDocument.subscribe(
      (doc) => (this.selectedDocument = doc)
    );

    this.maxCategoryId = (10 - this.category.order_by__c) * 1000000;
  }

  ngAfterContentInit(): void {
    this.filteredPicklists = this.dataPicklists.filter(
      (a) => a.categoryId === this.category.id
    );
    this.defaultAllStudyTypes = this.receivedAllStudyTypes.filter(
      (b) => b.id === this.category.id
    );
    this.defaultAllEndpoints = this.receivedAllEndpoints.filter(
      (c) => c.id === this.category.id
    );
    this.defaultAllEndpointUnits = this.receivedAllEndpointUnits.filter(
      (d) => d.id === this.category.id
    );
  }

  addNewEndpoint(endpoint: any): void {

    // make object
    const newEndpointTemplate: EndpointTableItem = {
      id__c: this.generateId(),
      work_area__c: this.workAreaId,
      category__c: this.category.id,
      substance_name__c: this.substance.id,
      substance_type__c: endpoint.substance_type__c ?? 'parent__c',
      related_substance__c: endpoint.related_substance__c ?? '',
      study_type__c: endpoint.study_type__c ?? '',
      endpoint__c: endpoint.endpoint__c ?? '',
      endpoint_unit__c: endpoint.endpoint_unit__c ?? '',
      operator__c: endpoint.operator__c ?? '',
      value_1__c: endpoint.value_1__c ?? null,
      value_2__c: endpoint.value_2__c ?? null,
      value_3__c: endpoint.value_3__c ?? '',
      // additional (depended) fields
      crops1__c: endpoint.crops1__c ?? '',
      comment__c: endpoint.comment__c ?? '',
      focus_step__c: endpoint.focus_step__c ?? '',
      scenario_with_highest_concentration__c: endpoint.scenario_with_highest_concentration__c ?? '',
      location_countrysite__c: endpoint.location_countrysite__c ?? '',
      ptrigger__c: endpoint.ptrigger__c ?? '',
      data_normalization__c: endpoint.data_normalization__c ?? '',
      no_of_systemssitesconc__c: endpoint.no_of_systemssitesconc__c ?? '',
      temp_c__c: endpoint.temp_c__c ?? null,
      kinetic_model__c: endpoint.kinetic_model__c ?? '',
      ph_dependency__c: endpoint.ph_dependency__c ?? '',
      ph_experiment__c: endpoint.ph_experiment__c ?? null,
      method_of_calc__c: endpoint.method_of_calc__c ?? '',
      dt50_used_for_pecsoil__c: endpoint.dt50_used_for_pecsoil__c ?? '',
      no_of_soilssites__c: endpoint.no_of_soilssites__c ?? '',
      no_of_soils__c: endpoint.no_of_soils__c ?? '',
      no_of_systemssites__c: endpoint.no_of_systemssites__c ?? '',
      plant_uptake_factor_used_for_pecgw__c: endpoint.plant_uptake_factor_used_for_pecgw__c ?? null,
      method_of_calculation__c: endpoint.method_of_calculation__c ?? '',
      dt50_used_for_pecgw__c: endpoint.dt50_used_for_pecgw__c ?? null,
      kfoc_used_for_pecgw__c: endpoint.kfoc_used_for_pecgw__c ?? null,
      no_of_sites__c: endpoint.no_of_sites__c ?? '',
      target_crop__c: endpoint.target_crop__c ?? '',
      application_rate__c: endpoint.application_rate__c ?? '',
      no_of_columns__c: endpoint.no_of_columns__c ?? '',
      ageing_period__c: endpoint.ageing_period__c ?? '',
      duration__c: endpoint.duration__c ?? '',
      soil_site__c: endpoint.soil_site__c ?? '',
      relevance_trigger__c: endpoint.relevance_trigger__c ?? '',
      toxicological_relevance__c: endpoint.toxicological_relevance__c ?? '',
      no_of_columnssites__c: endpoint.no_of_columnssites__c ?? '',
      leaching_model__c: endpoint.leaching_model__c ?? '',
      metabolites_included__c: endpoint.metabolites_included__c ?? '',
      population_group__c: endpoint.population_group__c ?? '',
      no_of_sitescountries__c: endpoint.no_of_sitescountries__c ?? '',
      study_type_picklist_name__c: endpoint.study_type_picklist_name__c ?? '', // field used for capturing additional (depended) fields
      document_unbound__c: endpoint.document_unbound__c ?? this.selectedDocument.id,
    };

    this.categoryEndpointsArrayList.push(newEndpointTemplate);
    this.endpointsArrayList.push(newEndpointTemplate);
  }

  removeEndpointInput(endpoint: EndpointTableItem): void {
    // remove object from array
    for (let index = 0; index < this.endpointsArrayList.length; index++) {
      const element: EndpointTableItem = this.endpointsArrayList[index];
      if (element.id__c === endpoint.id__c) {
        this.endpointsArrayList.splice(index, 1);
      }
    }

    for (let index = 0; index < this.categoryEndpointsArrayList.length; index++) {
      const element: EndpointTableItem = this.categoryEndpointsArrayList[index];
      if (element.id__c === endpoint.id__c) {
        this.categoryEndpointsArrayList.splice(index, 1);
      }
    }
  }

  removeSavedEndpoint(endpointId): any {
    this.categoryEndpointsArrayList = this.categoryEndpointsArrayList.filter(
      obj => obj.id__c !== endpointId
    );
  }

  generateId(): number {
    const newStartingId = this.startingId + 1;
    this.updateStartingId(newStartingId);
    return this.maxCategoryId - newStartingId;
  }

  updateStartingId(newStartingId): void {
    this.startingId = newStartingId;
  }

  copyEndpointInput(endpoint: EndpointTableItem): void {
    this.categoryEndpointsArrayList.filter(it => it.id__c === endpoint.id__c)
      .forEach(newEndpoint => {
        this.addNewEndpoint(newEndpoint);
      });

    console.log('C category list: ' + JSON.stringify(this.categoryEndpointsArrayList));
    console.log('C all list' + JSON.stringify(this.endpointsArrayList));

  }
}
