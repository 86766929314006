import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import {Timeline, TimelineGroup, TimelineItem, TimelineOptions, } from 'vis-timeline/peer';
import {DataSet} from 'vis-data';
import domtoimage from 'dom-to-image';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'app-timeline',
  templateUrl: './timeline.component.html',
  styleUrls: ['./timeline.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class TimelineComponent implements OnInit, OnChanges, AfterViewInit {
  @ViewChild('visTimeline') timelineContainer: ElementRef;
  @Input() data: Array<TimelineItem>;
  @Output() saveImage: EventEmitter<string> = new EventEmitter();

  private tlContainer: any;
  private timeline: Timeline;
  private items: DataSet<TimelineItem>;
  private groups: DataSet<TimelineGroup>;
  private options: TimelineOptions;

  constructor(private toastrService: ToastrService) {
    this.groups = new DataSet([
      {id: 1, content: 'Actual dates'},
      {id: 2, content: 'Estimated dates'},
    ]);

    this.options = {
      showMajorLabels: false,
      showCurrentTime: false,
      timeAxis: {
        scale: 'year',
        step: 1,
      },
      width: '100%',
      editable: false,
    };

    this.items = new DataSet();
  }

  ngOnInit(): void {
    if (this.data) {
      this.items.update(this.data);
    }
  }

  ngAfterViewInit(): void {
    this.tlContainer = this.timelineContainer.nativeElement;
    this.timeline = new Timeline(
      this.tlContainer,
      this.items,
      this.groups,
      this.options
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.data.currentValue !== undefined) {
      this.items.update(this.data);
    }
  }

  saveTimeline(): void {
    this.toastrService.info('Saving...', 'Info');
    domtoimage.toPng(this.tlContainer).then((imageDataUrl) => {
      this.saveImage.emit(imageDataUrl);
    });
  }
}
