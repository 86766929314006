<div class="endpointContent">
  <div class="endpointCategoryTitle">
    <button (click)="addNewEndpoint({})" class="buttons" data-cy="addNew"
            style="font-family: Arial, Helvetica, sans-serif; color: #3781b5; background-color: #e4ebef;">
      + Add New
    </button>
    {{ category["name__v"]}}
  </div>
  <div [ngSwitch]="category['name__v']">
    <div *ngSwitchCase="'Acute Toxicity'">
      <app-acute-toxicity
        (copyInput)="copyEndpointInput($event)"
        (removeInput)="removeEndpointInput($event)"
        *ngFor="let endpoint of categoryEndpointsArrayList"
        [singleEndpointRecord]="endpoint"
        [category]="category"
        [substanceTypes]="substanceTypes"
        [relatedSubstances]="relatedSubstances"
        [allStudyTypes]="defaultAllStudyTypes"
        [testSystemTypes]="allTestSystemTypes"
        [allRouteOfAdministration]="defaultAllRouteOfAdministration"
        [allTestSystemNames]="allTestSystemNames"
        [allEndpoints]="defaultAllEndpoints"
        [allEndpointUnits]="defaultAllEndpointUnits"
        [allOperators]="operators"
        [allAdditionalFields]="receivedAllAdditionalFields"
        [allAdditionalFieldValues]="receivedAdditionalFieldValues"
        [documents]="documents"
      >
      </app-acute-toxicity>
    </div>
    <div *ngSwitchCase="'Specific Target Organ Toxicity (STOT)'">
      <app-stot
        (copyInput)="copyEndpointInput($event)"
        (removeInput)="removeEndpointInput($event)"
        *ngFor="let endpoint of categoryEndpointsArrayList"
        [allAdditionalFieldValues]="receivedAdditionalFieldValues"
        [allAdditionalFields]="receivedAllAdditionalFields"
        [allEndpointUnits]="defaultAllEndpointUnits"
        [allEndpoints]="defaultAllEndpoints"
        [allStudyTypes]="defaultAllStudyTypes"
        [allTestSystemTypes]="allTestSystemTypes"
        [allRouteOfAdministration]="defaultAllRouteOfAdministration"
        [allTestSystemNames]="allTestSystemNames"
        [category]="category"
        [documents]="documents"
        [allOperators]="operators"
        [relatedSubstances]="relatedSubstances"
        [singleEndpointRecord]="endpoint"
        [substanceTypes]="substanceTypes"
        [substance]="substance"
      >
      </app-stot>
    </div>
    <div *ngSwitchCase="'Genotoxicity'">
      <app-genotoxicity
        (copyInput)="copyEndpointInput($event)"
        (removeInput)="removeEndpointInput($event)"
        *ngFor="let endpoint of categoryEndpointsArrayList"
        [allAdditionalFieldValues]="receivedAdditionalFieldValues"
        [allAdditionalFields]="receivedAllAdditionalFields"
        [category]="category"
        [documents]="documents"
        [relatedSubstances]="relatedSubstances"
        [singleEndpointRecord]="endpoint"
        [substanceTypes]="substanceTypes"
        [substance]="substance"
      >
      </app-genotoxicity>
    </div>
    <div *ngSwitchCase="'Carcinogenicity'">
      <app-carcinogenicity
        (copyInput)="copyEndpointInput($event)"
        (removeInput)="removeEndpointInput($event)"
        *ngFor="let endpoint of categoryEndpointsArrayList"
        [allAdditionalFieldValues]="receivedAdditionalFieldValues"
        [allAdditionalFields]="receivedAllAdditionalFields"
        [allEndpoints]="defaultAllEndpoints"
        [allStudyTypes]="defaultAllStudyTypes"
        [allTestSystemTypes]="allTestSystemTypes"
        [allRouteOfAdministration]="defaultAllRouteOfAdministration"
        [allTestSystemNames]="allTestSystemNames"
        [category]="category"
        [documents]="documents"
        [relatedSubstances]="relatedSubstances"
        [singleEndpointRecord]="endpoint"
        [substanceTypes]="substanceTypes"
        [substance]="substance"
      >
      </app-carcinogenicity>
    </div>
    <div *ngSwitchCase="'Reproduction Developmental Toxicity'">
      <app-reproduction-developmental-toxicity
        (copyInput)="copyEndpointInput($event)"
        (removeInput)="removeEndpointInput($event)"
        *ngFor="let endpoint of categoryEndpointsArrayList"
        [allAdditionalFieldValues]="receivedAdditionalFieldValues"
        [allAdditionalFields]="receivedAllAdditionalFields"
        [allEndpoints]="defaultAllEndpoints"
        [allStudyTypes]="defaultAllStudyTypes"
        [allTestSystemTypes]="allTestSystemTypes"
        [allRouteOfAdministration]="defaultAllRouteOfAdministration"
        [allTestSystemNames]="allTestSystemNames"
        [category]="category"
        [documents]="documents"
        [relatedSubstances]="relatedSubstances"
        [singleEndpointRecord]="endpoint"
        [substanceTypes]="substanceTypes"
        [substance]="substance"
      >
      </app-reproduction-developmental-toxicity>
    </div>
    <div *ngSwitchCase="'Neuro/Immunotoxicity'">
      <app-neuro-immunotoxicity
        (copyInput)="copyEndpointInput($event)"
        (removeInput)="removeEndpointInput($event)"
        *ngFor="let endpoint of categoryEndpointsArrayList"
        [allAdditionalFieldValues]="receivedAdditionalFieldValues"
        [allAdditionalFields]="receivedAllAdditionalFields"
        [allEndpoints]="defaultAllEndpoints"
        [allStudyTypes]="defaultAllStudyTypes"
        [allTestSystemTypes]="allTestSystemTypes"
        [allRouteOfAdministration]="defaultAllRouteOfAdministration"
        [allTestSystemNames]="allTestSystemNames"
        [category]="category"
        [documents]="documents"
        [relatedSubstances]="relatedSubstances"
        [singleEndpointRecord]="endpoint"
        [substanceTypes]="substanceTypes"
        [substance]="substance"
      >
      </app-neuro-immunotoxicity>
    </div>
    <div *ngSwitchCase="'Endocrine Disruption'">
      <app-endocrine-disruption
        (copyInput)="copyEndpointInput($event)"
        (removeInput)="removeEndpointInput($event)"
        *ngFor="let endpoint of categoryEndpointsArrayList"
        [allAdditionalFieldValues]="receivedAdditionalFieldValues"
        [allAdditionalFields]="receivedAllAdditionalFields"
        [allStudyTypes]="defaultAllStudyTypes"
        [allEndpoints]="defaultAllEndpoints"
        [allTestSystemTypes]="allTestSystemTypes"
        [allRouteOfAdministration]="defaultAllRouteOfAdministration"
        [allTestSystemNames]="allTestSystemNames"
        [category]="category"
        [documents]="documents"
        [relatedSubstances]="relatedSubstances"
        [singleEndpointRecord]="endpoint"
        [substanceTypes]="substanceTypes"
        [substance]="substance"
      >
      </app-endocrine-disruption>
    </div>
    <div *ngSwitchCase="'Metabolites/Impurities'">
      <app-metabolites-impurities
        (copyInput)="copyEndpointInput($event)"
        (removeInput)="removeEndpointInput($event)"
        *ngFor="let endpoint of categoryEndpointsArrayList"
        [allAdditionalFieldValues]="receivedAdditionalFieldValues"
        [allAdditionalFields]="receivedAllAdditionalFields"
        [allEndpointUnits]="defaultAllEndpointUnits"
        [allEndpoints]="defaultAllEndpoints"
        [allStudyTypes]="defaultAllStudyTypes"
        [allTestSystemNames]="allTestSystemNames"
        [allRouteOfAdministration]="defaultAllRouteOfAdministration"
        [category]="category"
        [documents]="documents"
        [allOperators]="operators"
        [relatedSubstances]="relatedSubstances"
        [singleEndpointRecord]="endpoint"
        [substanceTypes]="substanceTypes"
        [substance]="substance"
      >
      </app-metabolites-impurities>
    </div>
    <div *ngSwitchCase="'Reference Dose Levels'">
      <app-reference-dose-levels
        (copyInput)="copyEndpointInput($event)"
        (removeInput)="removeEndpointInput($event)"
        *ngFor="let endpoint of categoryEndpointsArrayList"
        [allAdditionalFieldValues]="receivedAdditionalFieldValues"
        [allAdditionalFields]="receivedAllAdditionalFields"
        [allEndpointUnits]="defaultAllEndpointUnits"
        [allEndpoints]="defaultAllEndpoints"
        [allStudyTypes]="defaultAllStudyTypes"
        [category]="category"
        [documents]="documents"
        [allOperators]="operators"
        [relatedSubstances]="relatedSubstances"
        [singleEndpointRecord]="endpoint"
        [substanceTypes]="substanceTypes"
        [substance]="substance"
      >
      </app-reference-dose-levels>
    </div>
    <div *ngSwitchCase="'Occupational Risk Assessment'">
      <app-occupational-risk-assessment
        (copyInput)="copyEndpointInput($event)"
        (removeInput)="removeEndpointInput($event)"
        *ngFor="let endpoint of categoryEndpointsArrayList"
        [allAdditionalFieldValues]="receivedAdditionalFieldValues"
        [allAdditionalFields]="receivedAllAdditionalFields"
        [allEndpointUnits]="defaultAllEndpointUnits"
        [allEndpoints]="defaultAllEndpoints"
        [allStudyTypes]="defaultAllStudyTypes"
        [allTestSystemTypes]="allTestSystemTypes"
        [category]="category"
        [documents]="documents"
        [allOperators]="operators"
        [relatedSubstances]="relatedSubstances"
        [singleEndpointRecord]="endpoint"
        [substanceTypes]="substanceTypes"
        [substance]="substance"
      >
      </app-occupational-risk-assessment>
    </div>
  </div>
  <div class="orangeLine"></div>
</div>
