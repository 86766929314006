import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatBadgeModule} from '@angular/material/badge';
import {MatDialogModule} from '@angular/material/dialog';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatRadioModule} from '@angular/material/radio';
import {MatStepperModule} from '@angular/material/stepper';
import {MatTableModule} from '@angular/material/table';
import {NgModule} from '@angular/core';
import {EndpointRoutingModule} from './endpoint-routing.module';
import {EndpointHomeComponent} from './endpoint-home/endpoint-home.component';
import {EfateComponent} from './environmental-fate/efate/efate.component';
import {EcotoxComponent} from './ecotoxicology/ecotox/ecotox.component';
import {MatButtonModule} from '@angular/material/button';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatPaginatorModule} from '@angular/material/paginator';
import {ToxComponent} from './toxicology/tox-page/tox.component';
import {ConsumerSafetyComponent} from './consumer-safety/consumer-safety-page/consumer-safety.component';
import {EfateCategoryComponent} from './environmental-fate/efate-category/efate-category.component';
import {EcotoxCategoryComponent} from './ecotoxicology/ecotox-category/ecotox-category.component';
import {EFateRecordComponent} from './environmental-fate/e-fate-record/e-fate-record.component';
import {ExistingEndpointsComponent} from './environmental-fate/existing-endpoints/existing-endpoints.component';
import {ExistingEfateEndpointRecordComponent} from './environmental-fate/existing-efate-endpoint-record/existing-efate-endpoint-record.component';
import {MatSelectModule} from '@angular/material/select';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatNativeDateModule} from '@angular/material/core';
import {MatChipsModule} from '@angular/material/chips';
import {MatIconModule} from '@angular/material/icon';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {EcotoxRecordComponent} from './ecotoxicology/ecotox-record/ecotox-record.component';
import {ExistingEcotoxEndpointRecordComponent} from './ecotoxicology/existing-ecotox-endpoint-record/existing-ecotox-endpoint-record.component';
import {ExistingEcotoxEndpointsComponent} from './ecotoxicology/existing-ecotox-endpoints/existing-ecotox-endpoints.component';
import {ToxCategoryComponent} from './toxicology/tox-category/tox-category.component';
import {ExistingToxEndpointsComponent} from './toxicology/existing-tox-endpoints/existing-tox-endpoints.component';
import {ExistingToxEndpointRecordComponent} from './toxicology/existing-tox-endpoint-record/existing-tox-endpoint-record.component';
import {ConsumerSafetyCategoryComponent} from './consumer-safety/consumer-safety-category/consumer-safety-category.component';
import {ConsumerSafetyExistingComponent} from './consumer-safety/consumer-safety-existing/consumer-safety-existing.component';
import {ConsumerSafetyRecordExistingComponent} from './consumer-safety/consumer-safety-record-existing/consumer-safety-record-existing.component';
import {PhysChemDataPageComponent} from './phys-chem-data/phys-chem-data-page/phys-chem-data-page.component';
import {PhysChemDataCategoryComponent} from './phys-chem-data/phys-chem-data-category/phys-chem-data-category.component';
import {PhysChemDataRecordComponent} from './phys-chem-data/phys-chem-data-record/phys-chem-data-record.component';
import {PhysChemDataCategoryExistingComponent} from './phys-chem-data/phys-chem-data-category-existing/phys-chem-data-category-existing.component';
import {PhysChemDataRecordExistingComponent} from './phys-chem-data/phys-chem-data-record-existing/phys-chem-data-record-existing.component';
import {MetabolismComponent} from './consumer-safety/new-categories/metabolism/metabolism.component';
import {ResidueDefinitionComponent} from './consumer-safety/new-categories/residue-definition/residue-definition.component';
import {StabilityOfResiduesComponent} from './consumer-safety/new-categories/stability-of-residues/stability-of-residues.component';
import {MagnitudeOfResidueComponent} from './consumer-safety/new-categories/magnitude-of-residue/magnitude-of-residue.component';
import {DietaryRiskAssessmentComponent} from './consumer-safety/new-categories/dietary-risk-assessment/dietary-risk-assessment.component';
import {AcuteToxicityComponent} from './toxicology/categories/acute-toxicity/acute-toxicity.component';
import {StotComponent} from './toxicology/categories/stot/stot.component';
import {GenotoxicityComponent} from './toxicology/categories/genotoxicity/genotoxicity.component';
import {CarcinogenicityComponent} from './toxicology/categories/carcinogenicity/carcinogenicity.component';
import {ReproductionDevelopmentalToxicityComponent} from './toxicology/categories/reproduction-developmental-toxicity/reproduction-developmental-toxicity.component';
import {NeuroImmunotoxicityComponent} from './toxicology/categories/neuro-immunotoxicity/neuro-immunotoxicity.component';
import {EndocrineDisruptionComponent} from './toxicology/categories/endocrine-disruption/endocrine-disruption.component';
import {MetabolitesImpuritiesComponent} from './toxicology/categories/metabolites-impurities/metabolites-impurities.component';
import {ReferenceDoseLevelsComponent} from './toxicology/categories/reference-dose-levels/reference-dose-levels.component';
import {OccupationalRiskAssessmentComponent} from './toxicology/categories/occupational-risk-assessment/occupational-risk-assessment.component';
import {AcuteToxicityExistingComponent} from './toxicology/existing-categories/acute-toxicity-existing/acute-toxicity-existing.component';
import {CarcinogenicityExistingComponent} from './toxicology/existing-categories/carcinogenicity-existing/carcinogenicity-existing.component';
import {EndocrineDisruptionExistingComponent} from './toxicology/existing-categories/endocrine-disruption-existing/endocrine-disruption-existing.component';
import {GenotoxicityExistingComponent} from './toxicology/existing-categories/genotoxicity-existing/genotoxicity-existing.component';
import {StotExistingComponent} from './toxicology/existing-categories/stot-existing/stot-existing.component';
import {ReproductionDevelopmentalToxicityExistingComponent} from './toxicology/existing-categories/reproduction-developmental-toxicity-existing/reproduction-developmental-toxicity-existing.component';
import {NeuroImmunotoxicityExistingComponent} from './toxicology/existing-categories/neuro-immunotoxicity-existing/neuro-immunotoxicity-existing.component';
import {MetabolitesImpuritiesExistingComponent} from './toxicology/existing-categories/metabolites-impurities-existing/metabolites-impurities-existing.component';
import {ReferenceDoseLevelsExistingComponent} from './toxicology/existing-categories/reference-dose-levels-existing/reference-dose-levels-existing.component';
import {OccupationalRiskAssessmentExistingComponent} from './toxicology/existing-categories/occupational-risk-assessment-existing/occupational-risk-assessment-existing.component';
import {MatDialogComponent} from './mat-dialog/mat-dialog.component';
import { MetabolismExistingComponent } from './consumer-safety/existing-categories/metabolism-existing/metabolism-existing.component';
import { ResidueDefinitionExistingComponent } from './consumer-safety/existing-categories/residue-definition-existing/residue-definition-existing.component';
import { StabilityOfResidueExistingComponent } from './consumer-safety/existing-categories/stability-of-residue-existing/stability-of-residue-existing.component';
import { MagnitudeOfResidueExistingComponent } from './consumer-safety/existing-categories/magnitude-of-residue-existing/magnitude-of-residue-existing.component';
import { DietaryRiskAssessmentExistingComponent } from './consumer-safety/existing-categories/dietary-risk-assessment-existing/dietary-risk-assessment-existing.component';
import { WaiverAndDataGapsExistingComponent } from './consumer-safety/existing-categories/waiver-and-data-gaps-existing/waiver-and-data-gaps-existing.component';
import { PhysChemDataAllCategoriesExistingComponent } from './phys-chem-data/existing-categories/phys-chem-data-all-categories-existing/phys-chem-data-all-categories-existing.component';
import { EcotoxAllCategoriesExistingComponent } from './ecotoxicology/existing-categories/ecotox-all-categories-existing/ecotox-all-categories-existing.component';
import { EfateAllCategoriesExistingComponent } from './environmental-fate/existing-categories/efate-all-categories-existing/efate-all-categories-existing.component';
import { WaiverAndDataGapsComponent } from './consumer-safety/new-categories/waiver-and-data-gaps/waiver-and-data-gaps.component';
import { NatureOfResiduesComponent } from './consumer-safety/new-categories/nature-of-residues/nature-of-residues.component';
import { NatureOfResiduesExistingComponent } from "./consumer-safety/existing-categories/nature-of-residues-existing/nature-of-residues-existing.component";

@NgModule({
    declarations: [
        EndpointHomeComponent,
        EfateComponent,
        EcotoxComponent,
        ToxComponent,
        ConsumerSafetyComponent,
        EfateCategoryComponent,
        EcotoxCategoryComponent,
        EFateRecordComponent,
        ExistingEndpointsComponent,
        ExistingEfateEndpointRecordComponent,
        EcotoxRecordComponent,
        ExistingEcotoxEndpointRecordComponent,
        ExistingEcotoxEndpointsComponent,
        ToxCategoryComponent,
        ExistingToxEndpointsComponent,
        ExistingToxEndpointRecordComponent,
        ConsumerSafetyCategoryComponent,
        ConsumerSafetyExistingComponent,
        ConsumerSafetyRecordExistingComponent,
        PhysChemDataPageComponent,
        PhysChemDataCategoryComponent,
        PhysChemDataRecordComponent,
        PhysChemDataCategoryExistingComponent,
        PhysChemDataRecordExistingComponent,
        MetabolismComponent,
        ResidueDefinitionComponent,
        StabilityOfResiduesComponent,
        DietaryRiskAssessmentComponent,
        AcuteToxicityComponent,
        StotComponent,
        GenotoxicityComponent,
        CarcinogenicityComponent,
        ReproductionDevelopmentalToxicityComponent,
        NeuroImmunotoxicityComponent,
        EndocrineDisruptionComponent,
        MetabolitesImpuritiesComponent,
        ReferenceDoseLevelsComponent,
        OccupationalRiskAssessmentComponent,
        MetabolitesImpuritiesComponent,
        AcuteToxicityExistingComponent,
        CarcinogenicityExistingComponent,
        EndocrineDisruptionExistingComponent,
        GenotoxicityExistingComponent,
        StotExistingComponent,
        ReproductionDevelopmentalToxicityExistingComponent,
        NeuroImmunotoxicityExistingComponent,
        MetabolitesImpuritiesExistingComponent,
        ReferenceDoseLevelsExistingComponent,
        OccupationalRiskAssessmentExistingComponent,
        MatDialogComponent,
        ResidueDefinitionExistingComponent,
        StabilityOfResidueExistingComponent,
        MagnitudeOfResidueExistingComponent,
        DietaryRiskAssessmentExistingComponent,
        WaiverAndDataGapsExistingComponent,
        PhysChemDataAllCategoriesExistingComponent,
        EcotoxAllCategoriesExistingComponent,
        EfateAllCategoriesExistingComponent,
        ExistingEfateEndpointRecordComponent,
        WaiverAndDataGapsComponent,
        MetabolismExistingComponent,
        NatureOfResiduesComponent,
        NatureOfResiduesExistingComponent,
        MagnitudeOfResidueComponent,
    ],
  imports: [
    CommonModule,
    EndpointRoutingModule,
    MatTableModule,
    MatDialogModule,
    MatAutocompleteModule,
    FormsModule,
    ReactiveFormsModule,
    MatStepperModule,
    MatRadioModule,
    MatProgressSpinnerModule,
    MatBadgeModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatExpansionModule,
    MatPaginatorModule,
    MatSelectModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatIconModule,
    MatChipsModule,
    MatProgressBarModule,
  ],

  exports: [
    // A11yModule,
    // ClipboardModule,
    // CdkStepperModule,
    // CdkTableModule,
    // CdkTreeModule,
    // DragDropModule,
    MatAutocompleteModule,
    // MatBadgeModule,
    // MatBottomSheetModule,
    // MatButtonModule,
    // MatButtonToggleModule,
    // MatCardModule,
    // MatCheckboxModule,
    MatChipsModule,
    // MatStepperModule,
    // MatDialogModule,
    // MatDividerModule,
    // MatGridListModule,
    // MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    // MatMenuModule,
    MatNativeDateModule,
    MatDatepickerModule,
    MatPaginatorModule,
    // MatProgressBarModule,
    // MatProgressSpinnerModule,
    // MatRadioModule,
    // MatRippleModule,
    MatSelectModule,
    // MatSidenavModule,
    // MatSliderModule,
    // MatSlideToggleModule,
    // MatSnackBarModule,
    // MatSortModule,
    // MatTableModule,
    // MatTabsModule,
    // MatToolbarModule,
    // MatTooltipModule,
    // MatTreeModule,
    // OverlayModule,
    // PortalModule,
    // ScrollingModule,
  ],
})
export class EndpointModule {
}
