<div [class.vaultThemeClass]="true" class="vaultThemeClass">
  <div class="header">
    <h3 class="title">Create & Manage Endpoints</h3>
  </div>

  <div class="navigationBar">
    <div (click)="pageToLeft()" class="toLeftArrow">
      <span class="material-icons">first_page</span>
    </div>

    <div (click)="pageToRight()" class="toRightArrow">
      <span class="material-icons">last_page</span>
    </div>
  </div>

  <div class="thisComponentContent">
    <form [formGroup]="selectFormGroup">

      <div class="singleFieldContainer">
        <div class="form-row">
          <div class="formItemTitle">Document:</div>
          <mat-form-field class="homepageAngularSearchWrapper">
            <input (click)="documentInputToNone()"
                   [formControl]="searchControl"
                   [matAutocomplete]="auto"
                   aria-label="Document"
                   matInput
                   placeholder="Select or search"
                   type="text"
            >
            <mat-autocomplete #auto="matAutocomplete">
              <mat-option (onSelectionChange)="selectDocument(document)"
                          *ngFor="let document of filteredOptions | async" [value]="document.name"
                          class="angularFormSelector">
                {{document.name}}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </div>
      </div>

      <div class="singleFieldContainer">
        <form>
          <div class="form-row">
            <div class="formItemTitle">Substance Name:</div>
            <div>
              <div class="formLink" title="{{substanceItem.typeLabel}}">
                {{ substanceItem.name }}
              </div>
            </div>
          </div>
        </form>
      </div>

      <div class="singleFieldContainer">
        <div class="form-row">
          <div class="formItemTitle">Work Area:</div>
          <mat-form-field class="homepageAngularSearchWrapper">
            <mat-label>Work Area</mat-label>
            <mat-select [(ngModel)]="WorkAreaSelectedLabel" formControlName="workArea" required>
              <mat-option *ngFor="let area of workAreas"
                          [value]="area.name__v"
                          class="angularFormSelector">
                {{ area.name__v }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>

    </form>

    <div [ngSwitch]="WorkAreaSelectedLabel" class="componentContent">
      <div *ngSwitchDefault class="componentContent"></div>
      <app-efate *ngSwitchCase="'Environmental Fate'"
                 [documents]="documents"
                 [relatedSubstances]="relatedSubstances"
                 [substanceTypes]="substanceTypes"
                 [substance]="substanceItem"
                 [workAreaLabel]="WorkAreaSelectedLabel"
                 [workAreas]="workAreas"
                 class="componentContent"
      >
      </app-efate>
      <app-ecotox *ngSwitchCase="'Ecotoxicology'"
                  [documents]="documents"
                  [relatedSubstances]="relatedSubstances"
                  [substanceTypes]="substanceTypes"
                  [substance]="substanceItem"
                  [workAreaLabel]="WorkAreaSelectedLabel"
                  [workAreas]="workAreas"
                  class="componentContent"
      >
      </app-ecotox>
      <app-tox *ngSwitchCase="'Toxicology'"
               [documents]="documents"
               [relatedSubstances]="relatedSubstances"
               [substanceTypes]="substanceTypes"
               [substance]="substanceItem"
               [workAreaLabel]="WorkAreaSelectedLabel"
               [workAreas]="workAreas"
               class="componentContent"
      >
      </app-tox>
      <app-consumer-safety *ngSwitchCase="'Consumer Safety'"
                           [documents]="documents"
                           [relatedSubstances]="relatedSubstances"
                           [substanceTypes]="substanceTypes"
                           [substance]="substanceItem"
                           [workAreaLabel]="WorkAreaSelectedLabel"
                           [workAreas]="workAreas"
                           class="componentContent"
      ></app-consumer-safety>

      <app-phys-chem-data-page *ngSwitchCase="'Phys/Chem Data'"
                               [documents]="documents"
                               [relatedSubstances]="relatedSubstances"
                               [substanceTypes]="substanceTypes"
                               [substance]="substanceItem"
                               [workAreaLabel]="WorkAreaSelectedLabel"
                               [workAreas]="workAreas"
                               class="componentContent"
      ></app-phys-chem-data-page>

    </div>

    <div class="footer"></div>
    <div class="spacer"></div>
  </div>
</div>

