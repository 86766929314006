/**
 *  Convert object properties to string if they are array.
 *  Special fields (multi-value) are left out.
 */


export function convertArrayToString(source: any, target: any, specialFields: string[]): void {
  for (const key of Object.keys(source)) {
    const value = source[key];
    if (value instanceof Array && !specialFields.includes(key)) {
      target[key] = value.join(',');
    }
  }
}
