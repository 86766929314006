import {Component, Input, OnInit} from '@angular/core';
import {NavigationService} from "../../../core/navigation/navigation.service";
import {Router} from "@angular/router";
import {VaultService} from "../../service/vault.service";
import {MatDialog} from "@angular/material/dialog";
import {WarningDialogComponent} from "../warning-dialog/warning-dialog.component";

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  url: string;

  @Input()
  queryParams: any;

  constructor(
    private navService: NavigationService,
    private router: Router,
    private service: VaultService,
    public dialog: MatDialog
  ) {
  }

  ngOnInit(): void {
    this.navService.currentUrl.subscribe(url => {
      this.url = url;
    });
  }

  isSelected(link: string): boolean {
    if (this.url != undefined) {
      return this.url.indexOf(link) !== -1;
    }
  }

  isDisabled(): boolean {
    return this.service.evaluations === undefined || this.service.evaluations.length == 0;

  }

  dashboardClicked(): void {
    if (this.isDisabled()) {
      const dialogRef = this.dialog.open(WarningDialogComponent, {
        data: {
          title: 'Please select at least one evaluation before proceeding to dashboard.',
          showCancel: false
        },
        height: '250px'
      });
      dialogRef.afterOpened().subscribe(_ => {
        setTimeout(() => {
          dialogRef.close();
        }, 2000)
      })
    }
  }

}
