<div class="table-container">
  <div *ngIf="(showLoading);else table" class="ball-loader">
    <div class="ball-loader-ball ball1"></div>
    <div class="ball-loader-ball ball2"></div>
    <div class="ball-loader-ball ball3"></div>
  </div>
  <ng-template #table>
    <div class="buttons-container">
      <button (click)="downloadExcel()" data-cy="download-excel-button">Download Excel</button>
    </div>
    <table [dataSource]="dataSource" class="mat-elevation-z0" data-cy="table" mat-table>

      <!--- Note that these columns can be defined in any order.
                The actual rendered columns are set as a property on the row definition" -->

      <!-- Last Modified Date column-->
      <ng-container data-cy="columnLastModifiedDate" matColumnDef="Last Modified Date">
        <th *matHeaderCellDef mat-header-cell matTooltip="Last Modified Date"> Last Modified Date</th>
        <td *matCellDef="let element" mat-cell>{{element.modified_date__v}}</td>
      </ng-container>

      <!-- Expiration date column-->
      <ng-container data-cy="columnExpirationDate" matColumnDef="Expiration Date">
        <th *matHeaderCellDef mat-header-cell matTooltip="Expiration Date"> Expiration Date</th>
        <td *matCellDef="let element" mat-cell>{{element.expiry_date__c}}</td>
      </ng-container>

      <!-- Registration Timeline column-->
      <ng-container data-cy="columnRegistrationTimeline" matColumnDef="Registration Timeline">
        <th *matHeaderCellDef mat-header-cell matTooltip="Registration Timeline"> Registration Timeline</th>
        <td *matCellDef="let element" mat-cell>{{element.registration_timeline}}</td>
      </ng-container>

      <!-- Application column-->
      <ng-container data-cy="columnApplication" matColumnDef="Application">
        <th *matHeaderCellDef mat-header-cell matTooltip="Application"> Application</th>
        <td *matCellDef="let element" mat-cell>{{element.application_date__c}}</td>
      </ng-container>

      <!-- Submission column-->
      <ng-container data-cy="columnSubmission" matColumnDef="Submission">
        <th *matHeaderCellDef mat-header-cell matTooltip="Submission"> Submission</th>
        <td *matCellDef="let element" mat-cell>{{element.actual_submission_date__c}}</td>
      </ng-container>

      <!-- DAR/dRAR column-->
      <ng-container data-cy="columnDAR/dRAR" matColumnDef="DAR/dRAR">
        <th *matHeaderCellDef mat-header-cell matTooltip="DAR/dRAR"> DAR/dRAR</th>
        <td *matCellDef="let element" mat-cell>{{element.dardrar_date__c}}</td>
      </ng-container>

      <!-- EFSA Conclusion column-->
      <ng-container data-cy="columnEFSAConclusion" matColumnDef="EFSA Conclusion">
        <th *matHeaderCellDef mat-header-cell matTooltip="EFSA Conclusion"> EFSA Conclusion</th>
        <td *matCellDef="let element" mat-cell>{{element.efsa_conclusion_date__c}}</td>
      </ng-container>

      <!-- 1st Commission Proposal column-->
      <ng-container data-cy="column1stCommissionProposal" matColumnDef="1st Commission Proposal">
        <th *matHeaderCellDef mat-header-cell matTooltip="1st Commission Proposal"> 1st Commission Proposal</th>
        <td *matCellDef="let element" mat-cell>{{element["1st_commission_proposal_date__c"]}}</td>
      </ng-container>

      <!-- Entry into Force Date column-->
      <ng-container data-cy="columnEntryIntoForceDate" matColumnDef="Entry into Force Date">
        <th *matHeaderCellDef mat-header-cell matTooltip="Entry into Force Date"> Entry into Force Date</th>
        <td *matCellDef="let element" mat-cell>{{element.entry_into_force__c}}</td>
      </ng-container>

      <!-- EFSA Conclusion Deadline column-->
      <ng-container data-cy="columnEFSAConclusionDeadline" matColumnDef="EFSA Conclusion Deadline">
        <th *matHeaderCellDef mat-header-cell matTooltip="EFSA Conclusion Deadline"> EFSA Conclusion Deadline</th>
        <td *matCellDef="let element" mat-cell>{{element.efsa_conclusion_deadline__c}}</td>
      </ng-container>

      <!-- Decision Type column-->
      <ng-container data-cy="columnDecisionType" matColumnDef="Decision Type">
        <th *matHeaderCellDef mat-header-cell matTooltip="Decision Type"> Decision Type</th>
        <td *matCellDef="let element" mat-cell>{{element.decision_type__c}}</td>
      </ng-container>

      <!-- Public Consultation Open column-->
      <ng-container data-cy="columnPublicConsultationOpen" matColumnDef="Public Consultation Open">
        <th *matHeaderCellDef mat-header-cell matTooltip="Public Consultation Open"> Public Consultation Open</th>
        <td *matCellDef="let element" mat-cell>{{element.public_consultation_open_date__c}}</td>
      </ng-container>

      <!-- Public Consultation Close column-->
      <ng-container data-cy="columnPublicConsultationClose" matColumnDef="Public Consultation Close">
        <th *matHeaderCellDef mat-header-cell matTooltip="Public Consultation Close"> Public Consultation Close</th>
        <td *matCellDef="let element" mat-cell>{{element.public_consultation_close_date__c}}</td>
      </ng-container>

      <!-- Latest Confirmatory Data Deadline column-->
      <ng-container data-cy="columnConfirmatoryDataSubmission" matColumnDef="Latest Confirmatory Data Deadline">
        <th *matHeaderCellDef mat-header-cell matTooltip="Latest Confirmatory Data Deadline"> Latest Confirmatory Data Deadline
        </th>
        <td *matCellDef="let element" mat-cell>{{element.latest_confirmatory_data_deadline__c}}</td>
      </ng-container>

      <tr *matHeaderRowDef="displayedColumns; sticky: true" mat-header-row></tr>
      <tr *matRowDef="let row; columns: displayedColumns;" mat-row></tr>
    </table>
    <mat-paginator [length]="dataSourceLength" [pageSizeOptions]="[10, 20, 50, 100, 500]" [pageSize]="pageSize"
                   class="mat-elevation-z0 paginator" showFirstLastButtons></mat-paginator>
  </ng-template>
</div>
