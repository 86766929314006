<div class="existingEndpointsComponent">

  <mat-accordion>
    <mat-expansion-panel (closed)="destroyEndpoints(); panelOpenState = false"
                         (opened)="getEndpointsWithFilters(this.pageSize, 0); panelOpenState = true"
                         [expanded]="expandPanel"
                         style="border:none; box-shadow: none;">
      <mat-expansion-panel-header class="expansionHeader">
        <mat-panel-title class="expansionTitle">
          Existing "{{workAreaName}}" Endpoints With "{{substance.name}}" Substance.
        </mat-panel-title>
      </mat-expansion-panel-header>

      <ng-container>

        <div class="filterChipsRow">

          <mat-form-field class="example-chip-list">
            <mat-label>Filters</mat-label>
            <mat-chip-list #chipList aria-label="Fields selection">
              <mat-chip (removed)="remove(field)"
                        *ngFor="let field of fields"
                        [removable]="removable"
                        [selectable]="selectable"
                        class="angularFormChipValue">
                {{field}}
                <mat-icon *ngIf="removable" matChipRemove>cancel</mat-icon>
              </mat-chip>
              <input
                #fieldInput
                (matChipInputTokenEnd)="add($event)"
                [formControl]="fieldCtrl"
                [matAutocomplete]="auto"
                [matChipInputFor]="chipList"
                [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                class="angularFormChipValue" placeholder="New filter...">
            </mat-chip-list>

            <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">

              <ng-container *ngFor="let field of filteredFields | async">
                <mat-option *ngIf="!fields.includes(field)" [value]="field" class="angularFormSelector">
                  {{field}}
                </mat-option>
              </ng-container>
            </mat-autocomplete>
          </mat-form-field>
        </div>

        <div class="filterFieldsRowSELECTED" id="filterFields">

          <div class="singleFieldContainer" id='Category'>
            <mat-form-field class="angularSearchWrapper">
              <mat-label>Category</mat-label>
              <mat-select (selectionChange)="picklistValueToArray('category__c', 'Category', category__c)"
                          [(ngModel)]="category__c">
                <mat-option value="">All</mat-option>
                <mat-option *ngFor="let category of receivedCategories" [value]=" category.id">
                  {{ category.name__v}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="singleFieldContainer" id='Related Substance'>
            <mat-form-field class="angularSearchWrapper">
              <mat-label>Related Substance</mat-label>
              <mat-select
                (selectionChange)="picklistValueToArray('related_substance__c', 'Related Substance', related_substance__c)"
                [(ngModel)]="related_substance__c">
                <mat-option value="">All</mat-option>
                <mat-option *ngFor="let relatedSubstance of relatedSubstances" [value]=" relatedSubstance.id">
                  {{ relatedSubstance.name}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

        </div>

        <div class="filterFieldsRow" id="preparedFilterFields">

          <div class="singleFieldContainer" id='Substance Type'>
            <mat-form-field class="angularSearchWrapper">
              <mat-label>Substance Type</mat-label>
              <mat-select
                (selectionChange)="picklistValueToArray('substance_type__c', 'Substance Type', substance_type__c)"
                [(ngModel)]="substance_type__c">
                <mat-option value="">All</mat-option>
                <mat-option *ngFor="let substanceType of substanceTypes" [value]=" substanceType.name">
                  {{ substanceType.label}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="singleFieldContainer" id='Study Type'>
            <mat-form-field class="angularSearchWrapper">
              <mat-label>Study Type</mat-label>
              <mat-select (selectionChange)="picklistValueToArray('study_type__c', 'Study Type', study_type__c)"
                          [(ngModel)]="study_type__c">
                <mat-option value="">All</mat-option>
                <mat-option *ngFor="let filterStudyType of uniqueStudyTypes" [value]=" filterStudyType.name">
                  {{ filterStudyType.label}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="singleFieldContainer" id='Endpoint'>
            <mat-form-field class="angularSearchWrapper">
              <mat-label>Endpoint</mat-label>
              <mat-select (selectionChange)="picklistValueToArray('endpoint__c', 'Endpoint', endpoint__c)"
                          [(ngModel)]="endpoint__c">
                <mat-option value="">All</mat-option>
                <mat-option *ngFor="let filterEndpoint of uniqueEndpoints" [value]=" filterEndpoint.name">
                  {{ filterEndpoint.label}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="singleFieldContainer" id='Endpoint Unit'>
            <mat-form-field class="angularSearchWrapper">
              <mat-label>Endpoint Unit</mat-label>
              <mat-select
                (selectionChange)="picklistValueToArray('endpoint_unit__c', 'Endpoint Unit', endpoint_unit__c)"
                [(ngModel)]="endpoint_unit__c">
                <mat-option value="">All</mat-option>
                <mat-option *ngFor="let filterEndpointUnit of uniqueEndpointUnits" [value]=" filterEndpointUnit.name">
                  {{ filterEndpointUnit.label}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="singleFieldContainer" id='Operator'>
            <mat-form-field class="angularSearchWrapper">
              <mat-label>Operator</mat-label>
              <mat-select (selectionChange)="picklistValueToArray('operator__c', 'Operator', operator__c)"
                          [(ngModel)]="operator__c">
                <mat-option value="">All</mat-option>
                <mat-option *ngFor="let filterOperator of receivedAllOperators" [value]=" filterOperator.name">
                  {{ filterOperator.label}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="singleFieldContainer" id='Value 1'>
            <mat-form-field class="angularSearchWrapper">
              <mat-label>Value 1</mat-label>
              <input (change)="textFieldValueToArray('value_1__c', 'Value 1')"
                     (keyup)="textFieldValueToArray('value_1__c', 'Value 1')" [(ngModel)]="value_1__c"
                     class="orangeTextFieldInput" id="value_1__c" matInput type="number">
            </mat-form-field>
          </div>

          <div class="singleFieldContainer" id='Value 2'>
            <mat-form-field class="angularSearchWrapper">
              <mat-label>Value 2</mat-label>
              <input (change)="textFieldValueToArray('value_2__c', 'Value 2')"
                     (keyup)="textFieldValueToArray('value_2__c', 'Value 2')" [(ngModel)]="value_2__c"
                     class="orangeTextFieldInput" id="value_2__c" matInput type="number">
            </mat-form-field>
          </div>

          <div class="singleFieldContainer" id="Created Date Range">
            <mat-form-field class="angularSearchWrapper">
              <mat-label>Created Date Range</mat-label>
              <mat-date-range-input [formGroup]="createdRange" [rangePicker]="picker">
                <input [(ngModel)]="created_date_start" formControlName="createdDateStart" matStartDate
                       placeholder="Start date">
                <input [(ngModel)]="created_date_end" formControlName="createdDateEnd" matEndDate
                       placeholder="End date">
              </mat-date-range-input>
              <mat-datepicker-toggle [for]="picker" matSuffix></mat-datepicker-toggle>
              <mat-date-range-picker #picker color="accent"></mat-date-range-picker>

              <mat-error *ngIf="createdRange.controls.createdDateStart.hasError('matStartDateInvalid')">Invalid start
                date
              </mat-error>
              <mat-error *ngIf="createdRange.controls.createdDateEnd.hasError('matEndDateInvalid')">Invalid end date
              </mat-error>
            </mat-form-field>
          </div>

          <div class="singleFieldContainer" id="Modified Date Range">
            <mat-form-field class="angularSearchWrapper">
              <mat-label>Modified Date Range</mat-label>
              <mat-date-range-input [formGroup]="modifiedRange" [rangePicker]="modifiedPicker">
                <input [(ngModel)]="modified_date_start" formControlName="modifiedDateStart" matStartDate
                       placeholder="Start date">
                <input [(ngModel)]="modified_date_end" formControlName="modifiedDateEnd" matEndDate
                       placeholder="End date">
              </mat-date-range-input>
              <mat-datepicker-toggle [for]="modifiedPicker" matSuffix></mat-datepicker-toggle>
              <mat-date-range-picker #modifiedPicker color="accent"></mat-date-range-picker>

              <mat-error *ngIf="modifiedRange.controls.modifiedDateStart.hasError('matStartDateInvalid')">Invalid start
                date
              </mat-error>
              <mat-error *ngIf="modifiedRange.controls.modifiedDateEnd.hasError('matEndDateInvalid')">Invalid end date
              </mat-error>
            </mat-form-field>
          </div>


          <div class="singleFieldContainer" id="Created By">
            <div class="form-row">
              <mat-form-field class="angularSearchWrapper">
                <input [(ngModel)]="created_by__v"
                       [formControl]="searchUserCreateControl"
                       [matAutocomplete]="autoCreatedUser"
                       aria-label="User"
                       matInput
                       placeholder="Created By" type="text"
                >
                <mat-autocomplete #autoCreatedUser="matAutocomplete">
                  <mat-option (onSelectionChange)="picklistValueToArray('created_by__v', 'Created By', '')"
                              [value]="All"
                              value="">All
                  </mat-option>
                  <mat-option (onSelectionChange)="picklistValueToArray('created_by__v', 'Created By', user.id)"
                              *ngFor="let user of filteredCreateUsers | async" [value]="user.name"
                              class="angularFormSelector">
                    {{user.name}}
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field>
            </div>
          </div>

          <div class="singleFieldContainer" id="Last Modified By">
            <div class="form-row">
              <mat-form-field class="angularSearchWrapper">
                <input [(ngModel)]="modified_by__v"
                       [formControl]="searchUserModifyControl"
                       [matAutocomplete]="autoModifiedUser"
                       aria-label="User"
                       matInput
                       placeholder="Last Modified By" type="text"
                >
                <mat-autocomplete #autoModifiedUser="matAutocomplete">
                  <mat-option (onSelectionChange)="picklistValueToArray('modified_by__v', 'Last Modified By', '')"
                              [value]="All"
                              value="">All
                  </mat-option>
                  <mat-option (onSelectionChange)="picklistValueToArray('modified_by__v', 'Last Modified By', user.id)"
                              *ngFor="let user of filteredModifiedUsers | async" [value]="user.name"
                              class="angularFormSelector">
                    {{user.name}}
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field>
            </div>
          </div>

          <div class="singleFieldContainer" id="Source">
            <mat-form-field class="angularSearchWrapper">
              <mat-label>Source</mat-label>
              <mat-select (selectionChange)="picklistValueToArray('document_unbound__c', 'Source', selectedDocumentId)"
                          [(ngModel)]="selectedDocumentId">
                <mat-option value="">All</mat-option>
                <mat-option *ngFor="let document of documents" [value]=" document.id">
                  {{ document.name}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>


          <div class="singleFieldContainer" id='Test System Type'>
            <mat-form-field class="angularSearchWrapper">
              <mat-label>Test System Type</mat-label>
              <mat-select
                (selectionChange)="picklistValueToArray('test_system_type__c', 'Test System Type', test_system_type__c)"
                [(ngModel)]="test_system_type__c">
                <mat-option value="">All</mat-option>
                <mat-option *ngFor="let testSystemType of uniqueTestSystemTypes" [value]=" testSystemType.name">
                  {{ testSystemType.label}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="singleFieldContainer" id='Test Species'>
            <mat-form-field class="angularSearchWrapper">
              <mat-label>Test Species</mat-label>
              <mat-select
                (selectionChange)="picklistValueToArray('test_species__c', 'Test Species', test_species__c)"
                [(ngModel)]="test_species__c">
                <mat-option value="">All</mat-option>
                <mat-option *ngFor="let testSpecie of uniqueTestpecies" [value]=" testSpecie.name">
                  {{ testSpecie.label}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="singleFieldContainer" id='Exposure Duration Unit'>
            <mat-form-field class="angularSearchWrapper">
              <mat-label>Exposure Duration Unit</mat-label>
              <mat-select
                (selectionChange)="picklistValueToArray('exposure_duration_unit__c', 'Exposure Duration Unit', exposure_duration_unit__c)"
                [(ngModel)]="exposure_duration_unit__c">
                <mat-option value="">All</mat-option>
                <mat-option
                  *ngFor="let exposureDurationUnit of filterAdditionalFieldValues(receivedAdditionalFieldValues, 'exposure_duration_unit__c')"
                  [value]="exposureDurationUnit.name">
                  {{exposureDurationUnit.label}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="singleFieldContainer" id='Exposure Design'>
            <mat-form-field class="angularSearchWrapper">
              <mat-label>Exposure Design</mat-label>
              <mat-select
                (selectionChange)="picklistValueToArray('exposure_design__c', 'Exposure Design', exposure_design__c)"
                [(ngModel)]="exposure_design__c">
                <mat-option value="">All</mat-option>
                <mat-option
                  *ngFor="let exposureDesignValue of uniqueExposureDesigns" [value]="exposureDesignValue.name">
                  {{exposureDesignValue.label}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="singleFieldContainer" id='ED Relevant'>
            <mat-form-field class="angularSearchWrapper">
              <mat-label>ED Relevant</mat-label>
              <mat-select (selectionChange)="picklistValueToArray('ed_relevant__c', 'ED Relevant', ed_relevant__c)"
                          [(ngModel)]="ed_relevant__c">
                <mat-option value="">All</mat-option>
                <mat-option value="yes__c">Yes</mat-option>
                <mat-option value="no__c">No</mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="singleFieldContainer" id='Trigger ED'>
            <mat-form-field class="angularSearchWrapper">
              <mat-label>Trigger ED</mat-label>
              <mat-select
                (selectionChange)="picklistValueToArray('trigger_ed__c', 'Trigger ED', trigger_ed__c)"
                [(ngModel)]="trigger_ed__c">
                <mat-option value="">All</mat-option>
                <mat-option
                  *ngFor="let triggerEDValue of filterAdditionalFieldValues(receivedAdditionalFieldValues, 'trigger_ed__c')"
                  [value]="triggerEDValue.name">
                  {{triggerEDValue.label}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="singleFieldContainer" id='ED Mechanisms'>
            <mat-form-field class="angularSearchWrapper">
              <mat-label>ED Mechanisms</mat-label>
              <mat-select
                (selectionChange)="picklistValueToArray('ed_mechanisms__c', 'ED Mechanisms', ed_mechanisms__c)"
                [(ngModel)]="ed_mechanisms__c">
                <mat-option value="">All</mat-option>
                <mat-option
                  *ngFor="let edMechanismsValue of uniqueEDMechanisms" [value]="edMechanismsValue.name">
                  {{edMechanismsValue.label}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="singleFieldContainer" id='Test Guideline'>
            <mat-form-field class="angularSearchWrapper">
              <mat-label>Test Guideline</mat-label>
              <mat-select
                (selectionChange)="picklistValueToArray('test_guideline__c', 'Test Guideline', test_guideline__c)"
                [(ngModel)]="test_guideline__c">
                <mat-option value="">All</mat-option>
                <mat-option
                  *ngFor="let testGuidelinesValue of uniqueTestGuidelines" [value]="testGuidelinesValue.name">
                  {{testGuidelinesValue.label}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="singleFieldContainer" id='Used in Risk Assessment'>
            <mat-form-field class="angularSearchWrapper">
              <mat-label>Used in Risk Assessment</mat-label>
              <mat-select
                (selectionChange)="picklistValueToArray('used_in_risk_assessment__c', 'Used in Risk Assessment', used_in_risk_assessment__c)"
                [(ngModel)]="used_in_risk_assessment__c">
                <mat-option value="">All</mat-option>
                <mat-option
                  *ngFor="let usedInRiskValue of filterAdditionalFieldValues(receivedAdditionalFieldValues, 'used_in_risk_assessment__c')"
                  [value]="usedInRiskValue.name">
                  {{usedInRiskValue.label}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="singleFieldContainer" id='Endpoint Analytically Based On'>
            <mat-form-field class="angularSearchWrapper">
              <mat-label>Endpoint Analytically Based On</mat-label>
              <mat-select
                (selectionChange)="picklistValueToArray('endpoint_analytically_based_on__c', 'Endpoint Analytically Based On', endpoint_analytically_based_on__c)"
                [(ngModel)]="endpoint_analytically_based_on__c">
                <mat-option value="">All</mat-option>
                <mat-option
                  *ngFor="let usedInRiskValue of filterAdditionalFieldValues(receivedAdditionalFieldValues, 'endpoint_analytically_based_on__c')"
                  [value]="usedInRiskValue.name">
                  {{usedInRiskValue.label}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="singleFieldContainer" id='T Trigger'>
            <mat-form-field class="angularSearchWrapper">
              <mat-label>T Trigger</mat-label>
              <mat-select
                (selectionChange)="picklistValueToArray('t_trigger__c', 'T Trigger', t_trigger__c)"
                [(ngModel)]="t_trigger__c">
                <mat-option value="">All</mat-option>
                <mat-option
                  *ngFor="let tTriggerValue of filterAdditionalFieldValues(receivedAdditionalFieldValues, 't_trigger__c')"
                  [value]="tTriggerValue.name">
                  {{tTriggerValue.label}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="singleFieldContainer" id='Affected ED - related parameter'>
            <mat-form-field class="angularSearchWrapper">
              <mat-label>Affected ED - related parameter</mat-label>
              <mat-select
                (selectionChange)="picklistValueToArray('affected_ed__c', 'Affected ED - related parameter', affected_ed__c)"
                [(ngModel)]="affected_ed__c">
                <mat-option value="">All</mat-option>
                <mat-option
                  *ngFor="let affectedEDValue of uniqueAffectedED"
                  [value]="affectedEDValue.name">
                  {{affectedEDValue.label}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="singleFieldContainer" id='TRR'>
            <mat-form-field class="angularSearchWrapper">
              <mat-label>TRR</mat-label>
              <mat-select
                (selectionChange)="picklistValueToArray('trr__c', 'TRR', trr__c)"
                [(ngModel)]="trr__c">
                <mat-option value="">All</mat-option>
                <mat-option value="yes__c">Yes</mat-option>
                <mat-option value="no__c">No</mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="singleFieldContainer" id='Normalization'>
            <mat-form-field class="angularSearchWrapper">
              <mat-label>Normalization</mat-label>
              <mat-select
                (selectionChange)="picklistValueToArray('normalization__c', 'Normalization', normalization__c)"
                [(ngModel)]="normalization__c">
                <mat-option value="">All</mat-option>
                <mat-option
                  *ngFor="let normalizationValue of filterAdditionalFieldValues(receivedAdditionalFieldValues, 'normalization__c')"
                  [value]="normalizationValue.name">
                  {{normalizationValue.label}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="singleFieldContainer" id='B Trigger'>
            <mat-form-field class="angularSearchWrapper">
              <mat-label>B Trigger</mat-label>
              <mat-select
                (selectionChange)="picklistValueToArray('b_trigger__c', 'B Trigger', b_trigger__c)"
                [(ngModel)]="b_trigger__c">
                <mat-option value="">All</mat-option>
                <mat-option
                  *ngFor="let bTriggerValue of filterAdditionalFieldValues(receivedAdditionalFieldValues, 'b_trigger__c')"
                  [value]="bTriggerValue.name">
                  {{bTriggerValue.label}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="singleFieldContainer" id='Substrate'>
            <mat-form-field class="angularSearchWrapper">
              <mat-label>Substrate</mat-label>
              <mat-select
                (selectionChange)="picklistValueToArray('substrate__c', 'Substrate', substrate__c)"
                [(ngModel)]="substrate__c">
                <mat-option value="">All</mat-option>
                <mat-option
                  *ngFor="let substrateValue of filterAdditionalFieldValues(receivedAdditionalFieldValues, 'substrate__c')"
                  [value]="substrateValue.name">
                  {{substrateValue.label}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="singleFieldContainer" id='Recovery Time Unit'>
            <mat-form-field class="angularSearchWrapper">
              <mat-label>Recovery Time Unit</mat-label>
              <mat-select
                (selectionChange)="picklistValueToArray('recovery_time_unit__c', 'Recovery Time Unit', recovery_time_unit__c)"
                [(ngModel)]="recovery_time_unit__c">
                <mat-option value="">All</mat-option>
                <mat-option
                  *ngFor="let recoveryTimeUnitValue of filterAdditionalFieldValues(receivedAdditionalFieldValues, 'recovery_time_unit__c')"
                  [value]="recoveryTimeUnitValue.name">
                  {{recoveryTimeUnitValue.label}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <!-- NUMBER FIELDS -->

          <div class="singleFieldContainer" id='Exposure Duration'>
            <mat-form-field class="angularSearchWrapper">
              <mat-label>Exposure Duration</mat-label>
              <input (change)="textFieldValueToArray('exposure_duration__c', 'Exposure Duration')"
                     (keyup)="textFieldValueToArray('exposure_duration__c', 'Exposure Duration')"
                     [(ngModel)]="exposure_duration__c"
                     class="orangeTextFieldInput" id="exposure_duration__c" matInput matNativeControl type="number">
            </mat-form-field>
          </div>


          <div class="singleFieldContainer" id='Recovery Time'>
            <mat-form-field class="angularSearchWrapper">
              <mat-label>Recovery Time</mat-label>
              <input (change)="textFieldValueToArray('recovery_time__c', 'Recovery Time')"
                     (keyup)="textFieldValueToArray('recovery_time__c', 'Recovery Time')" [(ngModel)]="recovery_time__c"
                     class="orangeTextFieldInput" id="recovery_time__c" matInput matNativeControl type="number">
            </mat-form-field>
          </div>

          <div class="singleFieldContainer" id='% peat (organic matter)'>
            <mat-form-field class="angularSearchWrapper">
              <mat-label>% peat (organic matter)</mat-label>
              <input (change)="textFieldValueToArray('peat_organic_matter__c', '% peat (organic matter)')"
                     (keyup)="textFieldValueToArray('peat_organic_matter__c', '% peat (organic matter)')"
                     [(ngModel)]="peat_organic_matter__c"
                     class="orangeTextFieldInput" id="peat_organic_matter__c" matInput matNativeControl type="number">
            </mat-form-field>
          </div>


          <div class="singleFieldContainer" id='BASF DocID'>
            <mat-form-field class="angularSearchWrapper">
              <mat-label>BASF DocID</mat-label>
              <input (change)="textFieldValueToArray('basf_docid__c', 'BASF DocID')"
                     (keyup)="textFieldValueToArray('basf_docid__c', 'BASF DocID')"
                     [(ngModel)]="basf_docid__c" class="orangeTextFieldInput" id="basf_docid__c"
                     matInput matNativeControl type="number">
            </mat-form-field>
          </div>

        </div>

        <div class="searchRow">
          <div class="singleFieldContainer">
            <mat-form-field class="angularSearchWrapper">
              <mat-label>Order By</mat-label>
              <mat-select (selectionChange)="defineSortOrder()" [(value)]="sortByFieldName">
                <mat-option value="id">Latest</mat-option>
                <mat-option value="category_name__c">Category</mat-option>
                <mat-option value="substance_type__c">Substance Type</mat-option>
                <mat-option value="related_substance__c">Related Substance</mat-option>
                <mat-option value="study_type__c">Study Type</mat-option>
                <mat-option value="endpoint__c">Endpoint</mat-option>
                <mat-option value="document__c">Source</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="singleFieldContainer">
            <button (click)="getEndpointsWithFilters(this.pageSize, 0)" [hidden]="searchButtonHidden"
                    class="searchButton" data-cy="ResetFilters" title="Apply Filters"><span class="material-icons">search</span>
            </button>
            <div [hidden]="loaderHidden" class="loaderHolder">
              <div class="loader"></div>
            </div>
          </div>
        </div>

        <!-- the list of the records in Vault -->
        <app-existing-ecotox-endpoint-record (reduceRecordCount)="reduceCount()"
                                             (refreshEndpoints)="getEndpointsWithFilters(this.pageSize, 0);"
                                             *ngFor="let singleRecord of existingEndpoints; index as i;"
                                             [allAdditionalFieldValues]="receivedAdditionalFieldValues"
                                             [allAdditionalFields]="receivedAllAdditionalFields"
                                             [allEndpointUnits]="receivedAllEndpointUnits"
                                             [allEndpoints]="receivedAllEndpoints"
                                             [allOperators]="receivedAllOperators"
                                             [allStudyTypes]="receivedAllStudyTypes"
                                             [allTestGuidelinePicklists]="allTestGuidelinePicklists"
                                             [allTestGuidelines]="allTestGuidelines"
                                             [allTestSpecies]="receivedAllTestSpecies"
                                             [allTestSystemTypes]="receivedAllTestSystemTypes"
                                             [allUsers]="allUsers"
                                             [endpointRecord]="singleRecord"
                                             [categories]="receivedCategories"
                                             [categoryPicklists]="receivedCategoryPicklists"
                                             [dataSourceLength]="dataSourceLength"
                                             [documents]="documents"
                                             [relatedSubstances]="relatedSubstances"
                                             [substanceTypes]="substanceTypes"
                                             [subtractNumber]="i"
                                             class="recordsContainer"

        >
        </app-existing-ecotox-endpoint-record>

        <mat-paginator (page)="pageEvent = $event; onPaginateChange($event)"
                       [length]="dataSourceLength"
                       [pageSizeOptions]="[5, 10, 25, 100]"
                       [pageSize]="pageSize"
                       class="paginator"
                       style="float: left;">
        </mat-paginator>

      </ng-container>

    </mat-expansion-panel>
  </mat-accordion>

</div>
