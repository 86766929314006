/**
 * Get Date from string
 */
export function getDateFromString(date: string): any {
  if (date === null) {
    return null;
  } else {
    if (!date.includes('-')) {
      return date;
    } else if (!date.includes('T')) {
      const [year, month, day] = date.split('-').map(x => +x);
      return new Date(year, month - 1, day);
    } else {
      const [year, month, day] = date.split('T')[0].split('-').map(x => +x);
      const [hours, minutes, seconds] = date.split('T')[1].split('.')[0].split(':').map(x => +x);

      const newDate = new Date(year, month - 1, day, hours, minutes, seconds);
      const offset = newDate.getTimezoneOffset();
      newDate.setMinutes(minutes - offset);
      return newDate;
    }
  }
}
