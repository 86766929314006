<div *ngIf="isLoading" class="ball-loader">
  <div class="ball-loader-ball ball1"></div>
  <div class="ball-loader-ball ball2"></div>
  <div class="ball-loader-ball ball3"></div>
</div>
<div *ngIf="!isLoading" class="container">
  <mat-form-field appearance="fill" class="chart-type-dropdown">
    <mat-label>Chart Type</mat-label>
    <mat-select
      (ngModelChange)="updateChartData($event);this.chartType = $event;"
      [(ngModel)]="this.chartType"
      required>
      <mat-option [value]="'radar'">Spiderweb</mat-option>
      <mat-option [value]="'bar'">Bar</mat-option>
    </mat-select>
  </mat-form-field>
  <div *ngIf="this.chartType==='radar'" class="radar-chart">
    <div class="canvas">
      <canvas [type]="this.chartType" baseChart
              [datasets]="chartData"
              [options]="radarChartOptions"
              [labels]="chartLabels"
              id="radar-chart"
              [legend]="false">
      </canvas>

      <div class="spider-legend" *ngIf="chartType==='radar'">

        <div *ngIf="radarLegendItems" class="radar-data-legend">
          <app-legend-item *ngFor="let i of [0,1,2,3];"

                           [chartType]="chartType" [index]="i" [legendItems]="radarLegendItems" [numOfDropdowns]="4"
                           (itemsEmitted)="updateSelectedData($event, i)"></app-legend-item>
        </div>

      <div class="background-colors-legend">
        <svg width="600px" height="170px">
          <g>
            <rect x="10" y="10" width="60" height="25" fill="#9CCF00"></rect>
            <text font-size="14" x="75" y="27">No risk: High likelihood to maintain or expand registrations.</text>
          </g>
          <g>
            <rect x="10" y="45" width="60" height="25" fill="#FFFF00"></rect>
            <text font-size="14" x="75" y="62">Minor risk: High likelihood to maintain or expand registrations.
              Additional studies may be required, positive outcome expected.
            </text>
          </g>
          <g>
            <rect x="10" y="75" width="60" height="25" fill="#FF9A00"></rect>
            <text font-size="14" x="75" y="92">Moderate risk: Registration possible with additional effort
              and/or with restrictions.
            </text>
          </g>
          <g>
            <rect x="10" y="105" width="60" height="25" fill="#FF0000"></rect>
            <text font-size="14" x="75" y="122">High risk: Loss of key uses and/or registrations likely.
              Data generation unlikely to improve outcome, or is not feasible.
            </text>
          </g>
        </svg>
      </div>
    </div>
  </div>
</div>


  <div *ngIf="this.chartType==='bar'" class="bar-chart">

    <div class="bar-canvas">

      <canvas [datasets]="chartData" [labels]="chartLabels"
              [legend]="false"
              [options]="barChartOptions"
              [type]="chartType"
              baseChart
              id="bar-chart">
      </canvas>

      <div class="bar-legend">
        <app-legend-item *ngFor="let i of [0,1,2,3,4,5]"
                         [index]="i" [legendItems]="barLegendItems" [numOfDropdowns]="6"
                         (itemsEmitted)="updateSelectedData($event, i)"
        ></app-legend-item>
      </div>

    </div>
  </div>
</div>

<!--<ngx-spinner-->
<!--  size="medium"-->
<!--  color="#F0F0F0"-->
<!--  type="ball-scale-multiple"-->
<!--  [fullScreen]="false"-->
<!--&gt;-->
<!--  <p style="font-size: 20px; color: #F0F0F0">Loading Data...</p>-->
<!--</ngx-spinner>-->
