<div class="header-container-ai-compare">
  <h1>Comparison of Active Ingredients</h1>
  <mat-toolbar class="navigation-bar" color="gray">
    <mat-nav-list class="nav-list">
      <a [ngStyle]="{'border-bottom': this.isSelected('select') ? '2px solid rgba(243, 149, 0, 1)' : ''}"
         [queryParams]="queryParams"
         class="nav-link"
         routerLink="select"
         routerLinkActive="active"
      ><span class="navbar-text">Select AIs</span>
      </a>

      <a [ngStyle]="{'border-bottom': this.isSelected('dashboard') ? '2px solid rgba(243, 149, 0, 1)' : ''}"
         [queryParams]="queryParams"
         class="nav-link"
         [routerLink]="isDisabled() ? null : 'dashboard'"
         (click)="dashboardClicked()"
         routerLinkActive="active"
      ><span class="navbar-text">Dashboard</span>
      </a>
    </mat-nav-list>
  </mat-toolbar>
</div>
