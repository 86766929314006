import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {PicklistValue} from '../../../models/picklist-value';
import {ToxEndpointTableItem} from '../../../models/tox-endpoint-table-item';
import {ToxAdditionalField} from '../../../models/tox-additional-field';
import {DocumentMenuItem} from '../../../models/document-menu-item';
import {FormBuilder, FormGroup} from '@angular/forms';
import {ActivatedRoute} from '@angular/router';
import {ExceptionService} from '../../../../core/exception.service';
import {ServerService} from '../../../../core/server.service';
import {ToastrService} from 'ngx-toastr';
import {ToxExistingEndpoint} from '../../../models/tox-existing-endpoint';
import {convertArrayToString} from '../../../../shared/convert-array-to-string';
import {CreateObjectResponse, CreateObjectsResponse} from '../../../models/create-objects-response';
import {VaultError} from '../../../../core/models/vault-error';
import {HttpErrorResponse} from '@angular/common/http';

let me: GenotoxicityExistingComponent;

@Component({
  selector: 'app-genotoxicity-existing',
  templateUrl: './genotoxicity-existing.component.html',
  styleUrls: ['../../../existing_record.css']
})
export class GenotoxicityExistingComponent implements OnInit {
  @Input() arrayedEndpointRecord: ToxExistingEndpoint;
  @Input() category: any;
  @Input() allAdditionalFields: Array<ToxAdditionalField>;
  @Input() allAdditionalFieldValues: Array<PicklistValue>;
  @Input() substanceTypes: Array<PicklistValue>;
  @Input() relatedSubstances: any;
  @Input() documents: Array<DocumentMenuItem>;
  @Input() allUsers: any;

  @Output() removeInput: EventEmitter<ToxEndpointTableItem> = new EventEmitter();
  @Output() copyExisting: EventEmitter<any> = new EventEmitter();

  subscriptions: any;
  selectFormGroup: FormGroup;
  configureFormGroup: FormGroup;
  public existingEndpointRecord: any;
  public preAdditionalFields: Array<ToxAdditionalField>;
  public additionalFields: Array<ToxAdditionalField>;
  public additionalFieldValues: Map<string, any>;
  public hiddenAdditionalFields: Array<ToxAdditionalField>;
  public userName: any;
  public createdBy: any;
  public modifiedBy: any;
  public formatCreatedDate: string;
  public formatModifiedDate: string;
  public studyTypePicklistName: string;
  private haveErrors: Boolean;
  showExistingRecord = true;
  showEditRecord = false;
  public purposeMessage: string;
  private sessionId: string;

  constructor(
    private route: ActivatedRoute,
    private exceptionService: ExceptionService,
    private serverService: ServerService,
    private toastrService: ToastrService,
    private formBuilder: FormBuilder
  ) {
    me = this;
    this.subscriptions = {};
    this.route.paramMap.subscribe((params) => {
      this.sessionId = params.get('sessionId');
    });
    this.selectFormGroup = this.formBuilder.group({
      category__c: [{disabled: true}],
      substanceType: [],
      relatedSubstance: [{value: '', disabled: true}],
      // additional fields
      ames_test__c: [{value: '', disabled: false}],
      hprt_test__c: [{value: '', disabled: false}],
      mouse_lymphoma_assay__c: [{value: '', disabled: false}],
      chromosome_aberration_human_lymphocytes__c: [{value: '', disabled: false}],
      chromosome_aberration_nonhuman_cells__c: [{value: '', disabled: false}],
      comet_assay_in_vitro__c: [{value: '', disabled: false}],
      uds_in_vitro__c: [{value: '', disabled: false}],
      micronucleus_test_in_vitro__c: [{value: '', disabled: false}],
      transgenic_rodent_assay_in_vitro__c: [{value: '', disabled: false}],
      chromosome_aberration_rat__c: [{value: '', disabled: false}],
      chromosome_aberration_mouse__c: [{value: '', disabled: false}],
      chromosome_aberration_hamster__c: [{value: '', disabled: false}],
      comet_assay_in_vivo__c: [{value: '', disabled: false}],
      uds_in_vivo__c: [{value: '', disabled: false}],
      micronucleus_test_rat__c: [{value: '', disabled: false}],
      micronucleus_test_mouse__c: [{value: '', disabled: false}],
      transgenic_rodent_assay_in_vivo__c: [{value: '', disabled: false}],
      other__c: [{value: '', disabled: false}],
      t_trigger__c: [{value: '', disabled: false}],
      // default
      source: [{value: '', disabled: false}],
    });
    this.studyTypePicklistName = 'study_type_genotoxicity__c';
    this.preAdditionalFields = [];
    this.additionalFields = [];
    this.additionalFieldValues = new Map();
    this.purposeMessage = 'editOnExisting';
  }

  ngOnInit(): void {

    this.selectFormGroup.disable();

    // convert record values to string values
    this.existingEndpointRecord = JSON.parse(
      JSON.stringify(this.arrayedEndpointRecord)
    );

    convertArrayToString(
      this.arrayedEndpointRecord,
      this.existingEndpointRecord,
      []
    );

    if (this.existingEndpointRecord.created_by__v === 1) {

      this.createdBy = [{name: 'System'}];
    } else {
      this.createdBy = this.allUsers.filter(
        (c) => c.id === this.existingEndpointRecord.created_by__v
      );
    }

    if (this.existingEndpointRecord.modified_by__v === 1) {
      // eslint-disable-next-line quote-props
      this.modifiedBy = [{name: 'System'}];
    } else {
      this.modifiedBy = this.allUsers.filter(
        (m) => m.id === this.existingEndpointRecord.modified_by__v
      );
    }

    this.formatCreatedDate =
      this.existingEndpointRecord.created_date__v.substring(0, 10) +
      ', ' +
      this.existingEndpointRecord.created_date__v.substring(11, 16) +
      'h Z';
    this.formatModifiedDate =
      this.existingEndpointRecord.modified_date__v.substring(0, 10) +
      ', ' +
      this.existingEndpointRecord.modified_date__v.substring(11, 16) +
      'h Z';

    this.selectFormGroup.get('source').setValue(this.existingEndpointRecord.document_unbound__c);

    this.setAdditionalFields();
    this.setFormFields();
  }

  setAdditionalFields(): any {
    const preAllAdditionalFields = this.allAdditionalFields.filter(
      (entry) =>
        entry.group === this.studyTypePicklistName
    );
    this.additionalFields = preAllAdditionalFields.sort((a, b) => a.sortNumber - b.sortNumber);
  }

  enableEditing(): any {
    this.showExistingRecord = false;
    this.showEditRecord = true;
  }

  cancelEditing(): void {
    this.showExistingRecord = true;
    this.showEditRecord = false;
  }

  saveChanges(endpointRecord): void {
    this.haveErrors = false;

    this.serverService.isLoading();

    delete endpointRecord.created_by__v;
    delete endpointRecord.modified_by__v;
    delete endpointRecord.created_date__v;
    delete endpointRecord.modified_date__v;

    const value1 = parseInt(String(endpointRecord.value_1__c));
    const value2 = parseInt(String(endpointRecord.value_2__c));

    if (endpointRecord.operator__c === 'between__c') {
      if (value2 <= value1 || isNaN(value1) || isNaN(value2)) {
        this.haveErrors = true;
        console.log(value1 + ', ' + value2);
      }
    }

    if (this.haveErrors === true) {
      console.log('saving with errors!');
      this.serverService.isNotLoading();
      this.toastrService.error('Value 2 must be greater than Value 1', 'Values Error');
    }
    else {
      const body = Array(endpointRecord);

      this.serverService.updateEndpoint(this.sessionId, body).subscribe(
        (success: CreateObjectsResponse) => {
          let updated = 0;
          success.data?.forEach((record: CreateObjectResponse) => {
            if (record?.responseStatus === 'SUCCESS') {
              updated++;
            }
            if (record?.responseStatus === 'FAILURE') {
              record?.errors?.forEach((error: VaultError) => {
                this.toastrService.error(error.message, error.type);
              });
            }
          });

          this.toastrService.info(
            ` Endpoint updated successfully.`,
            'Info'
          );
          this.serverService.isNotLoading();
        },
        (error: HttpErrorResponse) => {
          this.exceptionService.handleError(error);
          this.toastrService.error(
            `An error occurred while updating endpoint.`,
            'Error'
          );
          this.serverService.isNotLoading();
        }
      );

      this.showExistingRecord = true;
      this.showEditRecord = false;
      this.existingEndpointRecord = endpointRecord;
      this.setAdditionalFields();
      this.setFormFields();
    }
  }

  copyExistingEndpoint(endpoint: ToxExistingEndpoint): any {
    this.copyExisting.emit(endpoint);
  }

  filterAdditionalFieldValues(
    allAdditionalFieldValues: Array<PicklistValue>,
    filterWord: string
  ): Array<PicklistValue> {
    return allAdditionalFieldValues.filter(
      (item) => item.picklist === filterWord
    );
  }

  setFormFields(): void {
    this.selectFormGroup.get('category__c').setValue(this.existingEndpointRecord.category__c);
    this.selectFormGroup.get('substanceType').setValue(this.existingEndpointRecord.substance_type__c);
    this.selectFormGroup.get('relatedSubstance').setValue(this.existingEndpointRecord.related_substance__c);
    // additional fields
    this.selectFormGroup.get('ames_test__c').setValue(this.existingEndpointRecord.ames_test__c);
    this.selectFormGroup.get('hprt_test__c').setValue(this.existingEndpointRecord.hprt_test__c);
    this.selectFormGroup.get('mouse_lymphoma_assay__c').setValue(this.existingEndpointRecord.mouse_lymphoma_assay__c);
    this.selectFormGroup.get('chromosome_aberration_human_lymphocytes__c').setValue(this.existingEndpointRecord.chromosome_aberration_human_lymphocytes__c);
    this.selectFormGroup.get('chromosome_aberration_nonhuman_cells__c').setValue(this.existingEndpointRecord.chromosome_aberration_nonhuman_cells__c);
    this.selectFormGroup.get('comet_assay_in_vitro__c').setValue(this.existingEndpointRecord.comet_assay_in_vitro__c);
    this.selectFormGroup.get('uds_in_vitro__c').setValue(this.existingEndpointRecord.uds_in_vitro__c);
    this.selectFormGroup.get('micronucleus_test_in_vitro__c').setValue(this.existingEndpointRecord.micronucleus_test_in_vitro__c);
    this.selectFormGroup.get('transgenic_rodent_assay_in_vitro__c').setValue(this.existingEndpointRecord.transgenic_rodent_assay_in_vitro__c);
    this.selectFormGroup.get('chromosome_aberration_rat__c').setValue(this.existingEndpointRecord.chromosome_aberration_rat__c);
    this.selectFormGroup.get('chromosome_aberration_mouse__c').setValue(this.existingEndpointRecord.chromosome_aberration_mouse__c);
    this.selectFormGroup.get('chromosome_aberration_hamster__c').setValue(this.existingEndpointRecord.chromosome_aberration_hamster__c);
    this.selectFormGroup.get('comet_assay_in_vivo__c').setValue(this.existingEndpointRecord.comet_assay_in_vivo__c);
    this.selectFormGroup.get('uds_in_vivo__c').setValue(this.existingEndpointRecord.uds_in_vivo__c);
    this.selectFormGroup.get('micronucleus_test_rat__c').setValue(this.existingEndpointRecord.micronucleus_test_rat__c);
    this.selectFormGroup.get('micronucleus_test_mouse__c').setValue(this.existingEndpointRecord.micronucleus_test_mouse__c);
    this.selectFormGroup.get('transgenic_rodent_assay_in_vivo__c').setValue(this.existingEndpointRecord.transgenic_rodent_assay_in_vivo__c);
    this.selectFormGroup.get('other__c').setValue(this.existingEndpointRecord.other__c);
    this.selectFormGroup.get('t_trigger__c').setValue(this.existingEndpointRecord.t_trigger__c);
    // default
    this.selectFormGroup.get('source').setValue(this.existingEndpointRecord.document_unbound__c);
  }

}





