import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {PicklistValue} from '../../../models/picklist-value';
import {DocumentMenuItem} from '../../../models/document-menu-item';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute} from '@angular/router';
import {ExceptionService} from '../../../../core/exception.service';
import {ServerService} from '../../../../core/server.service';
import {ToastrService} from 'ngx-toastr';
import {convertArrayToString} from '../../../../shared/convert-array-to-string';
import {CreateObjectResponse, CreateObjectsResponse} from '../../../models/create-objects-response';
import {VaultError} from '../../../../core/models/vault-error';
import {HttpErrorResponse} from '@angular/common/http';
import {ExistingEndpoint} from "../../models/existing-endpoint";
import {AdditionalField} from "../../models/additional-field";
import {EndpointTableItem} from "../../models/endpoint-table-item";
import {CategoryPicklists} from "../../models/category-picklists";


let me: EfateAllCategoriesExistingComponent;

@Component({
  selector: 'app-efate-all-categories-existing',
  templateUrl: './efate-all-categories-existing.component.html',
  styleUrls: ['../../../existing_record.css']
})
export class EfateAllCategoriesExistingComponent implements OnInit {
  @Input() arrayedEndpointRecord: ExistingEndpoint;
  @Input() category: any;
  @Input() substanceTypes: Array<PicklistValue>;
  @Input() relatedSubstances: any;
  @Input() categoryPicklists: Array<CategoryPicklists>;
  @Input() allStudyTypes: Array<PicklistValue>;
  @Input() allEndpoints: Array<PicklistValue>;
  @Input() allEndpointUnits: Array<PicklistValue>;
  @Input() allOperators: Array<PicklistValue>;
  @Input() allAdditionalFields: Array<AdditionalField>;
  @Input() allAdditionalFieldValues: Array<AdditionalField>;
  @Input() documents: Array<DocumentMenuItem>;
  @Input() allUsers: any;

  @Output() removeInput: EventEmitter<EndpointTableItem> = new EventEmitter();
  @Output() copyExisting: EventEmitter<any> = new EventEmitter();

  subscriptions: any;
  selectFormGroup: FormGroup;
  configureFormGroup: FormGroup;
  public existingEndpointRecord: any;
  public additionalFields: Array<AdditionalField>;
  public additionalFieldValues: Map<string, any>;
  public isEndpointUnitHidden: boolean;
  public hiddenAdditionalFields: Array<AdditionalField>;
  public isValue1Disabled: boolean;
  public isValue2Disabled: boolean;
  public isValue3Disabled: boolean;
  public showHiddenFields: boolean;
  public userName: any;
  public createdBy: any;
  public modifiedBy: any;
  public formatCreatedDate: string;
  public formatModifiedDate: string;
  private haveErrors: Boolean;
  showExistingRecord = true;
  showEditRecord = false;
  public purposeMessage: string;
  private sessionId: string;

  constructor(
    private route: ActivatedRoute,
    private exceptionService: ExceptionService,
    private serverService: ServerService,
    private toastrService: ToastrService,
    private formBuilder: FormBuilder
  ) {
    me = this;
    this.subscriptions = {};
    this.route.paramMap.subscribe((params) => {
      this.sessionId = params.get('sessionId');
    });
    this.selectFormGroup = this.formBuilder.group({
      category__c: [{value: 'Acute Toxicity', disabled: true}],
      substanceType: [],
      relatedSubstance: [{value: '', disabled: true}],
      studyType: [{value: '', disabled: false}, Validators.required],
      endpoint: [{value: '', disabled: false}, Validators.required],
      endpointUnit: [{value: '', disabled: false}, Validators.required],
      operator: ['', Validators.required],
      value1: [{value: null, disabled: false}],
      value2: [{value: null, disabled: false}],
      value3: [{value: '', disabled: false}],
      // additional (depended) fields
      crops1__c: [{value: '', disabled: false}],
      comment__c: [{value: '', disabled: false}],
      focus_step__c: [{value: '', disabled: false}],
      scenario_with_highest_concentration__c: [{value: '', disabled: false}],
      location_countrysite__c: [{value: '', disabled: false}],
      ptrigger__c: [{value: '', disabled: false}],
      data_normalization__c: [{value: '', disabled: false}],
      no_of_systemssitesconc__c: [{value: '', disabled: false}],
      temp_c__c: [{value: '', disabled: false}],
      kinetic_model__c: [{value: '', disabled: false}],
      ph_dependency__c: [{value: '', disabled: false}],
      ph_experiment__c: [{value: '', disabled: false}],
      method_of_calc__c: [{value: '', disabled: false}],
      dt50_used_for_pecsoil__c: [{value: '', disabled: false}],
      no_of_soilssites__c: [{value: '', disabled: false}],
      no_of_soils__c: [{value: '', disabled: false}],
      no_of_systemssites__c: [{value: '', disabled: false}],
      plant_uptake_factor_used_for_pecgw__c: [{value: '', disabled: false}],
      method_of_calculation__c: [{value: '', disabled: false}],
      dt50_used_for_pecgw__c: [{value: '', disabled: false}],
      kfoc_used_for_pecgw__c: [{value: '', disabled: false}],
      no_of_sites__c: [{value: '', disabled: false}],
      target_crop__c: [{value: '', disabled: false}],
      application_rate__c: [{value: '', disabled: false}],
      no_of_columns__c: [{value: '', disabled: false}],
      ageing_period__c: [{value: '', disabled: false}],
      duration__c: [{value: '', disabled: false}],
      soil_site__c: [{value: '', disabled: false}],
      relevance_trigger__c: [{value: '', disabled: false}],
      toxicological_relevance__c: [{value: '', disabled: false}],
      no_of_columnssites__c: [{value: '', disabled: false}],
      leaching_model__c: [{value: '', disabled: false}],
      metabolites_included__c: [{value: '', disabled: false}],
      population_group__c: [{value: '', disabled: false}],
      no_of_sitescountries__c: [{value: '', disabled: false}],
      // default
      source: [{value: '', disabled: false}],
    });
    this.additionalFields = [];
    this.showHiddenFields = false;
    this.isEndpointUnitHidden = false;
    this.isValue1Disabled = true;
    this.isValue2Disabled = true;
    this.isValue3Disabled = true;
    this.purposeMessage = 'editOnExisting';
  }

  ngOnInit(): void {

    this.selectFormGroup.disable();

    // convert record values to string values
    this.existingEndpointRecord = JSON.parse(
      JSON.stringify(this.arrayedEndpointRecord)
    );

    convertArrayToString(
      this.arrayedEndpointRecord,
      this.existingEndpointRecord,
      []
    );

    if (this.existingEndpointRecord.created_by__v === 1) {

      this.createdBy = [{name: 'System'}];
    } else {
      this.createdBy = this.allUsers.filter(
        (c) => c.id === this.existingEndpointRecord.created_by__v
      );
    }

    if (this.existingEndpointRecord.modified_by__v === 1) {
      // eslint-disable-next-line quote-props
      this.modifiedBy = [{name: 'System'}];
    } else {
      this.modifiedBy = this.allUsers.filter(
        (m) => m.id === this.existingEndpointRecord.modified_by__v
      );
    }

    this.formatCreatedDate =
      this.existingEndpointRecord.created_date__v.substring(0, 10) +
      ', ' +
      this.existingEndpointRecord.created_date__v.substring(11, 16) +
      'h Z';
    this.formatModifiedDate =
      this.existingEndpointRecord.modified_date__v.substring(0, 10) +
      ', ' +
      this.existingEndpointRecord.modified_date__v.substring(11, 16) +
      'h Z';

    this.selectFormGroup.get('source').setValue(this.existingEndpointRecord.document_unbound__c);

    this.setAdditionalFields();
    this.setValuesVisibility(this.existingEndpointRecord.operator__c);
    this.setFormFields();
  }

  setAdditionalFields(): void {

    if (
      (this.existingEndpointRecord.study_type_picklist_name__c === 'study_type_soil_persistence_1__c' || this.existingEndpointRecord.study_type_picklist_name__c === 'study_type_soil_persistence_2__c')
      &&
      // eslint-disable-next-line max-len
      (this.existingEndpointRecord.endpoint__c === 'dt50_soil__c' || this.existingEndpointRecord.endpoint__c === 'dt50_soil_geomean__c' || this.existingEndpointRecord.endpoint__c === 'dt90_soil__c')
    ) {
      this.showHiddenFields = true;
    }

    if (
      // eslint-disable-next-line max-len
      (this.existingEndpointRecord.study_type_picklist_name__c === 'study_type_surface_water_1__c' || this.existingEndpointRecord.study_type_picklist_name__c === 'study_type_surface_water_2__c')
      &&
      (this.existingEndpointRecord.endpoint__c === 'dt50_water__c' || this.existingEndpointRecord.endpoint__c === 'dt90_water__c')) {
      this.showHiddenFields = true;
    }

    if (
      // eslint-disable-next-line max-len
      (this.existingEndpointRecord.study_type_picklist_name__c === 'study_type_drinking_water__c')
      &&
      // eslint-disable-next-line max-len
      (this.existingEndpointRecord.study_type__c === 'usedwc_acute__c' || this.existingEndpointRecord.study_type__c === 'usedwc_chronic__c' || this.existingEndpointRecord.study_type__c === 'eupec_raw_water__c')) {
      this.showHiddenFields = true;
    }


    if (this.showHiddenFields === true) {
      const preAdditionalFields = this.allAdditionalFields.filter(
        (entry) =>
          entry.group === this.existingEndpointRecord.study_type_picklist_name__c
      );
      this.additionalFields = preAdditionalFields.sort((a, b) => a.sortNumber - b.sortNumber);
    } else {
      const preAllAdditionalFields = this.allAdditionalFields.filter(
        (entry) =>
          entry.group === this.existingEndpointRecord.study_type_picklist_name__c
      );
      // remove fields mark with 'yes__c' in Initially Hidden field
      const preAdditionalFields = preAllAdditionalFields.filter(obj =>
        obj.hidden[0] !== 'yes__c'
      );
      this.additionalFields = preAdditionalFields.sort((a, b) => a.sortNumber - b.sortNumber);
    }
  }

  setValuesVisibility(operatorValue): void {
    if (operatorValue) {
      switch (operatorValue) {
        case 'between__c':
          this.isValue1Disabled = false;
          this.isValue2Disabled = false;
          this.isValue3Disabled = true;
          break;
        case 'text__c':
          this.isValue1Disabled = true;
          this.isValue2Disabled = true;
          this.isValue3Disabled = false;
          break;
        case null:
          this.isValue1Disabled = true;
          this.isValue2Disabled = true;
          this.isValue3Disabled = true;
          break;
        default:
          this.isValue1Disabled = false;
          this.isValue2Disabled = true;
          this.isValue3Disabled = true;
          break;
      }
    }
    else {
      this.isValue1Disabled = true;
      this.isValue2Disabled = true;
      this.isValue3Disabled = true;
    }
  }

  setFormFields(): void {
    this.selectFormGroup.get('category__c').setValue(this.existingEndpointRecord.category__c);
    this.selectFormGroup.get('substanceType').setValue(this.existingEndpointRecord.substance_type__c);
    this.selectFormGroup.get('relatedSubstance').setValue(this.existingEndpointRecord.related_substance__c);
    this.selectFormGroup.get('endpoint').setValue(this.existingEndpointRecord.endpoint__c);
    this.selectFormGroup.get('studyType').setValue(this.existingEndpointRecord.study_type__c);
    this.selectFormGroup.get('endpointUnit').setValue(this.existingEndpointRecord.endpoint_unit__c);
    // additional fields
    this.selectFormGroup.get('operator').setValue(this.existingEndpointRecord.operator__c);
    this.selectFormGroup.get('value1').setValue(this.existingEndpointRecord.value_1__c);
    this.selectFormGroup.get('value2').setValue(this.existingEndpointRecord.value_2__c);
    this.selectFormGroup.get('value3').setValue(this.existingEndpointRecord.value_3__c);
    this.selectFormGroup.get('crops1__c').setValue(this.existingEndpointRecord.crops1__c);
    this.selectFormGroup.get('comment__c').setValue(this.existingEndpointRecord.comment__c);
    this.selectFormGroup.get('focus_step__c').setValue(this.existingEndpointRecord.focus_step__c);
    this.selectFormGroup.get('scenario_with_highest_concentration__c').setValue
    (this.existingEndpointRecord.scenario_with_highest_concentration__c);
    this.selectFormGroup.get('location_countrysite__c').setValue(this.existingEndpointRecord.location_countrysite__c);
    this.selectFormGroup.get('ptrigger__c').setValue(this.existingEndpointRecord.ptrigger__c);
    this.selectFormGroup.get('data_normalization__c').setValue(this.existingEndpointRecord.data_normalization__c);
    this.selectFormGroup.get('no_of_systemssitesconc__c').setValue(this.existingEndpointRecord.no_of_systemssitesconc__c);
    this.selectFormGroup.get('temp_c__c').setValue(this.existingEndpointRecord.temp_c__c);
    this.selectFormGroup.get('kinetic_model__c').setValue(this.existingEndpointRecord.kinetic_model__c);
    this.selectFormGroup.get('ph_dependency__c').setValue(this.existingEndpointRecord.ph_dependency__c);
    this.selectFormGroup.get('ph_experiment__c').setValue(this.existingEndpointRecord.ph_experiment__c);
    this.selectFormGroup.get('method_of_calc__c').setValue(this.existingEndpointRecord.method_of_calc__c);
    this.selectFormGroup.get('dt50_used_for_pecsoil__c').setValue(this.existingEndpointRecord.dt50_used_for_pecsoil__c);
    this.selectFormGroup.get('no_of_soilssites__c').setValue(this.existingEndpointRecord.no_of_soilssites__c);
    this.selectFormGroup.get('no_of_soils__c').setValue(this.existingEndpointRecord.no_of_soils__c);
    this.selectFormGroup.get('no_of_systemssites__c').setValue(this.existingEndpointRecord.no_of_systemssites__c);
    this.selectFormGroup.get('plant_uptake_factor_used_for_pecgw__c').setValue
    (this.existingEndpointRecord.plant_uptake_factor_used_for_pecgw__c);
    this.selectFormGroup.get('method_of_calculation__c').setValue(this.existingEndpointRecord.method_of_calculation__c);
    this.selectFormGroup.get('dt50_used_for_pecgw__c').setValue(this.existingEndpointRecord.dt50_used_for_pecgw__c);
    this.selectFormGroup.get('kfoc_used_for_pecgw__c').setValue(this.existingEndpointRecord.kfoc_used_for_pecgw__c);
    this.selectFormGroup.get('no_of_sites__c').setValue(this.existingEndpointRecord.no_of_sites__c);
    this.selectFormGroup.get('target_crop__c').setValue(this.existingEndpointRecord.target_crop__c);
    this.selectFormGroup.get('application_rate__c').setValue(this.existingEndpointRecord.application_rate__c);
    this.selectFormGroup.get('no_of_columns__c').setValue(this.existingEndpointRecord.no_of_columns__c);
    this.selectFormGroup.get('ageing_period__c').setValue(this.existingEndpointRecord.ageing_period__c);
    this.selectFormGroup.get('duration__c').setValue(this.existingEndpointRecord.duration__c);
    this.selectFormGroup.get('soil_site__c').setValue(this.existingEndpointRecord.soil_site__c);
    this.selectFormGroup.get('relevance_trigger__c').setValue(this.existingEndpointRecord.relevance_trigger__c);
    this.selectFormGroup.get('toxicological_relevance__c').setValue(this.existingEndpointRecord.toxicological_relevance__c);
    this.selectFormGroup.get('no_of_columnssites__c').setValue(this.existingEndpointRecord.no_of_columnssites__c);
    this.selectFormGroup.get('leaching_model__c').setValue(this.existingEndpointRecord.leaching_model__c);
    this.selectFormGroup.get('metabolites_included__c').setValue(this.existingEndpointRecord.metabolites_included__c);
    this.selectFormGroup.get('population_group__c').setValue(this.existingEndpointRecord.population_group__c);
    this.selectFormGroup.get('no_of_sitescountries__c').setValue(this.existingEndpointRecord.no_of_sitescountries__c);
    // default
    this.selectFormGroup.get('source').setValue(this.existingEndpointRecord.document_unbound__c);
  }

  enableEditing(): any {
    this.showExistingRecord = false;
    this.showEditRecord = true;
  }

  cancelEditing(): void {
    this.showExistingRecord = true;
    this.showEditRecord = false;
  }

  saveChanges(endpointRecord): void {
    this.haveErrors = false;

    this.serverService.isLoading();

    delete endpointRecord.created_by__v;
    delete endpointRecord.modified_by__v;
    delete endpointRecord.created_date__v;
    delete endpointRecord.modified_date__v;

    const value1 = parseInt(String(endpointRecord.value_1__c));
    const value2 = parseInt(String(endpointRecord.value_2__c));

    if (endpointRecord.operator__c === 'between__c') {
      if (value2 <= value1 || isNaN(value1) || isNaN(value2)) {
        this.haveErrors = true;
        console.log(value1 + ', ' + value2);
      }
    }

    if (this.haveErrors === true) {
      console.log('saving with errors!');
      this.serverService.isNotLoading();
      this.toastrService.error('Value 2 must be greater than Value 1', 'Values Error');
    }
    else {
      const body = Array(endpointRecord);

      this.serverService.updateEndpoint(this.sessionId, body).subscribe(
        (success: CreateObjectsResponse) => {
          let updated = 0;
          success.data?.forEach((record: CreateObjectResponse) => {
            if (record?.responseStatus === 'SUCCESS') {
              updated++;
            }
            if (record?.responseStatus === 'FAILURE') {
              record?.errors?.forEach((error: VaultError) => {
                this.toastrService.error(error.message, error.type);
              });
            }
          });

          this.toastrService.info(
            ` Endpoint updated successfully.`,
            'Info'
          );
          this.serverService.isNotLoading();
        },
        (error: HttpErrorResponse) => {
          this.exceptionService.handleError(error);
          this.toastrService.error(
            `An error occurred while updating endpoint.`,
            'Error'
          );
          this.serverService.isNotLoading();
        }
      );

      this.showExistingRecord = true;
      this.showEditRecord = false;
      this.existingEndpointRecord = endpointRecord;
      this.setValuesVisibility(this.existingEndpointRecord.operator__c);
      this.setFormFields();
    }
  }

  copyExistingEndpoint(endpoint: ExistingEndpoint): any {
    this.copyExisting.emit(endpoint);
  }

  filterAdditionalFieldValues(
    allAdditionalFieldValues: Array<PicklistValue>,
    filterWord: string
  ): Array<PicklistValue> {
    return allAdditionalFieldValues.filter(
      (item) => item.picklist === filterWord
    );
  }

}


