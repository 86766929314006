import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {TimelinePageComponent} from './timeline-page/timeline-page.component';
import {HistoryComponent} from './history/history.component';

const routes: Routes = [
  {
    path: ':id/:sessionId',
    component: TimelinePageComponent,
    children: [],
  },
  {
    path: 'history/:id/:sessionId',
    component: HistoryComponent,
    children: [],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [],
})
export class TimelineRoutingModule {
}
