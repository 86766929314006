import {AfterContentInit, Component, Input, OnInit, ViewChild, } from '@angular/core';
import {ServerService} from 'src/app/core/server.service';
import {EcotoxEndpointTableItem} from 'src/app/endpoint/ecotoxicology/models/ecotox-endpoint-table-item';
import {EcotoxCategoryPicklists} from '../models/ecotox-category-picklists';
import {ActivatedRoute} from '@angular/router';
import {PicklistValue} from '../../models/picklist-value';
import {DocumentMenuItem} from '../../models/document-menu-item';
import {SubstanceItem} from '../../models/substance-item';
import {EndpointServicesService} from '../../endpoint-services.service';
import {RelatedSubstances} from '../../models/related-substances';
import {EcotoxAdditionalField} from '../models/ecotox-additional-field';
import {EcotoxRecordComponent} from '../ecotox-record/ecotox-record.component';
import {TestGuidelines} from '../../models/test-guidelines';

let me: EcotoxCategoryComponent;

@Component({
  selector: 'app-ecotox-category',
  templateUrl: './ecotox-category.component.html',
  styleUrls: ['./ecotox-category.component.css']
})
export class EcotoxCategoryComponent implements OnInit, AfterContentInit {
  @Input() workAreaId: string;
  @Input() workAreaLabel: string;
  @Input() category: any;
  @Input() dataPicklists: Array<EcotoxCategoryPicklists>;
  @Input() operators: Array<PicklistValue>;
  @Input() endpointsArrayList: Array<EcotoxEndpointTableItem>;
  @Input() substance: SubstanceItem;
  @Input() relatedSubstances: Array<RelatedSubstances>;
  @Input() substanceTypes: Array<PicklistValue>;
  @Input() receivedAllStudyTypes: Array<PicklistValue>;
  @Input() receivedAllEndpoints: Array<PicklistValue>;
  @Input() receivedAllEndpointUnits: Array<PicklistValue>;
  @Input() receivedAllTestSystemTypes: Array<PicklistValue>;
  @Input() receivedAllTestSpecies: Array<PicklistValue>;
  @Input() documents: Array<DocumentMenuItem>;
  @Input() receivedAllAdditionalFields: Array<EcotoxAdditionalField>;
  @Input() receivedAdditionalFieldValues: Array<EcotoxAdditionalField>;
  @Input() allTestGuidelinePicklists: Array<TestGuidelines>;
  @Input() allTestGuidelines: Array<PicklistValue>;

  @ViewChild(EcotoxRecordComponent) eRecord: EcotoxRecordComponent;

  subscriptions: any;
  public selectedDocument: DocumentMenuItem;
  public filteredPicklists: Array<EcotoxCategoryPicklists>;
  public categoryEndpointsArrayList: Array<EcotoxEndpointTableItem>;
  public groupPicklists: Array<EcotoxCategoryPicklists>;
  public defaultAllStudyTypes: Array<PicklistValue>; // reference list
  public defaultAllEndpoints: Array<PicklistValue>;
  public defaultAllEndpointUnits: Array<PicklistValue>;
  public defaultAllTestSystemTypes: Array<PicklistValue>;
  public defaultAllTestSpecies: Array<PicklistValue>;
  public allEndpoints: Array<PicklistValue>;
  public allEndpointUnits: Array<PicklistValue>;
  public allTestSystemTypes: Array<PicklistValue>;
  public allTestSpecies: Array<PicklistValue>;
  public testGuidelinePicklists: Array<TestGuidelines>;
  public startingId: number;
  public maxCategoryId: number;
  private sessionId: string;

  constructor(
    private route: ActivatedRoute,
    private serverService: ServerService,
    private endpointService: EndpointServicesService
  ) {
    me = this;
    this.subscriptions = {};
    this.route.paramMap.subscribe((params) => {
      this.sessionId = params.get('sessionId');
    });
    this.categoryEndpointsArrayList = [];
    this.filteredPicklists = [];
    this.groupPicklists = [];
    this.defaultAllStudyTypes = [];
    this.defaultAllEndpoints = [];
    this.defaultAllEndpointUnits = [];
    this.defaultAllTestSystemTypes = [];
    this.defaultAllTestSpecies = [];
    this.allEndpoints = [];
    this.allEndpointUnits = [];
    this.allTestSystemTypes = [];
    this.allTestSpecies = [];
    this.testGuidelinePicklists = [];
    this.startingId = 0;
    this.maxCategoryId = 0;
  }

  ngOnInit(): void {
    this.endpointService.currentDocument.subscribe(
      (doc) => (this.selectedDocument = doc)
    );
    this.maxCategoryId = (11 - this.category.order_by__c) * 1000000;
  }

  ngAfterContentInit(): void {

    this.filteredPicklists = this.dataPicklists.filter(
      (a) => a.categoryId === this.category.id
    );
    this.defaultAllStudyTypes = this.receivedAllStudyTypes.filter(
      (b) => b.id === this.category.id
    );
    this.defaultAllEndpoints = this.receivedAllEndpoints.filter(
      (c) => c.id === this.category.id
    );

    this.defaultAllEndpointUnits = this.receivedAllEndpointUnits.filter(
      (d) => d.id === this.category.id
    );
    this.defaultAllTestSystemTypes = this.receivedAllTestSystemTypes.filter(
      (e) => e.id === this.category.id
    );

    this.allTestSpecies = this.receivedAllTestSpecies.filter(
      (f) => f.id === this.category.id
    );

    this.testGuidelinePicklists = this.allTestGuidelinePicklists.filter(
      (n) => n.categoryName === this.category.name__v
    );

    this.unifyEndpoints();
    this.unifyEndpointUnits();
    this.unifyTestSystemTypes();
  }

  unifyEndpoints(): void {
    // remove duplicates
    const arrayEndpoints = this.defaultAllEndpoints;
    const map = new Map();
    for (const item of arrayEndpoints) {
      if (!map.has(item.name)) {
        map.set(item.name, true); // set any value to Map
        this.allEndpoints.push({
          name__v: item.name__v,
          id: item.id,
          name: item.name,
          label: item.label,
          picklist: item.picklist,
        });
      }
    }
  }

  unifyEndpointUnits(): void {
    // remove duplicates
    const array = this.defaultAllEndpointUnits;
    const map = new Map();
    for (const item of array) {
      if (!map.has(item.name)) {
        map.set(item.name, true); // set any value to Map
        this.allEndpointUnits.push({
          name__v: item.name__v,
          id: item.id,
          name: item.name,
          label: item.label,
          picklist: item.picklist,
        });
      }
    }
  }

  unifyTestSystemTypes(): void {
    // remove duplicates
    const array = this.defaultAllTestSystemTypes;
    const map = new Map();
    for (const item of array) {
      if (!map.has(item.name)) {
        map.set(item.name, true); // set any value to Map
        this.allTestSystemTypes.push({
          name__v: item.name__v,
          id: item.id,
          name: item.name,
          label: item.label,
          picklist: item.picklist,
        });
      }
    }
  }

  addNewEndpoint(endpoint: any): void {

    // make object
    const newEndpointTemplate: EcotoxEndpointTableItem = {
      id__c: this.generateId(),
      work_area__c: this.workAreaId,
      category__c: this.category.id,
      substance_name__c: this.substance.id,
      substance_type__c: endpoint.substance_type__c ?? 'parent__c',
      related_substance__c: endpoint.related_substance__c ?? '',
      study_type__c: endpoint.study_type__c ?? '',
      endpoint__c: endpoint.endpoint__c ?? '',
      endpoint_unit__c: endpoint.endpoint_unit__c ?? '',
      test_system_type__c: endpoint.test_system_type__c ?? '',
      test_species__c: endpoint.test_species__c ?? '',
      operator__c: endpoint.operator__c ?? '',
      value_1__c: endpoint.value_1__c ?? null,
      value_2__c: endpoint.value_2__c ?? null,
      value_3__c: endpoint.value_3__c ?? '',
      // additional (depended) fields
      exposure_duration__c: endpoint.exposure_duration__c ?? null,
      exposure_duration_unit__c: endpoint.exposure_duration_unit__c ?? '',
      exposure_design__c: endpoint.exposure_design__c ?? '',
      ed_relevant__c: endpoint.ed_relevant__c ?? '',
      trigger_ed__c: endpoint.trigger_ed__c ?? '',
      ed_modality__c: endpoint.ed_modality__c ?? '',
      ed_mechanisms__c: endpoint.ed_mechanisms__c ?? '',
      test_guideline__c: endpoint.test_guideline__c ?? '',
      used_in_risk_assessment__c: endpoint.used_in_risk_assessment__c ?? '',
      endpoint_analytically_based_on__c: endpoint.endpoint_analytically_based_on__c ?? '',
      t_trigger__c: endpoint.t_trigger__c ?? '',
      affected_ed__c: endpoint.affected_ed__c ?? '', // multi-value
      trr__c: endpoint.trr__c ?? '',
      normalization__c: endpoint.normalization__c ?? '',
      b_trigger__c: endpoint.b_trigger__c ?? '',
      substrate__c: endpoint.substrate__c ?? '',
      recovery_time__c: endpoint.recovery_time__c ?? null,
      recovery_time_unit__c: endpoint.recovery_time_unit__c ?? '',
      peat_organic_matter__c: endpoint.peat_organic_matter__c ?? null,
      comment__c: endpoint.comment__c ?? '',
      basf_docid__c: endpoint.basf_docid__c ?? null,
      study_type_picklist_name__c: endpoint.study_type_picklist_name__c ?? '', // field used for capturing additional (depended) fields
      document_unbound__c: endpoint.document_unbound__c ?? this.selectedDocument.id,
    };

    this.categoryEndpointsArrayList.push(newEndpointTemplate);
    this.endpointsArrayList.push(newEndpointTemplate);
  }

  removeEndpointInput(endpoint: EcotoxEndpointTableItem): void {
    // remove object from array
    for (let index = 0; index < this.endpointsArrayList.length; index++) {
      const element: EcotoxEndpointTableItem = this.endpointsArrayList[index];
      if (element.id__c === endpoint.id__c) {
        this.endpointsArrayList.splice(index, 1);
      }
    }

    for (let index = 0; index < this.categoryEndpointsArrayList.length; index++) {
      const element: EcotoxEndpointTableItem = this.categoryEndpointsArrayList[index];
      if (element.id__c === endpoint.id__c) {
        this.categoryEndpointsArrayList.splice(index, 1);
      }
    }
  }

  removeSavedEndpoint(endpointId): any {

    this.categoryEndpointsArrayList = this.categoryEndpointsArrayList.filter(
      obj => obj.id__c !== endpointId
    );
  }

  generateId(): number {
    const newStartingId = this.startingId + 1;
    this.updateStartingId(newStartingId);
    return this.maxCategoryId - newStartingId;
  }

  updateStartingId(newStartingId): void {
    this.startingId = newStartingId;
  }

  copyEndpointInput(endpoint: EcotoxEndpointTableItem): void {
    this.categoryEndpointsArrayList.filter(it => it.id__c === endpoint.id__c)
      .forEach(newEndpoint => {
        this.addNewEndpoint(newEndpoint);
      });
  }

}
