import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {PicklistValue} from '../../models/picklist-value';
import {DocumentMenuItem} from '../../models/document-menu-item';
import {convertArrayToString} from '../../../shared/convert-array-to-string';
import {CreateObjectResponse, CreateObjectsResponse} from '../../models/create-objects-response';
import {VaultError} from '../../../core/models/vault-error';
import {HttpErrorResponse} from '@angular/common/http';
import {ServerService} from '../../../core/server.service';
import {ExceptionService} from '../../../core/exception.service';
import {ToastrService} from 'ngx-toastr';
import {MatDialog} from '@angular/material/dialog';
import {MatDialogComponent} from '../../mat-dialog/mat-dialog.component';
import {PhysChemDataExisting} from "../models/phys-chem-data-existing";
import {PhysChemDataCategoryPicklists} from "../models/phys-chem-data-category-picklists";
import {PhysChemDataAdditionalField} from "../models/phys-chem-data-additional-field";
import {PhysChemDataEndpointTableItem} from "../models/phys-chem-data-endpoint-table-item";

@Component({
  selector: 'app-phys-chem-data-record-existing',
  templateUrl: './phys-chem-data-record-existing.component.html',
  styleUrls: ['./phys-chem-data-record-existing.component.css']
})
export class PhysChemDataRecordExistingComponent implements OnInit {
  @Input() endpointRecord: PhysChemDataExisting;
  @Input() categories: Array<PicklistValue>;
  @Input() categoryPicklists: Array<PhysChemDataCategoryPicklists>;
  @Input() allEndpoints: Array<PicklistValue>;
  @Input() allEndpointUnits: Array<PicklistValue>;
  @Input() allOperators: Array<PicklistValue>;
  @Input() allTestSystemTypes: Array<PicklistValue>;
  @Input() allAdditionalFields: Array<PhysChemDataAdditionalField>;
  @Input() substanceTypes: Array<PicklistValue>;
  @Input() relatedSubstances: any;
  @Input() documents: Array<DocumentMenuItem>;
  @Input() allUsers: any;
  @Input() dataSourceLength: number;
  @Input() subtractNumber: number;

  @Output() reduceRecordCount: EventEmitter<number> = new EventEmitter();
  @Output() refreshEndpoints: EventEmitter<any> = new EventEmitter();


  public categoryAdditionalFields: Array<PhysChemDataAdditionalField>;
  public categoryTestSystemTypes: Array<PicklistValue>;
  public categoryEndpoints: Array<PicklistValue>;
  public categoryEndpointUnits: Array<PicklistValue>;
  public uniqueTestSystemTypes: Array<PicklistValue>;
  public uniqueEndpoints: Array<PicklistValue>;
  public uniqueEndpointUnits: Array<PicklistValue>;
  public saveButtonShowing: boolean;
  public category: any;
  public categoryName: string;
  public zindex: number;
  public copiedEndpointsArrayList: Array<PhysChemDataEndpointTableItem>;
  private sessionId: string;
  public startingId: number;
  public maxCategoryId: number;
  public successRecords: any;
  public errorMessagesToUser: any[];
  public successMessagesToUser: string;
  private haveErrors: Boolean;
  loading = false;
  disabled = false;
  showErrorMessage = false;
  showSuccessMessage = false;
  loadingDataHidden: false;
  subscriptions: any;
  public dialogTitle: string;
  public dialogRow1: string;

  constructor(
    private route: ActivatedRoute,
    private serverService: ServerService,
    private exceptionService: ExceptionService,
    private toastrService: ToastrService,
    public dialog: MatDialog
  ) {

    this.zindex = 0;
    this.route.paramMap.subscribe((params) => {
      this.sessionId = params.get('sessionId');
    });
    this.categoryTestSystemTypes = [];
    this.categoryEndpoints = [];
    this.categoryEndpointUnits = [];
    this.uniqueTestSystemTypes = [];
    this.uniqueEndpoints = [];
    this.uniqueEndpointUnits = [];
    this.copiedEndpointsArrayList = [];
    this.startingId = 0;
    this.maxCategoryId = 10000000;
    this.saveButtonShowing = false;
    this.successRecords = [];
    this.errorMessagesToUser = [];
    this.successMessagesToUser = '';
    this.dialogTitle = 'Confirm Refresh';
    this.dialogRow1 = 'Please, save all copied Endpoints before refreshing the Existing Endpoints list !';
  }

  ngOnInit(): void {
    this.zindex = this.dataSourceLength - this.subtractNumber;

    this.category = this.categories.find(
      (c) => c.id === this.endpointRecord.category__c);

    this.categoryName = this.category.name__v;
    this.filterEndpoints();
    this.filterEndpointUnits();
    this.filterTestSystemTypes();
  }

  filterTestSystemTypes(): void {
    this.categoryTestSystemTypes = this.allTestSystemTypes.filter(
      (entry) => entry.id === this.category.id
    );
    this.makeUniqueTestSystemTypes();
  }
  filterEndpoints(): void {
    this.categoryEndpoints = this.allEndpoints.filter(
      (entry) => entry.id === this.category.id
    );
    this.makeUniqueEndpoints();
  }
  filterEndpointUnits(): void {
    this.categoryEndpointUnits = this.allEndpointUnits.filter(
      (entry) => entry.id === this.category.id
    );
    this.makeUniqueEndpointUnits();
  }

  makeUniqueTestSystemTypes(): any {
    const source = this.categoryTestSystemTypes;
    const result = this.uniqueTestSystemTypes;

    const testSystemTypeMap = new Map();
    for (const item of source) {
      if (!testSystemTypeMap.has(item.name)) {
        testSystemTypeMap.set(item.name, true);
        result.push({
          name__v: '',
          id: '',
          label: item.label,
          name: item.name,
          picklist: item.picklist,
        });
      }
    }
  }

  makeUniqueEndpoints(): any {
    const source = this.categoryEndpoints;

    const endpointMap = new Map();
    for (const item of source) {
      if (!endpointMap.has(item.name)) {
        endpointMap.set(item.name, true);
        this.uniqueEndpoints.push({
          name__v: '',
          id: '',
          label: item.label,
          name: item.name,
          picklist: item.picklist,
        });
      }
    }
  }

  makeUniqueEndpointUnits(): any {
    const source = this.categoryEndpointUnits;
    const result = this.uniqueEndpointUnits;

    const endpointUnitMap = new Map();
    for (const item of source) {
      if (!endpointUnitMap.has(item.name)) {
        endpointUnitMap.set(item.name, true);
        result.push({
          name__v: '',
          id: '',
          label: item.label,
          name: item.name,
          picklist: item.picklist,
        });
      }
    }
  }

  addNewEndpoint(existingEndpoint): void {

    // make object
    const newEndpointTemplate: PhysChemDataEndpointTableItem = {
      id__c: this.generateId(),
      work_area__c: existingEndpoint.work_area__c,
      category__c: existingEndpoint.category__c,
      substance_name__c: existingEndpoint.substance_name__c,
      substance_type__c: existingEndpoint.substance_type__c ?? 'parent__c',
      related_substance__c: existingEndpoint.related_substance__c ?? '',
      endpoint__c: existingEndpoint.endpoint__c ?? '',
      endpoint_unit__c: existingEndpoint.endpoint_unit__c ?? '',
      test_system_type__c: existingEndpoint.test_system_type__c ?? '',
      operator__c: existingEndpoint.operator__c ?? '',
      value_1__c: existingEndpoint.value_1__c ?? null,
      value_2__c: existingEndpoint.value_2__c ?? null,
      value_3__c: existingEndpoint.value_3__c ?? '',
      // additional (depended) fields
      ph__c: existingEndpoint.ph__c ?? '',
      purity__c: existingEndpoint.purity__c ?? '',
      temp_c__c: existingEndpoint.temp_c__c ?? null,
      comment__c: existingEndpoint.comment__c ?? '',
      // default
      document_unbound__c: existingEndpoint.document_unbound__c ?? '',
    };

    this.copiedEndpointsArrayList.push(newEndpointTemplate);
  }

  generateId(): number {
    const newStartingId = this.startingId + 1;
    this.updateStartingId(newStartingId);
    return this.maxCategoryId - newStartingId;
  }

  updateStartingId(newStartingId): void {
    this.startingId = newStartingId;
  }

  copyEndpointInput(receivedEndpoint: any): void {
    this.showSuccessMessage = false;
    this.saveButtonShowing = true;
    // convert record values to string values
    const endpoint = JSON.parse(
      JSON.stringify(receivedEndpoint)
    );

    convertArrayToString(
      receivedEndpoint,
      endpoint,
      []
    );

    this.copiedEndpointsArrayList.filter(it => it.id__c === endpoint.id)
      .forEach(newEndpoint => {
        this.addNewEndpoint(newEndpoint);
      });

    this.addNewEndpoint(endpoint);
  }

  saveCopies(): void {
    this.haveErrors = false;

    this.loading = true;
    this.disabled = true;
    this.serverService.isLoading();

    this.showErrorMessage = false;
    this.successRecords = [];
    this.errorMessagesToUser = [];

    this.copiedEndpointsArrayList.forEach((record) => {
      const value1 = parseInt(String(record.value_1__c));
      const value2 = parseInt(String(record.value_2__c));

        console.log('record to save ' + JSON.stringify(record));

        if (record.operator__c === 'between__c') {
          if (value2 <= value1 || isNaN(value1) || isNaN(value2)) {
            this.haveErrors = true;
            console.log(value1 + ', ' + value2);
          }
        }
      }
    );

    if (this.haveErrors === true) {
      console.log('saving with errors!');
      this.loading = false;
      this.disabled = false;
      this.serverService.isNotLoading();
      this.toastrService.error('Value 2 must be greater than Value 1', 'Values Error');
    }
    else {

      const body = this.copiedEndpointsArrayList;
      this.serverService.postEndpoint(this.sessionId, body).subscribe(
        (success: CreateObjectsResponse) => {
          let created = 0;
          success.data?.forEach((record: CreateObjectResponse) => {
            if (record?.responseStatus === 'SUCCESS') {
              created++;
            }
            if (record?.responseStatus === 'FAILURE') {
              this.showErrorMessage = true;
              record?.errors?.forEach((error: VaultError) => {
                this.toastrService.error(error.message, error.type);
                this.errorMessagesToUser = this.errorMessagesToUser.concat(error.message);
              });
            }
          });
          this.toastrService.info(
            `${created} Endpoint(s) created successfully.`,
            'Info'
          );

          this.loading = false;
          this.disabled = false;
          const response = success.data;

          for (let i = 0; i < this.copiedEndpointsArrayList.length; i++) {
            for (let j = 0; j < response.length; j++) {
              if (i === j) {
                if (response[j].responseStatus === 'SUCCESS') {
                  this.successRecords.push(this.copiedEndpointsArrayList[i].id__c);
                }
              }
            }
          }
          this.clearSuccessRecords();
          console.log('this.successRecords: ' + JSON.stringify(this.successRecords));
        },
        (error: HttpErrorResponse) => {
          this.exceptionService.handleError(error);
          this.toastrService.error(
            `An error occurred while saving endpoints.`,
            'Error'
          );
        },
      );
      this.serverService.isNotLoading();
    }
  }

  clearSuccessRecords(): any {
    for (const recordId of this.successRecords) {
      // define new copiedEndpointArrayList by leaving out success records
      this.copiedEndpointsArrayList = this.copiedEndpointsArrayList.filter(
        obj => obj.id__c !== recordId
      );
    }
    // check if there is any endpoints in the list, if not remove 'Save' button
    if (this.copiedEndpointsArrayList.length === 0) {
      this.saveButtonShowing = false;
    }

    if (this.successRecords.length > 0) {
      this.showSuccessMessage = true;
      this.successMessagesToUser = this.successRecords.length + ' Endpoint/s saved successfully !';
    }
  }

  removeEndpointInput(endpoint: PhysChemDataEndpointTableItem): void {
    // remove object from array
    for (let index = 0; index < this.copiedEndpointsArrayList.length; index++) {
      const element: PhysChemDataEndpointTableItem = this.copiedEndpointsArrayList[index];
      if (element.id__c === endpoint.id__c) {
        this.copiedEndpointsArrayList.splice(index, 1);
      }
    }
    // check if there is any endpoints in the list, if not remove 'Save' button
    if (this.copiedEndpointsArrayList.length === 0) {
      this.saveButtonShowing = false;
    }
  }

  refreshEndpointList(): void {
    this.refreshEndpoints.emit();
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(MatDialogComponent, {
      width: 'auto',
      data: {dialogTitle: this.dialogTitle, dialogRow1: this.dialogRow1},
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      if (result === 'confirmed') {
        this.refreshEndpointList();
      }
    });
  }
}
