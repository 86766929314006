/**
 *  Create Excel file from binary data
 */

const contentType = 'application/vnd.openxmlformats-ficedocument.spreadsheetml.sheet';

export function convertBinaryDataToExcel(response: any, fileName: string): void {
  const dateTime = new Date().toISOString()
    .replace('T', '_')
    .replace(/:/g, '-')
    .split('.')[0];
  const blob = new Blob([response], {type: contentType});
  const downloadURL = window.URL.createObjectURL(blob);
  const link = document.createElement('a');

  link.href = downloadURL;
  link.download = fileName + '-' + dateTime + '.xlsx';
  link.click();
}
