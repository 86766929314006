import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AbstractControl, FormBuilder, FormGroup, Validators, } from '@angular/forms';
import {ToastrService} from 'ngx-toastr';
import {ExceptionService} from 'src/app/core/exception.service';
import {ActivatedRoute} from '@angular/router';
import {ServerService} from 'src/app/core/server.service';
import {CategoryPicklists} from '../models/category-picklists';
import {PicklistValue} from 'src/app/endpoint/models/picklist-value';
import {EndpointTableItem} from '../models/endpoint-table-item';
import {AdditionalField} from '../models/additional-field';
import {SubstanceItem} from '../../models/substance-item';
import {DocumentMenuItem} from '../../models/document-menu-item';

import {MatDialog} from '@angular/material/dialog';
import {AddDataMatDialogComponent} from '../../../shared/add-data-mat-dialog/add-data-mat-dialog.component';
import {EndpointServicesService} from '../../endpoint-services.service';

let me: EFateRecordComponent;

@Component({
  selector: 'app-e-fate-record',
  templateUrl: './e-fate-record.component.html',
  styleUrls: ['../../record_style.css']
})
export class EFateRecordComponent implements OnInit {
  @Input() singleEndpointRecord: EndpointTableItem;
  @Input() category: PicklistValue;
  @Input() categoryPicklists: Array<CategoryPicklists>;
  @Input() preAllStudyTypes: Array<PicklistValue>;
  @Input() allEndpoints: Array<PicklistValue>;
  @Input() allEndpointUnits: Array<PicklistValue>;
  @Input() operators: Array<PicklistValue>;
  @Input() allAdditionalFields: Array<AdditionalField>;
  @Input() allAdditionalFieldValues: Array<AdditionalField>;
  @Input() substance: SubstanceItem;
  @Input() substanceTypes: Array<PicklistValue>;
  @Input() relatedSubstances: any;
  @Input() documents: Array<DocumentMenuItem>;
  @Input() purpose: string; // make difference between adding new or editing existing endpoint record

  @Output() removeInput: EventEmitter<EndpointTableItem> = new EventEmitter();
  @Output() copyInput: EventEmitter<EndpointTableItem> = new EventEmitter();
  @Output() cancelEdit: EventEmitter<EndpointTableItem> = new EventEmitter();
  @Output() saveChanges: EventEmitter<EndpointTableItem> = new EventEmitter();

  subscriptions: any;
  selectFormGroup: FormGroup;
  configureFormGroup: FormGroup;
  public additionalFields: Array<AdditionalField>;
  public hiddenAdditionalFields: Array<AdditionalField>;
  public additionalFieldValues: Map<string, any>;
  public allStudyTypes: Array<PicklistValue>;
  public studyTypes: Array<PicklistValue>;
  public endpoints: Array<PicklistValue>;
  public endpointUnits: Array<PicklistValue>;
  public isValue1Disabled: boolean;
  public isValue2Disabled: boolean;
  public isValue3Disabled: boolean;
  public filteredRelatedSubstances: any;
  public showHiddenFields: boolean;
  public oldStudyTypeGroup: string;
  private sessionId: string;
  private userId: string;
  public showCopyOptions: boolean;
  public showEditOptions: boolean;
  public errorOnValue2: Boolean;
  public dialogTitle: string;
  public dialogRow1: string;
  public selectedPicklist: any;
  public currentList: any;
  private currentUserProfile: string;
  public editPicklistOption: boolean;

  constructor(
    private route: ActivatedRoute,
    private exceptionService: ExceptionService,
    private serverService: ServerService,
    private toastrService: ToastrService,
    private formBuilder: FormBuilder,
    public dialog: MatDialog,
    private endpointService: EndpointServicesService
  ) {
    me = this;
    this.subscriptions = {};
    this.route.paramMap.subscribe((params) => {
      this.sessionId = params.get('sessionId');
      this.userId = params.get('userId');
    });
    this.selectFormGroup = this.formBuilder.group({
      substanceType: [],
      relatedSubstance: [{value: '', disabled: true}],
      endpoint: [{value: '', disabled: true}, Validators.required],
      studyType: [{value: '', disabled: false}, Validators.required],
      endpointUnit: [{value: '', disabled: true}, Validators.required],
      operator: ['', Validators.required],
      value1: [{value: '', disabled: false}],
      value2: [{value: '', disabled: false}],
      value3: [{value: '', disabled: false}],

      crops1__c: [{value: '', disabled: false}],
      comment__c: [{value: '', disabled: false}],
      focus_step__c: [{value: '', disabled: false}],
      scenario_with_highest_concentration__c: [{value: '', disabled: false}],
      location_countrysite__c: [{value: '', disabled: false}],
      ptrigger__c: [{value: '', disabled: false}],
      data_normalization__c: [{value: '', disabled: false}],
      no_of_systemssitesconc__c: [{value: '', disabled: false}],
      temp_c__c: [{value: '', disabled: false}],
      kinetic_model__c: [{value: '', disabled: false}],
      ph_dependency__c: [{value: '', disabled: false}],
      ph_experiment__c: [{value: '', disabled: false}],
      method_of_calc__c: [{value: '', disabled: false}],
      dt50_used_for_pecsoil__c: [{value: '', disabled: false}],
      no_of_soilssites__c: [{value: '', disabled: false}],
      no_of_soils__c: [{value: '', disabled: false}],
      no_of_systemssites__c: [{value: '', disabled: false}],
      plant_uptake_factor_used_for_pecgw__c: [{value: '', disabled: false}],
      method_of_calculation__c: [{value: '', disabled: false}],
      dt50_used_for_pecgw__c: [{value: '', disabled: false}],
      kfoc_used_for_pecgw__c: [{value: '', disabled: false}],
      no_of_sites__c: [{value: '', disabled: false}],
      target_crop__c: [{value: '', disabled: false}],
      application_rate__c: [{value: '', disabled: false}],
      no_of_columns__c: [{value: '', disabled: false}],
      ageing_period__c: [{value: '', disabled: false}],
      duration__c: [{value: '', disabled: false}],
      soil_site__c: [{value: '', disabled: false}],
      relevance_trigger__c: [{value: '', disabled: false}],
      toxicological_relevance__c: [{value: '', disabled: false}],
      no_of_columnssites__c: [{value: '', disabled: false}],
      leaching_model__c: [{value: '', disabled: false}],
      metabolites_included__c: [{value: '', disabled: false}],
      population_group__c: [{value: '', disabled: false}],
      no_of_sitescountries__c: [{value: '', disabled: false}],

      selectedDocument: [{value: '', disabled: false}],
    });
    this.allStudyTypes = [];
    this.studyTypes = [];
    this.endpoints = [];
    this.endpointUnits = [];
    this.additionalFields = [];
    this.hiddenAdditionalFields = [];
    this.additionalFieldValues = new Map();
    this.isValue1Disabled = true;
    this.isValue2Disabled = true;
    this.isValue3Disabled = true;
    this.filteredRelatedSubstances = [];
    this.showHiddenFields = false;
    this.oldStudyTypeGroup = '';
    this.errorOnValue2 = false;
    this.dialogTitle = 'Edit ';
    this.dialogRow1 = '';
    this.selectedPicklist = [];
    this.currentList = [];
    this.editPicklistOption = false;
  }

  get relatedSubstanceControl(): AbstractControl {
    return this.selectFormGroup.get('relatedSubstance');
  }

  get endpointControl(): AbstractControl {
    return this.selectFormGroup.get('endpoint');
  }

  get endpointUnitControl(): AbstractControl {
    return this.selectFormGroup.get('endpointUnit');
  }

  get studyTypeControl(): AbstractControl {
    return this.selectFormGroup.get('studyType');
  }

  get value1Control(): AbstractControl {
    return this.selectFormGroup.get('value1');
  }

  get value2Control(): AbstractControl {
    return this.selectFormGroup.get('value2');
  }

  get value3Control(): AbstractControl {
    return this.selectFormGroup.get('value3');
  }

  get sourceControl(): AbstractControl {
    return this.selectFormGroup.get('selectedDocument');
  }

  ngOnInit(): void {

    if (this.purpose === 'editOnExisting') {
      this.showCopyOptions = false;
      this.showEditOptions = true;
    }
    else {
      this.showCopyOptions = true;
      this.showEditOptions = false;
    }

    this.allStudyTypes = this.preAllStudyTypes.sort((a, b) => a.name > b.name ? 1 : -1);

    this.onSelectOperator({});

    if (this.singleEndpointRecord.study_type__c) {
      // COPY RECORD

      this.selectFormGroup.get('endpoint').enable();
      if (this.singleEndpointRecord.endpoint__c) {
        this.selectFormGroup.get('endpointUnit').enable();
      }

      if (
        (this.singleEndpointRecord.study_type_picklist_name__c === 'study_type_soil_persistence_1__c' || this.singleEndpointRecord.study_type_picklist_name__c === 'study_type_soil_persistence_2__c')
        &&
        // eslint-disable-next-line max-len
        (this.singleEndpointRecord.endpoint__c === 'dt50_soil__c' || this.singleEndpointRecord.endpoint__c === 'dt50_soil_geomean__c' || this.singleEndpointRecord.endpoint__c === 'dt90_soil__c')
      ) {
        this.showHiddenFields = true;
      }

      if (
        // eslint-disable-next-line max-len
        (this.singleEndpointRecord.study_type_picklist_name__c === 'study_type_surface_water_1__c' || this.singleEndpointRecord.study_type_picklist_name__c === 'study_type_surface_water_2__c')
        &&
        (this.singleEndpointRecord.endpoint__c === 'dt50_water__c' || this.singleEndpointRecord.endpoint__c === 'dt90_water__c')) {
        this.showHiddenFields = true;
      }

      if (
        // eslint-disable-next-line max-len
        (this.singleEndpointRecord.study_type_picklist_name__c === 'study_type_drinking_water__c')
        &&
        // eslint-disable-next-line max-len
        (this.singleEndpointRecord.study_type__c === 'usedwc_acute__c' || this.singleEndpointRecord.study_type__c === 'usedwc_chronic__c' || this.singleEndpointRecord.study_type__c === 'eupec_raw_water__c')) {
        this.showHiddenFields = true;
      }


      if (this.showHiddenFields === true) {
        const preAdditionalFields = this.allAdditionalFields.filter(
          (entry) =>
            entry.group === this.singleEndpointRecord.study_type_picklist_name__c
        );
        this.additionalFields = preAdditionalFields.sort((a, b) => a.sortNumber - b.sortNumber);
      } else {
        const preAllAdditionalFields = this.allAdditionalFields.filter(
          (entry) =>
            entry.group === this.singleEndpointRecord.study_type_picklist_name__c
        );
        // remove fields mark with 'yes__c' in Initially Hidden field
        const preAdditionalFields = preAllAdditionalFields.filter(obj =>
          obj.hidden[0] !== 'yes__c'
        );
        this.additionalFields = preAdditionalFields.sort((a, b) => a.sortNumber - b.sortNumber);
      }

      // parent (or none) cannot have Related Substance
      if (this.singleEndpointRecord.substance_type__c === 'parent__c' || this.singleEndpointRecord.substance_type__c === '') {
        this.selectFormGroup.get('relatedSubstance').disable();
      } else {
        // filter Related Substances by the selected Substance Type
        this.filteredRelatedSubstances = this.relatedSubstances.filter(
          (f) => f.substance_type__c[0] === this.singleEndpointRecord.substance_type__c
        );
        this.selectFormGroup.get('relatedSubstance').enable();
      }

      this.setFormFields();
      this.filterStudyTypes(this.categoryPicklists.filter(
        (entry) =>
          entry.studyTypePicklist === this.singleEndpointRecord.study_type_picklist_name__c
      )[0].studyTypePicklist);
      this.filterEndpoints(this.categoryPicklists.filter(
        (entry) =>
          entry.studyTypePicklist === this.singleEndpointRecord.study_type_picklist_name__c
      )[0].endpointPicklist);
      this.filterEndpointUnits(this.categoryPicklists.filter(
        (entry) =>
          entry.studyTypePicklist === this.singleEndpointRecord.study_type_picklist_name__c
      )[0].endpointUnitPicklist);
    }
    else {
      this.selectFormGroup.get('substanceType').setValue('parent__c');
      this.studyTypes = this.allStudyTypes;
      this.endpoints = this.allEndpoints;
      this.endpointUnits = this.allEndpointUnits;
    }

    this.sourceControl.setValue(this.singleEndpointRecord.document_unbound__c);

    this.endpointService.userProfile.subscribe(
      (profile) => (this.currentUserProfile = profile)
    );

    this.editPicklistOption = this.currentUserProfile === 'vault_owner__v'
    || this.currentUserProfile === 'super_user__c';
  }

  getSelectedOptionPicklist(event: any): string {
    this.selectedPicklist = event.target.options[event.target.options.selectedIndex].title
    return event.target.options[event.target.options.selectedIndex].title;

  }

  onSelectSubstanceType(event: any): any {
    // update object
    this.singleEndpointRecord.substance_type__c = event.target.value;

    // clear Related Substance value because the group of Related Substances has changed
    this.singleEndpointRecord.related_substance__c = '';
    // also clear Related Substance form field
    this.relatedSubstanceControl.setValue('');

    // parent (or none) cannot have Related Substance
    if (event.target.value === 'parent__c' || event.target.value === '') {
      this.selectFormGroup.get('relatedSubstance').disable();
    } else {
      // filter Related Substances by the selected Substance Type
      this.filteredRelatedSubstances = this.relatedSubstances.filter(
        (f) => f.substance_type__c[0] === event.target.value
      );
      this.selectFormGroup.get('relatedSubstance').enable();
    }
  }

  onSelectStudyType(event: any): any {
    if (event.target.value !== '') {
      this.singleEndpointRecord.study_type__c = event.target.value;
      const newSTGroup = this.getSelectedOptionPicklist(event);

      if (newSTGroup !== this.singleEndpointRecord.study_type_picklist_name__c) {
        // study type group CHANGED:

        this.selectFormGroup.get('endpoint').disable();
        this.selectFormGroup.get('endpointUnit').disable();

        // reset additional fields, array and form
        if (this.singleEndpointRecord.study_type_picklist_name__c !== '') {
          this.resetGroupByStudyTpye();
        }

        const preAllAdditionalFields = this.allAdditionalFields.filter(
          (entry) =>
            entry.group === newSTGroup
        );
        // remove fields mark with 'yes__c' in Initially Hidden field
        const preAdditionalFields = preAllAdditionalFields.filter(obj =>
          obj.hidden[0] !== 'yes__c'
        );
        this.additionalFields = preAdditionalFields.sort((a, b) => a.sortNumber - b.sortNumber);
      }

      if (this.getSelectedOptionPicklist(event) === 'study_type_drinking_water__c') {
        if (event.target.value === 'usedwc_acute__c' || event.target.value === 'usedwc_chronic__c' || event.target.value === 'eupec_raw_water__c') {
          const preAllAdditionalFields = this.allAdditionalFields.filter(
            (entry) =>
              entry.group === newSTGroup
          );
          this.additionalFields = preAllAdditionalFields.sort((a, b) => a.sortNumber - b.sortNumber);
        } else {
          // remove Leaching model
          const preAllAdditionalFields = this.allAdditionalFields.filter(
            (entry) =>
              entry.group === newSTGroup
          );
          // remove fields mark with 'yes__c' in Initially Hidden field
          const preAdditionalFields = preAllAdditionalFields.filter(obj =>
            obj.hidden[0] !== 'yes__c'
          );
          this.additionalFields = preAdditionalFields.sort((a, b) => a.sortNumber - b.sortNumber);

          this.selectFormGroup.get('leaching_model__c').setValue('');
          this.singleEndpointRecord.leaching_model__c = '';
        }
      }

      // returns group picklists
      this.selectedPicklist = this.categoryPicklists.filter(
        (entry) =>
          entry.studyTypePicklist === this.getSelectedOptionPicklist(event)
      )[0];

      this.filterEndpoints(this.selectedPicklist.endpointPicklist);
      this.filterEndpointUnits(this.selectedPicklist.endpointUnitPicklist);
      this.singleEndpointRecord.study_type_picklist_name__c = this.getSelectedOptionPicklist(event);
      this.selectFormGroup.get('endpoint').enable();
    }
    else {

      this.endpointControl.setValue('');
      this.endpointUnitControl.setValue('');
      this.selectFormGroup.get('endpoint').disable();
      this.selectFormGroup.get('endpointUnit').disable();
      this.studyTypes = this.allStudyTypes;
      this.endpoints = this.allEndpoints;
      this.endpointUnits = this.allEndpointUnits;
      this.additionalFields = [];
      this.clearAllFieldValues();
    }
  }

  onSelectEndpoint(event: any): any {
    if (event.target.value !== '') {
      if (event.target.value === 'addNewRecord') {
        this.currentList = this.endpoints;
        this.openDialog('Endpoint', 'endpoint', this.endpoints[0].picklist, 'endpoint__c');
      }
      else {
        this.singleEndpointRecord.endpoint__c = event.target.value;

        const categoryPicklists = this.categoryPicklists.filter(
          (entry) =>
            entry.endpointPicklist === this.getSelectedOptionPicklist(event)
        )[0];

        console.log('category picklist from Endpoint: ' + JSON.stringify(categoryPicklists));

        this.filterEndpointUnits(categoryPicklists.endpointUnitPicklist);
        this.selectFormGroup.get('endpointUnit').enable();

        // eslint-disable-next-line max-len
        if (this.singleEndpointRecord.study_type_picklist_name__c === 'study_type_soil_persistence_1__c'
          || this.singleEndpointRecord.study_type_picklist_name__c === 'study_type_soil_persistence_2__c') {
          if (
            this.singleEndpointRecord.endpoint__c === 'dt50_soil__c' ||
            this.singleEndpointRecord.endpoint__c === 'dt50_soil_geomean__c' ||
            this.singleEndpointRecord.endpoint__c === 'dt90_soil__c'
          ) {
            this.showHideSpecialAdditionalFields(true);
          } else {
            this.showHideSpecialAdditionalFields(false);
          }
        }
        // eslint-disable-next-line max-len
        else if (this.singleEndpointRecord.study_type_picklist_name__c === 'study_type_surface_water_1__c'
          || this.singleEndpointRecord.study_type_picklist_name__c === 'study_type_surface_water_2__c') {
          if (
            this.singleEndpointRecord.endpoint__c === 'dt50_water__c' ||
            this.singleEndpointRecord.endpoint__c === 'dt90_water__c'
          ) {
            this.showHideSpecialAdditionalFields(true);
          } else {
            this.showHideSpecialAdditionalFields(false);
          }
        }
      }
    }
    else {
      this.selectFormGroup.get('endpointUnit').disable();
      this.endpointUnitControl.setValue('');
      this.singleEndpointRecord.endpoint_unit__c = '';
      this.endpointUnits = this.allEndpointUnits;
    }
  }

  showHideSpecialAdditionalFields(showHiddenFields): void {
    if (showHiddenFields === true) {
      const preAdditionalFields = this.allAdditionalFields.filter(
        (entry) =>
          entry.group === this.singleEndpointRecord.study_type_picklist_name__c
      );
      this.additionalFields = preAdditionalFields.sort((a, b) => a.sortNumber - b.sortNumber);
    }
    else {
      const additionalFields = this.additionalFields; // next lines of code will remove hidden fields,that's why using a constant
      const preAllAdditionalFields = this.allAdditionalFields.filter(
        (entry) =>
          entry.group === this.singleEndpointRecord.study_type_picklist_name__c
      );
      // remove fields mark with 'yes__c' in Initially Hidden field
      const preAdditionalFields = preAllAdditionalFields.filter(obj =>
        obj.hidden[0] !== 'yes__c'
      );
      this.additionalFields = preAdditionalFields.sort((a, b) => a.sortNumber - b.sortNumber);

      // filter hidden fields
      this.hiddenAdditionalFields = additionalFields.filter(
        (a) => a.hidden[0] === 'yes__c'
      );

      // clear values for these fields and their forms ...
      this.hiddenAdditionalFields.forEach((element: any) => {
        this.singleEndpointRecord[element.name] = '';
        this.selectFormGroup.get(element.name).setValue('');
      });
    }
  }

  onSelectUnit(event: any): any {
    if (event.target.value === 'addNewRecord') {
      this.currentList = this.endpointUnits;
      this.openDialog('Endpoint Unit', 'endpointUnit', this.endpointUnits[0].picklist, 'endpoint_unit__c');
    } else {
      this.singleEndpointRecord.endpoint_unit__c = event.target.value;
    }
  }

  onSelectOperator(event: any): any {
    if (event.target !== undefined) {
      this.singleEndpointRecord.operator__c = event.target.value;
    }

    switch (this.singleEndpointRecord.operator__c) {
      case 'between__c':
        this.isValue1Disabled = false;
        this.isValue2Disabled = false;
        this.isValue3Disabled = true;
        this.selectFormGroup.get('value1').enable();
        this.selectFormGroup.get('value2').enable();
        this.selectFormGroup.get('value3').disable();
        this.value3Control.setValue('');
        this.singleEndpointRecord.value_3__c = '';
        break;
      case 'text__c':
        this.isValue1Disabled = true;
        this.isValue2Disabled = true;
        this.isValue3Disabled = false;
        this.selectFormGroup.get('value1').disable();
        this.selectFormGroup.get('value2').disable();
        this.selectFormGroup.get('value3').enable();
        this.value1Control.setValue(null);
        this.value2Control.setValue(null);
        this.singleEndpointRecord.value_1__c = null;
        this.singleEndpointRecord.value_2__c = null;
        break;
      case '':
        this.isValue1Disabled = true;
        this.isValue2Disabled = true;
        this.isValue3Disabled = true;
        this.selectFormGroup.get('value1').disable();
        this.selectFormGroup.get('value2').disable();
        this.selectFormGroup.get('value3').disable();
        this.value1Control.setValue(null);
        this.value2Control.setValue(null);
        this.value3Control.setValue('');
        this.singleEndpointRecord.value_1__c = null;
        this.singleEndpointRecord.value_2__c = null;
        this.singleEndpointRecord.value_3__c = '';
        break;
      default:
        this.isValue1Disabled = false;
        this.isValue2Disabled = true;
        this.isValue3Disabled = true;
        this.selectFormGroup.get('value1').enable();
        this.selectFormGroup.get('value2').disable();
        this.selectFormGroup.get('value3').disable();
        this.value2Control.setValue(null);
        this.value3Control.setValue('');
        this.singleEndpointRecord.value_2__c = null;
        this.singleEndpointRecord.value_3__c = '';
        break;
    }
  }

  updateValue1(newValue): any {
    if (newValue.value < 0) {
      this.selectFormGroup.get('value1').setValue('');
    }
    else {
      this.singleEndpointRecord.value_1__c = newValue.value;
    }

    // checking if value 2 is bigger than value 1
    const value1 = parseInt(String(newValue.value));
    const value2 = parseInt(String(this.singleEndpointRecord.value_2__c));

    if (value2 <= value1 || isNaN(value1)) {
      this.errorOnValue2 = true;
      console.log('error: ' + value1 + ', ' + value2);
    }
    else {
      this.errorOnValue2 = false;
      console.log('clear ' + value1 + ', ' + value2);
    }
  }

  updateValue2(newValue): any {
    if (newValue.value < 0) {
      this.selectFormGroup.get('value2').setValue('');
    } else {
      this.singleEndpointRecord.value_2__c = newValue.value;
    }

     // checking if value 2 is bigger than value 1
    const value1 = parseInt(String(this.singleEndpointRecord.value_1__c));
    const value2 = parseInt(String(newValue.value));

    if (value2 <= value1 || isNaN(value2)) {
      this.errorOnValue2 = true;
      console.log('error: ' + value1 + ', ' + value2);
    }
    else {
      this.errorOnValue2 = false;
      console.log('clear ' + value1 + ', ' + value2);
    }
  }

  updateValue3(newValue): any {
    this.singleEndpointRecord.value_3__c = newValue.value;
  }

  updateFieldValue(newValue): any {
    const fieldName = newValue.accessKey;
    if (newValue.name === 'picklist' && newValue.value === 'addNewRecord') {
      this.singleEndpointRecord[fieldName] = '';

      this.currentList = this.filterAdditionalFieldValues(this.allAdditionalFieldValues, newValue.title);
      this.openDialog(newValue.id, fieldName, newValue.title, fieldName);
      // ------------(field label, target picklist, ---source picklist, --------
    }
    else {
      this.singleEndpointRecord[fieldName] = newValue.value;
    }
  }

  updateMultiPicklistFieldValue(newValue, fieldName): void {
    this.singleEndpointRecord[fieldName] = newValue.value.toString();
  }

  updateNumberFieldValue(newValue): any {
    const fieldName = newValue.title;

    if (fieldName === 'temp_c__c') {
      this.singleEndpointRecord[fieldName] = newValue.value;
    } else {
      if (newValue.value < 0) {
        this.singleEndpointRecord[fieldName] = '';
        this.selectFormGroup.get(fieldName).setValue('');
      } else {
        this.singleEndpointRecord[fieldName] = newValue.value;
      }
    }
  }

  filterEndpoints(picklist: string): any {
    this.endpoints = this.allEndpoints.filter(
      (entry) => entry.picklist === picklist
    );
  }

  filterStudyTypes(picklist: string): any {
    this.studyTypes = this.allStudyTypes.filter(
      (entry) => entry.picklist === picklist
    );
  }

  filterEndpointUnits(picklist: string): any {
    this.endpointUnits = this.allEndpointUnits.filter(
      (entry) => entry.picklist === picklist
    );
  }

  filterAdditionalFieldValues(
    allAdditionalFieldValues: Array<AdditionalField>,
    filterWord: string
  ): Array<AdditionalField> {
    return allAdditionalFieldValues.filter(
      (item) => item.picklist === filterWord
    );
  }

  clearAllFieldValues(): any {
    /*
    removes all values from object except id, work area, category, substance, substance type, operator and it's values and source
     */
    this.singleEndpointRecord.study_type__c = '';
    this.singleEndpointRecord.endpoint__c = '';
    this.singleEndpointRecord.endpoint_unit__c = '';
    // additional  fields
    this.singleEndpointRecord.crops1__c = '';
    this.singleEndpointRecord.comment__c = '';
    this.singleEndpointRecord.focus_step__c = '';
    this.singleEndpointRecord.scenario_with_highest_concentration__c = '';
    this.singleEndpointRecord.location_countrysite__c = '';
    this.singleEndpointRecord.ptrigger__c = '';
    this.singleEndpointRecord.data_normalization__c = '';
    this.singleEndpointRecord.no_of_systemssitesconc__c = '';
    this.singleEndpointRecord.temp_c__c = null;
    this.singleEndpointRecord.kinetic_model__c = '';
    this.singleEndpointRecord.ph_dependency__c = '';
    this.singleEndpointRecord.ph_experiment__c = null;
    this.singleEndpointRecord.method_of_calc__c = '';
    this.singleEndpointRecord.dt50_used_for_pecsoil__c = '';
    this.singleEndpointRecord.no_of_soilssites__c = '';
    this.singleEndpointRecord.no_of_soils__c = '';
    this.singleEndpointRecord.no_of_systemssites__c = '';
    this.singleEndpointRecord.plant_uptake_factor_used_for_pecgw__c = null;
    this.singleEndpointRecord.method_of_calculation__c = '';
    this.singleEndpointRecord.dt50_used_for_pecgw__c = null;
    this.singleEndpointRecord.kfoc_used_for_pecgw__c = null;
    this.singleEndpointRecord.no_of_sites__c = '';
    this.singleEndpointRecord.target_crop__c = '';
    this.singleEndpointRecord.application_rate__c = '';
    this.singleEndpointRecord.no_of_columns__c = '';
    this.singleEndpointRecord.ageing_period__c = '';
    this.singleEndpointRecord.duration__c = '';
    this.singleEndpointRecord.soil_site__c = '';
    this.singleEndpointRecord.relevance_trigger__c = '';
    this.singleEndpointRecord.toxicological_relevance__c = '';
    this.singleEndpointRecord.no_of_columnssites__c = '';
    this.singleEndpointRecord.leaching_model__c = '';
    this.singleEndpointRecord.metabolites_included__c = '';
    this.singleEndpointRecord.population_group__c = '';
    this.singleEndpointRecord.no_of_sitescountries__c = '';
    this.singleEndpointRecord.study_type_picklist_name__c = '';

    // reset field form
    this.studyTypeControl.setValue('');
    this.endpointControl.setValue('');
    this.endpointUnitControl.setValue('');
    this.selectFormGroup.get('crops1__c').setValue('');
    this.selectFormGroup.get('comment__c').setValue('');
    this.selectFormGroup.get('focus_step__c').setValue('');
    this.selectFormGroup.get('scenario_with_highest_concentration__c').setValue('');
    this.selectFormGroup.get('location_countrysite__c').setValue('');
    this.selectFormGroup.get('ptrigger__c').setValue('');
    this.selectFormGroup.get('data_normalization__c').setValue('');
    this.selectFormGroup.get('no_of_systemssitesconc__c').setValue('');
    this.selectFormGroup.get('temp_c__c').setValue(null);
    this.selectFormGroup.get('kinetic_model__c').setValue('');
    this.selectFormGroup.get('ph_dependency__c').setValue('');
    this.selectFormGroup.get('ph_experiment__c').setValue(null);
    this.selectFormGroup.get('method_of_calc__c').setValue('');
    this.selectFormGroup.get('dt50_used_for_pecsoil__c').setValue('');
    this.selectFormGroup.get('no_of_soilssites__c').setValue('');
    this.selectFormGroup.get('no_of_soils__c').setValue('');
    this.selectFormGroup.get('no_of_systemssites__c').setValue('');
    this.selectFormGroup.get('plant_uptake_factor_used_for_pecgw__c').setValue(null);
    this.selectFormGroup.get('method_of_calculation__c').setValue('');
    this.selectFormGroup.get('dt50_used_for_pecgw__c').setValue(null);
    this.selectFormGroup.get('kfoc_used_for_pecgw__c').setValue(null);
    this.selectFormGroup.get('no_of_sites__c').setValue('');
    this.selectFormGroup.get('target_crop__c').setValue('');
    this.selectFormGroup.get('application_rate__c').setValue('');
    this.selectFormGroup.get('no_of_columns__c').setValue('');
    this.selectFormGroup.get('ageing_period__c').setValue('');
    this.selectFormGroup.get('duration__c').setValue('');
    this.selectFormGroup.get('soil_site__c').setValue('');
    this.selectFormGroup.get('relevance_trigger__c').setValue('');
    this.selectFormGroup.get('toxicological_relevance__c').setValue('');
    this.selectFormGroup.get('no_of_columnssites__c').setValue('');
    this.selectFormGroup.get('leaching_model__c').setValue('');
    this.selectFormGroup.get('metabolites_included__c').setValue('');
    this.selectFormGroup.get('population_group__c').setValue('');
    this.selectFormGroup.get('no_of_sitescountries__c').setValue('');
  }

  resetGroupByStudyTpye(): any {
    this.singleEndpointRecord.endpoint__c = '';
    this.singleEndpointRecord.endpoint_unit__c = '';
    // additional  fields
    this.singleEndpointRecord.crops1__c = '';
    this.singleEndpointRecord.comment__c = '';
    this.singleEndpointRecord.focus_step__c = '';
    this.singleEndpointRecord.scenario_with_highest_concentration__c = '';
    this.singleEndpointRecord.location_countrysite__c = '';
    this.singleEndpointRecord.ptrigger__c = '';
    this.singleEndpointRecord.data_normalization__c = '';
    this.singleEndpointRecord.no_of_systemssitesconc__c = '';
    this.singleEndpointRecord.temp_c__c = null;
    this.singleEndpointRecord.kinetic_model__c = '';
    this.singleEndpointRecord.ph_dependency__c = '';
    this.singleEndpointRecord.ph_experiment__c = null;
    this.singleEndpointRecord.method_of_calc__c = '';
    this.singleEndpointRecord.dt50_used_for_pecsoil__c = '';
    this.singleEndpointRecord.no_of_soilssites__c = '';
    this.singleEndpointRecord.no_of_soils__c = '';
    this.singleEndpointRecord.no_of_systemssites__c = '';
    this.singleEndpointRecord.plant_uptake_factor_used_for_pecgw__c = null;
    this.singleEndpointRecord.method_of_calculation__c = '';
    this.singleEndpointRecord.dt50_used_for_pecgw__c = null;
    this.singleEndpointRecord.kfoc_used_for_pecgw__c = null;
    this.singleEndpointRecord.no_of_sites__c = '';
    this.singleEndpointRecord.target_crop__c = '';
    this.singleEndpointRecord.application_rate__c = '';
    this.singleEndpointRecord.no_of_columns__c = '';
    this.singleEndpointRecord.ageing_period__c = '';
    this.singleEndpointRecord.duration__c = '';
    this.singleEndpointRecord.soil_site__c = '';
    this.singleEndpointRecord.relevance_trigger__c = '';
    this.singleEndpointRecord.toxicological_relevance__c = '';
    this.singleEndpointRecord.no_of_columnssites__c = '';
    this.singleEndpointRecord.leaching_model__c = '';
    this.singleEndpointRecord.metabolites_included__c = '';
    this.singleEndpointRecord.population_group__c = '';
    this.singleEndpointRecord.no_of_sitescountries__c = '';
    this.singleEndpointRecord.study_type_picklist_name__c = '';
    // reset field form
    this.endpointControl.setValue('');
    this.endpointUnitControl.setValue('');
    this.selectFormGroup.get('crops1__c').setValue('');
    this.selectFormGroup.get('comment__c').setValue('');
    this.selectFormGroup.get('focus_step__c').setValue('');
    this.selectFormGroup.get('scenario_with_highest_concentration__c').setValue('');
    this.selectFormGroup.get('location_countrysite__c').setValue('');
    this.selectFormGroup.get('ptrigger__c').setValue('');
    this.selectFormGroup.get('data_normalization__c').setValue('');
    this.selectFormGroup.get('no_of_systemssitesconc__c').setValue('');
    this.selectFormGroup.get('temp_c__c').setValue(null);
    this.selectFormGroup.get('kinetic_model__c').setValue('');
    this.selectFormGroup.get('ph_dependency__c').setValue('');
    this.selectFormGroup.get('ph_experiment__c').setValue(null);
    this.selectFormGroup.get('method_of_calc__c').setValue('');
    this.selectFormGroup.get('dt50_used_for_pecsoil__c').setValue('');
    this.selectFormGroup.get('no_of_soilssites__c').setValue('');
    this.selectFormGroup.get('no_of_soils__c').setValue('');
    this.selectFormGroup.get('no_of_systemssites__c').setValue('');
    this.selectFormGroup.get('plant_uptake_factor_used_for_pecgw__c').setValue(null);
    this.selectFormGroup.get('method_of_calculation__c').setValue('');
    this.selectFormGroup.get('dt50_used_for_pecgw__c').setValue(null);
    this.selectFormGroup.get('kfoc_used_for_pecgw__c').setValue(null);
    this.selectFormGroup.get('no_of_sites__c').setValue('');
    this.selectFormGroup.get('target_crop__c').setValue('');
    this.selectFormGroup.get('application_rate__c').setValue('');
    this.selectFormGroup.get('no_of_columns__c').setValue('');
    this.selectFormGroup.get('ageing_period__c').setValue('');
    this.selectFormGroup.get('duration__c').setValue('');
    this.selectFormGroup.get('soil_site__c').setValue('');
    this.selectFormGroup.get('relevance_trigger__c').setValue('');
    this.selectFormGroup.get('toxicological_relevance__c').setValue('');
    this.selectFormGroup.get('no_of_columnssites__c').setValue('');
    this.selectFormGroup.get('leaching_model__c').setValue('');
    this.selectFormGroup.get('metabolites_included__c').setValue('');
    this.selectFormGroup.get('population_group__c').setValue('');
    this.selectFormGroup.get('no_of_sitescountries__c').setValue('');
  }

  removeEndpoint(singleEndpointRecord): any {
    this.removeInput.emit(singleEndpointRecord);
  }

  copyEndpoint(singleEndpointRecord: EndpointTableItem): void {
    this.copyInput.emit(singleEndpointRecord);
  }

  setFormFields(): void {
    this.selectFormGroup.get('substanceType').setValue(this.singleEndpointRecord.substance_type__c);
    this.selectFormGroup.get('relatedSubstance').setValue(this.singleEndpointRecord.related_substance__c);
    this.selectFormGroup.get('endpoint').setValue(this.singleEndpointRecord.endpoint__c);
    this.selectFormGroup.get('studyType').setValue(this.singleEndpointRecord.study_type__c);
    this.selectFormGroup.get('endpointUnit').setValue(this.singleEndpointRecord.endpoint_unit__c);
    this.selectFormGroup.get('operator').setValue(this.singleEndpointRecord.operator__c);
    this.selectFormGroup.get('value1').setValue(this.singleEndpointRecord.value_1__c);
    this.selectFormGroup.get('value2').setValue(this.singleEndpointRecord.value_2__c);
    this.selectFormGroup.get('value3').setValue(this.singleEndpointRecord.value_3__c);
    // additional fields
    this.selectFormGroup.get('crops1__c').setValue(this.singleEndpointRecord.crops1__c);
    this.selectFormGroup.get('comment__c').setValue(this.singleEndpointRecord.comment__c);
    this.selectFormGroup.get('focus_step__c').setValue(this.singleEndpointRecord.focus_step__c);
    // eslint-disable-next-line max-len
    this.selectFormGroup.get('scenario_with_highest_concentration__c').setValue
    (this.singleEndpointRecord.scenario_with_highest_concentration__c);
    this.selectFormGroup.get('location_countrysite__c').setValue(this.singleEndpointRecord.location_countrysite__c);
    this.selectFormGroup.get('ptrigger__c').setValue(this.singleEndpointRecord.ptrigger__c);
    this.selectFormGroup.get('data_normalization__c').setValue(this.singleEndpointRecord.data_normalization__c);
    this.selectFormGroup.get('no_of_systemssitesconc__c').setValue(this.singleEndpointRecord.no_of_systemssitesconc__c);
    this.selectFormGroup.get('temp_c__c').setValue(this.singleEndpointRecord.temp_c__c);
    this.selectFormGroup.get('kinetic_model__c').setValue(this.singleEndpointRecord.kinetic_model__c);
    this.selectFormGroup.get('ph_dependency__c').setValue(this.singleEndpointRecord.ph_dependency__c);
    this.selectFormGroup.get('ph_experiment__c').setValue(this.singleEndpointRecord.ph_experiment__c);
    this.selectFormGroup.get('method_of_calc__c').setValue(this.singleEndpointRecord.method_of_calc__c);
    this.selectFormGroup.get('dt50_used_for_pecsoil__c').setValue(this.singleEndpointRecord.dt50_used_for_pecsoil__c);
    this.selectFormGroup.get('no_of_soilssites__c').setValue(this.singleEndpointRecord.no_of_soilssites__c);
    this.selectFormGroup.get('no_of_soils__c').setValue(this.singleEndpointRecord.no_of_soils__c);
    this.selectFormGroup.get('no_of_systemssites__c').setValue(this.singleEndpointRecord.no_of_systemssites__c);
    // eslint-disable-next-line max-len
    this.selectFormGroup.get('plant_uptake_factor_used_for_pecgw__c').setValue
    (this.singleEndpointRecord.plant_uptake_factor_used_for_pecgw__c);
    this.selectFormGroup.get('method_of_calculation__c').setValue(this.singleEndpointRecord.method_of_calculation__c);
    this.selectFormGroup.get('dt50_used_for_pecgw__c').setValue(this.singleEndpointRecord.dt50_used_for_pecgw__c);
    this.selectFormGroup.get('kfoc_used_for_pecgw__c').setValue(this.singleEndpointRecord.kfoc_used_for_pecgw__c);
    this.selectFormGroup.get('no_of_sites__c').setValue(this.singleEndpointRecord.no_of_sites__c);
    this.selectFormGroup.get('target_crop__c').setValue(this.singleEndpointRecord.target_crop__c);
    this.selectFormGroup.get('application_rate__c').setValue(this.singleEndpointRecord.application_rate__c);
    this.selectFormGroup.get('no_of_columns__c').setValue(this.singleEndpointRecord.no_of_columns__c);
    this.selectFormGroup.get('ageing_period__c').setValue(this.singleEndpointRecord.ageing_period__c);
    this.selectFormGroup.get('duration__c').setValue(this.singleEndpointRecord.duration__c);
    this.selectFormGroup.get('soil_site__c').setValue(this.singleEndpointRecord.soil_site__c);
    this.selectFormGroup.get('relevance_trigger__c').setValue(this.singleEndpointRecord.relevance_trigger__c);
    this.selectFormGroup.get('toxicological_relevance__c').setValue(this.singleEndpointRecord.toxicological_relevance__c);
    this.selectFormGroup.get('no_of_columnssites__c').setValue(this.singleEndpointRecord.no_of_columnssites__c);
    this.selectFormGroup.get('leaching_model__c').setValue(this.singleEndpointRecord.leaching_model__c);
    this.selectFormGroup.get('metabolites_included__c').setValue(this.singleEndpointRecord.metabolites_included__c);
    this.selectFormGroup.get('population_group__c').setValue(this.singleEndpointRecord.population_group__c);
    this.selectFormGroup.get('no_of_sitescountries__c').setValue(this.singleEndpointRecord.no_of_sitescountries__c);
    this.selectFormGroup.get('selectedDocument').setValue(this.singleEndpointRecord.document_unbound__c);
  }

  cancelEditing(endpointRecord): void {
    this.cancelEdit.emit(endpointRecord);
  }

  saveEndpointChanges(endpointRecord): void {
    this.saveChanges.emit(endpointRecord);
  }

  openDialog(fieldLabel, fieldName, picklistName, targetPicklist): void {
    const dialogRef = this.dialog.open(AddDataMatDialogComponent, {
      width: 'auto',
      data: {
        sessionId: this.sessionId,
        dialogTitle: this.dialogTitle,
        typeOfDependency: 'group',
        dialogFieldLabel: fieldLabel,
        dialogFieldName: fieldName,
        currentList:this.currentList,
        sourcePicklist: picklistName,
        targetPicklist: targetPicklist,
        categoryId: this.category.id
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === 'canceled') {
        // user has canceled operation
      }
      else {
        if (fieldName === 'endpoint') {
          this.endpoints = this.endpoints.concat(result);
        }
        else if (fieldName === 'endpointUnit') {
          this.endpointUnits = this.endpointUnits.concat(result);
        }
        else {
          // additional field
          result.forEach((element) => {
            this.allAdditionalFieldValues.push(element);
          });
        }
      }
    });
  }

}
