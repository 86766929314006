import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {PicklistValue} from '../../../models/picklist-value';
import {ConsumerSafetyEndpointTableItem} from '../../models/consumer-safety-endpoint-table-item';
import {DocumentMenuItem} from '../../../models/document-menu-item';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute} from '@angular/router';
import {ExceptionService} from '../../../../core/exception.service';
import {ServerService} from '../../../../core/server.service';
import {ToastrService} from 'ngx-toastr';
import {ConsumerSafetyExisting} from '../../models/consumer-safety-existing';
import {convertArrayToString} from '../../../../shared/convert-array-to-string';
import {CreateObjectResponse, CreateObjectsResponse} from '../../../models/create-objects-response';
import {VaultError} from '../../../../core/models/vault-error';
import {HttpErrorResponse} from '@angular/common/http';
import {ConsumerSafetyAdditionalFields} from "../../models/consumer-safety-additional-fields";
import {ConsumerSafetyCategoryPicklists} from "../../models/consumer-safety-category-picklists";
import {SingleRecordDependencies} from "../../models/single-record-dependencies";
import {convertSpecialFieldsToArray, convertSpecialFieldsToString} from "../../../../shared/convert-special-fields";

let me: MetabolismExistingComponent;

@Component({
  selector: 'app-metabolism-existing',
  templateUrl: './metabolism-existing.component.html',
  styleUrls: ['../../../existing_record.css']
})
export class MetabolismExistingComponent implements OnInit {
  @Input() arrayedEndpointRecord: ConsumerSafetyExisting;
  @Input() category: any;
  @Input() categoryPicklists: Array<ConsumerSafetyCategoryPicklists>;
  @Input() allStudyTypes: Array<PicklistValue>;
  @Input() allTestSystemTypes: Array<PicklistValue>;
  @Input() allTestSystemNames: Array<PicklistValue>;
  @Input() allAdditionalFields: Array<ConsumerSafetyAdditionalFields>;
  @Input() allAdditionalFieldValues: Array<ConsumerSafetyAdditionalFields>;
  @Input() singleRecordDependencies: Array<SingleRecordDependencies>;
  @Input() substanceTypes: Array<PicklistValue>;
  @Input() relatedSubstances: any;
  @Input() identifiedMetabolites:Array<ConsumerSafetyAdditionalFields>;
  @Input() documents: Array<DocumentMenuItem>;
  @Input() allUsers: any;

  @Output() removeInput: EventEmitter<ConsumerSafetyEndpointTableItem> = new EventEmitter();
  @Output() copyExisting: EventEmitter<any> = new EventEmitter();

  subscriptions: any;
  selectFormGroup: FormGroup;
  configureFormGroup: FormGroup;
  public showTestSystemName: boolean;
  public existingEndpointRecord: any;
  public arrayToSave: any;
  public additionalFields: Array<ConsumerSafetyAdditionalFields>;
  public additionalFieldValues: Map<string, any>;
  public hiddenAdditionalFields: Array<ConsumerSafetyAdditionalFields>;
  public testSystemTypes: Array<PicklistValue>;
  public testSystemNames: Array<PicklistValue>;
  public userName: any;
  public createdBy: any;
  public modifiedBy: any;
  public formatCreatedDate: string;
  public formatModifiedDate: string;

  showExistingRecord = true;
  showEditRecord = false;
  public purposeMessage: string;
  private sessionId: string;

  constructor(
    private route: ActivatedRoute,
    private exceptionService: ExceptionService,
    private serverService: ServerService,
    private toastrService: ToastrService,
    private formBuilder: FormBuilder
  ) {
    me = this;
    this.subscriptions = {};
    this.route.paramMap.subscribe((params) => {
      this.sessionId = params.get('sessionId');
    });
    this.selectFormGroup = this.formBuilder.group({
      category__c: [{value: 'Acute Toxicity', disabled: true}],
      substanceType: [],
      relatedSubstance: [{value: '', disabled: true}],
      studyType: [{value: '', disabled: false}, Validators.required],
      testSystemTypeMultiSelect: [{value: [], disabled: true}],
      testSystemNameMultiSelect: [{value: [], disabled: true}],
      // additional (depended) fields
      adequate_study__c: [{value: '', disabled: false}],
      application_rate1__c: [{value: '', disabled: false}],
      application_type__c: [{value: '', disabled: false}],
      comment__c: [{value: '', disabled: false}],
      fat_soluble_residues__c: [{value: '', disabled: false}],
      human_unique_or_disproportional_metaboli__c: [{value: '', disabled: false}],
      identified_metabolites__c: [{value: '', disabled: false}],
      open_id_rate_topic__c: [{value: '', disabled: false}],
      open_isomer_topic__c: [{value: '', disabled: false}],
      open_radio_label_topic__c: [{value: '', disabled: false}],
      parent_only__c: [{value: '', disabled: false}],
      plateau_reached_in_milkeggs__c: [{value: '', disabled: false}],
      replant_interval_days__c: [{value: '', disabled: false}],
      residue_uptake_001_mgkg__c: [{value: '', disabled: false}],
      similar_to_metabolism_study__c: [{value: '', disabled: false}],
      similar_to_other_crop_groups__c: [{value: '', disabled: false}],
      similar_to_other_species__c: [{value: '', disabled: false}],
      similar_to_primary_crops__c: [{value: '', disabled: false}],
      similar_to_rat__c: [{value: '', disabled: false}],
      time_needed_to_reach_plateau_in_milkegg__c: [{value: '', disabled: false}],
      unit_application_rate__c: [{value: '', disabled: false}],
      // default
      source: [{value: '', disabled: false}],
    });
    this.showTestSystemName = false;
    this.additionalFields = [];
    this.additionalFieldValues = new Map();
    this.testSystemTypes = [];
    this.testSystemNames = [];

    this.purposeMessage = 'editOnExisting';
  }

  ngOnInit(): void {
    this.selectFormGroup.disable();

    // convert record values to string values
    this.existingEndpointRecord = JSON.parse(
      JSON.stringify(this.arrayedEndpointRecord)
    );

    convertArrayToString(
      this.arrayedEndpointRecord,
      this.existingEndpointRecord,
      []
    );

    convertSpecialFieldsToArray(this.existingEndpointRecord, ['test_system_type__c', 'test_system_name__c']);

    if (this.existingEndpointRecord.created_by__v === 1) {
      this.createdBy = [{name: 'System'}];
    } else {
      this.createdBy = this.allUsers.filter(
        (c) => c.id === this.existingEndpointRecord.created_by__v
      );
    }

    if (this.existingEndpointRecord.modified_by__v === 1) {
      // eslint-disable-next-line quote-props
      this.modifiedBy = [{name: 'System'}];
    } else {
      this.modifiedBy = this.allUsers.filter(
        (m) => m.id === this.existingEndpointRecord.modified_by__v
      );
    }

    this.formatCreatedDate =
      this.existingEndpointRecord.created_date__v.substring(0, 10) +
      ', ' +
      this.existingEndpointRecord.created_date__v.substring(11, 16) +
      'h Z';
    this.formatModifiedDate =
      this.existingEndpointRecord.modified_date__v.substring(0, 10) +
      ', ' +
      this.existingEndpointRecord.modified_date__v.substring(11, 16) +
      'h Z';

    this.selectFormGroup.get('source').setValue(this.existingEndpointRecord.document_unbound__c);
    this.showAdditionalFields();
    Object.keys(this.existingEndpointRecord).forEach((key) => this.checkDependency(key));
    this.setFormFields();
  }

  showAdditionalFields(): void {
    const preAllAdditionalFields = this.allAdditionalFields.filter(
      (entry) =>
        entry.group === this.existingEndpointRecord.study_type_picklist_name__c
    );
    // remove fields mark with 'yes__c' in Initially Hidden field
    const preAdditionalFields = preAllAdditionalFields.filter(obj =>
      obj.hidden !== 'yes__c'
    );
    this.additionalFields = preAdditionalFields.sort((a, b) => a.sortNumber - b.sortNumber);
  }

  checkDependency(fieldName: string): void {
    if (fieldName === 'parent_only__c') {
      if (this.existingEndpointRecord.parent_only__c === 'no__c') {
        // show dependency field
        this.showAdditionalField('identified_metabolites__c');
      } else {
        this.existingEndpointRecord.identified_metabolites__c = '';
        this.selectFormGroup.get('identified_metabolites__c').setValue('');
        // hide dependency field
        this.hideAdditionalField('identified_metabolites__c');
      }
    }

    if (fieldName === 'plateau_reached_in_milkeggs__c') {
      if (this.existingEndpointRecord.plateau_reached_in_milkeggs__c === 'yes__c') {
        // show dependency field
        this.showAdditionalField('time_needed_to_reach_plateau_in_milkegg__c');
      } else {
        this.existingEndpointRecord.time_needed_to_reach_plateau_in_milkegg__c = null;
        this.selectFormGroup.get('time_needed_to_reach_plateau_in_milkegg__c').setValue('');
        // hide dependency field
        this.hideAdditionalField('time_needed_to_reach_plateau_in_milkegg__c');
      }
    }

    if (fieldName === 'test_system_name__c' && this.existingEndpointRecord.test_system_name__c) {

      if (this.existingEndpointRecord.test_system_name__c.includes('human__c')) {

        const fieldToShow = this.additionalFields.filter(obj =>
          obj.name === 'human_unique_or_disproportional_metaboli__c');
        console.log(fieldToShow);

        if (fieldToShow.length > 0) {
          // nothing
        } else {
          this.showAdditionalField('human_unique_or_disproportional_metaboli__c');
        }

      } else {
        this.existingEndpointRecord.human_unique_or_disproportional_metaboli__c = '';
        this.selectFormGroup.get('human_unique_or_disproportional_metaboli__c').setValue('');
        // hide dependency field
        this.hideAdditionalField('human_unique_or_disproportional_metaboli__c');
      }
    }
  }

  showAdditionalField(fieldName: string): void {
    // get all additional fields for study type group
    const allAdditionalFields = this.allAdditionalFields.filter(
      (entry) =>
        entry.group === this.existingEndpointRecord.study_type_picklist_name__c
    );
    // find field to show
    const fieldToShow = allAdditionalFields.filter(obj =>
      obj.name === fieldName
    );
    this.additionalFields = this.additionalFields.concat(fieldToShow);
    this.additionalFields = this.additionalFields.sort((a, b) => a.sortNumber - b.sortNumber);
  }

  hideAdditionalField(fieldName: string): void {
    const preAdditionalFields = this.additionalFields.filter(obj =>
      obj.name !== fieldName
    );
    this.additionalFields = preAdditionalFields.sort((a, b) => a.sortNumber - b.sortNumber);
  }

  setFormFields(): void {
    this.selectFormGroup.get('category__c').setValue(this.existingEndpointRecord.category__c);
    this.selectFormGroup.get('substanceType').setValue(this.existingEndpointRecord.substance_type__c);
    this.selectFormGroup.get('relatedSubstance').setValue(this.existingEndpointRecord.related_substance__c);
    this.selectFormGroup.get('studyType').setValue(this.existingEndpointRecord.study_type__c);
    if (this.existingEndpointRecord.test_system_type__c) {
      this.selectFormGroup.get('testSystemTypeMultiSelect').setValue(this.existingEndpointRecord.test_system_type__c);
    }
    if (this.existingEndpointRecord.test_system_name__c) {
      this.selectFormGroup.get('testSystemNameMultiSelect').setValue(this.existingEndpointRecord.test_system_name__c);
    }
    // additional fields
    this.selectFormGroup.get('adequate_study__c').setValue(this.existingEndpointRecord.adequate_study__c);
    this.selectFormGroup.get('application_rate1__c').setValue(this.existingEndpointRecord.application_rate1__c);
    this.selectFormGroup.get('application_type__c').setValue(this.existingEndpointRecord.application_type__c);
    this.selectFormGroup.get('comment__c').setValue(this.existingEndpointRecord.comment__c);
    this.selectFormGroup.get('fat_soluble_residues__c').setValue(this.existingEndpointRecord.fat_soluble_residues__c);
    this.selectFormGroup.get('human_unique_or_disproportional_metaboli__c')
      .setValue(this.existingEndpointRecord.human_unique_or_disproportional_metaboli__c);
    if (this.existingEndpointRecord.identified_metabolites__c) {
      this.selectFormGroup.get('identified_metabolites__c')
        .setValue(this.existingEndpointRecord.identified_metabolites__c.split(','));
    }
    this.selectFormGroup.get('open_id_rate_topic__c').setValue(this.existingEndpointRecord.open_id_rate_topic__c);
    this.selectFormGroup.get('open_isomer_topic__c').setValue(this.existingEndpointRecord.open_isomer_topic__c);
    this.selectFormGroup.get('open_radio_label_topic__c').setValue(this.existingEndpointRecord.open_radio_label_topic__c);
    this.selectFormGroup.get('parent_only__c').setValue(this.existingEndpointRecord.parent_only__c);
    this.selectFormGroup.get('plateau_reached_in_milkeggs__c').setValue(this.existingEndpointRecord.plateau_reached_in_milkeggs__c);
    if (this.existingEndpointRecord.replant_interval_days__c) {
      this.selectFormGroup.get('replant_interval_days__c')
        .setValue(this.existingEndpointRecord.replant_interval_days__c.split(','));
    }
    this.selectFormGroup.get('residue_uptake_001_mgkg__c').setValue(this.existingEndpointRecord.residue_uptake_001_mgkg__c);
    this.selectFormGroup.get('similar_to_metabolism_study__c').setValue(this.existingEndpointRecord.similar_to_metabolism_study__c);
    this.selectFormGroup.get('similar_to_other_crop_groups__c').setValue(this.existingEndpointRecord.similar_to_other_crop_groups__c);
    this.selectFormGroup.get('similar_to_other_species__c').setValue(this.existingEndpointRecord.similar_to_other_species__c);
    this.selectFormGroup.get('similar_to_primary_crops__c').setValue(this.existingEndpointRecord.similar_to_primary_crops__c);
    this.selectFormGroup.get('similar_to_rat__c').setValue(this.existingEndpointRecord.similar_to_rat__c);
    this.selectFormGroup.get('time_needed_to_reach_plateau_in_milkegg__c').setValue(this.existingEndpointRecord.time_needed_to_reach_plateau_in_milkegg__c);
    this.selectFormGroup.get('unit_application_rate__c').setValue(this.existingEndpointRecord.unit_application_rate__c);
    // default
    this.selectFormGroup.get('source').setValue(this.existingEndpointRecord.document_unbound__c);
  }

  enableEditing(): any {
    this.showExistingRecord = false;
    this.showEditRecord = true;
  }

  cancelEditing(): void {
    this.showExistingRecord = true;
    this.showEditRecord = false;
  }

  saveChanges(endpointRecord): void {
    this.serverService.isLoading();

    delete endpointRecord.created_by__v;
    delete endpointRecord.modified_by__v;
    delete endpointRecord.created_date__v;
    delete endpointRecord.modified_date__v;

    this.arrayToSave = endpointRecord;
    convertSpecialFieldsToString(this.arrayToSave, ['test_system_type__c', 'test_system_name__c']);

    const body = Array(this.arrayToSave);

    this.serverService.updateEndpoint(this.sessionId, body).subscribe(
      (success: CreateObjectsResponse) => {
        let updated = 0;
        success.data?.forEach((record: CreateObjectResponse) => {
          if (record?.responseStatus === 'SUCCESS') {
            updated++;
          }
          if (record?.responseStatus === 'FAILURE') {
            record?.errors?.forEach((error: VaultError) => {
              this.toastrService.error(error.message, error.type);
            });
          }
        });

        this.toastrService.info(
          ` Endpoint updated successfully.`,
          'Info'
        );
        this.serverService.isNotLoading();
      },
      (error: HttpErrorResponse) => {
        this.exceptionService.handleError(error);
        this.toastrService.error(
          `An error occurred while updating endpoint.`,
          'Error'
        );
        this.serverService.isNotLoading();
      }
    );

    this.showExistingRecord = true;
    this.showEditRecord = false;
    convertSpecialFieldsToArray(endpointRecord, ['test_system_type__c', 'test_system_name__c']);
    this.existingEndpointRecord = endpointRecord;
    console.log('updated record: ' + JSON.stringify(this.existingEndpointRecord));
    this.showAdditionalFields();
    Object.keys(this.existingEndpointRecord).forEach((key) => this.checkDependency(key));
    this.setFormFields();
  }

  copyExistingEndpoint(endpoint: ConsumerSafetyExisting): any {
    this.copyExisting.emit(endpoint);
  }

  filterAdditionalFieldValues(
    allAdditionalFieldValues: Array<ConsumerSafetyAdditionalFields>,
    filterWord: string,
  ): Array<ConsumerSafetyAdditionalFields> {
    if (filterWord === 'ghost__c') {
      return this.identifiedMetabolites;
    } else {
      return allAdditionalFieldValues.filter(
        (item) => item.picklist === filterWord
      );
    }
  }

}
