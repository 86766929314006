<div class="container">
  <div *ngIf="!isHidden" class="progress-bar-container">
    <div [ngClass]="{ hidden: isHidden }">
      <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </div>
  </div>
  <app-header *ngIf="checkHeaderVisibility()"></app-header>
  <div class="router-container">
    <router-outlet></router-outlet>
  </div>
  <div *ngIf="checkHeaderVisibility()">
    <app-footer></app-footer>
  </div>
</div>
