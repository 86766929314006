import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {PicklistValue} from '../../../models/picklist-value';
import {DocumentMenuItem} from '../../../models/document-menu-item';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute} from '@angular/router';
import {ExceptionService} from '../../../../core/exception.service';
import {ServerService} from '../../../../core/server.service';
import {ToastrService} from 'ngx-toastr';
import {convertArrayToString} from '../../../../shared/convert-array-to-string';
import {CreateObjectResponse, CreateObjectsResponse} from '../../../models/create-objects-response';
import {VaultError} from '../../../../core/models/vault-error';
import {HttpErrorResponse} from '@angular/common/http';
import {PhysChemDataExisting} from "../../models/phys-chem-data-existing";
import {PhysChemDataAdditionalField} from "../../models/phys-chem-data-additional-field";
import {PhysChemDataEndpointTableItem} from "../../models/phys-chem-data-endpoint-table-item";

let me: PhysChemDataAllCategoriesExistingComponent;

@Component({
  selector: 'app-phys-chem-data-all-categories-existing',
  templateUrl: './phys-chem-data-all-categories-existing.component.html',
  styleUrls: ['../../../existing_record.css']
})
export class PhysChemDataAllCategoriesExistingComponent implements OnInit {
  @Input() arrayedEndpointRecord: PhysChemDataExisting;
  @Input() category: any;
  @Input() testSystemTypes: Array<PicklistValue>;
  @Input() allEndpoints: Array<PicklistValue>;
  @Input() allEndpointUnits: Array<PicklistValue>;
  @Input() allOperators: Array<PicklistValue>;
  @Input() allAdditionalFields: Array<PhysChemDataAdditionalField>;
  @Input() substanceTypes: Array<PicklistValue>;
  @Input() relatedSubstances: any;
  @Input() documents: Array<DocumentMenuItem>;
  @Input() allUsers: any;

  @Output() removeInput: EventEmitter<PhysChemDataEndpointTableItem> = new EventEmitter();
  @Output() copyExisting: EventEmitter<any> = new EventEmitter();

  subscriptions: any;
  selectFormGroup: FormGroup;
  configureFormGroup: FormGroup;
  public existingEndpointRecord: any;
  public additionalFields: Array<PhysChemDataAdditionalField>;
  public additionalFieldValues: Map<string, any>;
  public operators: Array<PicklistValue>;
  public values31: Array<any>;
  public isEndpointUnitHidden: boolean;
  public hiddenAdditionalFields: Array<PhysChemDataAdditionalField>;
  public isValue1Disabled: boolean;
  public isValue2Disabled: boolean;
  public isValue3Disabled: boolean;
  public userName: any;
  public createdBy: any;
  public modifiedBy: any;
  public formatCreatedDate: string;
  public formatModifiedDate: string;
  private haveErrors: Boolean;
  showExistingRecord = true;
  showEditRecord = false;
  public purposeMessage: string;
  private sessionId: string;

  constructor(
    private route: ActivatedRoute,
    private exceptionService: ExceptionService,
    private serverService: ServerService,
    private toastrService: ToastrService,
    private formBuilder: FormBuilder
  ) {
    me = this;
    this.subscriptions = {};
    this.route.paramMap.subscribe((params) => {
      this.sessionId = params.get('sessionId');
    });
    this.selectFormGroup = this.formBuilder.group({
      category__c: [{value: 'Acute Toxicity', disabled: true}],
      substanceType: [],
      relatedSubstance: [{value: '', disabled: true}],
      test_system_type__c: [{value: '', disabled: false}],
      endpoint: [{value: '', disabled: false}, Validators.required],
      endpointUnit: [{value: '', disabled: false}, Validators.required],
      operator: ['', Validators.required],
      value1: [{value: '', disabled: false}],
      value2: [{value: '', disabled: false}],
      value3: [{value: '', disabled: false}],
      // additional fields
      ph__c: [{value: '', disabled: false}],
      purity__c: [{value: '', disabled: false}],
      temp_c__c: [{value: '', disabled: false}],
      comment__c: [{value: '', disabled: false}],
      // default
      source: [{value: '', disabled: false}],
    });

    this.operators = [];
    this.values31 = [];
    this.additionalFields = [];
    this.isEndpointUnitHidden = false;
    this.isValue1Disabled = true;
    this.isValue2Disabled = true;
    this.isValue3Disabled = true;
    this.purposeMessage = 'editOnExisting';
  }

  ngOnInit(): void {
    this.selectFormGroup.disable();

    // convert record values to string values
    this.existingEndpointRecord = JSON.parse(
      JSON.stringify(this.arrayedEndpointRecord)
    );

    convertArrayToString(
      this.arrayedEndpointRecord,
      this.existingEndpointRecord,
      []
    );

    if (this.existingEndpointRecord.created_by__v === 1) {

      this.createdBy = [{name: 'System'}];
    } else {
      this.createdBy = this.allUsers.filter(
        (c) => c.id === this.existingEndpointRecord.created_by__v
      );
    }

    if (this.existingEndpointRecord.modified_by__v === 1) {
      // eslint-disable-next-line quote-props
      this.modifiedBy = [{name: 'System'}];
    } else {
      this.modifiedBy = this.allUsers.filter(
        (m) => m.id === this.existingEndpointRecord.modified_by__v
      );
    }

    this.formatCreatedDate =
      this.existingEndpointRecord.created_date__v.substring(0, 10) +
      ', ' +
      this.existingEndpointRecord.created_date__v.substring(11, 16) +
      'h Z';
    this.formatModifiedDate =
      this.existingEndpointRecord.modified_date__v.substring(0, 10) +
      ', ' +
      this.existingEndpointRecord.modified_date__v.substring(11, 16) +
      'h Z';

    this.selectFormGroup.get('source').setValue(this.existingEndpointRecord.document_unbound__c);

    const filteredOperators = [
      'a1595852199787__c',
      'a1595852189563__c',
      'a1595852194618__c',
      'a1595852268945__c',
      'a1595852266201__c',
      'between__c',
      'a1595852359285__c',
      'text__c'
    ];

    filteredOperators.forEach(operator => {
        const singleOperator = this.allOperators.filter(
          (entry) => entry.name === operator
        );
        this.operators = this.operators.concat(singleOperator);
      }
    );
    this.checkVisibility();
    this.setValuesVisibility(this.existingEndpointRecord.operator__c);
    this.setFormFields();
  }

  checkVisibility(): void {

    const phField = this.allAdditionalFields.filter((it) => it.name === 'ph__c');

    if (this.category.name__v !== 'Vapour Pressure' && this.category.name__v !== 'Henry\'s Law Constant') {
      phField.forEach((it) => it.hidden = 'no__c');
    } else {
      phField.forEach((it) => it.hidden = 'yes__c');
    }

    const tempField = this.allAdditionalFields.filter((it) => it.name === 'temp_c__c');
    if (this.category.name__v !== 'Dissociation Constant' &&
      this.category.name__v !== 'UV/Vis Absorption' &&
      this.category.name__v !== 'Stereochemistry') {
      tempField.forEach((it) => it.hidden = 'no__c');
    } else {
      tempField.forEach((it) => it.hidden = 'yes__c');
    }

    this.isEndpointUnitHidden = !(this.category.name__v == 'Vapour Pressure' ||
      this.category.name__v == 'Henry\'s Law Constant' ||
      this.category.name__v == 'Solubility' ||
      this.category.name__v == 'UV/Vis Absorption');

    this.setAdditionalFields();
  }

  setAdditionalFields(): void {
    const preAdditionalFields = this.allAdditionalFields.filter(obj => obj.hidden !== 'yes__c');
    this.additionalFields = preAdditionalFields.sort((a, b) => a.sortNumber - b.sortNumber);
  }

  setValuesVisibility(operatorValue): void {
    if (operatorValue) {
      switch (operatorValue) {
        case 'between__c':
          this.isValue1Disabled = false;
          this.isValue2Disabled = false;
          this.isValue3Disabled = true;
          break;
        case 'text__c':
          this.isValue1Disabled = true;
          this.isValue2Disabled = true;
          this.isValue3Disabled = false;
          break;
        case null:
          this.isValue1Disabled = true;
          this.isValue2Disabled = true;
          this.isValue3Disabled = true;
          break;
        default:
          this.isValue1Disabled = false;
          this.isValue2Disabled = true;
          this.isValue3Disabled = true;
          break;
      }
    }
    else {
      this.isValue1Disabled = true;
      this.isValue2Disabled = true;
      this.isValue3Disabled = true;
    }
  }

  setFormFields(): void {
    this.selectFormGroup.get('category__c').setValue(this.existingEndpointRecord.category__c);
    this.selectFormGroup.get('substanceType').setValue(this.existingEndpointRecord.substance_type__c);
    this.selectFormGroup.get('relatedSubstance').setValue(this.existingEndpointRecord.related_substance__c);
    this.selectFormGroup.get('test_system_type__c').setValue(this.existingEndpointRecord.test_system_type__c);
    this.selectFormGroup.get('endpoint').setValue(this.existingEndpointRecord.endpoint__c);
    this.selectFormGroup.get('endpointUnit').setValue(this.existingEndpointRecord.endpoint_unit__c);
    this.selectFormGroup.get('operator').setValue(this.existingEndpointRecord.operator__c);
    this.selectFormGroup.get('value1').setValue(this.existingEndpointRecord.value_1__c);
    this.selectFormGroup.get('value2').setValue(this.existingEndpointRecord.value_2__c);
    this.selectFormGroup.get('value3').setValue(this.existingEndpointRecord.value_3__c);
    // additional fields
    this.selectFormGroup.get('ph__c').setValue(this.existingEndpointRecord.ph__c);
    this.selectFormGroup.get('purity__c').setValue(this.existingEndpointRecord.purity__c);
    this.selectFormGroup.get('temp_c__c').setValue(this.existingEndpointRecord.temp_c__c);
    this.selectFormGroup.get('comment__c').setValue(this.existingEndpointRecord.comment__c);
    // default
    this.selectFormGroup.get('source').setValue(this.existingEndpointRecord.document_unbound__c);
  }

  enableEditing(): any {
    this.showExistingRecord = false;
    this.showEditRecord = true;
  }

  cancelEditing(): void {
    this.showExistingRecord = true;
    this.showEditRecord = false;
  }

  saveChanges(endpointRecord): void {
    this.haveErrors = false;

    this.serverService.isLoading();

    delete endpointRecord.created_by__v;
    delete endpointRecord.modified_by__v;
    delete endpointRecord.created_date__v;
    delete endpointRecord.modified_date__v;

    const value1 = parseInt(String(endpointRecord.value_1__c));
    const value2 = parseInt(String(endpointRecord.value_2__c));

    if (endpointRecord.operator__c === 'between__c') {
      if (value2 <= value1 || isNaN(value1) || isNaN(value2)) {
        this.haveErrors = true;
        console.log(value1 + ', ' + value2);
      }
    }

    if (this.haveErrors === true) {
      console.log('saving with errors!');
      this.serverService.isNotLoading();
      this.toastrService.error('Value 2 must be greater than Value 1', 'Values Error');
    }
    else {
      const body = Array(endpointRecord);

      this.serverService.updateEndpoint(this.sessionId, body).subscribe(
        (success: CreateObjectsResponse) => {
          let updated = 0;
          success.data?.forEach((record: CreateObjectResponse) => {
            if (record?.responseStatus === 'SUCCESS') {
              updated++;
            }
            if (record?.responseStatus === 'FAILURE') {
              record?.errors?.forEach((error: VaultError) => {
                this.toastrService.error(error.message, error.type);
              });
            }
          });

          this.toastrService.info(
            ` Endpoint updated successfully.`,
            'Info'
          );
          this.serverService.isNotLoading();
        },
        (error: HttpErrorResponse) => {
          this.exceptionService.handleError(error);
          this.toastrService.error(
            `An error occurred while updating endpoint.`,
            'Error'
          );
          this.serverService.isNotLoading();
        }
      );

      this.showExistingRecord = true;
      this.showEditRecord = false;
      this.existingEndpointRecord = endpointRecord;
      this.setValuesVisibility(this.existingEndpointRecord.operator__c);
      this.setFormFields();
    }
  }

  copyExistingEndpoint(endpoint: PhysChemDataExisting): any {
    this.copyExisting.emit(endpoint);
  }

  filterAdditionalFieldValues(
    allAdditionalFieldValues: Array<PicklistValue>,
    filterWord: string
  ): Array<PicklistValue> {
    return allAdditionalFieldValues.filter(
      (item) => item.picklist === filterWord
    );
  }

}
