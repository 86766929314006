import {Component, ElementRef, Input, OnChanges, OnInit, ViewChild} from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';
import {ServerService} from 'src/app/core/server.service';
import {ToastrService} from 'ngx-toastr';
import {ExceptionService} from 'src/app/core/exception.service';
import {ActivatedRoute} from '@angular/router';
import {PicklistValue} from '../../models/picklist-value';
import {EcotoxCategoryPicklists} from '../models/ecotox-category-picklists';
import {EcotoxAdditionalField} from '../models/ecotox-additional-field';
import {EcotoxExistingEndpoint} from '../models/ecotox-existing-endpoint';
import {DocumentMenuItem} from '../../models/document-menu-item';
import {SubstanceItem} from '../../models/substance-item';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {MatAutocomplete, MatAutocompleteSelectedEvent, } from '@angular/material/autocomplete';
import {MatChipInputEvent} from '@angular/material/chips';
import {PageEvent} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import {TestGuidelines} from '../../models/test-guidelines';

@Component({
  selector: 'app-existing-ecotox-endpoints',
  templateUrl: './existing-ecotox-endpoints.component.html',
  styleUrls: ['./existing-ecotox-endpoints.component.css'],
})

export class ExistingEcotoxEndpointsComponent implements OnInit,OnChanges {
  dataSource: MatTableDataSource<any>;
  data: Array<any>;
  pageSize: number;
  pageSkip: number;
  dataSourceLength: number;
  loaderHidden = true;
  searchButtonHidden = false;

  // for filters
  visible = true;
  selectable = true;
  removable = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  fieldCtrl = new FormControl();
  filteredFields: Observable<string[]>;
  fields: string[] = ['Category', 'Related Substance'];
  public allFields: string[];

  @ViewChild('fieldInput') fieldInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto') matAutocomplete: MatAutocomplete;

  /**
   * for documents filtering
   */
  searchUserModifyControl: FormControl = new FormControl();
  searchUserCreateControl: FormControl = new FormControl();
  filteredCreateUsers: Observable<any>;
  filteredModifiedUsers: Observable<any>;
  /** */

  subscriptions: any;
  public panelOpenState = false;
  public expandPanel = false;
  public category__c: any;
  public substance_type__c: any;
  public related_substance__c: any;
  public study_type__c: any;
  public endpoint__c: any;
  public endpoint_unit__c: any;
  public operator__c: any;
  public value_1__c: number;
  public value_2__c: number;
  public created_by__v: any;
  public modified_by__v: any;
  public selectedDocumentId: number;
  public test_system_type__c: string;
  public test_species__c: string;
  public exposure_duration__c: number;
  public exposure_duration_unit__c: string;
  public exposure_design__c: string;
  public ed_relevant__c: string;
  public trigger_ed__c: string;
  public ed_modality__c: string;
  public ed_mechanisms__c: string;
  public test_guideline__c: string;
  public used_in_risk_assessment__c: string;
  public endpoint_analytically_based_on__c: string;
  public t_trigger__c: string;
  public affected_ed__c: string; // multi-value
  public trr__c: string;
  public normalization__c: string;
  public b_trigger__c: string;
  public substrate__c: string;
  public recovery_time__c: number;
  public recovery_time_unit__c: string;
  public peat_organic_matter__c: number;
  public comment__c: string;
  public basf_docid__c: number;
  public created_date_start: number;
  public created_date_end: number;
  public modified_date_start: number;
  public modified_date_end: number;

  @Input() receivedCategoryPicklists: Array<EcotoxCategoryPicklists>;
  @Input() workAreaName: string;
  @Input() workAreaId: string;
  @Input() substance: SubstanceItem;
  @Input() receivedCategories: Array<PicklistValue>;
  @Input() receivedAllStudyTypes: Array<PicklistValue>;
  @Input() receivedAllEndpoints: Array<PicklistValue>;
  @Input() receivedAllEndpointUnits: Array<PicklistValue>;
  @Input() receivedAllTestSystemTypes: Array<PicklistValue>;
  @Input() receivedAllTestSpecies: Array<PicklistValue>;
  @Input() allTestGuidelines: Array<PicklistValue>;
  @Input() allTestGuidelinePicklists: Array<TestGuidelines>;
  @Input() receivedAllOperators: Array<PicklistValue>;
  @Input() receivedAllAdditionalFields: Array<EcotoxAdditionalField>;
  @Input() receivedAdditionalFieldValues: Array<EcotoxAdditionalField>;
  @Input() substanceTypes: Array<PicklistValue>;
  @Input() relatedSubstances: any;
  @Input() documents: Array<DocumentMenuItem>;
  public existingEndpoints: Array<EcotoxExistingEndpoint>;
  public uniqueAdditionalFields: Array<EcotoxAdditionalField>;
  public allUsers: any;
  public queryStringConstructor: any;
  public queryStringClause: any;
  public sortByFieldName: string;
  public sortByOrder: string;
  // created date range
  createdRange = new FormGroup({
    createdDateStart: new FormControl(),
    createdDateEnd: new FormControl(),
  });
  // modified date range
  modifiedRange = new FormGroup({
    modifiedDateStart: new FormControl(),
    modifiedDateEnd: new FormControl(),
  });
  pageEvent: PageEvent;
  public uniqueStudyTypes: Array<PicklistValue>;
  public uniqueEndpoints: Array<PicklistValue>;
  public uniqueEndpointUnits: Array<PicklistValue>;
  public uniqueTestSystemTypes: Array<PicklistValue>;
  public uniqueTestpecies: Array<PicklistValue>;
  public uniqueExposureDesigns: Array<PicklistValue>;
  public uniqueEDMechanisms: Array<PicklistValue>;
  public uniqueTestGuidelines: Array<PicklistValue>;
  public uniqueAffectedED: Array<PicklistValue>;
  public All: string;
  private sessionId: string;

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private serverService: ServerService,
    private exceptionService: ExceptionService,
    private toastrService: ToastrService
  ) {
    this.route.paramMap.subscribe((params) => {
      this.sessionId = params.get('sessionId');
    });
    this.uniqueAdditionalFields = [];
    this.existingEndpoints = [];
    this.uniqueStudyTypes = [];
    this.uniqueEndpoints = [];
    this.uniqueEndpointUnits = [];
    this.uniqueTestSystemTypes = [];
    this.uniqueTestpecies = [];
    this.uniqueExposureDesigns = [];
    this.uniqueEDMechanisms = [];
    this.uniqueTestGuidelines = [];
    this.uniqueAffectedED = [];
    this.sortByFieldName = 'id';
    this.sortByOrder = 'DESC';
    this.allUsers = [];
    this.All = 'All';
    this.queryStringConstructor = [];
    this.queryStringClause = '';
    this.dataSource = new MatTableDataSource();
    this.pageSize = 10;
    this.pageSkip = 0;
    this.dataSourceLength = 0;
    this.category__c = '';
    this.substance_type__c = '';
    this.related_substance__c = '';
    this.study_type__c = '';
    this.endpoint__c = '';
    this.endpoint_unit__c = '';
    this.operator__c = '';
    this.value_1__c = null;
    this.value_2__c = null;
    this.created_by__v = '';
    this.modified_by__v = '';
    this.test_system_type__c = '';
    this.test_species__c = '';
    this.exposure_duration__c = null;
    this.exposure_duration_unit__c = '';
    this.exposure_design__c = '';
    this.ed_relevant__c = '';
    this.trigger_ed__c = '';
    this.ed_modality__c = '';
    this.ed_mechanisms__c = '';
    this.test_guideline__c = '';
    this.used_in_risk_assessment__c = '';
    this.endpoint_analytically_based_on__c = '';
    this.t_trigger__c = '';
    this.affected_ed__c = ''; // multi-value
    this.trr__c = '';
    this.normalization__c = '';
    this.b_trigger__c = '';
    this.substrate__c = '';
    this.recovery_time__c = null;
    this.recovery_time_unit__c = '';
    this.peat_organic_matter__c = null;
    this.comment__c = '';
    this.basf_docid__c = null;
    this.selectedDocumentId = null;
    this.created_date_start = null;
    this.created_date_end = null;
    this.modified_date_start = null;
    this.modified_date_end = null;

    /// for filters
    this.allFields = [
      'Category',
      'Related Substance',
      'Substance Type',
      'BASF DocID',
      'Created Date Range',
      'Modified Date Range',
      'Source',
      'Created By',
      'Last Modified By',
      'Study Type',
      'Endpoint',
      'Endpoint Unit',
      'Operator',
      'Value 1',
      'Value 2',
      'Test System Type',
      'Test Species',
      'Exposure Duration',
      'Exposure Duration Unit',
      'Exposure Design',
      'ED Relevant',
      'Trigger ED',
      'ED Mechanisms',
      'Test Guideline',
      'Used in Risk Assessment',
      'Endpoint Analytically Based On',
      'T Trigger',
      'Affected ED - related parameter',
      'TRR',
      'Normalization',
      'B Trigger',
      'Substrate',
      'Recovery Time',
      'Recovery Time Unit',
      '% peat (organic matter)',
    ];

    this.filteredFields = this.fieldCtrl.valueChanges.pipe(
      startWith(null),
      map((field: string | null) =>
        field ? this._filter(field) : this.allFields.slice()
      )
    );
  }

  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    // Add field
    if ((value || '').trim()) {
      this.fields.push(value.trim());
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }

    this.fieldCtrl.setValue(null);
  }

  remove(field: string): void {
    // remove filter form
    document
      .getElementById('preparedFilterFields')
      .appendChild(document.getElementById(field));

    // remove chip
    const index = this.fields.indexOf(field);
    if (index >= 0) {
      this.fields.splice(index, 1);
    }

    // remove from array IF something was selected
    const queryIndex = this.queryStringConstructor
      .map(function(e) {
        return e.fieldLabel;
      })
      .indexOf(field);
    if (queryIndex >= 0) {
      this.queryStringConstructor.splice(queryIndex, 1);
    }

    // reset form selected value
    if (field == 'Category') {
      this.category__c = '';
    }
    if (field == 'Substance Type') {
      this.substance_type__c = '';
    }
    if (field == 'Related Substance') {
      this.related_substance__c = '';
    }
    if (field == 'Study Type') {
      this.study_type__c = '';
    }
    if (field == 'Endpoint') {
      this.endpoint__c = '';
    }
    if (field == 'Endpoint Unit') {
      this.endpoint_unit__c = '';
    }
    if (field == 'Operator') {
      this.operator__c = '';
    }
    if (field == 'Value 1') {
      this.value_1__c = null;
    }
    if (field == 'Value 2') {
      this.value_2__c = null;
    }
    if (field == 'Created By') {
      this.created_by__v = '';
    }
    if (field == 'Last Modified By') {
      this.modified_by__v = '';
    }
    if (field == 'Source') {
      this.selectedDocumentId = null;
    }

    // picklist fields
    if (field == 'Test System Type') {
      this.test_system_type__c = '';
    }
    if (field == 'Test Species') {
      this.test_species__c = '';
    }
    if (field == 'Exposure Duration Unit') {
      this.exposure_duration_unit__c = '';
    }
    if (field == 'Exposure Design') {
      this.exposure_design__c = '';
    }
    if (field == 'ED Relevant') {
      this.ed_relevant__c = '';
    }
    if (field == 'Trigger ED') {
      this.trigger_ed__c = '';
    }
    if (field == 'ED Mechanisms') {
      this.ed_mechanisms__c = '';
    }
    if (field == 'Test Guideline') {
      this.test_guideline__c = '';
    }
    if (field == 'Used in Risk Assessment') {
      this.used_in_risk_assessment__c = '';
    }
    if (field == 'Endpoint Analytically Based On') {
      this.endpoint_analytically_based_on__c = '';
    }
    if (field == 'T Trigger') {
      this.t_trigger__c = '';
    }
    if (field == 'Affected ED - related parameter') {
      this.affected_ed__c = '';
    }
    if (field == 'TRR') {
      this.trr__c = '';
    }
    if (field == 'Normalization') {
      this.normalization__c = '';
    }
    if (field == 'B Trigger') {
      this.b_trigger__c = '';
    }
    if (field == 'Substrate') {
      this.substrate__c = '';
    }
    if (field == 'Recovery Time Unit') {
      this.recovery_time_unit__c = '';
    }

    // number fields
    if (field == 'Exposure Duration') {
      this.exposure_duration__c = null;
    }
    if (field == 'Recovery Time') {
      this.recovery_time__c = null;
    }
    if (field == '% peat (organic matter)') {
      this.peat_organic_matter__c = null;
    }
    if (field == 'BASF DocID') {
      this.basf_docid__c = null;
    }

    if (field == 'Created Date Range') {
      this.createdRange.value.createdDateStart = null;
      this.createdRange.value.createdDateEnd = null;
      this.created_date_start = null;
      this.created_date_end = null;
    }
    if (field == 'Modified Date Range') {
      this.modifiedRange.value.modifiedDateStart = null;
      this.modifiedRange.value.modifiedDateEnd = null;
      this.modified_date_start = null;
      this.modified_date_end = null;
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.fields.push(event.option.viewValue);
    this.fieldInput.nativeElement.value = '';
    this.fieldCtrl.setValue(null);

    document
      .getElementById('filterFields')
      .appendChild(document.getElementById(event.option.viewValue));
  }

  ngOnInit(): void {
    this.retrieveAllUsers();
    this.queryStringClause = 'work_area__c = ' + '\'' + this.workAreaId + '\'';

    this.makeUniqueStudyTypes();
    this.makeUniqueEndpoints();
    this.makeUniqueEndpointUnits();
    this.makeUniqueTestSystemTypes();
    this.makeUniqueTestSpecies();
    this.makeUniqueExposureDesignes();
    this.makeUniqueEDMechanisms();
    this.makeUniqueTestGuidelines();
    this.makeUniqueAffectedED();

    this.makeUniqueAdditionalFields();
  }

  ngOnChanges(): any {
    /*
    this.makeUniqueStudyTypes();
    this.makeUniqueEndpoints();
    this.makeUniqueEndpointUnits();
    this.makeUniqueTestSystemTypes();
    this.makeUniqueTestSpecies();
    this.makeUniqueExposureDesignes();
    this.makeUniqueEDMechanisms();
    this.makeUniqueTestGuidelines();
    this.makeUniqueAffectedED();

    this.makeUniqueAdditionalFields();


     */
  }

  makeUniqueAdditionalFields(): any {
    // remove duplicates
    const sourceArray = this.receivedAllAdditionalFields;
    const resultArray = this.uniqueAdditionalFields;

    const map = new Map();
    for (const item of sourceArray) {
      if (!map.has(item.saveTo)) {
        map.set(item.saveTo, true); // set any value to Map
        resultArray.push({
          categoryId: item.categoryId,
          label: item.label,
          name: item.name,
          type: item.type,
          picklist: item.picklist,
          saveTo: item.saveTo,
          group: item.group,
          hidden: item.hidden,
          sortNumber: item.sortNumber,
        });
      }
    }
  }

  makeUniqueStudyTypes(): any {
    const source = this.receivedAllStudyTypes;
    const result = this.uniqueStudyTypes;

    const map = new Map();
    for (const item of source) {
      if (!map.has(item.name)) {
        map.set(item.name, true);
        result.push({
          name__v: '',
          id: '',
          label: item.label,
          name: item.name,
          picklist: item.picklist,
        });
      }
    }
  }

  makeUniqueEndpoints(): any {
    const source = this.receivedAllEndpoints;
    const result = this.uniqueEndpoints;

    const map = new Map();
    for (const item of source) {
      if (!map.has(item.name)) {
        map.set(item.name, true);
        result.push({
          name__v: '',
          id: '',
          label: item.label,
          name: item.name,
          picklist: item.picklist,
        });
      }
    }
  }

  makeUniqueEndpointUnits(): any {
    const source = this.receivedAllEndpointUnits;
    const result = this.uniqueEndpointUnits;

    const map = new Map();
    for (const item of source) {
      if (!map.has(item.name)) {
        map.set(item.name, true);
        result.push({
          name__v: '',
          id: '',
          label: item.label,
          name: item.name,
          picklist: item.picklist,
        });
      }
    }
  }

  makeUniqueTestSystemTypes(): any {
    const source = this.receivedAllTestSystemTypes;
    const result = this.uniqueTestSystemTypes;

    const map = new Map();
    for (const item of source) {
      if (!map.has(item.name)) {
        map.set(item.name, true);
        result.push({
          name__v: '',
          id: '',
          label: item.label,
          name: item.name,
          picklist: item.picklist,
        });
      }
    }
  }

  makeUniqueTestSpecies(): any {
    const source = this.receivedAllTestSpecies;
    const result = this.uniqueTestpecies;

    const map = new Map();
    for (const item of source) {
      if (!map.has(item.name)) {
        map.set(item.name, true);
        result.push({
          name__v: '',
          id: '',
          label: item.label,
          name: item.name,
          picklist: item.picklist,
        });
      }
    }
  }

  makeUniqueExposureDesignes(): any {
    const source = this.receivedAdditionalFieldValues.filter(
      (d) => d.saveTo === 'exposure_design__c'
    );
    const result = this.uniqueExposureDesigns;

    const map = new Map();
    for (const item of source) {
      if (!map.has(item.name)) {
        map.set(item.name, true);
        result.push({
          name__v: '',
          id: '',
          label: item.label,
          name: item.name,
          picklist: item.picklist,
        });
      }
    }
  }

  makeUniqueEDMechanisms(): any {
    const source = this.receivedAdditionalFieldValues.filter(
      (d) => d.saveTo === 'ed_mechanisms__c'
    );

    const result = this.uniqueEDMechanisms;

    const map = new Map();
    for (const item of source) {
      if (!map.has(item.name)) {
        map.set(item.name, true);
        result.push({
          name__v: '',
          id: '',
          label: item.label,
          name: item.name,
          picklist: item.picklist,
        });
      }
    }
  }

  makeUniqueTestGuidelines(): any {
    const source = this.allTestGuidelines;
    const result = this.uniqueTestGuidelines;

    const map = new Map();
    for (const item of source) {
      if (!map.has(item.name)) {
        map.set(item.name, true);
        result.push({
          name__v: '',
          id: '',
          label: item.label,
          name: item.name,
          picklist: item.picklist,
        });
      }
    }
  }

  makeUniqueAffectedED(): any {
    const source = this.receivedAdditionalFieldValues.filter(
      (d) => d.saveTo === 'affected_ed__c'
    );
    const result = this.uniqueAffectedED;

    const map = new Map();
    for (const item of source) {
      if (!map.has(item.name)) {
        map.set(item.name, true);
        result.push({
          name__v: '',
          id: '',
          label: item.label,
          name: item.name,
          picklist: item.picklist,
        });
      }
    }
  }

  getEndpointsWithFilters(LIMIT, OFFSET) {
    this.loaderHidden = false;
    this.searchButtonHidden = true;
    this.serverService.isLoading();

    let createdDateStart = JSON.stringify(
      this.createdRange.value.createdDateStart
    );
    createdDateStart = createdDateStart.replace('"', '');
    createdDateStart = createdDateStart.replace('"', '');
    let createdDateEnd = JSON.stringify(this.createdRange.value.createdDateEnd);
    createdDateEnd = createdDateEnd.replace('"', '');
    createdDateEnd = createdDateEnd.replace('"', '');

    let modifiedDateStart = JSON.stringify(
      this.modifiedRange.value.modifiedDateStart
    );
    modifiedDateStart = modifiedDateStart.replace('"', '');
    modifiedDateStart = modifiedDateStart.replace('"', '');
    let modifiedDateEnd = JSON.stringify(
      this.modifiedRange.value.modifiedDateEnd
    );
    modifiedDateEnd = modifiedDateEnd.replace('"', '');
    modifiedDateEnd = modifiedDateEnd.replace('"', '');

    // reset condition clauses
    this.queryStringClause =
      'work_area__c = ' +
      '\'' +
      this.workAreaId +
      '\' AND substance_name__c = ' +
      '\'' +
      this.substance.id +
      '\'';

    // unpack conditions array and construct query
    this.queryStringConstructor.forEach((condition: any) => {
      const singleCondition =
        ' AND ' +
        condition.fieldName +
        ' = ' +
        '\'' +
        condition.fieldValue +
        '\'';
      this.queryStringClause = this.queryStringClause + singleCondition;
    });

    if (createdDateStart !== 'null' && createdDateEnd !== 'null') {
      this.queryStringClause =
        this.queryStringClause +
        ' AND created_date__v BETWEEN \'' +
        createdDateStart +
        '\'' +
        ' AND \'' +
        createdDateEnd +
        '\'';
    }

    if (modifiedDateStart !== 'null' && modifiedDateEnd !== 'null') {
      this.queryStringClause =
        this.queryStringClause +
        ' AND modified_date__v BETWEEN \'' +
        modifiedDateStart +
        '\'' +
        ' AND \'' +
        modifiedDateEnd +
        '\'';
    }

    this.queryStringClause =
      this.queryStringClause +
      ' ORDER BY ' +
      this.sortByFieldName +
      ' ' +
      this.sortByOrder +
      ' LIMIT ' +
      LIMIT +
      ' OFFSET ' +
      OFFSET;
    this.subscriptions = this.serverService
      .filterEndpointRecords(
        this.sessionId,
        this.queryStringClause,
        this.workAreaName
      )
      .subscribe(
        (response) => {
          // reset current list
          this.existingEndpoints = [];
          if (response.data && response.data.length > 0) {
            response.data.forEach((it) => this.existingEndpoints.push(it));
            this.dataSourceLength = response.responseDetails.total;
          }
        },
        (error: HttpErrorResponse) => {
          this.exceptionService.handleError(error);
          this.toastrService.error('Unable to get Endpoints.', 'Error');
        },
        () => {
          this.serverService.isNotLoading();
          this.loaderHidden = true;
          this.searchButtonHidden = false;
        }
      );
  }

  retrieveAllUsers(): any {
    this.subscriptions = this.serverService
      .getAllUsers(this.sessionId)
      .subscribe(
        (response) => {
          if (response.responseStatus == 'SUCCESS') {
            response.data.forEach((user: any) => {
              const entry = {
                id: user.id,
                name: user.user_first_name__v + ' ' + user.user_last_name__v,
              };
              this.allUsers.push(entry);

              this.filteredCreateUsers = this.searchUserCreateControl.valueChanges.pipe(
                startWith(''),
                map((val) => this.filter(val))
              );

              this.filteredModifiedUsers = this.searchUserModifyControl.valueChanges.pipe(
                startWith(''),
                map((val) => this.filter(val))
              );
            });
          }
        },
        (error: HttpErrorResponse) => {
          this.exceptionService.handleError(error);
          this.toastrService.error(`Unable to get users.`, 'Error');
        }
      );
  }

  /**
   * used for document search (filtering based on user input)
   * @param val = user text field input
   */
  filter(val) {
    if (this.allUsers) {
      return this.allUsers.filter((option) =>
        option.name.toLowerCase().includes(val)
      );
    }
  }

  picklistValueToArray(name, label, value) {
    const fieldValuePair = {
      fieldName: name,
      fieldLabel: label,
      fieldValue: value,
    };

    // remove from array if something was selected, we need to replace old value with new
    const queryIndex = this.queryStringConstructor
      .map(function(e) {
        return e.fieldLabel;
      })
      .indexOf(label);

    if (queryIndex >= 0) {
      this.queryStringConstructor.splice(queryIndex, 1);
    }

    // add selected condition to array
    if (value !== '') {
      this.queryStringConstructor.push(fieldValuePair);
    }
  }

  textFieldValueToArray(name, label) {
    // @ts-ignore
    const value = document.getElementById(name).value;
    this.picklistValueToArray(name, label, value);
  }

  defineSortOrder(): any {
    if (
      this.sortByFieldName === 'id' ||
      this.sortByFieldName === 'related_substance__c'
    ) {
      this.sortByOrder = 'DESC';
    } else {
      this.sortByOrder = 'ASC';
    }
    this.getEndpointsWithFilters(this.pageSize, 0);
  }

  filterAdditionalFieldValues(
    allAdditionalFieldValues: Array<EcotoxAdditionalField>,
    filterWord: string
  ): Array<EcotoxAdditionalField> {
    return allAdditionalFieldValues.filter(
      (item) => item.picklist === filterWord
    );
  }

  destroyEndpoints(): any {
    this.existingEndpoints = [];
    // reset filter values (UI)
  }

  onPaginateChange($event: PageEvent) {
    let page = $event.pageIndex;
    const size = $event.pageSize;
    this.pageSize = $event.pageSize;
    const skip = page * size;
    this.pageSkip = skip;

    this.getEndpointsWithFilters(size, skip);
  }

  reduceCount(): void {
    this.dataSourceLength = this.dataSourceLength - 1;
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.allFields.filter(
      (field) => field.toLowerCase().indexOf(filterValue) === 0
    );
  }
}
