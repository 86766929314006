import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AbstractControl, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ToastrService} from 'ngx-toastr';
import {ExceptionService} from 'src/app/core/exception.service';
import {ActivatedRoute} from '@angular/router';
import {ServerService} from 'src/app/core/server.service';
import {PicklistValue} from 'src/app/endpoint/models/picklist-value';
import {ToxAdditionalField} from '../../../models/tox-additional-field';
import {SubstanceItem} from '../../../models/substance-item';
import {DocumentMenuItem} from '../../../models/document-menu-item';
import {ToxEndpointTableItem} from '../../../models/tox-endpoint-table-item';

let me: StotComponent;

@Component({
  selector: 'app-stot',
  templateUrl: './stot.component.html',
  styleUrls: ['../../../record_style.css']
})
export class StotComponent implements OnInit {
  @Input() singleEndpointRecord: ToxEndpointTableItem;
  @Input() category: PicklistValue;
  @Input() allStudyTypes: Array<PicklistValue>;
  @Input() allTestSystemTypes: Array<PicklistValue>;
  @Input() allRouteOfAdministration: Array<PicklistValue>;
  @Input() allTestSystemNames: Array<PicklistValue>;
  @Input() allEndpoints: Array<PicklistValue>;
  @Input() allEndpointUnits: Array<PicklistValue>;
  @Input() allOperators: Array<PicklistValue>;
  @Input() allAdditionalFields: Array<ToxAdditionalField>;
  @Input() allAdditionalFieldValues: Array<PicklistValue>;
  @Input() substance: SubstanceItem;
  @Input() substanceTypes: Array<PicklistValue>;
  @Input() relatedSubstances: any;
  @Input() documents: Array<DocumentMenuItem>;
  @Input() purpose: string;

  @Output() removeInput: EventEmitter<ToxEndpointTableItem> = new EventEmitter();
  @Output() copyInput: EventEmitter<ToxEndpointTableItem> = new EventEmitter();
  @Output() cancelEdit: EventEmitter<ToxEndpointTableItem> = new EventEmitter();
  @Output() saveChanges: EventEmitter<ToxEndpointTableItem> = new EventEmitter();

  subscriptions: any;
  selectFormGroup: FormGroup;
  configureFormGroup: FormGroup;
  public additionalFields: Array<ToxAdditionalField>;
  public additionalFieldValues: Map<string, any>;
  public studyTypes: Array<PicklistValue>;
  public testSystemTypes: Array<PicklistValue>;
  public testSystemNames: Array<PicklistValue>;
  public endpoints: Array<PicklistValue>;
  public endpointUnits: Array<PicklistValue>;
  public operators: Array<PicklistValue>;
  public isEndpointUnitDisabled: boolean;
  public filteredRelatedSubstances: any;
  public hiddenAdditionalFields: Array<ToxAdditionalField>;
  public isValue1Disabled: boolean;
  public isValue2Disabled: boolean;
  public ROAColor: string;
  public routeOfAdministration: Array<PicklistValue>;
  private sessionId: string;
  public showCopyOptions: boolean;
  public showEditOptions: boolean;
  public errorOnValue2: Boolean;

  constructor(
    private route: ActivatedRoute,
    private exceptionService: ExceptionService,
    private serverService: ServerService,
    private toastrService: ToastrService,
    private formBuilder: FormBuilder
  ) {
    me = this;
    this.subscriptions = {};
    this.route.paramMap.subscribe((params) => {
      this.sessionId = params.get('sessionId');
    });
    this.selectFormGroup = this.formBuilder.group({
      substanceType: [],
      relatedSubstance: [{value: '', disabled: true}],
      studyType: [{value: '', disabled: false}, Validators.required],
      test_system_type__c: [{value: '', disabled: false}],
      route_of_administration__c: [{value: '', disabled: false}],
      test_system_name__c: [{value: '', disabled: false}],
      endpoint: [{value: '', disabled: false}, Validators.required],
      endpointUnit: [{value: '', disabled: false}, Validators.required],
      operator: ['', Validators.required],
      value1: [{value: '', disabled: false}],
      value2: [{value: '', disabled: false}],
      value3: [{value: '', disabled: false}],
      // additional fields
      target_organ__c: [{value: '', disabled: false}],
      t_trigger__c: [{value: '', disabled: false}],
      // default
      selectedDocument: [{value: '', disabled: false}],
    });

    this.testSystemTypes = [];
    this.testSystemNames = [];
    this.endpoints = [];
    this.endpointUnits = [];
    this.additionalFields = [];
    this.additionalFieldValues = new Map();
    this.isEndpointUnitDisabled = false;
    this.hiddenAdditionalFields = [];
    this.filteredRelatedSubstances = [];
    this.isValue1Disabled = true;
    this.isValue2Disabled = true;
    this.operators = [];
    this.ROAColor = '';
    this.errorOnValue2 = false;
  }

  get relatedSubstanceControl(): AbstractControl {
    return this.selectFormGroup.get('relatedSubstance');
  }

  get value1Control(): AbstractControl {
    return this.selectFormGroup.get('value1');
  }
  get value2Control(): AbstractControl {
    return this.selectFormGroup.get('value2');
  }
  get sourceControl(): AbstractControl {
    return this.selectFormGroup.get('selectedDocument');
  }

  ngOnInit(): void {

    if (this.purpose === 'editOnExisting') {
      this.showCopyOptions = false;
      this.showEditOptions = true;
    }
    else {
      this.showCopyOptions = true;
      this.showEditOptions = false;
    }

    const filteredOperators = [
    'a1595852199787__c',
    'a1595852189563__c',
    'a1595852194618__c',
    'a1595852268945__c',
    'a1595852266201__c',
    'between__c',
    'a1595852359285__c'
    ];

    filteredOperators.forEach(operator => {
        const singleOperator = this.allOperators.filter(
          (entry) => entry.name === operator
        );
        this.operators = this.operators.concat(singleOperator);
      }
    );

    if (this.singleEndpointRecord.study_type_picklist_name__c) {
      // copy record

      switch (this.singleEndpointRecord.operator__c) {
        case 'between__c':
          this.isValue1Disabled = false;
          this.isValue2Disabled = false;
          this.selectFormGroup.get('value1').enable();
          this.selectFormGroup.get('value2').enable();
          break;
        case '':
          this.isValue1Disabled = true;
          this.isValue2Disabled = true;
          this.selectFormGroup.get('value1').disable();
          this.selectFormGroup.get('value2').disable();
          this.value1Control.setValue(null);
          this.value2Control.setValue(null);
          this.singleEndpointRecord.value_1__c = null;
          this.singleEndpointRecord.value_2__c = null;
          break;
        default:
          this.isValue1Disabled = false;
          this.isValue2Disabled = true;
          this.selectFormGroup.get('value1').enable();
          this.selectFormGroup.get('value2').disable();
          this.value2Control.setValue(null);
          this.singleEndpointRecord.value_2__c = null;
          break;
      }

      const preAllAdditionalFields = this.allAdditionalFields.filter(
        (entry) =>
          entry.group === this.singleEndpointRecord.study_type_picklist_name__c
      );
      this.additionalFields = preAllAdditionalFields.sort((a, b) => a.sortNumber - b.sortNumber);

      // parent (or none) cannot have Related Substance
      if (this.singleEndpointRecord.substance_type__c === 'parent__c' || this.singleEndpointRecord.substance_type__c === '') {
        this.selectFormGroup.get('relatedSubstance').disable();
      } else {
        // filter Related Substances by the selected Substance Type
        this.filteredRelatedSubstances = this.relatedSubstances.filter(
          (f) => f.substance_type__c[0] === this.singleEndpointRecord.substance_type__c
        );
        this.selectFormGroup.get('relatedSubstance').enable();
      }

      this.filterEndpoints();
      this.filterEndpointUnits();
      this.setFormFields();
    }
    else {
      // new record
      this.selectFormGroup.get('substanceType').setValue('parent__c');
    }

    this.studyTypes = this.allStudyTypes;
    this.testSystemTypes = this.allTestSystemTypes;
    this.testSystemNames = this.allTestSystemNames;
    this.sourceControl.setValue(this.singleEndpointRecord.document_unbound__c);
  }

  getSelectedOptionPicklist(event: any): string {
    return event.target.options[event.target.options.selectedIndex].title;
  }

  onSelectSubstanceType(event: any): any {
    // update object
    this.singleEndpointRecord.substance_type__c = event.target.value;

    // clear Related Substance value because the group of Related Substances has changed
    this.singleEndpointRecord.related_substance__c = '';
    // also clear Related Substance form field
    this.relatedSubstanceControl.setValue('');

    // parent (or none) cannot have Related Substance
    if (event.target.value === 'parent__c' || event.target.value === '') {
      this.selectFormGroup.get('relatedSubstance').disable();
    } else {
      // filter Related Substances by the selected Substance Type
      this.filteredRelatedSubstances = this.relatedSubstances.filter(
        (f) => f.substance_type__c[0] === event.target.value
      );
      this.selectFormGroup.get('relatedSubstance').enable();
    }
  }

  onSelectStudyType(event: any): any {
    this.singleEndpointRecord.study_type__c = event.target.value;
    if (event.target.value !== '') {
      this.singleEndpointRecord.study_type_picklist_name__c = this.getSelectedOptionPicklist(
        event
      );

      const preAdditionalFields = this.allAdditionalFields.filter(
        (entry) =>
          entry.group === this.singleEndpointRecord.study_type_picklist_name__c
      );
      this.additionalFields = preAdditionalFields.sort((a, b) => a.sortNumber - b.sortNumber);
    }
    else {
      this.singleEndpointRecord.study_type_picklist_name__c = '';
    }
  }


  onSelectRoute(event: any): any {
    this.singleEndpointRecord.route_of_administration__c = event.target.value;

    this.filterEndpoints();
    this.filterEndpointUnits();

    if (this.singleEndpointRecord.route_of_administration__c === 'inhalation__c') {
       // selected yellow
      if (this.ROAColor !== 'yellow'){
        // changing group => reset endpoint value and form
        this.resetEndpointAndEndpointUnit();
      }
      this.ROAColor = 'yellow';
    }
    else {
      // selected orange
      if (this.ROAColor !== 'orange'){
        // if currently yellow: changing group => reset endpoint value and form
        this.resetEndpointAndEndpointUnit();
      }
    }
    this.ROAColor = 'orange';
  }

  filterEndpoints(): any {
    // Endpoints are being filtered base on "Route of Administration" selection
    this.endpoints = []; // reset because of concat function
    if (this.singleEndpointRecord.route_of_administration__c === 'inhalation__c') {
      // YELLOW group
      const dependedEndpoints = ['loael_inhalation__c'];
      dependedEndpoints.forEach(endpoint => {
          const singleEndpoint = this.allEndpoints.filter(
            (entry) => entry.name === endpoint
          );
          this.endpoints = this.endpoints.concat(singleEndpoint);
        }
      );
      return this.endpoints;
    }
    else {
       // ORANGE group
      const dependedEndpoints = ['loael_oral__c', 'loael_dermal__c'];
      dependedEndpoints.forEach(endpoint => {
          const singleEndpoint = this.allEndpoints.filter(
            (entry) => entry.name === endpoint
          );
          this.endpoints = this.endpoints.concat(singleEndpoint);
        }
      );
      return this.endpoints;
    }
  }

  filterEndpointUnits(): any {
    // Endpoints Units are being filtered base on "Route of Administration" selection
    this.endpointUnits = []; // reset because of concat function
    if (this.singleEndpointRecord.route_of_administration__c === 'inhalation__c') {
      // YELLOW group
      const dependedEndpointUnits = ['mgl__c', 'mgm3__c'];
      dependedEndpointUnits.forEach(endpointUnit => {
          const singleEndpointUnit = this.allEndpointUnits.filter(
            (entry) => entry.name === endpointUnit
          );
          this.endpointUnits = this.endpointUnits.concat(singleEndpointUnit);
        }
      );
      return this.endpointUnits;
    }
    else {
      // ORANGE group
      const dependedEndpointUnits = ['mgkg_bwd__c'];
      dependedEndpointUnits.forEach(endpointUnit => {
          const singleEndpointUnit = this.allEndpointUnits.filter(
            (entry) => entry.name === endpointUnit
          );
          this.endpointUnits = this.endpointUnits.concat(singleEndpointUnit);
        }
      );
      return this.endpointUnits;
    }
  }

  filterAdditionalFieldValues(allAdditionalFieldValues: Array<PicklistValue>, filterWord: string): Array<PicklistValue> {
      return allAdditionalFieldValues.filter(
        (item) => item.picklist === filterWord
      );
  }

  removeEndpoint(singleEndpointRecord): any {
    this.removeInput.emit(singleEndpointRecord);
  }

   updateValue1(newValue): any {
    if (newValue.value < 0) {
      this.selectFormGroup.get('value1').setValue('');
    }
    else {
      this.singleEndpointRecord.value_1__c = newValue.value;
    }

    // checking if value 2 is bigger than value 1
    const value1 = parseInt(String(newValue.value));
    const value2 = parseInt(String(this.singleEndpointRecord.value_2__c));

    if (value2 <= value1 || isNaN(value1)) {
      this.errorOnValue2 = true;
      console.log('error: ' + value1 + ', ' + value2);
    }
    else {
      this.errorOnValue2 = false;
      console.log('clear ' + value1 + ', ' + value2);
    }
  }

  updateValue2(newValue): any {
    if (newValue.value < 0) {
      this.selectFormGroup.get('value2').setValue('');
    } else {
      this.singleEndpointRecord.value_2__c = newValue.value;
    }

     // checking if value 2 is bigger than value 1
    const value1 = parseInt(String(this.singleEndpointRecord.value_1__c));
    const value2 = parseInt(String(newValue.value));

    if (value2 <= value1 || isNaN(value2)) {
      this.errorOnValue2 = true;
      console.log('error: ' + value1 + ', ' + value2);
    }
    else {
      this.errorOnValue2 = false;
      console.log('clear ' + value1 + ', ' + value2);
    }
  }

  updateValue3(newValue): any {
    this.singleEndpointRecord.value_3__c = newValue.value;
  }

  updateFieldValue(newValue): any {
    const fieldName = newValue.title;
    this.singleEndpointRecord[fieldName] = newValue.value;
  }

  updateNumberFieldValue(newValue): any {
    const fieldName = newValue.title;

    if (fieldName === 'temp_c__c') {
      this.singleEndpointRecord[fieldName] = newValue.value;
    } else {
      if (newValue.value < 0) {
        this.singleEndpointRecord[fieldName] = '';
        this.selectFormGroup.get(fieldName).setValue('');
      } else {
        this.singleEndpointRecord[fieldName] = newValue.value;
      }
    }
  }

  copyEndpoint(singleEndpointRecord: ToxEndpointTableItem): void {
    this.copyInput.emit(singleEndpointRecord);
  }

  updateMultiPicklistFieldValue(newValue, fieldName): void {
    this.singleEndpointRecord[fieldName] = newValue.value.toString();
  }

  setFormFields(): void {
    this.selectFormGroup.get('substanceType').setValue(this.singleEndpointRecord.substance_type__c);
    this.selectFormGroup.get('relatedSubstance').setValue(this.singleEndpointRecord.related_substance__c);
    this.selectFormGroup.get('studyType').setValue(this.singleEndpointRecord.study_type__c);
    this.selectFormGroup.get('test_system_type__c').setValue(this.singleEndpointRecord.test_system_type__c);
    this.selectFormGroup.get('test_system_name__c').setValue(this.singleEndpointRecord.test_system_name__c);
    this.selectFormGroup.get('endpoint').setValue(this.singleEndpointRecord.endpoint__c);
    this.selectFormGroup.get('endpointUnit').setValue(this.singleEndpointRecord.endpoint_unit__c);
    this.selectFormGroup.get('operator').setValue(this.singleEndpointRecord.operator__c);
    this.selectFormGroup.get('value1').setValue(this.singleEndpointRecord.value_1__c);
    this.selectFormGroup.get('value2').setValue(this.singleEndpointRecord.value_2__c);
    // additional fields
    if (this.singleEndpointRecord.target_organ__c) {
      this.selectFormGroup.get('target_organ__c').setValue(this.singleEndpointRecord.target_organ__c.split(','));
    }
    this.selectFormGroup.get('route_of_administration__c').setValue(this.singleEndpointRecord.route_of_administration__c);
    this.selectFormGroup.get('t_trigger__c').setValue(this.singleEndpointRecord.t_trigger__c);
    // default
    this.selectFormGroup.get('selectedDocument').setValue(this.singleEndpointRecord.document_unbound__c);
  }

  onSelectOperator(event: any): any {
    if (event.target !== undefined) {
      this.singleEndpointRecord.operator__c = event.target.value;
    }

    switch (this.singleEndpointRecord.operator__c) {
      case 'between__c':
        this.isValue1Disabled = false;
        this.isValue2Disabled = false;
        this.selectFormGroup.get('value1').enable();
        this.selectFormGroup.get('value2').enable();
        break;
      case '':
        this.isValue1Disabled = true;
        this.isValue2Disabled = true;
        this.selectFormGroup.get('value1').disable();
        this.selectFormGroup.get('value2').disable();
        this.value1Control.setValue(null);
        this.value2Control.setValue(null);
        this.singleEndpointRecord.value_1__c = null;
        this.singleEndpointRecord.value_2__c = null;
        break;
      default:
        this.isValue1Disabled = false;
        this.isValue2Disabled = true;
        this.selectFormGroup.get('value1').enable();
        this.selectFormGroup.get('value2').disable();
        this.value2Control.setValue(null);
        this.singleEndpointRecord.value_2__c = null;
        break;
    }
  }

  resetEndpointAndEndpointUnit(): any {
    this.singleEndpointRecord.endpoint__c = '';
    this.selectFormGroup.get('endpoint').setValue('');
    this.singleEndpointRecord.endpoint_unit__c = '';
    this.selectFormGroup.get('endpointUnit').setValue('');
  }

  cancelEditing(endpointRecord): void {
    this.cancelEdit.emit(endpointRecord);
  }

  saveEndpointChanges(endpointRecord): void {
    this.saveChanges.emit(endpointRecord);
  }

}

