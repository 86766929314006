import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AbstractControl, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ToastrService} from 'ngx-toastr';
import {ExceptionService} from 'src/app/core/exception.service';
import {ActivatedRoute} from '@angular/router';
import {ServerService} from 'src/app/core/server.service';
import {PicklistValue} from 'src/app/endpoint/models/picklist-value';
import {ToxAdditionalField} from '../../../models/tox-additional-field';
import {DocumentMenuItem} from '../../../models/document-menu-item';
import {ToxEndpointTableItem} from '../../../models/tox-endpoint-table-item';

let me: AcuteToxicityComponent;

@Component({
  selector: 'app-acute-toxicity',
  templateUrl: './acute-toxicity.component.html',
  styleUrls: ['../../../record_style.css']
})
export class AcuteToxicityComponent implements OnInit {
  @Input() singleEndpointRecord: ToxEndpointTableItem;
  @Input() category: PicklistValue;
  @Input() allStudyTypes: Array<PicklistValue>;
  @Input() testSystemTypes: Array<PicklistValue>;
  @Input() allRouteOfAdministration: Array<PicklistValue>;
  @Input() allTestSystemNames: Array<PicklistValue>;
  @Input() allEndpoints: Array<PicklistValue>;
  @Input() allEndpointUnits: Array<PicklistValue>;
  @Input() allOperators: Array<PicklistValue>;
  @Input() allAdditionalFields: Array<ToxAdditionalField>;
  @Input() allAdditionalFieldValues: Array<PicklistValue>;
  @Input() substanceTypes: Array<PicklistValue>;
  @Input() relatedSubstances: any;
  @Input() documents: Array<DocumentMenuItem>;
  @Input() purpose: string;

  @Output() removeInput: EventEmitter<ToxEndpointTableItem> = new EventEmitter();
  @Output() copyInput: EventEmitter<ToxEndpointTableItem> = new EventEmitter();
  @Output() cancelEdit: EventEmitter<ToxEndpointTableItem> = new EventEmitter();
  @Output() saveChanges: EventEmitter<ToxEndpointTableItem> = new EventEmitter();

  subscriptions: any;
  selectFormGroup: FormGroup;
  configureFormGroup: FormGroup;
  public additionalFields: Array<ToxAdditionalField>;
  public additionalFieldValues: Map<string, any>;
  public studyTypes: Array<PicklistValue>;
  public testSystemNames: Array<PicklistValue>;
  public endpoints: Array<PicklistValue>;
  public endpointUnits: Array<PicklistValue>;
  public operators: Array<PicklistValue>;
  public values31: Array<any>;
  public isEndpointUnitDisabled: boolean;
  public filteredRelatedSubstances: any;
  public hiddenAdditionalFields: Array<ToxAdditionalField>;
  public isValue1Disabled: boolean;
  public isValue2Disabled: boolean;
  public isValue3Disabled: boolean;
  public isValue31Disabled: boolean;
  operatorHidden = true;
  public routeOfAdministration: Array<PicklistValue>;
  public studyTypeColor: string;
  private sessionId: string;
  public showCopyOptions: boolean;
  public showEditOptions: boolean;
  public errorOnValue2: Boolean;

  constructor(
    private route: ActivatedRoute,
    private exceptionService: ExceptionService,
    private serverService: ServerService,
    private toastrService: ToastrService,
    private formBuilder: FormBuilder
  ) {
    me = this;
    this.subscriptions = {};
    this.route.paramMap.subscribe((params) => {
      this.sessionId = params.get('sessionId');
    });
    this.selectFormGroup = this.formBuilder.group({
      substanceType: [],
      relatedSubstance: [{value: '', disabled: true}],
      studyType: [{value: '', disabled: false}, Validators.required],
      test_system_type__c: [{value: '', disabled: false}],
      route_of_administration__c: [{value: '', disabled: false}],
      test_system_name__c: [{value: '', disabled: false}],
      endpoint: [{value: '', disabled: false}, Validators.required],
      endpointUnit: [{value: '', disabled: false}, Validators.required],
      operator: ['', Validators.required],
      value1: [{value: '', disabled: false}],
      value2: [{value: '', disabled: false}],
      value3: [{value: '', disabled: false}],
      value31: [{value: '', disabled: false}],
      // additional fields
      test_method_skin_sensitization__c: [{value: '', disabled: false}],
      ec3__c: [{value: '', disabled: false}],
      ec15__c: [{value: '', disabled: false}],
      // default
      selectedDocument: [{value: '', disabled: false}],
    });
    this.endpoints = [];
    this.endpointUnits = [];
    this.testSystemNames = [];
    this.routeOfAdministration = [];
    this.operators = [];
    this.values31 = [];
    this.additionalFields = [];
    this.additionalFieldValues = new Map();
    this.isEndpointUnitDisabled = false;
    this.hiddenAdditionalFields = [];
    this.filteredRelatedSubstances = [];
    this.isValue1Disabled = true;
    this.isValue2Disabled = true;
    this.isValue3Disabled = true;
    this.isValue31Disabled = true;
    this.studyTypeColor = '';
    this.errorOnValue2 = false;
  }

  get relatedSubstanceControl(): AbstractControl {
    return this.selectFormGroup.get('relatedSubstance');
  }

  get endpointControl(): AbstractControl {
    return this.selectFormGroup.get('endpoint');
  }

  get value1Control(): AbstractControl {
    return this.selectFormGroup.get('value1');
  }

  get value2Control(): AbstractControl {
    return this.selectFormGroup.get('value2');
  }

  get value3Control(): AbstractControl {
    return this.selectFormGroup.get('value3');
  }

  get sourceControl(): AbstractControl {
    return this.selectFormGroup.get('selectedDocument');
  }

  ngOnInit(): void {

    if (this.purpose === 'editOnExisting') {
      this.showCopyOptions = false;
      this.showEditOptions = true;
    }
    else {
      this.showCopyOptions = true;
      this.showEditOptions = false;
    }

    this.sourceControl.setValue(this.singleEndpointRecord.document_unbound__c);
    this.studyTypes = this.allStudyTypes;
    this.endpoints = this.allEndpoints;
    this.endpointUnits = this.allEndpointUnits;

    const filteredOperators = [
      'a1595852199787__c',
      'a1595852189563__c',
      'a1595852194618__c',
      'a1595852268945__c',
      'a1595852266201__c',
      'between__c',
      'a1595852359285__c',
      'text__c'
    ];

    filteredOperators.forEach(operator => {
        const singleOperator = this.allOperators.filter(
          (entry) => entry.name === operator
        );
        this.operators = this.operators.concat(singleOperator);
      }
    );

    if (this.singleEndpointRecord.study_type_picklist_name__c) {
      // COPY record

      if (this.singleEndpointRecord.study_type__c === 'skin_irritation__c' || this.singleEndpointRecord.study_type__c === 'eye_irritation__c') {
        this.values31 = ['non-irritant / corrosive', 'irritant', 'corrosive'];
        this.operatorHidden = true;
        this.isValue1Disabled = true;
        this.isValue2Disabled = true;
        this.isValue3Disabled = true;
        this.isValue31Disabled = false;
        this.studyTypeColor = 'green';
      }
      else if (this.singleEndpointRecord.study_type__c === 'skin_sensitization__c' || this.singleEndpointRecord.study_type__c === 'phototoxicity__c')
      {
        // if one of these Study Type is selected and if operator is text, then 'selections' should appear instead of free text field
        this.values31 = ['positive', 'negative'];
        this.operatorHidden = false;

        if (this.singleEndpointRecord.operator__c === 'text__c'){
          this.isValue1Disabled = true;
          this.isValue2Disabled = true;
          this.isValue3Disabled = true; // free text
          this.isValue31Disabled = false; // selection
        }
        else if (this.singleEndpointRecord.operator__c === 'between__c') {
          this.isValue1Disabled = false;
          this.isValue2Disabled = false;
          this.isValue3Disabled = true;
          this.isValue31Disabled = true;
        }
        else if (this.singleEndpointRecord.operator__c === '') {
          this.isValue1Disabled = true;
          this.isValue2Disabled = true;
          this.isValue3Disabled = true;
          this.isValue31Disabled = true;
        }
        else {
          // num field
          this.isValue1Disabled = false;
          this.isValue2Disabled = true;
          this.isValue3Disabled = true;
          this.isValue31Disabled = true;
        }
        this.studyTypeColor = 'blueAndPurple';
      }
      else {
        // Study Type without special conditions
        this.values31 = [];
        this.operatorHidden = false;

        if (this.singleEndpointRecord.operator__c === 'text__c'){
          this.isValue1Disabled = true;
          this.isValue2Disabled = true;
          this.isValue3Disabled = false; // free text
          this.isValue31Disabled = true; // selection
        }
        else if (this.singleEndpointRecord.operator__c === 'between__c') {
          this.isValue1Disabled = false;
          this.isValue2Disabled = false;
          this.isValue3Disabled = true;
          this.isValue31Disabled = true;
        }
        else if (this.singleEndpointRecord.operator__c === '') {
          this.isValue1Disabled = true;
          this.isValue2Disabled = true;
          this.isValue3Disabled = true;
          this.isValue31Disabled = true;
        }
        else {
          // num field
          this.isValue1Disabled = false;
          this.isValue2Disabled = true;
          this.isValue3Disabled = true;
          this.isValue31Disabled = true;
        }
        this.studyTypeColor = 'clear';
      }

      // define additional fields
      if (this.singleEndpointRecord.study_type__c === 'skin_sensitization__c') {
        const preAdditionalFields = this.allAdditionalFields.filter(
          (entry) =>
            entry.group === this.singleEndpointRecord.study_type_picklist_name__c
        );
        this.additionalFields = preAdditionalFields.sort((a, b) => a.sortNumber - b.sortNumber);
      }

      // parent (or none) cannot have Related Substance
      if (this.singleEndpointRecord.substance_type__c === 'parent__c' || this.singleEndpointRecord.substance_type__c === '') {
        this.selectFormGroup.get('relatedSubstance').disable();
      }
      else {
        // filter Related Substances by the selected Substance Type
        this.filteredRelatedSubstances = this.relatedSubstances.filter(
          (f) => f.substance_type__c[0] === this.singleEndpointRecord.substance_type__c
        );
        this.selectFormGroup.get('relatedSubstance').enable();
      }
      this.filterTestSystemNames(this.singleEndpointRecord.test_system_type__c);
      this.filterRouteOfAdministrations(this.singleEndpointRecord.test_system_type__c);
      this.setFormFields();
    }
    else {
      this.selectFormGroup.get('substanceType').setValue('parent__c');
    }
  }

  getSelectedOptionPicklist(event: any): string {
    return event.target.options[event.target.options.selectedIndex].title;
  }

  onSelectSubstanceType(event: any): any {
    // update object
    this.singleEndpointRecord.substance_type__c = event.target.value;

    // clear Related Substance value because the group of Related Substances has changed
    this.singleEndpointRecord.related_substance__c = '';
    // also clear Related Substance form field
    this.relatedSubstanceControl.setValue('');

    // parent (or none) cannot have Related Substance
    if (event.target.value === 'parent__c' || event.target.value === '') {
      this.selectFormGroup.get('relatedSubstance').disable();
    } else {
      // filter Related Substances by the selected Substance Type
      this.filteredRelatedSubstances = this.relatedSubstances.filter(
        (f) => f.substance_type__c[0] === event.target.value
      );
      this.selectFormGroup.get('relatedSubstance').enable();
    }
  }

  onSelectStudyType(event: any): any {
    this.singleEndpointRecord.study_type__c = event.target.value;

    if (event.target.value !== '') {
      // Study Type value selected
      this.singleEndpointRecord.study_type_picklist_name__c = this.getSelectedOptionPicklist(
        event
      );

      if (event.target.value === 'skin_irritation__c' || event.target.value === 'eye_irritation__c') {
        if (this.studyTypeColor !== 'green')
        {
          // Operator: set value, hide
          this.singleEndpointRecord.operator__c = 'text__c';
          this.selectFormGroup.get('operator').setValue('text__c');
          this.operatorHidden = true;
          // values
          this.singleEndpointRecord.value_1__c = null;
          this.singleEndpointRecord.value_2__c = null;
          this.singleEndpointRecord.value_3__c = '';
          this.value1Control.setValue(null);
          this.value2Control.setValue(null);
          this.value3Control.setValue('');
          this.selectFormGroup.get('value31').setValue('');
          this.isValue1Disabled = true;
          this.isValue2Disabled = true;
          this.isValue3Disabled = true;
          // define Value 3 list content
          this.values31 = ['non-irritant / corrosive', 'irritant', 'corrosive'];
          // show Value 3 field
          this.isValue31Disabled = false;
          this.studyTypeColor = 'green';
        }
      }
      else if (event.target.value === 'skin_sensitization__c' || event.target.value === 'phototoxicity__c')
      {
        if (this.studyTypeColor !== 'blueAndPurple') {
          // Operator: set value
          this.singleEndpointRecord.operator__c = '';
          this.selectFormGroup.get('operator').setValue('');
          this.operatorHidden = false;
          // values
          this.singleEndpointRecord.value_1__c = null;
          this.singleEndpointRecord.value_2__c = null;
          this.singleEndpointRecord.value_3__c = '';
          this.value1Control.setValue(null);
          this.value2Control.setValue(null);
          this.value3Control.setValue('');
          this.selectFormGroup.get('value31').setValue('');
          this.isValue1Disabled = true;
          this.isValue2Disabled = true;
          this.isValue3Disabled = true;
          this.isValue31Disabled = true;
          // define Value 3 list content
          this.values31 = ['positive', 'negative'];
          this.studyTypeColor = 'blueAndPurple';
        }
      }
      else {
        if (this.studyTypeColor !== 'clear') {
          // Operator: set value
          this.singleEndpointRecord.operator__c = '';
          this.selectFormGroup.get('operator').setValue('');
          this.operatorHidden = false;
          // values
          this.singleEndpointRecord.value_1__c = null;
          this.singleEndpointRecord.value_2__c = null;
          this.singleEndpointRecord.value_3__c = '';
          this.value1Control.setValue(null);
          this.value2Control.setValue(null);
          this.value3Control.setValue('');
          this.selectFormGroup.get('value31').setValue('');
          this.isValue1Disabled = true;
          this.isValue2Disabled = true;
          this.isValue3Disabled = true;
          this.isValue31Disabled = true;
          // reset Value 3 list content
          this.values31 = [];
          this.studyTypeColor = 'clear';
        }
      }

      // define additional fields
      if (event.target.value === 'skin_sensitization__c') {
        const preAdditionalFields = this.allAdditionalFields.filter(
          (entry) =>
            entry.group === this.singleEndpointRecord.study_type_picklist_name__c
        );
        this.additionalFields = preAdditionalFields.sort((a, b) => a.sortNumber - b.sortNumber);
      }
      else {
        this.resetAdditionalFields();
        this.additionalFields = [];
      }
    }
    else {
      // Study Type cleared
      this.singleEndpointRecord.study_type_picklist_name__c = '';
      this.resetAdditionalFields();
      this.additionalFields = [];
      this.values31 = [];
      this.studyTypeColor = '';
      // values
      this.singleEndpointRecord.operator__c = '';
      this.singleEndpointRecord.value_1__c = null;
      this.singleEndpointRecord.value_2__c = null;
      this.singleEndpointRecord.value_3__c = '';
      this.selectFormGroup.get('operator').setValue('');
      this.value1Control.setValue(null);
      this.value2Control.setValue(null);
      this.value3Control.setValue('');
      this.selectFormGroup.get('value31').setValue('');
      this.operatorHidden = true;
      this.isValue1Disabled = true;
      this.isValue2Disabled = true;
      this.isValue3Disabled = true;
      this.isValue31Disabled = true;
    }
  }


  onSelectTestSystemType(event: any): any {
    this.singleEndpointRecord.test_system_type__c = event.target.value;

    if (event.target.value !== '') {
      // always different group because there are only 2 values
      this.singleEndpointRecord.test_system_name__c = '';
      this.singleEndpointRecord.route_of_administration__c = '';
      this.selectFormGroup.get('test_system_name__c').setValue('');
      this.selectFormGroup.get('route_of_administration__c').setValue('');
      this.testSystemNames = [];
      this.routeOfAdministration = [];

      this.filterTestSystemNames(event.target.value);
      this.filterRouteOfAdministrations(event.target.value);
    }
    else {
      this.singleEndpointRecord.test_system_name__c = '';
      this.singleEndpointRecord.route_of_administration__c = '';
      this.selectFormGroup.get('test_system_name__c').setValue('');
      this.selectFormGroup.get('route_of_administration__c').setValue('');
      this.testSystemNames = [];
      this.routeOfAdministration = [];
    }
  }

  filterTestSystemNames(testSystemType): any{
    if (testSystemType === 'acute_toxicity_in_vitro__c') {
      // filter Test System Name values
      const dependedTestSystemNames = ['hetcam__c', 'epiderm__c', 'bcop__c', 'bacteria__c', 'bacterial_cell__c', 'mammalian_cell__c', 'human_cell__c', 'other_cell_type_in_vitro__c'];
      dependedTestSystemNames.forEach(record => {
          const singleRecord = this.allTestSystemNames.filter(
            (entry) => entry.name === record
          );
          this.testSystemNames = this.testSystemNames.concat(singleRecord);
        }
      );
    }
    else if (testSystemType === 'acute_toxicity_in_vivo__c') {
      // filter Test System Name values

      const dependedTestSystemNames = ['rat__c', 'rabbit__c', 'mouse__c', 'dog__c', 'human__c', 'guinea_pig__c', 'other_species_in_vivo__c'];
      dependedTestSystemNames.forEach(record => {
          const singleRecord = this.allTestSystemNames.filter(
            (entry) => entry.name === record
          );
          this.testSystemNames = this.testSystemNames.concat(singleRecord);
        }
      );
    }
  }

  filterRouteOfAdministrations(testSystemType): any{
    if (testSystemType === 'acute_toxicity_in_vitro__c') {
      // filter Route of Administration values
      const dependedRouteOfAdministrations = ['cell_culture__c'];
      dependedRouteOfAdministrations.forEach(record => {
          const singleRecord = this.allRouteOfAdministration.filter(
            (entry) => entry.name === record
          );
          this.routeOfAdministration = this.routeOfAdministration.concat(singleRecord);
        }
      );
    }
    else if (testSystemType === 'acute_toxicity_in_vivo__c') {
      // filter Route of Administration values
      // eslint-disable-next-line max-len
      const dependedRouteOfAdministrations = ['oral_gavage__c', 'oral_diet__c', 'oral_drinking_water__c', 'oral_capsule__c', 'dermal__c', '4h_nose_only__c', 'whole_body_4h_or_24h__c'];
      dependedRouteOfAdministrations.forEach(record => {
          const singleRecord = this.allRouteOfAdministration.filter(
            (entry) => entry.name === record
          );
          this.routeOfAdministration = this.routeOfAdministration.concat(singleRecord);
        }
      );
    }
  }

  filterAdditionalFieldValues(allAdditionalFieldValues: Array<PicklistValue>, filterWord: string): Array<PicklistValue> {
      return allAdditionalFieldValues.filter(
        (item) => item.picklist === filterWord
      );
  }

  removeEndpoint(singleEndpointRecord): any {
    this.removeInput.emit(singleEndpointRecord);
  }

  updateValue1(newValue): any {
    if (newValue.value < 0) {
      this.selectFormGroup.get('value1').setValue('');
    }
    else {
      this.singleEndpointRecord.value_1__c = newValue.value;
    }

    // checking if value 2 is bigger than value 1
    const value1 = parseInt(String(newValue.value));
    const value2 = parseInt(String(this.singleEndpointRecord.value_2__c));

    if (value2 <= value1 || isNaN(value1)) {
      this.errorOnValue2 = true;
      console.log('error: ' + value1 + ', ' + value2);
    }
    else {
      this.errorOnValue2 = false;
      console.log('clear ' + value1 + ', ' + value2);
    }
  }

  updateValue2(newValue): any {
    if (newValue.value < 0) {
      this.selectFormGroup.get('value2').setValue('');
    } else {
      this.singleEndpointRecord.value_2__c = newValue.value;
    }

     // checking if value 2 is bigger than value 1
    const value1 = parseInt(String(this.singleEndpointRecord.value_1__c));
    const value2 = parseInt(String(newValue.value));

    if (value2 <= value1 || isNaN(value2)) {
      this.errorOnValue2 = true;
      console.log('error: ' + value1 + ', ' + value2);
    }
    else {
      this.errorOnValue2 = false;
      console.log('clear ' + value1 + ', ' + value2);
    }
  }

  updateValue3(newValue): any {
    this.singleEndpointRecord.value_3__c = newValue.value;
  }

  updateFieldValue(newValue): any {
    const fieldName = newValue.title;
    this.singleEndpointRecord[fieldName] = newValue.value;
  }

  updateNumberFieldValue(newValue): any {
    const fieldName = newValue.title;

    if (fieldName === 'temp_c__c') {
      this.singleEndpointRecord[fieldName] = newValue.value;
    } else {
      if (newValue.value < 0) {
        this.singleEndpointRecord[fieldName] = '';
        this.selectFormGroup.get(fieldName).setValue('');
      } else {
        this.singleEndpointRecord[fieldName] = newValue.value;
      }
    }
  }

  copyEndpoint(singleEndpointRecord: ToxEndpointTableItem): void {
    this.copyInput.emit(singleEndpointRecord);
  }

  updateMultiPicklistFieldValue(newValue, fieldName): void {
    this.singleEndpointRecord[fieldName] = newValue.value.toString();
  }

  setFormFields(): void {
    this.selectFormGroup.get('substanceType').setValue(this.singleEndpointRecord.substance_type__c);
    this.selectFormGroup.get('relatedSubstance').setValue(this.singleEndpointRecord.related_substance__c);
    this.selectFormGroup.get('studyType').setValue(this.singleEndpointRecord.study_type__c);
    this.selectFormGroup.get('test_system_type__c').setValue(this.singleEndpointRecord.test_system_type__c);
    this.selectFormGroup.get('route_of_administration__c').setValue(this.singleEndpointRecord.route_of_administration__c);
    this.selectFormGroup.get('test_system_name__c').setValue(this.singleEndpointRecord.test_system_name__c);
    this.selectFormGroup.get('endpoint').setValue(this.singleEndpointRecord.endpoint__c);
    this.selectFormGroup.get('endpointUnit').setValue(this.singleEndpointRecord.endpoint_unit__c);
    this.selectFormGroup.get('operator').setValue(this.singleEndpointRecord.operator__c);
    this.selectFormGroup.get('value1').setValue(this.singleEndpointRecord.value_1__c);
    this.selectFormGroup.get('value2').setValue(this.singleEndpointRecord.value_2__c);
    this.selectFormGroup.get('value3').setValue(this.singleEndpointRecord.value_3__c);
    this.selectFormGroup.get('value31').setValue(this.singleEndpointRecord.value_3__c);
    // additional fields
    if (this.singleEndpointRecord.study_type__c === 'skin_sensitization__c') {
      this.selectFormGroup.get('test_method_skin_sensitization__c').setValue(this.singleEndpointRecord.test_method_skin_sensitization__c);
      this.selectFormGroup.get('ec3__c').setValue(this.singleEndpointRecord.ec3__c);
      this.selectFormGroup.get('ec15__c').setValue(this.singleEndpointRecord.ec15__c);
    }
    // default
    this.selectFormGroup.get('selectedDocument').setValue(this.singleEndpointRecord.document_unbound__c);
  }

  onSelectOperator(event: any): any {
    if (event.target !== undefined) {
      this.singleEndpointRecord.operator__c = event.target.value;
    }

    switch (this.singleEndpointRecord.operator__c) {
      case 'between__c':
        this.isValue1Disabled = false;
        this.isValue2Disabled = false;
        this.isValue3Disabled = true;
        this.isValue31Disabled = true;
        this.selectFormGroup.get('value1').enable();
        this.selectFormGroup.get('value2').enable();
        this.selectFormGroup.get('value3').disable();
        this.value3Control.setValue('');
        this.singleEndpointRecord.value_3__c = '';
        break;
      case 'text__c':
        this.isValue1Disabled = true;
        this.isValue2Disabled = true;
        if (this.studyTypeColor === 'blueAndPurple') {
          this.isValue3Disabled = true;
          this.isValue31Disabled = false;
        }
        else {
          this.isValue3Disabled = false;
          this.isValue31Disabled = true;
        }
        this.selectFormGroup.get('value1').disable();
        this.selectFormGroup.get('value2').disable();
        this.selectFormGroup.get('value3').enable();
        this.value1Control.setValue(null);
        this.value2Control.setValue(null);
        this.singleEndpointRecord.value_1__c = null;
        this.singleEndpointRecord.value_2__c = null;
        break;
      case '':
        this.isValue1Disabled = true;
        this.isValue2Disabled = true;
        this.isValue3Disabled = true;
        this.selectFormGroup.get('value1').disable();
        this.selectFormGroup.get('value2').disable();
        this.selectFormGroup.get('value3').disable();
        this.value1Control.setValue(null);
        this.value2Control.setValue(null);
        this.value3Control.setValue('');
        this.singleEndpointRecord.value_1__c = null;
        this.singleEndpointRecord.value_2__c = null;
        this.singleEndpointRecord.value_3__c = '';
        break;
      default:
        this.isValue1Disabled = false;
        this.isValue2Disabled = true;
        this.isValue3Disabled = true;
        this.selectFormGroup.get('value1').enable();
        this.selectFormGroup.get('value2').disable();
        this.selectFormGroup.get('value3').disable();
        this.value2Control.setValue(null);
        this.value3Control.setValue('');
        this.singleEndpointRecord.value_2__c = null;
        this.singleEndpointRecord.value_3__c = '';
        break;
    }
  }

  resetAdditionalFields(): any {
    this.singleEndpointRecord.test_method_skin_sensitization__c = '';
    this.singleEndpointRecord.ec3__c = '';
    this.singleEndpointRecord.ec15__c = '';

    this.selectFormGroup.get('test_method_skin_sensitization__c').setValue('');
    this.selectFormGroup.get('ec3__c').setValue('');
    this.selectFormGroup.get('ec15__c').setValue('');
  }

  cancelEditing(endpointRecord): void {
    this.cancelEdit.emit(endpointRecord);
  }

  saveEndpointChanges(endpointRecord): void {
    this.saveChanges.emit(endpointRecord);
  }

}

