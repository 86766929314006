<h2 class="title" mat-dialog-title>{{data.dialogTitle}} "{{data.dialogFieldName}}" Picklist</h2>
<div mat-dialog-content>
  <p>Add picklist value/s.</p>
</div>
<div>
  <mat-form-field class="example-chip-list" >
  <mat-label>Picklist Values</mat-label>
  <mat-chip-list #chipList>
    <mat-chip *ngFor="let newValue of allValues">
      {{newValue.label}}

    </mat-chip>
    <input placeholder="New picklist value..."
           [matChipInputFor]="chipList"

           [matChipInputAddOnBlur]="addOnBlur"
           (matChipInputTokenEnd)="add($event)">
  </mat-chip-list>
</mat-form-field>
</div>
<div mat-dialog-actions class="buttonsHolder">
  <button class="cancelButton" mat-button (click)="onNoClick()">Cancel</button>
  <button class="confirmButton" mat-button (click)="onConfirm()">Save</button>
</div>
