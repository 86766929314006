import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AbstractControl, FormBuilder, FormGroup, Validators, } from '@angular/forms';
import {ToastrService} from 'ngx-toastr';
import {ExceptionService} from 'src/app/core/exception.service';
import {ActivatedRoute} from '@angular/router';
import {ServerService} from 'src/app/core/server.service';
import {EcotoxCategoryPicklists} from '../models/ecotox-category-picklists';
import {PicklistValue} from 'src/app/endpoint/models/picklist-value';
import {EcotoxEndpointTableItem} from '../models/ecotox-endpoint-table-item';
import {EcotoxAdditionalField} from '../models/ecotox-additional-field';
import {DocumentMenuItem} from '../../models/document-menu-item';
import {TestGuidelines} from '../../models/test-guidelines';
import {ToxEndpointTableItem} from "../../models/tox-endpoint-table-item";

let me: EcotoxRecordComponent;

@Component({
  selector: 'app-ecotox-record',
  templateUrl: './ecotox-record.component.html',
  styleUrls: ['../../record_style.css']
})
export class EcotoxRecordComponent implements OnInit {
  @Input() singleEndpointRecord: EcotoxEndpointTableItem;
  @Input() category: PicklistValue;
  @Input() categoryPicklists: Array<EcotoxCategoryPicklists>;
  @Input() allStudyTypes: Array<PicklistValue>;
  @Input() allEndpoints: Array<PicklistValue>;
  @Input() allEndpointUnits: Array<PicklistValue>;
  @Input() allTestSystemTypes: Array<PicklistValue>;
  @Input() allTestSpecies: Array<PicklistValue>;
  @Input() operators: Array<PicklistValue>;
  @Input() allAdditionalFields: Array<EcotoxAdditionalField>;
  @Input() allAdditionalFieldValues: Array<EcotoxAdditionalField>;
  @Input() substanceTypes: Array<PicklistValue>;
  @Input() relatedSubstances: any;
  @Input() documents: Array<DocumentMenuItem>;
  @Input() testGuidelinePicklists: Array<TestGuidelines>;
  @Input() allTestGuidelines: Array<PicklistValue>;
  @Input() purpose: string;

  @Output() removeInput: EventEmitter<EcotoxEndpointTableItem> = new EventEmitter();
  @Output() copyInput: EventEmitter<EcotoxEndpointTableItem> = new EventEmitter();
  @Output() cancelEdit: EventEmitter<ToxEndpointTableItem> = new EventEmitter();
  @Output() saveChanges: EventEmitter<ToxEndpointTableItem> = new EventEmitter();

  subscriptions: any;
  selectFormGroup: FormGroup;
  configureFormGroup: FormGroup;
  public additionalFields: Array<EcotoxAdditionalField>;
  public additionalFieldValues: Map<string, any>;
  public studyTypes: Array<PicklistValue>;
  public endpoints: Array<PicklistValue>;
  public endpointUnits: Array<PicklistValue>;
  public testSystemTypes: Array<PicklistValue>;
  public testSpecies: Array<PicklistValue>;
  public isEndpointUnitDisabled: boolean;
  public isValue1Disabled: boolean;
  public isValue2Disabled: boolean;
  public isValue3Disabled: boolean;
  public selectedTestGuidelineGroup: string;
  public filteredRelatedSubstances: any;
  public hiddenAdditionalFields: Array<EcotoxAdditionalField>;
  public testGuidelines: Array<PicklistValue>;
  private sessionId: string;
  public showCopyOptions: boolean;
  public showEditOptions: boolean;
  public errorOnValue2: Boolean;

  constructor(
    private route: ActivatedRoute,
    private exceptionService: ExceptionService,
    private serverService: ServerService,
    private toastrService: ToastrService,
    private formBuilder: FormBuilder
  ) {
    me = this;
    this.subscriptions = {};
    this.route.paramMap.subscribe((params) => {
      this.sessionId = params.get('sessionId');
    });
    this.selectFormGroup = this.formBuilder.group({
      substanceType: [],
      relatedSubstance: [{value: '', disabled: true}],
      studyType: [{value: '', disabled: false}, Validators.required],
      endpoint: [{value: '', disabled: false}, Validators.required],
      endpointUnit: [{value: '', disabled: false}, Validators.required],
      testSystemType: [{value: '', disabled: false}, Validators.required],
      testSpecies: [{value: '', disabled: false}, Validators.required],
      testGuideline: [{value: '', disabled: false}],
      operator: ['', Validators.required],
      value1: [{value: null, disabled: false}],
      value2: [{value: null, disabled: false}],
      value3: [{value: '', disabled: false}],
      // additional (depended) fields
      exposure_duration__c: [{value: null, disabled: false}],
      exposure_duration_unit__c: [{value: '', disabled: false}],
      exposure_design__c: [{value: '', disabled: false}],
      ed_relevant__c: [{value: '', disabled: false}],
      trigger_ed__c: [{value: '', disabled: false}],
      ed_modality__c: [{value: '', disabled: false}],
      ed_mechanisms__c: [{value: '', disabled: false}],
      used_in_risk_assessment__c: [{value: '', disabled: false}],
      endpoint_analytically_based_on__c: [{value: '', disabled: false}],
      t_trigger__c: [{value: '', disabled: false}],
      affected_ed__c: [{value: '', disabled: false}],
      trr__c: [{value: '', disabled: false}],
      normalization__c: [{value: '', disabled: false}],
      b_trigger__c: [{value: '', disabled: false}],
      substrate__c: [{value: '', disabled: false}],
      recovery_time__c: [{value: null, disabled: false}],
      recovery_time_unit__c: [{value: '', disabled: false}],
      peat_organic_matter__c: [{value: null, disabled: false}],
      comment__c: [{value: '', disabled: false}],
      basf_docid__c: [{value: null, disabled: false}],
      selectedDocument: [{value: '', disabled: false}],
    });
    this.testSystemTypes = [];
    this.testSpecies = [];
    this.additionalFields = [];
    this.additionalFieldValues = new Map();
    this.isEndpointUnitDisabled = false;
    this.isValue1Disabled = true;
    this.isValue2Disabled = true;
    this.isValue3Disabled = true;
    this.filteredRelatedSubstances = [];
    this.hiddenAdditionalFields = [];
    this.testGuidelines = [];
    this.selectedTestGuidelineGroup = '';
    this.errorOnValue2 = false;
  }

  get relatedSubstanceControl(): AbstractControl {
    return this.selectFormGroup.get('relatedSubstance');
  }

  get endpointControl(): AbstractControl {
    return this.selectFormGroup.get('endpoint');
  }

  get endpointUnitControl(): AbstractControl {
    return this.selectFormGroup.get('endpointUnit');
  }

  get testSystemTypeControl(): AbstractControl {
    return this.selectFormGroup.get('testSystemType');
  }

  get testSpeciesControl(): AbstractControl {
    return this.selectFormGroup.get('testSpecies');
  }

  get testGuidelineControl(): AbstractControl {
    return this.selectFormGroup.get('testGuideline');
  }

  get value1Control(): AbstractControl {
    return this.selectFormGroup.get('value1');
  }

  get value2Control(): AbstractControl {
    return this.selectFormGroup.get('value2');
  }

  get value3Control(): AbstractControl {
    return this.selectFormGroup.get('value3');
  }

  get sourceControl(): AbstractControl {
    return this.selectFormGroup.get('selectedDocument');
  }


  ngOnInit(): void {

    if (this.purpose === 'editOnExisting') {
      this.showCopyOptions = false;
      this.showEditOptions = true;
    }
    else {
      this.showCopyOptions = true;
      this.showEditOptions = false;
    }

    this.onSelectOperator({});

    if (this.singleEndpointRecord.study_type__c) {
      // COPY OR EDIT RECORD
      const categoryPicklists = this.categoryPicklists.filter(
        (entry) =>
          entry.studyTypePicklist === this.singleEndpointRecord.study_type_picklist_name__c
      )[0];

      this.filterEndpoints(categoryPicklists.endpointPicklist);
      this.filterEndpointUnits(categoryPicklists.endpointUnitPicklist);
      this.filterTestSystemTypes(categoryPicklists.testSystemTypesPicklist);
      this.filterTestSpecies(categoryPicklists.testSpeciesPicklist);

      if (this.singleEndpointRecord.ed_relevant__c === 'yes__c') {
        const preAdditionalFields = this.allAdditionalFields.filter(
          (entry) =>
            entry.group === this.singleEndpointRecord.study_type_picklist_name__c
        );
        this.additionalFields = preAdditionalFields.sort((a, b) => a.sortNumber - b.sortNumber);
      } else {
        const preAllAdditionalFields = this.allAdditionalFields.filter(
          (entry) =>
            entry.group === this.singleEndpointRecord.study_type_picklist_name__c
        );
        // remove fields mark with 'yes__c' in Initially Hidden field
        const preAdditionalFields = preAllAdditionalFields.filter(obj =>
          obj.hidden !== 'yes__c'
        );
        this.additionalFields = preAdditionalFields.sort((a, b) => a.sortNumber - b.sortNumber);
      }

      const countTestGuidelineGroups = this.testGuidelinePicklists.filter((obj) => obj.studyType === this.singleEndpointRecord.study_type__c).length;

      if (countTestGuidelineGroups > 0) {
        // if there is Test Guideline set for this Study Type, get Test Guideline group picklist name
        this.testGuidelineControl.enable();
        // eslint-disable-next-line max-len
        const testGuidelineGroup = this.testGuidelinePicklists.find(x => x.studyType === this.singleEndpointRecord.study_type__c).testGuidelineGroup;
        if (testGuidelineGroup !== this.selectedTestGuidelineGroup) {
          // if Test Guideline group is different from currently selected:
          // filter testGuidelines
          this.testGuidelines = this.allTestGuidelines.filter(obj =>
            obj.picklist === testGuidelineGroup
          );
          this.selectedTestGuidelineGroup = testGuidelineGroup;
        }
      } else {
        // there is no Test Guideline group defined by this Study Type
        this.testGuidelines = [];
        this.selectedTestGuidelineGroup = '';
      }

      // parent (or none) cannot have Related Substance
      if (this.singleEndpointRecord.substance_type__c === 'parent__c' || this.singleEndpointRecord.substance_type__c === '') {
        this.selectFormGroup.get('relatedSubstance').disable();
      } else {
        // filter Related Substances by the selected Substance Type
        this.filteredRelatedSubstances = this.relatedSubstances.filter(
          (f) => f.substance_type__c[0] === this.singleEndpointRecord.substance_type__c
        );
        this.selectFormGroup.get('relatedSubstance').enable();
      }

      this.setFormFields();
    } else {
      this.selectFormGroup.get('substanceType').setValue('parent__c');
    }

    this.sourceControl.setValue(this.singleEndpointRecord.document_unbound__c);
    this.studyTypes = this.allStudyTypes;
    this.endpoints = this.allEndpoints;
    this.endpointUnits = this.allEndpointUnits;
  }

  getSelectedOptionPicklist(event: any): string {
    return event.target.options[event.target.options.selectedIndex].title;
  }

  onSelectSubstanceType(event: any): any {
    // update object
    this.singleEndpointRecord.substance_type__c = event.target.value;

    // clear Related Substance value because the group of Related Substances has changed
    this.singleEndpointRecord.related_substance__c = '';
    // also clear Related Substance form field
    this.relatedSubstanceControl.setValue('');

    // parent (or none) cannot have Related Substance
    if (event.target.value === 'parent__c' || event.target.value === '') {
      this.selectFormGroup.get('relatedSubstance').disable();
    } else {
      // filter Related Substances by the selected Substance Type
      this.filteredRelatedSubstances = this.relatedSubstances.filter(
        (f) => f.substance_type__c[0] === event.target.value
      );
      this.selectFormGroup.get('relatedSubstance').enable();
    }
  }

  onSelectStudyType(event: any): any {
    if (event.target.value !== '') {
      this.singleEndpointRecord.study_type__c = event.target.value;
      this.singleEndpointRecord.study_type_picklist_name__c = this.getSelectedOptionPicklist(
        event
      );

      // returns group picklists
      const categoryPicklists = this.categoryPicklists.filter(
        (entry) =>
          entry.studyTypePicklist === this.getSelectedOptionPicklist(event)
      )[0];

      this.filterEndpoints(categoryPicklists.endpointPicklist);
      this.filterEndpointUnits(categoryPicklists.endpointUnitPicklist);
      this.filterTestSystemTypes(categoryPicklists.testSystemTypesPicklist);
      this.filterTestSpecies(categoryPicklists.testSpeciesPicklist);

      if (this.singleEndpointRecord.ed_relevant__c === 'yes__c') {
        const preAdditionalFields = this.allAdditionalFields.filter(
          (entry) =>
            entry.group === this.singleEndpointRecord.study_type_picklist_name__c
        );
        this.additionalFields = preAdditionalFields.sort((a, b) => a.sortNumber - b.sortNumber);
      } else {
        const preAllAdditionalFields = this.allAdditionalFields.filter(
          (entry) =>
            entry.group === this.singleEndpointRecord.study_type_picklist_name__c
        );
        // remove fields mark with 'yes__c' in Initially Hidden field
        const preAdditionalFields = preAllAdditionalFields.filter(obj =>
          obj.hidden !== 'yes__c'
        );
        this.additionalFields = preAdditionalFields.sort((a, b) => a.sortNumber - b.sortNumber);
      }

      const countTestGuidelineGroups = this.testGuidelinePicklists.filter((obj) => obj.studyType === event.target.value).length;

      if (countTestGuidelineGroups > 0) {
        // if there is Test Guideline set for this Study Type, get Test Guideline group picklist name
        this.testGuidelineControl.enable();
        const testGuidelineGroup = this.testGuidelinePicklists.find(x => x.studyType === event.target.value).testGuidelineGroup;

        if (testGuidelineGroup !== this.selectedTestGuidelineGroup) {
          // if Test Guideline group is different than currently selected:
          this.testGuidelines = this.allTestGuidelines.filter(obj =>
            obj.picklist === testGuidelineGroup
          );
          this.selectedTestGuidelineGroup = testGuidelineGroup;
          this.singleEndpointRecord.test_guideline__c = '';
          this.selectFormGroup.get('testGuideline').setValue('');
        }
      } else {
        // there is no Test Guideline group defined by this Study Type
        this.testGuidelineControl.disable();
        this.testGuidelines = [];
        this.selectedTestGuidelineGroup = '';
        this.singleEndpointRecord.test_guideline__c = '';
      }

      this.testSystemTypeControl.enable();
      this.testSpeciesControl.enable();
    }
    else {
      this.endpointControl.setValue('');
      this.endpointUnitControl.setValue('');
      this.testSystemTypeControl.setValue('');
      this.testSpeciesControl.setValue('');
      this.studyTypes = this.allStudyTypes;
      this.endpoints = this.allEndpoints;
      this.endpointUnits = this.allEndpointUnits;
      this.additionalFields = [];
      this.testSystemTypeControl.disable();
      this.testSpeciesControl.disable();
      this.testGuidelineControl.disable();
      this.testGuidelines = [];
      this.selectedTestGuidelineGroup = '';
      this.clearAllFieldValues();
    }
  }

  onSelectEndpoint(event: any): any {
    this.singleEndpointRecord.endpoint__c = event.target.value;
    if (event.target.value !== '') {
      this.endpointUnitControl.enable();
    } else {
      this.endpointUnitControl.setValue('');
      this.endpointUnitControl.disable();
      this.singleEndpointRecord.endpoint_unit__c = '';
    }
  }

  onSelectUnit(event: any): any {
    this.singleEndpointRecord.endpoint_unit__c = event.target.value;
  }

  onSelectOperator(event: any): any {
    if (event.target !== undefined) {
      this.singleEndpointRecord.operator__c = event.target.value;
    }

    switch (this.singleEndpointRecord.operator__c) {
      case 'between__c':
        this.isValue1Disabled = false;
        this.isValue2Disabled = false;
        this.isValue3Disabled = true;
        this.selectFormGroup.get('value1').enable();
        this.selectFormGroup.get('value2').enable();
        this.selectFormGroup.get('value3').disable();
        this.value3Control.setValue('');
        this.singleEndpointRecord.value_3__c = '';
        break;
      case 'text__c':
        this.isValue1Disabled = true;
        this.isValue2Disabled = true;
        this.isValue3Disabled = false;
        this.selectFormGroup.get('value1').disable();
        this.selectFormGroup.get('value2').disable();
        this.selectFormGroup.get('value3').enable();
        this.value1Control.setValue(null);
        this.value2Control.setValue(null);
        this.singleEndpointRecord.value_1__c = null;
        this.singleEndpointRecord.value_2__c = null;
        break;
      case '':
        this.isValue1Disabled = true;
        this.isValue2Disabled = true;
        this.isValue3Disabled = true;
        this.selectFormGroup.get('value1').disable();
        this.selectFormGroup.get('value2').disable();
        this.selectFormGroup.get('value3').disable();
        this.value1Control.setValue(null);
        this.value2Control.setValue(null);
        this.value3Control.setValue('');
        this.singleEndpointRecord.value_1__c = null;
        this.singleEndpointRecord.value_2__c = null;
        this.singleEndpointRecord.value_3__c = '';
        break;
      default:
        this.isValue1Disabled = false;
        this.isValue2Disabled = true;
        this.isValue3Disabled = true;
        this.selectFormGroup.get('value1').enable();
        this.selectFormGroup.get('value2').disable();
        this.selectFormGroup.get('value3').disable();
        this.value2Control.setValue(null);
        this.value3Control.setValue('');
        this.singleEndpointRecord.value_2__c = null;
        this.singleEndpointRecord.value_3__c = '';
        break;
    }
  }

  filterEndpoints(picklist: string): any {
    this.endpoints = this.allEndpoints.filter(
      (entry) => entry.picklist === picklist
    );
  }

  filterStudyTypes(picklist: string): any {
    this.studyTypes = this.allStudyTypes.filter(
      (entry) => entry.picklist === picklist
    );
  }

  filterEndpointUnits(picklist: string): any {
    this.endpointUnits = this.allEndpointUnits.filter(
      (entry) => entry.picklist === picklist
    );
  }

  filterTestSystemTypes(picklist: string): any {
    this.testSystemTypes = [];
    this.testSystemTypes = this.allTestSystemTypes.filter(
      (entry) => entry.picklist === picklist
    );
  }

  filterTestSpecies(picklist: string): any {
    this.testSpecies = this.allTestSpecies.filter(
      (entry) => entry.picklist === picklist
    );
  }

  filterAdditionalFieldValues(
    allAdditionalFieldValues: Array<EcotoxAdditionalField>,
    filterWord: string,
  ): Array<EcotoxAdditionalField> {
    return allAdditionalFieldValues.filter(
      (item) => item.picklist === filterWord
    );
  }


  clearAllFieldValues(): any {
    /*
    removes all values from object except id, work area, category, substance, substance type, operator and it's values and source
     */
    this.singleEndpointRecord.study_type__c = '';
    this.singleEndpointRecord.endpoint__c = '';
    this.singleEndpointRecord.endpoint_unit__c = '';
    this.singleEndpointRecord.test_system_type__c = '';
    this.singleEndpointRecord.test_species__c = '';
    this.singleEndpointRecord.exposure_duration__c = null;
    this.singleEndpointRecord.exposure_duration_unit__c = '';
    this.singleEndpointRecord.exposure_design__c = '';
    this.singleEndpointRecord.ed_relevant__c = '';
    this.singleEndpointRecord.trigger_ed__c = '';
    this.singleEndpointRecord.ed_modality__c = '';
    this.singleEndpointRecord.ed_mechanisms__c = '';
    this.singleEndpointRecord.test_guideline__c = '';
    this.singleEndpointRecord.used_in_risk_assessment__c = '';
    this.singleEndpointRecord.endpoint_analytically_based_on__c = '';
    this.singleEndpointRecord.t_trigger__c = '';
    this.singleEndpointRecord.affected_ed__c = '';
    this.singleEndpointRecord.trr__c = '';
    this.singleEndpointRecord.normalization__c = '';
    this.singleEndpointRecord.b_trigger__c = '';
    this.singleEndpointRecord.substrate__c = '';
    this.singleEndpointRecord.recovery_time__c = null;
    this.singleEndpointRecord.recovery_time_unit__c = '';
    this.singleEndpointRecord.peat_organic_matter__c = null;
    this.singleEndpointRecord.comment__c = '';
    this.singleEndpointRecord.basf_docid__c = null;
    this.singleEndpointRecord.study_type_picklist_name__c = '';
  }

  removeEndpoint(singleEndpointRecord): any {
    this.removeInput.emit(singleEndpointRecord);
  }

  updateValue1(newValue): any {
    if (newValue.value < 0) {
      this.selectFormGroup.get('value1').setValue('');
    }
    else {
      this.singleEndpointRecord.value_1__c = newValue.value;
    }

    // checking if value 2 is bigger than value 1
    const value1 = parseInt(String(newValue.value));
    const value2 = parseInt(String(this.singleEndpointRecord.value_2__c));

    if (value2 <= value1 || isNaN(value1)) {
      this.errorOnValue2 = true;
      console.log('error: ' + value1 + ', ' + value2);
    }
    else {
      this.errorOnValue2 = false;
      console.log('clear ' + value1 + ', ' + value2);
    }
  }

  updateValue2(newValue): any {
    if (newValue.value < 0) {
      this.selectFormGroup.get('value2').setValue('');
    } else {
      this.singleEndpointRecord.value_2__c = newValue.value;
    }

     // checking if value 2 is bigger than value 1
    const value1 = parseInt(String(this.singleEndpointRecord.value_1__c));
    const value2 = parseInt(String(newValue.value));

    if (value2 <= value1 || isNaN(value2)) {
      this.errorOnValue2 = true;
      console.log('error: ' + value1 + ', ' + value2);
    }
    else {
      this.errorOnValue2 = false;
      console.log('clear ' + value1 + ', ' + value2);
    }
  }

  updateValue3(newValue): any {
    this.singleEndpointRecord.value_3__c = newValue.value;
  }

  updateFieldValue(newValue): any {
    const fieldName = newValue.title;
    this.singleEndpointRecord[fieldName] = newValue.value;

    if (fieldName === 'ed_relevant__c') {
      if (newValue.value === 'yes__c') {
        // show all additional fields for selected study type
        const preAdditionalFields = this.allAdditionalFields.filter(
          (entry) =>
            entry.group === this.singleEndpointRecord.study_type_picklist_name__c
        );
        this.additionalFields = preAdditionalFields.sort((a, b) => a.sortNumber - b.sortNumber);
      } else {
        const additionalFields = this.additionalFields;
        // remove fields mark with 'yes__c' in Initially Hidden field
        const preAdditionalFields = this.additionalFields.filter(obj =>
          obj.hidden !== 'yes__c'
        );
        this.additionalFields = preAdditionalFields.sort((a, b) => a.sortNumber - b.sortNumber);

        // filter hidden fields
        this.hiddenAdditionalFields = additionalFields.filter(
          (a) => a.hidden === 'yes__c'
        );
        this.hiddenAdditionalFields.forEach((element: any) => {
          this.singleEndpointRecord[element.name] = '';
          this.selectFormGroup.get(element.name).setValue('');
        });
      }
    }
  }

  updateNumberFieldValue(newValue): any {
    const fieldName = newValue.title;

    if (fieldName === 'temp_c__c') {
      this.singleEndpointRecord[fieldName] = newValue.value;
    } else {
      if (newValue.value < 0) {
        this.singleEndpointRecord[fieldName] = '';
        this.selectFormGroup.get(fieldName).setValue('');
      } else {
        this.singleEndpointRecord[fieldName] = newValue.value;
      }
    }
  }

  updateMultiPicklistFieldValue(newValue, fieldName): void {
    this.singleEndpointRecord[fieldName] = newValue.value.toString();
  }

  copyEndpoint(singleEndpointRecord: EcotoxEndpointTableItem): void {
    this.copyInput.emit(singleEndpointRecord);
  }

  setFormFields(): void {
    this.selectFormGroup.get('substanceType').setValue(this.singleEndpointRecord.substance_type__c);
    this.selectFormGroup.get('relatedSubstance').setValue(this.singleEndpointRecord.related_substance__c);
    this.selectFormGroup.get('endpoint').setValue(this.singleEndpointRecord.endpoint__c);
    this.selectFormGroup.get('studyType').setValue(this.singleEndpointRecord.study_type__c);
    this.selectFormGroup.get('endpointUnit').setValue(this.singleEndpointRecord.endpoint_unit__c);
    this.selectFormGroup.get('testSystemType').setValue(this.singleEndpointRecord.test_system_type__c);
    this.selectFormGroup.get('testSpecies').setValue(this.singleEndpointRecord.test_species__c);
    this.selectFormGroup.get('operator').setValue(this.singleEndpointRecord.operator__c);
    this.selectFormGroup.get('value1').setValue(this.singleEndpointRecord.value_1__c);
    this.selectFormGroup.get('value2').setValue(this.singleEndpointRecord.value_2__c);
    this.selectFormGroup.get('value3').setValue(this.singleEndpointRecord.value_3__c);
    if (this.singleEndpointRecord.test_guideline__c) {
      this.selectFormGroup.get('testGuideline').setValue(this.singleEndpointRecord.test_guideline__c.split(','));
    }
    this.selectFormGroup.get('exposure_duration__c').setValue(this.singleEndpointRecord.exposure_duration__c);
    this.selectFormGroup.get('exposure_duration_unit__c').setValue(this.singleEndpointRecord.exposure_duration_unit__c);
    this.selectFormGroup.get('exposure_design__c').setValue(this.singleEndpointRecord.exposure_design__c);
    this.selectFormGroup.get('ed_relevant__c').setValue(this.singleEndpointRecord.ed_relevant__c);
    this.selectFormGroup.get('trigger_ed__c').setValue(this.singleEndpointRecord.trigger_ed__c);
    if (this.singleEndpointRecord.ed_modality__c) {
      this.selectFormGroup.get('ed_modality__c').setValue(this.singleEndpointRecord.ed_modality__c.split(','));
    }
    this.selectFormGroup.get('ed_mechanisms__c').setValue(this.singleEndpointRecord.ed_mechanisms__c);
    this.selectFormGroup.get('used_in_risk_assessment__c').setValue(this.singleEndpointRecord.used_in_risk_assessment__c);
    this.selectFormGroup.get('endpoint_analytically_based_on__c').setValue(this.singleEndpointRecord.endpoint_analytically_based_on__c);
    this.selectFormGroup.get('t_trigger__c').setValue(this.singleEndpointRecord.t_trigger__c);
    if (this.singleEndpointRecord.affected_ed__c) {
      this.selectFormGroup.get('affected_ed__c').setValue(this.singleEndpointRecord.affected_ed__c.split(','));
    }
    this.selectFormGroup.get('trr__c').setValue(this.singleEndpointRecord.trr__c);
    this.selectFormGroup.get('normalization__c').setValue(this.singleEndpointRecord.normalization__c);
    this.selectFormGroup.get('b_trigger__c').setValue(this.singleEndpointRecord.b_trigger__c);
    this.selectFormGroup.get('substrate__c').setValue(this.singleEndpointRecord.substrate__c);
    this.selectFormGroup.get('recovery_time__c').setValue(this.singleEndpointRecord.recovery_time__c);
    this.selectFormGroup.get('recovery_time_unit__c').setValue(this.singleEndpointRecord.recovery_time_unit__c);
    this.selectFormGroup.get('peat_organic_matter__c').setValue(this.singleEndpointRecord.peat_organic_matter__c);
    this.selectFormGroup.get('comment__c').setValue(this.singleEndpointRecord.comment__c);
    this.selectFormGroup.get('basf_docid__c').setValue(this.singleEndpointRecord.basf_docid__c);
    this.selectFormGroup.get('selectedDocument').setValue(this.singleEndpointRecord.document_unbound__c);
  }

  cancelEditing(endpointRecord): void {
    this.cancelEdit.emit(endpointRecord);
  }

  saveEndpointChanges(endpointRecord): void {
    this.saveChanges.emit(endpointRecord);
  }

}


