import {NgModule, Optional, SkipSelf} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NotFoundComponent} from './not-found/not-found.component';
import {HomePageComponent} from './home-page/home-page.component';
import {NgSelectModule} from "@ng-select/ng-select";
import {FormsModule} from "@angular/forms";
import {MatDialogModule} from "@angular/material/dialog";
import {MenuListItemComponent} from './navigation/components/menu-list-item/menu-list-item.component';
import {MatIconModule} from "@angular/material/icon";
import {MatListModule} from "@angular/material/list";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatSelectModule} from "@angular/material/select";
import {FooterComponent} from './footer/footer.component';

@NgModule({
  declarations: [NotFoundComponent, HomePageComponent, MenuListItemComponent, FooterComponent],
  imports: [CommonModule, NgSelectModule, FormsModule, MatDialogModule, MatIconModule, MatListModule, MatFormFieldModule, MatSelectModule],
  exports: [
    MenuListItemComponent,
    FooterComponent
  ]
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() core: CoreModule) {
    if (core) {
      throw new Error('You should import core module only in the root module');
    }
  }
}
